<div class="d-flex justify-content-end mt-1 mb-2">
  <button class="btn btn-primary" (click)="openEditDetailModal('addBranch')">{{ "General.Create" | translate }}</button>
</div>
<table class="table">
  <thead>
    <tr>
      <th width="3%">#</th>
      <th>Name</th>
      <th width="10%">Status</th>
      <th width="10%">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let branch of branchListTest; index as ind">
      <td>{{ ind + 1 }}</td>
      <td>{{ branch.name }}</td>
      <td>
        <span style="display: inline-block; margin-left: 10px; align-self: center;">
          <div ngbDropdown>
            <a ngbDropdownToggle href="javascript:void(0);" class="d-flex justify-content-center text-secondary"
              id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="{
              'text-success': branch.isActive === true,
              'text-danger': branch.isActive === false}">
              <div>
                {{
                (branch.isActive == true
                ? "Status.Published"
                : "Status.Unpublished"
                ) | translate
                }}
              </div>
              <i data-feather="chevron-down" class="text-dark cursor-pointer ml-50 my-auto"></i>
            </a>
            <div ngbDropdownMenu class="dropdown-menu-left" aria-labelledby="dropdownBrowserState">
              <a ngbDropdownItem *ngIf="branch.isActive == true" class="d-flex align-items-center"
                (click)="openActiveModal(branch.id, false)">
                <i data-feather="eye-off" class="mr-50"></i>
                {{ "Status.Unpublish" | translate }}
              </a>
              <a ngbDropdownItem *ngIf="branch.isActive == false" class="d-flex align-items-center"
                (click)="openActiveModal(branch.id, true)">
                <i data-feather="eye" class="mr-50"></i>
                {{ "Status.Publish" | translate }}
              </a>
            </div>
          </div>

        </span>
      </td>
      <td class="text-center d-flex justify-content-center">
        <a class="btn btn-icon btn-icon rounded-circle btn-outline-warning mr-50"
          (click)="openEditDetailModal('editBranch', branch)"><i data-feather="edit"></i></a>
        <a class="btn btn-icon btn-icon rounded-circle btn-outline-danger mr-50" (click)="openDeleteModal(branch.id)"><i
            data-feather="trash-2" class="text-danger"></i></a>
      </td>
    </tr>
  </tbody>
</table>