<section id="apex-column-charts">
  <div class="row" (window:resize)="onResize($event)">
    <div class="col-12">
      <div class="card">
        <div class="d-flex justify-content-center align-items-center my-2">
          <div class="mr-1">
            <label>{{ "Stock.From" | translate }}:</label>
          </div>
          <select class="col-2 custom-select mx-1" [(ngModel)]="selectedStartYear" (change)="onStartYearChange()">
            <option *ngFor="let year of yearList" [value]="year.toString()">{{ year }}</option>
          </select>
          <select class="col-2 custom-select mx-0" [disabled]="!selectedStartYear" [(ngModel)]="selectedStartMonth" (change)="onStartMonthChange()">
            <option *ngFor="let month of months" [value]="month.value.toString()">
              <span *ngIf="currentLang == 'en'">
                {{ month.nameEN }}
              </span>
              <span *ngIf="currentLang == 'th'">
                {{ month.nameTH }}
              </span>
            </option>
          </select>


          <div class="ml-3">
            <label>{{ "Stock.To" | translate }}:</label>
          </div>
          <select class="col-2 custom-select mx-1" [disabled]="!selectedStartYear" [(ngModel)]="selectedEndYear" (change)="onYearChange()">
            <option *ngFor="let year of selectedEndYearOptions" [value]="year">{{ year }}</option>
          </select>
          <select class="col-2 custom-select mx-0" [disabled]="!selectedStartYear" [(ngModel)]="selectedEndMonth" (change)="onMonthChange()">
            <option *ngFor="let month of selectedEndMonthOptions" [value]="month.value"><span
                *ngIf="currentLang == 'en'">
                {{ month.nameEN }}
              </span>
              <span *ngIf="currentLang == 'th'">
                {{ month.nameTH }}
              </span>
            </option>
          </select>
        </div>

        <div class="d-flex justify-content-center">
          <button class="btn btn-secondary" (click)="resetSearchCriteria()"><i data-feather="refresh-ccw"
              class="mr-1"></i>{{
            "General.Reset" | translate }}</button>

          <button (click)="submitDate()" class="btn btn-primary mx-1" style="margin-left: 0.5rem">
            <i data-feather="search" class="mr-1"></i>{{ "General.Search" | translate }}
          </button>

        </div>

        <hr>
        <div class="card-body px-0">
          <div class="d-flex justify-content-center align-items-center">
            <div class="col-10"></div>
            <button class="btn btn-primary" (click)="exportReport()">
              <span>{{ "General.Export" | translate }}</span>
            </button>
          </div>
          <div id="column-chart" #apexColumnChartRef>
            <apx-chart [series]="overallLineChart.series" [chart]="overallLineChart.chart"
              [xaxis]="overallLineChart.xaxis" [yaxis]="overallLineChart.yaxis" [stroke]="overallLineChart.stroke"
              [tooltip]="overallLineChart.tooltip" [colors]="overallLineChart.colors" [legend]="overallLineChart.legend"
              [dataLabels]="overallLineChart.dataLabels" [plotOptions]="overallLineChart.plotOptions"
              [grid]="overallLineChart.grid" [labels]="overallLineChart.labels"
              [yaxis]="overallLineChart.yaxis" [noData]="overallLineChart.noData"></apx-chart>
            <apx-chart [series]="apexColumnChart.series" [chart]="apexColumnChart.chart" [xaxis]="apexColumnChart.xaxis"
              [yaxis]="apexColumnChart.yaxis" [stroke]="apexColumnChart.stroke" [tooltip]="apexColumnChart.tooltip"
              [colors]="apexColumnChart.colors" [legend]="apexColumnChart.legend"
              [dataLabels]="apexColumnChart.dataLabels" [plotOptions]="apexColumnChart.plotOptions"
              [grid]="apexColumnChart.grid" [labels]="apexColumnChart.labels"
              [yaxis]="apexColumnChart.yaxis" [noData]="apexColumnChart.noData"></apx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="d-flex justify-content-center align-items-center my-2">
          <label>{{'Dashboard.Member.Target' | translate}}:</label>
          <select class="col-4 custom-select mx-1" [(ngModel)]="targetSelect" (change)="onSetTargetChange($event)">
            <option *ngFor="let target of targetType" [value]="target.value">{{ 'Dashboard.Member.' + target.name |
              translate}}</option>
          </select>
          <label>{{'Dashboard.Member.Year' | translate}}:</label>
          <select class="col-2 custom-select mx-1" [(ngModel)]="targetYear" (change)="onSelectTargetYear($event)">
            <option *ngFor="let year of yearList" [value]="year.toString()">{{ year }}</option>
          </select>
          <button (click)="onTargetSearch()" class="btn btn-primary mx-1" style="margin-left: 0.5rem">
            <i data-feather="search" class="mr-1"></i>{{ "General.Search" | translate }}
          </button>
        </div>

        <div class="d-flex justify-content-center align-items-center my-2">
          <h4>
            {{ 'Dashboard.Member.' + currentTable | translate}} {{currentYearTable}}
          </h4>
        </div>

        <div class="d-flex justify-content-center align-items-center my-2">
          <table class="target-table col-12">
            <thead>
              <th class="p-1">{{ 'Dashboard.Member.Month' | translate}}</th>
              <th>{{ 'Dashboard.Member.TargetValue' | translate}}</th>
              <th>{{ 'Dashboard.Member.Classifier' | translate}}</th>
              <th>{{ 'General.Action' | translate}}</th>
            </thead>
            <tbody>
              <tr *ngFor="let month of months; let i = index">
                <td class="p-1">{{getMonth(i) }}</td>
                <td>
                  <ng-container *ngIf="!isEditing[i]">
                    {{ tableData[i].targetValue | number:'1.0-0' }}
                  </ng-container>
                  <ng-container *ngIf="isEditing[i]">
                    <div class="d-flex justify-content-center">
                      <input type="number" class="form-control text-center col-4 " [(ngModel)]="targetValues[i]" />
                    </div>
                  </ng-container>

                </td>
                <td>{{ 'Dashboard.Member.' + classifier| translate}}</td>
                <td>
                  <ng-container *ngIf="editingRowIndex === -1">
                    <a class="btn btn-icon btn-icon rounded-circle btn-outline-secondary pointer-event-none"
                      *ngIf="!checkEditCondition(tableData[i].endDate)"
                      style="display: inline-block; margin-right: 5px;">
                      <i data-feather="edit" class="text-secondary"></i>
                    </a>
                    <a class="btn btn-icon btn-icon rounded-circle btn-outline-warning"
                      *ngIf="checkEditCondition(tableData[i].endDate)" (click)="startEditing(i)"
                      style="display: inline-block; margin-right: 5px;">
                      <i data-feather="edit" class="text-warning"></i>
                    </a>

                  </ng-container>
                  <ng-container *ngIf="editingRowIndex !== -1 && editingRowIndex !== -1 ">
                    <a class="btn btn-icon btn-icon rounded-circle btn-outline-secondary pointer-event-none"
                      *ngIf="!isEditing[i]" style="display: inline-block; margin-right: 5px;">
                      <i data-feather="edit" class="text-secondary"></i>
                    </a>
                    <span>&nbsp;</span>
                  </ng-container>
                  <ng-container *ngIf="isEditing[i]">
                    <a class="btn btn-icon rounded-circle btn-outline-success" (click)="saveEditing(i)"
                      style="display: inline-block; margin-right: 5px;">
                      <i data-feather="save" class="text-success"></i>
                    </a>
                    <a class="btn btn-icon rounded-circle btn-outline-warning" (click)="cancelEditing(i)"
                      style="display: inline-block; margin-right: 5px;">
                      <i data-feather="x-square" class="text-warning"></i>
                    </a>
                    <!-- <button class="btn btn-success"">Save</button>
                    <button class="btn btn-secondary" (click)="cancelEditing(i)">Cancel</button> -->
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>