<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <app-search-panel-document
      [tableCode]="apiPath"
      [forCompanentName]="componentName"
      [pathUrl]="pathUrl + '/create'"
      [isGridView]="isGridView"
      [isForGroupTemplate]="isForGroupTemplate"
      [isShowSortByGroup]="false"
      tableCode="taxInvoice"
      [statusList]="statusList"
      (callDataFromAPI)="getAllData($event)"
      (SetLoading)="SetLoadingState()"
    ></app-search-panel-document>

    <section>
      <!-- <div>
        <div
          class="nav py-2 px-1 border justify-content-end"
          style="background: #f0f0f0"
        >
          <button class="btn btn-primary" (click)="ExportXLSX()">
            {{ "General.Export" | translate }}
          </button>
        </div>
      </div> -->

      <div class="card">
        <div class="card-body">
          <table [dtOptions]="dtOptions" class="table" datatable>
            <thead>
              <tr class="text-center" style="width: 100% !important">
                <th class="text-center" width="8%">
                  {{ "ViewTable.No" | translate }}
                </th>
                <th class="text-center" width="15%">
                  {{ "ViewTable.Code" | translate }}
                </th>
                <th class="text-center" width="15%">
                  {{ "ViewTable.CustomerName" | translate }}
                </th>
                <th class="text-center" width="12%">
                  {{ "ViewTable.Status" | translate }}
                </th>
                <th class="text-center" width="12%">
                  {{ "ViewTable.DateFrom" | translate }}
                </th>
                <!-- <th class="text-center" width="12%">
                  {{ "ViewTable.DateTo" | translate }}
                </th> -->
                <th class="text-center" width="15%">
                  {{ "ViewTable.GrandTotal" | translate }}
                </th>
                <th class="text-center" width="10%">
                  {{ "ViewTable.Actions" | translate }}
                </th>
              </tr>
            </thead>

            <tbody style="width: 100% !important">
              <tr class="w-100" *ngIf="isLoading">
                <td class="text-center" *ngFor="let num of [].constructor(7)">
                  <div class="skeleton-loading"></div>
                </td>
              </tr>

              <tr *ngIf="itemList.length < 1 && !isLoading">
                <td colspan="100%" class="text-center">
                  <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
                </td>
              </tr>

              <tr
                *ngFor="let itemObj of itemList; let ind = index"
                [ngClass]="{
                  isvoice: itemObj.isVoice
                }"
              >
                <td class="text-center">
                  {{ (page - 1) * pageSize + ind + 1 }}
                </td>
                <td class="text-truncate" style="max-width: 200px">
                  <a
                    [routerLink]="
                      itemObj.transactionStatusId == 1
                        ? [editPathUrl + '/' + itemObj.id]
                        : [detailPathUrl + '/' + itemObj.id]
                    "
                    class="text-truncate text-primary"
                  >
                    {{ itemObj.taxInvoiceCode }}
                  </a>
                </td>
                <td class="text-truncate" style="max-width: 200px">
                  <a
                    [routerLink]="[detailPathUrl + '/' + itemObj.id]"
                    class="text-truncate text-secondary"
                  >
                    {{ itemObj.customerName }}
                  </a>
                </td>
                <td class="text-center my-auto">
                  <app-document-status-badge
                    [status]="itemObj.transactionStatusId"
                    [isDelete]="itemObj.isDelete"
                  ></app-document-status-badge>
                </td>
                <td
                  class="text-center my-auto"
                >
                  <span *ngIf="currentLang != 'th'">
                    {{ itemObj.documentCreateDate | date : "dd MMM YYYY HH:mm" }}
                  </span>
                  <span *ngIf="currentLang != 'en'">
                    {{ itemObj.documentCreateDate | thaidate : "DD MMM YYYY HH:mm" }} น.
                  </span>
                </td>
                <td class="text-end">
                  <span>
                    {{ convertNumbertoMoneyFormat(itemObj.grandTotal) }}
                  </span>
                </td>
                <td class="text-center d-flex justify-content-center">
                  <a
                    class="btn btn-icon btn-icon rounded-circle btn-outline-success mr-50"
                    [routerLink]="[detailPathUrl + '/' + itemObj.id]"
                    ><i data-feather="eye"></i
                  ></a>
                  <a
                    class="btn btn-icon btn-icon rounded-circle btn-outline-warning mr-50"
                    *ngIf="itemObj.transactionStatusId == 1"
                    [routerLink]="[editPathUrl + '/' + itemObj.id]"
                    ><i data-feather="edit"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</div>
