import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  componentName: string = 'General.Customer';
  apiPath: string = 'customer';
  pathUrl: string = '/customer';
  apiUrl: string = 'customer';
  keyName: string = 'CreateDate';

  pathUrlAddr: string = '/CustomerAddress';
  apiPathAddr: string = 'CustomerAddress';

  editPathURL: string = '/customer/edit';
  detailPathURL: string = '/customer/detail';
  constructor(private _translateService: TranslateService) {
    console.log(this.componentName);
  }
}
