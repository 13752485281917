import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { CommonResponse } from 'app/main/model/CommonResponse';
import Notification, {
  NotificationStatus,
  typeId,
} from 'app/main/model/Notification';
import { ApiService } from 'app/main/service/api.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {

  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;
  contentHeader: object;

  itemList: Notification[] = [];

  isLoading: boolean = false
  page: number = 1
  pageSize: number = 10
  currentLang: string;

  searchText: string = '';
  searchData: any = {};
  searchByType: number = null;
  searchByTypeId: number = null;
  searchByStatus: boolean = null;
  isAllChecked: any;
  pathUrl: string = '/notification';
  status: string;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = { searching: false, lengthChange: false };
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _apiService: ApiService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private _componentsService: ComponentsService
  ) { }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;

    this.contentHeader = {
      headerTitle: 'Notification.Title',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
    this.currentLang = this.translateService.currentLang || 'en';

    this.translateService.onLangChange.subscribe((e) => {
      this.currentLang = this.translateService.currentLang;
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: this.pageSize,
      order: [[1, 'desc']],
      ordering: true,
      serverSide: true,
      lengthMenu: [5, 10, 25, 50, 100],
      lengthChange: true,
      dom: '<"float-left"B><"float-right"f>rt<"row"<"col-sm-4"i><"col-sm-4 text-center"l><"col-sm-4"p>>',
      columnDefs: [
        {
          orderable: false,
          targets: 0,
        },
        { orderable: false, targets: 1 },
        { name: 'ItemCode', targets: 2 },
        { name: 'SellingPrice', targets: 3 },
        { name: 'Quantity', targets: 4 },
        { orderable: false, targets: 5 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.itemList = [];
        self.SetLoadingState();

        self.searchData.NotificationTypeId = ''
        self.searchData.TypeId = ''
        self.searchData.Name = ''
        self.searchData.isActive = ''
        if (this.searchByTypeId != null) {
          self.searchData.TypeId = this.searchByTypeId;
        }
        if (this.searchByType != null) {
          self.searchData.NotificationTypeId = this.searchByType;
        }
        if (this.searchText != null) {
          self.searchData.Name = this.searchText;
        }
        if (this.searchByStatus != null) {
          self.searchData.isActive = this.searchByStatus;
        }

        let defaultSortName = 'createDate';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        } else {
          self.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.searchData.Direction = order?.dir == 'desc' ? 1 : 0;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.searchData.PageLength = dataTablesParameters.length;
        this.pageSize = dataTablesParameters.length;
        this.searchData.isDelete = false;
        this.searchData.verbose = false;

        console.log(this.searchData);
        this._apiService.GetAllData('Notification', this.searchData).subscribe(
          (response: CommonResponse<Notification>) => {
            console.log(response.data.resultData);
            this.itemList = response.data.resultData;
            self.SetLoadedState();
            callback({
              recordsTotal: response.data.itemCount,
              recordsFiltered: response.data.totalItem,
              data: [],
            });
          },
          (err) => {
            self.SetLoadedState();
          }
        );
      },
    };
  }

  initData(obj?: { [key: string]: any }): void {
    this.SetLoadingState();
    this.itemList = [];

    this.searchData = {
      SortPath: 'CreateDate',
      Direction: 1,
      isDelete: false,
    };

    if (obj) {
      console.log(obj.params);
      console.log('have searchtext: ', obj.searchText);

      this.searchText = obj.searchText ? obj.searchText : '';

      if (!obj.params) {
        this.searchData = {
          SortPath: 'CreateDate',
          Direction: 1,
          isDelete: false,
        };
      } else {
        this.searchData = obj.params;
      }
    } else {
      this.searchText = '';
      this.searchData = {
        SortPath: 'CreateDate',
        Direction: 1,
        isDelete: false,
      };
    }

    this.searchData.name = this.searchText;

    this.loadData(this.searchData);
  }

  loadData(searchData?: any) {
    if (searchData) {
      this.searchData = searchData;
    } else {
      this.searchData = {
        Direction: 1,
        isDelete: false,
      };
    }

    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          this.SetLoadedState();
        });
      });
    }
  }

  openSetActiveModal(index: number, status) {
    const modalRef = this.modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    let modeText = status
      ? this.translateService.instant('Status.Published')
      : this.translateService.instant('Status.Unpublished');
    modalRef.componentInstance.title = `${modeText}`;
    modalRef.componentInstance.detail =
      this.translateService.instant('BonusPlan.Active') + `${modeText}`;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      if (modalRef.componentInstance.closeModal()) {
        this.setActiveContent(index, this.itemList[index].isActive);
      } else {
        this.setActiveContent(index, status);
      }

      // this.loadData()
    });
  }

  setActiveContent(index: number, value: boolean): void {
    this._apiService
      .SetIsActiveData('Notification', this.itemList[index].id.toString(), value)
      .subscribe(
        (res) => {
          console.log(res);
          this.loadData();
          this._componentsService.SuccessSwal();
        },
        (error) => {
          console.error('Error deleting data:', error);
          this._componentsService.ErrorSwal();
        }
      );
  }

  openDeleteContentModal(itemObj: any) {
    const modalRef = this.modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title =
      this.translateService.instant('Brand.DeleteTitle') + ` ${itemObj.name} Notification`;
    modalRef.componentInstance.detail =
      this.translateService.instant('Brand.Delete') + ` ${itemObj.name} Notification`;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.deleteContent(itemObj.id);
    });
  }

  deleteContent(id: string): void {
    this._apiService.SoftDelete('Notification', id).subscribe(
      (res) => {
        this._componentsService.SuccessSwal();
        this.SetLoadingState();
        this.loadData();
      },
      (err) => {
        this._componentsService.ErrorSwal();
      }
    );
  }

  Search() {
    let Table = this.dtElements.find((item: any) => {
      return item.el.nativeElement.id == 'eventTable';
    });

    Table.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload(() => {
        this.SetLoadedState();
      });
    });

    console.log(this.dtElements);
  }

  Reset() {
    this.searchByType = null
    this.searchText = ''
    this.searchByStatus = null
    this.Search();
  }

  displayStatusOn(status: number) {
    switch (status) {
      case NotificationStatus.All:
        return 'ALL';
      case NotificationStatus.Group:
        return 'GROUP ';
      case NotificationStatus.AllRank:
        return 'ALL RANK';
      case NotificationStatus.GroupRank:
        return 'GROUP RANK';
      default:
        return '-';
    }
  }

  displayTypeIdOn(status: number) {
    switch (status) {
      case typeId.RepoveTH:
        return 'Repove TH';
      case typeId.Promotion:
        return 'Promotion ';
      case typeId.Order:
        return 'Order';
      case typeId.Finance:
        return 'Finance';
      default:
        return '-';
    }
  }
}
