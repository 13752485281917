import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'app/auth/helpers';
import { TranslateModule } from '@ngx-translate/core';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSummernoteModule } from 'ngx-summernote';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { ComponentsModule } from 'app/main/components/components.module';
import { TermsConditionComponent } from './terms-condition.component';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';

const path: string = 'cms/terms-condition';
const routes: Routes = [
  {
    path: path,
    component: TermsConditionComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-terms-condition' },
  },
];

@NgModule({
  declarations: [TermsConditionComponent],
  imports: [CommonModule, RouterModule.forChild(routes), CmsFormpageModule],
  exports: [TermsConditionComponent],
})
export class TermsConditionModule {}
