<div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
  <app-loading></app-loading>
</div>
<div *ngIf="!isLoading" class="row">
  <div class="col-md-12">
    <form [formGroup]="registerForm">
      <h4 class="mb-2">{{ "General.SubClass" | translate }}</h4>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{
          "Form.Name" | translate
        }}</label>
        <div class="col-sm-9">
          <input
            class="form-control"
            type="text"
            formControlName="name"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.name?.invalid) ||
                (f.name?.invalid && (f.name?.dirty || f.name?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block': f.name.invalid && (f.name.dirty || f.name.touched)
            }"
          >
            <div>
              <div *ngIf="f.name.errors?.required">
                {{ "Handle.Name" | translate }}
              </div>
              <div *ngIf="f.name.errors?.maxlength">
                {{ "Handle.Exceed" | translate }}
                50
                {{ "Handle.Char" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{
          "General.Class" | translate
        }}</label>
        <div class="col-sm-9">
          <select
            class="custom-select"
            name="classId"
            id="classId"
            formControlName="classId"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.classId.errors?.required) ||
                (f.classId.errors?.required &&
                  (f.classId.dirty || f.classId.touched))
            }"
          >
            <option
              [value]="class.id"
              *ngFor="let class of classList"
              [selected]="class.id == itemObj.classId"
            >
              {{ class.name }}
            </option>
          </select>

          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                f.classId.invalid && (f.classId.dirty || f.classId.touched)
            }"
          >
            <div>
              <div>
                <div *ngIf="f.classId.errors?.required">
                  {{ "Handle.classId" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-3 col-form-label">{{
          "Form.Status" | translate
        }}</label>
        <div class="col-sm-9">
          <div
            class="custom-control custom-switch custom-control-inline col-form-label"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitch1"
              [(ngModel)]="itemObj.isActive"
              [ngModelOptions]="{ standalone: true }"
              name="isActive"
              [checked]="itemObj.isActive"
            />
            <label class="custom-control-label" for="customSwitch1"></label>
          </div>
        </div>
      </div>

      <br />
      <div class="form-group d-flex justify-content-between">
        <a class="btn btn-secondary" (click)="openCancelModal()">
          {{ "Form.Cancel" | translate }}
        </a>

        <a class="btn btn-primary" (click)="openConfirmModal(itemObj)">
          {{ "Form.Submit" | translate }}
        </a>
      </div>
    </form>
  </div>
</div>
