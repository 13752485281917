import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { FaqService } from './faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  config!: ICMSConfig;
  keyName: string;

  constructor(private _faqService: FaqService) {
    this.config = this._faqService.config;
    this.keyName = this._faqService.keyName;
  }


  ngOnInit(): void {
  }

}
