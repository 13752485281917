import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './general/home/home.component';
import { AuthGuard } from 'app/auth/helpers';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AuthenticationModule } from './authentication/authentication.module';
import { MiscellaneousModule } from './miscellaneous/miscellaneous.module';
import { DataTablesModule } from 'angular-datatables';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgModule } from '@angular/core';
import { CustomerModule } from './customer/customer.module';
import { CmsModule } from './cms/cms.module';
import { GeneralModule } from './general/general.module';
import { AdminModule } from './admin/admin.module';
import { ItemManageModule } from './item-manage/item-manage.module';
import { DocumentModule } from './document/document.module';
import { StockManageModule } from './stock-manage/stock-manage.module';
import { DragulaService } from 'ng2-dragula';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { FrontendModule } from './admin/frontend/frontend.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { MLMConfigurationModule } from './mlm-configuration/mlm-configuration.module';
import { CodeManageModule } from './code-manage/code-manage.module';
import { NotificationModule } from './notification/notification.module';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home' },
  },
];

@NgModule({
  declarations: [
    HomeComponent,
  ],
  imports: [
    CorePipesModule,
    RouterModule.forChild(routes),
    CommonModule,
    CoreCommonModule,
    ContentHeaderModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    DataTablesModule,
    SweetAlert2Module,
    NgbPaginationModule,
    NgxDatatableModule,
    //page modules
    AuthenticationModule,
    MiscellaneousModule,
    CmsModule,
    GeneralModule,
    AdminModule,
    CustomerModule,
    ItemManageModule,
    DocumentModule,
    StockManageModule,
    FrontendModule,
    DashboardModule,
    MLMConfigurationModule,
    CodeManageModule,
    NotificationModule,
  ],
  exports: [HomeComponent],
  providers: [DragulaService, CurrencyPipe, DecimalPipe],
})
export class PagesModule {}
