import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemBonusTableComponent } from './system-bonus-table.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { DataTablesModule } from 'angular-datatables';
import { CoreDirectivesModule } from '@core/directives/directives';
import { TranslateModule } from '@ngx-translate/core';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreCommonModule } from '@core/common.module';

const path: string = 'mlm-config/system-bonus-table-config';
const routes: Routes = [
  {
    path: path,
    component: SystemBonusTableComponent,
    canActivate: [AuthGuard],
    data: { animation: 'mlm-system-bonus-plan' },
  },
];

@NgModule({
  declarations: [SystemBonusTableComponent],
  imports: [
    CommonModule,
    CoreCommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    ContentHeaderModule,
    DataTablesModule,
    CoreDirectivesModule,
    TranslateModule
  ],
})
export class SystemBonusModule {}
