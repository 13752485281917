import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import BonusPlan from 'app/main/model/BonusPlan';

@Component({
  selector: 'app-bonus-plan',
  templateUrl: './bonus-plan.component.html',
  styleUrls: ['./bonus-plan.component.scss']
})
export class BonusPlanComponent implements OnInit, OnDestroy {
  config: ICMSConfig = {
    apiPath: 'helpcenterbonusplan',
    pathUrl: '/cms/bonus-plan',
    tableName: 'Content',
    model: new BonusPlan(),
    isList: false,
    componentName: 'Bonus-Plan',
    formConfig: {
      personalSaleTitle: {
        type: FormType.Text,
        validator: [Validators.required],
        isMultipleLang: true,
        label: 'Personal Sale Title',
        description: 'Title',
        labelTranslate: 'Informative.HelpCenter.PersonalSaleTitle.Label',
        descriptionTranslate: 'Informative.HelpCenter.PersonalSaleTitle.Description',
      },
      personalSaleDescription: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Personal Sale Description',
        description: 'Description',
        labelTranslate: 'Informative.HelpCenter.PersonalSaleDescription.Label',
        descriptionTranslate: 'Informative.HelpCenter.PersonalSaleDescription.Description',
      },
      downlineSaleTitle: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Downline Sale Title',
        description: 'Title',
        labelTranslate: 'Informative.HelpCenter.DownlineSaleTitle.Label',
        descriptionTranslate: 'Informative.HelpCenter.DownlineSaleTitle.Description',
      },
      downlineSaleDescription: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Downline Sale Description',
        description: 'Description',
        labelTranslate: 'Informative.HelpCenter.DownlineSaleDescription.Label',
        descriptionTranslate: 'Informative.HelpCenter.DownlineSaleDescription.Description',
      },
      systemBonusTitle: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'System Bonus Title',
        description: 'Title',
        labelTranslate: 'Informative.HelpCenter.SystemBonusTitle.Label',
        descriptionTranslate: 'Informative.HelpCenter.SystemBonusTitle.Description',
      },
      systemBonusDescription: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'System Bonus Description',
        description: 'Description',
        labelTranslate: 'Informative.HelpCenter.SystemBonusDescription.Label',
        descriptionTranslate: 'Informative.HelpCenter.SystemBonusDescription.Description',
      },
      //   personalBonusTableImage: {
      //     type: FormType.SingleImage,
      //     validator: [Validators.required],
      //     label: 'Personal Bonus Table',
      //     description: 'Input an image',
      //     labelTranslate: 'Informative.HelpCenter.PersonalBonusTable.Label',
      //     descriptionTranslate: 'Informative.HelpCenter.PersonalBonusTable.Description',
      //   },
      //   downlineBonusTableImage: {
      //     type: FormType.SingleImage,
      //     validator: [Validators.required],
      //     label: 'Downline Bonus Table',
      //     description: 'Input an image',
      //     labelTranslate: 'Informative.HelpCenter.DownlineBonusTable.Label',
      //     descriptionTranslate: 'Informative.HelpCenter.DownlineBonusTable.Description',
      //   },
      // systemBonusTableImage: {
      //     type: FormType.SingleImage,
      //     validator: [Validators.required],
      //     label: 'System Bonus Table',
      //     description: 'Input an image',
      //     labelTranslate: 'Informative.HelpCenter.SystemBonusTable.Label',
      //     descriptionTranslate: 'Informative.HelpCenter.SystemBonusTable.Description',
      //   },
      // overAllTableImage: {
      //     type: FormType.SingleImage,
      //     validator: [Validators.required],
      //     label: 'Overall Bonus Table',
      //     description: 'Input an image',
      //     labelTranslate: 'Informative.HelpCenter.OverallBonusTable.Label',
      //     descriptionTranslate: 'Informative.HelpCenter.OverallBonusTable.Description',
      //   },
    },
  };

  constructor() { }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {

  }

}
