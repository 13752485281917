<section id="apex-column-charts">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="d-flex justify-content-center align-items-center my-2">
          <div class="mr-1">
            <label>{{ "Stock.From" | translate }}:</label>
          </div>
          <select class="col-2 custom-select mx-1" [(ngModel)]="selectedStartYear" (change)="onStartYearChange()">
            <option *ngFor="let year of yearList" [value]="year.toString()">
              {{ year }}
            </option>
          </select>
          <select class="col-2 custom-select mx-0" [disabled]="!selectedStartYear" [(ngModel)]="selectedStartMonth"
            (change)="onStartMonthChange()">
            <option *ngFor="let month of months" [value]="month.value.toString()">
              <span *ngIf="currentLang == 'en'">
                {{ month.nameEN }}
              </span>
              <span *ngIf="currentLang == 'th'">
                {{ month.nameTH }}
              </span>
            </option>
          </select>


          <div class="ml-3">
            <label>{{ "Stock.To" | translate }}:</label>
          </div>
          <select class="col-2 custom-select mx-1" [disabled]="!selectedStartYear" [(ngModel)]="selectedEndYear"
            (change)="onYearChange()">
            <option *ngFor="let year of selectedEndYearOptions" [value]="year.toString()">
              {{ year }}
            </option>
          </select>
          <select class="col-2 custom-select mx-0" [disabled]="!selectedStartYear" [(ngModel)]="selectedEndMonth"
            (change)="onMonthChange()">
            <option *ngFor="let month of selectedEndMonthOptions" [value]="month.value">
              <span *ngIf="currentLang == 'en'">
                {{ month.nameEN }}
              </span>
              <span *ngIf="currentLang == 'th'">
                {{ month.nameTH }}
              </span>
            </option>
          </select>
        </div>

        <div class="d-flex justify-content-center">
          <button class="btn btn-secondary" (click)="resetSearchCriteria()"><i data-feather="refresh-ccw"
              class="mr-1"></i>{{ "General.Reset" | translate }}</button>

          <button (click)="submitDate()" class="btn btn-primary mx-1" style="margin-left: 0.5rem">
            <i data-feather="search" class="mr-1"></i>{{ "General.Search" | translate }}
          </button>
        </div>

        <hr>
        <div class="d-flex justify-content-end align-items-center">
          <button class="btn btn-primary" (click)="exportToExcel()">
            <span>{{ "General.Export" | translate }}</span>
          </button>
        </div>
        <div class="card-body px-0">
          <div id="column-chart" #apexLineChartRef>
            <apx-chart [series]="apexLineChart.series" [chart]="apexLineChart.chart" [xaxis]="apexLineChart.xaxis"
              [yaxis]="apexLineChart.yaxis" [stroke]="apexLineChart.stroke" [tooltip]="apexLineChart.tooltip"
              [colors]="apexLineChart.colors" [legend]="apexLineChart.legend" [dataLabels]="apexLineChart.dataLabels"
              [plotOptions]="apexLineChart.plotOptions" [grid]="apexLineChart.grid" [noData]="apexLineChart.noData"></apx-chart>
          </div>
          <div id="column-chart" #apexColumnChartRef>
            <apx-chart [series]="apexColumnChart.series" [chart]="apexColumnChart.chart" [xaxis]="apexColumnChart.xaxis"
              [yaxis]="apexColumnChart.yaxis" [stroke]="apexColumnChart.stroke" [tooltip]="apexColumnChart.tooltip"
              [colors]="apexColumnChart.colors" [legend]="apexColumnChart.legend"
              [dataLabels]="apexColumnChart.dataLabels" [plotOptions]="apexColumnChart.plotOptions"
              [grid]="apexColumnChart.grid" [noData]="apexColumnChart.noData"></apx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="d-flex justify-content-center align-items-center my-2">
          <label>{{'Dashboard.Revenue.Target' | translate}}:</label>
          <select class="col-2 custom-select mx-1" [(ngModel)]="targetSelect">
            <option *ngFor="let target of targetType" [value]="target.value.toString()">
              {{ 'Dashboard.Revenue.' + target.name | translate}}
            </option>
          </select>
          <label>{{'Dashboard.Revenue.Year' | translate}}:</label>
          <select class="col-2 custom-select mx-1" [(ngModel)]="targetYear">
            <option *ngFor="let year of targetYearList" [value]="year.toString()">
              {{ year }}
            </option>
          </select>
          <button (click)="getTargetResultListByYear(targetYear)" class="btn btn-primary mx-1"
            style="margin-left: 0.5rem">
            <i data-feather="search" class="mr-1"></i>{{ "General.Search" | translate }}
          </button>
        </div>

        <div class="d-flex justify-content-center align-items-center my-2">
          <h4>
            {{ 'Dashboard.Revenue.' + currentTable | translate}} {{currentYearTable}}
          </h4>
        </div>

        <div class="d-flex justify-content-center align-items-center my-2">
          <table class="target-table col-12">
            <thead>
              <th class="p-1">{{ 'Dashboard.Revenue.Month' | translate}}</th>
              <th>{{ 'Dashboard.Revenue.TargetValue' | translate}}</th>
              <th>{{ 'Dashboard.Revenue.Classifier' | translate}}</th>
              <th>{{ 'General.Action' | translate}}</th>
            </thead>
            <tbody>
              <tr *ngFor="let month of months; index as i; ">
                <td class="p-1">{{getMonthName(i)}}</td>
                <td>
                  <input class="form-control text-right col-6" type="text" [formControl]="form.controls['targetValue']"
                    *ngIf="isEditData== true && i === selectedRowIndex" />
                  <div *ngIf="i !== selectedRowIndex && isEditData == false">{{ tableListData[i].targetValue |
                    number:'1.0-0'}}</div>
                  <div *ngIf="i !== selectedRowIndex && isEditData == true">{{ tableListData[i].targetValue |
                    number:'1.0-0'}}</div>
                </td>
                <td>{{ 'Dashboard.Revenue.' + classifier| translate}}</td>
                <td>
                  <span style="display: inline-block;">
                    <a class="btn btn-icon btn-icon rounded-circle btn-outline-warning"
                      *ngIf="isEditData == false && checkEditCondition(tableListData[i].endDate)" (click)="editData(i)">
                      <i data-feather="edit" class="text-warning"></i>
                    </a>
                    <a class="btn btn-icon btn-icon rounded-circle btn-outline-secondary pointer-event-none"
                      *ngIf="!checkEditCondition(tableListData[i].endDate) && isEditData == false"
                      (click)="editData(i)">
                      <i data-feather="edit" class="text-secondary"></i>
                    </a>
                    <a class="btn btn-icon btn-icon rounded-circle btn-outline-secondary pointer-event-none"
                      *ngIf="i !== selectedRowIndex && isEditData == true" (click)="editData(i)">
                      <i data-feather="edit" class="text-secondary"></i>
                    </a>
                  </span>

                  <span style="display: inline-block; margin-right: 5px;">
                    <a class="btn btn-icon btn-icon rounded-circle btn-outline-success"
                      *ngIf="i === selectedRowIndex && isEditData == true" (click)="openUpdateDataModal(i)">
                      <i data-feather="save" class="text-success"></i>
                    </a>
                  </span>

                  <span style="display: inline-block; margin-right: 5px;">
                    <a class="btn btn-icon btn-icon rounded-circle btn-outline-warning"
                      *ngIf="i === selectedRowIndex && isEditData == true" (click)="cancelEdit(i)"
                      disabled="{{i !== selectedRowIndex && isEditData == true}}">
                      <i data-feather="x-square" class="text-warning"></i>
                    </a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>


</section>