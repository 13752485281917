<div class="d-flex justify-content-between align-items-center my-1">
  <h4>{{ 'Settings.DefaultBankAccount' | translate }}</h4>
</div>

<table class="table">
  <thead>
    <tr>
      <th class="text-center" width="3%">#</th>
      <th class="text-center" width="30%">
        {{ "Payment.Modal.BankAccount.AccountName.Label" | translate }}
      </th>
      <th class="text-center" width="20%">
        {{ "Payment.Modal.BankAccount.Bank.Label" | translate }}
      </th>
      <th class="text-center" width="20%">
        {{ "Payment.Modal.BankAccount.AccountNumber.Label" | translate }}
      </th>
      <th class="text-center" width="15%">
        {{ "Payment.Modal.BankAccount.BankAccountType.Label" | translate }}
      </th>
      <th width="10%" class="text-center" width="15%">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr class="w-100" *ngIf="isLoading">
      <td class="text-center" *ngFor="let num of [].constructor(6)">
        <div class="skeleton-loading"></div>
      </td>
    </tr>

    <tr *ngIf="defaultBankList.length < 1 && !isLoading">
      <td colspan="100%" class="text-center">
        <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
      </td>
    </tr>

    <tr *ngFor="let bank of defaultBankList; index as ind">
      <td class="text-center">{{ ind + 1 }}</td>
      <td class="text-primary">
        <a (click)="openManagePaymentModal('editBankAccount', bank)"
          >{{ bank.accountName }}
          <div class="badge badge-secondary">
            {{ "General.Default" | translate }}
          </div></a
        >
      </td>
      <td>{{ bank.bankName }}</td>

      <td class="text-center">
        <a (click)="copyCode(bank.accountNumber)">
          {{ bank.accountNumber }}
        </a>
      </td>
      <td class="text-center">{{ bank.bankAccountTypeName }}</td>
      <td class="text-center d-flex justify-content-center">
        <a
          class="btn btn-icon btn-icon rounded-circle btn-outline-danger mr-50"
          (click)="openRemoveDefaultModal(bank.id)"
          ><i data-feather="x" class="text-danger"></i
        ></a>
      </td>
    </tr>
  </tbody>
</table>

<hr />

<div class="d-flex justify-content-between align-items-center mb-1">
  <h4>{{ 'Settings.AllBankAccount' | translate }}</h4>
  <button
    class="btn btn-primary"
    (click)="onOpenAddBankAccountModal('addBankAccount')"
  >
    {{ "Payment.Modal.BankAccount.AddBankAccount" | translate }}
  </button>
</div>

<table class="table bank-account-table">
  <thead>
    <tr>
      <th class="text-center" width="3%">#</th>
      <th class="text-center" width="30%">
        {{ "Payment.Modal.BankAccount.AccountName.Label" | translate }}
      </th>
      <th class="text-center" width="20%">
        {{ "Payment.Modal.BankAccount.Bank.Label" | translate }}
      </th>
      <th class="text-center" width="20%">
        {{ "Payment.Modal.BankAccount.AccountNumber.Label" | translate }}
      </th>
      <th class="text-center" width="15%">
        {{ "Payment.Modal.BankAccount.BankAccountType.Label" | translate }}
      </th>
      <th width="10%" class="text-center" width="10%">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr class="w-100" *ngIf="isLoading">
      <td class="text-center" *ngFor="let num of [].constructor(6)">
        <div class="skeleton-loading"></div>
      </td>
    </tr>

    <tr *ngIf="allBankList.length < 1 && !isLoading">
      <td colspan="100%" class="text-center">
        <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
      </td>
    </tr>

    <tr *ngFor="let bank of allBankList; index as ind">
      <td class="text-center">{{ ind + 1 }}</td>
      <td class="text-primary">
        <a (click)="openManagePaymentModal('editBankAccount', bank)">{{
          bank.accountName
        }}</a>
      </td>
      <td>{{ bank.bankName }}</td>

      <td class="text-center">
        <a (click)="copyCode(bank.accountNumber)">
          {{ bank.accountNumber }}
        </a>
      </td>
      <td class="text-center">{{ bank.bankAccountTypeName }}</td>
      <td class="text-center d-flex justify-content-center">
        <button
          class="btn btn-icon btn-icon rounded-circle btn-outline-success mr-50"
          (click)="openSetDefaultModal(bank.id)"
          *ngIf="countDefaultBank < 2"
        >
          <i data-feather="bookmark" class="text-success"></i>
        </button>
        <a
          class="btn btn-icon btn-icon rounded-circle btn-outline-warning mr-50"
          (click)="openManagePaymentModal('editBankAccount', bank)"
          ><i data-feather="edit"></i
        ></a>
        <a
          class="btn btn-icon btn-icon rounded-circle btn-outline-danger mr-50"
          [ngClass]="{
            'd-none': bank.isDelete
          }"
          (click)="openDeleteModal(bank)"
          ><i data-feather="trash-2" class="text-danger"></i
        ></a>
      </td>
    </tr>
  </tbody>
</table>
