<div class="row">
  <div class="col-md-12">
    <form [formGroup]="registerForm">
      <h4 class="mb-2">{{ componentName }} Name</h4>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{
          "Form.Name" | translate
        }}</label>
        <div class="col-sm-9">
          <input class="form-control" type="text" formControlName="name" />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block': f.name.invalid && (f.name.dirty || f.name.touched)
            }"
          >
            <div>
              <div *ngIf="f.name.errors?.required">Name is required.</div>
              <div *ngIf="f.name.errors?.maxlength">
                Name cannot exceed 50 characters.
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <h4 class="mb-2">{{ componentName }} Content</h4>
      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-3 col-form-label"
          >Title</label
        >
        <div class="col-sm-9">
          <input class="form-control" type="text" formControlName="title" />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block': f.title.invalid && (f.title.dirty || f.title.touched)
            }"
          >
            <div>
              <div *ngIf="f.title.errors?.required">Title is required.</div>
              <div *ngIf="f.title.errors?.maxlength">
                Title cannot exceed 100 characters.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-3 col-form-label"
          >Subtitle</label
        >
        <div class="col-sm-9">
          <input class="form-control" type="text" formControlName="subTitle" />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                f.subTitle.invalid && (f.subTitle.dirty || f.subTitle.touched)
            }"
          >
            <div>
              <div *ngIf="f.subTitle.errors?.required">
                subTitle is required.
              </div>
              <div *ngIf="f.subTitle.errors?.maxlength">
                subTitle cannot exceed 100 characters.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-3 col-form-label"
          >Detail</label
        >
        <div class="col-sm-9">
          <textarea
            class="form-control"
            [ngxSummernote]="summernoteConfig"
            formControlName="detail"
          ></textarea>
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                f.detail.invalid && (f.detail.dirty || f.detail.touched)
            }"
          >
            <div>
              <div *ngIf="f.detail.errors?.required">detail is required.</div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-12"></div>
        <label for="colFormLabelLg" class="col-sm-3 col-form-label"
          >Date Picker</label
        >
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-6">
              <label for="dateTo">Date from</label>
              <ng2-flatpickr
                [setDate]="dateFromOptions.defaultDate"
                [config]="dateFromOptions"
                formControlName="dateFrom"
              ></ng2-flatpickr>
              <div
                class="invalid-feedback"
                [ngClass]="{
                  'd-block':
                    f.dateFrom.invalid &&
                    (f.dateFrom.dirty || f.dateFrom.touched)
                }"
              >
                <div>
                  <div *ngIf="f.dateFrom.errors?.required">
                    dateFrom is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <label for="dateTo">Date to </label>
              <ng2-flatpickr
              
                [setDate]="dateToOptions.defaultDate"
                [config]="dateToOptions"
                formControlName="dateTo"
              ></ng2-flatpickr>
              <div
                class="invalid-feedback"
                [ngClass]="{
                  'd-block':
                    f.dateTo.invalid && (f.dateTo.dirty || f.dateTo.touched)
                }"
              >
                <div>
                  <div *ngIf="f.dateTo.errors?.required">
                    dateTo is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 mt-1">
              <label for="contentDate">Content date</label>
              <ng2-flatpickr
                [setDate]="dateTimeOptions.defaultDate"
                [config]="dateTimeOptions"
                formControlName="contentDate"
              ></ng2-flatpickr>
              <div
                class="invalid-feedback"
                [ngClass]="{
                  'd-block':
                    f.contentDate.invalid &&
                    (f.contentDate.dirty || f.contentDate.touched)
                }"
              >
                <div>
                  <div *ngIf="f.contentDate.errors?.required">
                    contentDate is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-3 col-form-label"
          >Category</label
        >
        <div class="col-sm-9">
          <div class="form-group">
            <label for="selectLarge"
              >Select Large {{ f.contentCategoryId.value }}</label
            >
            <select class="custom-select" formControlName="contentCategoryId">
              <option
                [value]="group.id"
                *ngFor="let group of GroupObjList"
                [selected]="group.id == f.contentCategoryId.value"
              >
                {{ group.name }}
              </option>
            </select>

            <div
              class="invalid-feedback"
              [ngClass]="{
                'd-block':
                  f.contentCategoryId.invalid &&
                  (f.contentCategoryId.dirty || f.contentCategoryId.touched)
              }"
            >
              <div>
                <div *ngIf="f.contentCategoryId.errors?.required">
                  contentCategoryId is required.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-3 col-form-label">Link</label>
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-4">
              <input
                class="form-control"
                type="text"
                formControlName="linkBtnCaption"
                placeholder="Caption (link)"
              />
              <div
                class="invalid-feedback"
                [ngClass]="{
                  'd-block':
                    f.linkBtnCaption.invalid &&
                    (f.linkBtnCaption.dirty || f.linkBtnCaption.touched)
                }"
              >
                <div>
                  <div *ngIf="f.linkBtnCaption.errors?.required">
                    linkBtnCaption is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <input
                class="form-control"
                type="text"
                formControlName="linkBtnUrl"
                placeholder="https://"
              />
              <div
                class="invalid-feedback"
                [ngClass]="{
                  'd-block':
                    f.linkBtnUrl.invalid &&
                    (f.linkBtnUrl.dirty || f.linkBtnUrl.touched)
                }"
              >
                <div>
                  <div *ngIf="f.linkBtnUrl.errors?.required">
                    linkBtnUrl is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div class="d-flex justify-content-between">
        <a class="btn btn-secondary" (click)="openCancelModal(itemObj)">
          {{ "Form.Cancel" | translate }}
        </a>

        <div class="d-flex justify-content-between align-items-center">
          <div class="custom-control custom-checkbox mr-2">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customCheck1"
              formControlName="isActive"
              [checked]="f.isActive.value"
            />
            <label class="custom-control-label" for="customCheck1"
              >Active now</label
            >
          </div>
          <a class="btn btn-primary" (click)="openConfirmModal(itemObj)">
            {{ "Form.Submit" | translate }}
          </a>
        </div>
      </div>
    </form>
  </div>
</div>
