import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BonusPlanComponent } from './bonus-plan.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { CmsFormpageModule } from '../../cms-formpage/cms-formpage.module';

const path: string = 'cms/repove-tools/bonus-plan';
const routes: Routes = [
  {
    path: path,
    component: BonusPlanComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-repove-tools-bonus-plan' },
  },
];

@NgModule({
  declarations: [BonusPlanComponent],
  imports: [CommonModule, RouterModule.forChild(routes), CmsFormpageModule],
})
export class BonusPlanModule {}
