import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexStroke,
  ApexDataLabels,
  ApexXAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexPlotOptions,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexTheme,
  ApexNonAxisChartSeries,
  ApexLegend,
  ApexResponsive,
  ApexStates,
  ApexNoData,
} from 'ng-apexcharts';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { MpRevenueChartComponent } from './mp-revenue-chart/mp-revenue-chart.component';
import { MemberChartComponent } from './member-chart/member-chart.component';
import { RevenueChartComponent } from './revenue-chart/revenue-chart.component';
import { YearOnYearChartComponent } from './year-on-year-chart/year-on-year-chart.component';

export interface ChartOptions {
  series?: CustomApexAxisChartSeries;
  chart?: ApexChart;
  xaxis?: ApexXAxis;
  dataLabels?: ApexDataLabels;
  grid?: ApexGrid;
  stroke?: ApexStroke;
  legend?: ApexLegend;
  title?: ApexTitleSubtitle;
  colors?: string[];
  tooltip?: ApexTooltip;
  plotOptions?: ApexPlotOptions;
  yaxis?: ApexYAxis | ApexYAxis[];
  fill?: ApexFill;
  labels?: string[];
  markers: ApexMarkers;
  theme: ApexTheme;
  noData?: ApexNoData;
}

export declare type CustomApexAxisChartSeries = {
  name?: string;
  type?: string;
  group?: string;
  color?: string;
  data:
    | (number | null)[]
    | {
        x: any;
        y: any;
        fill?: ApexFill;
        fillColor?: string;
        strokeColor?: string;
        meta?: any;
        goals?: any;
        barHeightOffset?: number;
        columnWidthOffset?: number;
      }[]
    | [number, number | null][]
    | [number, (number | null)[]][]
    | number[][];
}[];

@Component({
  selector: 'app-executive-summary',
  templateUrl: './executive-summary.component.html',
  styleUrls: ['./executive-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExecutiveSummaryComponent
  implements OnInit, AfterViewInit, AfterContentInit, OnDestroy
{
  contentHeader: object;
  currentLang: string;

  @BlockUI() blockUI: NgBlockUI;

  @ViewChild('apexColumnChartRef') apexColumnChartRef: any;

  @ViewChild('MPRevenueChart') MPRevenueChartRef: MpRevenueChartComponent;
  @ViewChild('MemberChart') MemberChartRef: MemberChartComponent;
  @ViewChild('RevenueChart') RevenueChartRef: RevenueChartComponent;
  @ViewChild('YearOnYearChart') YearOnYearChartRef: YearOnYearChartComponent;
  @ViewChild('ConversionRateChart')
  ConversionRateChartRef: MemberChartComponent;

  public apexColumnChart: Partial<ChartOptions>;

  constructor(
    private _translateService: TranslateService,
    private _coreConfigService: CoreConfigService
  ) {}

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Dashboard.ExecutiveSummaryDashboard',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  ngAfterViewInit(): void {
    // this.blockUI.start()
    this.CallDrawCharts();
    // this.blockUI.stop()
  }

  ngAfterContentInit(): void {
    // this.CallDrawCharts();
  }

  ngOnDestroy(): void {
    this.blockUI.stop();
    this.MPRevenueChartRef.ngOnDestroy();
    this.MemberChartRef.ngOnDestroy();
    this.RevenueChartRef.ngOnDestroy();
    this.YearOnYearChartRef.ngOnDestroy();
    this.ConversionRateChartRef.ngOnDestroy();
  }

  CallDrawCharts() {
    setTimeout(() => {
      this.blockUI.stop();
      this.MPRevenueChartRef.DrawChart();
      this.MemberChartRef.DrawChart();
      this.RevenueChartRef.DrawChart();
      this.YearOnYearChartRef.DrawChart();
      this.ConversionRateChartRef.DrawChart();
    }, 0);
  }
}
