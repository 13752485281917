import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import ShoppingCart from 'app/main/model/ShoppingCart';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
})
export class ShoppingCartComponent implements OnInit {
  config: ICMSConfig = {
    apiPath: 'ShoppingCart',
    tableName: 'Content',
    pathUrl: '/cms/shopping-cart',
    model: new ShoppingCart(),
    isList: false,
    componentName: 'Shopping Cart',
    formConfig: {
      bannerPaymentDescription: {
        type: FormType.Text,
        validator: [Validators.required],
        isMultipleLang: true,
        label: 'Banner Text',
        labelTranslate: 'Informative.Home.WhoWeAreQuote.Label',
        description: 'Text that show on banner',
        descriptionTranslate: 'Informative.Home.WhoWeAreQuote.Description',
      },
      bannerPayment: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        isMultipleLang: false,
        label: 'Banner Background',
        labelTranslate: 'Informative.Home.WhoWeAreDescription.Label',
        description: 'Background',
        descriptionTranslate:
          'Informative.Home.WhoWeAreDescription.Description',
      },
    },
  };

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
