import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventComponent } from './event.component';
import { AuthGuard } from 'app/auth/helpers';
import { RouterModule } from '@angular/router';
import { EventCreateComponent } from './event-create/event-create.component';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';
import { CmsListModule } from '../cms-list/cms-list.module';

const path: string = 'cms/event';
const routes = [
  {
    path: `${path}`,
    component: EventComponent,
    canActivate: [AuthGuard],
    data: { animation: 'event-manage' },
  },
  {
    path: `${path}/create`,
    component: EventCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'event-create' },
  },
  {
    path: `${path}/edit/:id`,
    component: EventCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'event-edit' },
  },
];
@NgModule({
  declarations: [EventComponent, EventCreateComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CmsFormpageModule,
    CmsListModule,
  ],
  exports: [EventComponent, EventCreateComponent],
})
export class EventModule {}
