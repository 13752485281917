import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CodeManageService {
  componentName: string = 'General.PromotionCode';
  apiUrl: string = 'PromotionCode';
  pathUrl: string = '/code-manage/promotion-code';

  promotionCode = {
    ApiPath: 'PromotionCode',
    PathURL: '/code-manage/promotion-code',
    EditPathURL: '/code-manage/promotion-code/edit',
    DetailPathURL: '/code-manage/promotion-code/detail',
    DetailExport: '/code-manage/promotion-code/detail/export',
    ComponentsName: 'PromotionCode',
  };
  

  // itemcomponentName: string = 'Item';
  // itemApiUrl: string = 'item';
  // itemPathUrl: string = '/item-manage/item';
  constructor() { }
}
