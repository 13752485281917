import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';

import { Subject, Subscription } from 'rxjs';
import { UserService } from './users.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { UsersApiService } from 'app/main/service/users-api.service';
import { ApiService } from 'app/main/service/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { ComponentsService } from 'app/main/components/components.service';
import * as XLSX from 'xlsx';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { FilesApiService } from 'app/main/service/files-api.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UsersComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject();
  private dataSubscription: Subscription;

  componentName: string;
  apiPath: string;
  pathUrl: string;
  contentHeader: object;
  isLoading: boolean;

  isGridView: boolean;
  isForGroupTemplate: boolean;
  searchText: string;
  activeStatus: string;

  currentLang: string;

  selectedOption;

  userList: User[] = [];
  roleSetList: any[] = [];

  searchData: any = {};

  defaultIMG: string = environment.defaultIMG

  public page = 1;
  public pageSize = 12;

  public rows;

  columnToExportXLSX = [
    'id',
    'username',
    'userCode',
    'roleSetId',
    'roleSetName',
    'coverMediaFile',
    'mediaFiles',
    'email',
    'firstName',
    'lastName',
    'isCompanyUser',
    'socialMediaLink',
    'namePrefixId',
    'genderId',
    'personalId',
    'organization',
    'branch',
    'department',
    'position',
    'phone',
    'businessTypeId',
    'bankId',
    'cartId',
    'lastLogin',
    'isActive',
    'isDelete',
    'isVerify',
    'createBy',
    'createDate',
    'updateBy',
    'updateDate',
  ];

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _userService: UserService,
    private _userApiService: UsersApiService,
    private _router: Router,
    private _apiService: ApiService,
    private _translateService: TranslateService,
    private _modalService: NgbModal,
    private _componentsService: ComponentsService,
    private _fileApiService: FilesApiService
  ) {
    this.SetLoadingState();
    this._unsubscribeAll = new Subject();

    this.componentName = this._userService.componentName;
    this.apiPath = this._userService.apiPath;
    this.pathUrl = this._userService.pathUrl;

    this.currentLang = this._translateService.currentLang;
    this.initData();
  }

  initData(): void {
    // this.getAllUser();
    // this.getRoleSet();
    this.searchData = {
      SortPath: 'Username',
      Direction: 0,
      isDelete: false,
    };
  }

  getAllUser(obj?: { [key: string]: any }) {
    var self = this;
    this.SetLoadingState();
    this.userList = [];

    if (obj) {
      this.searchText = obj.searchText ? obj.searchText : '';

      if (!obj.params) {
        this.searchData = {
          SortPath: 'Username',
          Direction: 0,
          isDelete: false,
        };
      } else {
        if ((this.searchData.SortPath = 'Name')) {
          this.searchData.SortPath = 'Username';
        }
        this.searchData = obj.params;
      }
    } else {
      this.searchText = '';
      this.searchData = {
        SortPath: 'Username',
        Direction: 0,
        isDelete: false,
      };
    }
    this.searchData.Username = this.searchText
    this.loadData(this.searchData);
  }

  loadData(searchData?: any) {
    if (searchData) {
      this.searchData = searchData;
    } else {
      this.searchData = {
        SortPath: 'Username',
        Direction: 0,
        isDelete: false,
      };
    }

    if (this.dtTrigger) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          this.SetLoadedState();
        });
      });
    }
  }

  ngOnInit(): void {
    var self = this;
    this.contentHeader = {
      headerTitle: 'BreadCrumbs.Manage.User',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: 10,
      order: [[1, 'asc']],
      ordering: true,
      serverSide: true,
      lengthMenu: [10, 25, 50, 100],
      lengthChange: true,
      dom: '<"float-left"B><"float-right"f>rt<"row"<"col-sm-4"i><"col-sm-4 text-center"l><"col-sm-4"p>>',
      columnDefs: [
        { orderable: false, targets: 0 },
        { name: 'Username', targets: 1 },
        { name: 'RoleSetName', targets: 2 },
        { name: 'FirstName', targets: 3 },
        { name: 'IsActive', targets: 5 },
        { name: 'CreateDate', targets: 6 },
        { orderable: false, targets: 7 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.userList = [];
        self.SetLoadingState();

        let defaultSortName = 'Username';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        } else {
          self.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.searchData.Direction = order?.dir == 'asc' ? 0 : 1;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.searchData.PageLength = dataTablesParameters.length;
        this.searchData.isDelete = false;

        console.log(this.searchData);
        this._apiService
          .GetAllData(this.apiPath, this.searchData)
          .subscribe((response) => {
            console.log(response);
            this.userList = response.data.resultData;
            self.SetLoadedState();
            callback({
              recordsTotal: response.data.itemCount,
              recordsFiltered: response.data.totalItem,
              data: [],
            });
          });
      },
    };
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  getRoleSet(): void {
    this._apiService
      .GetAllData('roleSet')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.roleSetList = res.data.resultData;
      });
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  listView() {
    this.isGridView = false;
  }

  gridView() {
    this.isGridView = true;
  }

  SearchFilter(val: string) {
    this.searchText = val;
    this.isLoading = false;
  }

  ActiveStatusFilter(val: string) {
    this.activeStatus = val;
    console.log(val);
  }

  exportToXLSX(jsonData: any[], fileName: string, columns: string[]) {
    const filteredData = jsonData.map((obj) => {
      const filteredObj = {};
      columns.forEach((key) => {
        filteredObj[key] = obj[key];
      });
      return filteredObj;
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    // Calculate the maximum width for each column
    const columnWidths: { [key: string]: number } = {};
    filteredData.forEach((obj) => {
      columns.forEach((key) => {
        const value = obj[key] ? String(obj[key]) : '';
        const width = value.length;
        if (!columnWidths[key] || width > columnWidths[key]) {
          columnWidths[key] = width;
        }
      });
    });

    // Apply the column widths to the worksheet
    const columnKeys = Object.keys(columnWidths);
    columnKeys.forEach((key, index) => {
      const columnWidth = columnWidths[key];
      const column = worksheet[`!cols`] ? worksheet[`!cols`][index] || {} : {};
      column.wch = columnWidth;
      if (!worksheet[`!cols`]) {
        worksheet[`!cols`] = [];
      }
      worksheet[`!cols`][index] = column;
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    XLSX.writeFile(workbook, fileName);
  }

  openDeleleUserModal(itemObj: User, status: boolean): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.title = this._translateService.instant(
      'ManageUser.DeleteTitle'
    );
    modalRef.componentInstance.detail =
      this._translateService.instant('ManageUser.Delete') +
      ` (${itemObj.username})`;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.deleteUser(itemObj.id);
    });
  }

  deleteUser(guid: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._userApiService.SoftDelete(guid).subscribe((res) => {
        if (res.data && res.success) {
          self.initData();
          self._componentsService.SuccessSwal();
        } else {
          self._componentsService.ErrorSwal();
        }
      });
    });
  }

  openIsActiveModal(itemObj, status): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    let modeText = status
      ? this._translateService.instant('Status.Active')
      : this._translateService.instant('Status.Inactive');
    modalRef.componentInstance.title = `${modeText}`;
    modalRef.componentInstance.detail =
      this._translateService.instant('ManageUser.Active') +
      `${modeText} (${itemObj.username})`;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.setIsActiveUser({ id: itemObj.id, status: status });
    });
  }

  setIsActiveUser(val): void {
    this._apiService
      .SetIsActiveData(this.apiPath, val.id, val.status)
      .subscribe(
        (res) => {
          this._componentsService.SuccessSwal();
          this.isLoading = true;
          this.initData();
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
  }

  openResetPasswordModal(itemObj): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = this._translateService.instant(
      'ManageUser.ResetTitle'
    );
    modalRef.componentInstance.detail =
      this._translateService.instant('ManageUser.Reset') +
      ` (${itemObj.username})`;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.resetPassword(itemObj.id);
    });
  }

  resetPassword(guid: string): void {
    var self = this;
    this._userApiService.ResetPasswordByID(guid).subscribe(
      (res) => {
        self._componentsService.SuccessSwal();
      },
      (err) => {
        self._componentsService.ErrorSwal();
      }
    );
  }

  onSubmit() {}

  getImages(guid: string, params): string {
    return this._fileApiService.getImage(guid, params);
  }
}
