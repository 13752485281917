<div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
    <app-loading></app-loading>
</div>

<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <a [routerLink]="[pathUrl]">
                    <i data-feather="arrow-left" class="text-primary cursor-pointer back-to-manage-btn"></i>
                    {{ 'ProductDetailForm.Product' | translate }}
                </a>

                <ul ngbNav class="nav nav-pills">
                    <li ngbNavItem *ngFor="let lang of langList">
                        <a ngbNavLink (click)="selectedLanguage(lang)" [class.active]="selectedLang === lang">{{ lang |
                            uppercase
                            }}</a>
                    </li>
                </ul>
            </div>

            <div class="lang-switcher-sidebar nav-vertical">
                <ul ngbNav class="nav nav-pills flex-column text-nowrap mb-0">
                    <li ngbNavItem *ngFor="let lang of langList">
                        <a ngbNavLink (click)="selectedLanguage(lang)" [class.active]="selectedLang === lang">{{ lang |
                            uppercase
                            }}</a>
                    </li>
                </ul>
            </div>
            <!-- Form -->
            <div class="card-body">
                <div class="col-md-12">
                    <form [formGroup]="ProductDetailForm">
                        <h4 class="mb-2">{{ "ProductDetailForm.ProductDetail" | translate }}</h4>

                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label for="colFormLabelLg">
                                    {{ "ProductDetailForm.ItemIdName" | translate }}
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-sm-9">
                                <select class="form-control" formControlName="ItemId" rows="5" [(ngModel)]="itemSelect"
                                    [ngClass]="{
                                    'is-invalid error':
                                    (isSubmit && f.ItemId?.invalid) ||
                                    (f.ItemId?.invalid && (f.ItemId?.dirty || f.ItemId?.touched))
                                    }">
                                    <option *ngFor="let item of itemOption" [ngValue]="item.id">{{
                                        item.name }}</option>
                                </select>
                                <div class="invalid-feedback">
                                    <div *ngIf="
                                        isSubmit ||
                                        (f.ItemId.invalid &&
                                        (f.ItemId.dirty || f.ItemId.touched))
                                    ">
                                        {{ "Form.Required" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label for="colFormLabelLg">
                                    {{ "ProductDetailForm.ItemName" | translate }}
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" formControlName="ItemName" rows="5" [ngClass]="{
                                    'is-invalid error':
                                    (isSubmit && f.ItemName?.invalid) ||
                                    (f.ItemName?.invalid && (f.ItemName?.dirty || f.ItemName?.touched))
                                    }" [placeholder]="'Form.EnterName' | translate">
                                <div class="invalid-feedback">
                                    <div *ngIf="
                                        isSubmit ||
                                        (f.ItemName.invalid &&
                                        (f.ItemName.dirty || f.ItemName.touched))
                                    ">
                                        {{ "Form.EnterName" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div formArrayName="HowItWork">
                            <div *ngFor="let list of HowItWorkArray.controls, let ind = index">
                                <div [formGroupName]="ind">

                                    <div class="form-group row">
                                        <div class="col-md-11">

                                            <div *ngFor="let lang of langList">
                                                <div [class.d-none]="selectedLang != lang">

                                                    <!-- Title -->
                                                    <div formGroupName="title" class="form-group row" *ngIf="selectedLang == lang">
                                                        <div class="col-sm-3 col-form-label">
                                                            <label for="colFormLabelLg">
                                                                {{ "ProductDetailForm.HowItWorkTitle" | translate }} {{ind+1}}
                                                            </label>
                                                            <br />
                                                            <div *ngIf="langList.length > 1"
                                                                class="badge badge-primary">
                                                                {{ lang | uppercase }}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-9">
                                                            <input
                                                                class="form-control" [formControlName]="lang" rows="5"
                                                                [ngClass]="{'disabled-form': f.isUsingLabel?.value == false}"
                                                                [placeholder]="'Form.EnterDescription' | translate">
                                                            <div class="invalid-feedback">
                                                                <div
                                                                    *ngIf="isSubmit || (f.HowItWork.invalid && (f.HowItWork.dirty || f.HowItWork.touched))">
                                                                    {{ "Handle.DescriptionAllLanguage" | translate }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Detail -->
                                                    <div formGroupName="detail" class="form-group row" *ngIf="selectedLang == lang">
                                                        <div class="col-sm-3 col-form-label">
                                                            <label for="colFormLabelLg">
                                                                {{ "ProductDetailForm.HowItWorkDetail" | translate }} {{ind+1}}
                                                            </label>
                                                            <br />
                                                            <div *ngIf="langList.length > 1"
                                                                class="badge badge-primary">
                                                                {{ lang | uppercase }}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-9">
                                                            <textarea [ngxSummernote]="summernoteConfig"
                                                                class="form-control" [formControlName]="lang" rows="5"
                                                                [ngClass]="{'disabled-form': f.isUsingLabel?.value == false}"
                                                                [placeholder]="'Form.EnterDescription' | translate">
                                                            </textarea>
                                                            <div class="invalid-feedback">
                                                                <div
                                                                    *ngIf="isSubmit || (f.HowItWork.invalid && (f.HowItWork.dirty || f.HowItWork.touched))">
                                                                    {{ "Handle.DescriptionAllLanguage" | translate }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">
                                            <a *ngIf="HowItWorkArray.length > 1" class="remove-button btn btn-primary"
                                                (click)="removeRow('HowItWork', ind)">
                                                <i data-feather="x"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-4"></div>
                            <div class="col-sm-4" style="text-align-last: center; padding-bottom: 10px">
                                <a class="btn btn-outline-primary mr-1 text-primary"
                                    (click)="addHowItWorkRow(HowItWorkArray.length + 1)">
                                    <i data-feather="plus"></i>{{ "DocTable.Button.AddItem" | translate }}
                                </a>
                            </div>
                            <div class="col-sm-4"></div>
                        </div>

                        <hr>

                        <div formArrayName="ActiveIngredients">
                            <div *ngFor="let list of ActiveIngredientsArray.controls, let ind = index">
                                <div [formGroupName]="ind">

                                    <div class="form-group row">
                                        <div class="col-md-11">

                                            <div *ngFor="let lang of langList">
                                                <div [class.d-none]="selectedLang != lang">

                                                    <!-- Title -->
                                                    <div formGroupName="title" class="form-group row" *ngIf="selectedLang == lang">
                                                        <div class="col-sm-3 col-form-label">
                                                            <label for="colFormLabelLg">
                                                                {{ "ProductDetailForm.ActiveIngredientsTitle" | translate }} {{ind+1}}
                                                            </label>
                                                            <br />
                                                            <div *ngIf="langList.length > 1"
                                                                class="badge badge-primary">
                                                                {{ lang | uppercase }}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-9">
                                                            <input
                                                                class="form-control" [formControlName]="lang" rows="5"
                                                                [ngClass]="{'disabled-form': f.isUsingLabel?.value == false}"
                                                                [placeholder]="'Form.EnterDescription' | translate">
                                                            <div class="invalid-feedback">
                                                                <div
                                                                    *ngIf="isSubmit || (f.ActiveIngredients.invalid && (f.ActiveIngredients.dirty || f.ActiveIngredients.touched))">
                                                                    {{ "Handle.DescriptionAllLanguage" | translate }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Detail -->
                                                    <div formGroupName="detail" class="form-group row" *ngIf="selectedLang == lang">
                                                        <div class="col-sm-3 col-form-label">
                                                            <label for="colFormLabelLg">
                                                                {{ "ProductDetailForm.ActiveIngredientsTitle" | translate }} {{ind+1}}
                                                            </label>
                                                            <br />
                                                            <div *ngIf="langList.length > 1"
                                                                class="badge badge-primary">
                                                                {{ lang | uppercase }}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-9">
                                                            <textarea [ngxSummernote]="summernoteConfig"
                                                                class="form-control" [formControlName]="lang" rows="5"
                                                                [ngClass]="{'disabled-form': f.isUsingLabel?.value == false}"
                                                                [placeholder]="'Form.EnterDescription' | translate">
                                                            </textarea>
                                                            <div class="invalid-feedback">
                                                                <div
                                                                    *ngIf="isSubmit || (f.HowItWork.invalid && (f.HowItWork.dirty || f.HowItWork.touched))">
                                                                    {{ "Handle.DescriptionAllLanguage" | translate }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">
                                            <a *ngIf="ActiveIngredientsArray.length > 1"
                                                class="remove-button btn btn-primary"
                                                (click)="removeRow('ActiveIngredients', ind)">
                                                <i data-feather="x"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-4"></div>
                            <div class="col-sm-4" style="text-align-last: center; padding-bottom: 10px">
                                <a class="btn btn-outline-primary mr-1 text-primary"
                                    (click)="addActiveIngredientsRow(ActiveIngredientsArray.length + 1)">
                                    <i data-feather="plus"></i>{{ "DocTable.Button.AddItem" | translate }}
                                </a>
                            </div>
                            <div class="col-sm-4"></div>
                        </div>

                        <hr>

                        <div formArrayName="HowToUse">
                            <div *ngFor="let list of HowToUseArray.controls, let ind = index">
                                <div [formGroupName]="ind">

                                    <div class="form-group row">
                                        <div class="col-md-11">

                                            <div *ngFor="let lang of langList">
                                                <div [class.d-none]="selectedLang != lang">

                                                    <!-- Title -->
                                                    <div formGroupName="title" class="form-group row" *ngIf="selectedLang == lang">
                                                        <div class="col-sm-3 col-form-label">
                                                            <label for="colFormLabelLg">
                                                                {{ "ProductDetailForm.HowToUseTitle" | translate }} {{ind+1}}
                                                            </label>
                                                            <br />
                                                            <div *ngIf="langList.length > 1"
                                                                class="badge badge-primary">
                                                                {{ lang | uppercase }}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-9">
                                                            <input
                                                                class="form-control" [formControlName]="lang" rows="5"
                                                                [ngClass]="{'disabled-form': f.isUsingLabel?.value == false}"
                                                                [placeholder]="'Form.EnterDescription' | translate">
                                                            <div class="invalid-feedback">
                                                                <div
                                                                    *ngIf="isSubmit || (f.HowToUse.invalid && (f.HowToUse.dirty || f.HowToUse.touched))">
                                                                    {{ "Handle.DescriptionAllLanguage" | translate }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Detail -->
                                                    <div formGroupName="detail" class="form-group row" *ngIf="selectedLang == lang">
                                                        <div class="col-sm-3 col-form-label">
                                                            <label for="colFormLabelLg">
                                                                {{ "ProductDetailForm.HowToUseDetail" | translate }} {{ind+1}}
                                                            </label>
                                                            <br />
                                                            <div *ngIf="langList.length > 1"
                                                                class="badge badge-primary">
                                                                {{ lang | uppercase }}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-9">
                                                            <textarea [ngxSummernote]="summernoteConfig"
                                                                class="form-control" [formControlName]="lang" rows="5"
                                                                [ngClass]="{'disabled-form': f.isUsingLabel?.value == false}"
                                                                [placeholder]="'Form.EnterDescription' | translate">
                                                            </textarea>
                                                            <div class="invalid-feedback">
                                                                <div
                                                                    *ngIf="isSubmit || (f.HowItWork.invalid && (f.HowItWork.dirty || f.HowItWork.touched))">
                                                                    {{ "Handle.DescriptionAllLanguage" | translate }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">
                                            <a *ngIf="HowToUseArray.length > 1" class="remove-button btn btn-primary"
                                                (click)="removeRow('HowToUse', ind)">
                                                <i data-feather="x"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-4"></div>
                            <div class="col-sm-4" style="text-align-last: center; padding-bottom: 10px">
                                <a class="btn btn-outline-primary mr-1 text-primary"
                                    (click)="addHowToUseRow(HowToUseArray.length + 1)">
                                    <i data-feather="plus"></i>{{ "DocTable.Button.AddItem" | translate }}
                                </a>
                            </div>
                            <div class="col-sm-4"></div>
                        </div>

                        <div class="auto-form-footer">
                            <a (click)="openCancelModal()" class="btn btn-secondary">{{'Form.Cancel' | translate}}</a>
                            <a (click)="openConfirmModal();" class="btn btn-primary">{{'Form.Submit' | translate}}</a>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>