import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CmsFormpageModule } from '../../../cms-formpage/cms-formpage.module';
import { PartibiomeComponent } from './partibiome.component';
import { AuthGuard } from 'app/auth/helpers';

const path: string = 'cms/repove-tools/technology/partibiome';
const routes: Routes = [
  {
    path: path,
    component: PartibiomeComponent,
    canActivate: [AuthGuard],
    data: { animation: 'technology-partibiome' },
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule, RouterModule.forChild(routes), CmsFormpageModule
  ],
  exports: []
})
export class PartibiomeModule { }
