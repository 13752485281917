import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { ApiService } from 'app/main/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainConfig, filesPropNameConfig } from 'app/main/config/MainConfig';
import { AuthenticationService } from 'app/auth/service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2';
import { environment } from 'environments/environment';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { FilesApiService } from 'app/main/service/files-api.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CustomDropzoneComponent } from 'app/main/components/custom-dropzone/custom-dropzone.component';
import { ProductDetailService } from '../product-detail.service';
import { ComponentsService } from 'app/main/components/components.service';
import { ProductDetail } from 'app/main/model/ProductDetail';
import { ModalComponent } from 'app/main/components/modal/modal.component';

@Component({
  selector: 'app-product-detail-create',
  templateUrl: './product-detail-create.component.html',
  styleUrls: ['./product-detail-create.component.scss']
})
export class ProductDetailCreateComponent implements OnInit {

  private _unsubscribeAll: Subject<any>;
  private dataSubscription: Subscription;
  private fileSubscription: Subscription;
  contentHeader: object;

  @BlockUI() blockUI: NgBlockUI;
  // @Input() isUseMultipleLang: boolean = false;
  callbackUrl: string = this._productDetailService.pathUrl;
  filesPropName = filesPropNameConfig;
  @ViewChild('CustomDropzone') dropzoneComponent: CustomDropzoneComponent;

  passData: File[] = [];
  files: File[] = [];
  AlertFiles: Boolean;
  countMedia: number;

  ProductDetailForm: FormGroup;
  HowItWork: string[];
  ActiveIngredients: string[];
  HowToUse: string[];
  itemGuid: string;
  productDetailObj: ProductDetail;

  isEditing: boolean;
  isLoading: boolean;
  isSubmit: boolean;

  componentName: string;
  apiPath: string;
  pathUrl: string;
  itemOption: any[];
  itemSelect: number = 0;

  modeText: string;
  summernoteConfig: any;

  isMultiLang: any = true;
  selectedLang: string = environment.langDefault;
  langList = environment.langContent;

  // categoriesList: Categories[] = categoriesList;

  constructor(
    private _apiService: ApiService,
    private _formBuilder: FormBuilder,
    private _productDetailService: ProductDetailService,
    private _mainConfig: MainConfig,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _modalService: NgbModal,
    private _globalFuncService: GlobalFuncService,
    private _fileService: FilesApiService,
    private sanitizer: DomSanitizer,
  ) {
    this.SetLoadingState();
    this.initConfig();
    this.getItemOption();
    this.initForm(this.isEditing);
  }

  get f() {
    return this.ProductDetailForm.controls;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'ProductDetailForm.ProductDetail',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'ProductDetailForm.Product',
            isLink: true,
            link: this.pathUrl,
          },
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  initConfig(): void {
    this._unsubscribeAll = new Subject();
    this.componentName = this._translateService.instant('ProductDetailForm.ProductDetail');
    this.apiPath = this._productDetailService.apiPath;
    this.pathUrl = this._productDetailService.pathUrl;
    this.summernoteConfig = this._mainConfig.summernoteNewsConfig;

    this.isEditing = this._route.snapshot.paramMap.get('id') ? true : false;
    this.isLoading = this.isEditing ? true : false;

    this._apiService.SetHttpHeaders(this._authenticationService.tokenValue);

    this.modeText = this.isEditing
      ? this._translateService.instant('General.Edit')
      : this._translateService.instant('General.Create');

    this.ProductDetailForm = this._formBuilder.group({
      ItemId: [0, [Validators.required]],
      ItemName: ['', [Validators.required]]
    });

    this.langList.forEach((lang) => {

      this.ProductDetailForm.addControl(
        'HowItWork',
        this._formBuilder.array([])
      );

      this.ProductDetailForm.addControl(
        'ActiveIngredients',
        this._formBuilder.array([])
      );

      this.ProductDetailForm.addControl(
        'HowToUse',
        this._formBuilder.array([])
      );
    });

    if (!this.isEditing) {
      this.addHowItWorkRow(0);
      this.addActiveIngredientsRow(0);
      this.addHowToUseRow(0);
    }

    console.log(this.ProductDetailForm.value)
  }

  initForm(isEditing: boolean): void {
    var self = this;
    if (isEditing) {
      this.itemGuid = this._route.snapshot.paramMap.get('id');
      this._route.paramMap.subscribe((val) => {
        self.itemGuid = self._route.snapshot.paramMap.get('id');
        self.getProductDetailData(self.itemGuid);
        self.getItemData(self.itemGuid)
      });
    } else {
      this.productDetailObj = new ProductDetail();
    }
  }

  getItemData(guid: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataSubscription = this._apiService
        .GetDataById(this.apiPath, guid)
        .subscribe(
          (res) => {
            const self = this;
            self.productDetailObj = res.data.resultData[0];
          })
    });
  }

  getItemOption() {
    const param = { verbose: false, IncludeMediaURL: false, isDelete: false }
    this._apiService.GetAllData('item', param).subscribe((res) => {
      this.itemOption = res.data.resultData
      if (!this.productDetailObj.ItemId) {
        this.itemSelect = this.itemOption[0].id
      }
    })
  }

  setFormValue(): void {
    let tempHowItWork = JSON.parse(this.productDetailObj.HowItWork);
    let tempActiveIngredients = JSON.parse(this.productDetailObj.ActiveIngredients);
    let tempHowToUse = JSON.parse(this.productDetailObj.HowToUse);
    if (this.isEditing) {
      if (tempHowItWork.length > 1) {
        tempHowItWork.forEach((element, index) => {
          this.addHowItWorkRow(index)
        });
      } else {
        this.addHowItWorkRow(0)
      }
      if (tempActiveIngredients.length > 1) {
        tempActiveIngredients.forEach((element, index) => {
          this.addActiveIngredientsRow(index)
        });
      } else {
        this.addActiveIngredientsRow(0)
      }
      if (tempHowToUse.length > 1) {
        tempHowToUse.forEach((element, index) => {
          this.addHowToUseRow(index)
        });
      } else {
        this.addHowToUseRow(0)
      }
    }

    this.ProductDetailForm
      .get(`ItemId`)
      .patchValue(this.productDetailObj.ItemId);

    this.ProductDetailForm
      .get(`ItemName`)
      .patchValue(this.productDetailObj.ItemName);

    tempHowItWork.forEach((element, index) => {
      this.ProductDetailForm
        .get(`HowItWork.${index}`)
        .patchValue(tempHowItWork[index]);
    });

    tempActiveIngredients.forEach((element, index) => {
      this.ProductDetailForm
        .get(`ActiveIngredients.${index}`)
        .patchValue(tempActiveIngredients[index]);
    });

    tempHowToUse.forEach((element, index) => {
      this.ProductDetailForm
        .get(`HowToUse.${index}`)
        .patchValue(tempHowToUse[index]);
    });
  }

  getProductDetailData(guid: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService.GetDataById(this.apiPath, guid).subscribe((res) => {
        self.productDetailObj = res.data.resultData[0];
        self.setFormValue();
        self.isLoading = false;
      }, rejects);
    });
  }

  openConfirmModal(): void {
    let isConfirm = true;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.Edit');
    } else {
      var stateForm = this._translateService.instant('General.Create');
    }
    let title =
      this._translateService.instant('General.Confirm') +
      `${stateForm} ${this.componentName}`;
    let detail =
      this._translateService.instant('General.AreYouConfirm') +
      `${stateForm} ${this.componentName}`;
    if (this.ProductDetailForm.status === 'INVALID') {
      if (this.ProductDetailForm.status === 'INVALID') {
        Swal.fire(this._translateService.instant('Alert.Invalid'), '', 'warning')
      }
    } else {
      this.openModal(title, detail, isConfirm);
    }
  }

  openCancelModal(): void {
    let isConfirm = false;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.CancelEdit');
    } else {
      var stateForm = this._translateService.instant('General.CancelCreate');
    }
    let title =
      this._translateService.instant('General.Confirm') +
      `${stateForm} ${this.componentName}`;
    let detail =
      this._translateService.instant('General.AreYouConfirm') +
      `${stateForm} ${this.componentName}`;
    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    this.isSubmit = true;

    if (!this.ProductDetailForm.invalid) {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        if (IsConfirm) {
          this.submit();
        } else {
          this._router.navigate([`${this.pathUrl}`]);
        }
      });
    } else {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        this._router.navigate([`${this.pathUrl}`]);
      });
    }
  }

  prepareFinalData(data: any, tempForm: FormGroup) {
    for (let controlName in data.controls) {
      tempForm.addControl(
        controlName,
        this._formBuilder.control(data.get(controlName).value)
      );
    }

    let tempItemIdObj =
      tempForm.removeControl('ItemId');
    tempForm.addControl(
      'ItemId',
      this._formBuilder.control(tempItemIdObj, [])
    );

    let tempItemNameObj = this.ProductDetailForm.get(`ItemId`).value;
    tempForm.removeControl('ItemId');
    tempForm.addControl(
      'ItemId',
      this._formBuilder.control(tempItemNameObj, [])
    );

    let tempHowItWork = this.HowItWorkArray
    tempForm.removeControl('HowItWork');
    tempForm.addControl(
      'HowItWork',
      this._formBuilder.control(JSON.stringify(tempHowItWork.value), [])
    );

    let tempActiveIngredients = this.ActiveIngredientsArray
    tempForm.removeControl('ActiveIngredients');
    tempForm.addControl(
      'ActiveIngredients',
      this._formBuilder.control(JSON.stringify(tempActiveIngredients.value), [])
    );

    let tempHowToUse = this.HowToUseArray
    tempForm.removeControl('HowToUse');
    tempForm.addControl(
      'HowToUse',
      this._formBuilder.control(JSON.stringify(tempHowToUse.value), [])
    );

    console.log(tempForm.value)
  }

  testPrePareData() {
    let tempForm = new FormGroup({});
    this.prepareFinalData(this.ProductDetailForm, tempForm);
  }

  submit(): void {
    var self = this;
    let isUpdatefile;
    this.isSubmit = true;
    if (this.ProductDetailForm.invalid) {
      console.log('Invalid');
      return;
    }

    let tempForm = new FormGroup({});
    this.prepareFinalData(this.ProductDetailForm, tempForm);

    if (this.isEditing) {
      console.log('Edit mode');
      isUpdatefile = true;

      this._apiService
        .UpdateDataById(
          this.apiPath,
          this.productDetailObj.id.toString(),
          tempForm.value
        )
        .subscribe(
          (res) => {
            this._componentsService.SuccessSwal();
            this._router.navigate([`${this.pathUrl}`]);
          },
          (err) => {
            this._componentsService.ErrorSwal();
          }
        );
    } else {
      isUpdatefile = false;

      this._apiService.AddData(this.apiPath, tempForm.value).subscribe(
        (res) => {
          console.log('DOM : ' + res.data.resultData);

          this._router.navigate([this.callbackUrl]);
          self._componentsService.SuccessSwal();
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
    }
  }

  get HowItWorkArray() {
    return this.ProductDetailForm.controls['HowItWork'] as FormArray;
  }

  get ActiveIngredientsArray() {
    return this.ProductDetailForm.controls["ActiveIngredients"] as FormArray;
  }

  get HowToUseArray() {
    return this.ProductDetailForm.controls["HowToUse"] as FormArray;
  }

  addHowItWorkRow(index): void {
    const descForm: any = {}
    const titleControls: any = {}
    const detailControls: any = {}
    this.langList.forEach((lang) => {
      for (const lang of this.langList) {
        titleControls[lang] = '', [Validators.required];
        detailControls[lang] = '', [Validators.required];
      }
    });
    descForm[`title`] = this._formBuilder.group(
      titleControls
    );
    descForm[`detail`] = this._formBuilder.group(
      detailControls
    );

    this.HowItWorkArray.push(this._formBuilder.group(descForm));
  }

  addActiveIngredientsRow(index): void {
    const descForm: any = {}
    const titleControls: any = {}
    const detailControls: any = {}
    this.langList.forEach((lang) => {
      for (const lang of this.langList) {
        titleControls[lang] = '', [Validators.required];
        detailControls[lang] = '', [Validators.required];
      }
    });
    descForm[`title`] = this._formBuilder.group(
      titleControls
    );
    descForm[`detail`] = this._formBuilder.group(
      detailControls
    );

    this.ActiveIngredientsArray.push(this._formBuilder.group(descForm));
  }

  addHowToUseRow(index): void {
    const descForm: any = {}
    const titleControls: any = {}
    const detailControls: any = {}
    this.langList.forEach((lang) => {
      for (const lang of this.langList) {
        titleControls[lang] = '', [Validators.required];
        detailControls[lang] = '', [Validators.required];
      }
    });
    descForm[`title`] = this._formBuilder.group(
      titleControls
    );
    descForm[`detail`] = this._formBuilder.group(
      detailControls
    );

    this.HowToUseArray.push(this._formBuilder.group(descForm));
  }

  trackBy(idx: number, item: { id: number }): number {
    return item.id;
  }

  removeRow(type: string, index: number): void {

    if (type == 'HowItWork') {
      this.HowItWorkArray.removeAt(index);
    } else if (type == 'ActiveIngredients') {
      this.ActiveIngredientsArray.removeAt(index);
    } else if (type == 'HowToUse') {
      this.HowToUseArray.removeAt(index);
    }
  }

  selectedLanguage(lang: string) {
    this.selectedLang = lang;
  }

}
