import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from 'app/main/components/components.module';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { CmsListComponent } from './cms-list.component';

@NgModule({
  declarations: [CmsListComponent],
  imports: [
    CoreCommonModule,
    CommonModule,
    ContentHeaderModule,
    FormsModule,
    ComponentsModule,
    CorePipesModule,
    TranslateModule,
    NgbPaginationModule,
    DataTablesModule,
  ],
  exports: [CmsListComponent],
})
export class CmsListModule {}
