<div class="card m-2" style="min-height: 400px">
  <h2 class="my-2">{{ "ActionHistory.DocumentLog" | translate }}</h2>

  <div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
    <app-loading></app-loading>
  </div>
  <ul *ngIf="!isLoading" class="timeline">
    <ng-container *ngFor="let item of documentLog">
      <li class="timeline-item" *ngIf="item.event !== 'Viewed'">
        <span
          class="timeline-point timeline-point-indicator"
          [class]="getLogEvent(item.event).class"
        ></span>
        <div class="timeline-event border p-2 rounded">
          <div
            class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
          >
            <h6>
              {{ "ActionHistory." + getLogEvent(item.event).text | translate }}
            </h6>
            <span class="timeline-event-time">
              {{ item.createDate | thaidate : "DD/MM/YYYY - HH:mm:ss" }}
            </span>
          </div>
          <p style="background: #fafafa; border-radius: 4px; padding: 4px">
            {{ item.createBy }}
            {{ "ActionHistory." + getLogEvent(item.event).text | translate }}
            <ng-container *ngIf="shouldDisplayTitle(item)">
              {{ title | translate }}
            </ng-container>
            {{ "ActionHistory.At" | translate }}
            <u>{{ item.createDate | thaidate : "DD/MM/YYYY - HH:mm:ss" }}</u>
          </p>
          <div class="media align-items-center">
            <picture>
              <img
                [src]="getProfileImage(item.mediaFiles.profileImage?.[0]?.id) || defaultImage"
                alt="profile_image"
                class="rounded-circle mr-1"
                width="20px"
                height="20px"
                style="object-fit: cover; object-position: center"
              />
            </picture>
            <div class="media-body">
              <b>{{ item.createBy }}&nbsp;</b>
              <app-role-badge [roleSetName]="item.roleName"></app-role-badge>
            </div>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
