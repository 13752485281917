export class ProductDetail{
    id?: any;
    ItemId: any;
    ItemName: string;
    mediaFiles?: any[]
    HowItWork: string;
    ActiveIngredients: string;
    HowToUse: string;
  }

  
  export default ProductDetail