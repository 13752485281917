import { NgModule } from '@angular/core';

import { FeatherIconDirective } from '@core/directives/core-feather-icons/core-feather-icons';
import { RippleEffectDirective } from '@core/directives/core-ripple-effect/core-ripple-effect.directive';
import { NumbersOnly } from './numbers-only.directive';
import { DefaultImageDirective } from './default-image.directive';

@NgModule({
  declarations: [
    RippleEffectDirective,
    FeatherIconDirective,
    NumbersOnly,
    DefaultImageDirective,
  ],
  exports: [
    RippleEffectDirective,
    FeatherIconDirective,
    NumbersOnly,
    DefaultImageDirective,
  ],
})
export class CoreDirectivesModule {}
