import { array } from '@amcharts/amcharts5';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalManageBranchComponent } from 'app/main/components/modal-manage-branch/modal-manage-branch.component';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import Branch, { branchList } from 'app/main/model/Branch';
import { ApiService } from 'app/main/service/api.service';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss'],
})
export class BranchComponent implements OnInit {
  branchList: any[] = [];

  apiPath: string = 'Branch';
  isDelete: boolean 

  searchData: any = {
    isDelete: false,
    Direction: 0,
    SortPath: 'createDate'
    // isActive: true,
  };

  constructor(
    private _apiService: ApiService,
    private _modalService: NgbModal,
    private _componentsService: ComponentsService,
    private _translateService: TranslateService
  ) {
    this.getBranchData();
  }

  ngOnInit(): void {
    
  }


  openEditDetailModal(event: string, data?: any) {
    const modalRef = this._modalService.open(ModalManageBranchComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
    });
    console.log(data)
    
    modalRef.componentInstance.actionBranch = event;
    modalRef.componentInstance.editData = data;
    modalRef.componentInstance.setMode = false;
    // modalRef.componentInstance.callBackFunc.subscribe((res) => {
    //   this.selectedBankAccount = null;
    // });
    modalRef.componentInstance.refreshData.subscribe((res) => {
      this.getBranchData(res[0]);
    });
  }
  
  getAllBranch(){

  }
  branchListTest:[] = [];

  // Not using at moment
  getBranchData(id?: number) {
    this._apiService
      .GetAllData(this.apiPath, this.searchData)
      .subscribe((res) => {
        this.branchListTest = res.data.resultData  
        console.log(res.data.resultData)       // this.allBankList = [];
        // this.defaultBankList = [];
        // this.allBankList = res.data.resultData;

        // const objectsToMove = this.allBankList.filter(
        //   (item) => item.isUseForDocument === true
        // );

        // this.countDefaultBank = objectsToMove.length;

        // if (objectsToMove.length > 0) {
        //   objectsToMove.forEach((objectToMove) => {
        //     const indexToMove = this.allBankList.indexOf(objectToMove);
        //     this.allBankList.splice(indexToMove, 1);
        //     this.defaultBankList.push(objectToMove);
        //   });
        // }
        console.log(res)
      });
  }
  openDeleteModal(id){
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
      
    });
    console.log(id)
    modalRef.componentInstance.title = 'delete'
    modalRef.componentInstance.detail = 'Delete'
    modalRef.componentInstance.callBackFunc.subscribe((res)=>{
      this.deleteBranch(id)
    })
    

  }
  deleteBranch(id){
    this._apiService.SoftDelete(this.apiPath,id,true).subscribe((res)=>{
      this._componentsService.SuccessSwal()
    })
  }


  openActiveModal(id,status){
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static'
    })
    let modeText = status
      ? this._translateService.instant('Status.Published')
      : this._translateService.instant('Status.Unpublished');
    modalRef.componentInstance.title = `${modeText}`;
    modalRef.componentInstance.detail = this._translateService.instant('BonusPlan.Active')+
    `${modeText}`
    modalRef.componentInstance.callBackFunc.subscribe((res) => {

      if (modalRef.componentInstance.closeModal()) {
        // this.setActive(id, this.branchList.isActive)
      } else {
        this.setActive(id, status)
      }


      // this.loadData()
    })
  }

  setActive(id,value:boolean){
    this._apiService.SetIsActiveData(this.apiPath,id,value).subscribe((res)=>{
      console.log(res)
      this._componentsService.SuccessSwal();
      this.getBranchData()

    },
    (error) => {
      console.log(error)
      this._componentsService.ErrorSwal();
    })
  }

  
}
