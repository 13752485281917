import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { BookingCalendarComponent } from './booking-calendar/booking-calendar.component';
import { ExecutiveSummaryComponent } from './executive-summary/executive-summary.component';
import { RevenueComponent } from './revenue/revenue.component';
import { MemberComponent } from './member/member.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { CoreCommonModule } from '@core/common.module';
import { DashboardService } from './dashboard.service';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { MemberDetailComponent } from './member/member-detail/member-detail.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { BookingCalendarModalComponent } from './booking-calendar/booking-calendar-modal/booking-calendar-modal.component';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ComponentsModule } from 'app/main/components/components.module';
import { MpRevenueChartComponent } from './executive-summary/mp-revenue-chart/mp-revenue-chart.component';
import { MemberChartComponent } from './executive-summary/member-chart/member-chart.component';
import { ConversionRateChartComponent } from './executive-summary/conversion-rate-chart/conversion-rate-chart.component';
import { RevenueChartComponent } from './executive-summary/revenue-chart/revenue-chart.component';
import { YearOnYearChartComponent } from './executive-summary/year-on-year-chart/year-on-year-chart.component';
import { SelectBranchModalComponent } from './booking-calendar/select-branch-modal/select-branch-modal.component';
import { BookingApprovalComponent } from './booking-approval/booking-approval.component';
import { ApprovalModalComponent } from './booking-approval/approval-modal/approval-modal.component';
import { DataTablesModule } from 'angular-datatables';
import { ReturnProductComponent } from './return-product/return-product.component';
import { ReturnProductDetailModalComponent } from './return-product/return-product-detail-modal/return-product-detail-modal.component';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
]);

const routes: Routes = [
  {
    path: 'dashboard/booking-calendar',
    component: BookingCalendarComponent,
    canActivate: [AuthGuard],
    resolve: {
      data: DashboardService,
    },
    data: { animation: 'booking-calendar' },
  },
  {
    path: 'dashboard/booking-calendar-approve',
    component: BookingApprovalComponent,
    canActivate: [AuthGuard],
    resolve: {
      data: DashboardService,
    },
    data: { animation: 'booking-calendar' },
  },
  {
    path: 'dashboard/member',
    component: MemberComponent,
    canActivate: [AuthGuard],
    data: { animation: 'member' },
  },

  {
    path: 'dashboard/member/detail/:id',
    component: MemberDetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'member' },
  },

  {
    path: 'dashboard/revenue',
    component: RevenueComponent,
    canActivate: [AuthGuard],
    data: { animation: 'revenue' },
  },
  {
    path: 'dashboard/executive-summary',
    component: ExecutiveSummaryComponent,
    canActivate: [AuthGuard],
    data: { animation: 'executive-summary' },
  },

  {
    path: 'dashboard/refund-request',
    component: ReturnProductComponent,
    canActivate: [AuthGuard],
    data: { animation: 'refund-request' },
  },
];

@NgModule({
  declarations: [
    BookingCalendarComponent,
    ExecutiveSummaryComponent,
    RevenueComponent,
    MemberComponent,
    MemberDetailComponent,
    BookingCalendarModalComponent,
    MpRevenueChartComponent,
    MemberChartComponent,
    ConversionRateChartComponent,
    RevenueChartComponent,
    YearOnYearChartComponent,
    SelectBranchModalComponent,
    BookingApprovalComponent,
    ApprovalModalComponent,
    ReturnProductComponent,
    ReturnProductDetailModalComponent,
  ],
  imports: [
    CommonModule,
    CorePipesModule,
    CoreCommonModule,
    FullCalendarModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    Ng2FlatpickrModule,
    TranslateModule,
    NgbModule,
    NgApexchartsModule,
    ComponentsModule,
    DataTablesModule,
  ],
  providers: [DashboardService],
})
export class DashboardModule {}
