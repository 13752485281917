import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Contact from 'app/main/model/Contact';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  config: ICMSConfig = {
    apiPath: 'contact',
    pathUrl: '/cms/contact',
    tableName: 'Content',
    model: new Contact(),
    isList: false,
    componentName: 'Contact',
    formConfig: {
      bannerContactDescription: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Banner Title',
        description: 'Wording in the middle of Banner',
        labelTranslate: 'Informative.Contact.BannerTitle',
        descriptionTranslate: 'Informative.Contact.BannerTitleDescription',
      },
      bannerContact: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Banner Image',
        description: 'Resolution 1920px*450px',
        labelTranslate: 'Informative.Contact.BannerImage',
        descriptionTranslate: 'Informative.Contact.BannerImageDescription',
      },

      contactInfoDescription1: {
        type: FormType.Textarea,
        validator: [Validators.required],
        isMultipleLang: true,
        label: 'Visit us',
        description: 'Description of Office Location',
        labelTranslate: 'Informative.Contact.VisitUs',
        descriptionTranslate: 'Informative.Contact.VisitUsDescription',
      },
      contactInfoDescription2: {
        type: FormType.Textarea,
        validator: [Validators.required],
        isMultipleLang: true,
        label: 'Call us',
        description: 'Description of Store Location',
        labelTranslate: 'Informative.Contact.CallUs',
        descriptionTranslate: 'Informative.Contact.CallUsDescription',
      },
      contactInfoDescription3: {
        type: FormType.Textarea,
        validator: [Validators.required],
        isMultipleLang: true,
        label: 'Email us',
        description: 'Description of Call us directly',
        labelTranslate: 'Informative.Contact.EmailUs',
        descriptionTranslate: 'Informative.Contact.EmailUsDescription',
      },

      contactPic1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Contact Form Image 1',
        description: 'Resolution 510px*587px',
        labelTranslate: 'Informative.Contact.ContactFormImage1',
        descriptionTranslate:
          'Informative.Contact.ContactFormImage1Description',
      },
      contactPic2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Contact Form Image 2',
        description: 'Resolution 330px*387px',
        labelTranslate: 'Informative.Contact.ContactFormImage2',
        descriptionTranslate:
          'Informative.Contact.ContactFormImage2Description',
      },
    },
  };

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
