<div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">
        จัดการข้อมูลที่อยู่
    </h5>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">
            <i data-feather="x" size="22"></i>
        </span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
    <!-- <p>{{ data | json }}</p> -->

    <form [formGroup]="formGroup" class="row">

        <div class="col-12" style="margin-bottom: 0.5rem">
            <label class="form-label" for="nameTH">ชื่อ (ไทย)</label>
            <span class="text-danger">&nbsp;*</span>
            <input type="text" class="form-control" formControlName="nameTH" placeholder="กรุณาระบุชื่อ (ไทย)"
                id="nameTH" maxlength="200" [ngClass]="{
                'is-invalid error': (isSubmit && f.nameTH?.invalid) || (f.nameTH?.invalid && (f.nameTH?.dirty || f.nameTH?.touched))
              }" />
            <div class="invalid-feedback">
                <div *ngIf="isSubmit || (f.nameTH.invalid && (f.nameTH.dirty || f.nameTH.touched))">
                    กรุณากรอกชื่อ (ไทย)
                </div>
            </div>
        </div>

        <div class="col-12" style="margin-bottom: 0.5rem">
            <label class="form-label" for="nameTH">ชื่อ (อังกฤษ)</label>
            <span class="text-danger">&nbsp;*</span>
            <input type="text" class="form-control" formControlName="nameEN" placeholder="กรุณาระบุชื่อ (ไทย)"
                id="nameEN" maxlength="200" [ngClass]="{
                'is-invalid error': (isSubmit && f.nameEN?.invalid) || (f.nameEN?.invalid && (f.nameEN?.dirty || f.nameEN?.touched))
              }" />
            <div class="invalid-feedback">
                <div *ngIf="isSubmit || (f.nameEN.invalid && (f.nameEN.dirty || f.nameEN.touched))">
                    กรุณากรอกชื่อ (อังกฤษ)
                </div>
            </div>
        </div>

        <div class="col-12" style="margin-bottom: 0.5rem">
            <label class="form-label" for="province">{{ 'Region.Title' | translate }}</label>

            <span class="text-danger">&nbsp;*</span>
            <div class="w-100">
                <ng-select id="region" formControlName="region" [clearable]="false" [searchable]="true"
                    placeholder="{{ 'Address.cLocalRequiredError' | translate }}" (change)="onSelectRegion()" [ngClass]="{
                  'is-invalid error':
                    (isSubmit && f.region?.invalid) ||
                    (f.region?.invalid && (f.region?.dirty || f.region?.touched))
                }">
                    <ng-option [value]="0">{{ 'General.All' | translate }}</ng-option>
                    <ng-option [value]="1">{{ 'Region.Northern' | translate }}</ng-option>
                    <ng-option [value]="2">{{ 'Region.Central' | translate }}</ng-option>
                    <ng-option [value]="3">{{ 'Region.Northeastern' | translate }}</ng-option>
                    <ng-option [value]="4">{{ 'Region.Western' | translate }}</ng-option>
                    <ng-option [value]="5">{{ 'Region.Eastern' | translate }}</ng-option>
                    <ng-option [value]="6">{{ 'Region.Southern' | translate }}</ng-option>
                </ng-select>
                <div class="invalid-feedback">
                    <div *ngIf="isSubmit || (f.region.invalid && (f.region.dirty || f.region.touched))">
                        {{ 'Address.cLocalRequiredError' | translate }}
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="isDistrictMode() || isSubDistrictMode()">
            <div class="col-12" style="margin-bottom: 0.5rem">
                <label class="form-label" for="province">{{ 'Address.province' | translate }}</label>

                <span class="text-danger">&nbsp;*</span>
                <div class="w-100">
                    <ng-select id="province" formControlName="province" [clearable]="false" [searchable]="true"
                        placeholder="{{ 'Address.cCityRequiredError' | translate }}" (change)="onSelectProvince()"
                        [ngClass]="{
                      'is-invalid error':
                        (isSubmit && f.province?.invalid) ||
                        (f.province?.invalid && (f.province?.dirty || f.province?.touched))
                    }">
                        <ng-option *ngFor="let province of provinceList" [value]="province.id">
                            {{ province.provinceName }}
                        </ng-option>
                    </ng-select>
                    <div class="invalid-feedback">
                        <div *ngIf="
                        isSubmit ||
                        (f.province.invalid && (f.province.dirty || f.province.touched))
                      ">
                            {{ 'Address.cCityRequiredError' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>


        <ng-container *ngIf="isSubDistrictMode()">
            <div class="col-12" style="margin-bottom: 0.5rem">
                <label class="form-label" for="district">{{ 'Address.district' | translate }}</label>

                <span class="text-danger">&nbsp;*</span>
                <div class="w-100">
                    <ng-select id="district" formControlName="district" [clearable]="false" [searchable]="true"
                        placeholder="{{ 'Address.cDistrictRequiredError' | translate }}" [ngClass]="{
                      'is-invalid error':
                        (isSubmit && f.district?.invalid) ||
                        (f.district?.invalid && (f.district?.dirty || f.district?.touched))
                    }">
                        <ng-option *ngFor="let district of districtList" [value]="district.id">
                            {{ district.districtName }}
                        </ng-option>
                    </ng-select>
                    <div class="invalid-feedback">
                        <div *ngIf="
                        isSubmit ||
                        (f.district.invalid && (f.district.dirty || f.district.touched))
                      ">
                            {{ 'Address.cDistrictRequiredError' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>


        <ng-container *ngIf="isSubDistrictMode()">
            <div class="col-12" style="margin-bottom: 0.5rem">
                <label class="form-label" for="postCode">{{ 'Address.postalCode' | translate }}</label>
                <span class="text-danger">&nbsp;*</span>
                <input type="text" class="form-control" formControlName="postCode"
                    placeholder="{{ 'Address.cPostcodeRequiredError' | translate }}" id="postCode" maxlength="200"
                    [ngClass]="{
                    'is-invalid error': (isSubmit && f.postCode?.invalid) || (f.postCode?.invalid && (f.postCode?.dirty || f.postCode?.touched))
                  }" />
                <div class="invalid-feedback">
                    <div *ngIf="isSubmit || (f.postCode.invalid && (f.postCode.dirty || f.postCode.touched))">
                        {{ 'Address.cPostcodeRequiredError' | translate }}
                    </div>
                </div>
            </div>
        </ng-container>


    </form>
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-secondary m-0" (click)="closeModal()" rippleEffect>
        {{ "Form.Cancel" | translate }}
    </button>
    <a type="button" class="btn btn-primary m-0" (click)="callBack()" rippleEffect>
        {{ "Form.Confirm" | translate }}
    </a>
</div>