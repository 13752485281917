import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { BonusTableComponent } from './bonus-table.component';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { CoreCommonModule } from '@core/common.module';
import { TranslateModule } from '@ngx-translate/core';
import { DataTablesModule } from 'angular-datatables';
import { CsvModule } from '@ctrl/ngx-csv';
import { ComponentsModule } from 'app/main/components/components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const path: string = 'mlm-config/bonus-table-config';
const routes: Routes = [
  {
    path: path,
    component: BonusTableComponent,
    canActivate: [AuthGuard],
    data: { animation: 'mlm-personal-bonus-plan' },
  },
];

@NgModule({
  declarations: [BonusTableComponent],
  imports: [
    CommonModule,RouterModule.forChild(routes),
    FormsModule,ReactiveFormsModule,
    HttpClientModule,ContentHeaderModule,
    CoreCommonModule,
    TranslateModule,
    DataTablesModule,
    CsvModule,
    ComponentsModule,
    ReactiveFormsModule,
    NgbModule,
  ]
})
export class BonusTableModule { }
