<div class="form-group row">
  <label class="col-sm-3 col-form-label">{{ "User.Email" | translate }}</label>
  <div class="col-sm-9">
    <select class="custom-select" name="" id="">
      <option [value]="role.id" *ngFor="let role of roleSetList">
        {{ role.roleName }}
      </option>
    </select>
  </div>
</div>
<table class="table table-striped table-hover">
  <thead>
    <tr>
      <th>Module</th>
      <th>Read</th>
      <th>Write</th>
      <th>Create</th>
      <th>Delete</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let acl of aclList">
      <td>{{ acl.operationCode }}</td>
      <td>
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            id="{{ acl.operationCode }}-read"
          />
          <label
            class="custom-control-label"
            for="{{ acl.operationCode }}-read"
          ></label>
        </div>
      </td>
      <td>
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            id="{{ acl.operationCode }}-write"
          />
          <label
            class="custom-control-label"
            for="{{ acl.operationCode }}-write"
          ></label>
        </div>
      </td>
      <td>
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            id="{{ acl.operationCode }}-create"
          />
          <label
            class="custom-control-label"
            for="{{ acl.operationCode }}-create"
          ></label>
        </div>
      </td>
      <td>
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            id="{{ acl.operationCode }}-delete"
          />
          <label
            class="custom-control-label"
            for="{{ acl.operationCode }}-delete"
          ></label>
        </div>
      </td>
    </tr>
  </tbody>
</table>
