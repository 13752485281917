import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { filesPropNameConfig } from 'app/main/config/MainConfig';
import { FilesApiService } from 'app/main/service/files-api.service';

import { Subject } from 'rxjs';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexLegend,
  ApexMarkers,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexResponsive,
  ApexStates,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
} from 'ng-apexcharts';
import ItemMovement from 'app/main/model/ItemMovement';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdjustItemModalComponent } from 'app/main/components/adjust-item-modal/adjust-item-modal.component';
import Item from 'app/main/model/Item';
import { TranslateService } from '@ngx-translate/core';

export interface ChartOptions {
  // Apex-Non-Axis-Chart-Series
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  fill: ApexFill;
  colors: string[];
  legend: ApexLegend;
  labels: any;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  markers: ApexMarkers[];
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  states: ApexStates;
}

@Component({
  selector: 'app-item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ItemDetailComponent implements OnInit, OnChanges {
  @Input() itemObj: Item;
  @Input() lastItemMovementObj: ItemMovement;
  @Input() childItemsList: Item[];
  @Input() stockSeries: any[] = [];

  filesPropName = filesPropNameConfig;

  @ViewChild('customerChartoptionsRef') customerChartoptionsRef: any;

  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();
  public customerChartoptions: Partial<ChartOptions>;

  private $primary = '#7367F0';
  private $danger = '#EA5455';
  private $warning = '#FF9F43';
  private $info = '#00cfe8';
  private $success = '#00db89';
  private $primary_light = '#9c8cfc';
  private $warning_light = '#FFC085';
  private $danger_light = '#f29292';
  private $info_light = '#1edec5';
  private $stroke_color = '#ebe9f1';
  private $label_color = '#e7eef7';
  private $purple = '#df87f2';
  private $white = '#fff';

  private $textHeadingColor = '#5e5873';
  private $strokeColor = '#ebe9f1';
  private $labelColor = '#e7eef7';
  private $avgSessionStrokeColor2 = '#ebf0f7';
  private $budgetStrokeColor2 = '#dcdae3';
  private $goalStrokeColor2 = '#51e5a8';
  private $revenueStrokeColor2 = '#d0ccff';
  private $textMutedColor = '#b9b9c3';
  private $salesStrokeColor2 = '#df87f2';
  private $earningsStrokeColor2 = '#28c76f66';
  private $earningsStrokeColor3 = '#28c76f33';

  public isMenuToggled = false;

  private _unsubscribeAll: Subject<any>;

  currentLang: string;

  constructor(
    private fileApiService: FilesApiService,
    private _modalService: NgbModal,
    private _translateService: TranslateService
  ) {
    // Customer Chart
    this.customerChartoptions = {
      chart: {
        type: 'pie',
        height: 345,
        toolbar: {
          show: false,
        },
      },
      labels: ['FG', 'FOC', 'Claim', 'Clear'],
      dataLabels: {
        enabled: false,
      },
      legend: { show: false },
      stroke: {
        width: 4,
      },
      colors: [this.$primary, this.$warning, this.$danger, this.$info],
    };

    this.currentLang = this._translateService.currentLang;
  }

  ngOnInit(): void {
    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });
    console.log(this.lastItemMovementObj);
    
  }

  getItemDescription() {
    let tempObj = JSON.parse(this.itemObj.description);

    return tempObj[this.currentLang];
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  getItemCoverImage(guid: string): string {
    return this.fileApiService.getImage(guid);
  }

  openAdjustModal() {
    const modalRef = this._modalService.open(AdjustItemModalComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: true,
    });
    modalRef.componentInstance.itemObj = this.itemObj;
    modalRef.componentInstance.childItemsList = this.childItemsList;
    modalRef.componentInstance.isItemSet = this.itemObj.isItemSet;
    modalRef.componentInstance.lastItemMovementObj = this.lastItemMovementObj;
    modalRef.componentInstance.setMode = false;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.callBackFunc.emit();
    });
  }

  openAdjustSetModal() {
    const modalRef = this._modalService.open(AdjustItemModalComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: true,
    });
    modalRef.componentInstance.itemObj = this.itemObj;
    modalRef.componentInstance.childItemsList = this.childItemsList;
    modalRef.componentInstance.isItemSet = this.itemObj.isItemSet;
    modalRef.componentInstance.lastItemMovementObj = this.lastItemMovementObj;
    modalRef.componentInstance.setMode = true;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.callBackFunc.emit();
    });
  }
}
