export const debitNoteReasons = [
  {
    value: 1,
    label:
      'เพิ่มราคาสินค้า เนื่องจากสินค้าเกินกว่าจำนวนที่ตกลงกัน คำนวณราคาต่ำกว่าความเป็นจริง',
  },
  {
    value: 2,
    label:
      'เพิ่มราคาบริการ เนื่องจากให้บริการเกินกว่าที่กำหนดคำนวณราคาต่ำกว่าความเป็นจริง',
  },
  { value: 3, label: '3. อื่น ๆ' },
];
