import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promotion-code-formpage',
  templateUrl: './promotion-code-formpage.component.html',
  styleUrls: ['./promotion-code-formpage.component.scss']
})
export class PromotionCodeFormpageComponent implements OnInit {
  contentHeader: object;
  constructor() { }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'General.PromotionCodeManage',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.PromotionCodeManage',
            isLink: true,
            link: '/code-manage/promotion-code',
          },
          {
            name: 'General.Create',
            isLink: false,
          },
        ],
      },
    };
  }

}
