import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'app/main/components/components.module';
import { CoreCommonModule } from '@core/common.module';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSummernoteModule } from 'ngx-summernote';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { HighlightTechComponent } from './highlight-tech.component';
import { HighlightTechCreateComponent } from './highlight-tech-create/highlight-tech-create.component';

const path: string = 'cms/repove-tools/technology/hydroethosome/highlight-tech';
const routes: Routes = [
  {
    path: path,
    component: HighlightTechComponent,
    canActivate: [AuthGuard],
    data: { animation: 'highlight-management' },
  },
  {
    path: `${path}/create`,
    component: HighlightTechCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'highlight-create' },
  },
  {
    path: `${path}/edit/:id`,
    component: HighlightTechCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'highlight-edit' },
  },
]

@NgModule({
  declarations: [
    HighlightTechComponent,
    HighlightTechCreateComponent,
  ],
  exports: [
    HighlightTechComponent
  ],
  imports: [
    RouterModule.forChild(routes),
      ComponentsModule,
      ContentHeaderModule,
      CoreCommonModule,
      CommonModule,
      CorePipesModule,
      TranslateModule,
      NgxDropzoneModule,
      NgxSummernoteModule,
      FormsModule,
      ReactiveFormsModule,
      CoreTouchspinModule,
      NgbPaginationModule,
      NgbModule,
  ]
})
export class HighlightTechModule { }
