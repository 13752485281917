<div class="card m-2" style="min-height: 400px">

    <div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
        <app-loading></app-loading>
    </div>

    <div *ngIf="!isLoading">
        <div>
            <h2 class="my-2">{{ titleDoc }}</h2>
            <h2 *ngIf="detailObj?.customerPaymentTypeEnum">{{detailObj.customerPaymentTypeEnum == 2 ? 'โอนชำระ' : 
                detailObj.customerPaymentTypeEnum == 5 ? 'เก็บปลายทาง' : '' }}</h2>

            <div class="row" *ngIf="detailObj.customerPaymentTypeEnum != 5">
                <div class="col img-slip">
                    <img [src]="detailObj.mediaFiles.paymentEvidence ? getPic(detailObj.mediaFiles.paymentEvidence[0].id) : ''"
                        alt="">
                </div>
                <div class="col">
                    <div *ngIf="detailObj.mediaFiles.paymentEvidence" class="d-flex pb-2 border-bottom">
                        <div class="bankImg">
                            <img [src]="getBankImg(bankName)" alt="">
                        </div>
                      <div class="ml-1">
                            <h5>{{accountNumber ?? ''}}</h5>
                            <h5>{{bankName ?? ''}}</h5>
                            <h5>{{accountName ?? ''}}</h5>
                      </div>
                    </div>
                    <div class="mt-2">
                        <h4 *ngIf="currentLang != 'th'">{{ detailObj?.attachPaymentEvidenceDate ? (detailObj.attachPaymentEvidenceDate | date : "dd MMM YYYY") : '' }}
                            {{detailObj.attachPaymentEvidenceTime}}</h4>
                        <h4 *ngIf="currentLang != 'en'">{{ detailObj?.attachPaymentEvidenceDate ? (detailObj.attachPaymentEvidenceDate | thaidate : "DD MMM YYYY") : '' }}
                            {{detailObj.attachPaymentEvidenceTime ?? ''}}</h4>
                        <h2 class="text-secondary font-weight-bold ">{{formatNumberToMoney(detailObj.attachPaymentEvidenceTotal ?? 0)}} {{"General.Baht" | translate}}</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>