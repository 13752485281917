import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about.component';
import { AuthGuard } from 'app/auth/helpers';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';

const path: string = 'cms/about'
const routes: Routes = [
  {
    path: path,
    component: AboutComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-about' },
  },
];

@NgModule({
  declarations: [AboutComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CmsFormpageModule,
  ],
  exports: [AboutComponent],
})
export class AboutModule {}
