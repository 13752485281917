export class Invoice {
    id: string;
    isTaxInvoice: boolean;
    isTaxInvoiceCreatable: boolean;
    orderId: string;
    transactionStatusId: number;
    InventoryStatusId: number;
    saleOrderId: string;
    paymentTypeId: string;
    customerId: string;
    customerName: string;
    isHeadBranch: boolean;
    branchNumber: number;
    issuerId: string;
    issuerName: string;
    invoiceCode: string;
    monthId: number;
    monthName: string;
    documentCreateDate: string;
    documentExpireDate: string;
    references: string;
    remarks: string;
    isExcludeTax: boolean;
    vatExemptedAmountTotal: number;
    preDiscountAmountTotal: number;
    preTaxAmountTotal: number;
    discount: number;
    isDiscountByPercent: boolean;
    isVatExempt: boolean;
    taxRate: number;
    salesTax: number;
    salesWHT: number;
    preWHTAmountTotal: number;
    grandTotal: number;
    preparedById: string;
    preparedByName: string;
    isVoid: boolean;
    isActive: boolean;
    isDelete: boolean;
    createBy: string;
    createDate: string;
    updateBy: string;
    updateDate: string;
    deleteBy: string;
    deleteDate: string;
    item: InvoiceItem[];
    isAllCNCreate: boolean;
  }

  export interface InvoiceItem {
    id?: string;
    uniqueId: string;
    ordinal: number;
    invoiceId?: string;
    itemId: string;
    itemName?: string;
    itemCode?: string;
    quantity: number;
    unit: string;
    unitPrice: number;
    discount: number;
    isDiscountByPercent?: boolean;
    salesTax?: number;
    whtRate: number;
    salesWHT?: number;
    preTaxAmount?: number;
    isActive?: boolean;
    isDelete?: boolean;
    createBy?: string;
    createDate?: string;
    updateBy?: string;
    updateDate?: string;
    deleteBy?: string;
    deleteDate?: string;
    description: InvoiceDescription[];
    isAllCNCreate?: boolean;
  }

  export interface InvoiceDescription {
    id?: string;
    ordinal: number;
    invoiceItemId?: string;
    description: string;
    isActive?: boolean;
    isDelete?: boolean;
    createBy?: string;
    createDate?: string;
    updateBy?: string;
    updateDate?: string;
    deleteBy?: string;
    deleteDate?: string;
  }

  export class TempRowInvoice {
    rowType: 'item' | 'description';
    itemId: string;
    itemCode?: string;
    quantity: number;
    unit?: string;
    unitPrice: number;
    discount: number;
    isDiscountByPercent: boolean;
    description: any;
    whtRate: number;
    salesWHT: number;
  }
