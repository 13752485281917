import { Component, OnInit } from '@angular/core';
import { CertificationsCardService } from './certifications-card.service';
import { ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-certifications-card',
  templateUrl: './certifications-card.component.html',
  styleUrls: ['./certifications-card.component.scss']
})
export class CertificationsCardComponent implements OnInit {
  config!: ICMSConfig;
  keyName: string;

  constructor(private _certCardService: CertificationsCardService) {
    this.config = this._certCardService.config;
    this.keyName = this._certCardService.keyName;
  }

  ngOnInit(): void {
  }

}
