<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div class="card">
      <div class="card-body">
        <div class="">
          <ul ngbNav #navFilled="ngbNav" class="nav nav-pills" [destroyOnHide]="false" [activeId]="1">
            <li [ngbNavItem]="1">
              <a ngbNavLink (click)="CallDrawCharts()" class="justify-content-start"><i data-feather="users"
                  class="mr-1"></i>{{ "Dashboard.Member.Title" | translate }}</a>
              <ng-template ngbNavContent>
                <h3 class="general-title">{{ "Dashboard.Member.Title" | translate }}</h3>

                <app-member-chart #MemberChart></app-member-chart>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink (click)="CallDrawCharts()" class="justify-content-start"><i data-feather="dollar-sign"
                  class="mr-1"></i>{{ "Dashboard.Revenue.Title" | translate }}</a>
              <ng-template ngbNavContent>
                <h3 class="general-title">{{ "Dashboard.Revenue.Title" | translate }}</h3>

                <app-revenue-chart #RevenueChart></app-revenue-chart>
              </ng-template>
            </li>

            <li [ngbNavItem]="3">
              <a ngbNavLink (click)="CallDrawCharts()" class="justify-content-start"><i data-feather="trending-up"
                  class="mr-1"></i>{{ "Dashboard.ConversionRate" | translate }}</a>
              <ng-template ngbNavContent>
                <h3 class="general-title">{{ "Dashboard.ConversionRate" | translate }}</h3>

                <app-conversion-rate-chart #ConversionRateChart></app-conversion-rate-chart>
              </ng-template>
            </li>

            <li [ngbNavItem]="4">
              <a ngbNavLink (click)="CallDrawCharts()" class="justify-content-start"><i data-feather="airplay"
                  class="mr-1"></i>{{ "Dashboard.MPVSRevenue" | translate }}</a>
              <ng-template ngbNavContent>
                <h3 class="general-title">{{ "Dashboard.MPVSRevenue" | translate }}</h3>

                <app-mp-revenue-chart #MPRevenueChart></app-mp-revenue-chart>
              </ng-template>
            </li>

            <li [ngbNavItem]="5">
              <a ngbNavLink (click)="CallDrawCharts()" class="justify-content-start"><i data-feather="bar-chart-2"
                  class="mr-1"></i>{{ "Dashboard.YOY.Title" | translate }}</a>
              <ng-template ngbNavContent>
                <h3 class="general-title">{{ "Dashboard.YOY.Title" | translate }}</h3>

                <app-year-on-year-chart #YearOnYearChart></app-year-on-year-chart>
              </ng-template>
            </li>
          </ul>

          <hr class="my-2">
          <div [ngbNavOutlet]="navFilled" class="ml-1" style="width: -webkit-fill-available"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #mockChart> </ng-template>