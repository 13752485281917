import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild('test', { static: true })
  test: ElementRef<HTMLElement>;
  public contentHeader: object;

  currentUser: User;
  currentDate = new Date();
  currentLang: string;

  isLoading: boolean;
  appLogoImage: string = environment.appLogoImage;

  constructor(
    private _authService: AuthenticationService,
    private _translateService: TranslateService
  ) {
    this.currentUser = this._authService.currentUserValue;
  }

  ngOnInit(): void {
    console.log(this.test.nativeElement.getBoundingClientRect().height);

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    console.log(this.currentUser);

    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }
}
