import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FrontendUserService {
  componentName: string = 'Frontend User';
  apiPath: string = 'user/front';
  pathUrl: string = '/admin/frontend/users';

  constructor() { }
}
