import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
  ViewChildren,
  QueryList,
  Input,
} from '@angular/core';
import { ApiService } from 'app/main/service/api.service';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { DocumentService } from '../../document.service';
import { TranslateService } from '@ngx-translate/core';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { ComponentsService } from 'app/main/components/components.service';
import { ActivatedRoute, Router } from '@angular/router';
import { event } from 'jquery';
import { SelectCreditDebitComponent } from 'app/main/components/document/select-credit-debit/select-credit-debit.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddBillComponent } from 'app/main/components/document/template/add-bill/add-bill.component';
import Stepper from 'bs-stepper';
import { Quotation, TempRowQuotation } from 'app/main/model/Quotation';
import Customer from 'app/main/model/Customer';
import CustomerAddress from 'app/main/model/CustomerAddress';
import Item from 'app/main/model/Item';
import { TimelineItem } from 'app/main/components/horizontal-timeline/horizontal-timeline.component';
import { Subject } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { QuatationService } from '../../quotation/quotation.service';
import { CalculateFuncService } from 'app/main/service/calculate-func.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { PaymentComponent } from 'app/main/components/payment/payment.component';
import { User } from 'app/auth/models';
import { DebitNoteFormpageComponent } from '../../debit-note/debit-note-formpage/debit-note-formpage.component';
import { CreditNote } from 'app/main/model/CreditNote';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PaymentMethodList } from 'app/main/model/PaymentMethod';

@Component({
  selector: 'app-credit-note-formpage',
  templateUrl: './credit-note-formpage.component.html',
  styleUrls: ['./credit-note-formpage.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreditNoteFormpageComponent implements OnInit {
  @ViewChild('confirmModal') confirmModal: ModalComponent;
  @ViewChild('addBillComponent')
  addBillComponent: AddBillComponent;
  @ViewChild('selectCreditDebitComponent')
  selectCreditDebitComponent: SelectCreditDebitComponent;
  @ViewChild(PaymentComponent)
  paymentComponent: PaymentComponent;
  @ViewChildren('formTable') formTable: QueryList<ElementRef<HTMLLIElement>>;
  @Input() itemObj: CreditNote;

  @BlockUI() blockUI: NgBlockUI;

  private _unsubscribeAll: Subject<any> = new Subject();
  quotationData: any;
  formDocument: FormGroup;
  formPayment: FormGroup;
  paymentMethod: number;
  public sharedData: any;
  personalInfoForm: FormGroup;
  codeAddBill: string;
  creditDebitData: any;
  creditNoteStepper: Stepper;
  contentHeader: object;
  invoiceApiPath: string;
  creditNoteApiPath: string;
  creditNotePath: string;
  receiptApiPath: string;
  currentUser: User;
  apiPath: string;
  docID: string;
  callBackData: any;
  docCode: string;
  isSubmit: boolean;
  quotationForm: FormGroup;
  taxRate: number = 7.0;
  whtRate: number = 0;
  componentName: String;
  itemCounter: number = 0;
  rows: TempRowQuotation[] = [];
  draggingRow: any;
  currentDate: Date = new Date();
  nextWeekDate: Date = new Date();
  customerList: Customer[] = [];
  customerObj: Customer;
  customerAddressObj: CustomerAddress;
  itemList: Item[] = [];
  isLoading: boolean;
  payObj: any;
  isEditing: boolean = true;
  isVatExempted: boolean;
  isSetValueWhenInit: boolean = false;
  isExcludeTax: boolean = true;
  isSameWHTAllRow: boolean;
  isDiscountByPercent: boolean;
  whtList: any[] = [
    // { value: 0, label: 'ยังไม่ระบุ' },
    { value: 0, label: 'ไม่มี' },
    { value: 0.75, label: '0.75%' },
    { value: 1, label: '1%' },
    { value: 1.5, label: '1.5%' },
    { value: 2, label: '2%' },
    { value: 3, label: '3%' },
    { value: 5, label: '5%' },
    { value: 10, label: '10%' },
    { value: 15, label: '15%' },
  ];

  invoiceDocList: any[];

  passObj: any;

  reasonList: any[] = [
    {
      value: 1,
      label:
        '1. ลดราคาสินค้า เนื่องจากผิดจากที่ตกลงกัน ชำรุดเสียหายย ไม่ครบ หรือคำนวณราคาผิด',
    },
    {
      value: 2,
      label:
        '2. ลดราคาค่าบริการ เนื่องจากผิดจากที่ตกลงกัน ให้บริการไม่ครบ หรือคำนวณราคาผิด',
    },
    {
      value: 3,
      label:
        '3. ได้รับสินค้า เนื่องจากผิดจากชำรุดเสียหาย ไม่ครบ ไม่ตรงตามตัวอย่าง หรือไม่ตรงตามคำบรรยาย',
    },
    {
      value: 4,
      label:
        '4. จ่ายเงินชดเชย ให้แก่ผู้ซื้อสินค้า หรือผู้รับบริการตามข้อผูกพัน/ข้อตกลงกัน',
    },
    {
      value: 5,
      label:
        '5. จ่ายเงินคืนเงินล่วงหน้า เงินมัดจำ เงินประกัน เงินจอง ให้แก่ผู้ซื้อสินค้า หรือผู้รับบริการตามข้อผูกพัน/ข้อตกลงกัน',
    },
    {
      value: 6,
      label:
        '6. ได้รับคืนสินค้า หรือแลกเปลี่ยนสินค้า ระหว่างผู้ประกอบการ VAT ด้วยกัน',
    },
    {
      value: 7,
      label:
        '7. ได้รับสินค้า หรือแลกเปลี่ยนสินค้า ระหว่างผู้ขายกับลูกค้า (เฉพาะภายในเวลาอันเหมาะสม)',
    },
    {
      value: 8,
      label: '8. บอกเลิกบริการ เนื่องจากผิดจากที่ตกลงกัน',
    },
    {
      value: 9,
      label: '9. บอกเลิกบริการ เนื่องจากไม่ได้มีการให้บริการตามสัญญา',
    },
    {
      value: 10,
      label: '10. อื่นๆ',
    },
  ];

  creditNoteCode: string;

  timelineData: TimelineItem[];

  createDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: this.currentDate,
  };

  expiredDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: new Date(
      this.nextWeekDate.setDate(this.currentDate.getDate() + 7)
    ),
  };

  paidDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: new Date(),
  };

  selectedPaymentMethod: string;
  paymentMethodList: any[] = [{ amount: 1070, paidSolution: 1, note: '' }];

  //test payment
  pathUrl: string;
  editPathUrl: string;
  detailPathUrl: string;
  isConfirm: boolean = false;
  title: string;
  quatationObj: Quotation;

  isGridView: boolean = false;
  isShowChildList: boolean = false;
  isForGroupTemplate: boolean = false;
  isGoEditPage: boolean = false;
  isSendingData: boolean = false;
  nextButtonDisabled: boolean = true;
  activeStatus: string;
  selectedColumns: string[];
  objectExport: any;
  searchName: string;
  searchId: string;
  activeTab: string = 'all';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  itemInvalid: boolean[] = [];
  descriptionInvalid: boolean = false;

  currentStepIndex = 0;

  constructor(
    private _apiService: ApiService,
    private _documentApiService: DocumentApiService,
    private _documentService: DocumentService,
    private _translateService: TranslateService,
    private _globalFuncService: GlobalFuncService,
    private _dragulaService: DragulaService,
    private _modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _componentsService: ComponentsService,
    private _contentTemplateService: QuatationService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _calculateService: CalculateFuncService,
    private _componentService: ComponentsService
  ) {
    this.componentName = 'Item';
    this._unsubscribeAll = new Subject();
    this.componentName = this._contentTemplateService.componentName;
    this.timelineData = this._documentService.timelineData;
    this.creditNoteApiPath = this._documentService.creditNote.ApiPath;
    this.creditNotePath = this._documentService.creditNote.PathURL;
    this.invoiceApiPath = this._documentService.invoice.ApiPath;
    this.receiptApiPath = this._documentService.receipt.ApiPath;
    this.passObj = this._documentService.getDataNote();
    this.initData();
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }
  SetLoadedState(): void {
    this.isLoading = false;
  }

  previousPage(): void {
    this.addBillComponent.initDataForce();
    this.creditNoteStepper.previous();
  }

  previousFinalPage(): void {
    this.creditNoteStepper.previous();
  }

  nextPage(): void {
    if (!this.selectCreditDebitComponent.isValid()) {
      console.log('Please select your info.');
      return;
    }

    const creditDebitData = this.selectCreditDebitComponent.getData();
    const infoData = this.personalInfoForm.value;

    if (creditDebitData) {
      this._documentService.setDataNote('infoDocument', creditDebitData);
    }
    if (infoData) {
      this._documentService.setDataNote('infoDescription', infoData);
    }
    this.setFormDocument();
    if (this.validatorAddbill()) {
      return;
    } else {
      this.creditNoteStepper.next();
    }
  }

  validatorAddbill(): boolean {
    return this.addBillComponent.validator();
  }

  nextPageAddBill(): void {
    const creditDebitData = this.selectCreditDebitComponent.getData();
    const infoData = this.personalInfoForm.value;

    console.log('infoData : ', infoData);

    if (creditDebitData) {
      this._documentService.setDataNote('infoDocument', creditDebitData);
    }
    if (infoData) {
      this._documentService.setDataNote('infoDescription', infoData);
    }
    console.log(this._documentService.getDataNote());
    const noteObj = this._documentService.getDataNote();
    if (noteObj?.infoDocument?.docCode) {
      this.getRefDocData(noteObj.infoDocument.docCode);
    } else {
      console.error('docType is undefined or noteObj is null');
    }

    this.addBillComponent.initDataForce();
    this.addBillComponent.ngAfterViewInit();

    this.creditNoteStepper.next();
    console.log('PASSSSSSOBJ', this.formDocument);

    this.creditNoteCode = this.formDocument.value.creditNoteCode;
    console.log('CreditNote Code : ', this.creditNoteCode);
  }

  setPayObj(): void {
    this.payObj = this.addBillComponent.getGrandTotal();
  }

  handleCreditDebitData(data: any) {
    this.creditDebitData = data;
  }

  submit(): void {
    this.sharedData = this._documentService.getDataNote();
    console.log(this.sharedData);
  }

  initData(): void {
    const noteObj = this._documentService.getDataNote();
    this._documentApiService
      .GetDocRunningNumber(this.creditNoteApiPath)
      .subscribe((res) => {
        this.creditNoteCode = res.data.resultData[0];
      });
    this.getReferDocDataList();
    if (noteObj?.infoDocument?.docCode) {
      this.passObj = this.getRefDocData(noteObj.infoDocument.docCode);
    }
    console.log(this.passObj);
    this.getitemList();

    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
      this.isEditing = this.docID ? true : false;
    });

    this.isEditing = false;
    if (this.isEditing) {
      this.getQuatationData(this.invoiceApiPath, this.docID);
    } else {
      this.getLastCode();
      this.customerObj = new Customer();
      this.quotationData = {};
      this.SetLoadedState();
    }

  }

  onItemInvalidChange(itemInvalid: boolean[]) {
    this.itemInvalid = itemInvalid;
  }

  onDescriptionInvalidChange(descriptionInvalid: boolean) {
    this.descriptionInvalid = descriptionInvalid;
  }

  hasInvalidItem(): boolean {
    return this.itemInvalid.some((invalid) => invalid);
  }

  get f() {
    return this.quotationForm.controls;
  }

  ngAfterViewInit(): void {
    const paymentMethod = this.getPaymentMethod();
    const paymentDate = this.getPaymentDate();
    console.log('paymentMethod:', paymentMethod);
    console.log('paymentDate:', paymentDate);
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData[0];
        });
    });
  }

  getitemList(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('item', { isDelete: false })
        .subscribe((res) => {
          self.itemList = res.data.resultData;

          this.quotationData = {
            itemList: res.data.resultData,
          };
        });
    });
  }

  getLastCode(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocRunningNumber('Quotation')
        .subscribe((res) => {
          let docCode = document.getElementById('docCode') as HTMLFormElement;
          self.docCode = res.data.resultData[0];
          docCode.value = self.docCode;
        });
    });
  }

  getQuatationData(ApiPath, DocID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._documentApiService.GetDocData(ApiPath, DocID).subscribe(
        (res) => {
          const quotationObj = res.data.resultData[0];
          console.log('quotationObj : ', res.data.resultData[0]);

          this.passObj = quotationObj;
          this.isExcludeTax = quotationObj.isExcludeTax;
          // resolve(quotationObj);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  ngOnInit(): void {
    this.creditNoteStepper = new Stepper(
      document.querySelector('#credit-stepper'),
      {
        linear: false,
        animation: true,
      }
    );

    this.contentHeader = {
      headerTitle: 'Document.CreditNote',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Document',
            isLink: true,
            link: '/',
          },
          {
            name: 'Document.CreditNote',
            isLink: true,
            link: '/document/credit-note/view',
          },
          {
            name: 'FormName.CreditNoteForm',
            isLink: false,
            link: '/document/credit-note/view',
          },
        ],
      },
    };

    this.personalInfoForm = this._formBuilder.group({
      reasonId: [0, Validators.required],
      description: ['', Validators.required],
    });

    this.formDocument = this._formBuilder.group({
      creditNoteCode: ['', Validators.required],
      invoiceId: ['', Validators.required],
      documentCreateDate: ['', Validators.required],
      documentExpireDate: ['', Validators.required],
      remarks: '',
      reasonId: 0,
      reason: '',
      discount: 0,
      isDiscountByPercent: true,
      isVatExempt: true,
      taxRate: 0,
      isProductReceived: false,
      salesWHT: 0,
      shippingFee: 0,
      shippingAddressId: '',
      shippingAddressDetail: '',
      shippingPhone: '',
      shippingCustomerName: '',
      shippingCustomerTaxId: '',
      item: this._formBuilder.array([
        this._formBuilder.group({
          ordinal: 0,
          itemId: ['', Validators.required],
          quantity: 0,
          discount: 0,
          isDiscountByPercent: true,
          unit: '',
          whtPrice: 0,
          unitPrice: 0,
          whtRate: 0,
          description: this._formBuilder.array([
            this._formBuilder.group({
              ordinal: 0,
              description: '',
            }),
          ]),
        }),
      ]),
    });
  }

  ngOnDestroy(): void {
    this._documentService.clearDataNote();
    this.addBillComponent.ngOnDestroy();
    this._unsubscribeAll.complete();
    this._unsubscribeAll.next();
  }

  changeRefDoc(event: any) {
    console.log(event);
    // this.getRefDocData(event.target.value);
  }

  getReferDocDataList(): Promise<any> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(this.invoiceApiPath, {
          isDelete: false,
          isActive: true,
        })
        .subscribe((res) => {
          this.invoiceDocList = res.data.resultData;
        });
    });
  }

  handleFormValidityChanged(isValid: boolean): void {
    this.nextButtonDisabled = !isValid;
  }

  async getRefDocData(guid: string): Promise<any> {
    const noteObj = this._documentService.getDataNote();

    if (!noteObj || !noteObj.infoDocument || !noteObj.infoDocument.docType) {
      // Handle the case when 'docType' is missing or 'noteObj' is null/undefined
      console.error("Unable to retrieve 'docType' from 'noteObj.infoDocument'");
      return Promise.reject("Unable to determine 'docType'");
    }

    let path;
    if (noteObj.infoDocument.docType === 'receipt') {
      path = this.receiptApiPath;
      this.codeAddBill = 'receiptCode';
    } else if (noteObj.infoDocument.docType === 'invoice') {
      path = this.creditNoteApiPath;
      this.codeAddBill = 'invoiceCode';
    } else {
      // Handle the case when 'docType' is invalid or not supported
      console.error("Invalid 'docType' in 'noteObj.infoDocument'");
      return Promise.reject("Invalid 'docType'");
    }

    try {
      const res = await this._documentApiService
        .GetRemainingDocData(path, guid)
        .toPromise();
      this.passObj = res.data.resultData[0];
      console.log('passObj : ', this.passObj);
      return Promise.resolve(this.passObj);
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error('Error while fetching data:', error);
      return Promise.reject(error);
    }
  }

  formatMoney(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }

  addPaymentMethod() {
    this.paymentMethodList.push({ amount: 0, paidSolution: 1, note: '' });
  }

  setFormDocument() {
    let formValue = this.addBillComponent.getFormValue();
    console.log('formValue : ', formValue);

    let noteObj = this._documentService.getDataNote();
    let itemArray = this.formDocument.get('item') as FormArray;
    console.log(itemArray);

    itemArray.clear();
    console.log('FORMMMMMMVALUE', formValue);

    if (Array.isArray(formValue.item)) {
      console.log('YYYYYYYYYOYOYOYOYO', formValue);

      for (let item of formValue.item) {
        console.log('formValue.item : ', item);
        let itemFormGroup = this._formBuilder.group({
          ordinal: item.ordinal,
          itemId: [item.itemId, Validators.required],
          quantity: item.quantity,
          discount: item.discount,
          isDiscountByPercent: item.isDiscountByPercent,
          unit: item.unit,
          uniqueId: item.uniqueId,
          unitPrice: item.unitPrice,
          whtPrice: item.whtPrice,
          description: this._formBuilder.array([]), // Initialize an empty FormArray for 'description'
        });
        console.log('SHOW ADDBILL REBACK', itemFormGroup);

        let descriptionArray = itemFormGroup.get('description') as FormArray;
        for (let description of item.description) {
          let descriptionFormGroup = this._formBuilder.group({
            ordinal: description.ordinal,
            description: description.description,
          });
          descriptionArray.push(descriptionFormGroup);
        }
        itemArray.push(itemFormGroup);
      }
      console.log('ITEMARRAY PUSH', itemArray);
    }

    let invoiceId: string;
    let receiptId: string;
    if (noteObj.infoDocument.docType == 'invoice') {
      invoiceId = noteObj?.infoDocument?.docCode;
    }
    if (noteObj.infoDocument.docType == 'receipt') {
      receiptId = noteObj?.infoDocument?.docCode;
    }
    console.log(this.passObj.discount);

    console.log(
      formValue.shippingAddressId +
        formValue.shippingAddressDetail +
        formValue.shippingPhone
    );

    this.formDocument.patchValue({
      creditNoteCode: this.creditNoteCode,
      invoiceId: invoiceId,
      receiptId: receiptId,
      documentCreateDate: formValue.documentCreateDate,
      documentExpireDate: formValue.documentExpireDate,
      reason: this.personalInfoForm.value.description,
      reasonId: this.personalInfoForm.value.reasonId,
      remarks: formValue.remarks,
      discount: formValue.discount,
      shippingFee: formValue.shippingFee,
      shippingAddressId: formValue.shippingAddressId,
      shippingAddressDetail: formValue.shippingAddressDetail,
      shippingPhone: formValue.shippingPhone,
      shippingCustomerName: formValue.shippingCustomerName,
      shippingCustomerTaxId: formValue.shippingCustomerTaxId,
      isDiscountByPercent: formValue.isDiscountByPercent,
      isVatExempt: formValue.isVatExempt,
      taxRate: formValue.taxRate,
      isProductReceived: formValue.isProductReceived,
      salesWHT: formValue.salesWHT ? formValue.salesWHT : 0.0,
      isProductSent: false,
      // item: this.passObj.item,
    });
    console.log('formDocument : ', this.formDocument.value);

    return this.formDocument.value;
  }

  removePaymentMethod(index: number) {
    this.paymentMethodList.splice(index, 1);
  }

  onDataChange(event: any) {
    console.log('THIS DATA EVENTS', event);
  }

  getResultData(data: any): void {
    console.log('gsadfasdfasdsf', data);

    this.callBackData = data;
  }

  openConfirmModal(content: any): void {
    let isInvalidForm;

    isInvalidForm = this.paymentComponent.checkInvalidForm();

    let isConfirm = true;
    let title;
    let detail;
    let contentNameText = this.isEditing ? content.name : this.componentName;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.Edit');
    } else {
      title = this._translateService.instant('CreditNote.ConfirmCreateTitle');
      detail = this._translateService.instant('CreditNote.ConfirmCreate');
    }

    if (isInvalidForm) {
      console.error('Please select or fill all input');
    } else {
      this.openModal(title, detail, isConfirm);
    }
  }

  openModal(
    title: string,
    detail: string,
    IsConfirm: boolean,
    isDraft?: boolean
  ): void {
    if (this.formDocument.value) {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        console.log('res : ', res);

        if (IsConfirm) {
          this.Submit(false, this.formDocument.value, false);
        } else {
          this._router.navigate([`/document/quotation/view`]);
        }
      });
    } else {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        console.log('res : ', res);

        this._router.navigate([`/document/quotation/view`]);
      });
    }
    console.log('=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=', this.quotationForm);
  }

  Submit(
    isDraft?: boolean,
    quotationForm?: object,
    isEditing?: boolean,
    docID?: string
  ) {
    this.isSendingData = true;
    var self = this;
    console.log(quotationForm);
    if (isEditing) {
      this.blockUI.start();
      this._documentApiService
        .UpdateDocData(this.creditNoteApiPath, quotationForm, docID)
        .subscribe(
          (res) => {
            if (!isDraft) {
              this._documentApiService
                .ApproveDocData(this.creditNoteApiPath, docID)
                .subscribe((res) => {
                  if (res) {
                    this.blockUI.stop();
                    self._componentsService.SuccessSwal();
                    this.isSendingData = false;
                    this._router.navigate([
                      `/document/credit-note/detail/${docID}`,
                    ]);
                  }
                });
            } else {
              this.blockUI.stop();
              self._componentsService.SuccessSwal();
              this.isSendingData = false;
              self._router.navigate([`/document/credit-note/view`]);
            }
          },
          (err) => {
            this.blockUI.stop();
            self._componentsService.ErrorSwal();
            this.isSendingData = false;
            self._router.navigate([`/document/credit-note/view`]);
          }
        );
    } else {
      this.blockUI.start();
      console.log(this.creditNoteApiPath);
      console.log(quotationForm);

      let paymentTypeId = this.paymentComponent.getTypePaymentId();

      console.log('create');
      // this.isSendingData = false;
      // console.log(this.getPaymentMethod())
      this._documentApiService
        .AddDocData(this.creditNoteApiPath, this.setFormDocument())
        .subscribe(
          (res) => {
            let cntGuid = res.data.resultData[0].id;
            if (res) {
              if (paymentTypeId == PaymentMethodList.Cash.id) {
                this._documentApiService
                  .CashPayment(this.creditNoteApiPath, cntGuid, {
                    paymentDate: this.getPaymentDate(),
                    paymentRemarks: this.getPaymentRemarks(),
                  })
                  .subscribe((r) => {
                    this.blockUI.stop();
                    self._componentsService.SuccessSwal();
                    this.isSendingData = false;
                    this._router.navigate([
                      `/document/credit-note/detail/${cntGuid}`,
                    ]);
                  });
              } else if (paymentTypeId == PaymentMethodList.Transfer.id) {
                this._documentApiService
                  .TransferPayment(this.creditNoteApiPath, cntGuid, {
                    paymentDate: this.getPaymentDate(),
                    bankAccountId: this.getBankAccountId(),
                    paymentRemarks: this.getPaymentRemarks(),
                  })
                  .subscribe((r) => {
                    this.blockUI.stop();
                    self._componentsService.SuccessSwal();
                    this.isSendingData = false;
                    this._router.navigate([
                      `/document/credit-note/detail/${cntGuid}`,
                    ]);
                  });
              } else {
                this._documentApiService
                  .Payment(this.creditNoteApiPath, cntGuid, {
                    paymentTypeId: this.getPaymentMethod(),
                    paymentDate: this.getPaymentDate(),
                  })
                  .subscribe((r) => {
                    this.blockUI.stop();
                    self._componentsService.SuccessSwal();
                    this.isSendingData = false;
                    this._router.navigate([
                      `/document/credit-note/detail/${cntGuid}`,
                    ]);
                  });
              }
            }
            console.log(res.data.resultData[0]);
          },
          (err) => {
            this.blockUI.stop();
            self._componentsService.ErrorSwal();
            this.isSendingData = false;
            self._router.navigate([`/document/credit-note/view`]);
          }
        );
    }
  }

  getPaymentMethod(): string {
    let tempObjMethod = this.paymentComponent.getResult();
    console.log('tempObjMethod : ', tempObjMethod.controls);
    return tempObjMethod.controls.paymentTypeId.value;
  }

  getPaymentDate(): string {
    let tempObjDate = this.paymentComponent.getResult();
    console.log('tempObjDate : ', tempObjDate.controls);
    return tempObjDate.controls.paymentDate.value;
  }

  getBankAccountId(): string {
    let tempObjBankAccountId = this.paymentComponent.getResult();
    console.log('tempObjBankAccountId : ', tempObjBankAccountId.controls);
    return tempObjBankAccountId.controls.bankAccountId.value;
  }

  getPaymentRemarks(): string {
    let tempObjRemark = this.paymentComponent.getResult();
    console.log('tempObjRemark : ', tempObjRemark.controls);
    return tempObjRemark.controls.paymentRemarks.value;
  }

  handlePayment(event: Event) {
    console.log(event);
    // this.selectedPaymentMethod
  }
}
