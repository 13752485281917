import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';


@Component({
  selector: 'app-search-panel-code-manage',
  templateUrl: './search-panel-code-manage.component.html',
  styleUrls: ['./search-panel-code-manage.component.scss']
})
export class SearchPanelCodeManageComponent implements OnInit {
  @Input() pathUrl: string;
  @Input() forCompanentName: string;
  @Input() notShowCreateBtn: boolean = false;
  @Input() notForContentMode: boolean = false;
  @Input() isForGroupTemplate: boolean;
  @Input() isShowSortByGroup: boolean = false;
  @ViewChild('searchbar') searchBar: ElementRef;
  @ViewChild('activeFil') activeFil: ElementRef;

  @Input() isShowRoleSet: boolean = false;
  @Output() callSearch: EventEmitter<any> = new EventEmitter();
  // @Output() callReset: EventEmitter<any> = new EventEmitter();


  constructor() { }

  ResetSearch(): void {
    console.log('ResetSearch');
    this.searchBar.nativeElement.value = '';
    this.activeFil.nativeElement.value = 'all';
    this.callSearch.emit();
  }

  SubmitSearch(): void {
    console.log('SubmitSearch');
    let obj: any = {};

    const activeStr = this.activeFil.nativeElement.value;
    if (activeStr === 'active') {
      obj.isActive = true;
    } else if (activeStr === 'inactive') {
      obj.isActive = false;
    }
    
    obj.codeName = this.searchBar.nativeElement.value ?? '',

    console.log(obj);

    this.callSearch.emit(obj);
  }

  ngOnInit(): void {
    console.log(this.pathUrl);
  }

}
