import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TempRowQuotation } from '../model/Quotation';

@Injectable({
  providedIn: 'root',
})
export class CalculateFuncService {
  constructor(private http: HttpClient) {}

  calculateBeforeTax(
    row?: TempRowQuotation,
    isExcludeTax?: boolean,
    taxRate?: number
  ) {
    if (isExcludeTax) {
      let beforeTax = 0;
      if (row.rowType === 'item') {
        if (row.isDiscountByPercent) {
          let disWithPer = (row.unitPrice * row.discount) / 100;
          beforeTax = (row.unitPrice - disWithPer) * row.quantity;
        } else {
          beforeTax = (row.unitPrice - row.discount) * row.quantity;
        }
      }
      return beforeTax;
    } else {
      let beforeTax = 0;
      if (row.rowType === 'item') {
        if (row.isDiscountByPercent) {
          let disWithPer = (row.unitPrice * row.discount) / 100;
          beforeTax =
            ((row.unitPrice - disWithPer) / ((100 + taxRate) / 100)) *
            row.quantity;
        } else {
          beforeTax =
            ((row.unitPrice - row.discount) / ((100 + taxRate) / 100)) *
            row.quantity;
        }
      }
      return beforeTax;
    }
  }

  calculateNet(
    listRow?: TempRowQuotation[],
    isExcludeTax?: boolean,
    taxRate?: number,
    discount?: number,
    isSumDiscountByPercent?: boolean
  ) {
    discount = discount ? discount : 0;
    if (isExcludeTax) {
      let sum = 0;
      for (let row of listRow) {
        if (row.rowType === 'item') {
          let beforeTax = this.calculateBeforeTax(row, isExcludeTax, taxRate);
          sum += beforeTax;
        }
      }
      if (isSumDiscountByPercent) {
        sum -= sum * (discount / 100);
        return sum;
      }

      return sum - discount;
    } else {
      let sum = 0;
      for (let row of listRow) {
        if (row.rowType === 'item') {
          let beforeTax = this.calculateBeforeTax(row, isExcludeTax, taxRate);
          sum += beforeTax;
        }
      }
      if (isSumDiscountByPercent) {
        sum -= sum * (discount / 100);
        return sum;
      }
      return sum - discount / ((100 + taxRate) / 100);
    }
  }

  calculateWHT(netPrice: number, whtRate?: number) {
    return netPrice * (whtRate / 100);
  }

  calculateVat(totalNet: number, taxRate: number) {
    return totalNet * (taxRate / 100);
  }

  getSumWht(
    listRow: TempRowQuotation[],
    taxRate: number,
    discount: number,
    isExcludeTax: boolean,
    isSumDiscountByPercent?: boolean
  ) {
    if (isExcludeTax) {
      let sum = 0;
      let sumWht = 0;
      let length = listRow.length < 1 ? listRow.length : 1;

      listRow.forEach((element, index) => {
        if (element.rowType == 'item') {
          let net = 0;
          net = this.calculateBeforeTax(element, isExcludeTax, taxRate);
          sum += net;
        }
      });

      listRow.forEach((element, index) => {
        let weight = 0;
        if (element.rowType == 'item') {
          let net = 0;
          let weightedWHT = 0;
          let weightedDiscount = 0;
          let weightedPriceDiscount = 0;
          net = this.calculateBeforeTax(element, isExcludeTax, taxRate);
          weight = net / sum;
          if (!weight) {
            weight = 1;
          }

          if (isSumDiscountByPercent) {
            let tempDiscount = net * (discount / 100);
            weightedDiscount = tempDiscount * weight;
          } else {
            weightedDiscount = discount * weight;
          }

    

          weightedPriceDiscount = net - weightedDiscount;
          weightedWHT = weightedPriceDiscount * (element.whtRate / 100);
          sumWht += weightedWHT;
   
        }
      });
      return sumWht;
    } else {
      let sum = 0;
      let sumWht = 0;
      let length = listRow.length < 1 ? listRow.length : 1;

      listRow.forEach((element, index) => {
        if (element.rowType == 'item') {
          let net = 0;
          net = this.calculateBeforeTax(element, isExcludeTax, taxRate);
          sum += net;
        }
      });

      listRow.forEach((element, index) => {
        let weight = 0;
        if (element.rowType == 'item') {
          let net = 0;
          let weightedWHT = 0;
          let weightedDiscount = 0;
          let weightedPriceDiscount = 0;
          net = this.calculateBeforeTax(element, isExcludeTax, taxRate);
          weight = net / sum;
          // weightedDiscount = (discount / (100 + taxRate)) * 100 * weight;

          if (isSumDiscountByPercent) {
            let tempDiscount = net * (discount / 100);
            weightedDiscount = tempDiscount * weight;
          } else {
            weightedDiscount = (discount / (100 + taxRate)) * 100 * weight;
          }

          weightedPriceDiscount = net - weightedDiscount;
          weightedWHT = weightedPriceDiscount * (element.whtRate / 100);
          sumWht += weightedWHT;
        }
      });

      // console.log('include WHT: ', sumWht);

      if (!sumWht) {
        return 0.0;
      }
      return sumWht;
    }
  }

  getTotal(totalNet: number, vat: number) {
    return totalNet + vat;
  }

  getGrandTotal(totalNet: number, whtRate: number) {
    return totalNet - whtRate;
  }

  calculateSumaryDiscount(
    summaryPrice: number,
    discount: number,
    isPercent: boolean
  ) {
    if (isPercent) {
      summaryPrice *= discount / 100;
    } else {
      summaryPrice -= discount;
    }
    return summaryPrice;
  }
}
