import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriesCreateComponent } from './categories-create/categories-create.component';
import { CategoriesComponent } from './categories.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';
import { CmsListModule } from '../cms-list/cms-list.module';
import { ContentHeaderModule } from "../../../../layout/components/content-header/content-header.module";
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'app/main/components/components.module';
import { CoreCommonModule } from '@core/common.module';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSummernoteModule } from 'ngx-summernote';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

const path: string = 'cms/categories';
const routes: Routes = [
  {
    path: path,
    component: CategoriesComponent,
    canActivate: [AuthGuard],
    data: { animation: 'categories-management' },
  },
  {
    path: `${path}/create`,
    component: CategoriesCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'categories-create' },
  },
  {
    path: `${path}/edit/:id`,
    component: CategoriesCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'categories-edit' },
  },
]

@NgModule({
    declarations: [
        CategoriesComponent,
        CategoriesCreateComponent
    ],
    imports: [
      RouterModule.forChild(routes),
      ComponentsModule,
      ContentHeaderModule,
      CoreCommonModule,
      CommonModule,
      CorePipesModule,
      TranslateModule,
      NgxDropzoneModule,
      NgxSummernoteModule,
      FormsModule,
      ReactiveFormsModule,
      CoreTouchspinModule,
      NgbPaginationModule,
      NgbModule
    ]
})
export class CategoriesModule { }
