export class CreditNote {
  id: string
  orderId: string
  transactionStatusId: number
  InventoryStatusId: number
  invoiceId: string
  paymentTypeId: string
  paymentDate: string
  reason: string
  isProductSent: boolean
  customerId: string
  customerName: string
  isHeadBranch: boolean
  branchNumber: number
  issuerId: string
  issuerName: string
  creditNoteCode: string
  monthId: number
  monthName: string
  documentCreateDate: string
  documentExpireDate: string
  references: string
  remarks: string
  isExcludeTax: boolean
  vatExemptedAmountTotal: number
  preDiscountAmountTotal: number
  discount: number
  percentDiscount: number
  isDiscountByPercent: boolean
  isVatExempt: boolean
  preTaxAmountTotal: number
  taxRate: number
  salesTax: number
  salesWHT: number
  preWHTAmountTotal: number
  grandTotal: number
  preparedById: string
  preparedByName: string
  isVoid: boolean
  isActive: boolean
  isDelete: boolean
  createBy: string
  createDate: string
  updateBy: string
  updateDate: string
  deleteBy: string
  deleteDate: string
  item: CreditNoteItem[]
  }

  export interface CreditNoteItem {
    id: string
    uniqueId: string
    ordinal: number
    creditNoteId: string
    itemId: string
    itemName: string
    itemCode: string
    quantity: number
    unit: string
    unitPrice: number
    discount: number
    percentDiscount: number
    isDiscountByPercent: boolean
    salesTax: number
    whtRate: number
    salesWHT: number
    preTaxAmount: number
    isActive: boolean
    isDelete: boolean
    createBy: string
    createDate: string
    updateBy: string
    updateDate: string
    deleteBy: string
    deleteDate: string
    description: CreditNoteDescription[];
  }

  export interface CreditNoteDescription {
    id?: string;
    ordinal: number;
    creditNoteItemId?: string;
    description: string;
    isActive?: boolean;
    isDelete?: boolean;
    createBy?: string;
    createDate?: string;
    updateBy?: string;
    updateDate?: string;
    deleteBy?: string;
    deleteDate?: string;
  }

  export class TempRowCreditNote {
    rowType: 'item' | 'description';
    itemId: string;
    itemCode?: string;
    quantity: number;
    unit?: string;
    unitPrice: number;
    discount: number;
    isDiscountByPercent: boolean;
    description: any;
    whtRate: number;
    salesWHT: number;
  }