import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import News from 'app/main/model/News';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  public config: ICMSConfig = {
    apiPath: 'NewsEvent',
    pathUrl: '/cms/news',
    tableName: 'Content',
    model: new News(),
    isList: true,
    componentName: 'BreadCrumbs.CMS.News',
    formConfig: {
      reserved1: {
        type: FormType.Datepicker,
        validator: [Validators.required],
        label: 'News Date',
        description: 'วันที่ของข่าวสาร',
      },
      name: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'News Title',
        description: 'หัวข้อข่าวสาร',
      },
      itemNewsPic: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'News Picture',
        description: 'ภาพปก',
      },

      detail: {
        type: FormType.RichText,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'News Content',
        description: 'เนื้อหาของข่าวสาร',
      },

      subTitle: {
        type: FormType.Text,
        isMultipleLang: true,
        label: 'Quote',
        description: 'สโลแกนข่าวสาร',
      },

      isHighlight: {
        type: FormType.Switch,
        label: 'Set as Highlight',
        description: 'แสดงเป็นไฮไลท์',
      },

      isActive: {
        type: FormType.IsActive,
        label: 'Active Status',
        labelTranslate: 'Informative.ActiveStatus',
      },
    },
  }

  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'name';

  constructor() { }
}
