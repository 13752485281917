<section id="apex-column-charts">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="d-flex justify-content-center align-items-center my-2">
                    <div class="mr-1">
                        <label>{{ "Stock.From" | translate }}:</label>
                    </div>
                    <select class="col-2 custom-select mx-1" [(ngModel)]="selectedStartYear"
                        (change)="onStartYearChange()">
                        <option *ngFor="let year of yearList" [value]="year.toString()">{{ year }}</option>
                    </select>
                    <select class="col-2 custom-select mx-0" [disabled]="!selectedStartYear" [(ngModel)]="selectedStartMonth"
                        (change)="onStartMonthChange()">
                        <option *ngFor="let month of months" [value]="month.value.toString()">{{ month.name }}</option>
                    </select>


                    <div class="ml-3">
                        <label>{{ "Stock.To" | translate }}:</label>
                    </div>
                    <select class="col-2 custom-select mx-1" [disabled]="!selectedStartYear" [(ngModel)]="selectedEndYear" (change)="onYearChange()">
                        <option *ngFor="let year of selectedEndYearOptions" [value]="year">{{ year }}</option>
                    </select>
                    <select class="col-2 custom-select mx-0" [disabled]="!selectedStartYear" [(ngModel)]="selectedEndMonth" (change)="onMonthChange()">
                        <option *ngFor="let month of selectedEndMonthOptions" [value]="month.value">{{ month.name }}
                        </option>
                    </select>
                </div>

                <div class="d-flex justify-content-center">
                    <button class="btn btn-secondary" (click)="resetSearchCriteria()"><i data-feather="refresh-ccw"
                            class="mr-1"></i>{{
                        "General.Reset" | translate }}</button>

                    <button (click)="submitDate()" class="btn btn-primary mx-1" style="margin-left: 0.5rem">
                        <i data-feather="search" class="mr-1"></i>{{ "General.Search" | translate }}
                    </button>
                </div>

                <hr>
                <div class="d-flex justify-content-end align-items-center">
                    <button class="btn btn-primary" (click)="exportToExcel()">
                        <span>{{ "General.Export" | translate }}</span>
                    </button>
                </div>
                <div class="card-body px-0">
                    <div id="column-chart" #MPApexColumnChartRef>
                        <apx-chart [series]="MPApexColumnChart.series" [chart]="MPApexColumnChart.chart"
                            [xaxis]="MPApexColumnChart.xaxis" [stroke]="MPApexColumnChart.stroke"
                            [yaxis]="MPApexColumnChart.yaxis" [tooltip]="MPApexColumnChart.tooltip"
                            [colors]="MPApexColumnChart.colors" [legend]="MPApexColumnChart.legend"
                            [dataLabels]="MPApexColumnChart.dataLabels" [plotOptions]="MPApexColumnChart.plotOptions"
                            [grid]="MPApexColumnChart.grid" [noData]="MPApexColumnChart.noData"></apx-chart>
                    </div>
                </div>
                <div class="card-body px-0">
                    <div id="column-chart" #RevenueApexColumnChartRef>
                        <apx-chart [series]="RevenueApexColumnChart.series" [chart]="RevenueApexColumnChart.chart"
                            [xaxis]="RevenueApexColumnChart.xaxis" [stroke]="RevenueApexColumnChart.stroke"
                            [yaxis]="RevenueApexColumnChart.yaxis" [tooltip]="RevenueApexColumnChart.tooltip"
                            [colors]="RevenueApexColumnChart.colors" [legend]="RevenueApexColumnChart.legend"
                            [dataLabels]="RevenueApexColumnChart.dataLabels"
                            [plotOptions]="RevenueApexColumnChart.plotOptions"
                            [grid]="RevenueApexColumnChart.grid" [noData]="RevenueApexColumnChart.noData"></apx-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>