import { Component, OnInit } from '@angular/core';
import { BranchService } from '../branch.service';
import { ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-branch-create',
  templateUrl: './branch-create.component.html',
  styleUrls: ['./branch-create.component.scss']
})
export class BranchCreateComponent implements OnInit {

  config: ICMSConfig;

  constructor(private _branchService: BranchService) {
    this.config = this._branchService.config;
  }

  ngOnInit(): void {
  }

}
