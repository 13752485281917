import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brand-formpage',
  templateUrl: './brand-formpage.component.html',
  styleUrls: ['./brand-formpage.component.scss']
})
export class BrandFormpageComponent implements OnInit {
  contentHeader: object;

  constructor() { }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'General.BrandManage',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.BrandManage',
            isLink: true,
            link: '/item-manage/brand',
          },
          {
            name: 'General.Create',
            isLink: false,
          },
        ],
      },
    };
  }

}
