import { User } from 'app/auth/models';

export const locale = {
  lang: 'th',
  data: {
    autoForm: {
      typeAnyKey: 'ป้อนตัวอักษร......',
    },
    Alert: {
      lang: 'ฟิลด์นี้ยังไม่ได้ระบุเป็นภาษา : ',
      Success: 'เสร็จสิ้น',
      Confirm: 'ตกลง',
      Error: 'ผิดพลาด',
      SomethingWentWrong: 'เกิดข้อผิดพลาดบางอย่าง',
      Invalid: 'กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน',
      Approved: 'อนุมัติ',
      ApproveFail: 'อนุมัติล้มเหลว',
      PleaseCancelVoid: 'กรุณายกเลิกเอกสารที่เกี่ยวข้องก่อน',
      GetReceiptSuccess: 'รับใบเสร็จสำเร็จ',
      VoidGotReceiptSuccess: 'ยกเลิกรับใบเสร็จสำเร็จ',
      CancelDocumentSuccess: 'ยกเลิกเอกสารสำเร็จ',
      VoidDocumentSuccess: 'ยกเลิกเอกสารสำเร็จ',
      SignUpComplete: 'สมัครสมาชิกสำเร็จ',
      VerifyEmailSendSuccessfully: 'ส่งอีเมลยืนยันสำเร็จ',
      EmailNotExist: 'ไม่มีอีเมลนี้',
      PleaseTryAgain: 'โปรดลองอีกครั้ง',
      FormValidationFailed: 'ตรวจสอบข้อมูลแบบฟอร์มล้มเหลว',
      ImageUploadSuccessfully: 'อัปโหลดรูปภาพสำเร็จ',
      EmailResetPasswordHasSent: 'อีเมลสำหรับรีเซ็ตรหัสผ่านถูกส่งแล้ว',
      PasswordHasBeenReset: 'รหัสผ่านถูกรีเซ็ตแล้ว',
      max: 'สูงสุด',
      files: 'ไฟล์',
      maximize: 'ครบจำนวนที่สามารถเพิ่มได้แล้ว',
      ChooseFiles: 'เลือกไฟล์',
      Cancel: 'ยกเลิก',
      CodeQO: 'ใบเสนอราคาเลขที่ : {{docCode}}',
      GoToSO: 'ไปยังเอกสารใบสั่งขาย',
      CodeSO: 'ใบสั่งขายเลขที่ : {{docCode}}',
      GoToIV: 'ไปยังเอกสารใบแจ้งหนี้',
      CodeIV: 'ใบแจ้งหนี้เลขที่ : {{docCode}}',
      GoToRC: 'ไปยังเอกสารใบเสร็จรับเงิน',
      CodeRC: 'ใบเสร็จรับเงินเลขที่ : {{docCode}}',
      GoToTX: 'ไปยังเอกสารใบกำกับภาษี',
      CodeTX: 'ใบกำกับภาษีเลขที่ : {{docCode}}',
      CodePR: 'ใบขอสั่งซื้อเลขที่ : {{docCode}}',
      GoToPO: 'ไปยังเอกสารใบสั่งซื้อ',
      CodePO: 'ใบสั่งซื้อเลขที่ : {{docCode}}',
      GoToGR: 'ไปยังเอกสารใบรับสินค้า',
      CodeGR: 'ใบรับสินค้าเลขที่ : {{docCode}}',
    },
    Modal: {
      ConfirmTheCancellation: 'ยืนยันการยกเลิกเอกสาร',
      AreYouSureToCancellation:
        'คุณยืนยันที่จะยกเลิก{{title}} เลขที่ {{docCode}} ?',
      ConfirmTheCreateTaxInvoice: 'ยืนยันการสร้างใบกำกับภาษี',
      AreYouSureToCreateTaxInvoice:
        'คุณยืนยันที่จะสร้างใบกำกับภาษีจากใบแจ้งหนี้หรือไม่ ?',
      CancelCreateSeparateIV: 'ยกเลิกการสร้างเอกสารใบแจ้งหนี้แยก',
      AreYouSureToCancelCreateSeparateIV:
        'คุณยืนยันที่จะยกเลิกการสร้างใบแจ้งหนี้แยกหรือไม่ ?',
      CancelCreatePR: 'ยกเลิกการสร้างเอกสารใบขอสั่งซื้อ',
      AreYouSureToCancelCreatePR:
        'คุณยืนยันที่จะยกเลิกการสร้างใบขอสั่งซื้อหรือไม่ ?',
      CancelCreateSeparateGR: 'ยกเลิกการสร้างเอกสารใบรับสินค้าแยก',
      AreYouSureToCancelCreateSeparateGR:
        'คุณยืนยันที่จะยกเลิกการสร้างใบรับสินค้าแยกหรือไม่ ?',
      CancelEditPR: 'ยกเลิกการแก้ไขใบขอสั่งซื้อ',
      AreYouSureToCancelEditPR: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขใบขอสั่งซื้อนี้',
      SaveDraftPR: 'บันทึกร่างใบขอสั่งซื้อ',
      AreYouSureToSaveDraftPR: 'คุณแน่ใจไหมที่จะบันทึกร่างใบขอสั่งซื้อนี้',
      CreateSeparateIV: 'สร้างใบแจ้งหนี้แยก',
      AreYouSureToCreateSeparateIV: 'คุณแน่ใจไหมที่จะสร้างใบแจ้งหนี้แยกนี้',
      CreateSeparateGR: 'สร้างใบรับสินค้าแยก',
      AreYouSureToCreateSeparateGR: 'คุณแน่ใจไหมที่จะสร้างใบรับสินค้าแยกนี้',
      CreatePR: 'สร้างใบขอสั่งซื้อ',
      AreYouSureToCreatePR: 'คุณแน่ใจไหมที่จะสร้างใบขอสั่งซื้อ',
      ConfirmGetReceipt: 'ยืนยันการรับใบเสร็จ',
      AreYouSureToGetReceipt: 'คุณยืนยันที่จะรับใบเสร็จหรือไม่ ?',
      ConfirmVoidGotReceipt: 'ยืนยันการยกเลิกรับใบเสร็จ',
      AreYouSureToVoidGotReceipt: 'คุณยืนยันที่จะยกเลิกการรับใบเสร็จหรือไม่ ?',
      ApprovePO: 'ยืนยันการอนุมัติใบสั่งซื้อ',
      AreYouConfirmToApprovePO: 'คุณยืนยันที่จะอนุมัติใบสั่งซื้อนี้หรือไม่ ?',
      CancelManagePage: 'ยกเลิกการแก้ไขข้อมูล',
      CancelManage: 'ยกเลิกการจัดการ',
      PressSubmitToCancel: 'คุณแน่ใจที่จะยกเลิกการบันทึกข้อมูลเหล่านี้หรือไม่?',
      ConfirmEditPage: 'ยืนยันการแก้ไขหน้า',
      ConfirmEdit: 'ยืนยันการแก้ไข',
      AreYouSureToEditPage: 'คุณยืนยันที่จะแก้ไขหน้า',
      AreYouSureToEdit: 'คุณยืนยันที่จะแก้ไข',
      ConfirmCreate: 'ยืนยันการบันทึกข้อมูล',
      AreYouSureToCreate: 'คุณยืนยันที่จะบันทึกข้อมูลเหล่านี้หรือไม่?',
      ConfirmEditNewsLandingPage: 'ยืนยันการแก้ไขข่าวสาร Landing Page',
      AreYouSureToEditNewsLandingPage:
        'คุณยืนยันที่จะแก้ไขหน้าข่าวสาร Landing Page',
      CancelManageNewsLandingPage: 'ยกเลิกการจัดการหน้าข่าวสาร Landing Page',
    },
    ErrorMessage: {
      QuantityMustNotBeNegative: '*จำนวนสินค้า/บริการ ต้องไม่เป็นค่าติดลบ',
      QuantityMustNotBeNegativeOrExceedTheRemaining:
        '*จำนวนสินค้า/บริการ ต้องไม่มากกว่ายอดจำนวนคงเหลือหรือไม่เป็นค่าติดลบ',
      ProductPriceMustNotBeNegative: '*ราคาสินค้าต้องไม่เป็นค่าติดลบ',
      ProductPriceMustNotBeNegativeOrExceedTheOriginalAmount:
        '*ราคาสินค้าต้องไม่มากกว่ายอดเดิมหรือต้องไม่เป็นค่าติดลบ',
      DiscountMustNotBeNegativeOrExceedTheProductPrice:
        '*ราคาส่วนลดต้องไม่มากกว่าราคาสินค้าหรือต้องไม่เป็นค่าติดลบ',
      TotalDiscountMustNotBeNegative: '**ยอดส่วนลดรวมต้องไม่เป็นค่าติดลบ',
      TotalDiscountMustNotExceed: '**ยอดส่วนลดรวมต้องไม่เกิน',
      AndMustNotBeNegative: 'และไม่เป็นค่าติดลบ',
      GrandTotalAmountMustNotBe0Baht:
        '**จำนวนเงินที่ต้องชำระต้องไม่น้อยกว่า 0 บาท',
      QuantityGreaterThanRemaining: '*จำนวนสินค้า/บริการ มีมากกว่าค่าคงเหลือ',
      DescNotFirstRow:
        'แถวคำอธิบายไม่สามารถอยู่ก่อนหน้าแถวรายการสินค้าแรกในรายการ',
      PleaseSelectItem: '*โปรดเลือกรายการสินค้า',
    },
    Handle: {
      Name: 'กรุณาระบุชื่อ',
      Exceed: 'ไม่สามารถเกิน',
      Char: 'ตัวอักษร',
      Group: 'กรุณาระบุกลุ่ม',
      Title: 'กรุณาระบุหัวข้อ',
      SubTitle: 'กรุณาระบุหัวข้อย่อย',
      Category: 'กรุณาระบุหมวดหมู่',
      Class: 'กรุณาระบุคลาสย่อย',
      classId: 'กรุณาระบุคลาส',
      Detail: 'กรุณาระบุเนื้อหา',
      ExceedChar: 'ชื่อต้องมีความยาวไม่เกิน 50 ตัวอักษร',
      UserName: 'กรุณาระบุชื่อผู้ใช้งาน',
      ExceedCharUserName: 'ชื่อผู้ใช้งานต้องมีความยาวไม่เกิน 100 ตัวอักษร',
      Email: 'กรุณาระบุอีเมล',
      EmailSyntax: 'กรุณาระบุอีเมลให้ถูกต้อง',
      LastName: 'กรุณาระบุนามสกุล',
      ExceedCharLastName: 'นามสกุลต้องมีความยาวไม่เกิน 100 ตัวอักษร',
      ExceedCharName: 'ชื่อจริงต้องมีความยาวไม่เกิน 100 ตัวอักษร',
      DescriptionAllLanguage: 'กรุณาระบุคำอธิบายให้ครบทุกภาษา',
      DetailAllLanguage: 'กรุณาระบุรายละเอียดให้ครบทุกภาษา',
      SingleImage: 'กรุณาเลือกรูปภาพ',
      MutipleFile: 'กรุณาเลือกไฟล์',
      FirstLetterInvalid: 'ตัวอักษรตัวแรกห้ามเป็นตัวอักษรพิเศษ',
      CodeName: 'กรุณาระบุชื่อโปรโมชั่น',
      RedeemCode: 'กรุณาระบุโค้ดโปรโมชั่น',
      Amount: 'กรุณาระบุจำนวน',
      Discount: 'กรุณาระบุส่วนลด',
      AmountPerCustomer: 'กรุณาระบุจำนวนโค้ดที่ลูกค้าสามารถใช้ซ้ำได้',
      StartDate: 'กรุณาระบุวันที่เริ่มต้น',
      EndDate: 'กรุณาระบุวันที่สิ้นสุด',
      noWhitespaceValidator: 'ห้ามมีช่องว่าง',
    },
    General: {
      Save: 'บันทึก',
      Action: 'การกระทำ',
      PressToCancel: 'กดปุ่มยืนยันเพื่อยกเลิก',
      CancelAction: 'ยกเลิกการกระทำที่เกี่ยวกับ',
      CancelEdit: 'ยกเลิกการแก้ไข',
      CancelCreate: 'ยกเลิกการสร้าง',
      Cancel: 'ยกเลิกการจัดการ',
      General: 'ทั่วไป',
      Default: 'ค่าเริ่มต้น',
      Deprecated: 'ยกเลิก',
      Gender: 'เพศ',
      Detail: 'รายละเอียด',
      Date: 'วันที่',
      Total: 'มูลค่า',
      Buy: 'ซื้อ',
      Baht: 'บาท',
      Sell: 'ขาย',
      OrderHistory: 'ประวัติการซื้อ-ขาย',
      RankUpHistory: 'ประวัติแรงค์',
      Check: 'ตรวจสอบ',
      Copy: 'คัดลอก',
      Quotation: 'ใบเสนอราคา',
      Confirm: 'ยืนยันการ',
      ConfirmCreate: 'ยืนยันการสร้าง ',
      AreYouConfirm: 'คุณแน่ใจไหมที่จะ',
      toCreate: 'ที่จะสร้าง',
      toEdit: 'ที่จะแก้ไข',
      Home: 'หน้าหลัก',
      Admin: 'ผู้ดูแลระบบ',
      Profile: 'โปรไฟล์',
      ACLManage: 'จัดการ ACL',
      UserManage: 'จัดการผู้ใช้งาน',
      RolesManage: 'จัดการระดับผู้ใช้งาน',
      Banner: 'แบนเนอร์',
      BannerManage: 'จัดการแบนเนอร์',
      BrandManage: 'จัดการแบรนด์',
      Brand: 'แบรนด์',
      News: 'ข่าวสาร',
      NewsManage: 'จัดการข่าวสาร',
      HelpCenter: 'ศูนย์ช่วยเหลือ',
      BonusPlan: 'โบนัสแพลน',
      FAQ: 'คำถามที่พบบ่อย',
      Item: 'สินค้า',
      ContentManage: 'จัดการเนื้อหา',
      RelatedDocument: 'เอกสารที่เกี่ยวข้อง',
      ItemManage: 'จัดการสินค้า',
      ItemSetManage: 'จัดการชุดสินค้า',
      GroupManage: 'จัดการกลุ่ม',
      CategoryManage: 'จัดการหมวดหมู่',
      SubCategoryManage: 'จัดการหมวดหมู่ย่อย',
      ClassManage: 'จัดการคลาส',
      SubClassManage: 'จัดการคลาสย่อย',
      Purchasing: 'ระบบจัดซื้อ',
      PurchaseOrder: 'ใบสั่งซื้อ',
      ControlPanel: 'แผงควบคุม',
      Create: 'สร้าง',
      EditUp: 'แก้ไข',
      Edit: 'แก้ไข',
      Export: 'พิมพ์ทั้งหมด',
      Delete: 'ลบ',
      Add: 'เพิ่ม',
      Manage: 'จัดการ',
      PDPA: 'PDPA',
      Event: 'อีเวนต์',
      Search: 'ค้นหา',
      SearchBy: 'ค้นหาด้วย',
      SearchKeyword: 'คำค้นหา',
      SortBy: 'จัดเรียงโดย',
      Status: 'สถานะ',
      All: 'ทั้งหมด',
      Draft: 'ร่าง',
      Approve: 'อนุมัติ',
      Rejects: 'ไม่อนุมัติ',
      Bin: 'ถังขยะ',
      Previous: 'ก่อนหน้า',
      Next: 'ถัดไป',
      Change: 'เปลี่ยน',
      Settings: 'การตั้งค่า',
      Group: 'กลุ่ม',
      Category: 'หมวดหมู่',
      SubCategory: 'หมวดหมู่ย่อย',
      Class: 'คลาส',
      SubClass: 'คลาสย่อย',
      ItemManagement: 'จัดการสินค้า',
      Customer: 'ผู้ติดต่อ',
      Sale: 'เอกสารซื้อขาย',
      Account: 'จัดการบัญชี',
      Trash: 'ถังขยะ',
      Document: 'เอกสาร',
      RevenueDocument: 'เอกสารรายรับ',
      ExpensesDocument: 'เอกสารรายจ่าย',
      Revenue: 'รายรับ',
      Exoenses: 'รายจ่าย',
      ViewAll: 'ดูทั้งหมด',
      Stock: 'คลังสินค้า',
      StockManage: 'จัดการคลังสินค้า',
      Reset: 'รีเซ็ต',
      Back: 'กลับ',
      Expired: '(หมดอายุ)',
      DocumentInfo: 'ข้อมูลเอกสาร',
      SlipInfo: 'ข้อมูลสลิป',
      PaymentInfo: 'ข้อมูลการชำระ',
      HistoryInfo: 'ข้อมูลประวัติ',
      Percent: 'เปอร์เซ็นต์',
      Remain: 'เหลือ',
      InStock: 'ในคลัง',
      Original: 'ยอดเดิม',
      Product: 'สินค้า',
      OurTechnology: 'เทคโนโลยีของเรา',
      About: 'เกี่ยวกับ',
      NewsEvent: 'ข่าวสารและกิจกรรม',
      LandingPage: 'รวมข่าวสาร',
      ConsumerReview: 'Consumer Review',
      CompanyVideo: 'วีดีโอของบริษัท',
      Contact: 'ติดต่อ',
      PrivacyPolicy: 'นโยบายความเป็นส่วนตัว',
      CookiePolicy: 'ข้อตกลงการใช้คุกกี้',
      TermsCondition: 'เงื่อนไขและข้อตกลง',
      SellerManage: 'Seller Management Regulation',
      ShoppingCart: 'ตะกร้าสินค้า',
      SignIn: 'ลงชื่อเข้าใช้',
      RoleSet: 'ระดับผู้ใช้งาน',
      ValueWaitToBePaid: 'มูลค่าที่รอรับชำระอีก',
      ValueWaitToPaid: 'มูลค่าที่รอชำระอีก',
      ReceivePayment: 'รับชำระ',
      Payment: 'ชำระ',
      SystemSettings: 'ตั้งค่าระบบ',
      Downline: 'ดาวน์ไลน์',
      SaleDashboard: 'ภาพรวมการขาย',
      DeliveryProvider: 'บริษัทขนส่ง',
      Cod: 'เก็บเงินปลายทาง',
      TransferPayment: 'โอนชำระ',
      ManageCode: 'จัดการโค้ด',
      PromotionCode: 'โค้ดโปรโมชั่น',
      PromotionCodeManage: 'จัดการโค้ดโปรโมชั่น',
      ConfirmReceivePayment: 'ยืนยันการรับเงินแบบปลายทาง',
      AreYouSureToReleaseCOD:
        'คุณแน่ใจที่จะปล่อยสินค้าที่มีการชำระเงินแบบ COD หรือไม่? \nหากยกเลิกจะเป็นการยกเลิกรายการซื้อขายนี้ทันที',
      ConfirmCancelOrdertitle: 'ยืนยันการยกเลิกรายการซื้อขาย',
      ConfirmCancelOrdertitleDetail:
        'คุณยืนยันที่จะยกเลิกรายการซื้อขายนี้หรือไม่',
      Success: 'สำเร็จ',
      CancelOrderSuccess: 'ยกเลิกรายการซื้อขายสำเร็จ',
      PaymentType: 'ช่องทางการชำระ',
      CashOnDelivery: 'เก็บเงินปลายทาง',
      BankTransfer: 'โอนเงินผ่านธนาคาร',
      RepoveToolsLandingPage: 'หน้ารวม',
      TechProductLandingPage: 'หน้ารวมเทคโนโลยีและสินค้า',
      OurCompany: 'บริษัทของเรา',
      Timeline: 'ไทม์ไลน์',
      CompanyCard: 'การ์ดบริษัท',
      OfficeBranch: 'สาขา',
      Booster: 'บูสเตอร์',
      Serum: 'เซรั่ม',
      Cream: 'ครีม',
      SunProtector: 'ครีมกันแดด',
      Bodywash: 'ครีมอาบน้ำ',
      Technology: 'เทคโนโลยี',
      Highlight: 'ไฮไลท์',
      ETCTechnology: 'เทคโนโลยีอื่นๆ',
      Certification: 'ใบรับรอง',
      CertificationCard: 'การ์ดใบรับรอง',
      CertificationFile: 'ไฟล์ใบรับรอง',
      Highest: 'มากสุด',
      Lowest: 'น้อยสุด',
      ProductDetail: 'รายละเอียดสินค้า',
    },
    Form: {
      Name: 'ชื่อ',
      Barcode: 'บาร์โค้ด',
      Description: 'คำอธิบาย',
      Detail: 'รายละเอียด',
      Status: 'สถานะ',
      Quantity: 'จำนวน',
      Price: 'ราคา',
      purchasePrice: 'ราคาซื้อ',
      SellingPrice: 'ราคาขาย',
      Group: 'กลุ่ม',
      Images: 'รูปภาพ',
      Cancel: 'ยกเลิก',
      Confirm: 'ตกลง',
      Submit: 'ยืนยัน',
      RefText1: 'ข้อความอ้างอิง 1',
      Title: 'หัวข้อ',
      Subtitle: 'คำบรรยายย่อย',
      Details: 'รายละเอียด',
      ButtonCaption: 'คำอธิบายปุ่ม',
      ButtonURL: 'ลิงก์ปุ่ม',
      Files: 'ไฟล์',
      FieldRequired: 'จำเป็นต้องกรอกข้อมูลในฟิลด์นี้!',
      Attract: 'แนบไฟล์',
      Cover: 'ปก',
      SaveDraft: 'บันทึกร่าง',
      ApproveDoc: 'อนุมัติรายการ',
      CreateGRFormPO: 'สร้างใบรับสินค้าจากใบสั่งซื้อ',
      IsShowing: 'โชว์ที่เว็บไซต์',
      IsBestSeller: 'สินค้าขายดี',
      IsNewProduct: 'สินค้าใหม่',
      EnterDescription: 'กรอกคำอธิบาย...',
      EnterName: 'กรอกชื่อ...',
      BeginTypeHere: 'เริ่มพิมพ์ได้ที่นี่...',
      Required: 'กรุณากรอกช่องนี้...',
    },
    PromotionForm: {
      PromotionName: 'ชื่อโปรโมชัน',
      RedeemCode: 'โค้ดโปรโมชั น',
      Amount: 'จำนวนโค้ด',
      UnlimitedCode: 'ไม่จำกัดจำนวนโค้ด',
      Discount: 'ส่วนลด',
      PercentDiscount: 'ส่วนลดเป็นเปอร์เซ็นต์',
      NumberCanReuse: 'จำนวนโค้ดที่ลูกค้าสามารถใช้ซ้ำได้',
      UnlimitedReusable: 'ส่วนลดใช้ซ้ำได้ไม่จำกัดจำนวน',
      StartDate: 'วันที่เริ่มต้น',
      EndDate: 'วันที่สิ้นสุด',
      DiscountNoExpire: 'ส่วนลดไม่มีวันหมดอายุ',
      CancelEditTitle: 'ยกเลิกการแก้ไขการจัดการโปรโมชันโค้ด',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขการจัดการโปรโมชันโค้ด',
      CancelAddTitle: 'ยกเลิกการเพิ่มการจัดการโปรโมชันโค้ด',
      CancelAdd: 'คุณแน่ใจไหมที่จะยกเลิกการจัดการโปรโมชันโค้ด',
      ConfirmEditTitle: 'ยืนยันการแก้ไขการจัดการโปรโมชันโค้ด',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขการจัดการโปรโมชันโค้ด',
      ConfirmAddTitle: 'ยืนยันการเพิ่มการจัดการโปรโมชันโค้ด',
      ConfirmAdd: 'คุณแน่ใจไหมที่จะเพิ่มการจัดการโปรโมชันโค้ด',
    },
    ProductForm: {
      FormTitle: 'หน้าสินค้า',
      Product: 'จัดการหน้าสินค้า',
      Title: 'หัวข้อ',
      Detail: 'รายละเอียด',
      Icon: 'ไอคอน',
      Label: 'ป้าย',
      UsingDetailList: 'ใช้งานเมนูรายละเอียด',
      UsingLabel: 'ใช้งานป้ายคุณลักษณะ',

      PCRow: 'ตำแหน่งแถวสำหรับคอมพิวเตอร์',
      PCColumn: 'ตำแหน่งคอลลัมน์สำหรับคอมพิวเตอร์',
      MBRow: 'ตำแหน่งแถวสำหรับมือถือ',
      MBColumn: 'ตำแหน่งคอลลัมน์สำหรับมือถือ',
      LandingCoverImage: 'รูปปกในหน้ารวม',
      PresenterImage: 'รูปพรีเซนเตอร์',
      TechnologyCoverImage: 'รูปประกอบเทคโนโลยี',
      ProductImage: 'รูปสินค้า',
      ThaiName: 'ชื่อสินค้าภาษาไทย',
      EnglishName: 'ชื่อสินค้าภาษาอังกฤษ',
      Price: 'ราคา',
      Volume: 'น้ำหนัก',
      ProductDetail: 'รายละเอียดสินค้า',

      DetailIcon: 'ไอคอนรายละเอียด',
      TechnologyDetail: 'รายละเอียดเทคโนโลยี',
      FeatureTechTitle: 'หัวข้อคุณสมบัติเด่น',
      OtherFeatureTechTitle: 'หัวข้อคุณสมบัติอื่นๆ',
      ComponentDetail: 'รายละเอียดส่วนประกอบ',
      TestResult: 'ผลการทดสอบ',
      TBTestResult: 'ผลการทดสอบ สำหรับแท็บเล็ต',
      MBTestResult: 'ผลการทดสอบ สำหรับมือถือ',

      BeforeAfterImage: 'รูปภาพก่อนใช้และหลังใช้',
    },

    ProductDetailForm: {
      Product: 'จัดการรายละเอียดสินค้า',
      ProductDetail: 'รายละเอียดสินค้า',
      ItemIdName: 'สินค้า',
      ItemName: 'ชื่อรายละเอียด',
      HowItWorkTitle: 'หัวข้อ ทำงานได้อย่างไร',
      HowItWorkDetail: 'รายละเอียด ทำงานได้อย่างไร',
      ActiveIngredientsTitle: 'หัวข้อ ส่วนประกอบ',
      ActiveIngredientsDetail: 'รายละเอียด ส่วนประกอบ',
      HowToUseTitle: 'หัวข้อ วิธีการใช้งาน',
      HowToUseDetail: 'รายละเอียด วิธีการใช้งาน',
    },

    Status: {
      Active: 'เปิดใช้งาน',
      Inactive: 'ปิดใช้งาน',
      Published: 'เผยแพร่แล้ว',
      Unpublished: 'ยังไม่เผยแพร่',
      Publish: 'เผยแพร่',
      Unpublish: 'ไม่เผยแพร่',
      Draft: 'ร่าง',
      AwaitApproval: 'รออนุมัติ',
      Accepted: 'อนุมัติ',
      AwaitPayment: 'รอชำระเงิน',
      Paid: 'ชำระแล้ว',
      Expired: 'หมดอายุ',
      Overdue: 'พ้นกำหนด',
      Voided: 'ยกเลิก',
      Cancel: 'ยกเลิก',
      GotReceipt: 'รับใบเสร็จแล้ว',
      Pending: 'รอดำเนินการ',
      Complete: 'เสร็จสิ้น',
      WaitingAdjust: 'รอจัดการสินค้า',
    },
    BonusPlan: {
      Active: 'คุณแน่ใจไหมที่จะ ',
      DeleteTitle: 'ยืนยันการลบ ',
      Delete: 'คุณแน่ใจไหมที่จะลบ ',
      UpdateTitle: 'ยืนยันการอัปเดต',
      Update: 'คุณแน่ใจไหมที่จะอัปเดต ',
      AddTitle: 'ยืนยันการเพิ่มข้อมูล',
      Add: 'คุณแน่ใจไหมที่จะเพิ่มข้อมูล',
      PersonalBonus: 'โบนัสส่วนบุคคล',
      DownlineBonus: 'โบนัสดาวน์ไลน์',

      SystemBonus: 'โบนัสของระบบ',
      Condition: 'เงื่อนไข',
      BonusCondition: {
        PersonalSale: 'ยอดขายส่วนบุคคล 30,000 + SV ใน 1 Period',
        JoinBonus: 'เข้าร่วม 100%',
        MPBonus: 'โบนัส MP',
      },
      SystemBonusHistory: 'ประวัติโบนัสของระบบ',
      SVHistory: 'ประวัติอัตราการแลกเปลี่ยน SV',
      UpdateBy: 'อัพเดตโดย',
      UpdateDate: 'วันที่อัพเดต',

      SVExchange: 'อัตราการแลกเปลี่ยน SV',
      EditSV: 'แก้ไขอัตราการแลกเปลี่ยน',

      BonusPlanManage: 'จัดการโบนัสแพลน',
      PersonalBonusManage: 'ตารางโบนัสส่วนบุคคล',
      DownlineBonusManage: 'ตารางโบนัสดาวน์ไลน์',
      SystemBonusManage: 'ตารางโบนัสของระบบ',

      Status: {
        InActive: 'ปิดใช้งาน',
        Active: 'เปิดใช้งาน',
        Create: 'สร้าง',
        Deleted: 'ลบ',
      },
    },
    Sort: {
      AscChar: 'A-Z',
      DescChar: 'Z-A',
      Newest: 'ใหม่ที่สุด',
      Oldest: 'เก่าที่สุด',
    },
    Menu: {
      Document: {
        QO: 'ใบเสนอราคา (QO)',
        SO: 'ใบสั่งขาย (SO)',
        IV: 'ใบแจ้งหนี้ (IV)',
        TX: 'ใบกำกับภาษี (TX)',
        RC: 'ใบเสร็จรับเงิน (RC)',
        PR: 'ใบขอสั่งซื้อ (PR)',
        PO: 'ใบสั่งซื้อ (PO)',
        GR: 'ใบรับสินค้า (GR)',
        CNT: 'ใบลดหนี้ (CNT)',
        DBN: 'ใบเพิ่มหนี้ (DBN)',
      },
      Profile: 'โปรไฟล์',
      Logout: 'ออกจากระบบ',
      Settings: 'การตั้งค่า',
      FrontendControlPanel: 'แผงควบคุมสำหรับเว็บหน้าบ้าน',
    },
    Dashboard: {
      Title: 'แดชบอร์ด',
      ExecutiveSummaryDashboard: 'แดชบอร์ดสรุปการบริหาร',
      RevenueData: 'ข้อมูลรายได้',
      MemberData: 'ข้อมูลสมาชิก',
      BookingCalendar: 'ปฏิทินการจอง',
      BookingCalendarData: 'ปฏิทินข้อมูลการจอง',
      ApproveBookingList: 'รายการอนุมัติการจอง',
      ReturnProductRequest: 'คำขอคืนสินค้า',
      Revenue: {
        Title: 'รายได้',
        Month: 'เดือน',
        Target: 'เป้าหมาย',
        Year: 'ปี',
        TargetValue: 'ยอดเป้าหมาย',
        Classifier: 'หน่วย',

        Baht: 'บาท',
        TargetAccum: 'เป้าหมายสะสม',
        TargetSales: 'เป้าหมายการขาย',

        Item: 'ไอเทม',
        SelectItem: 'เลือกไอเทม',
      },
      Member: {
        Title: 'สมาชิก',
        TrackingTitle: 'Member Data Trackings',
        Month: 'เดือน',
        Target: 'เป้าหมาย',
        Year: 'ปี',
        TargetValue: 'ยอดเป้าหมาย',
        Classifier: 'หน่วย',
        NewMemberTarget: 'เป้าหมายสมาชิกใหม่',
        ExistingMemberTarget: 'เป้าหมายสมาชิกเดิม',

        Member: 'คน',
        BestAllTime: 'Top 5 Best Performance All Time',
        WorstAllTime: 'Top 5 Worst Performance All Time',
        BestInMonth: 'Top 5 Best Performance In',
        WorstInMonth: 'Top 5 Worst Performance In',
      },
      ConversionRate: 'ฮัตราการแปลง',
      MPVSRevenue: 'MP VS รายได้',
      YOY: {
        Title: 'YOY',
        Compare: 'เปรียบเทียบ',
        With: 'กับ',
      },
    },
    Settings: {
      General: 'ตั้งค่าทั่วไป',
      BankAccount: 'ตั้งค่าบัญชีธนาคาร',
      DocumentInfo: 'รายละเอียดเอกสาร',
      Copied: 'คัดลอกสำเร็จ',
      CopyBankAccountToClipboard: 'คัดลอกหมายเลขบัญชีธนาคารเรียบร้อยแล้ว',
      Modal: {
        GeneralConfirmTitle: 'ยืนยันการบันทึกรายละเอียดเอกสาร',
        GeneralConfirmDetail:
          'คุณแน่ใจที่จะบันทึกรายละเอียดเอกสารด้วยข้อมูลเหล่านี้หรือไม่?',
        SetDefaultBankAccountTitle: 'ยืนยันการตั้งค่าบัญชีธนาคารเริ่มต้น',
        SetDefaultBankAccountDetail:
          'คุณแน่ใจที่จะยืนยันการตั้งค่าบัญชีธนาคารเริ่มต้นด้วยบัญชีนี้หรือไม่?',
        RemoveDefaultBankAccountTitle: 'ยืนยันการยกเลิกบัญชีธนาคารเริ่มต้น',
        RemoveDefaultBankAccountDetail:
          'คุณแน่ใจที่จะยกเลิกการตั้งค่าบัญชีธนาคารเริ่มต้นบัญชีนี้หรือไม่?',
      },
      DefaultBankAccount: 'บัญชีธนาคารเริ่มต้น',
      AllBankAccount: 'บัญชีธนาคารทั้งหมด',
      Toast: {
        Success: 'สำเร็จ',
        Error: 'ผิดพลาด',
        SuccessDefaultBankAccountDetail:
          'ตั้งเป็นค่าเริ่มต้นสำหรับบัญชีธนาคารสำเร็จ',
        SuccessRemoveDefaultBankAccountDetail:
          'ถอนค่าเริ่มต้นสำหรับบัญชีธนาคารสำเร็จ',
      },
      Branch: 'ตั้งค่าสาขา',
    },
    User: {
      Username: 'ชื่อผู้ใช้งาน',
      Name: 'ชื่อ',
      Prefix: 'คำนำหน้าชื่อ',
      FirstName: 'ชื่อจริง',
      LastName: 'นามสกุล',
      Gender: 'เพศ',
      Email: 'อีเมล',
      Phone: 'เบอร์โทรศัพท์',
      Role: 'ระดับผู้ใช้งาน',
      Company: 'บริษัท',
      Organization: 'องค์กร',
      Branch: 'สาขา',
      Department: 'แผนก',
      Position: 'ตำแหน่ง',
      CreateOn: 'เข้าร่วมเมื่อวันที่',
      General: 'ทั่วไป',
      Security: 'ความปลอดภัย',
      AccountSettings: 'ตั้งค่าผู้ใช้งาน',
      OldPassword: 'รหัสผ่านเดิม',
      NewPassword: 'รหัสผ่านใหม่',
      ReNewPassword: 'รหัสผ่านใหม่อีกครั้ง',
      IsActive: 'การเปิดใช้งาน',
      IsVerify: 'การยืนยัน',
      UserStatus: 'สถานะผู้ใช้งาน',
      UserDetail: 'รายละเอียดผู้ใช้งาน',
      Active: 'เปิดใช้งาน',
      InActive: 'ปิดใช้งาน',
      SelectRole: 'กรุณาเลือกระดับผู้ใช้งาน',
      SelectPrefix: 'กรุณาเลือกคำนำหน้าชื่อ',
      SelectGender: 'กรุณาเลือกเพศ',
      Cover: 'ภาพปก',
    },
    // {{"Customer.branch" | translate}}
    Customer: {
      businessName: 'ชื่อธุรกิจ',
      businessNameError: 'จำเป็นต้องระบุชื่อธุรกิจ',
      contactInfo: 'ข้อมูลผู้ติดต่อ',
      dateOfBirth: 'วันเกิด',
      accountsReceivableRecord: 'บันทึกบัญชีลูกหนี้',
      accountsPayableRecord: 'บันทึกบัญชีเจ้าหนี้',
      thaiName: 'ชื่อ',
      contactName: 'ชื่อผู้ติดต่อ',
      individualOrLegalEntity: 'บุคคลหรือนิติ',
      JuristicPerson: 'นิติบุคคล',
      OrdinaryPerson: 'บุคคลธรรมดา',
      requiredError: 'จำเป็นต้องระบุชื่อ',
      selectCountry: 'เลือกประเทศ',
      country: 'ไทย',
      countryOther: 'ประเทศอื่น ๆ',
      idNumber: 'เลขประจำตัวผู้เสียภาษี/เลขประจำตัว',
      contactType: 'ประเภทผู้ติดต่อ',
      officeType: 'ประเภทสำนักงาน',
      noOffice: 'สำหรับผู้ที่ไม่มีสำนักงาน',
      headOffice: 'สำนักงานใหญ่',
      office: 'สำนักงาน',
      noCountry: 'ไม่มี',
      branch: 'สาขา',
      search: 'ค้นหา',
      branchCodeLabel: 'รหัสสาขา',
      branchCodePlaceholder: 'กรอกรหัสสาขา',
      branchNameLabel: 'ชื่อสาขา',
      branchNamePlaceholder: 'กรอกชื่อสาขา',
      officeTypeLabel: 'เลือกประเภทขององค์กร',
      pleaseSpecify: 'กรุณาระบุ',
      nameLabel: 'ชื่อ',
      contactNameLabel: 'ชื่อผู้ติดต่อ',
      debtorLabel: 'บันทึกบัญชีลูกหนี้',
      creditorLabel: 'บันทึกบัญชีเจ้าหนี้',
      personalInfo: 'ข้อมูลบุคคล',
      primaryContact: 'ผู้ติดต่อหลัก',
      specifyContactInfo: 'ระบุข้อมูลติดต่อเบื้องต้นของคุณ',
      firstName: 'ชื่อจริง',
      lastName: 'นามสกุล',
      nickname: 'ชื่อเล่น',
      ContactCode: 'รหัสผู้ติดต่อ',
      UplineCode: 'รหัส Upline',
      FullName: 'ชื่อ - นามสกุล',
      position: 'ตำแหน่ง',
      nameRequiredError: 'จำเป็นต้องระบุชื่อ',
      lastNameRequiredError: 'จำเป็นต้องระบุนามสกุล',
      nicknameRequiredError: 'จำเป็นต้องระบุชื่อเล่น',
      positionRequiredError: 'จำเป็นต้องระบุตำแหน่ง',
      contactChannelInfo: 'ข้อมูลช่องทางติดต่อ',
      centralContactInfo: 'ข้อมูลติดต่อกลาง',
      indirectContact: 'ในกรณีที่ติดต่อคุณไม่ได้โดยตรง',
      centralPhoneNumber: 'หมายเลขโทรศัพท์กลาง',
      faxNumber: 'หมายเลขแฟกซ์',
      centralEmail: 'อีเมลกลาง',
      syntaxError: 'ผิดพลาดททางอักขระ',
      website: 'เว็บไซต์',
      gbPhoneRequiredError: 'จำเป็นต้องระบุหมายเลขโทรศัพท์กลาง',
      gbFaxRequiredError: 'จำเป็นต้องระบุหมายเลขแฟกซ์',
      gbEmailRequiredError: 'จำเป็นต้องระบุอีเมลกลาง',
      gbSiteRequiredError: 'จำเป็นต้องระบุเว็บไซต์',
      office_type: 'จำเป็นต้องเลือกประเภทของสำนักงาน',
      debtorRq: 'จำเป็นต้องเลือกประเภทบันทึกบัญชีลูกหนี้',
      creditorRq: 'จำเป็นต้องเลือกประเภทบันทึกบัญชีเจ้าหนี้',
      phone: 'เบอร์โทรศัพท์',
      email: 'อีเมล',
      emailRequiredError: 'จำเป็นต้องระบุอีเมล',
      phoneRequiredError: 'จำเป็นต้องระบุหมายเลขโทรศัพท์',
      fillOutCompletely: 'กรุณากรอกข้อมูลให้ครบ',
      invalidAllZeroBranchNumber: 'ไม่สามารถใช้รหัสสาขา 00000 ได้',
      branchNameRequiredError: 'จำเป็นต้องระบุชื่อสาขา',
      duplicateTaxID:
        'หมายเลขนี้ได้ถูกใช้ในที่อยู่สำหรับออกใบกับกำภาษีอื่นแล้ว',
      BuyHistory: 'ประวัติการซื้อ',
      SellHistory: 'ประวัติการขาย',
    },
    Gender: {
      Male: 'ชาย',
      Female: 'หญิง',
      NotSpecified: 'ไม่ระบุ',
    },
    Address: {
      AlertAddr: 'กรุณากรอกให้ครบและตรวจสอบความถูกต้อง',
      addrInfo: 'ข้อมูลที่อยู่',
      registeredAddress: 'ที่อยู่ออกใบกำกับภาษี',
      mailingAddress: 'ที่อยู่จัดส่ง',
      contactPerson: 'ผู้ติดต่อ',
      address: 'ที่อยู่',
      addressDetails: 'เลขที่ - ตรอก/ซอย ถนน',
      addressDetailsNote: 'กรุณาระบุ',
      country: 'ประเทศ',
      copy: 'ใช้ข้อมูลที่อยู่ออกใบกำกับภาษี',
      thailand: 'ไทย',
      india: 'อินเดีย',
      thailand2: 'ไทย(2)',
      province: 'จังหวัด',
      bangkok: 'กรุงเทพมหานคร',
      startingPoint: 'มุมต้น',
      district: 'เขต/อำเภอ',
      subdistrict: 'แขวง/ตำบล',
      postalCode: 'รหัสไปรษณีย์',
      cNameRequiredError: 'จำเป็นต้องระบุผู้ติดต่อ',
      cAddressRequiredError: 'จำเป็นต้องระบุที่อยู่',
      cLocalRequiredError: 'จำเป็นต้องระบุภาค',
      cCityRequiredError: 'จำเป็นต้องระบุจังหวัด',
      cDistrictRequiredError: 'จำเป็นต้องระบุเขต/อำเภอ',
      cSubDistrictRequiredError: 'จำเป็นต้องระบุแขวง/ตำบล',
      cCountyRequiredError: 'จำเป็นต้องระบุประเทศ',
      cPostcodeRequiredError: 'จำเป็นต้องระบุรหัสไปรษณีย์',
      cPhoneRequiredError: 'จำเป็นต้องระบุเบอร์ติดต่อ',
      selectCountry: 'เลือกประเทศ',
      selectProvince: 'เลือกจังหวัด',
      selectDistrict: 'เลือกเขต/อำเภอ',
      selectSubDistrict: 'เลือกแขวง/ตำบล',
      isDefault: 'ค่าเริ่มต้น',
      setAsDefault: 'ตั้งเป็นค่าเริ่มต้น',
      contactPhone: 'เบอร์ติดต่อ',
      addNewAddress: 'เพิ่มที่อยู่',
      editSendShippingAddress: 'แก้ไขที่อยู่ในการจัดส่งสินค้า',
      editReceiveShippingAddress: 'แก้ไขที่อยู่การรับสินค้า',
      cancelEditAddress: 'ยกเลิกการแก้ไข',
    },
    Region: {
      Title: 'ภาค',
      Northern: 'ภาคเหนือ',
      Central: 'ภาคกลาง',
      Northeastern: 'ภาคตะวันออกเฉียงเหนือ',
      Western: 'ภาคตะวันตก',
      Eastern: 'ภาคตะวันออก',
      Southern: 'ภาคใต้',
    },
    Bank: {
      reftext: 'ข้อมูลธนาคาร',
      bankName: 'ธนาคาร',
      bankGroupRequired: 'ต้องระบุ bank_group',
      branch: 'สาขา',
      bankAddressRequired: 'ต้องระบุ bank_addr',
      accountNumber: 'เลขที่บัญชี',
      bankIdRequired: 'ต้องระบุ bank_id',
      accountName: 'ชื่อบัญชี',
      bankNameRequired: 'ต้องระบุ bank_name',
      accountType: 'ประเภทบัญชี',
      savings: 'ออมทรัพย์',
      current: 'กระแสรายวัน',
      bankTypeRequired: 'ต้องระบุ bank_type',
    },
    BookingCalendar: {
      Modal: {
        Header: 'การจองกิจกรรม',
        EventName: 'ชื่อกิจกรรม',
        Customer: 'ลูกค้า',
        Location: 'สถานที่',
        Date: 'วันที่',
        CancelEvent: 'ยกเลิกกิจกรรม',
        EventColor: 'สีของกิจกรรม',
        PeriodTime: 'ช่วงเวลา',
        StartTime: 'เวลาเริ่ม',
        EndTime: 'เวลาสิ้นสุด',
        Remarks: 'หมายเหตุ',
        CreateEvent: 'สร้างกิจกรรม',
        UpdateEvent: 'แก้ไขกิจกรรม',
        ConfirmCreate: 'คุณแน่ใจหรือไม่ที่ต้องการสร้างกิจกรรม?',
        ConfirmUpdate: 'คุณแน่ใจหรือไม่ที่ต้องการแก้ไขกิจกรรม?',
        Error: {
          EventColor: 'กรุณาเลือกสีของกิจกรรม',
          Customer: 'กรุณาระบุชื่อลูกค้า',
          Location: 'กรุณาระบุชื่อสาขา',
          EventName: 'กรุณาระบุชื่อกิจกรรม',
          Time: 'กรุณาระบุเวลา',
        },
        EditPastEvent: 'ไม่สามารถแก้ไขกิจกรรมในอดีตได้',
      },
      Approval: {
        Event: 'กิจกรรม',
        Remarks: 'คำอธิบาย',
        Time: 'เวลา',
        Customer: 'ลูกค้า',
        Status: 'สถานะ',
        Action: 'จัดการ',
      },
      Status: {
        WaitApprove: 'รอการอนุมัติ',
        Approved: 'อนุมัติแล้ว',
        Reject: 'ปฏิเสธการอนุมัติ',
        None: 'ยังไม่ได้ยื่น'
      },
    },

    Branch: {
      Modal: {
        EditBranch: 'แก้ไขข้อมูลสาขา',
        CreateBranch: 'เพิ่มสาขา',
        CancelCreateTitle: 'ยกเลิกการสร้างสาขา',
        CancelCreate: 'คุณแน่ใจหรือไม่ที่ต้องการยกเลิกการสร้างสาขา?',
        CancelEditTitle: 'ยกเลิกการสร้างสาขา',
        CancelEdit: 'คุณแน่ใจหรือไม่ที่ต้องการยกเลิกการสร้างสาขา?',
        ConfirmEditTitle: 'ยืนยันการแก้ไขสาขา',
        ConfirmEdit: 'คุณแน่ใจหรือไม่ที่ต้องการแก้ไขสาขา',
        ConfirmCreateTitle: 'ยืนยันการสร้างสาขา',
        ConfirmCreate: 'คุณแน่ใจหรือไม่ที่ต้องการสร้างสาขา',
        BranchName: {
          Label: 'ชื่อสาขา',
          Placeholder: 'ระบุชื่อสาขา',
          Error: 'กรุณาระบุชื่อสาขา',
        },
        BranchAddressNumber: {
          Label: 'ที่อยู่สาขา',
          Placeholder: 'ระบุที่อยู่สาขา',
          Error: 'กรุณาระบุที่อยู่สาขา',
        },
        BranchPhone: {
          Label: 'เบอร์โทรศัพท์สาขา',
          Placeholder: 'ระบุเบอร์โทรศัพท์สาขา',
          Error: 'กรุณาระบุเบอร์โทรศัพท์สาขา',
        },
        BranchEmail: {
          Label: 'อีเมลสาขา',
          Placeholder: 'ระบุอีเมลสาขา',
          Error: 'กรุณาระบุอีเมลสาขา',
        },
        BranchOpenDate: {
          Label: 'เวลาเปิดทำการ',
          Placeholder: 'ระบุเวลาเปิดทำการ',
          Error: 'กรุณาระบุเวลาเปิดทำการ',
        },
        BranchCloseDate: {
          Label: 'เวลาปิดทำการ',
          Placeholder: 'ระบุเวลาปิดทำการ',
          Error: 'กรุณาระบุเวลาปิดทำการ',
        },
        BranchMapUrl: {
          Label: 'ลิงค์แผนที่สาขา',
          Placeholder: 'ระบุลิงค์แผนที่สาขา',
          Error: 'กรุณาระบุลิงค์แผนที่สาขา',
        },
        BranchColor: {
          Label: 'สีประจำสาขา',
          Placeholder: 'เลือกสีประจำสาขา',
          Error: 'กรุณาเลือกสีประจำสาขา',
        },
        BranchPostCode: {
          Label: 'รหัสไปรษณีย์',
          Placeholder: 'ระบุรหัสไปรษณีย์',
          Error: 'กรุณาระบุรหัสไปรษณีย์',
        },
        BranchImage: {
          Label: 'ภาพสาขา',
          Error: 'กรุณาเลือกภาพสาขา',
        },
        Weekday: {
          Label: 'วันธรรมดา (จ.-ศ.)',
          Open: 'เวลาทำการ',
          Placeholder: 'โปรดระบุเวลา',
          Start: 'เวลาเปิด',
          End: 'เวลาปิด',
          Error: 'กรุณาระบุเวลา',
        },
        Weekend: {
          Label: 'สุดสัปดาห์ (ส.-อา.)',
          Start: 'เวลาเปิด',
          End: 'เวลาปิด',
          Error: 'กรุณาระบุเวลา',
        },
        PastTimeError: 'วันที่หรือเวลาผิดพลาด',
      },
    },
    Invoice: {
      item: 'การตั้งค่าใบแจ้งหนี้',
      sellPaymentSettingLabel: 'กำหนดการชำระเงินตามใบแจ้งหนี้ที่ออก (ขายไป)',
      sellPaymentSettingOptions: {
        settingByCompany: 'ตั้งค่าตามการตั้งค่าของบริษัท',
        afterInvoiceXDays: 'X วันหลังออกใบแจ้งหนี้',
        endOfMonthInvoiceDate: 'สิ้นเดือนของวันที่ออกใบแจ้งหนี้',
        endOfMonthNextMonth: 'สิ้นเดือนของเดือนถัดไป',
      },
      buyPaymentSettingLabel:
        'กำหนดการชำระเงินตามรายจ่ายที่บันทึก/ใบแจ้งหนี้ที่ได้รับ (ซื้อมา)',
      cancelButton: 'ยกเลิก',
      saveButton: 'บันทึก',
      sellreq: 'กรุณากรอก sell.',
      buyreq: 'กรุณากรอก buy',
    },
    iTem: {
      Group: 'กลุ่มของหมวดหมู่',
      Validate: ' * จำเป็นต้องเลือก',
      Cate: 'หมวดหมู่ของกลุ่ม',
      subCate: 'หมวดหมู่ย่อย',
      Number: ' * ตัวเลขเท่านั้น',
      Media: 'จำกัดจำนวน',
      MediaLimit: ' * สามารถเพิ่มรูปภาพได้ไม่เกิน 5 รูป',
    },
    BonusPaln: {},
    EKYC: {
      Bookbank: 'รูปถ่ายปกสมุดบัญชี',
      PIDImage: 'รูปบัตรประชาชน',
      PIDWithPersonImage: 'รูปถ่ายคู่บัตรประชาชน',
      EditData: 'แก้ไขข้อมูล e-KYC',
      Data: 'ข้อมูล',
      History: 'ประวัติ',
      Table: {
        Date: 'วันที่',
        Detail: 'รายละเอียด',
        File: 'ไฟล์',
        UpdatedBy: 'แก้ไขโดย',
        BankAccountName: 'ชื่อบัญชี',
        BankAccountNo: 'หมายเลขบัญชี',
        BankName: 'ธนาคาร',
      },
      BankAccount: 'บัญชีธนาคาร',
      FileHistory: 'ประวัติไฟล์รูปภาพ',
      IdCardImage: 'รูปบัตรประชาชน',
      PersonalWithIdCardImage: 'รูปถ่ายคู่กับบัตรประชาชน',
      BookbankImage: 'รูปหน้าปกบัญชีธนาคาร',
      BookbankDetail: 'Book bank',
    },
    ReturnProduct: {
      Refund: 'คืนเงิน',
      ReturnProduct: 'คืนสินค้า',
      ReturnRequest: 'คำขอคืนสินค้า',
      SaveRequestModalTitle: 'บันทึกคำขอคืนสินค้า',
      SaveRequestModalDetail:
        'คุณแน่ใจที่จะบันทึกคำขอคืนสินค้าด้วยข้อมูลเหล่านี้หรือไม่?',
      ReturnStatus: 'สถานะคำขอ',
      RequestBy: 'โดย',
      CreateDate: 'วันที่ทำรายการ',
      RequestDate: 'วันที่ทำยื่นขอคืนสินค้า',
      Remarks: 'หมายเหตุ',
      Reasons: 'สาเหตุ',
      OtherReasons: 'หมายเหตุอื่น ๆ (ถ้ามี)',
      CheckedBy: 'ตรวจสอบโดย',
      ReturnType: 'ประเภททำรายการ',
      AttachmentFile: 'ไฟล์แนบ',
      CheckBeforeSave:
        'กรุณาตรวจสอบความถูกต้องของข้อมูล หากบันทึกคำขอแล้วจะไม่สามารถกลับมาแก้ไขได้อีก',
      InvoiceCode: 'รหัสใบแจ้งหนี้',
      RequestDetail: 'รายละเอียดคำขอคืนสินค้า',
      ItemList: 'รายการสินค้า',
      Claim: 'รับเคลม',
      ClaimModalTitle: 'รับเคลม',
      ClaimModalDetail:
        'คุณต้องการที่จะรับเคลมสินค้าตามรายการหรือไม่ เมื่อรับเคลมแล้วยอดสินค้าจะกลับคืนมาใน Stock สินค้าเคลม',
    },
    MLM: {
      YourCrew: 'ลูกทีมของคุณ',
      SVFormCrew: 'SV จากลูกทีม',
      BonusFormCrew: 'โบนัสจากลูกทีม',
      OverviewCrew: 'ภาพรวมทีมของคุณ',
      TodayBonusFromYourCrew: 'โบนัสรวมของวันนี้ จากทีมของคุณ',
      Bonus: 'โบนัส (บาท)',

      HighestBonusIn: 'โบนัสสูงสุดภายใน',
      HighestSalesIn: 'ยอดขายสูงสุด',
      HighestSalesInThisWeek: 'ยอดขายสูงสุดภายในสัปดาห์นี้',
      HighestSalesInThisMonth: 'ยอดขายสูงสุดภายในเดือนนี้',
      ThisWeek: 'สัปดาห์นี้',
      ThisMonth: 'เดือนนี้',
      PersonalBonus: 'โบนัสส่วนบุคคล',
      DownlineBonus: 'โบนัสดาวน์ไลน์',
      SystemBonus: 'โบนัสระบบ',
      TotalIncome: 'รายได้รวมของคุณ',
      JoinWhen: 'เข้าร่วมเมื่อ',
      Period: {
        Title: 'ระยะเวลา',
        Year: 'ปี',
        Month: 'เดือน',
        Start: 'เริ่ม',
        End: 'จบ',
        Type: 'ประเภท',
        From: 'จากประเภท',
        To: 'ไปประเภท',
        Date: 'วันที่',
        Editor: 'แก้ไขโดย',
        Current: 'Sale Period ปัจจุบัน',
        Setting: 'การตั้งค่า Sale Period',
        HalfOfMonth: 'ทุกวันที่ 15 ( 1-15, 16-วันสุดท้ายของเดือน )',
        EndOfMonth: 'ทุกวันสุดท้ายของเดือน',
        SendTo: 'ส่งถึง',
      },
      Member: 'สมาชิก',
      NewMember: 'สมาชิกใหม่',
      ExistMember: 'สมาชิกเดิม',
      TotalMember: 'สมาชิกทั้งหมด',
      SaleTotal: 'ยอดขายรวม',
      MemberUnit: 'ราย',
      Result: 'ผลลัพธ์',
      Filter: 'ตัวกรอง',
      MemberDemographicMap: 'แผนที่ประชากรสมาชิก',
      Configuration: 'MLM Configuration',
      RankManage: 'จัดการแรงค์',
      SalePeriodManage: 'จัดการ Sale Period',
      SalePeriodHistory: 'ประวัติ Sale Period',
      SettingHistory: 'ประวัติการตั้งค่า',
      HighestSV: 'SV มากสุด',
      LowestSV: 'SV น้อยสุด',
      SystemBonusCondition: {
        PresonalSV30K: 'ยอดขายส่วนบุคคล 30,000+ SV ใน 1 Period',
        JoinEven100Percent: 'เข้าร่วม 100%',
        MPBonus: 'MP Bonus',
      },
    },
    Document: {
      QuotationDraft: 'ร่างใบเสนอราคา',
      Quotation: 'ใบเสนอราคา',
      SaleOrder: 'ใบสั่งขาย',
      Invoice: 'ใบแจ้งหนี้',
      Invoice_Tax: 'ใบแจ้งหนี้/ใบกำกับภาษี',
      Tax: 'ใบกำกับภาษี',
      DebitNote: 'ใบเพิ่มหนี้',
      CreditNote: 'ใบลดหนี้',
      ReturnSlip: 'ใบรับคืนสินค้า',
      Receipt: 'ใบเสร็จรับเงิน',
      IV_TX_RE: 'ใบแจ้งหนี้/ใบกำกับภาษี/ใบเสร็จรับเงิน',
      PurchaseRequest: 'ใบขอสั่งซื้อ',
      PurchaseOrder: 'ใบสั่งซื้อ',
      SeparateGR: 'สร้างใบรับสินค้าแยก',
      SeparateIV: 'สร้างใบแจ้งหนี้แยก',
      GoodsReceive: 'ใบรับสินค้า',
      goodRecieve: 'ใบรับสินค้า',
      fullValue: 'มูลค่าเต็ม',
      writtenOff: 'จำนวนเงินที่ตัดชำระออก',
    },
    BreadCrumbs: {
      Manage: {
        Brand: 'แบรนด์',
        Group: 'กลุ่ม',
        Category: 'หมวดหมู่',
        SubCategory: 'หมวดหมู่ย่อย',
        Class: 'คลาส',
        SubClass: 'คลาสย่อย',
        Item: 'สินค้า',
        ItemSet: 'ชุดสินค้า',
        Customer: 'จัดการบัญชี',
        User: 'จัดการผู้ใช้งาน',
        Role: 'จัดการระดับผู้ใช้งาน',
        Trash: 'ถังขยะ',
        BankAccount: 'บัญชีธนาคาร',
      },
      CMS: {
        Banner: 'จัดการแบนเนอร์',
        banner: 'แบนเนอร์',
        News: 'จัดการข่าวสารและกิจกรรม',
        news: 'ข่าวสารและกิจกรรม',
        PDPA: 'จัดการหน้า Privacy Policy',
        PrivacyPolicy: ' Privacy Policy',
        pdpa: ' PDPA',
        Event: 'จัดการอีเวนต์',
        event: 'อีเวนต์',
        cookiePolicy: 'จัดการหน้า ข้อตกลงการใช้คุกกี้',
        CookiePolicy: 'ข้อตกลงการใช้คุุกกี้',
        termsCondition: 'จัดการหน้า เงื่อนไขและข้อตกลง',
        TermsCondition: 'เงื่อนไขและข้อตกลง',
        shoppingCart: 'จัดการหน้าตะกร้าสินค้า',
        ShoppingCart: ' ตะกร้าสินค้า',
        signIn: 'จัดการหน้าเข้าสู่ระบบ',
        SignIn: ' เข้าสู่ระบบ',
        Contact: 'จัดการหน้า การติดต่อ',
        contact: 'การติดต่อ',
        About: 'จัดการหน้าเกี่ยวกับ',
        about: 'เกี่ยวกับ',
        Home: 'จัดการหน้าแรก',
        home: 'แรก',
        Product: 'จัดการหน้ารวมผลิตภัณท์',
        ProductLanding: 'รวมผลิตภัณท์',
        LandingPage: 'จัดการหน้ารวมข่าวสาร',
        landingPage: ' Landing Page',
        CreateNews: 'สร้างข่าวสารและอีเวนต์',
        SellerManageRegulation: 'Seller Management Regulation',
        SellerManagementRegulation: 'จัดการหน้า Seller Management Regulation',
        FAQ: 'จัดการคำถามที่พบบ่อย',
        Categories: 'จัดการหมวดหมู่',
        categories: 'หมวดหมู่',

        newsEvent: 'จัดการหน้า News and Event',
        NewsEvent: ' News and Event',
        NewsEventLandingPage: 'จัดการหน้ารวม News and Event',

        consumerReview: 'จัดการหน้า Consumer Review',
        ConsumerReview: ' Consumer Review',
        ConsumerReviewLandingPage: 'จัดการหน้ารวม Consumer Review',

        newsReview: 'จัดการหน้า Consumer Review',
        NewsReview: ' Consumer Review',
        NewsReviewLandingPage: 'จัดการหน้ารวม Consumer Review',

        companyVideo: 'จัดการหน้า Company Video',
        CompanyVideo: ' Company Video',
        CompanyVideoLandingPage: 'จัดการหน้ารวม Company Video',

        newsVideo: 'จัดการหน้า Company Video',
        NewsVideo: ' Company Video',
        NewsVideoLandingPage: 'จัดการหน้ารวม Company Video',

        RepoveTools: {
          Landing: {
            Title: 'จัดการหน้ารวม Repove Tools',

            BannerTitle: 'หัวข้อในแบนเนอร์',
            BannerDescription: 'คำอธิบายในแบนเนอร์',
            BannerBackground: 'พื้นหลังแบนเนอร์',

            TPBannerTitle: 'หัวข้อในแบนเนอร์เทคโนโลยีและสินค้า',
            TPBannerDescription: 'คำอธิบายในแบนเนอร์เทคโนโลยีและสินค้า',
            TPBannerBackground: 'พื้นหลังแบนเนอร์เทคโนโลยีและสินค้า',

            OurCompanyBannerTitle: 'หัวข้อในแบนเนอร์บริษัท',
            OurCompanyBannerDescription: 'คำอธิบายในแบนเนอร์บริษัท',
            OurCompanyBannerBackground: 'พื้นหลังแบนเนอร์บริษัท',

            BonusPlanBannerTitle: 'หัวข้อในแบนเนอร์โบนัสแพลน',
            BonusPlanBannerDescription: 'คำอธิบายในแบนเนอร์โบนัสแพลน',
            BonusPlanBannerBackground: 'พื้นหลังแบนเนอร์โบนัสแพลน',
          },
          Branch: {
            Title: 'จัดการหน้าสาขา',

            CoverImage: 'รูปปก',
            Name: 'ชื่อ',
            Subname: 'ชื่อรอง',
            Detail: 'รายละเอียด',
          },
          BonusPlan: {
            Title: 'จัดการหน้าโบนัสแพลน',

            SalePeriodDescription: 'คำอธิบายระยะเวลาการขาย',
            saleValueDescription: 'คำอธิบายยอดการขาย',

            APBackground: 'พื้นหลัง Active Provider',
            MPBackground: 'พื้นหลัง Master Provider',

            APMainBannerBackground: 'พื้นหลังแบนเนอร์ Active Provider หลัก',
            APSubBannerBackground: 'พื้นหลังแบนเนอร์ Active Provider รอง',
            APSubBannerBackgroundMobile:
              'พื้นหลังแบนเนอร์ Active Provider รองแบบมือถือ',

            APBannerTitle: 'หัวข้อในแบนเนอร์ Active Provider ',
            APBannerSubtitle: 'หัวข้อรองในแบนเนอร์ Active Provider',

            LeftBannerBackground: 'พื้นหลังแบนเนอร์ซ้าย',
            RightBannerBackground: 'พื้นหลังแบนเนอร์ขวา',

            LeftBannerDescription: 'คำอธิบายในแบนเนอร์ซ้าย',
            RightBannerDescription: 'คำอธิบายในแบนเนอร์ขวา',
          },
          CompanyCard: {
            Title: 'จัดการหน้าบริษัทในเครือ',

            CoverImage: 'โลโก้',
            Type: 'ประเภทของบริษัท',
            Name: 'ชื่อ',
            Detail: 'รายละเอียด',
          },
          Detail: {
            Title: 'จัดการหน้ารายละเอียดบริษัทในเครือ',

            BannerImage: 'รูปภาพแบนเนอร์',
            BannerTitle: 'หัวข้อในแบนเนอร์',
            BannerDescription: 'คำอธิบายในแบนเนอร์',
            BannerSubdescription: 'คำอธิบายรองในแบนเนอร์',

            Video: 'วิดีโอของบริษัท',

            ACImage: 'รูปภาพบริษัทในเครือ',
            ACLogo: 'โลโก้บริษัทในเครือ',
            ACTitle: 'หัวข้อบริษัทในเครือ',
            ACName: 'ชื่อบริษัทในเครือ',
            ACDetail: 'รายละเอียดบริษัทในเครือ',

            AnnualSale: 'ยอดขายประจำปี',
            Branch: 'จำนวนสาขาในเกาหลี',
            Subscription: 'จำนวนผู้สมัครสมาชิก',

            TimelineTitle: 'หัวข้อในแบนเนอร์ Timeline',
            TimelineDescription: 'คำอธิบายในแบนเนอร์ Timeline',
            TimelineImage: 'รูปภาพในแบนเนอร์ Timeline',

            TargetBannerTitle: 'หัวข้อในแบนเนอร์เป้าหมาย',
            TargetIconOne: 'ไอคอนเป้าหมาย หนึ่ง',
            TargetTitleOne: 'หัวข้อเป้าหมาย หนึ่ง',
            TargetIconTwo: 'ไอคอนเป้าหมาย สอง',
            TargetTitleTwo: 'หัวข้อเป้าหมาย สอง',
            TargetIconThree: 'ไอคอนเป้าหมาย สาม',
            TargetTitleThree: 'หัวข้อเป้าหมาย สาม',
            TargetDescription: 'คำอธิบายในแบนเนอร์เป้าหมาย',
          },
          Timeline: {
            Title: 'จัดการหน้า Timeline',

            Year: 'ปี',
            Description: 'คำอธิบาย Timeline',
            Month1: 'เดือนที่ 1',
            Month2: 'เดือนที่ 2',
            Month3: 'เดือนที่ 3',
            Month4: 'เดือนที่ 4',
            Month5: 'เดือนที่ 5',
            Desecription1: 'คำอธิบาย 1',
            Desecription2: 'คำอธิบาย 2',
            Desecription3: 'คำอธิบาย 3',
            Desecription4: 'คำอธิบาย 4',
            Desecription5: 'คำอธิบาย 5',
          },
          Booster: 'จัดการหน้า Booster',
          Serum: 'จัดการหน้า Serum',
          Cream: 'จัดการหน้า Cream',
          SunProtector: 'จัดการหน้า Sun Protector',
          Bodywash: 'จัดการหน้า Bodywash',
          HydroethosomeHighlightTech: {
            Title: 'ไฮไลท์ Hydroethosome',
            Content: 'เนื้อหา',

            Name: 'ชื่อ',
            Subtitle: 'หัวข้อรอง',

            Icon: 'ไอคอน',
            Description: 'คำอธิบาย',
          },
          TechAndProductLanding: {
            Title: 'จัดการหน้ารวมเทคโนโลยีและสินค้า',

            TechBannerTitle: 'หัวข้อในแบนเนอร์เทคโนโลยี',
            TechBannerDescription: 'คำอธิบายในแบนเนอร์เทคโนโลยี',
            TechBannerBackground: 'พื้นหลังแบนเนอร์เทคโนโลยี',

            TechTitleOne: 'หัวข้อเทคโนโลยี หนึ่ง',
            TechDescriptionOne: 'คำอธิบายเทคโนโลยี หนึ่ง',
            TechBackgroundOne: 'พื้นหลังเทคโนโลยี หนึ่ง',

            TechTitleTwo: 'หัวข้อเทคโนโลยี สอง',
            TechDescriptionTwo: 'คำอธิบายเทคโนโลยี สอง',
            TechBackgroundTwo: 'พื้นหลังเทคโนโลยี สอง',

            TechTitleThree: 'หัวข้อเทคโนโลยี สาม',
            TechDescriptionThree: 'คำอธิบายเทคโนโลยี สาม',
            TechBackgroundThree: 'พื้นหลังเทคโนโลยี สาม',

            TechTitleFour: 'หัวข้อเทคโนโลยี สี่',
            TechDescriptionFour: 'คำอธิบายเทคโนโลยี สี่',
            TechBackgroundFour: 'พื้นหลังเทคโนโลยี สี่',

            ProductBannerTitle: 'หัวข้อในแบนเนอร์สินค้า',
            ProductBannerDescription: 'คำอธิบายในแบนเนอร์สินค้า',
            ProductBannerBackground: 'พื้นหลังแบนเนอร์สินค้า',

            ProductTitleOne: 'หัวข้อสินค้า หนึ่ง',
            ProductBackgroundOne: 'พื้นหลังสินค้า หนึ่ง',

            ProductTitleTwo: 'หัวข้อสินค้า สอง',
            ProductBackgroundTwo: 'พื้นหลังสินค้า สอง',

            ProductTitleThree: 'หัวข้อสินค้า สาม',
            ProductBackgroundThree: 'พื้นหลังสินค้า สาม',

            ProductTitleFour: 'หัวข้อสินค้า สี่',
            ProductBackgroundFour: 'พื้นหลังสินค้า สี่',

            ProductTitleFive: 'หัวข้อสินค้า ห้า',
            ProductBackgroundFive: 'พื้นหลังสินค้า ห้า',
          },
          Technology: {
            Hydroethosome: 'จัดการหน้า Hydroethosome',
            HydroethosomeEtcTech: 'จัดการหน้าเทคโนโลยีของ Hydroethosome',
            HydroethosomeHighlight: 'จัดการหน้าไฮไลท์ Hydroethosome ',
            HydroethosomeDetail: 'จัดการหน้ารายละเอียด Hydroethosome',
            Hydropartisome: {
              Title: 'จัดการหน้า Hydropartisome',

              BannerTitle: 'หัวข้อในแบนเนอร์',
              BannerDescription: 'คำอธิบายในแบนเนอร์',
              BannerImage: 'รูปภาพแบนเนอร์',

              TechParagraph1: 'หัวข้อเนื้อหา',
              TechParagraph2: 'เนื้อหา หนึ่ง',
              TechParagraph3: 'เนื้อหา สอง',
              TechParagraph4: 'เนื้อหา สาม',

              Substance1: 'สารสกัด หนึ่ง',
              Substance2: 'สารสกัด สอง',
              Substance3: 'สารสกัด สาม',

              Example: 'รูปภาพตัวอย่าง',

              PropertyIcon1: 'ไอคอนคุณสมบัติ หนึ่ง',
              PropertyDetail1: 'รายละเอียดคุณสมบัติ หนึ่ง',
              PropertyIcon2: 'ไอคอนคุณสมบัติ สอง',
              PropertyDetail2: 'รายละเอียดคุณสมบัติ สอง',
              PropertyIcon3: 'ไอคอนคุณสมบัติ สาม',
              PropertyDetail3: 'รายละเอียดคุณสมบัติ สาม',
              PropertyIcon4: 'ไอคอนคุณสมบัติ สี่',
              PropertyDetail4: 'รายละเอียดคุณสมบัติ สี่',
              PropertyIcon5: 'ไอคอนคุณสมบัติ ห้า',
              PropertyDetail5: 'รายละเอียดคุณสมบัติ ห้า',
            },
            Hydroextraction: {
              Title: 'จัดการหน้า Hydroextraction',

              BannerTitle: 'หัวข้อในแบนเนอร์',
              BannerSubtitle: 'หัวข้อรองในแบนเนอร์',
              BannerImage: 'รูปภาพแบนเนอร์',
              BannerDescription: 'คำอธิบายในแบนเนอร์',

              StepLabel1: 'หัวข้อกำกับขั้นตอนที่ หนึ่ง',
              StepTitle1: 'หัวข้อขั้นตอนที่ หนึ่ง',
              StepDescription1: 'คำอธิบายขั้นตอนที่ หนึ่ง',
              StepImage1: 'รูปภาพขั้นตอนที่ หนึ่ง',

              StepLabel2: 'หัวข้อกำกับขั้นตอนที่ สอง',
              StepTitle2: 'หัวข้อขั้นตอนที่ สอง',
              StepDescription2: 'คำอธิบายขั้นตอนที่ สอง',
              StepImage2: 'รูปภาพขั้นตอนที่ สอง',

              StepLabel3: 'หัวข้อกำกับขั้นตอนที่ สาม',
              StepTitle3: 'หัวข้อขั้นตอนที่ สาม',
              StepDescription3: 'คำอธิบายขั้นตอนที่ สาม',
              StepImage3: 'รูปภาพขั้นตอนที่ สาม',

              StepLabel4: 'หัวข้อกำกับขั้นตอนที่ สี่',
              StepTitle4: 'หัวข้อขั้นตอนที่ สี่',
              StepDescription4: 'คำอธิบายขั้นตอนที่ สี่',
              StepImage4: 'รูปภาพขั้นตอนที่ สี่',

              StepLabel5: 'หัวข้อกำกับขั้นตอนที่ ห้า',
              StepTitle5: 'หัวข้อขั้นตอนที่ ห้า',
              StepDescription5: 'คำอธิบายขั้นตอนที่ ห้า',
              StepImage5: 'รูปภาพขั้นตอนที่ ห้า',

              StepLabel6: 'หัวข้อกำกับขั้นตอนที่ หก',
              StepTitle6: 'หัวข้อขั้นตอนที่ หก',
              StepDescription6: 'คำอธิบายขั้นตอนที่ หก',
              StepImage6: 'รูปภาพขั้นตอนที่ หก',

              ExtractionTitle1: 'หัวข้อสารสกัดที่ หนึ่ง',
              ExtractionImage1: 'รูปภาพสารสกัดที่ หนึ่ง',

              ExtractionTitle2: 'หัวข้อสารสกัดที่ สอง',
              ExtractionImage2: 'รูปภาพสารสกัดที่ สอง',

              ExtractionTitle3: 'หัวข้อสารสกัดที่ สาม',
              ExtractionImage3: 'รูปภาพสารสกัดที่ สาม',

              ExtractionTitle4: 'หัวข้อสารสกัดที่ สี่',
              ExtractionImage4: 'รูปภาพสารสกัดที่ สี่',

              ExtractionTitle5: 'หัวข้อสารสกัดที่ ห้า',
              ExtractionImage5: 'รูปภาพสารสกัดที่ ห้า',

              ExtractionDescription: 'คำอธิบายสารสกัด',

              ExtractionDetailImage1: 'รูปภาพรายละเอียดสารสกัดที่ หนึ่ง',
              ExtractionDetailScale1: 'รายละเอียดขนาดของสารสกัดที่ หนึ่ง',
              ExtractionDetailTitle1: 'รายละเอียดหัวข้อของสารสกัดที่ หนึ่ง',
              ExtractionDetailSubtitle1:
                'รายละเอียดหัวข้อรองของสารสกัดที่ หนึ่ง',
              ExtractionDetailDescription1:
                'รายละเอียดคำอธิบายของสารสกัดที่ หนึ่ง',
              Ex1PropertyIcon1: 'ไอคอนของคุณสมบัติสารสกัดที่หนึ่ง หนึ่ง',
              Ex1PropertyTitle1: 'หัวข้อของคุณสมบัติสารสกัดที่หนึ่ง หนึ่ง',
              Ex1PropertyIcon2: 'ไอคอนของคุณสมบัติสารสกัดที่หนึ่ง สอง',
              Ex1PropertyTitle2: 'หัวข้อของคุณสมบัติสารสกัดที่หนึ่ง สอง',
              Ex1PropertyIcon3: 'ไอคอนของคุณสมบัติสารสกัดที่หนึ่ง สาม',
              Ex1PropertyTitle3: 'หัวข้อของคุณสมบัติสารสกัดที่หนึ่ง สาม',
              Ex1PropertyIcon4: 'ไอคอนของคุณสมบัติสารสกัดที่หนึ่ง สี่',
              Ex1PropertyTitle4: 'หัวข้อของคุณสมบัติสารสกัดที่หนึ่ง สี่',

              ExtractionDetailImage2: 'รูปภาพรายละเอียดสารสกัดที่ สอง',
              ExtractionDetailScale2: 'รายละเอียดขนาดของสารสกัดที่ สอง',
              ExtractionDetailTitle2: 'รายละเอียดหัวข้อของสารสกัดที่ สอง',
              ExtractionDetailSubtitle2: 'รายละเอียดหัวข้อรองของสารสกัดที่ สอง',
              ExtractionDetailDescription2:
                'รายละเอียดคำอธิบายของสารสกัดที่ สอง',
              Ex2PropertyIcon1: 'ไอคอนของคุณสมบัติสารสกัดที่สอง หนึ่ง',
              Ex2PropertyTitle1: 'หัวข้อของคุณสมบัติสารสกัดที่สอง หนึ่ง',
              Ex2PropertyIcon2: 'ไอคอนของคุณสมบัติสารสกัดที่สอง สอง',
              Ex2PropertyTitle2: 'หัวข้อของคุณสมบัติสารสกัดที่สอง สอง',
              Ex2PropertyIcon3: 'ไอคอนของคุณสมบัติสารสกัดที่สอง สาม',
              Ex2PropertyTitle3: 'หัวข้อของคุณสมบัติสารสกัดที่สอง สาม',
              Ex2PropertyIcon4: 'ไอคอนของคุณสมบัติสารสกัดที่สอง สี่',
              Ex2PropertyTitle4: 'หัวข้อของคุณสมบัติสารสกัดที่สอง สี่',

              ExtractionDetailImage3: 'รูปภาพรายละเอียดสารสกัดที่ สาม',
              ExtractionDetailScale3: 'รายละเอียดขนาดของสารสกัดที่ สาม',
              ExtractionDetailTitle3: 'รายละเอียดหัวข้อของสารสกัดที่ สาม',
              ExtractionDetailSubtitle3: 'รายละเอียดหัวข้อรองของสารสกัดที่ สาม',
              ExtractionDetailDescription3:
                'รายละเอียดคำอธิบายของสารสกัดที่ สาม',
              Ex3PropertyIcon1: 'ไอคอนของคุณสมบัติสารสกัดที่สาม  หนึ่ง',
              Ex3PropertyTitle1: 'หัวข้อของคุณสมบัติสารสกัดที่สาม  หนึ่ง',
              Ex3PropertyIcon2: 'ไอคอนของคุณสมบัติสารสกัดที่สาม  สอง',
              Ex3PropertyTitle2: 'หัวข้อของคุณสมบัติสารสกัดที่สาม  สอง',
              Ex3PropertyIcon3: 'ไอคอนของคุณสมบัติสารสกัดที่สาม  สาม',
              Ex3PropertyTitle3: 'หัวข้อของคุณสมบัติสารสกัดที่สาม  สาม',
              Ex3PropertyIcon4: 'ไอคอนของคุณสมบัติสารสกัดที่สาม  สี่',
              Ex3PropertyTitle4: 'หัวข้อของคุณสมบัติสารสกัดที่สาม  สี่',

              ExtractionDetailImage4: 'รูปภาพรายละเอียดสารสกัดที่ สี่',
              ExtractionDetailScale4: 'รายละเอียดขนาดของสารสกัดที่ สี่',
              ExtractionDetailTitle4: 'รายละเอียดหัวข้อของสารสกัดที่ สี่',
              ExtractionDetailSubtitle4: 'รายละเอียดหัวข้อรองของสารสกัดที่ สี่',
              ExtractionDetailDescription4:
                'รายละเอียดคำอธิบายของสารสกัดที่ สี่',
              Ex4PropertyIcon1: 'ไอคอนของคุณสมบัติสารสกัดที่สี่  หนึ่ง',
              Ex4PropertyTitle1: 'หัวข้อของคุณสมบัติสารสกัดที่สี่  หนึ่ง',
              Ex4PropertyIcon2: 'ไอคอนของคุณสมบัติสารสกัดที่สี่  สอง',
              Ex4PropertyTitle2: 'หัวข้อของคุณสมบัติสารสกัดที่สี่  สอง',
              Ex4PropertyIcon3: 'ไอคอนของคุณสมบัติสารสกัดที่สี่  สาม',
              Ex4PropertyTitle3: 'หัวข้อของคุณสมบัติสารสกัดที่สี่  สาม',
              Ex4PropertyIcon4: 'ไอคอนของคุณสมบัติสารสกัดที่สี่  สี่',
              Ex4PropertyTitle4: 'หัวข้อของคุณสมบัติสารสกัดที่สี่  สี่',

              ExtractionDetailImage5: 'รูปภาพรายละเอียดสารสกัดที่ ห้า',
              ExtractionDetailScale5: 'รายละเอียดขนาดของสารสกัดที่ ห้า',
              ExtractionDetailTitle5: 'รายละเอียดหัวข้อของสารสกัดที่ ห้า',
              ExtractionDetailSubtitle5: 'รายละเอียดหัวข้อรองของสารสกัดที่ ห้า',
              ExtractionDetailDescription5:
                'รายละเอียดคำอธิบายของสารสกัดที่ ห้า',
              Ex5PropertyIcon1: 'ไอคอนของคุณสมบัติสารสกัดที่ห้า  หนึ่ง',
              Ex5PropertyTitle1: 'หัวข้อของคุณสมบัติสารสกัดที่ห้า  หนึ่ง',
              Ex5PropertyIcon2: 'ไอคอนของคุณสมบัติสารสกัดที่ห้า  สอง',
              Ex5PropertyTitle2: 'หัวข้อของคุณสมบัติสารสกัดที่ห้า  สอง',
              Ex5PropertyIcon3: 'ไอคอนของคุณสมบัติสารสกัดที่ห้า  สาม',
              Ex5PropertyTitle3: 'หัวข้อของคุณสมบัติสารสกัดที่ห้า  สาม',
              Ex5PropertyIcon4: 'ไอคอนของคุณสมบัติสารสกัดที่ห้า  สี่',
              Ex5PropertyTitle4: 'หัวข้อของคุณสมบัติสารสกัดที่ห้า  สี่',
            },
            Partibiome: {
              Title: ' จัดการหน้า Partibiome',

              BannerTitle: 'หัวข้อในแบนเนอร์',
              BannerSubtitle: 'หัวข้อรองในแบนเนอร์',
              BannerImage: 'รูปภาพแบนเนอร์',

              ContentDetail1: 'รายละเอียดของเนื้อหา หนึ่ง',
              ContentDetail2: 'รายละเอียดของเนื้อหา สอง',

              CardTitle1: 'หัวข้อในการ์ด หนึ่ง',
              CardDetail1: 'รายละเอียดในการ์ด หนึ่ง',
              CardTitle2: 'หัวข้อในการ์ด สอง',
              CardDetail2: 'รายละเอียดในการ์ด สอง',
              CardTitle3: 'หัวข้อในการ์ด สาม',
              CardDetail3: 'รายละเอียดในการ์ด สาม',
              CardTitle4: 'หัวข้อในการ์ด สี่',
              CardDetail4: 'รายละเอียดในการ์ด สี่',

              BottleTitle: 'หัวข้อของข้อความบนขวด',
              BottleSubtitle: 'คำอธิบายของข้อความบนขวด',

              StepBackground: 'พื้นหลังขั้นตอนการสกัด',
              StepIcon1: 'ไอคอนขั้นตอนการสกัด หนึ่ง',
              StepHoverIcon1: 'ไฮไลท์ไอคอนขั้นตอนการสกัด หนึ่ง',
              StepTitle1: 'หัวข้อย่อยขั้นตอนการสกัด หนึ่ง',
              StepSubtitle1: 'หัวข้อขั้นตอนการสกัด หนึ่ง',

              StepIcon2: 'ไอคอนขั้นตอนการสกัด สอง',
              StepHoverIcon2: 'ไฮไลท์ไอคอนขั้นตอนการสกัด สอง',
              StepTitle2: 'หัวข้อย่อยขั้นตอนการสกัด สอง',
              StepSubtitle2: 'หัวข้อขั้นตอนการสกัด สอง',

              StepIcon3: 'ไอคอนขั้นตอนการสกัด สาม',
              StepHoverIcon3: 'ไฮไลท์ไอคอนขั้นตอนการสกัด สาม',
              StepTitle3: 'หัวข้อย่อยขั้นตอนการสกัด สาม',
              StepSubtitle3: 'หัวข้อขั้นตอนการสกัด สาม',

              StepIcon4: 'ไอคอนขั้นตอนการสกัด สี่',
              StepHoverIcon4: 'ไฮไลท์ไอคอนขั้นตอนการสกัด สี่',
              StepTitle4: 'หัวข้อย่อยขั้นตอนการสกัด สี่',
              StepSubtitle4: 'หัวข้อขั้นตอนการสกัด สี่',

              StepIcon5: 'ไอคอนขั้นตอนการสกัด ห้า',
              StepHoverIcon5: 'ไฮไลท์ไอคอนขั้นตอนการสกัด ห้า',
              StepTitle5: 'หัวข้อย่อยขั้นตอนการสกัด ห้า',
              StepSubtitle5: 'หัวข้อขั้นตอนการสกัด ห้า',

              LeftImage: 'รูปภาพผลการทดลองฝั่งซ้าย',
              RightImage: 'รูปภาพผลการทดลองฝั่งขวา',
            },
          },
          Certification: {
            CardTitle: 'จัดการการ์ดใบรับรอง',
            FileTitle: 'จัดการไฟล์ใบรับรอง',

            Name: 'ชื่อ',
            Title: 'หัวข้อ',
            Detail: 'รายละเอียด',
            Image: 'รูปปก',
            CertImage: 'รูปภาพใบรับรอง',
            CardSelect: 'การ์ดใบรับรอง',
            ActiveStatus: 'สถานะ',
          },
        },
      },
    },
    FormQuotation: {
      SelectCustomer: 'กรุณาเลือกผู้ติดต่อ',
      SelectStockType: 'กรุณาเลือกประเภทคลังสินค้า',
      SelectItem: 'กรุณาเลือกสินค้า/บริการ',
      SelectShippingAddress: 'กรุณาเลือกที่อยู่ในการจัดส่งสินค้า',
      SelectReceiveAddress: 'กรุณาเลือกที่อยู่ในการรับสินค้า',
      SelectBillingAddress: 'กรุณาเลือกที่อยู่ในการจัดส่งใบกำกับภาษี',
      SelectIssueBillingAddress: 'กรุณาเลือกที่อยู่ในการออกใบกำกับภาษี',
      SelectPaymentMethod: 'กรุณาเลือกช่องทางการชำระเงิน',
      CreateDate: 'วันที่ออก',
      ExprieDate: 'ใช้ได้ถึง',
      DocCode: 'เอกสารเลขที่',
      DocRef: 'เอกสารอ้างอิง',
      SaleRef: 'ผู้ขาย',
      Price: {
        Title: 'ราคา',
        Select: 'เลือกประเภทภาษี',
        Exclude: 'แยกภาษี',
        Include: 'รวมภาษี',
      },
      AddressTax: 'ที่อยู่ออกใบกำกับภาษี',
      AddressShipping: 'ที่อยู่ในการจัดส่งสินค้า',
      AddressReceive: 'ที่อยู่ในการรับสินค้า',
      AddressBilling: 'ที่อยู่ในการจัดส่งใบกำกับภาษี',
      AddressIssueBilling: 'ที่อยู่ในการออกใบกำกับภาษี',
      Tel: 'เบอร์โทร',
      RecieveMoreProduct: {
        Label: 'มีการรับคืนสินค้าเพิ่มหรือไม่',
        Option1: 'ไม่รับคืนสินค้า',
        Option2: 'รับคืนสินค้า',
        Description1: 'จำนวนสต๊อกของสินค้าจะไม่มีการเปลี่ยนแปลง',
        Description2: 'จำนวนสต๊อกของสินค้าจะเพิ่มขึ้น',
        Reason: 'สาเหตุการลดหนี้',
      },
      SendMoreProduct: {
        Label: 'มีการส่งสินค้าเพิ่มหรือไม่',
        Option1: 'ไม่ส่งสินค้าเพิ่ม',
        Option2: 'ส่งสินค้าเพิ่ม',
        Description1: 'จำนวนสต๊อกของสินค้าจะไม่มีการเปลี่ยนแปลง',
        Description2: 'จำนวนสต๊อกของสินค้าจะลดลง',
        Reason: 'สาเหตุการเพิ่มหนี้',
      },
      DescriptionOfReason: 'คำอธิบายอย่างย่อ',
    },
    SearchPanel: {
      General: {},
      Document: {
        CustomerName: 'ผู้ติดต่อ',
        DocCode: 'เลขที่เอกสาร',
        CreateDateFrom: 'จากวันที่ออกเอกสาร',
        CreateDateTo: 'ถึงวันที่ออกเอกสาร',
        PriceFrom: 'จากมูลค่า',
        PriceTo: 'ถึงมูลค่า',
      },
    },
    ControlPanel: {
      AreYouSureToCancel: 'คุณแน่ใจไหมที่จะยกเลิกการ',
      AreYouSureTo: 'คุณแน่ใจไหมที่จะ',
      Confirm: 'ยืนยันการ',
      Cancel: 'ยกเลิกการ',
      Create: 'สร้าง',
      Edit: 'แก้ไข',
    },
    Stock: {
      AreYouSureToCancel: 'คุณแน่ใจไหมที่จะยกเลิกการ',
      AreYouSureTo: 'คุณแน่ใจไหมที่จะ',
      Confirm: 'ยืนยันการ',
      Cancel: 'ยกเลิกการ',
      Create: 'สร้าง',
      Edit: 'แก้ไข',
      FG: 'FG',
      FGReserve: 'FG (จอง)',
      FGAvailable: 'FG (สามารถใช้ได้)',
      FOC: 'FOC',
      FOCReserve: 'FOC (จอง)',
      FOCAvailable: 'FOC (สามารถใช้ได้)',
      Reserve: 'จอง',
      Avaliable: 'สามารถใช้ได้',
      CenterIn: 'ถังกลาง (ขาเข้า)',
      CenterOut: 'ถังกลาง (ขาออก)',
      Claim: 'ถังเคลม',
      Clear: 'ถังเคลียร์',
      Balance: 'จำนวนทั้งหมด',
      Document: 'เอกสาร',
      TransferGoods: 'โอนสินค้า',
      StockType: 'ประเภทคลัง',
      Type: 'ประเภท',
      Quantity: 'จำนวน',
      TransferIn: 'ย้ายเข้า',
      TransferOut: 'ย้ายออก',
      Adjusted: 'จัดการ',
      Adjust: 'จัดการ',
      Increase: 'เพิ่ม',
      Decrease: 'ลด',
      Transfer: 'ย้าย',
      From: 'จาก',
      To: 'ไปยัง',
      Before: 'ก่อนปรับ',
      After: 'หลังปรับ',
      Remarks: 'หมายเหตุ',
      Name: 'ชื่อสินค้า',
      Remain: 'คงเหลือ',
      Used: 'ใช้',
      QuantityForAdd: 'ต้องใช้ทั้งหมด',
      AdjustStock: 'จัดการสินค้า',
      AdjustSetStock: 'จัดการชุดสินค้า',
      Purchase: 'ซื้อเข้า',
      Sell: 'ขายออก',
      Return: 'รับคืน',
      CancelReserve: 'ยกเลิกจอง',
      AdjustSet: 'จัดการชุดสินค้า',
      CreditNote: 'ลดหนี้',
      DebitNote: 'เพิ่มหนี้',
    },
    Brand: {
      CancelCreate: 'ยกเลิกการสร้าง',
      Create: 'สร้าง',
      Edit: 'แก้ไข',
      Confirmsave: 'ยืนยันการบันทึก',
      Confirmedit: 'ยืนยันการแก้ไข',
      Confirmcreate: 'ยืนยันการสร้าง',
      Save: 'บันทึก',
      CancelEditTitle: 'ยกเลิกการแก้ไขการจัดการแบรนด์',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขการจัดการแบรนด์',
      CancelAddTitle: 'ยกเลิกการเพิ่มการจัดการแบรนด์',
      CancelAdd: 'คุณแน่ใจไหมที่จะยกเลิกการเพิ่มการจัดการแบรนด์',
      ConfirmEditTitle: 'ยืนยันการแก้ไขการจัดการแบรนด์',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขการจัดการแบรนด์',
      ConfirmAddTitle: 'ยืนยันการเพิ่มการจัดการแบรนด์',
      ConfirmAdd: 'คุณแน่ใจไหมที่จะเพิ่มการจัดการแบรนด์',
      Active: 'คุณแน่ใจไหมที่จะ',
      DeleteTitle: 'ยืนยันการลบ',
      Delete: 'คุณแน่ใจไหมที่จะลบ',
    },
    DocTable: {
      No: 'ลำดับ',
      Item: 'สินค้า/บริการ',
      Detail: 'รายละเอียด',
      Quantity: 'จำนวน',
      Price: 'ราคา',
      Wht: 'หัก ณ ที่จ่าย',
      StockType: 'ประเภทคลังสินค้า',
      Select: 'เลือกสินค้า',
      Discount: 'ส่วนลด',
      PreTaxAmount: 'ยอดก่อนภาษี',
      PreNewAmount: 'มูลค่าที่ถูกต้องก่อนภาษี',
      Button: {
        AddItem: 'เพิ่มรายการ',
        AddDes: 'เพิ่มคำอธิบาย',
        Reset: 'คืนค่า',
      },
    },
    ViewTable: {
      No: '#',
      Code: 'เลขที่เอกสาร',
      CustomerName: 'ชื่อลูกค้า/คู่ค้า',
      CustomerCode: 'รหัสลูกค้า/คู่ค้า',
      EventName: 'ชื่ออีเว้นท์',
      Status: 'สถานะ',
      DateFrom: 'วันที่',
      DateTo: 'ถึงวันที่',
      GrandTotal: 'จำนวนเงินที่ต้องชำระ',
      Actions: 'จัดการ',
      NoItemPreview: 'ไม่มีรายการข้อมูล',
      Name: 'ชื่อ',
      UpdateDate: 'วันที่อัปเดต',
      CreateBy: 'ถูกสร้างโดย',
      Role: 'ระดับผู้ใช้งาน',
      CreateDate: 'วันที่สร้าง',
      By: 'โดย',
      Date: 'วันที่',
      UserName: 'ชื่อผู้ใช้งาน',
      Info: 'ข้อมูล',
      InventoryStatus: 'สถานะสินค้า',
      CodeName: 'ชื่อโปรโมชั่น',
      RedeemCode: 'โค้ดโปรโมชั่น',
      StartDate: 'วันที่เริ่ม',
      EndDate: 'วันที่สิ้นสุด',
      Amount: 'จำนวน',
      OrderCode: 'รหัสคำสั่งซื้อ',
    },
    DocFooter: {
      Description: 'หมายเหตุ',
      Validate: '*บันทึกได้ไม่เกิน 9 บรรทัด',
      TotalDiscount: 'ส่วนลดรวม',
      AmountVatExemp: 'ยอดรวมรายการก่อนภาษี',
      Vat: 'ภาษีมูลค่าเพิ่ม',
      Baht: 'บาท',
      NetAmount: 'ยอดรวมสุทธิ',
      GrandTotal: 'จำนวนเงินที่ต้องชำระ',
      GrandTotalRepaid: 'จำนวนเงินที่ต้องชำระคืน',
      AmountOfReferred: 'มูลค่าตามเอกสารเดิม',
      NewAmount: 'มูลค่าที่ถูกต้อง',
      Not: 'ไม่มี',
      DeliveryPrice: 'ค่าจัดส่ง',
      SelectDiscountFormat: 'เลือกรูปแบบส่วนลด',
    },
    ActionHistory: {
      No: 'เลขที่',
      UsageActivity: 'ประวัติการใช้งาน',
      Created: 'สร้าง',
      Paid: 'ชำระ',
      Attach: ' ใบ',
      Viewed: 'เรียกดู',
      At: 'เมื่อ',
      Update: 'อัปเดต',
      Accept: 'อนุมัติ',
      Void: 'ยกเลิก',
      Cancel: 'ยกเลิก',
      DocumentLog: 'ประวัติเอกสาร',
      IssueTaxInvoice: 'ออกใบกำกับภาษี',
      GotReceipt: 'รับใบเสร็จ',
      VoidGotReceipt: 'ยกเลิกรับใบเสร็จ',
      TaxInvoice: 'เลขใบกำกับภาษี',
      RegisterTaxInvoice: 'รับใบกำกับภาษี',
      VoidTaxInvoice: 'ยกเลิกใบกำกับภาษี',
    },
    ContentDocument: {
      No: 'เลขที่',
      Title: 'หัวข้อ',
      Ref: 'อ้างอิง',
      Print: 'พิมพ์',
      PrintDemo: 'พิมพ์ (ตัวอย่าง)',
      Menu: 'เมนู',
      Edit: 'แก้ไข',
      CustomerInfo: 'ข้อมูลลูกค้า',
      Customer: 'ชื่อลูกค้า',
      Address: 'ที่อยู่',
      IssueDate: 'วันที่ออก',
      DueDate: 'วันที่ใช้ได้ถึง',
      ValidUntil: 'Valid Until',
      PricingAndTaxSetting: 'ข้อมูลราคาและภาษี',
      CreditNoteInfo: 'ข้อมูลการลดหนี้',
      DebitNoteInfo: 'ข้อมูลการเพิ่มหนี้',
      PricingType: 'ประเภทราคา',
      Currency: 'ราคานี้เป็นค่าเงิน',
      ProductService: 'สินค้า/บริการ',
      Description: 'คำอธิบายรายการ',
      Quantity: 'จำนวน',
      Unit: 'หน่วย',
      PriceQ: 'ราคา/หน่วย',
      CostQ: 'ต้นทุน/หน่วย',
      DiscQ: 'ส่วนลด/หน่วย',
      CostAmount: 'ต้นทุนรวม',
      PreVatAmount: 'มูลค่าก่อนภาษี',
      DocumentSummary: 'เอกสารที่ออก',
      CurrentDocument: 'เอกสารปัจจุบัน',
      Discount: 'มูลค่าส่วนลดรวม',
      VatItemValue: 'มูลค่ารายการก่อนภาษี',
      VatAmount: 'ภาษีมูลค่าเพิ่มรวม',
      NetTotal: 'มูลค่ารวม',
      Baht: 'บาท',
      RemarkForCustomer: 'หมายเหตุสำหรับลูกค้า',
      HeadOffice: 'สำนักงานใหญ่',
      Branch: 'สาขา',
      Exclude: 'แยกภาษี',
      Include: 'รวมภาษี',
      WithholdingTaxAmount: 'จำนวนเงินที่ถูกหัก ณ ที่จ่าย',
      NetAmountToPay: 'จำนวนเงินที่จะต้องชำระ',
      Difference: 'ผลต่าง',
      TotalAmountOfReferredTaxInvoices: 'ราคามูลค่าตามใบกำกับภาษีเดิม',
      NewAmount: 'มูลค่าที่ถูกต้อง',
      CancelDocument: 'ยกเลิกเอกสาร',
      VoidDocument: 'ยกเลิกเอกสาร',
      CreateTaxInvoice: 'สร้างใบกำกับภาษี',
      VoidGotReceipt: 'ยกเลิกรับใบเสร็จ',
      Amount: 'มูลค่ารวม',
      Remain: 'คงเหลือ',
      Item: 'รายการ',
      ItemForItemSet: 'รายการไอเทมสำหรับชุดไอเทม',
      ItemSetValue: 'มูลค่าชุดไอเทม',
      ValueForItemSet: 'มูลค่าสำหรับชุดไอเทม',
      ValueForItemSetCost: 'มูลค่าต้นทุนสำหรับชุดไอเทม',
      TotalCostPrice: 'มูลค่าต้นทุนรวม',
      TotalProductPrice: 'มูลค่ารายการสินค้ารวม',
      InventoryStatus: 'สถานะการโอนสินค้า',
      TransferCenterInAll: 'โอนเข้าคลังสินค้า',
      SetInventory: 'ตัดจำนวนสินค้า',
      EditRemarks: 'แก้ไขหมายเหตุ',
      PaymentMethod: 'ประเภทการชำระเงิน',
      TrackingNumber: 'หมายเลขพัสดุ',
      TransportationChannel: 'ช่องทางขนส่ง',
      ShippingInfo: 'ข้อมูลที่อยู่จัดส่ง',
      Reciever: 'ชื่อผู้รับ',
      ShippingAddress: 'ที่อยู่จัดส่งสินค้า',
      TaxInvoiceInfo: 'ข้อมูลออกใบกำกับภาษี',
      TaxInvoiceCustomerName: 'ชื่อผู้ออกใบกำกับภาษี',
      TaxInvoiceAddress: 'ที่อยู่ออกใบกำกับภาษี',
    },

    SaleOrder: {
      ConfirmOrder: 'ยืนยันใบสั่งขายของคุณ',
      CreateIVFormSO: 'สร้างใบแจ้งหนี้จากใบสั่งขาย',
      Approve: 'อนุมัติ',
      toApprove: 'อนุมัติ',
      this: 'นี้',
      Approvespace: 'อนุมัติ',
      ApproveSale: 'การอนุมัติเพื่อออกใบแจ้งหนี้',
      DetailSale: 'คุณยืนยันที่จะอนุมัติเพื่อออกใบแจ้งหนี้',
    },
    CreditDebit: {
      Create: 'สร้าง',
      EnterYour: 'เลือกข้อมูล',
      SelectDocument: 'เลือกเอกสาร',
      Business: 'บริษัท',
      DocumentNo: 'เลขที่เอกสาร',
      PleaseSelectBusiness: 'โปรดเลือกข้อมูลบริษัท',
      PleaseSelectDocumentNo: 'โปรดเลือกเลขที่เอกสาร',
      SelectBusiness: 'เลือกข้อมูลบริษัท',
      SelectDocumentNo: 'เลือกข้อมูลเลขที่เอกสาร',
      BusinessNotFound: 'ไม่พบข้อมูลบริษัท',
      DocumentNotFound: 'ไม่พบข้อมูลเลขที่เอกสาร',
      DebitNoteInfo: 'ข้อมูลการเพิ่มหนี้',
      CreditNoteInfo: 'ข้อมูลการลดหนี้',
      EnterDebitNoteInfo: 'กรอกข้อมูลการเพิ่มหนี้',
      EnterCreditNoteInfo: 'กรอกข้อมูลการลดหนี้',
      ReasonForIssuingCreditNote: 'กรุณาระบุสาเหตุของการลดหนี้',
      ReasonForIssuingDebitNote: 'กรุณาระบุสาเหตุของการเพิ่มหนี้',
      DescriptionOfReason: 'รายละเอียดอย่างย่อ',
    },
    Payment: {
      Baht: 'บาท',
      ReceivePaymentRecord: 'รับชำระเงิน',
      PaymentRecord: 'ชำระเงิน',
      TheTotalAmountOfReceivableDebt: 'มูลค่าลูกหนี้ที่สามารถชำระได้ทั้งสิ้น',
      PaymentRecieveMethod: 'รับเงินโดย',
      PaymentMethod: 'รับเงินโดย',
      PaymentReceiveAmount: 'จำนวนเงินที่รับชำระ',
      PaymentAmount: 'จำนวนเงินที่ชำระ',
      Remark: 'หมายเหตุ',
      MoneyPaymentRecieve: 'รับชำระด้วยเงินรวม',
      MoneyPayment: 'ชำระด้วยเงินรวม',
      Paid: 'มูลค่าที่ชำระแล้ว',
      TaxWithheld: 'ถูกหัก ณ​ ที่จ่าย',
      TotalPaymentRecieve: 'รับชำระรวมทั้งสิ้น',
      TotalPayment: 'ชำระรวมทั้งสิ้น',
      RemainingAmount: 'ต้องชำระเงินอีก',
      Selectmethod: 'กรุณาเลือกช่องทางการชำระเงิน',
      dueDate: 'วันครบกำหนด',
      getPaidOn: 'ชำระเมื่อ',
      getFullReceivePaid: 'รับชำระครบเมื่อวันที่',
      getFullPaid: 'ชำระครบเมื่อวันที่',
      PaymentDate: 'วันที่ชำระเงิน',
      FullReceivePaid: 'รับชำระเต็มจำนวนแล้ว',
      FullPaid: 'ชำระเต็มจำนวนแล้ว',
      paymentMethod: 'ช่องทางชำระเงิน',
      payWithDoc: 'ตัดชำระกับเอกสาร',
      stillPay: 'ยังชำระไม่ครบจำนวน',
      thisPrice: 'ราคานี้เป็นค่าเงิน',
      NoCPay: 'ชำระเงินครั้งที่',
      reCPay: 'รับชำระเงินครั้งที่',
      cutOPay: 'ตัดชำระกับเอกสารครั้งที่',
      Cash: 'เงินสด',
      cash: 'เงินสด',
      Transfer: 'โอนเงิน',
      CreditDebit: 'บัตรเครดิต/เดบิต',
      Cheque: 'เช็ค',
      Other: 'อื่นๆ',
      Modal: {
        AccountNo: 'บัญชีเลขที่ : ',
        CreateBankAccount: 'สร้างบัญชีธนาคาร',
        EditBankAccount: 'แก้ไขบัญชีธนาคาร',
        BankAccount: {
          Label: 'บัญชีรับเงิน',
          Placeholder: 'เลือกบัญชีรับเงิน',
          AddBankAccount: 'เพิ่มบัญชีธนาคาร',
          Error: 'กรุณาเลือกบัญชีรับเงิน',
          CancelCreateTitle: 'ยกเลิกการสร้างบัญชีธนาคาร',
          CancelCreate: 'คุณแน่ใจหรือไม่ที่ต้องการยกเลิกการสร้างบัญชีธนาคาร?',
          ConfirmCreateTitle: 'ยืนยันการสร้างบัญชีธนาคาร',
          ConfirmCreate: 'คุณแน่ใจหรือไม่ที่ต้องการสร้างบัญชีธนาคาร?',
          CancelEditTitle: 'ยกเลิกการแก้ไขบัญชีธนาคาร',
          CancelEdit: 'คุณแน่ใจหรือไม่ที่ต้องการยกเลิกการแก้ไขบัญชีธนาคาร?',
          ConfirmEditTitle: 'ยืนยันการแก้ไขบัญชีธนาคาร',
          ConfirmEdit: 'คุณแน่ใจหรือไม่ที่ต้องการแก้ไขบัญชีธนาคาร?',
          Bank: {
            Label: 'ธนาคาร',
            Placeholder: 'เลือกธนาคาร',
            Error: 'กรุณาเลือกธนาคาร',
          },
          AccountNumber: {
            Label: 'เลขที่บัญชี',
            Placeholder: 'กรอกเลขที่บัญชี',
            Error: 'กรุณากรอกเลขที่บัญชี',
          },
          BankBranch: {
            Label: 'สาขาธนาคาร',
            Placeholder: 'กรอกสาขาธนาคาร',
            Error: 'กรุณากรอกสาขาธนาคาร',
          },
          AccountName: {
            Label: 'ชื่อบัญชี',
            Placeholder: 'กรอกชื่อบัญชี',
            Error: 'กรุณากรอกชื่อบัญชี',
          },
          BankAccountType: {
            Label: 'ประเภทบัญชี',
            Saving: 'ออมทรัพย์',
            FixedDeposit: 'กระแสรายวัน',
            Current: 'ฝากประจำ',
          },
        },
      },
    },
    DocumentPaper: {
      Print: 'พิมพ์',
      FixedPage: 'ปรับหน้า',
      HideObject: 'แสดง/ซ่อน',
      PageNumber: 'หมายเลขหน้า',
      Manuscript: 'สถานะ',
      Watermark: 'ลายน้ำ',
      TableColor: 'สีตาราง',
      Discount: 'ส่วนลด',
      WithheldTax: 'ภาษีหัก ณ ที่จ่าย',
      ClearAll: 'เคลียร์',
      BGColor: 'สีพื้นหลัง',
      IsOriginal: 'เอกสารต้นฉบับ',
      ShippingFee: 'ค่าจัดส่ง',
    },
    FormName: {
      QuotationForm: 'ฟอร์มใบเสนอราคา',
      QuotationDetail: 'รายละเอียดใบเสนอราคา',
      SaleOrderForm: 'ฟอร์มใบสั่งขาย',
      SaleOrderDetail: 'รายละเอียดใบสั่งขาย',
      InvoiceForm: 'ฟอร์มใบแจ้งหนี้',
      InvoiceDetail: 'รายละเอียดใบแจ้งหนี้',
      TaxInvoiceForm: 'ฟอร์มใบกำกับภาษี',
      TaxInvoiceDetail: 'รายละเอียดใบกำกับภาษี',
      ReceiptDetail: 'รายละเอียดใบเสร็จรับเงิน',
      DebitNoteForm: 'ฟอร์มใบเพิ่มหนี้',
      DebitNoteDetail: 'รายละเอียดใบเพิ่มหนี้',
      CreditNoteForm: 'ฟอร์มใบลดหนี้',
      CreditNoteDetail: 'รายละเอียดใบลดหนี้',

      PurchaseRequestForm: 'ฟอร์มใบขอสั่งซื้อ',
      PurchaseRequestDetail: 'รายละเอียดใบขอสั่งซื้อ',

      PurchaseOrder: 'ฟอร์มใบสั่งซื้อ',
      PurchaseOrderDetail: 'รายละเอียดใบสั่งซื้อ',

      GoodsReceiveForm: 'ฟอร์มใบรับสินค้า',
      GoodsReceiveDetail: 'รายละเอียดใบรับสินค้า',
    },
    Quotation: {
      ConfirmOrder: 'ยืนยันใบเสนอราคาของคุณ',
      CancelCreateTitle: 'ยกเลิกการสร้างใบเสนอราคา',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างใบเสนอราคานี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขใบเสนอราคา',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขใบเสนอราคานี้',
      SaveDraftTitle: 'บันทึกร่างใบเสนอราคา',
      SaveDraft: 'คุณแน่ใจไหมที่จะบันทึกร่างใบเสนอราคานี้',
      EditTitle: 'แก้ไขใบเสนอราคา',
      Edit: 'คุณแน่ใจไหมที่จะแก้ไขใบเสนอราคานี้',
      CreateTitle: 'สร้างใบเสนอราคา',
      Create: 'คุณแน่ใจไหมที่จะสร้างใบเสนอราคานี้',
      ApproveSale: 'การอนุมัติเพื่อออกใบสั่งขาย',
      DetailSale: 'คุณยืนยันที่จะอนุมัติเพื่อออกใบสั่งขาย ?',
    },
    ManageItem: {
      CancelCreateTitle: 'ยกเลิกการสร้างสินค้า',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างสินค้านี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขสินค้า',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขสินค้านี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างสินค้า',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างสินค้านี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขสินค้า',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขสินค้านี้',
    },
    ManageItemSet: {
      CancelCreateTitle: 'ยกเลิกการสร้างชุดสินค้า',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างชุดสินค้านี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขชุดสินค้า',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขชุดสินค้านี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างชุดสินค้า',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างชุดสินค้านี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขชุดสินค้า',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขชุดสินค้านี้',
    },
    ManageGroup: {
      CancelCreateTitle: 'ยกเลิกการสร้างกลุ่ม',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างกลุ่มนี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขกลุ่ม',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขกลุ่มนี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างกลุ่ม',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างกลุ่มนี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขกลุ่ม',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขกลุ่มนี้',
    },
    ManageCategory: {
      CancelCreateTitle: 'ยกเลิกการสร้างหมวดหมู่',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างหมวดหมู่นี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขหมวดหมู่',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขหมวดหมู่นี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างหมวดหมู่',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างหมวดหมู่นี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขหมวดหมู่',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขหมวดหมู่นี้',
    },
    ManageSubCategory: {
      CancelCreateTitle: 'ยกเลิกการสร้างหมวดหมู่ย่อย',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างหมวดหมู่ย่อยนี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขหมวดหมู่ย่อย',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขหมวดหมู่ย่อยนี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างหมวดหมู่ย่อย',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างหมวดหมู่ย่อยนี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขหมวดหมู่ย่อย',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขหมวดหมู่ย่อยนี้',
    },
    ManageClass: {
      CancelCreateTitle: 'ยกเลิกการสร้างคลาส',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างคลาสนี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขคลาส',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขคลาสนี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างคลาส',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างคลาสนี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขคลาส',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขคลาสนี้',
    },
    ManageSubClass: {
      CancelCreateTitle: 'ยกเลิกการสร้างคลาสย่อย',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างคลาสย่อยนี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขคลาสย่อย',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขคลาสย่อยนี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างคลาสย่อย',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างคลาสย่อยนี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขคลาสย่อย',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขคลาสย่อยนี้',
    },
    ManageRole: {
      CancelCreateTitle: 'ยกเลิกการสร้างระดับผู้ใช้งาน',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างระดับผู้ใช้งานนี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขระดับผู้ใช้งาน',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขระดับผู้ใช้งานนี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างระดับผู้ใช้งาน',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างระดับผู้ใช้งานนี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขระดับผู้ใช้งาน',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขระดับผู้ใช้งานนี้',
      DeleteTitle: 'ยืนยันการลบระดับผู้ใช้งาน',
      Delete: 'คุณแน่ใจไหมที่จะลบระดับผู้ใช้งานนี้',
      RoleName: 'ชื่อระดับผู้ใช้งาน',
      Active: 'คุณแน่ใจไหมที่จะ',
    },
    ManageUser: {
      CancelCreateTitle: 'ยกเลิกการสร้างผู้ใช้งาน',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างผู้ใช้งานนี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขผู้ใช้งาน',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขผู้ใช้งานนี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างผู้ใช้งาน',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างผู้ใช้งานนี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขผู้ใช้งาน',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขผู้ใช้งานนี้',
      DeleteTitle: 'ยืนยันการลบผู้ใช้งาน',
      Delete: 'คุณแน่ใจไหมที่จะลบผู้ใช้งานนี้',
      ResetTitle: 'ยืนยันการรีเซ็ตรหัสผ่าน',
      Reset: 'คุณแน่ใจไหมที่จะรีเซ็ตรหัสผ่านผู้ใช้งานนี้',
      Active: 'คุณแน่ใจไหมที่จะ',
    },
    CreditNote: {
      ConfirmCreateTitle: 'ยืนยันการสร้างใบลดหนี้',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างใบลดหนี้นี้',
    },
    DebitNote: {
      ConfirmCreateTitle: 'ยืนยันการสร้างใบเพิ่มหนี้',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างใบเพิ่มหนี้นี้',
    },
    GoodsReceive: {
      GetReceipt: 'รับใบเสร็จ',
      YouCanGetTheReceipt: 'คุณสามารถรับใบเสร็จได้',
      VoidGetReceipt: 'ยกเลิกรับใบเสร็จ',
    },
    Files: {
      NoFileChosen: 'ยังไม่ได้เลือกไฟล์',
      ChooseFile: 'เลือกไฟล์',
    },
    Informative: {
      Description: 'คำอธิบาย',
      ActiveStatus: 'เปิดใช้งาน',
      Home: {
        BannerImage: 'รูปภาพแบนเนอร์',
        BannerImageDescription: 'ความละเอียด 1920px*730px',
        BannerVideo: 'วิดีโอแบนเนอร์',
        BannerVideoDescription: 'ความละเอียด 1920px*730px',
        UseBannerAsVideo: 'ใช้แบนเนอร์เป็นวิดีโอ',
        WhoWeAreQuote: {
          Label: 'สโลแกนว่าเราคือใคร',
          Description: 'สโลแกน',
        },
        WhoWeAreDescription: {
          Label: 'คำอธิบายว่าเราคือใคร',
          Description: 'คำอธิบาย',
        },
        WhoWeAreImage1: {
          Label: 'รูปภาพว่าเราคือใคร 1',
          Description: 'ความละเอียด: 260px*260px',
        },
        WhoWeAreImage2: {
          Label: 'รูปภาพว่าเราคือใคร 2',
          Description: 'ความละเอียด: 440px*640px',
        },
        WhoWeAreImage3: {
          Label: 'รูปภาพว่าเราคือใคร 3',
          Description: 'ความละเอียด: 480px*300px',
        },
        NewProductSection: 'คำอธิบายส่วนสินค้าใหม่',
        NewProductBG: 'ภาพพื้นหลังสินค้าใหม่',
        NewProductBGDescription: 'ความละเอียด: 940px*750px',
        NewProductWordingButton: 'ข้อความปุ่มสินค้าใหม่',
        WordingOnButton: 'ข้อความบนปุ่ม',
        NewProductURL: 'URL สินค้าใหม่',
        NewProductURLDescription: 'URL สำหรับ redirect',
        OurTechnologyQuote: 'สโลแกนเทคโนโลยีของเรา',
        OurTechnologyVideo: 'วีดีโอเทคโนโลยีของเรา',
        OurTechnologyVideoDescription: 'ความละเอียด: 1280px*600px',
        MemberPromotionImage1: 'รูปภาพโปรโมชันสำหรับสมาชิก 1',
        MemberPromotionImage1Description: 'ความละเอียด: 660px*447px',
        MemberPromotionImage2: 'รูปภาพโปรโมชันสำหรับสมาชิก 2',
        MemberPromotionImage2Description: 'ความละเอียด: 660px*447px',
      },
      About: {
        BannerTitle: 'ชื่อแบนเนอร์',
        MainWordingOnBanner: 'ข้อความหลักบนแบนเนอร์',
        BannerSubTitle: 'ชื่อแบนเนอร์รอง',
        SubWordingOnBanner: 'ข้อความรองบนแบนเนอร์',
        BannerImage: 'รูปภาพแบนเนอร์',
        BannerImageDescription:
          'แบนเนอร์รูปภาพพื้นหลัง, ความละเอียด 1920px*450px',
        QuoteTitle1: 'ชื่อสโลแกน (Quote) 1',
        QuoteDescription: 'คำอธิบายสโลแกน (Quote)',
        QuoteImage1: 'รูปภาพสโลแกน (Quote) 1',
        QuoteImageDescription: 'ความละเอียด 1280px*600px',
        AboutDescription1: 'รายละเอียดเกี่ยวกับ (About) 1',
        DescriptionOnLeftSide: 'คำอธิบายทางด้านซ้าย',
        AboutDescription2: 'รายละเอียดเกี่ยวกับ (About) 2',
        DescriptionOnRightSide: 'คำอธิบายทางด้านขวา',
        AboutImage1: 'รูปภาพเกี่ยวกับ (About) 1',
        AboutImage1Description: 'รูปภาพทางด้านซ้าย, ความละเอียด 220px*220px',
        AboutImage2: 'รูปภาพเกี่ยวกับ (About) 2',
        AboutImage2Description: 'รูปภาพตรงกลาง, ความละเอียด 400px*600px',
        AboutImage3: 'รูปภาพเกี่ยวกับ (About) 3',
        AboutImage3Description: 'รูปภาพทางด้านขวา, ความละเอียด 510px*300px',
        BannerTitle2: 'ชื่อแบนเนอร์ 2',
        BannerImage2: 'รูปภาพแบนเนอร์ 2',
        QuoteTitle2: 'ชื่อสโลแกน (Quote) 2',
        QuoteVideo: 'วีดีโอสโลแกน (Quote)',
      },
      Contact: {
        BannerTitle: 'ชื่อแบนเนอร์',
        BannerTitleDescription: 'ข้อความตรงกลางบนแบนเนอร์',
        BannerImage: 'รูปภาพแบนเนอร์',
        BannerImageDescription: 'ความละเอียด 1920px*450px',
        VisitUs: 'เยี่ยมชม',
        VisitUsDescription: 'คำอธิบายที่ตั้งสำนักงาน',
        CallUs: 'ติดต่อเรา',
        CallUsDescription: 'คำอธิบายของที่ตั้งร้านค้า',
        EmailUs: 'อีเมลของเรา',
        EmailUsDescription: 'คำอธิบายของการติดต่อเราโดยตรง',
        ContactFormImage1: 'รูปภาพฟอร์มติดต่อ 1',
        ContactFormImage1Description: 'ความละเอียด 510px*587px',
        ContactFormImage2: 'รูปภาพฟอร์มติดต่อ 2',
        ContactFormImage2Description: 'ความละเอียด 330px*387px',
      },
      NewsEvent: {
        NewsBanner: {
          Label: 'แบนเนอร์ข่าวสารและอีเวนต์',
          Description: 'ภาพพื้นหลังแบนเนอร์',
        },
        NewsTitle: {
          Label: 'ชื่อข่าวสารและอีเวนต์',
          Description: 'ชื่อแบนเนอร์',
        },
        ConsumerReviewDate: {
          Label: 'วันที่รีวิวจากผู้ใช้',
          Description: 'Date of Consumer Review',
        },
        ConsumerReviewTitle: {
          Label: 'หัวข้อรีวิวจากผู้ใช้',
          Description: 'Title of Consumer Review',
        },
        ConsumerReviewImage1: {
          Label: 'รูปภาพรีวิวจากผู้ใช้ 1',
          Description: '1st Image of Consumer Review',
        },
        Detail: {
          Label: 'รายละเอียด',
          Description: 'รายละเอียดการรีวิวจากผู้ใช้',
        },
        ConsumerReviewImage2: {
          Label: 'รูปภาพรีวิวจากผู้ใช้ 2',
          Description: '2nd Image of Consumer Review',
        },
        ReviewerName: {
          Label: 'ชื่อผู้รีวิว',
          Description: 'Name of Reviewer',
        },
        URLButton: {
          Label: 'URL ปุ่ม',
          Description: 'URL สำหรับ redirect',
        },
        SocialMedia: {
          Label: 'โซเชียลมีเดีย',
          Description: 'เลือกโซเชียลมีเดีย',
        },
        CompanyVideo: {
          ContentDate: {
            Label: 'วันที่คอนเทนต์',
            Description: 'วันที่วีดีโอของบริษัท',
          },
          VideoTitle: {
            Label: 'ชื่อวีดีโอ',
            Description: 'ชื่อวีดีโอของบริษัท',
          },
          VideoCoverImage: {
            Label: 'รูปภาพปกวีดีโอ',
            Description: 'รูปภาพปกวีดีโอของบริษัท',
          },
          Subtitle: {
            Label: 'รายละเอียด',
            Description: 'รายละเอียดวีดีโอของบริษัท',
          },
          RepoveLive: {
            Label: 'Repove Live',
            Description: 'เลือกเพื่อถ่ายทอดสด',
          },
          LiveDate: {
            Label: 'วันที่ถ่ายทอดสด',
            Description: 'วันที่ของการถ่ายทอดสด',
          },
          MediaType: {
            Label: 'ประเภทสื่อ',
            Description: 'วีดีโอหรือการถ่ายทอดสด',
          },
        },
      },
      SignIn: {
        BannerImage: {
          1: 'รูปภาพแบนเนอร์ 1',
          2: 'รูปภาพแบนเนอร์ 2',
        },
        BannerMobileImage: {
          1: 'รูปภาพแบนเนอร์ (จอมือถือ) 1',
          2: 'รูปภาพแบนเนอร์ (จอมือถือ) 2',
        },
        Description: 'ความละเอียด 648px*785px',
      },
      HelpCenter: {
        PersonalSaleTitle: {
          Label: 'การขายส่วนบุคคล',
          Description: 'หัวข้อ',
        },
        PersonalSaleDescription: {
          Label: 'การขายส่วนบุคคล',
          Description: 'คำอธิบาย',
        },
        DownlineSaleTitle: {
          Label: 'การขายดาวน์ไลน์',
          Description: 'หัวข้อ',
        },
        DownlineSaleDescription: {
          Label: 'การขายดาวน์ไลน์',
          Description: 'คำอธิบาย',
        },
        SystemBonusTitle: {
          Label: 'โบนัสของระบบ',
          Description: 'หัวข้อ',
        },
        SystemBonusDescription: {
          Label: 'โบนัสของระบบ',
          Description: 'คำอธิบาย',
        },
        PersonalBonusTable: {
          Label: 'ตารางโบนัสตามยอดขายส่วนบุคคล',
          Description: 'เพิ่มรูปภาพโบนัสยอดขายส่วนบุคคล',
        },
        DownlineBonusTable: {
          Label: 'ตารางโบนัสตามยอดขายดาวน์ไลน์',
          Description: 'เพิ่มรูปภาพโบนัสยอดขายดาวน์ไลน์',
        },
        SystemBonusTable: {
          Label: 'ตารางโบนัสของระบบ',
          Description: 'เพิ่มรูปภาพโบนัสของระบบ',
        },
        OverallBonusTable: {
          Label: 'ตารางสรุปโบนัสของแต่ละประเภท',
          Description: 'เพิ่มรูปสรุปโบนัสของแต่ละประเภท',
        },

        Categories: {
          Label: 'หมวดหมู่',
        },
      },
      Product: {
        BannerImage: {
          1: 'รูปภาพแบนเนอร์ 1',
          2: 'รูปภาพแบนเนอร์ 2',
        },
        BannerTitle: {
          Label: 'ชื่อแบนเนอร์',
          Description: 'ข้อความบนรูปภาพแบนเนอร์ 2',
        },
        Description: 'ความละเอียด 1920px*450px',
      },
      PrivacyPolicy: {
        Date: {
          Label: 'วันที่',
          Description: 'วันที่นโยบายความเป็นส่วนตัว',
        },
        Title: {
          Label: 'หัวข้อ',
          Description: 'หัวข้อนโยบายความเป็นส่วนตัว',
        },
        Description: {
          Label: 'คำอธิบาย',
          Description: 'คำอธิบายนโยบายความเป็นส่วนตัว',
        },
      },
      CookiePolicy: {
        Date: {
          Label: 'วันที่',
          Description: 'วันที่ข้อตกลงการใช้คุกกี้',
        },
        Title: {
          Label: 'หัวข้อ',
          Description: 'หัวข้อข้อตกลงการใช้คุกกี้',
        },
        Description: {
          Label: 'คำอธิบาย',
          Description: 'คำอธิบายข้อตกลงการใช้คุกกี้',
        },
      },
      TermsCondition: {
        Date: {
          Label: 'วันที่',
          Description: 'วันที่เงื่อนไขและข้อตกลง',
        },
        Title: {
          Label: 'หัวข้อ',
          Description: 'หัวข้อเงื่อนไขและข้อตกลง',
        },
        Description: {
          Label: 'คำอธิบาย',
          Description: 'คำอธิบายเงื่อนไขและข้อตกลง',
        },
      },
      SellerManagementRegulation: {
        Date: {
          Label: 'วันที่',
          Description: 'วันที่ Seller Management Regulation',
        },
        Title: {
          Label: 'หัวข้อ',
          Description: 'หัวข้อ Seller Management Regulation',
        },
        Description: {
          Label: 'คำอธิบาย',
          Description: 'คำอธิบาย Seller Management Regulation',
        },
      },
      RepoveToolsProduct: {
        PresenterImage: 'รูปภาพพรีเซ็นเตอร์',
        PresenterImageDescription: 'ความละเอียด 920px*670px',
        ProductNameTH: 'ชื่อสินค้า (ไทย)',
        ProductNameTHDescription: 'ชื่อสินภาษาไทยด้านบนสุด',
        ProductNameEN: 'ชื่อสินค้า (อังกฤษ)',
        ProductNameENDescription: 'ชื่อสินภาษาอังกฤษด้านล่างชื่อสินค้าภาษาไทย',
        ProductPrice: 'ราคาสินค้า',
        // ProductPriceDescription : 'ราคา',
        ProductVolume: 'ปริมาณสินค้า',
        ProductVolumeDetail: 'ปริมาณสินค้าถัดจากราคา',
        ProductDetail: 'รายละเอียดสินค้า',
        ProductDetailDiscription: 'รายละเอียดสินค้าด้านล่างราคาสินค้า',
        TechnologyDetails: 'รายละเอียดเทคโนโลยี',
        TechnologyDetailsDiscription: 'รายละเอียดเทคโนโลยีทางด้านซ้าย',
        FeatureTitle: 'ชื่อหัวข้อคุณสมบัติ',
        FeatureTitleDiscription: 'ชื่อหัวข้อคุณสมบัติด้านบน',
        OtherFeaturesTitle: 'ชื่อข้อคุณสมบัติอื่นๆ',
        OtherFeaturesTitleDiscription: 'ชื่อหัวข้อคุณสมบัติอื่นๆด้านล่าง',
        FeaturIcon1: 'ไอคอนคุณสมบัติ 1',
        FeaturIcon2: 'ไอคอนคุณสมบัติ 2',
        FeaturIcon3: 'ไอคอนคุณสมบัติ 3',
        FeaturIcon4: 'ไอคอนคุณสมบัติ 4',
        FeaturIconDiscription: 'ความละเอียด 50px*50px',
        FeaturDetail1: 'รายละเอียดคุณสมบัติ 1',
        FeaturDetail2: 'รายละเอียดคุณสมบัติ 2',
        FeaturDetail3: 'รายละเอียดคุณสมบัติ 3',
        FeaturDetail4: 'รายละเอียดคุณสมบัติ 4',
        OtherFeaturesIcon1: 'ไอคอนคุณสมบัติอื่นๆ 1',
        OtherFeaturesIcon2: 'ไอคอนคุณสมบัติอื่นๆ 2',
        OtherFeaturesIcon3: 'ไอคอนคุณสมบัติอื่นๆ 3',
        OtherFeaturesIcon4: 'ไอคอนคุณสมบัติอื่นๆ 4',
        OtherFeaturesDetail1: 'รายละเอียดคุณสมบัติอื่นๆ 1',
        OtherFeaturesDetail2: 'รายละเอียดคุณสมบัติอื่นๆ 2',
        OtherFeaturesDetail3: 'รายละเอียดคุณสมบัติอื่นๆ 3',
        OtherFeaturesDetail4: 'รายละเอียดคุณสมบัติอื่นๆ 4',
        ComponentImage: 'ภาพส่วนประกอบหลัก',
        ComponentImageDescription: 'ความละเอียด 920px*670px',
        ComponentDetails: 'รายละเอียดส่วนประกอบหลัก',
        ComponentDetailsDiscription: 'รายละเอียดส่วนประกอบหลักด้านขวา',
        TestResults: 'ผลการทดสอบ',
        TestResultsTablet: 'ผลการทดสอบ Tablet',
        TestResultsMobile: 'ผลการทดสอบ Mobile',
        BeforeAfterImage1: 'รูปภาพก่อนและหลัง 1',
        BeforeAfterImage2: 'รูปภาพก่อนและหลัง 2',
        BeforeAfterImage3: 'รูปภาพก่อนและหลัง 3',
        BeforeAfterImage4: 'รูปภาพก่อนและหลัง 4',
        BeforeAfterImageDiscription: 'ความละเอียด 480px*250px',
        bodywash: {
          ProductDetailHeadIcon: 'รายละเอียดสินค้า Icon ศรีษะ',
          ProductDetailFacialSkinIcon: 'รายละเอียดสินค้า Icon ผิวหน้า',
          ProductDetailbodyIcon: 'รายละเอียดสินค้า Icon ลำตัว',
          ProductDetailIconDescription: 'ความละเอียด 50px*50px',
          ProductDetailHeadTitle: 'รายละเอียดสินค้าหัวข้อศรีษะ',
          ProductDetailFacialSkinTitle: 'รายละเอียดสินค้าหัวข้อผิวหน้า',
          ProductDetailbodyTitle: 'รายละเอียดสินค้าหัวข้อลำตัว',
          ProductDetailTitleDescription: 'ชื่อหัวข้อด้านล่างรายละเอียดสินค้า',
          ProductDetailHeadDetail: 'รายละเอียดหัวข้อศรีษะ',
          ProductDetailFacialSkinDetail: 'รายละเอียดหัวข้อผิวหน้า',
          ProductDetailbodyDetail: 'รายละเอียดหัวข้อลำตัว',
          ProductDetailDetailDescription: 'รายละเอียดหัวข้อ',
          Label1: 'ป้ายชื่อการ์ดคุณสมบัติ 1',
          Label2: 'ป้ายชื่อการ์ดคุณสมบัติ 2',
          Label3: 'ป้ายชื่อการ์ดคุณสมบัติ 3',
          LabelDescription: 'ป้ายชื่อด้านบน icon',
        },
      },
      RepoveToolsTechnology: {
        BannerImg: 'รูปภาพแบนเนอร์',
        BannerImgDescription: 'ความละเอียด 1800px*600px',
        BannerDescription: 'คำอธิบายแบนเนอร์',
        BannerSubDescription: 'คำอธิบายแบนเนอร์รอง',
        BannerSubDescriptionDes: 'คำอธิบายแบนเนอร์รองด้านล่างคำอธิบายแบนเนอร์',
        Hydroethosome: {
          QuoteTitle1: 'ชื่อสโลแกน (Quote) 1',
          QuoteDescription: 'คำอธิบายสโลแกน (Quote)',
          QuoteImage1: 'รูปภาพสโลแกน (Quote) 1',
          QuoteImageDescription: 'ความละเอียด 400px*405px',
          FeatureCardIcon1: 'ไอคอนการ์ดคุณสมบัติ 1',
          FeatureCardIcon2: 'ไอคอนการ์ดคุณสมบัติ 2',
          FeatureCardIcon3: 'ไอคอนการ์ดคุณสมบัติ 3',
          FeatureCardIcon4: 'ไอคอนการ์ดคุณสมบัติ 4',
          FeatureCardIcon5: 'ไอคอนการ์ดคุณสมบัติ 5',
          FeatureCardIconDescription: 'ความละเอียด 60px*60px',
          FeatureCardDetail1: 'รายละเอียดการ์ดคุณสมบัติ 1',
          FeatureCardDetail2: 'รายละเอียดการ์ดคุณสมบัติ 2',
          FeatureCardDetail3: 'รายละเอียดการ์ดคุณสมบัติ 3',
          FeatureCardDetail4: 'รายละเอียดการ์ดคุณสมบัติ 4',
          FeatureCardDetail5: 'รายละเอียดการ์ดคุณสมบัติ 5',
          FeatureCardDetailDescription: 'รายละเอียดคุณสมบัติด้านล่าง icon',
          OtherBasedImage1: 'รูปภาพวัตถุดิบพื้นฐานอื่นๆ 1',
          OtherBasedImage2: 'รูปภาพวัตถุดิบพื้นฐานอื่นๆ 2',
          OtherBasedImage3: 'รูปภาพวัตถุดิบพื้นฐานอื่นๆ 3',
          OtherBasedImage4: 'รูปภาพวัตถุดิบพื้นฐานอื่นๆ 4',
          OtherBasedImage5: 'รูปภาพวัตถุดิบพื้นฐานอื่นๆ 5',
          OtherBasedImage6: 'รูปภาพวัตถุดิบพื้นฐานอื่นๆ 6',
          OtherBasedImageDescription: 'ความละเอียด 180px*180px',
          OtherBasedDetail1: 'รายละเอียดวัตถุดิบพื้นฐานอื่นๆ 1',
          OtherBasedDetail2: 'รายละเอียดวัตถุดิบพื้นฐานอื่นๆ 2',
          OtherBasedDetail3: 'รายละเอียดวัตถุดิบพื้นฐานอื่นๆ 3',
          OtherBasedDetail4: 'รายละเอียดวัตถุดิบพื้นฐานอื่นๆ 4',
          OtherBasedDetail5: 'รายละเอียดวัตถุดิบพื้นฐานอื่นๆ 5',
          OtherBasedDetail6: 'รายละเอียดวัตถุดิบพื้นฐานอื่นๆ 6',
          OtherBasedDetailDescription:
            'รายละเอียดวัตถุดิบพื้นฐานด้านล่างรูปภาพวัตถุดิบพื้นฐาน',
          hydroethosomeFunctionImg: 'รูปภาพสารสกัด',
          hydroethosomeFunctionImgDescription: 'ความละเอียด 350px*350px',
          hydroethosomeFunctionName: 'ชื่อสารสกัด',
          hydroethosomeFunctionDetail: 'รายละเอียดสารสกัด',
          hydroethosomeFunctionSubDetail: 'รายละเอียดสารสกัดรอง',
          hydroethosomeFunctionSubDetailDes:
            'รายละเอียดสารสกัดรองด้านล่างรายละเอียดสารสกัด',
          hydroFunctionCardIcon1: 'ไอคอนการ์ดสาร 1',
          hydroFunctionCardIcon2: 'ไอคอนการ์ดสาร 2',
          hydroFunctionCardIcon3: 'ไอคอนการ์ดสาร 3',
          hydroFunctionCardIcon4: 'ไอคอนการ์ดสาร 4',
          hydroFunctionCardIcon5: 'ไอคอนการ์ดสาร 5',
          hydroFunctionCardDescription: 'ความละเอียด 40px*40px',
          hydroFunctionCardDetail1: 'รายละเอียดการ์ดสาร 1',
          hydroFunctionCardDetail2: 'รายละเอียดการ์ดสาร 2',
          hydroFunctionCardDetail3: 'รายละเอียดการ์ดสาร 3',
          hydroFunctionCardDetail4: 'รายละเอียดการ์ดสาร 4',
          hydroFunctionCardDetail5: 'รายละเอียดการ์ดสาร 5',
          hydroFunctionCardDetailDescription:
            'รายละเอียดการ์ดสารด้านล่างไอคอนการ์ดสาร',
        },
      },
    },

    Notification: {
      Title: 'การแจ้งเตือน',
      StartDate: 'วันที่แจ้งเตือน',

      Manage: 'จัดการการแจ้งเตือน',
      NotificationType: 'ประเภทของการแจ้งเตือน',
      MemberType: 'ประเภท',

      SendNow: 'ส่งทันที',
      SendLater: 'ส่งภายหลัง',
    },
  },
};
