<form [formGroup]="payForm">
  <div class="modal-header" style="background-color: white">
    <div class="d-flex justify-content-between w-100 mt-2">
      <div>
        <h2 class="my-auto">
          {{ "Payment.PaymentRecord" | translate }} #{{
            runningNumber ? runningNumber : "-"
          }}
        </h2>
        <div class="d-flex justify-content-between">
          {{ "Payment.TheTotalAmountOfReceivableDebt" | translate }}
          {{ payData ? payData.summaryPrice : "-" }}
          {{ "Payment.Baht" | translate }}
        </div>
      </div>

      <div class="d-flex">
        <ng2-flatpickr
          *ngIf="isMaxTodayDate"
          [config]="maxTodayDateOption"
          [setDate]="maxTodayDateOption.defaultDate"
          formControlName="paymentDate"
          (change)="onDateChange($event)"
        ></ng2-flatpickr>
        <ng2-flatpickr
          *ngIf="!isMaxTodayDate"
          [config]="DefaultDateOption"
          [setDate]="DefaultDateOption.defaultDate"
          formControlName="paymentDate"
          (change)="onDateChange($event)"
        ></ng2-flatpickr>
      </div>
    </div>
  </div>

  <div class="modal-body" tabindex="0" ngbAutofocus>
    <!-- <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="custom-control-input"
          id="isCheckPayment"
          [checked]="isCheckPayment"
          (change)="togglePayment()"
        />
        <label class="custom-control-label" for="isCheckPayment"
          >ช่องทางการรับชำระเงิน</label
        >
      </div> -->
    <div class="detail-row row" *ngIf="isCheckPayment">
      <div class="col-5 mt-1">
        <label>{{ "Payment.PaymentRecieveMethod" | translate }}</label>
        <ng-select
          [items]="paymentList"
          bindLabel="name"
          bindValue="id"
          formControlName="paymentTypeId"
          [clearable]="false"
          [searchable]="false"
          [markFirst]="false"
          [(ngModel)]="selectedPayment"
          [loading]="loadingPaymentList"
          (change)="onPaymentMethodChange()"
          [ngClass]="{
            'is-invalid error':
              payForm.get('paymentTypeId').invalid &&
              (payForm.get('paymentTypeId').dirty ||
                payForm.get('paymentTypeId').touched)
          }"
        >
          <ng-option *ngFor="let payment of paymentList" [value]="payment.id">
            {{ payment.name }}
          </ng-option>
        </ng-select>
        <div
          *ngIf="
            payForm.get('paymentTypeId').invalid &&
            (payForm.get('paymentTypeId').dirty ||
              payForm.get('paymentTypeId').touched)
          "
          class="text-danger"
        >
          {{ "Payment.Selectmethod" | translate }}
        </div>
      </div>

      <div class="col-3 mt-1">
        <label>{{ "Payment.PaymentAmount" | translate }}</label>
        <div class="d-flex justify-content-end">
          <input
            type="text"
            class="input-number form-control text-end"
            (focus)="removeComma($event)"
            [value]="payData ? payData.grandTotalPrice : '-'"
            (change)="calculateBalance()"
            (blur)="onFormatAmountBlur()"
            id="summaryPrice"
            formControlName="summaryPrice"
            disabled
            *ngIf="!isDebitNote"
          />
          <input
            type="text"
            class="input-number form-control text-end"
            (focus)="removeComma($event)"
            [value]="payData ? payData.grandTotalPrice : '-'"
            (change)="calculateBalance()"
            (blur)="onFormatAmountBlur()"
            id="summaryPrice"
            formControlName="summaryPrice"
            *ngIf="isDebitNote"
            disabled
          />
        </div>
      </div>

      <div class="col-4 mt-1">
        <label>{{ "Payment.Remark" | translate }}</label>
        <div class="d-flex justify-content-end">
          <input
            type="text"
            class="form-control"
            id="paymentRemark"
            formControlName="paymentRemarks"
            (change)="onPaymentRemarks()"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="modal-body">
    <div class="d-flex justify-content-between w-100 mt-1 mb-1 moty">
      <!-- <div>
        <div class="d-flex justify-content-end subtotal-row">
          <p class="my-auto">{{ "Payment.MoneyPaymentRecieve" | translate }}</p>
          <p class="ml-1 my-auto" id="calculatedBalance1-discountWthTax">
            {{ payData ? payData.grandTotalPrice : "-" }}
          </p>
          <p class="ml-2 my-auto">{{ "Payment.Baht" | translate }}</p>
        </div>
        <div
          class="justify-content-end subtotal-row"
          [style.display]="isUseWHT ? 'flex' : 'none'"
        >
          <p class="my-auto">{{ "Payment.TaxWithheld" | translate }}:</p>
          <input
            type="text"
            class="input-number form-control text-end ml-1 my-auto"
            (focus)="removeComma($event)"
            [value]="payData ? payData.sumWhtTax : '-'"
            (change)="calculateBalance()"
            (blur)="onFormatAmountBlur()"
            id="discountWthTax"
            formControlName="discountWthTax"
          />
          <p class="ml-2 my-auto">{{ "Payment.Baht" | translate }}</p>
        </div>
      </div> -->
      <div class="w-100">
        <div class="row mr-1" *ngIf="isTransfer">
          <div class="col-12" style="margin-bottom: 0.5rem">
            <label>{{ "Payment.Modal.BankAccount.Label" | translate }}</label>
            <ng-container>
              <ng-select
                bindLabel="name"
                bindValue="id"
                [items]="bankAccountList"
                formControlName="bankAccountId"
                [clearable]="false"
                [searchable]="false"
                [markFirst]="false"
                placeholder="{{
                  'Payment.Modal.BankAccount.Placeholder' | translate
                }}"
                [loading]="isLoadingBankAccount"
                [(ngModel)]="selectedBankAccount"
                (change)="onPaymentCanCreateChange($event)"
                [ngClass]="{
                  'is-invalid error':
                    (isSubmit && isTransfer && f.bankAccountId?.invalid) ||
                    (f.bankAccountId?.invalid &&
                      (f.bankAccountId?.dirty || f.bankAccountId?.touched))
                }"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="custom-option-body">
                    <div
                      class="d-flex justify-content-between align-items-start"
                    >
                      <p class="custom-option-title custom-option-text">
                        {{ item.accountName }}, บัญชีเลขที่ :
                        {{ item.accountNumber }}
                      </p>
                      <div
                        class="badge badge-secondary"
                        *ngIf="item.isUseForDocument"
                      >
                        {{ "General.Default" | translate }}
                      </div>
                    </div>
                    <p class="card-text custom-option-text">
                      {{ item.bankName }}, {{ item.bankAccountTypeName }}
                    </p>
                  </div>
                </ng-template>
                <ng-template ng-footer-tmp>
                  <button
                    class="btn btn-primary"
                    (click)="onPaymentCanCreateChange('addBankAccount')"
                    style="float: right; margin-bottom: 5px"
                  >
                    <i data-feather="plus-square"></i>&nbsp;{{
                      "Payment.Modal.BankAccount.AddBankAccount" | translate
                    }}
                  </button>
                </ng-template>

                <ng-template ng-label-tmp let-item="item">
                  {{ item.name }}
                </ng-template>
              </ng-select>
            </ng-container>
            <div class="invalid-feedback">
              <div
                *ngIf="
                  (isSubmit && isTransfer && f.bankAccountId?.invalid) ||
                  (f.bankAccountId?.invalid &&
                    (f.bankAccountId?.dirty || f.bankAccountId?.touched))
                "
              >
                {{ "Payment.Modal.BankAccount.Bank.Error" | translate }}
              </div>
            </div>
          </div>
        </div>

        <!-- <div
          class="justify-content-end subtotal-row ml-1"
          [style.display]="isUseWHT ? 'flex' : 'none'"
        >
          <p class="my-auto">{{ "Payment.TaxWithheld" | translate }}:</p>
          <input
            type="text"
            class="input-number form-control text-end ml-1 my-auto"
            (focus)="removeComma($event)"
            [value]="
              convertNumbertoMoneyFormat(netData ? netData.salesWHT : 0.0)
            "
            (change)="calculateBalance()"
            (blur)="onFormatAmountBlur()"
            id="salesWHT"
            formControlName="salesWHT"
          />
          <p class="ml-2 my-auto">{{ "Payment.Baht" | translate }}</p>
        </div> -->
      </div>

      <div>
        <div class="grandTotal-box align-items-center mb-0">
          <p class="mb-0 mr-1 text-white text-left">
            {{ "Payment.TotalPaymentRecieve" | translate }}
          </p>
          <p class="grandTotal-price mb-0 align-items-center">
            {{ payData ? payData.grandTotalPrice : "0" }}
          </p>
          <p class="mb-0 ml-1">{{ "Payment.Baht" | translate }}</p>
        </div>

        <div>
          <div class="d-flex justify-content-end subtotal-row">
            <p class="my-auto">{{ "Payment.RemainingAmount" | translate }}:</p>
            <p class="ml-1 my-auto" id="calculatedBalance">
              {{ payData ? payData.grandTotalPrice : "0" }}
            </p>
            <p class="ml-2 my-auto">{{ "Payment.Baht" | translate }}</p>
          </div>
          <!-- <div class="d-flex justify-content-end subtotal-row">
            <p class="my-auto">{{ "Payment.TaxWithheld" | translate }}:</p>
            <p class="ml-1 my-auto">
              {{ payData ? payData.sumWhtTax : "-" }}
            </p>
            <p class="ml-2 my-auto">{{ "Payment.Baht" | translate }}</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</form>
