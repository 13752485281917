<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
  
      <app-search-panel
        [forCompanentName]="componentName"
        pathUrl="{{ pathUrl + '/create' }}"
        [isGridView]="isGridView"
        [isForGroupTemplate]="isForGroupTemplate"
        [isShowChildList]="isShowChildList"
        [isShowSortByGroup]="false"
        [isShowGroup]="false"
        [isShowCategory]="true"
        [isShowSubCategory]="true"
        [isShowClass]="true"
        [isShowSubClass]="true"
        (listView)="listView()"
        (gridView)="gridView()"
        (SearchFilter)="SearchFilter($event)"
        (ActiveStatusFilter)="ActiveStatusFilter($event)"
        (SortFilter)="SortFilter($event)"
        (SetLoading)="SetLoadingState()"
        (groupContentView)="groupContentView()"
        (childListView)="childListView()"
        (setPageSize)="setPageSize($event)"
        (exportXLSX)="exportXLSX()"
        (callDataFromAPI)="initData($event)"
      ></app-search-panel>
  
      <section [ngClass]="{ 'd-none': !isShowChildList }">
        <div  [ngClass]="{ 'd-none': isGridView }">
          <app-content-list
            [apiPath]="apiPath"
            [searchData]="searchData"
            [mainPathUrl]="pathUrl + '/edit'"
            [editPathUrl]="pathUrl + '/edit'"
            [keyName]="'name'"
            [page]="page"
            [pageSize]="pageSize"
            (deleteFunc)="deleteContent($event)"
            (isActiveFunc)="setActiveContent($event)"
            [isGoEditPage]="isGoEditPage"
            [isShowCoverImage]="false"
            [isSortByNewest]="true"
            [isForGroupTemplate]="isForGroupTemplate"
            [selectedColumns]="selectedColumns"
          ></app-content-list>
        </div>
      </section>
    </div>
  </div>