import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FileTypeFilter } from 'app/main/components/custom-dropzone/file-type.enum';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';

@Injectable({
  providedIn: 'root'
})
export class CertificationsFileService {

  config: ICMSConfig = {
    apiPath: 'certificationsfiles',
    pathUrl: '/cms/repove-tools/certification/file',
    tableName: 'Content',
    model: new Object(),
    isList: true,
    componentName: 'BreadCrumbs.CMS.RepoveTools.Certification.FileTitle',
    formConfig: {
      name: {
        type: FormType.Text,
        isMultipleLang: false,
        validator: [Validators.required],
        // label: 'Name',
        description: '',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Certification.Name',
        // descriptionTranslate:
        //   'Informative.NewsEvent.CompanyVideo.VideoTitle.Description',
      },

      certFiles: {
        type: FormType.MultipleImage,
        validator: [Validators.required],
        limit: 5,
        fileTypeFilter: FileTypeFilter.Images,
        label: 'Certifications Image',
        description: '',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Certification.CertImage',
        // descriptionTranslate:
        //   'Informative.NewsEvent.CompanyVideo.VideoCoverImage.Description',
      },

      reserved1: {
        type: FormType.Select,
        isMultipleLang: false,
        validator: [Validators.required],
        label: 'Certifications Card',
        description: '',
        selectDataAPI: 'certificationscard',
        selectDataLabelColumn: 'name',
        selectDataValueColumn: 'id',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Certification.CardSelect',
        // descriptionTranslate:
        //   'Informative.NewsEvent.CompanyVideo.VideoTitle.Description',
      },


      isActive: {
        type: FormType.IsActive,
        radioList: [
          { label: 'Active', translate: 'Status.Active', value: true },
          { label: 'Inactive', translate: 'Status.Inactive', value: false },
        ],
        // label: 'Active Status',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Certification.ActiveStatus',
      },
    },
  };

  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'name';

  constructor() { }

  
}
