<div class="collapse-icon">
  <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" [destroyOnHide]="false">
    <ngb-panel>
      <ng-template ngbPanelTitle>
        <span class="lead collapse-title">{{ 'Settings.DocumentInfo' | translate }}</span>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-document-info></app-document-info>
      </ng-template>
    </ngb-panel>
    <!-- <ngb-panel>
      <ng-template ngbPanelTitle>
        <span class="lead collapse-title"> Collapse Item 2 </span>
      </ng-template>
      <ng-template ngbPanelContent> </ng-template>
    </ngb-panel> -->
  </ngb-accordion>
</div>
