import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import CompanyCard from 'app/main/model/CompanyCard';

@Injectable({
  providedIn: 'root'
})
export class CompanyCardService {

  public config: ICMSConfig = {
    apiPath: 'AffliatedCompanies',
    pathUrl: '/cms/repove-tools/our-company/company-card',
    tableName: 'Content',
    model: new CompanyCard(),
    isList: true,
    componentName: 'BreadCrumbs.CMS.RepoveTools.CompanyCard.Title',
    formConfig: {
      coverImage: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Logo',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.CompanyCard.CoverImage',
      },
      type: {
        type: FormType.Text,
        validator: [Validators.required],
        // label: 'Company Type',
        // description: 'วันที่ของข่าวสาร',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.CompanyCard.Type',
      },
      name: {
        type: FormType.Text,
        validator: [Validators.required],
        // label: 'Name',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.CompanyCard.Name',
      },
      detail: {
        type: FormType.RichText,
        validator: [Validators.required],
        // label: 'Detail',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.CompanyCard.Detail',
      },
    }
  }

  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'name';

}
