import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import News from 'app/main/model/News';

@Injectable({
  providedIn: 'root',
})
export class CompanyVideoService {
  config: ICMSConfig = {
    apiPath: 'NewsVideo',
    pathUrl: '/cms/company-video',
    tableName: 'Content',
    model: new News(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.CompanyVideo',
    formConfig: {
      reserved1: {
        type: FormType.Datepicker,
        validator: [Validators.required],
        label: 'Content Date',
        description: 'Date of Company Video',
        labelTranslate: 'Informative.NewsEvent.CompanyVideo.ContentDate.Label',
        descriptionTranslate:
          'Informative.NewsEvent.CompanyVideo.ContentDate.Description',
      },
      name: {
        type: FormType.Text,
        isMultipleLang: false,
        validator: [Validators.required],
        label: 'Video Title',
        description: 'Title of Company Video',
        labelTranslate: 'Informative.NewsEvent.CompanyVideo.VideoTitle.Label',
        descriptionTranslate:
          'Informative.NewsEvent.CompanyVideo.VideoTitle.Description',
      },

      itemNewsPic: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Video Cover Image',
        description: 'Cover Image of Company Video',
        labelTranslate:
          'Informative.NewsEvent.CompanyVideo.VideoCoverImage.Label',
        descriptionTranslate:
          'Informative.NewsEvent.CompanyVideo.VideoCoverImage.Description',
      },

      detail: {
        type: FormType.RichText,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Subtitle',
        description: 'Subtitle of Company Video',
        labelTranslate: 'Informative.NewsEvent.CompanyVideo.Subtitle.Label',
        descriptionTranslate:
          'Informative.NewsEvent.CompanyVideo.Subtitle.Description',
      },

      isHighlight: {
        type: FormType.Switch,
        label: 'Repove Live',
        description: 'Check if is Live',
        labelTranslate: 'Informative.NewsEvent.CompanyVideo.RepoveLive.Label',
        descriptionTranslate:
          'Informative.NewsEvent.CompanyVideo.RepoveLive.Description',
      },

      activeDate: {
        type: FormType.Datepicker,
        isMultipleLang: false,
        validator: [Validators.required],
        label: 'Live Date',
        description: 'Date on Live',
        labelTranslate: 'Informative.NewsEvent.CompanyVideo.LiveDate.Label',
        descriptionTranslate:
          'Informative.NewsEvent.CompanyVideo.LiveDate.Description',
      },

      iconEnum: {
        type: FormType.Select,
        selectDataList: [
          { label: 'Video', value: 1 },
          { label: 'Live', value: 2 },
        ],
        defaultSelectLabel: '-- Select Media Type --',
        validator: [Validators.required],
        label: 'Media Type',
        description: 'Video or Live',
        labelTranslate: 'Informative.NewsEvent.CompanyVideo.MediaType.Label',
        descriptionTranslate:
          'Informative.NewsEvent.CompanyVideo.MediaType.Description',
      },

      isActive: {
        type: FormType.IsActive,
        radioList: [
          { label: 'Active', translate: 'Status.Active', value: true },
          { label: 'Inactive', translate: 'Status.Inactive', value: false },
        ],
        label: 'Active Status',
        labelTranslate: 'Informative.ActiveStatus',
      },
    },
  };

  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'name';
  constructor() {}
}
