import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { NewsService } from './news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit, OnDestroy {
  config!: ICMSConfig;
  keyName: string;

  constructor(private _newsService: NewsService) {
    this.config = this._newsService.config;
    this.keyName = this._newsService.keyName;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
