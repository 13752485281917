<div class="container-xxl export-header-qo">
  <app-content-header [contentHeader]="contentHeader"></app-content-header>
</div>

<app-document-papers
  [data]="passObj"
  [showValidDate]="false"
  docCodeColumn="taxInvoiceCode"
  [isShowPayment]="true"
  [isPaymentDataInDocument]="false"
></app-document-papers>
