<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <section id="knowledge-base-search">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="col-2 px-0 d-flex justify-content-start">
                                <a [routerLink]="['/cms/repove-tools/certification/file/create']"
                                    class="btn btn-primary">
                                    <i data-feather="plus" class="mr-1"></i>{{ "General.Create" | translate }}
                                </a>
                            </div>


                            <div class="col-2 px-0 d-flex justify-content-end">

                            </div>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4">
                                    <div class="scope-custom">
                                        <label for="first-name-vertical">{{
                                            "General.Search" | translate
                                            }}</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" #searchbar name="searchbar"
                                                placeholder="{{ 'General.Search' | translate }}" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-12 col-md-4">
                                    <div class="scope-custom">
                                        <label for="first-name-vertical">{{
                                            "General.Group" | translate
                                            }}</label>
                                        <select class="custom-select" [(ngModel)]="categoryId" #selectCategory>
                                            <option value="All" selected>
                                                {{ "General.All" | translate }}
                                            </option>
                                            <option [value]="category.id" *ngFor="let category of categoryList">
                                                {{ category.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-12 col-md-4">
                                    <div class="scope-custom">
                                        <label for="first-name-vertical">{{
                                            "General.Status" | translate
                                            }}</label>
                                        <select name="sorting" class="custom-select" #activeFil>
                                            <option value="all">{{ "General.All" | translate }}</option>
                                            <option value="active">
                                                {{ "Status.Published" | translate }}
                                            </option>
                                            <option value="inactive">
                                                {{ "Status.Unpublished" | translate }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body row">
                            <div class="col-12 px-0 d-flex justify-content-between">

                                <div class="col-12 px-0 d-flex justify-content-center">
                                    <button (click)="ResetSearch()" class="btn btn-secondary" [disabled]="isLoading"
                                        style="margin-right: 0.5rem">
                                        <i data-feather="refresh-ccw" class="mr-1"></i>{{ "General.Reset" | translate }}
                                    </button>

                                    <button (click)="Search()" [disabled]="isLoading" class="btn btn-primary"
                                        style="margin-left: 0.5rem">
                                        <i data-feather="search" class="mr-1"></i>{{ "General.Search" | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <section>
            <div class="card">
                <div class="card-body" style="overflow-x: auto">
                    <div class="d-flex justify-content-end col-12 p-0">
                        <button class="btn btn-primary mb-1" (click)="ExportXLSX()">
                            {{ "General.Export" | translate }}
                        </button>
                    </div>
                    <div>
                        <table datatable [dtOptions]="dtOptions" #contentTable class="table table-hover w-100">
                            <thead>
                                <tr class="text-center">
                                    <th class="text-center" width="8%">#</th>
                                    <th class="text-center" width="40%">
                                        {{ "ViewTable.Name" | translate }}
                                    </th>
                                    <th class="text-center" width="10%">
                                        {{ "ViewTable.Status" | translate }}
                                    </th>
                                    <th class="text-center" width="10%">
                                        {{ "ViewTable.UpdateDate" | translate }}
                                    </th>
                                    <th class="text-center" width="10%">
                                        {{ "ViewTable.CreateBy" | translate }}
                                    </th>
                                    <th class="text-center" width="10%">
                                        {{ "ViewTable.Actions" | translate }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody style="width: 100% !important">
                                <tr class="w-100" *ngIf="isLoading">
                                    <td class="text-center" *ngFor="let num of [].constructor(6)">
                                        <div class="skeleton-loading"></div>
                                    </td>
                                </tr>

                                <tr *ngIf="itemObjList.length < 1 && !isLoading">
                                    <td colspan="100%" class="text-center">
                                        <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
                                    </td>
                                </tr>

                                <tr *ngFor="let itemObj of itemObjList; let ind = index">
                                    <td class="text-center">
                                        {{ (page - 1) * pageSize + ind + 1 }}
                                    </td>
                                    <td class="text-truncate" style="max-width: 200px">
                                        <a [routerLink]="['/cms/repove-tools/certification/file/edit/' + itemObj.id]"
                                            class="d-flex text-truncate primary-color">

                                            <p class="my-auto">
                                                {{ keyName ? itemObj[keyName] : itemObj["name"] }}
                                            </p>
                                        </a>
                                    </td>
                                    <td class="text-center">
                                        <div class="form-group my-auto">
                                            <div ngbDropdown>
                                                <a ngbDropdownToggle href="javascript:void(0);"
                                                    class="d-flex justify-content-center text-secondary"
                                                    id="dropdownBrowserState" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false" [ngClass]="{
                                'text-success': itemObj.isActive,
                                'text-danger': !itemObj.isActive
                              }">
                                                    <div>
                                                        {{
                                                        (itemObj.isActive
                                                        ? "Status.Published"
                                                        : "Status.Unpublished"
                                                        ) | translate
                                                        }}
                                                    </div>
                                                    <i data-feather="chevron-down"
                                                        class="text-dark cursor-pointer ml-50 my-auto"></i>
                                                </a>
                                                <div ngbDropdownMenu class="dropdown-menu-left"
                                                    aria-labelledby="dropdownBrowserState">
                                                    <a ngbDropdownItem *ngIf="itemObj.isActive"
                                                        class="d-flex align-items-center"
                                                        (click)="openIsActiveModal(itemObj, false)">
                                                        <i data-feather="eye-off" class="mr-50"></i>
                                                        {{ "Status.Unpublish" | translate }}
                                                    </a>
                                                    <a ngbDropdownItem *ngIf="!itemObj.isActive"
                                                        class="d-flex align-items-center"
                                                        (click)="openIsActiveModal(itemObj, true)">
                                                        <i data-feather="eye" class="mr-50"></i>
                                                        {{ "Status.Publish" | translate }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span *ngIf="currentLang != 'th'">
                                            {{ itemObj.updateDate | date : "dd MMM YYYY HH:mm" }}
                                        </span>
                                        <span *ngIf="currentLang != 'en'">
                                            {{ itemObj.updateDate | thaidate : "DD MMM YYYY HH:mm" }} น.
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        {{ itemObj.createBy ? itemObj.createBy : "-" }}
                                        <br />
                                    </td>

                                    <td class="text-center">
                                        <div class="d-flex justify-content-center">
                                            <a class="btn btn-icon btn-icon rounded-circle btn-outline-warning mr-50"
                                                [routerLink]="['/cms/repove-tools/certification/file/edit/' + itemObj.id]"><i
                                                    data-feather="edit"></i></a>
                                            <a class="btn btn-icon btn-icon rounded-circle btn-outline-danger mr-50"
                                                [ngClass]="{
                              'd-none': itemObj.isDelete
                            }" (click)="openDeleteContentModal(itemObj)"><i data-feather="trash-2"
                                                    class="text-danger"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>