class Category {
  id: number;
  groupId: number;
  categoryCode: string;
  name: string;
  taxRate: number;
  mediaFiles: string[];
  isShowing: boolean;
  isActive: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
}

export default Category;
