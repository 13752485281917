import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormTemplateComponent } from 'app/main/components/form/form-template/form-template.component';
import { CmsFormConfig } from 'app/main/config/CMSFormConfig';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import CMSSignIn from 'app/main/model/CMSSignIn';
import { ApiService } from 'app/main/service/api.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  config: ICMSConfig =  {
    apiPath: 'SignIn',
    tableName: 'Content',
    pathUrl: '/cms/sign-in',
    model: new CMSSignIn(),
    isList: false,
    componentName: 'Sign In',
    formConfig: {
      signInPic1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Banner Image 1',
        description: 'Resolution 648px*785px',
        labelTranslate: 'Informative.SignIn.BannerImage.1',
        descriptionTranslate: 'Informative.SignIn.Description',
      },
      signInPic2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Banner Image 2',
        description: 'Resolution 648px*785px',
        labelTranslate: 'Informative.SignIn.BannerImage.2',
        descriptionTranslate: 'Informative.SignIn.Description',
      },
      signInPicMobile1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        labelTranslate: 'Informative.SignIn.BannerMobileImage.1',
        descriptionTranslate: 'Informative.SignIn.Description',
      },
      signInPicMobile2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Banner Mobile Image 2',
        description: 'Resolution 648px*785px',
        labelTranslate: 'Informative.SignIn.BannerMobileImage.2',
        descriptionTranslate: 'Informative.SignIn.Description',
      },
    },
  }

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
