import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ConsumerReviewComponent } from './consumer-review.component';
import { AuthGuard } from 'app/auth/helpers';
import { ConsumerReviewCreateComponent } from './consumer-review-create/consumer-review-create.component';

import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';
import { CmsListModule } from '../cms-list/cms-list.module';

const path: string = 'cms/consumer-review';
const routes: Routes = [
  {
    path: `${path}`,
    component: ConsumerReviewComponent,
    canActivate: [AuthGuard],
    data: { animation: 'consumer-review-manage' },
  },
  {
    path: `${path}/create`,
    component: ConsumerReviewCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'consumer-review-create' },
  },
  {
    path: `${path}/edit/:id`,
    component: ConsumerReviewCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'consumer-review-edit' },
  },
];

@NgModule({
  declarations: [ConsumerReviewComponent, ConsumerReviewCreateComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CmsFormpageModule,
    CmsListModule,
  ],
  exports: [ConsumerReviewComponent],
})
export class ConsumerReviewModule {}
