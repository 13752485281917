import {
  AfterViewInit,
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FlatpickrOptions } from 'ng2-flatpickr';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexStroke,
  ApexDataLabels,
  ApexXAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexPlotOptions,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexTheme,
  ApexNonAxisChartSeries,
  ApexLegend,
  ApexResponsive,
  ApexStates,
  ChartComponent,
} from 'ng-apexcharts';
import { ChartOptions } from '../executive-summary/executive-summary.component';
import { CoreConfigService } from '@core/services/config.service';
import Item from 'app/main/model/Item';
import { ApiService } from 'app/main/service/api.service';
import { List } from 'lodash';
import { CommonResponse } from 'app/main/model/CommonResponse';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';
import { color } from '@amcharts/amcharts5';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import moment from 'moment';

@Component({
  selector: 'app-revenue',
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RevenueComponent implements OnInit, AfterViewInit {
  contentHeader: ContentHeader;
  currentLang: string;

  dateFrom: Date[];
  dateTo: Date[];

  rndNumber: number[] = [];

  sumRevenue: number = 0;

  itemList: Item[] = [];

  seriesList: Item[] = [];

  selectedItem1: any;
  selectedItem2: any;
  selectedItem3: any;
  selectedItem4: any;
  selectedItem5: any;

  public DateRangeOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',

    // altInput: true,
    // mode: 'range',
  };

  @BlockUI() blockUI: NgBlockUI;

  @ViewChild('apexColumnChartRef') apexColumnChartRef;
  @ViewChild('selectItemOption') selectItemOption: NgSelectComponent;

  public apexColumnChart: Partial<ChartOptions>;

  chartColors = {
    column: {
      series1: '#191D88',
      series2: '#1450A3',
      series3: '#337CCF',
      series4: '#FFC436',
      series5: '#7752FE',
      bg: '#ffffff',
    },
    success: {
      shade_100: '#7eefc7',
      shade_200: '#06774f',
    },
    donut: {
      series1: '#ffe700',
      series2: '#00d4bd',
      series3: '#826bf8',
      series4: '#2b9bf4',
      series5: '#FFA1A1',
    },
    area: {
      series3: '#a4f8cd',
      series2: '#60f2ca',
      series1: '#2bdac7',
    },
  };

  months: { value: number; nameEN: string; nameTH: string }[] = [
    { value: 0, nameEN: 'January', nameTH: 'มกราคม' },
    { value: 1, nameEN: 'February', nameTH: 'กุมภาพันธ์' },
    { value: 2, nameEN: 'March', nameTH: 'มีนาคม' },
    { value: 3, nameEN: 'April', nameTH: 'เมษายน' },
    { value: 4, nameEN: 'May', nameTH: 'พฤษภาคม' },
    { value: 5, nameEN: 'June', nameTH: 'มิถุนายน' },
    { value: 6, nameEN: 'July', nameTH: 'กรกฎาคม' },
    { value: 7, nameEN: 'August', nameTH: 'สิงหาคม' },
    { value: 8, nameEN: 'September', nameTH: 'กันยายน' },
    { value: 9, nameEN: 'October', nameTH: 'ตุลาคม' },
    { value: 10, nameEN: 'November', nameTH: 'พฤศจิกายน' },
    { value: 11, nameEN: 'December', nameTH: 'ธันวาคม' },
  ];
  selectedStartMonth: string = '';
  selectedEndMonth: string = '';
  selectedStartYear: number;
  selectedEndYear: number;
  minYear: any;
  maxYear: any;
  yearList: any[] = [];
  selectedEndMonthOptions: any;
  selectedEndYearOptions: any;

  startDate: string;
  endDate: string;

  timeConvertObj: Date[] = [];

  currentDate: Date = new Date();
  default_startDate: string = moment(this.currentDate)
    .startOf('month')
    .subtract(11, 'months')
    .toISOString();
  default_endDate: string = moment(this.currentDate)
    .endOf('month')
    .toISOString();

  constructor(
    private _translateService: TranslateService,
    private _coreConfigService: CoreConfigService,
    private _apiService: ApiService,
    private _globalFuncService: GlobalFuncService
  ) {
    var self = this;

    this.currentLang = this._translateService.currentLang;
    this.apexColumnChart = {
      series: [],
      chart: {
        type: 'bar',
        height: 400,
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
      },
      plotOptions: {
        bar: {
          columnWidth: '15%',
          colors: {
            backgroundBarRadius: 10,
          },
        },
      },
      colors: [
        this.chartColors.column.series1,
        this.chartColors.column.series2,
        this.chartColors.column.series3,
        this.chartColors.column.series4,
        this.chartColors.column.series5,
      ],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: this.getDefaultXaxis(),
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return self._globalFuncService.FormatToMoney(value);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return self._globalFuncService.FormatToMoney(val) + ' Baht';
          },
        },
      },
    };

    this.getItemList();
    this.getDropdownYear();
  }
  getDropdownYear() {
    this._apiService
      .GetAllData(
        'Dashboard/ForSearch/DropDown/GetNewestDataAndOldest/Customer'
      )
      .subscribe((res) => {
        console.log(res.data.resultData);
        this.minYear = new Date(res.data.resultData[0].min);
        this.maxYear = new Date(res.data.resultData[0].max);
        console.log(this.minYear.getFullYear());
        console.log(this.maxYear.getFullYear());
        this.yearList = this.getRangeOfYears(
          this.minYear.getFullYear(),
          this.maxYear.getFullYear()
        );
        console.log(this.yearList);
      });
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Dashboard.RevenueData',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });
  }

  ngAfterViewInit(): void {
    this.blockUI.start();
    this._coreConfigService.getConfig().subscribe((config) => {
      // If Menu Collapsed Changes
      if (
        config.layout.menu.collapsed === true ||
        config.layout.menu.collapsed === false
      ) {
        setTimeout(() => {
          // Get Dynamic Width for Charts

          this.apexColumnChart.chart.width =
            this.apexColumnChartRef?.nativeElement.offsetWidth;
          this.blockUI.stop();
        }, 900);
      }
    });
  }

  getItemList(): Promise<void> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('item', {
          isDelete: false,
          verbose: false,
        })
        .subscribe((res: CommonResponse<Item>) => {
          this.itemList = res.data.resultData;
        });
    });
  }

  onChange(index: number, event: any): void {
    if (index == 1) {
      this.selectedItem1 = event;
    } else if (index == 2) {
      this.selectedItem2 = event;
    } else if (index == 3) {
      this.selectedItem3 = event;
    } else if (index == 4) {
      this.selectedItem4 = event;
    } else if (index == 5) {
      this.selectedItem5 = event;
    }
  }

  randomNumber(): void {
    for (let i = 0; i < 12; i++) {
      this.rndNumber.push(Math.floor(Math.random() * 100));
    }
  }

  logDate(): void {
    // let dateStartString = this.ngDate[0].toISOString();
    // let dateEndString = this.ngDate[1].toISOString();
  }

  resetSearch(): void {
    console.log(this.selectItemOption);

    this.selectItemOption.clearModel();

    this.selectedItem1 = '';
    this.selectedItem2 = '';
    this.selectedItem3 = '';
    this.selectedItem4 = '';
    this.selectedItem5 = '';

    this.selectedStartYear = null;
    this.selectedStartMonth = '';
    this.selectedEndYear = null;
    this.selectedEndMonth = '';
    this.selectedEndMonthOptions = [];
    this.selectedEndYearOptions = [];

    this.Search();
  }

  Search(): void {
    this.blockUI.start();
    this.startDate = '';
    this.endDate = '';
    let tempArray: ApexAxisChartSeries = [];
    let itemList: string[] = [];

    if (this.selectedStartYear) {
      this.startDate = moment({
        year: this.selectedStartYear,
        month: parseInt(this.selectedStartMonth),
      })
        .startOf('month')
        .toISOString();
    }

    console.log(this.selectedEndYear);

    if (this.selectedEndYear) {
      this.endDate = moment({
        year: this.selectedEndYear,
        month: parseInt(this.selectedEndMonth),
      })
        .endOf('month')
        .toISOString();
    }

    if (this.selectedItem1?.id) itemList.push(this.selectedItem1.id);
    if (this.selectedItem2?.id) itemList.push(this.selectedItem2.id);
    if (this.selectedItem3?.id) itemList.push(this.selectedItem3.id);
    if (this.selectedItem4?.id) itemList.push(this.selectedItem4.id);
    if (this.selectedItem5?.id) itemList.push(this.selectedItem5.id);

    console.log(itemList);

    this._apiService
      .get('Dashboard/RevenueData', {
        params: {
          itemList: itemList,
          DateFrom: this.startDate || this.default_startDate,
          DateTo: this.endDate || this.default_endDate,
        },
      })
      .subscribe((res: CommonResponse<RevenueData>) => {
        this.UpdateChart(res.data.resultData[0]);
        this.blockUI.stop();
      });

    this.apexColumnChart.series = tempArray;
  }

  getDefaultXaxis(): string[] {
    let currentDate: Date = new Date();
    let formattedCategories: string[] = [];

    for (let i = 0; i < 12; i++) {
      const date = new Date();
      date.setMonth(currentDate.getMonth() - i);
      const month = date.toLocaleString('en-us', { month: 'short' });
      const year = date.getFullYear();
      formattedCategories.unshift(`${month}/${year}`);
    }

    return formattedCategories;
  }

  UpdateChart(data: RevenueData): void {
    this.sumRevenue = data.revenueSum;

    let formattedCategories = data.categoriesDateTimeList.map((date) => {
      let result: string = '';
      let tempdate = new Date(date);

      result = `${tempdate.toLocaleString('en-US', {
        month: 'short',
      })}/${tempdate.getFullYear()}`;
      return result;
    });

    let formattedChartData = data.columnChartList.map((d) => {
      if (this.selectedItem1 && d.id == this.selectedItem1.id) {
        d.color = this.chartColors.column.series1;
      } else if (this.selectedItem2 && d.id == this.selectedItem2.id) {
        d.color = this.chartColors.column.series2;
      } else if (this.selectedItem3 && d.id == this.selectedItem3.id) {
        d.color = this.chartColors.column.series3;
      } else if (this.selectedItem4 && d.id == this.selectedItem4.id) {
        d.color = this.chartColors.column.series4;
      } else if (this.selectedItem5 && d.id == this.selectedItem5.id) {
        d.color = this.chartColors.column.series5;
      }
      return d;
    });

    this.apexColumnChart.series = formattedChartData;
    this.apexColumnChart.xaxis = {
      categories: formattedCategories,
    };
  }

  onStartMonthChange() {
    console.log(this.selectedStartMonth);

    this.selectedEndMonthOptions = this.generateEndMonthOptions(
      parseInt(this.selectedStartMonth)
    );
    this.selectedEndMonth = this.selectedStartMonth;
    console.log(this.selectedEndMonth);
  }

  onStartYearChange() {
    this.selectedEndYear = this.selectedStartYear;
    this.selectedEndYearOptions = this.generateEndYearOptions(
      this.selectedStartYear
    );
    this.selectedStartMonth = '0';
    this.selectedEndMonthOptions = this.generateEndMonthOptions(
      parseInt(this.selectedStartMonth)
    );
    this.selectedEndMonth = '0';
  }

  onMonthChange() {
    console.log('Selected month:', parseInt(this.selectedStartMonth));
    console.log('Selected month: End', this.selectedEndMonth);

    this.selectedEndMonthOptions = this.generateEndMonthOptions(
      parseInt(this.selectedStartMonth)
    );
  }

  onYearChange() {
    this.selectedEndYearOptions = this.generateEndYearOptions(
      this.selectedStartYear
    );
    // If the start year is the same as the selected end year,
    // then adjust the end month options to include only months after the start month
    console.log(this.selectedStartYear, this.selectedEndYear);
    // this.selectedEndMonthOptions = this.months;
    if (this.selectedStartYear === this.selectedEndYear) {
      this.selectedEndMonthOptions = this.generateEndMonthOptions(
        parseInt(this.selectedStartMonth)
      );
    } else if (this.selectedStartYear > this.selectedEndYear) {
      this.selectedEndMonth = null;
      this.selectedEndMonthOptions = this.months;
    } else {
      // If the start year is before the selected end year, enable all months
      this.selectedEndMonthOptions = this.months;
    }

    console.log('Selected year:', this.selectedStartYear);
    console.log('Selected year:', this.selectedEndYear);
  }

  generateEndMonthOptions(
    startMonth: number
  ): { value: number; nameEN: string; nameTH: string }[] {
    console.log(this.selectedStartYear, this.selectedEndYear);
    // If no start month selected, enable all months
    if (!startMonth || this.selectedStartYear < this.selectedEndYear) {
      console.log(1);

      return this.months;
    }
    console.log(2);

    // Otherwise, disable past months
    return this.months.filter((month) => month.value >= startMonth);
  }
  generateEndYearOptions(startYear: number): number[] {
    // If no start year selected, enable all years
    if (!startYear) {
      return this.yearList;
    }
    // Otherwise, disable past years
    return this.yearList.filter((year) => year >= startYear);
  }
  getRangeOfYears(startYear: number, endYear: number): number[] {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  }

  checkItemWasSelected(item: Item): boolean {
    return (
      item.id == this.selectedItem1?.id ||
      item.id == this.selectedItem2?.id ||
      item.id == this.selectedItem3?.id ||
      item.id == this.selectedItem4?.id ||
      item.id == this.selectedItem5?.id
    );
  }
}

export interface RevenueData {
  revenueSum: number;
  categoriesDateTimeList: string[];
  columnChartList: RevenueDataColumnChartList[];
}

export interface RevenueDataColumnChartList {
  id: string;
  name: string;
  data: number[];
  color?: string;
}
