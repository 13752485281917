import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-etctech',
  templateUrl: './etctech.component.html',
  styleUrls: ['./etctech.component.scss']
})
export class ETCTechComponent implements OnInit {

  config = {
    apiPath: 'EtcTech',  //?ชื่อ api
    pathUrl: 'cms/repove-tools/technology/hydroethosome/etc-tech',  //?path
    tableName: 'Content',
    model: new Object(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.RepoveTools.Technology.HydroethosomeEtcTech', 
    formConfig: {
      otherBasedImg1: {
        type: FormType.SingleImage,
        label: 'Other Based Image 1',
        description: 'Resolution 180px*180px',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedImage1',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedImageDescription',
        validator: [Validators.required],
      },
      otherBasedDetail1: {
        type: FormType.Text,
        isMultipleLang: true,
        label: 'Other Based Detail 1',
        description: 'Details of other bases below the image',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedDetail1',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedDetailDescription',
        validator: [Validators.required],
      },
      otherBasedImg2: {
        type: FormType.SingleImage,
        label: 'Other Based Image 2',
        description: 'Resolution 180px*180px',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedImage2',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedImageDescription',
        validator: [Validators.required],
      },
      otherBasedDetail2: {
        type: FormType.Text,
        isMultipleLang: true,
        label: 'Other Based Detail 2',
        description: 'Details of other bases below the image',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedDetail2',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedDetailDescription',
        validator: [Validators.required],
      },
      otherBasedImg3: {
        type: FormType.SingleImage,
        label: 'Other Based Image 3',
        description: 'Resolution 180px*180px',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedImage3',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedImageDescription',
        validator: [Validators.required],
      },
      otherBasedDetail3: {
        type: FormType.Text,
        isMultipleLang: true,
        label: 'Other Based Detail 3',
        description: 'Details of other bases below the image',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedDetail3',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedDetailDescription',
        validator: [Validators.required],
      },
      otherBasedImg4: {
        type: FormType.SingleImage,
        label: 'Other Based Image 4',
        description: 'Resolution 180px*180px',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedImage4',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedImageDescription',
        validator: [Validators.required],
      },
      otherBasedDetail4: {
        type: FormType.Text,
        isMultipleLang: true,
        label: 'Other Based Detail 4',
        description: 'Details of other bases below the image',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedDetail4',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedDetailDescription',
        validator: [Validators.required],
      },
      otherBasedImg5: {
        type: FormType.SingleImage,
        label: 'Other Based Image 5',
        description: 'Resolution 180px*180px',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedImage5',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedImageDescription',
        validator: [Validators.required],
      },
      otherBasedDetail5: {
        type: FormType.Text,
        isMultipleLang: true,
        label: 'Other Based Detail 5',
        description: 'Details of other bases below the image',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedDetail5',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedDetailDescription',
        validator: [Validators.required],
      },
      otherBasedImg6: {
        type: FormType.SingleImage,
        label: 'Other Based Image 6',
        description: 'Resolution 180px*180px',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedImage6',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedImageDescription',
        validator: [Validators.required],
      },
      otherBasedDetail6: {
        type: FormType.Text,
        isMultipleLang: true,
        label: 'Other Based Detail 6',
        description: 'Details of other bases below the image',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedDetail6',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.OtherBasedDetailDescription',
        validator: [Validators.required],
      },
    }
  };

  constructor() { }

  ngOnInit(): void {
  }
}
