import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class RoleSetService {
  componentName: string = 'RoleSet';
  apiPath: string = 'roleSet';
  pathUrl: string = '/admin/roleset';

  constructor() { }


}
