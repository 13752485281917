<div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">
        {{title}}
    </h5>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">
            <i data-feather="x" size="22"></i>
        </span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="col-12" style="margin-bottom: 0.5rem">
        <label class="form-label"> Please Select Branch </label>

        <span class="text-danger">&nbsp;*</span>
        <ng-select (change)="onBranchChange($event)">
          <ng-option *ngFor="let branch of branchList" [value]="branch.id">{{
            branch.name
            }}</ng-option>
        </ng-select>
      </div>
</div>
<div class="modal-footer justify-content-between">
    
    <a type="button" class="btn btn-primary m-0" (click)="confirmModal()" rippleEffect>
        {{ "Form.Submit" | translate }}
    </a>
</div>