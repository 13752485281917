<div class="content-wrapper container-xxl p-0">
  <app-content-header [contentHeader]="contentHeader"></app-content-header>
  <div class="content-body">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-2">
          <button *ngIf="isEditData == false" class="btn btn-primary" (click)="onaddData(0)">{{"General.Create" |
            translate}}</button>
          <button *ngIf="isEditData == false" class="btn btn-primary" (click)="export">Export</button>
        </div>
        <table class="bonus-table">

          <thead>
            <tr>
              <th class="text-center" colspan="8">{{ "BonusPlan.DownlineBonus" | translate }}</th>
            </tr>
            <tr class="header-column">


              <th rowspan="2" class="text-center col-2">Sale Interval</th>
              <th class="active-pv text-center col-4" colspan="3">Active Provider</th>
              <th class="master-pv text-center col-4" colspan="3">Master Provider</th>
              <th rowspan="2" class="action text-center col-3">{{ "General.Manage" | translate }}</th>
            </tr>
            <tr>


              <th class="active-pv text-center">%</th>
              <th class="active-pv text-center">SV</th>
              <th class="active-pv text-center">THB</th>
              <th class="master-pv text-center">%</th>
              <th class="master-pv text-center">SV</th>
              <th class="master-pv text-center">THB</th>

            </tr>
          </thead>

          <tbody>
            <tr *ngIf="isAddData">
              <td>
                <input
                  [ngClass]="{'invalid-control': form.get('saleInterval').invalid && form.get('saleInterval').touched}"
                  class="form-control  text-right" type="text" [formControl]="form.controls['saleInterval']" />
              </td>
              <td class="active-pv">
                <input class="form-control  text-right" type="text" [formControl]="form.controls['percentageAP']" />
              </td>
              <td class="active-pv">{{ calculateSv(form.value.saleInterval, form.value.percentageAP) | number:'1.0-0' }}
              </td>
              <td class="active-pv">{{ calculateThb(form.value.saleInterval, form.value.percentageAP, svRate) |
                number:'1.0-0' }}</td>
              <td class="master-pv">
                <input class="form-control  text-right" type="text" [formControl]="form.controls['percentageMP']" />
              </td>
              <td class="master-pv">{{ calculateSv(form.value.saleInterval, form.value.percentageMP) | number:'1.0-0' }}
              </td>
              <td class="master-pv">{{ calculateThb(form.value.saleInterval, form.value.percentageMP, svRate) |
                number:'1.0-0' }}</td>
              <td class="action">
                <a class="btn btn-icon btn-icon rounded-circle btn-outline-success mr-50" (click)="openAddDataModal()">
                  <i data-feather="save" class="text-success"></i>
                </a>
                <a class="btn btn-icon btn-icon rounded-circle btn-outline-warning mr-50" (click)="cancelEdit(i)">
                  <i data-feather="x-square" class="text-warning"></i>
                </a>
              </td>
            </tr>
            <tr *ngFor="let item of tableListData; let i = index ">
              <ng-container *ngIf="i > 0 ">
                <td class="text-center">
                  <!-- Use form controls for inline editing -->
                  <input
                    [ngClass]="{'invalid-control': form.get('saleInterval').invalid && form.get('saleInterval').touched}"
                    class="form-control  text-right" type="text" [formControl]="form.controls['saleInterval']"
                    *ngIf="isEditData== true && isAddData == false && i === selectedRowIndex" />
                  <div *ngIf="i !== selectedRowIndex && isEditData == false && isAddData == false">{{ item.saleInterval
                    |
                    number:'1.0-0'}}</div>
                  <div *ngIf="i !== selectedRowIndex && isEditData == true">{{ item.saleInterval | number:'1.0-0'}}
                  </div>
                  <div *ngIf="isAddData == true">{{ item.saleInterval | number:'1.0-0'}}</div>
                </td>

                <td class="active-pv text-center ">
                  <!-- Use form controls for inline editing -->
                  <input class="form-control  text-right" type="text" [formControl]="form.controls['percentageAP']"
                    *ngIf="isEditData== true && isAddData == false && i === selectedRowIndex" />
                  <div *ngIf="i !== selectedRowIndex && isEditData == false && isAddData == false">{{ item.percentageAP
                    |
                    number:'1.0-0'}}%</div>
                  <div *ngIf="i !== selectedRowIndex && isEditData == true">{{ item.percentageAP | number:'1.0-0'}}%
                  </div>
                  <div *ngIf="isAddData == true">{{ item.percentageAP | number:'1.0-0'}}%</div>
                </td>
                <td class="active-pv text-center">{{ calculateSv(item.saleInterval, item.percentageAP)| number:'1.0-0'}}
                </td>
                <td class="active-pv text-center">{{ calculateThb(item.saleInterval, item.percentageAP, svRate) |
                  number:'1.0-0'}}
                </td>

                <td class="master-pv text-center">
                  <!-- Use form controls for inline editing -->
                  <input class="form-control  text-right" type="text" [formControl]="form.controls['percentageMP']"
                    *ngIf="isEditData== true && isAddData == false && i === selectedRowIndex" />
                  <div *ngIf="i !== selectedRowIndex && isEditData == false && isAddData == false">{{ item.percentageMP
                    |
                    number:'1.0-0'}}%</div>
                  <div *ngIf="i !== selectedRowIndex && isEditData == true">{{ item.percentageMP | number:'1.0-0'}}%
                  </div>
                  <div *ngIf="isAddData == true">{{ item.percentageMP | number:'1.0-0'}}%</div>
                </td>
                <td class="master-pv text-center">{{ calculateSv(item.saleInterval, item.percentageMP) |
                  number:'1.0-0'}}
                </td>
                <td class="master-pv text-center">{{ calculateThb(item.saleInterval, item.percentageMP, svRate)|
                  number:'1.0-0' }}
                </td>


                <!-- Repeat the above structure for other columns -->

                <td class=" align-items-center justify-content-between" style="text-align: center;">


                  <span style="display: inline-block; margin-right: 5px;">
                    <a class="btn btn-icon btn-icon rounded-circle btn-outline-warning"
                      *ngIf="isAddData == false && isEditData == false" (click)="editData(i)">
                      <i data-feather="edit" class="text-warning"></i>
                    </a>
                  </span>

                  <span style="display: inline-block; margin-right: 5px;">
                    <a class="btn btn-icon btn-icon rounded-circle btn-outline-success"
                      *ngIf="i === selectedRowIndex && isEditData == true" (click)="openUpdateDataModal(i)">
                      <i data-feather="save" class="text-success"></i>
                    </a>
                  </span>

                  <span style="display: inline-block; margin-right: 5px;">
                    <a class="btn btn-icon btn-icon rounded-circle btn-outline-warning"
                      *ngIf="i === selectedRowIndex && isEditData == true" (click)="cancelEdit(i)">
                      <i data-feather="x-square" class="text-warning"></i>
                    </a>
                  </span>

                  <span style="display: inline-block; margin-right: 5px;">
                    <a class="btn btn-icon btn-icon rounded-circle btn-outline-danger"
                      *ngIf="isEditData == false && isAddData == false" (click)="openDeleteDataModal(i,value = true)">
                      <i data-feather="trash-2" class="text-danger"></i>
                    </a>
                  </span>

                  <span style="display: inline-block; margin-left: 10px;">
                    <div ngbDropdown *ngIf="isEditData == false && isAddData == false">
                      <a ngbDropdownToggle href="javascript:void(0);"
                        class="d-flex justify-content-center text-secondary" id="dropdownBrowserState"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="{
                      'text-success': tableListData[i].isActive === true,
                      'text-danger': tableListData[i].isActive === false
                    }">
                        <div>
                          {{
                          (tableListData[i].isActive == true
                          ? "Status.Published"
                          : "Status.Unpublished"
                          ) | translate
                          }}
                        </div>
                        <i data-feather="chevron-down" class="text-dark cursor-pointer ml-50 my-auto"></i>
                      </a>
                      <div ngbDropdownMenu class="dropdown-menu-left" aria-labelledby="dropdownBrowserState">
                        <a ngbDropdownItem *ngIf="tableListData[i].isActive == true" class="d-flex align-items-center"
                          (click)="openSetActiveModal(i, false)">
                          <i data-feather="eye-off" class="mr-50"></i>
                          {{ "Status.Unpublish" | translate }}
                        </a>
                        <a ngbDropdownItem *ngIf="tableListData[i].isActive == false" class="d-flex align-items-center"
                          (click)="openSetActiveModal(i, true)">
                          <i data-feather="eye" class="mr-50"></i>
                          {{ "Status.Publish" | translate }}
                        </a>
                      </div>

                    </div>
                  </span>





                </td>
              </ng-container>


            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card">
      <div class="card-header">Downline Bonus History</div>
      <div class="card-body">
        <div class="hisotry-table">
          <table class="table" datatable [dtOptions]="dtOptions">
            <thead>
              <tr>
                <th class="text-center" width="5%">#</th>
                <th>Status</th>
                <th class="text-center" width="10%">Sale Interval</th>
                <th class="text-center" width="10%">FROM AP</th>
                <th class="text-center" width="10%">TO AP</th>
                <th class="text-center" width="10%">FROM MP</th>
                <th class="text-center" width="10%">TO MP</th>
                <th class="text-center">Updated By</th>
                <th class="text-center">Updated Date</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let log of downlineLogList; let i = index">
                <td class="text-center">{{i+1}}</td>
                <td>{{"BonusPlan.Status." + log.status | translate}}</td>
                <td class="text-center">{{log.saleInterval | number:'1.0-0' }}</td>
                <td class="text-center active-pv">{{log.fromAPPercentage}}</td>
                <td class="text-center active-pv">{{log.toAPPercentage}}</td>
                <td class="text-center master-pv">{{log.fromMPPercentage}}</td>
                <td class="text-center master-pv">{{log.toMPPercentage}}</td>
                <td class="text-center">{{log.updateBy}}</td>
                <td class="text-center">
                  <div *ngIf="currentLang != 'th'">
                    {{ log.updateDate | date : "dd MMM YYYY HH:mm" }}
                  </div>
                  <div *ngIf="currentLang != 'en'">
                    {{ log.updateDate | thaidate : "DD MMM YYYY HH:mm" }}
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>