import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { SignInComponent } from './sign-in.component';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';

const path: string = 'cms/sign-in';
const routes: Routes = [
  {
    path: path,
    component: SignInComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-sign-in' },
  },
];

@NgModule({
  declarations: [SignInComponent],
  imports: [CommonModule, RouterModule.forChild(routes), CmsFormpageModule],
  exports: [SignInComponent],
})
export class SignInModule {}
