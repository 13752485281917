import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChartOptions } from '../executive-summary.component';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CoreConfigService } from '@core/services/config.service';
import { ApiService } from 'app/main/service/api.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { CommonResponse } from 'app/main/model/CommonResponse';
import moment from 'moment';
import { FilesApiService } from 'app/main/service/files-api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-mp-revenue-chart',
  templateUrl: './mp-revenue-chart.component.html',
  styleUrls: ['./mp-revenue-chart.component.scss'],
})
export class MpRevenueChartComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  @ViewChild('MPApexColumnChartRef') MPApexColumnChartRef: any;
  public MPApexColumnChart: Partial<ChartOptions>;
  @ViewChild('RevenueApexColumnChartRef') RevenueApexColumnChartRef: any;
  public RevenueApexColumnChart: Partial<ChartOptions>;

  @BlockUI() blockUI: NgBlockUI;

  // public DateRangeOptions: FlatpickrOptions = {
  //   altInput: true,
  //   mode: 'range',
  // };
  startDate: string;
  endDate: string;
  yearList: any[] = [];
  selectedStartMonth: string = '';
  selectedEndMonth: string = '';
  selectedStartYear: number;
  selectedEndYear: number;
  minYear: any;
  maxYear: any;

  selectedEndMonthOptions: any;
  selectedEndYearOptions: any;

  fileNameFromDate: string = '';
  fileNameToDate: string = '';

  months: { value: number; name: string }[] = [
    { value: 0, name: 'January' },
    { value: 1, name: 'February' },
    { value: 2, name: 'March' },
    { value: 3, name: 'April' },
    { value: 4, name: 'May' },
    { value: 5, name: 'June' },
    { value: 6, name: 'July' },
    { value: 7, name: 'August' },
    { value: 8, name: 'September' },
    { value: 9, name: 'October' },
    { value: 10, name: 'November' },
    { value: 11, name: 'December' },
  ];

  chartColors = {
    column: {
      series1: '#FCD19C',
      series2: '#FFC7C2',
    },
    success: {
      shade_100: '#7eefc7',
      shade_200: '#06774f',
    },
    donut: {
      series1: '#ffe700',
      series2: '#00d4bd',
      series3: '#826bf8',
      series4: '#2b9bf4',
      series5: '#FFA1A1',
    },
    area: {
      series3: '#a4f8cd',
      series2: '#60f2ca',
      series1: '#2bdac7',
    },
  };

  isInit: boolean = false;

  currentDate: Date = new Date();
  default_startDate: string = moment(this.currentDate)
    .startOf('month')
    .subtract(11, 'months')
    .toISOString();
  default_endDate: string = moment(this.currentDate)
    .endOf('month')
    .toISOString();

  constructor(
    private apiService: ApiService,
    private _globalFuncService: GlobalFuncService,
    private fileService: FilesApiService
  ) {
    this.getChartData(this.default_startDate, this.default_endDate);
    this.getDropdownYear();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.MPApexColumnChart = null;
    this.RevenueApexColumnChart = null;
  }

  setDefaultChart() {
    var self = this;
    this.MPApexColumnChart = {
      series: [
        {
          name: 'New MP Member',
          group: 'MP Member',
          color: '#0134a1',
          data: [305, 220, 335, 420, 550],
        },
        {
          name: 'Existing MP Member',
          group: 'MP Member',
          color: '#088395',
          data: [280, 280, 320, 410, 490],
        },
      ],

      chart: {
        group: undefined,
        type: 'bar',
        height: 400,
        stacked: true,

        toolbar: {
          show: false,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
      },
      plotOptions: {
        bar: {
          columnWidth: '30px',
          colors: {
            // backgroundBarColors: [
            //   this.chartColors.column.bg,
            //   this.chartColors.column.bg,
            //   this.chartColors.column.bg,
            //   this.chartColors.column.bg,
            //   this.chartColors.column.bg,
            // ],
            backgroundBarRadius: 10,
          },
        },
      },
      colors: [
        this.chartColors.column.series1,
        this.chartColors.column.series2,
      ],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        title: {
          text: 'Month/Year',
        },
        categories: [
          'Jan/2023',
          'Feb/2023',
          'Mar/2023',
          'Apr/2023',
          'May/2023',
          'Jun/2023',
          'Jul/2023',
          'Aug/2023',
          'Sep/2023',
          'Oct/2023',
          'Nov/2023',
          'Dec/2023',
        ],
      },
      yaxis: {
        title: {
          text: 'Members',
        },
        labels: {
          formatter: function (val) {
            return self._globalFuncService.FormatToMoney(val, false, true);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (
              self._globalFuncService.FormatToMoney(val, false, true) +
              (Math.round(val) > 1 ? ' Members' : ' Member')
            );
          },
        },
      },
      noData: {
        text: 'No data available.',
        align: 'center',
        verticalAlign: 'middle',
        offsetY: -20,
        offsetX: 20,
      },
    };
    this.RevenueApexColumnChart = {
      series: [
        {
          name: 'Revenue',
          group: 'Revenue',
          color: '#088395',
          data: [28000, 28000, 32000, 41000, 49000],
        },
      ],

      chart: {
        group: undefined,
        type: 'bar',
        height: 400,
        stacked: true,

        toolbar: {
          show: false,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
      },
      plotOptions: {
        bar: {
          columnWidth: '30px',
          colors: {
            // backgroundBarColors: [
            //   this.chartColors.column.bg,
            //   this.chartColors.column.bg,
            //   this.chartColors.column.bg,
            //   this.chartColors.column.bg,
            //   this.chartColors.column.bg,
            // ],
            backgroundBarRadius: 10,
          },
        },
      },
      colors: [
        this.chartColors.column.series1,
        this.chartColors.column.series2,
      ],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        title: {
          text: 'Month/Year',
        },
        categories: [
          'Jan/2023',
          'Feb/2023',
          'Mar/2023',
          'Apr/2023',
          'May/2023',
          'Jun/2023',
          'Jul/2023',
          'Aug/2023',
          'Sep/2023',
          'Oct/2023',
          'Nov/2023',
          'Dec/2023',
        ],
      },
      yaxis: {
        title: {
          text: 'Baht',
        },
        labels: {
          formatter: function (val) {
            return self._globalFuncService.FormatToMoney(val);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return self._globalFuncService.FormatToMoney(val) + ' Baht';
          },
        },
      },
      noData: {
        text: 'No data available.',
        align: 'center',
        verticalAlign: 'middle',
        offsetY: -20,
        offsetX: 20,
      },
    };
  }

  onStartMonthChange() {
    console.log(this.selectedStartMonth);

    this.selectedEndMonthOptions = this.generateEndMonthOptions(
      parseInt(this.selectedStartMonth)
    );
    this.selectedEndMonth = this.selectedStartMonth;
    console.log(this.selectedEndMonth);
  }
  onStartYearChange() {
    this.selectedEndYear = this.selectedStartYear;
    this.selectedEndYearOptions = this.generateEndYearOptions(
      this.selectedStartYear
    );
    this.selectedStartMonth = '0';
    this.selectedEndMonthOptions = this.generateEndMonthOptions(
      parseInt(this.selectedStartMonth)
    );
    this.selectedEndMonth = '0';
  }
  onMonthChange() {
    console.log('Selected month:', parseInt(this.selectedStartMonth));
    console.log('Selected month: End', this.selectedEndMonth);

    this.selectedEndMonthOptions = this.generateEndMonthOptions(
      parseInt(this.selectedStartMonth)
    );
  }
  onYearChange() {
    this.selectedEndYearOptions = this.generateEndYearOptions(
      this.selectedStartYear
    );
    // If the start year is the same as the selected end year,
    // then adjust the end month options to include only months after the start month
    console.log(this.selectedStartYear, this.selectedEndYear);
    // this.selectedEndMonthOptions = this.months;
    if (this.selectedStartYear === this.selectedEndYear) {
      this.selectedEndMonthOptions = this.generateEndMonthOptions(
        parseInt(this.selectedStartMonth)
      );
    } else if (this.selectedStartYear > this.selectedEndYear) {
      this.selectedEndMonth = null;
      this.selectedEndMonthOptions = this.months;
    } else {
      // If the start year is before the selected end year, enable all months
      this.selectedEndMonthOptions = this.months;
    }

    console.log('Selected year:', this.selectedStartYear);
    console.log('Selected year:', this.selectedEndYear);
  }

  getDropdownYear() {
    this.apiService
      .GetAllData(
        'Dashboard/ForSearch/DropDown/GetNewestDataAndOldest/Customer'
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res.data.resultData);
        this.minYear = new Date(res.data.resultData[0].min);
        this.maxYear = new Date(res.data.resultData[0].max);
        console.log(this.minYear.getFullYear());
        console.log(this.maxYear.getFullYear());
        this.yearList = this.getRangeOfYears(
          this.minYear.getFullYear(),
          this.maxYear.getFullYear() + 20
        );
        console.log(this.yearList);
      });
  }
  generateEndMonthOptions(
    startMonth: number
  ): { value: number; name: string }[] {
    console.log(this.selectedStartYear, this.selectedEndYear);
    // If no start month selected, enable all months
    if (!startMonth || this.selectedStartYear < this.selectedEndYear) {
      console.log(1);

      return this.months;
    }
    console.log(2);

    // Otherwise, disable past months
    return this.months.filter((month) => month.value >= startMonth);
  }
  generateEndYearOptions(startYear: number): number[] {
    // If no start year selected, enable all years
    if (!startYear) {
      return this.yearList;
    }
    // Otherwise, disable past years
    return this.yearList.filter((year) => year >= startYear);
  }
  getRangeOfYears(startYear: number, endYear: number): number[] {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  }

  submitDate() {
    this.startDate = moment({
      year: this.selectedStartYear,
      month: parseInt(this.selectedStartMonth),
    })
      .startOf('month')
      .toISOString();
    this.endDate = moment({
      year: this.selectedEndYear,
      month: parseInt(this.selectedEndMonth),
    })
      .endOf('month')
      .toISOString();

    this.getChartData(this.startDate, this.endDate);
  }

  resetSearchCriteria() {
    // Reset all search criteria
    this.selectedStartMonth = '';
    this.selectedEndMonth = '';
    this.selectedStartYear = null;
    this.selectedEndYear = null;
    this.selectedEndMonthOptions = [];
    this.selectedEndYearOptions = [];
    this.getChartData();
  }

  getChartData(startDate?: string, endDate?: string): Promise<void> {
    this.blockUI.start();
    startDate = startDate || '';
    endDate = endDate || '';
    return new Promise((resolve, rejects) => {
      this.apiService
        .GetAllData('Dashboard/ExecutiveSummary/MPVSRevenue', {
          dateFrom: startDate,
          dateTo: endDate,
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<any>) => {
            this.setDefaultChart();

            this.updateChartData(res.data.resultData[0]);
            this.blockUI.stop();
          },
          (err) => {
            this.blockUI.stop();
          }
        );
    });
  }

  checkDataAvaliable(numArr: number[]): boolean {
    return !numArr.every((value) => value === 0);
  }

  updateChartData(data) {
    // let defaultData: number[] = [];
    // data.categoriesDateTimeList.forEach(() => {
    //   defaultData.push(0);
    // });

    let formattedCategories = data.categoriesDateTimeList.map((date) => {
      let result: string = '';
      let tempdate = new Date(date);

      result = `${tempdate.toLocaleString('en-US', {
        month: 'short',
      })}/${tempdate.getFullYear()}`;
      return result;
    });

    //prepare file name for export excel
    let fromDate = new Date(data.categoriesDateTimeList[0]);
    let toDate = new Date(
      data.categoriesDateTimeList[data.categoriesDateTimeList.length - 1]
    );
    this.fileNameFromDate = `${fromDate.toLocaleString('en-US', {
      month: 'short',
    })}/${fromDate.getFullYear()}`;
    this.fileNameToDate = `${toDate.toLocaleString('en-US', {
      month: 'short',
    })}/${toDate.getFullYear()}`;

    console.log(formattedCategories);

    let formattedMPChartData = [
      {
        name: 'New MP Member',
        group: 'MP Member',
        color: '#0134a1',
        data: this.checkDataAvaliable(data.columnChart.newMP)
          ? data.columnChart.newMP
          : [],
      },
      {
        name: 'Existing MP Member',
        group: 'MP Member',
        color: '#088395',
        data: this.checkDataAvaliable(data.columnChart.totalMP)
          ? data.columnChart.totalMP
          : [],
      },
    ];

    let formattedRevenueChartData = [
      {
        name: 'Revenue',
        group: 'Revenue',
        color: '#088395',
        data: this.checkDataAvaliable(data.columnChart.revenue)
          ? data.columnChart.revenue
          : [],
      },
    ];

    this.MPApexColumnChart.series = formattedMPChartData;
    this.RevenueApexColumnChart.series = formattedRevenueChartData;

    this.MPApexColumnChart.xaxis.categories = formattedCategories;

    this.RevenueApexColumnChart.xaxis.categories = formattedCategories;
  }

  DrawChart() {
    this.MPApexColumnChart.chart.width =
      this.MPApexColumnChartRef?.nativeElement.offsetWidth;

    window.dispatchEvent(new Event('resize'));
    this.isInit = true;
  }

  exportToExcel() {
    let dateFrom = this.startDate ? this.startDate : this.default_startDate;
    let dateTo = this.endDate ? this.endDate : this.default_endDate;
    let exportDate = new Date().toISOString();

    let fileName: string = `MP VS Revenue Report ${this.fileNameFromDate}-${this.fileNameToDate}`;

    let param = {
      DateFrom: dateFrom,
      DateTo: dateTo,
      ExportDate: exportDate,
    };

    this.fileService.getExcelReport('Excel/ExportMPVSRevenue', fileName, param);
  }
}
