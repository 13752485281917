import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';
import { District, Province, Region, SubDistrict } from './master-addr-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterAddrFormModalComponent } from './master-addr-form-modal/master-addr-form-modal.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'app/main/service/api.service';
import { CommonResponse } from 'app/main/model/CommonResponse';
import { TranslateService } from '@ngx-translate/core';

export enum ViewMasterAddrMode {
  Province = 0,
  District = 1,
  SubDistrict = 2,
}

@Component({
  selector: 'app-master-addr-crud',
  templateUrl: './master-addr-crud.component.html',
  styleUrls: ['./master-addr-crud.component.scss'],
})
export class MasterAddrCrudComponent implements OnInit {
  contentHeader: ContentHeader;

  og_provinceList: Province[] = [];
  provinceList: Province[] = [];
  districtList: District[] = [];
  subDistrictList: SubDistrict[] = [];

  selectedRegion: number = 0;
  selectedProvince: Province;
  selectedDistrict: District;

  isProvinceInvalid: boolean = false;
  isDistrictInvalid: boolean = false;

  viewMode: number = ViewMasterAddrMode.Province;
  viewModeEnum = ViewMasterAddrMode;

  @BlockUI() blockUI: NgBlockUI;

  currentLang: string;

  constructor(
    private _apiService: ApiService,
    private _modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private _translateService: TranslateService
  ) {
    this.getProvince().then((res) => {
      this.og_provinceList = res.data.resultData;
      this.provinceList = res.data.resultData;
    });
  }

  ngOnInit(): void {
    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.contentHeader = {
      headerTitle: 'จัดการข้อมูลที่อยู่',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          // {
          //   name: 'General.Home',
          //   isLink: true,
          //   link: '/',
          // },
        ],
      },
    };
  }

  changeTabs(mode: number) {
    this.viewMode = mode;
    this.resetSearch();
  }

  async onSearch(): Promise<void> {
    if (!this.checkValidateSearch()) return;

    console.log(this.checkValidateSearch());

    this.blockUI.start();
    switch (this.viewMode) {
      case this.viewModeEnum.Province:
        if (this.selectedRegion == 0) {
          this.provinceList = [...this.og_provinceList];
        } else {
          this.provinceList = [
            ...this.og_provinceList.filter(
              (p) => p.region == this.selectedRegion
            ),
          ];
        }
        this.blockUI.stop();
        break;
      case this.viewModeEnum.District:
        await this.getDistrict(this.selectedProvince.id).then((res) => {
          this.districtList = res.data.resultData;
        });
        this.blockUI.stop();
        break;
      case this.viewModeEnum.SubDistrict:
        await this.getSubDistrict(this.selectedDistrict.id).then((res) => {
          this.subDistrictList = res.data.resultData;
        });
        this.blockUI.stop();
        break;

      default:
        this.blockUI.stop();
        break;
    }
  }

  checkValidateSearch(): boolean {
    this.isProvinceInvalid = false;
    this.isDistrictInvalid = false;

    switch (this.viewMode) {
      case this.viewModeEnum.District:
        if (!this.selectedProvince) this.isProvinceInvalid = true;
        return !this.isProvinceInvalid;

      case this.viewModeEnum.SubDistrict:
        if (!this.selectedProvince) this.isProvinceInvalid = true;
        if (!this.selectedDistrict) this.isDistrictInvalid = true;

        return !(this.isProvinceInvalid || this.isDistrictInvalid);

      default:
        return true;
    }
  }

  onReset() {
    this.resetSearch();
  }

  resetSearch() {
    this.selectedRegion = 0;
    this.selectedProvince = null;
    this.selectedDistrict = null;

    this.isProvinceInvalid = false;
    this.isDistrictInvalid = false;

    this.districtList = [];
    this.subDistrictList = [];

    this.provinceList = [...this.og_provinceList];
  }

  onSelectRegion() {
    this.selectedProvince = null;
    this.selectedDistrict = null;

    if (this.viewMode != this.viewModeEnum.Province) {
      this.provinceList = [
        ...this.og_provinceList.filter((p) => p.region == this.selectedRegion),
      ];
    }
    this.cdr.detectChanges();
  }

  onSelectProvince() {
    this.selectedDistrict = null;

    if (this.viewMode == this.viewModeEnum.SubDistrict) {
      this.getDistrict(this.selectedProvince.id).then((res) => {
        this.districtList = res.data.resultData;
      });
    }
  }

  getProvince(): Promise<CommonResponse<Province>> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterProvince`, {
          SortPath: 'provinceName',
          Direction: 0,
        })
        .subscribe(
          (res: CommonResponse<Province>) => {
            resolve(res);
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  getDistrict(id: string): Promise<CommonResponse<District>> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterDistrict`, {
          ProvinceId: id,
          SortPath: 'districtName',
          Direction: 0,
        })
        .subscribe(
          (res: CommonResponse<District>) => {
            resolve(res);
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  getSubDistrict(id: string): Promise<CommonResponse<SubDistrict>> {
    // this.registerForm.get('c_subDistrict').setValue(null);
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterSubDistrict`, {
          DistrictId: id,
          SortPath: 'subDistrictName',
          Direction: 0,
        })
        .subscribe(
          (res: CommonResponse<SubDistrict>) => {
            resolve(res);
          },
          (err) => {
            rejects();
          }
        );
      return true;
    });
  }

  openFormModal(
    isEditing: boolean,
    data: Province | District | SubDistrict
  ): void {
    const modalRef = this._modalService.open(MasterAddrFormModalComponent, {
      centered: true,
    });

    modalRef.componentInstance.viewMode = this.viewMode;
    modalRef.componentInstance.isEditing = isEditing;
    modalRef.componentInstance.data = data;
    modalRef.componentInstance.og_provinceList = this.og_provinceList;
    modalRef.componentInstance.provinceList = this.provinceList;
    modalRef.componentInstance.districtList = this.districtList;

    modalRef.componentInstance.selectedRegion = this.selectedRegion;
    modalRef.componentInstance.selectedProvince = this.selectedProvince;
    modalRef.componentInstance.selectedDistrict = this.selectedDistrict;
  }

  isProvinceMode(): boolean {
    return this.viewMode == ViewMasterAddrMode.Province;
  }

  isDistrictMode(): boolean {
    return this.viewMode == ViewMasterAddrMode.District;
  }

  isSubDistrictMode(): boolean {
    return this.viewMode == ViewMasterAddrMode.SubDistrict;
  }

  getRegionLabel(region: number): string {
    switch (region) {
      case Region.North:
        return 'Region.Northern';
      case Region.Central:
        return 'Region.Central';
      case Region.Northeast:
        return 'Region.Northeastern';
      case Region.West:
        return 'Region.Western';
      case Region.East:
        return 'Region.Eastern';
      case Region.South:
        return 'Region.Southern';
      default:
        return '-';
    }
  }
}
