import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClassService {
  componentName: string = 'Class';
  apiUrl: string = 'class';
  pathUrl: string = '/item-manage/class';

  parentComponentName: string = 'SubCategory';
  parentApiUrl: string = 'subcategory';
  parentPathUrl: string = '/item-manage/subcategory';

  childComponentName: string = 'SubClass';
  childApiUrl: string = 'subclass';
  childPathUrl: string = '/item-manage/subclass';

  itemComponentName: string = 'Item';
  itemApiUrl: string = 'item';
  itemPathUrl: string = '/item-manage/item';


  constructor() { }
}
