import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { Period } from 'app/main/model/Period';
import { ApiService } from 'app/main/service/api.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sale-period-manage',
  templateUrl: './sale-period-manage.component.html',
  styleUrls: ['./sale-period-manage.component.scss'],
})
export class SalePeriodManageComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();
  contentHeader: ContentHeader;
  currentLang: string;

  isEditing: boolean = false;
  isLoadingPeriodHistory: boolean = false;
  isLoadingSettingsHistory: boolean = false;

  periodHistoryList: any[] = [];
  settingsHistoryList: any[] = [];

  lastPeriod: Period;
  periodSettings: number = 0;

  periodMode: string[] = ['EndOfMonth', 'HalfOfMonth'];

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};

  periodHistory_dtOptions: any = {};
  settingsHistory_dtOptions: any = {};

  dtTrigger: Subject<any> = new Subject<any>();

  @BlockUI() blockUI: NgBlockUI;

  pageSize: number = 10;
  periodHistoryPage: number = 1;
  settingsHistoryPage: number = 1;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private componentService: ComponentsService,
    private globalFuncService: GlobalFuncService,
    private _translateService: TranslateService
  ) {
    this.refreshData();
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Sale Period',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.periodHistory_dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      page: this.periodHistoryPage,
      pageLength: this.pageSize,
      
      order: [[1, 'desc']],
      ordering: true,
      lengthChange: false,
    };

    this.settingsHistory_dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      page: this.settingsHistoryPage,
      pageLength: this.pageSize,
      order: [[1, 'desc']],
      ordering: true,
      lengthChange: false,
    };
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  editMode() {
    this.isEditing = !this.isEditing;
  }

  getLastPeriod(): Promise<void> {
    return new Promise((resolve, rejects) => {
      this.apiService
        .GetAllData('mlm/SalePeriod', { direction: 1, page: 1, pageLength: 1 })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          this.lastPeriod = res.data.resultData[0];
        });
    });
  }

  getCurrentPeriodSettings(): Promise<void> {
    return new Promise((resolve, rejects) => {
      this.apiService
        .GetAllData('mlm/Argument', { name: 'PeriodSetting' })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          this.periodSettings = res.data.resultData[0].PeriodSetting;
        });
    });
  }

  getPeriodHistoryList(): Promise<void> {
    this.isLoadingPeriodHistory = true;
    return new Promise((resolve, rejects) => {
      this.apiService
        .GetAllData('mlm/SalePeriod', { direction: 1 })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.isLoadingPeriodHistory = false;
            this.periodHistoryList = res.data.resultData;
            resolve();
          },
          (err) => {
            this.isLoadingPeriodHistory = false;
            rejects();
          }
        );
    });
  }

  getSettingsHistoryList(): Promise<void> {
    this.isLoadingSettingsHistory = true;
    return new Promise((resolve, rejects) => {
      this.apiService
        .GetAllData('mlm/ArgumentLog', { name: 'PeriodSetting' })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.isLoadingSettingsHistory = false;
            this.settingsHistoryList = res.data.resultData;
            resolve();
          },
          (err) => {
            this.isLoadingSettingsHistory = false;
            rejects();
          }
        );
    });
  }

  refreshData() {
    this.getLastPeriod();
    this.getCurrentPeriodSettings();
    this.getSettingsHistoryList();
    this.getPeriodHistoryList();
  }

  onSubmit() {
    const modalRef = this.modalService.open(ModalComponent, {
      centered: true,
    });
    let modeText = '';

    if (this.periodSettings == 0) {
      modeText = 'ตัดรอบทุกสิ้นเดือน';
    } else if (this.periodSettings == 1) {
      modeText = 'ตัดรอบทุกวันที่ 15';
    }

    modalRef.componentInstance.title = 'ยืนยันการตั้งค่า';
    modalRef.componentInstance.detail = `คุณยืนยันที่จะให้ Sale Period รอบถัดไปใช้การตั้งค่าแบบ ${modeText} หรือไม่`;
    modalRef.componentInstance.callBackFunc.subscribe(() => {
      this.SaveSettings().then(() => {
        this.refreshData();
      });
    });
  }

  SaveSettings(): Promise<void> {
    return new Promise((resolve, rejects) => {
      this.blockUI.start();
      this.apiService
        .UpdateDataById('mlm/Argument', 'PeriodSetting', {
          value: this.periodSettings,
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            resolve();
            this.blockUI.stop();
            this.componentService.SuccessSwal();
          },
          (err) => {
            rejects();
            this.blockUI.stop();
            this.componentService.ErrorSwal();
          }
        );
    });
  }

  getSalePeriodModeLabel(mode: number) {
    return `MLM.Period.${this.periodMode[mode]}`;
  }
}
