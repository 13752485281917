import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-tax-invoice',
  templateUrl: './add-tax-invoice.component.html',
  styleUrls: ['./add-tax-invoice.component.scss'],
})
export class AddTaxInvoiceComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();

  @Input() TaxIv: string;
  @Input() TaxIvDate: any;
  @Input() DocID: string;
  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();

  addTaxForm: FormGroup;
  openEdit: Boolean;
  conFirDelete: Boolean;
  addTaxInvoice: Boolean;

  removedTax: boolean;

  currentDate: Date = new Date();
  nextWeekDate: Date = new Date();
  createDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: this.currentDate,
  };

  expiredDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: new Date(
      this.nextWeekDate.setDate(this.currentDate.getDate() + 7)
    ),
  };
  constructor(
    private _formBuilder: FormBuilder,
    private _documentApiService: DocumentApiService
  ) {}

  ngOnInit(): void {
    this.addTaxForm = this._formBuilder.group({
      taxInvoiceNo: [null, [Validators.maxLength(50)]],
      taxInvoiceDate: [null, []],
    });
  }

  get f() {
    return this.addTaxForm.controls;
  }

  onDateFromChange(event) {
    let dateform = this.addTaxForm.controls.documentCreateDate.value;
    this.expiredDateOptions.minDate = new Date(dateform);
  }
  VoidTaxInvoice() {
    this._documentApiService
      .VoidTaxInvoice('GoodsReceive', this.DocID)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res);
        this.conFirDelete = false;
        this.openEdit = false;
        this.removedTax = true;
        this.TaxIv = null;
        this.valueChanged.emit(null);
        this.addTaxForm.patchValue({
          taxInvoiceNo: null,
        });
      });
  }
  submit() {
    this.addTaxForm.patchValue({
      taxInvoiceDate: this.addTaxForm.controls.taxInvoiceDate.value[0],
      taxInvoiceNo: this.addTaxForm.controls.taxInvoiceNo.value,
    });
    const ghost = this.addTaxForm.controls.taxInvoiceNo.value;
    console.log('asdf', this.addTaxForm.value);

    //this.addTaxForm pathvalue here this.addTaxForm.taxInvoiceDate = this.addTaxForm.taxInvoiceDate[0]
    this._documentApiService
      .AddTaxInvoice('GoodsReceive', this.DocID, this.addTaxForm.value)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.openEdit = false;
        this.removedTax = false;
        this.TaxIv = ghost;
        this.valueChanged.emit(ghost);
      });
  }
}
