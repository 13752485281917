<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>


    <app-search-panel-code-manage [pathUrl]="pathUrl + '/create'"
      (callSearch)="loadData($event)"></app-search-panel-code-manage>

    <section>
      <div>

        <!-- <app-content-list
        [apiPath]="apiPath"
        [searchData]="searchData"
        [mainPathUrl]="pathUrl + '/detail'"
        [editPathUrl]="pathUrl + '/edit'"
        ></app-content-list> -->

        <div>
          <div class="card">
            <div class="card-body">
              <table [dtOptions]="dtOptions" class="table" datatable>
                <thead>
                  <tr class="text-center" style="width: auto !important">
                    <th class="text-center" width="6%">
                      {{ "ViewTable.No" | translate }}
                    </th>
                    <th class="text-center" width="14%">
                      {{ "ViewTable.CodeName" | translate }}
                    </th>
                    <th class="text-center" width="14%">
                      {{ "ViewTable.RedeemCode" | translate }}
                    </th>
                    <th class="text-center" width="7%">
                      {{ "ViewTable.Status" | translate }}
                    </th>
                    <th class="text-center" width="12%">
                      {{ "ViewTable.StartDate" | translate }}
                    </th>
                    <th class="text-center" width="12%">
                      {{ "ViewTable.EndDate" | translate }}
                    </th>
                    <th class="text-center" width="12%">
                      {{ "ViewTable.Amount" | translate }}
                    </th>
                    <th class="text-center" width="11%">
                      {{ "ViewTable.CreateBy" | translate }}
                    </th>
                    <th class="text-center" width="10%">
                      {{ "ViewTable.Actions" | translate }}
                    </th>
                  </tr>
                </thead>

                <tbody style="width: 100% !important">

                  <tr class="w-100" *ngIf="isLoading">
                    <td class="text-center" *ngFor="let num of [].constructor(9)">
                      <div class="skeleton-loading"></div>
                    </td>
                  </tr>

                  <tr *ngIf="itemList.length < 1 && !isLoading">
                    <td colspan="100%" class="text-center">
                      <p>{{ 'ViewTable.NoItemPreview' | translate }}</p>
                    </td>
                  </tr>

                  <tr *ngFor="let itemObj of itemList; let ind = index">

                    <td class="text-center">{{ (page - 1) * pageSize + ind + 1 }}</td>

                    <td class="text-truncate" style="max-width: 200px">
                      <a [routerLink]="[editPathUrl + '/' + itemObj.id]
                        " class="text-truncate text-primary">
                        {{ itemObj.codeName }}
                      </a>
                    </td>

                    <td class="text-truncate" style="max-width: 200px">
                      <a [routerLink]="[editPathUrl + '/' + itemObj.id]
                        " class="text-truncate text-secondary">
                        {{ itemObj.redeemCode }}
                      </a>
                    </td>

                    <td class="text-center">
                      <div class="form-group my-auto">
                        <div ngbDropdown>
                          <a ngbDropdownToggle href="javascript:void(0);"
                            class="d-flex justify-content-center text-secondary" id="dropdownBrowserState"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="{
                              'text-success': itemObj.isActive,
                              'text-danger': !itemObj.isActive
                            }">
                            <div>
                              {{
                              (itemObj.isActive
                              ? "Status.Published"
                              : "Status.Unpublished"
                              ) | translate
                              }}
                            </div>
                            <i data-feather="chevron-down" class="text-dark cursor-pointer ml-50 my-auto"></i>
                          </a>
                          <div ngbDropdownMenu class="dropdown-menu-left" aria-labelledby="dropdownBrowserState">
                            <a ngbDropdownItem *ngIf="itemObj.isActive" class="d-flex align-items-center"
                              (click)="openIsActiveModal(itemObj, false)">
                              <i data-feather="eye-off" class="mr-50"></i>
                              {{ "Status.Unpublish" | translate }}
                            </a>
                            <a ngbDropdownItem *ngIf="!itemObj.isActive" class="d-flex align-items-center"
                              (click)="openIsActiveModal(itemObj, true)">
                              <i data-feather="eye" class="mr-50"></i>
                              {{ "Status.Publish" | translate }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class="text-center my-auto">
                      <span *ngIf="itemObj.endDate; else noStartDate">
                        <span *ngIf="currentLang != 'th'">
                          {{ itemObj.startDate | date : "dd MMM YYYY" }}
                        </span>
                        <span *ngIf="currentLang == 'th'">
                          {{ itemObj.startDate | thaidate : "DD MMM YYYY" }}
                        </span>
                      </span>
                      <ng-template #noStartDate>-</ng-template>
                    </td>

                    <td class="text-center" style="width: 100px">
                      <span *ngIf="itemObj.endDate; else noEndDate">
                        <span *ngIf="currentLang != 'th'">
                          {{ itemObj.endDate | date : "dd MMM YYYY" }}
                        </span>
                        <span *ngIf="currentLang == 'th'">
                          {{ itemObj.endDate | thaidate : "DD MMM YYYY" }}
                        </span>
                      </span>
                      <ng-template #noEndDate>-</ng-template>
                      <!-- {{ itemObj.endDate | date : "dd MMM YYYY" }} -->
                      <br />
                    </td>

                    <td class="text-center my-auto" [ngClass]="{
                        'date-exit-td':
                          itemObj.documentExpireDate < this.currentDate
                      }">
                      <!-- <span *ngIf="currentLang != 'th'">
                        {{ itemObj.documentExpireDate | date : "dd MMM YYYY" }}
                      </span>
                      <span *ngIf="currentLang != 'en'">
                        {{ itemObj.documentExpireDate | thaidate : "DD MMM YYYY" }}
                      </span> -->
                      <span [ngClass]="{
                          'd-block': itemObj.documentExpireDate < this.currentDate
                        }" class="text-caption-exit">{{itemObj.amount | number}}</span>
                    </td>

                    <td class="text-center">
                      <span>
                        {{ itemObj.createBy }}
                      </span>
                    </td>
                    <td class="text-center d-flex justify-content-center">
                      <!-- <a
                        class="btn btn-icon btn-icon rounded-circle btn-outline-success mr-50"
                        [routerLink]="[detailPathUrl + '/' + itemObj.id]"
                        ><i data-feather="eye"></i
                      ></a> -->
                      <a class="btn btn-icon btn-icon rounded-circle btn-outline-warning mr-50"
                        [routerLink]="[editPathUrl + '/' + itemObj.id]"><i data-feather="edit"></i></a>

                      <a class="btn btn-icon btn-icon rounded-circle btn-outline-danger mr-50" [ngClass]="{
                    'd-none': itemObj.isDelete
                  }" (click)="openDeleteContentModal(itemObj)"><i data-feather="trash-2" class="text-danger"></i></a>

                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>