import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { BankList } from 'app/main/model/BankAccount';
import { ApiService } from 'app/main/service/api.service';
import { FilesApiService } from 'app/main/service/files-api.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  ekycFileNameEnum,
  EkycFormModalComponent,
} from './ekyc-form-modal/ekyc-form-modal.component';

import { CommonResponse } from 'app/main/model/CommonResponse';

import { TranslateService } from '@ngx-translate/core';
import {
  EKYCDetail,
  EKYCStatusEnum,
  EKYCVerifyStatusEnum,
} from 'app/main/model/EKYC';
import { takeUntil } from 'rxjs/operators';

declare var GLightbox: any;
@Component({
  selector: 'app-ekyc-detail',
  templateUrl: './ekyc-detail.component.html',
  styleUrls: ['./ekyc-detail.component.scss'],
})
export class EkycDetailComponent implements OnInit, OnDestroy {
  pidImage: string;
  pidWithPersonImage: string;
  bookBankImage: string;

  pidImageFile: File;
  pidWithPersonImageFile: File;
  bookBankImageFile: File;

  og_pidImage: string;
  og_pidWithPersonImage: string;
  og_bookBankImage: string;

  isPidImageInvalid: boolean = false;
  isPidWithPersonImageInvalid: boolean = false;

  isPidImageChanged: boolean = false;
  isPidWithPersonImageChanged: boolean = false;
  isBookBankImageChanged: boolean = false;

  previewIMG: string;
  previewIMG_file: File;

  maxFileSize: number = environment.limitFileSize.maxImageSizeInBytes;
  activeModal: any;

  EKYCDetail: EKYCDetail = new EKYCDetail();

  bankAccountList: any[] = [];
  isLoading: boolean = false;
  isSubmit: boolean = false;
  isDataChanged: boolean = false;

  public isEditing: Observable<boolean>;
  public isDataSaved: Observable<boolean>;

  private isEditingSubject: BehaviorSubject<boolean>;
  private isDataSavedSubject: BehaviorSubject<boolean>;

  // @Output() setEditing: EventEmitter<any> = new EventEmitter();

  page: number = 1;
  pageSize: number = 10;
  searchData: any = {};

  bankAccountForm: FormGroup;
  bankNameList: { id: number; name: string }[] = [];
  originalData: {
    bankId: number;
    accountNumber: string;
    accountName: string;
  };

  selectedFileEnum: string;

  customerId: string;

  bankaccountname: string;
  bankaccountnumber: string;

  bank: any = {
    id: 4,
  };

  stateEnum = EKYCVerifyStatusEnum;

  private _unsubscribeAll = new Subject();

  isInitedData: boolean = false;

  constructor(
    private _componentsService: ComponentsService,
    private _globalFuncService: GlobalFuncService,
    private _modalService: NgbModal,
    private _apiService: ApiService,
    private _formBuilder: FormBuilder,
    private _fileService: FilesApiService,
    private _route: ActivatedRoute,
    private _translateService: TranslateService
  ) {
    this._route.paramMap.subscribe((val) => {
      this.customerId = this._route.snapshot.paramMap.get('id');
    });

    this.bankNameList = BankList;
    this.initData();
  }

  initData(): void {
    this.getEKYCDetail().then((res) => {
      if (res.data.resultData.length >= 1) {
        this.EKYCDetail = res.data.resultData[0];

        if (this.EKYCDetail.mediaFiles) {
          console.log('loading files ekyc');

          if (this.EKYCDetail.mediaFiles[ekycFileNameEnum.PidImage]) {
            this.pidImageFile = this.fetchAndSetImageFile(
              this.EKYCDetail.mediaFiles[ekycFileNameEnum.PidImage][0].id,
              ekycFileNameEnum.PidImage
            );

            this.pidImage = this._fileService.getImage(
              this.EKYCDetail.mediaFiles[ekycFileNameEnum.PidImage][0].id
            );

            this.og_pidImage = this.pidImage;
          }

          if (this.EKYCDetail.mediaFiles[ekycFileNameEnum.PidWithPersonImage]) {
            this.pidWithPersonImageFile = this.fetchAndSetImageFile(
              this.EKYCDetail.mediaFiles[ekycFileNameEnum.PidWithPersonImage][0]
                .id,
              ekycFileNameEnum.PidWithPersonImage
            );
            this.pidWithPersonImage = this._fileService.getImage(
              this.EKYCDetail.mediaFiles[ekycFileNameEnum.PidWithPersonImage][0]
                .id
            );

            this.og_pidWithPersonImage = this.pidWithPersonImage;
          }

          if (this.EKYCDetail.mediaFiles[ekycFileNameEnum.BookBankImage]) {
            this.bookBankImageFile = this.fetchAndSetImageFile(
              this.EKYCDetail.mediaFiles[ekycFileNameEnum.BookBankImage][0].id,
              ekycFileNameEnum.BookBankImage
            );
            this.bookBankImage = this._fileService.getImage(
              this.EKYCDetail.mediaFiles[ekycFileNameEnum.BookBankImage][0].id
            );

            this.og_bookBankImage = this.bookBankImage;
          }
        }
        this.isInitedData = true;
      }
    });
  }

  fetchAndSetImageFile(mediaFile, imageType) {
    if (mediaFile) {
      if (!mediaFile.id) {
        return;
      }
      this.getImageFile(mediaFile.id)
        .then((blob) => {
          return new File([blob], imageType);
        })
        .catch(() => {
          return null;
        });
    }
    return null;
  }

  fileToImageURL(file: File): string {
    const blob = new Blob([file], { type: 'image/*' });
    const img = new Image();
    img.src = URL.createObjectURL(blob);
    return img.src;
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  ngOnInit(): void {
    setTimeout(() => {
      const ekycLightBox = new GLightbox({
        selector: '.glightbox',
        closeOnOutsideClick: true,
        width: 'auto',
        height: 'auto',
      });
    }, 300);
  }

  resetTab() {}

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  openEKYCFormModal() {
    const modalRef = this._modalService.open(EkycFormModalComponent, {
      centered: true,
      size: 'xl',
    });

    modalRef.componentInstance.EKYCDetail = this.EKYCDetail;
    modalRef.componentInstance.customerId = this.customerId;

    modalRef.componentInstance.pidImage = this.pidImage;
    modalRef.componentInstance.pidWithPersonImage = this.pidWithPersonImage;
    modalRef.componentInstance.bookBankImage = this.bookBankImage;

    modalRef.componentInstance.pidImageFile = this.pidImageFile;
    modalRef.componentInstance.pidWithPersonImageFile =
      this.pidWithPersonImageFile;
    modalRef.componentInstance.bookBankImageFile = this.bookBankImageFile;

    modalRef.componentInstance.og_pidImage = this.og_pidImage;
    modalRef.componentInstance.og_pidWithPersonImage =
      this.og_pidWithPersonImage;
    modalRef.componentInstance.og_bookBankImage = this.og_bookBankImage;

    modalRef.componentInstance.callBackFunc.subscribe(() => {
      this.initData();
    });
  }

  getBankImg(bankId: number) {
    if (bankId) {
      return this._globalFuncService.getBankImg(bankId.toString());
    }
  }

  getBankName(bankId: number) {
    if (bankId) {
      return this._globalFuncService.getBankName(bankId);
    }
  }

  viewImage(res) {
    const blob = new Blob([res], { type: 'image/*' });
    const img = new Image();
    img.src = URL.createObjectURL(blob);

    window.open(img.src, '_blank');
  }

  getEKYCDetail(): Promise<CommonResponse<EKYCDetail>> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetDataById('ekyc/customer', this.customerId)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<EKYCDetail>) => {
            resolve(res);
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  getImageFile(guid: string): Promise<Blob> {
    return new Promise((resolve, rejects) => {
      this._fileService.GetFile(guid).subscribe(
        (res: Blob) => {
          resolve(res);
        },
        (err) => {
          rejects();
        }
      );
    });
  }

  getStatusLabel(state: number): any {
    switch (state) {
      case this.stateEnum.WaitForVerify:
        return this._translateService.instant(
          'BookingCalendar.Status.WaitApprove'
        );
      case this.stateEnum.Verified:
        return this._translateService.instant(
          'BookingCalendar.Status.Approved'
        );
      case this.stateEnum.Rejected:
        return this._translateService.instant('BookingCalendar.Status.Reject');

      default:
        return this._translateService.instant('BookingCalendar.Status.None');
    }
  }

  onFileStatusChange(fileEnum: string, value: string) {
    if (fileEnum == ekycFileNameEnum.PidImage) {
      this.EKYCDetail.verifyIdCardImageStatusEnum = parseInt(value);
      this.handleImagePatch(
        'idcardimage',
        this.EKYCDetail.verifyIdCardImageStatusEnum
      );
    } else if (fileEnum == ekycFileNameEnum.PidWithPersonImage) {
      this.EKYCDetail.verifyPersonalWithIdCardImageStatusEnum = parseInt(value);
      this.handleImagePatch(
        'personalwithidcardimage',
        this.EKYCDetail.verifyPersonalWithIdCardImageStatusEnum
      );
    } else if (fileEnum == ekycFileNameEnum.BookBankImage) {
      this.EKYCDetail.verifyBookbankImageStatusEnum = parseInt(value);
      this.handleImagePatch(
        'bookbankimage',
        this.EKYCDetail.verifyBookbankImageStatusEnum
      );
    }
  }

  private handleImagePatch(filename: string, status: number) {
    if (status === this.stateEnum.Verified) {
      this.patchFileStatusEnum(filename, 'approve');
    } else if (status === this.stateEnum.Rejected) {
      this.patchFileStatusEnum(filename, 'reject');
    } else if (status === this.stateEnum.WaitForVerify) {
      this.patchFileStatusEnum(filename, 'waitforverify');
    }
  }

  private patchFileStatusEnum(filename: string, status: string): Promise<void> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .patch(`ekyc/${status}/${filename}/customer/${this.customerId}`)
        .subscribe(
          (res: CommonResponse<any>) => {
            this._componentsService.SuccessSwal();
            resolve();
          },
          (err) => {
            this._componentsService.ErrorSwal();
            rejects();
          }
        );
    });
  }

  onBankStatusChange(value) {
    this.EKYCDetail.verifyBookbankDetailStatusEnum = parseInt(value);
    this.handleImagePatch(
      ekycFileNameEnum.BookBankDetail,
      this.EKYCDetail.verifyBookbankDetailStatusEnum
    );
  }
}
