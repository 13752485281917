import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PersonalBonus } from 'app/main/model/PersonalBonus';
import Swal from 'sweetalert2';
import { PersonalBonusService } from '../bonus-table/personal-bonus.service';
import { ApiService } from 'app/main/service/api.service';
import { HttpClient } from '@angular/common/http';
import { ComponentsService } from 'app/main/components/components.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';
import { parse } from 'path';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-downline-bonus-table',
  templateUrl: './downline-bonus-table.component.html',
  styleUrls: ['./downline-bonus-table.component.scss'],
})
export class DownlineBonusTableComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  contentHeader: ContentHeader;
  form: FormGroup;
  tableListData: PersonalBonus[] = [];
  selectedRowIndex: number = -1;
  svRate: number;
  isDuplicate: boolean = false;
  isAddData: boolean = false;
  isEditData: boolean = false;
  page: number = 1;
  pageSize: number = 10;
  searchText: string = '';
  searchData: any = {};
  isLoading: boolean;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = { searching: false, lengthChange: false };
  dtTrigger: Subject<any> = new Subject<any>();

  downlineLogList: any[] = [];

  @BlockUI() blockUI: NgBlockUI;

  currentLang: string = ''

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private personalBonusService: PersonalBonusService,
    private http: HttpClient,
    private _componentsService: ComponentsService,
    private modalService: NgbModal,
    private _translateService: TranslateService
  ) {
    this.form = this.formBuilder.group({
      saleInterval: [null, Validators.required],
      percentageP: [0, Validators.required],
      percentageAP: [null, Validators.required],
      percentageMP: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.blockUI.start();
    this.getSvrate();
    this.loadData();
    this.getDownlineBonusAll();

    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: this.pageSize,
      order: [[7, 'desc']],
      ordering: true,
      serverSide: true,
      lengthMenu: [5, 10, 25, 50, 100],
      lengthChange: true,
      columnDefs: [
        { orderable: false, targets: 0 },
        { name: 'status', targets: 1 },
        { name: 'fromAPPercentage', targets: 2 },
        { name: 'toAPPercentage', targets: 3 },
        { name: 'fromMPPercentage', targets: 4 },
        { name: 'toMPPercentage', targets: 5 },
        { name: 'updateBy', targets: 6 },
        { name: 'updateDate', targets: 7 },
        { name: 'createBy', targets: 8 },
        { name: 'createDate', targets: 9 },
        { orderable: false, targets: 10 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        let apiPath = 'mlm/DownlineLog';
        this.downlineLogList = [];
        this.SetLoadingState();

        let defaultSortName = 'updateDate';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          this.searchData.SortPath = defaultSortName;
        } else {
          this.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.searchData.Direction = order?.dir == 'desc' ? 1 : 0;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );

        this.page = this.searchData.Page;

        this.searchData.PageLength = dataTablesParameters.length;
        this.searchData.isDelete = false;

        this.apiService
          .GetAllData(apiPath, this.searchData)
          .subscribe((response) => {
            this.downlineLogList = response.data.resultData;
            this.SetLoadedState();
            callback({
              recordsTotal: response.data.itemCount,
              recordsFiltered: response.data.totalItem,
              data: [],
            });
          });
      },
    };
    this.contentHeader = {
      headerTitle: 'BonusPlan.DownlineBonus',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }
  getSvrate() {
    this.apiService
      .GetAllData('mlm/Argument?Name=SvToBaht')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.svRate = res.data.resultData[0].SVToBaht;
        this.blockUI.stop();
      });
  }

  ngOnDestroy(): void {
      this._unsubscribeAll.next()
      this._unsubscribeAll.complete()
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  initData(obj?: { [key: string]: any }): void {
    this.SetLoadingState();
    this.downlineLogList = [];

    this.searchData = {
      SortPath: 'UpdateDate',
      Direction: 1,
      isDelete: false,
    };

    if (obj) {
      this.searchText = obj.searchText ? obj.searchText : '';

      if (!obj.params) {
        this.searchData = {
          SortPath: 'UpdateDate',
          Direction: 1,
          isDelete: false,
        };
      } else {
        this.searchData = obj.params;
      }
    } else {
      this.searchText = '';
      this.searchData = {
        SortPath: 'CreateDate',
        Direction: 1,
        isDelete: false,
      };
    }

    this.searchData.name = this.searchText;

    this.loadData(this.searchData);
  }

  loadData(searchData?: any): void {
    if (searchData) {
      this.searchData = searchData;
    } else {
      this.searchData = {
        Direction: 1,
        isDelete: false,
      };
    }

    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          this.SetLoadedState();
        });
      });
    }
  }

  sortDataBySaleInterval(): void {
    this.tableListData.sort((a, b) => a.saleInterval - b.saleInterval);
  }

  openAddDataModal() {
    const modalRef = this.modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = 'Add Data';
    modalRef.componentInstance.detail = 'Are you sure to add this data?';
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.addData();
    });
  }
  openDeleteDataModal(index, value: boolean): void {
    const modalRef = this.modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = 'Delete Data';
    modalRef.componentInstance.detail = 'Are you sure to delete this data?';
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.setDeleteStatus(index, value);
      this.loadData();
    });
  }
  openUpdateDataModal(index) {
    const modalRef = this.modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = 'Update Data';
    modalRef.componentInstance.detail = 'Are you sure to update this data?';
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.updateData(index);
      this.selectedRowIndex = -1;
      this.isEditData = false;
      this.loadData();
    });
  }
  openSetActiveModal(index: number, event: any) {
    const modalRef = this.modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = 'Set Active';
    modalRef.componentInstance.detail = 'Are you sure to set this data status?';

    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      if (modalRef.componentInstance.closeModal()) {
        this.setActiveContent(index, this.tableListData[index].isActive);
      } else {
        this.setActiveContent(index, event);
      }

      // this.loadData()
    });
  }

  updateData(index: number): void {
    this.blockUI.start();
    var self = this;

    const updatedData = this.form.value;
    const downlineBonusToUpdate = this.tableListData[index];

    updatedData.saleInterval = parseInt(updatedData.saleInterval, 10);
    updatedData.percentageP = 0;
    updatedData.percentageAP = parseInt(updatedData.percentageAP, 10);
    updatedData.percentageMP = parseInt(updatedData.percentageMP, 10);

    const isDuplicateSaleInterval = this.tableListData
      .filter((item, i) => i !== index)
      .some((item) => item.saleInterval == updatedData.saleInterval);

    if (updatedData.saleInterval <= 0 || isDuplicateSaleInterval) {
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        text: 'Sale interval must be greater than zero and do not duplicate',
      });
      this.blockUI.stop();
      return;
    } else {
      this.blockUI.start();
      if (updatedData.percentageAP) {
        const formData = {
          ...updatedData,
          percentage: updatedData.percentageAP,
        };
        this.apiService
          .UpdateDataById(
            'mlm/MasterDownlineBonus',
            downlineBonusToUpdate.apId,
            formData
          )
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((newData: PersonalBonus) => {
            this.loadData();
            this.tableListData.push(newData);
            this.form.reset();
            this.sortDataBySaleInterval();
            this.blockUI.stop();
          });
      }
      if (updatedData.percentageMP) {
        const formData = {
          ...updatedData,
          percentage: updatedData.percentageMP,
        };

        this.apiService
          .UpdateDataById(
            'mlm/MasterDownlineBonus',
            downlineBonusToUpdate.mpId,
            formData
          )
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((newData: PersonalBonus) => {
            this.tableListData.push(newData);
            this.form.reset();
            this.sortDataBySaleInterval();
            self._componentsService.SuccessSwal();
            this.blockUI.stop();
          });
      } else {
        (error) => {
          console.error('Error adding data:', error);
          self._componentsService.ErrorSwal();
          this.blockUI.stop();
        };
      }
    }
    this.blockUI.stop();
    this.sortDataBySaleInterval();
  }

  addData(): void {
    this.blockUI.start();
    this.isAddData = true;

    var self = this;
    const formData = this.form.value;
    formData.saleInterval = parseInt(formData.saleInterval, 10);
    formData.percentageP = 0;
    formData.percentageAP = parseInt(formData.percentageAP, 10);
    formData.percentageMP = parseInt(formData.percentageMP, 10);
    const isDuplicateSaleInterval = this.tableListData.some(
      (item) => item.saleInterval === formData.saleInterval
    );

    if (formData.saleInterval <= 0 || isDuplicateSaleInterval) {
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        text: 'Sale interval must be greater than zero and do not duplicate',
      });
      this.blockUI.stop();
      return;
    }

    this.apiService.AddData('mlm/MasterDownlineBonus', formData).pipe(takeUntil(this._unsubscribeAll)).subscribe(
      (newData: PersonalBonus) => {
        this.tableListData.unshift(newData);
        this.form.reset();
        this.sortDataBySaleInterval();
        this.loadData();
        self._componentsService.SuccessSwal();
      },
      (error) => {
        console.error('Error adding data:', error);
        self._componentsService.ErrorSwal();
      }
    );
    this.selectedRowIndex = -1;
    this.isAddData = false;
  }

  deleteData(index: number): void {
    var self = this;
    this.blockUI.start();
    const personalBonusToDelete = this.tableListData[index];
    this.apiService
      .DeleleDataById('mlm/MasterDownlineBonus', personalBonusToDelete.apId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        () => {
          this.tableListData.splice(index, 1);
          this.selectedRowIndex = -1;
          self._componentsService.SuccessSwal();
        },
        (error) => {
          console.error('Error deleting data:', error);
          self._componentsService.ErrorSwal();
        }
      );
    this.apiService
      .DeleleDataById('mlm/MasterDownlineBonus', personalBonusToDelete.mpId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {});
    this.blockUI.stop();
    this.sortDataBySaleInterval();
  }

  editData(index: number): void {
    this.isEditData = true;

    this.selectedRowIndex = index;

    this.form.get('saleInterval').disable();
    const originalData = this.tableListData[index];
    this.form = this.formBuilder.group({
      saleInterval: originalData.saleInterval,
      percentageP: originalData.percentageP,
      percentageAP: originalData.percentageAP,
      percentageMP: originalData.percentageMP,
    });
  }

  onaddData(index: number): void {
    this.selectedRowIndex = index;
    this.isAddData = true;
    this.form = this.formBuilder.group({
      saleInterval: [null, Validators.required],
      percentageP: [null, Validators.required],
      percentageAP: [null, Validators.required],
      percentageMP: [null, Validators.required],
    });
  }

  saveRow(index: number): void {
    this.updateData(index);
    this.selectedRowIndex = -1;
    this.loadData();
    this.isAddData == false;
  }

  cancelEdit(index: number): void {
    this.selectedRowIndex = -1;
    this.isAddData = false;
    this.isEditData = false;
  }

  calculateSv(saleInterval: number, percentage: number): number {
    return saleInterval * (percentage / 100);
  }

  calculateThb(
    saleInterval: number,
    percentage: number,
    svRate: number
  ): number {
    return saleInterval * (percentage / 100) * svRate;
  }

  setActiveContent(index: number, value: boolean): void {
    const setActive = this.tableListData[index];
    const isActiveObj = {
      pId: parseInt(setActive.pId, 10),
      mpId: parseInt(setActive.mpId, 10),
      apId: parseInt(setActive.apId, 10),
      isActive: value,
    };
    this.apiService
      .SetIsActiveBonus('mlm/MasterDownlineBonus/PathcActive', isActiveObj)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          this.loadData();
          this._componentsService.SuccessSwal();
          this.getDownlineBonusAll();
        },
        (error) => {
          this._componentsService.ErrorSwal();
        }
      );
  }

  setDeleteStatus(index: number, value: boolean): void {
    const setDelete = this.tableListData[index];
    const isDeleteObj = {
      pId: 0,
      apId: parseInt(setDelete.apId, 10),
      mpId: parseInt(setDelete.mpId, 10),
      isDelete: value,
    };

    this.apiService
      .SoftDeleteBonus('mlm/MasterDownlineBonus/PathcDelete', isDeleteObj)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          this._componentsService.SuccessSwal();
          this.getDownlineBonusAll();
        },
        (error) => {
          this._componentsService.ErrorSwal();
        }
      );
  }

  getDownlineBonusAll() {
    this.apiService
      .GetAllData('mlm/MasterDownlineBonusAll', {
        IsActive: true,
        IsDelete: false,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.tableListData = res.data.resultData;
        this.sortDataBySaleInterval();
        this.blockUI.stop();
      });
  }
}
