<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section>
      <div class="card">
        <div class="card-body">
          <h4>create</h4>
          <ul>
            <li>
              <a
                class="list-group-item border-0"
                routerLink="/auto-form/create/news"
                >News</a
              >
            </li>
            <li>
              <a
                class="list-group-item border-0"
                routerLink="/auto-form/create/banner"
                >Banner</a
              >
            </li>
            <li>
              <a
                class="list-group-item border-0"
                routerLink="/auto-form/create/pdpa"
                >PDPA</a
              >
            </li>
          </ul>
          <h4>edit</h4>
          <ul>
            <li>
              <a
                class="list-group-item border-0"
                routerLink="/auto-form/edit/news/144"
                >News</a
              >
            </li>
            <li>
              <a
                class="list-group-item border-0"
                routerLink="/auto-form/edit/banner/"
                >Banner</a
              >
            </li>
            <li>
              <a
                class="list-group-item border-0"
                routerLink="/auto-form/edit/pdpa/"
                >PDPA</a
              >
            </li>
          </ul>
          <h4>view</h4>
          <ul>
            <li>
              <a
                class="list-group-item border-0"
                routerLink="/document/detail/quotation/dec722ca-8b35-4a03-bdc7-ef9174d6923b/"
              >
                quotation-detail
              </a>
            </li>
            <li>
              <a
                class="list-group-item border-0"
                routerLink="/document/detail/invoice/dec722ca-8b35-4a03-bdc7-ef9174d6923b/"
              >
                invoice-detail
              </a>
            </li>
            <li>
              <a
                class="list-group-item border-0"
                routerLink="/document/detail/saleorder/711820a7-984b-4532-ae3f-7c4b41f57e88/"
              >
                saleorder-detail
              </a>
            </li>
            <li>
              <a class="list-group-item border-0" routerLink="/history/detail">
                history-detail
              </a>
            </li>
            <li>
              <a class="list-group-item border-0" routerLink="/document/test">
                test
              </a>
            </li>
            <li>test</li>
          </ul>

          <h4>modal</h4>
          <ul>
            <li>
              <a class="btn btn-primary" (click)="openModal()">
                {{ "Form.Submit" | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</div>
