import {
  Component,
  OnInit,
  SimpleChanges,
  Input,
  OnChanges,
} from '@angular/core';
import { InventoryStatus } from 'app/main/model/InventoryStatus';

@Component({
  selector: 'app-inventory-status-badge',
  templateUrl: './inventory-status-badge.component.html',
  styleUrls: ['./inventory-status-badge.component.scss'],
})
export class InventoryStatusBadgeComponent implements OnInit, OnChanges {
  @Input() status: number;
  @Input() isDelete: boolean;

  cssClass: string;
  label: string;
  
  constructor() {}

  displayStatusOn(status: number, isDelete: boolean) {
    switch (status) {
      case InventoryStatus.Pending:
        this.cssClass = 'badge-light-warning';
        this.label = 'Status.Pending';
        break;
      case InventoryStatus.Complete:
        this.cssClass = 'badge-light-success';
        this.label = 'Status.Complete';
        break;
      case InventoryStatus.WaitingAdjust:
        this.cssClass = 'badge-light-info';
        this.label = 'Status.WaitingAdjust';
        break;
      default:
        this.label = '-';
        break;
    }
  }

  ngOnInit(): void {

    this.displayStatusOn(this.status, this.isDelete);
    console.log(this.status);
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.displayStatusOn(this.status, this.isDelete);
  }
}
