<!-- Menu header -->
<!-- <div class="navbar-header">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item mx-auto"> -->
<!-- App Branding
      <a class="navbar-brand mx-auto" [routerLink]="['/']">
        <span class="brand-logo">
          <img [src]="coreConfig.app.appLogoNav" alt="brand-logo" />
        </span>
      </a> -->

<div class="navbar-header">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item mx-auto">
      <a class="navbar-brand mx-auto" [routerLink]="['/']">
        <span class="brand-logo">
          <img [src]="coreConfig.app.appLogoNav" alt="brand-logo" />
        </span>
      </a>
      <!-- <a class="navbar-brand" href="/vuexy-angular-admin-template/demo-1/"
        ><span class="brand-logo"
          ><img
            alt="brand-logo"
            src="assets/images/logo/favicon.ico"
            width="35px"
        /></span>
        <h2 class="brand-text mb-0" style="font-size: 1.2rem">
          App Intouch
        </h2></a
      > -->
    </li>
    <li class="nav-item nav-toggle">
      <!-- <a
        class="nav-link modern-nav-toggle d-none d-xl-block pr-0"
        (click)="collapsMenu()"
        style="padding-top: 5px"
        ><i
          class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary icon-disc"
        ></i
      ></a> -->
    </li>
  </ul>
</div>
<!-- </li> -->

<!-- Menu Toggler -->
<!-- <li class="nav-item nav-toggle">
      <a class="nav-link modern-nav-toggle d-none d-xl-block pr-0" (click)="toggleSidebarCollapsible()">
        <i
          [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"
        ></i>
      </a>
      <a class="nav-link modern-nav-toggle d-block d-xl-none pr-0" (click)="toggleSidebar()">
        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
      </a>
    </li> -->
<!-- </ul>
</div> -->
<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

<!-- Main menu -->
<div class="main-menu-content" [perfectScrollbar] (scroll)="onSidebarScroll()">
  <ul class="navigation navigation-main" layout="vertical" core-menu></ul>
</div>
<!--/ Main menu -->
