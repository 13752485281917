import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsLandingComponent } from './news-landing.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';

const path: string = 'cms/news-landing';
const routes: Routes = [
  {
    path: path,
    component: NewsLandingComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-news-landing' },
  },
  
];

@NgModule({
  declarations: [NewsLandingComponent],
  imports: [CommonModule, RouterModule.forChild(routes), CmsFormpageModule],
  exports: [NewsLandingComponent],
})
export class NewsLandingModule {}
