import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdpaComponent } from './pdpa.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';

const path: string = 'cms/privacy-policy';
const routes: Routes = [
  {
    path: path,
    component: PdpaComponent,
    canActivate: [AuthGuard],
    data: { animation: 'privacy-policy' },
  },
];

@NgModule({
  declarations: [PdpaComponent],
  imports: [CommonModule, RouterModule.forChild(routes), CmsFormpageModule],
  exports: [PdpaComponent],
})
export class PdpaModule {}
