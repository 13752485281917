import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'app/main/service/api.service';

@Component({
  selector: 'app-approval-modal',
  templateUrl: './approval-modal.component.html',
  styleUrls: ['./approval-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApprovalModalComponent implements OnInit {
  @Input() title: string;
  @Input() detail: string;
  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();
  @Input() isSubmit: boolean;

  formGroup: FormGroup;
  remarks: string;

  constructor(
    public activeModal: NgbActiveModal,
    private _apiService: ApiService,
    private _formBuilder: FormBuilder,
  ) { 
    this.formGroup = this._formBuilder.group({
      remarks: ['', []]
    });
  }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.activeModal.close();
  }

  callBack(): void {
    this.isSubmit = true
    if (this.formGroup.invalid) {
      return;
    }

    this.remarks = this.formGroup.get('remarks').value;
    console.log('Callback Final Data:', this.remarks);

    this.callBackFunc.emit(this.remarks);
    this.closeModal()
  }
}
