import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormTemplateComponent } from 'app/main/components/form/form-template/form-template.component';
import { CmsFormConfig } from 'app/main/config/CMSFormConfig';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import TermsCondition from 'app/main/model/TermsCondition';
import { ApiService } from 'app/main/service/api.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss'],
})
export class TermsConditionComponent implements OnInit {
  config: ICMSConfig = {
    apiPath: 'TermsCondition',
    tableName: 'Content',
    pathUrl: '/cms/terms-condition',
    model: new TermsCondition(),
    isList: false,
    componentName: 'Terms and Condition',
    formConfig: {
      termsDate: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Date',
        description: 'Date of Terms and Condition',
        labelTranslate: 'Informative.TermsCondition.Date.Label',
        descriptionTranslate: 'Informative.TermsCondition.Date.Description',
      },
      termsName: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Title',
        description: 'Title of Terms and Condition',
        labelTranslate: 'Informative.TermsCondition.Title.Label',
        descriptionTranslate: 'Informative.TermsCondition.Title.Description',
      },
      termsDescription: {
        type: FormType.RichText,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Description',
        description: 'Description of Terms and Condition',
        labelTranslate: 'Informative.TermsCondition.Description.Label',
        descriptionTranslate:
          'Informative.TermsCondition.Description.Description',
      },
    },
  };

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
