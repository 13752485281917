import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AclComponent } from './acl.component';
import { CoreCommonModule } from '@core/common.module';
import { FormsModule } from '@angular/forms';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'app/main/components/components.module';

const routes: Routes = [
  {
    path: 'admin/acl',
    component: AclComponent,
    canActivate: [AuthGuard],
    data: { animation: 'acl-manage', roles: ['SuperAdmin'] },
  },
];

@NgModule({
  declarations: [AclComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    CoreCommonModule,
    FormsModule,
    ContentHeaderModule,
    NgbModule,
    TranslateModule,
    ComponentsModule
  ],
  exports: [AclComponent],
})
export class AclModule {}
