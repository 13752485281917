import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-technology',
  templateUrl: './our-technology.component.html',
  styleUrls: ['./our-technology.component.scss']
})
export class OurTechnologyComponent implements OnInit {
  contentHeader: object;
  constructor() { }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Manage Our Technology Page',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }


}
