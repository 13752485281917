import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from './faq.component';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { FaqCreateComponent } from './faq-create/faq-create.component';
import { CmsListModule } from '../cms-list/cms-list.module';

const path: string = 'cms/faq';
const routes: Routes = [
  {
    path: path,
    component: FaqComponent,
    canActivate: [AuthGuard],
    data: { animation: 'faq-management' },
  },
  {
    path: `${path}/create`,
    component: FaqCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'faq-create' },
  },
  {
    path: `${path}/edit/:id`,
    component: FaqCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'faq-edit' },
  },
]

@NgModule({
  declarations: [
    FaqComponent,
    FaqCreateComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CmsFormpageModule,
    CmsListModule,
  ],
  exports: [
    FaqComponent
  ]
})
export class FaqModule { }
