<div class="modal-header">
  <h5 class="modal-title" id="exampleModalCenterTitle">Select User Modal</h5>
  <button type="button" class="close" (click)="closeModal()" aria-label="Close">
    <span aria-hidden="true">
      <i data-feather="x" size="22"></i>
    </span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <div class="user-search d-flex">
    <div class="col-4 pl-0">
      <select class="form-control" [(ngModel)]="searchType" id="">
        <option [value]="1">Username</option>
        <option [value]="2">Email</option>
      </select>
    </div>
    <div class="col-8 d-flex px-0">
      <div class="input-group input-group-merge pr-1">
        <input
          type="text"
          class="form-control"
          placeholder="Search here"
          [(ngModel)]="searchText"
          [disabled]="isLoading"
        />
        <div class="input-group-append">
          <span class="input-group-text cursor-pointer">
            <i data-feather="search"></i>
          </span>
        </div>
      </div>
      <button (click)="SearchUser()" [disabled]="isLoading" class="btn btn-primary">Search</button>
    </div>
  </div>
 
  <div class="user-list">
    <table class="table table-hover" datatable [dtOptions]="dtOptions">
      <thead>
        <tr>
          <th class="text-center" width="10%">#</th>
          <th width="45%">Username</th>
          <th width="45%">Email</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let user of userList; index as ind"
          (click)="selectUser(user)"
        >
          <td class="text-center">{{ ind + 1 }}</td>
          <td class="d-flex">
            <img
              [src]="
                user.mediaFiles?.profileImage
                  ? getImages(user.mediaFiles?.profileImage[0]?.id, {
                      Width: 60,
                      Height: 60
                    })
                  : defaultIMG
              "
              [alt]="defaultIMG"
              class="mr-1"
              style="
                border-radius: 5px;
                width: 50px;
                height: 50px;
                object-fit: cover;
              "
            />
            <div class="text-primary align-self-center">
              {{ user.username }}
            </div>
          </td>
          <td>{{ user.email }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer justify-content-between">
  <button
    type="button"
    class="btn btn-secondary m-0"
    (click)="closeModal()"
    rippleEffect
  >
    {{ "Form.Cancel" | translate }}
  </button>
  <a
    type="button"
    class="btn btn-primary m-0"
    (click)="callBack()"
    rippleEffect
  >
    {{ "Form.Confirm" | translate }}
  </a>
</div>
