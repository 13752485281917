import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import News from 'app/main/model/News';

@Injectable({
  providedIn: 'root',
})
export class ConsumerReviewService {
  config: ICMSConfig = {
    apiPath: 'NewsReview',
    pathUrl: '/cms/consumer-review',
    tableName: 'Content',
    model: new News(),
    isList: true,
    componentName: 'BreadCrumbs.CMS.ConsumerReview',
    formConfig: {
      reserved1: {
        type: FormType.Datepicker,
        validator: [Validators.required],
        label: 'Consumer Review Date',
        description: 'Date of Consumer Review',
        labelTranslate: 'Informative.NewsEvent.ConsumerReviewDate.Label',
        descriptionTranslate:
          'Informative.NewsEvent.ConsumerReviewDate.Description',
      },
      name: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Consumer Review Title',
        description: 'Title of Consumer Review',
        labelTranslate: 'Informative.NewsEvent.ConsumerReviewTitle.Label',
        descriptionTranslate:
          'Informative.NewsEvent.ConsumerReviewTitle.Description',
      },
      itemNewsPic1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Consumer Review Picture 1',
        description: '1st Picture of Consumer Review',
        labelTranslate: 'Informative.NewsEvent.ConsumerReviewImage1.Label',
        descriptionTranslate:
          'Informative.NewsEvent.ConsumerReviewImage1.Description',
      },
      detail: {
        type: FormType.RichText,
        validator: [Validators.required],
        isMultipleLang: true,
        label: 'Detail',
        description: 'Detail of Consumer Review',
        labelTranslate: 'Informative.NewsEvent.Detail.Label',
        descriptionTranslate: 'Informative.NewsEvent.Detail.Description',
      },

      itemNewsPic2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Consumer Review Picture 2',
        description: '2nd Picture of Consumer Review',
        labelTranslate: 'Informative.NewsEvent.ConsumerReviewImage2.Label',
        descriptionTranslate:
          'Informative.NewsEvent.ConsumerReviewImage2.Description',
      },

      subTitle: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Reviewer Name',
        description: 'Name of Reviewer',
        labelTranslate: 'Informative.NewsEvent.ReviewerName.Label',
        descriptionTranslate: 'Informative.NewsEvent.ReviewerName.Description',
      },

      linkBtnUrl: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'URL Button',
        description: 'URL to redirect',
        labelTranslate: 'Informative.NewsEvent.URLButton.Label',
        descriptionTranslate: 'Informative.NewsEvent.URLButton.Description',
      },

      iconEnum: {
        type: FormType.Select,
        selectDataList: [
          { label: 'Facebook', value: 1 },
          { label: 'X', value: 2 },
          { label: 'Youtube', value: 3 },
          { label: 'Instagram', value: 4 },
          { label: 'Tiktok', value: 5 },
          { label: 'Pantip', value: 6 },
          { label: 'Jeban', value: 7 },
        ],
        validator: [Validators.required],
        defaultSelectLabel: '-- Select Social Media --',
        label: 'Social Media',
        description: 'Select Social Media',
        labelTranslate: 'Informative.NewsEvent.SocialMedia.Label',
        descriptionTranslate: 'Informative.NewsEvent.SocialMedia.Description',
      },

      isActive: {
        type: FormType.IsActive,
        radioList: [
          { label: 'Active', translate: 'Status.Active', value: true },
          { label: 'Inactive', translate: 'Status.Inactive', value: false },
        ],
        label: 'Active Status',
        labelTranslate: 'Informative.ActiveStatus',
      },
    },
  };

  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'name';
  constructor() {}
}
