import { Component, OnInit, ViewChild } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { HighlightTechService } from './highlight-tech.service';
import { ApiService } from 'app/main/service/api.service';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from 'app/main/components/components.service';
import { AuthenticationService } from 'app/auth/service';
import { ActivatedRoute } from '@angular/router';
import { ContentListComponent } from 'app/main/components/content-list/content-list.component';
import { Subject, Subscription } from 'rxjs';
import Hydroethosome from 'app/main/model/Hydroethosome';

@Component({
  selector: 'app-highlight-tech',
  templateUrl: './highlight-tech.component.html',
  styleUrls: ['./highlight-tech.component.scss']
})
export class HighlightTechComponent implements OnInit {

  private _unsubscribeAll: Subject<any>;
  private dataSubscription: Subscription;

  componentName: string;
  childComponentName: string;

  apiPath: string;

  groupPathUrl: string;
  groupApiPathUrl: string;

  pathUrl: string;
  contentHeader: object;
  contentGuid: string;

  contentHydroethosomeList: Hydroethosome[];
  selectedColumns: string[];

  isLoading: boolean;
  isGridView: boolean;
  isShowChildList: boolean = true;
  isForGroupTemplate: boolean = false;
  isGoEditPage: boolean = true;
  activeStatus: string;
  searchText: string;
  searchData: { [k: string]: any } = {};

  public page;
  public pageSize;

  @ViewChild(ContentListComponent) contentListTable: ContentListComponent;

  constructor(
    private _apiService: ApiService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _authenticationService: AuthenticationService,
    private _highlightTechService: HighlightTechService,
    private _route: ActivatedRoute
  ) {
    this.SetLoadingState();
    this._unsubscribeAll = new Subject();

    this.componentName = this._highlightTechService.componentName;
    this.apiPath = this._highlightTechService.apiPath;
    this.pathUrl = this._highlightTechService.pathUrl;

    this.page = 1;
    this.pageSize = 20;

    this._apiService.SetHttpHeaders(this._authenticationService.tokenValue);
    this.initData();
  }

  initData(obj?: { [key: string]: any }): void {
    this.SetLoadingState();
    this.selectedColumns = ['NO.', 'NAME', 'STATUS', 'UPDATE DATE'];
    this.contentHydroethosomeList = [];

    if (obj) {
      console.log(obj.params);
      console.log('have searchtext: ', obj.searchText);

      this.searchText = obj.searchText ? obj.searchText : '';

      if (!obj.params) {
        this.searchData = {
          SortPath: 'CreateDate',
          Direction: 1,
          isDelete: false,
        };
      } else {
        this.searchData = obj.params;
      }
    } else {
      this.searchText = '';
      this.searchData = {
        SortPath: 'CreateDate',
        Direction: 1,
        isDelete: false,
      };
    }

    this.searchData.name = this.searchText;
    this.searchData.isItemSet = false;
    this.searchData.verbose = false;

    if (this.contentListTable) {
      this.contentListTable.loadData(this.searchData);
    }
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  childListView() {
    this.isShowChildList = true;
  }

  groupContentView() {
    this.isShowChildList = false;
  }

  listView() {
    this.isGridView = false;
  }

  gridView() {
    this.isGridView = true;
  }

  SearchFilter(val: string) {
    this.searchText = val.toLowerCase();
    this.SetLoadedState();
  }

  ActiveStatusFilter(val: string) {
    this.activeStatus = val;
    console.log(val);
  }

  setPageSize(val: number): void {
    this.pageSize = val;
  }

  SortFilter(key: string) {
    let objKey = JSON.parse(key);
    let SortPath = objKey.SortPath;
    let Direction = objKey.Direction;
    console.log(key);

    // this.SetLoadingState()
    // this._apiService.GetAllData(this.apiPath, objKey.SortPath, objKey.Direction).subscribe((res) => {
    //   this.contentList = res.data.resultData;
    //   this.SetLoadedState()
    // });
  }

  exportXLSX() {
    this.contentListTable.ExportXLSX();
  }

  setActiveContent(val): void {
    this._apiService
      .SetIsActiveData(this.apiPath, val.id, val.status)
      .subscribe(
        (res) => {
          this._componentsService.SuccessSwal();
          this.SetLoadingState();
          this.initData();
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
  }

  deleteContent(guid: string): void {
    console.log('delele Complete' + guid);
    this._apiService.SoftDelete(this.apiPath, guid).subscribe(
      (res) => {
        this._componentsService.SuccessSwal();
        this.SetLoadingState();
        this.initData();
      },
      (err) => {
        this._componentsService.ErrorSwal();
      }
    );
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'BreadCrumbs.CMS.RepoveTools.HydroethosomeHighlightTech.Title',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

}
