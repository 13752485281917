import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { BankList } from 'app/main/model/BankAccount';
import { CommonResponse } from 'app/main/model/CommonResponse';
import {
  EKYCDetail,
  EKYCStatusEnum,
  EKYCVerifyStatusEnum,
} from 'app/main/model/EKYC';
import { ApiService } from 'app/main/service/api.service';
import {
  FileMimeType,
  FilesApiService,
} from 'app/main/service/files-api.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { environment } from 'environments/environment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export enum ekycFileNameEnum {
  PidImage = 'pidImage',
  PidWithPersonImage = 'pidWithPersonImage',
  BookBankImage = 'bookBankImage',
  BookBankDetail = 'bookBankDetail',
}

@Component({
  selector: 'app-ekyc-form-modal',
  templateUrl: './ekyc-form-modal.component.html',
  styleUrls: ['./ekyc-form-modal.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class EkycFormModalComponent implements OnInit {
  @Input() isConfirm: boolean;
  @Input() confirmUrl: string;
  @Input() title: string;
  @Input() customerId: string;

  @Output() cancelFunc: EventEmitter<any> = new EventEmitter();
  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();

  stateEnum = EKYCVerifyStatusEnum;

  EKYCDetail: EKYCDetail;

  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private _componentsService: ComponentsService,
    private _globalFuncService: GlobalFuncService,
    private _modalService: NgbModal,
    private _apiService: ApiService,
    private _formBuilder: FormBuilder,
    private _fileService: FilesApiService,
    private _route: ActivatedRoute,
    private _translateService: TranslateService
  ) {
    this.bankNameList = BankList;

    this.bankAccountForm = this._formBuilder.group({
      bankId: [null], // Validators.required
      accountNumber: [
        null,
        [Validators.required, Validators.pattern('[0-9]{9,20}$')],
      ], // Validators.required
      accountName: [
        null,
        [
          Validators.required,
          Validators.pattern(
            /^[^\s\d-][a-zA-Z|\u0E00-\u0E7F\s-]*[a-zA-Z|\u0E00-\u0E7F]$/
          ),
        ],
      ], // Validators.required
    });

    this.isEditingSubject = new BehaviorSubject<boolean>(false);
    this.isDataSavedSubject = new BehaviorSubject<boolean>(false);

    this.isEditing = this.isEditingSubject.asObservable();
    this.isDataSaved = this.isDataSavedSubject.asObservable();

    this.SetDisabledForm();
  }

  closeModal(): void {
    this.cancelFunc.emit();
    // this.activeModal.close();
    this._modalService.dismissAll();
  }

  callBack(): void {
    this.callBackFunc.emit();
    this.activeModal.close();
    // this.router.navigate([this.confirmUrl]);
  }

  @Input() pidImage: string;
  @Input() pidWithPersonImage: string;
  @Input() bookBankImage: string;

  @Input() pidImageFile: File;
  @Input() pidWithPersonImageFile: File;
  @Input() bookBankImageFile: File;

  @Input() og_pidImage: string;
  @Input() og_pidWithPersonImage: string;
  @Input() og_bookBankImage: string;

  isPidImageInvalid: boolean = false;
  isPidWithPersonImageInvalid: boolean = false;

  isPidImageChanged: boolean = false;
  isPidWithPersonImageChanged: boolean = false;
  isBookBankImageChanged: boolean = false;
  isBookBankDetailChanged: boolean = false;

  isPidStatusChanged: boolean = false;
  isPidWithPersonStatusChanged: boolean = false;
  isBookBankImageStatusChanged: boolean = false;
  isBookBankDetailStatusChanged: boolean = false;

  pidImageStateEnum: number = 0;
  pidWithPersonImageStateEnum: number = 0;
  bookBankImageStateEnum: number = 0;
  bookBankDetailStateEnum: number = 0;

  previewIMG: string;
  previewIMG_file: File;

  maxFileSize: number = environment.limitFileSize.maxImageSizeInBytes;

  bankAccountList: any[] = [];
  isLoading: boolean = false;
  isSubmit: boolean = false;
  isDataChanged: boolean = false;

  public isEditing: Observable<boolean>;
  public isDataSaved: Observable<boolean>;

  private isEditingSubject: BehaviorSubject<boolean>;
  private isDataSavedSubject: BehaviorSubject<boolean>;

  // @Output() setEditing: EventEmitter<any> = new EventEmitter();

  page: number = 1;
  pageSize: number = 10;
  searchData: any = {};

  bankAccountForm: FormGroup;
  bankNameList: { id: number; name: string }[] = [];
  originalData: {
    bankId: number;
    accountNumber: string;
    accountName: string;
  };

  selectedFileEnum: string;

  @BlockUI() blockUI: NgBlockUI;

  public get isEditingValue(): boolean {
    return this.isEditingSubject.value;
  }

  public get isDataSavedValue(): boolean {
    return this.isDataSavedSubject.value;
  }

  SetDisabledForm(): void {
    this.bankAccountForm.controls.bankId.disable();
    this.bankAccountForm.controls.accountNumber.disable();
    this.bankAccountForm.controls.accountName.disable();
    this.isEditingSubject.next(false);
  }

  SetEnabledForm(): void {
    this.bankAccountForm.controls.bankId.enable();
    this.bankAccountForm.controls.accountNumber.enable();
    this.bankAccountForm.controls.accountName.enable();
    this.isEditingSubject.next(true);

    this.bankAccountForm.valueChanges.subscribe((res) => {
      this.isBookBankDetailChanged = true;
    });
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  ngOnInit(): void {
    if (this.isEditing) {
      this.bankAccountForm.patchValue({
        bankId: this.EKYCDetail.masterBankNameId,
        accountNumber: this.EKYCDetail.bankAccountNo,
        accountName: this.EKYCDetail.bankAccountName,
      });

      this.pidImageStateEnum = this.EKYCDetail.verifyIdCardImageStatusEnum;
      this.pidWithPersonImageStateEnum =
        this.EKYCDetail.verifyPersonalWithIdCardImageStatusEnum;
      this.bookBankImageStateEnum =
        this.EKYCDetail.verifyBookbankImageStatusEnum;
      // this.bookBankImageStateEnum = this.EKYCDetail.verifyBookbankDetailStatusEnum
    }


  }

  editMode() {
    this.isEditingSubject.next(true);
    this.SetEnabledForm();
  }

  bytesToSizes(size: number): string {
    return this._globalFuncService.bytesToSize(size);
  }

  onFileSelected(event, fileEnum: string, modal) {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file && file.size <= this.maxFileSize) {
      reader.onloadend = () => {
        if (fileEnum == 'pidImage') {
          this.isPidImageInvalid = false;
        } else if (fileEnum == 'pidWithPersonImage') {
          this.isPidWithPersonImageInvalid = false;
        }

        this.previewIMG = reader.result as string;
        this.previewIMG_file = file;
        this.selectedFileEnum = fileEnum;
      };
      reader.readAsDataURL(file);
      this.openChangePictureModal(modal);
    } else {
      console.error(
        `File size exceeds the maximum limit of ${this.bytesToSizes(
          this.maxFileSize
        )}.`
      );
      this._componentsService.ErrorSwal(
        'Invalid File Size',
        `File size exceeds the maximum limit of ${this.bytesToSizes(
          this.maxFileSize
        )}. `
      );
    }
  }

  viewImage(tempimage) {
    console.log('view image', tempimage);
  }

  openChangePictureModal(modal) {
    this.activeModal = this._modalService.open(modal, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });
  }

  get f() {
    return this.bankAccountForm.controls;
  }

  resetTab() {
    this.isEditingSubject.next(false);
    this.SetDisabledForm();
  }

  ngOnDestroy(): void {
    this.isEditingSubject.next(false);
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.SetDisabledForm();
  }

  onSelectBankId() {}

  onSelectAccountNumber() {}

  onSelectAccountName() {}

  editBankAccount() {}

  clearDataToOriginal() {
    this.bankAccountForm.patchValue({
      bankId: this.originalData.bankId,
      accountNumber: this.originalData.accountNumber,
      accountName: this.originalData.accountName,
    });
  }

  checkValidateImage(): boolean {
    let havePidImage: boolean = !!this.og_pidImage || !!this.pidImageFile;
    let havePidWithPersonImage: boolean =
      !!this.og_pidWithPersonImage || !!this.pidWithPersonImageFile;

    this.isPidImageInvalid = !havePidImage;
    this.isPidWithPersonImageInvalid = !havePidWithPersonImage;

    // console.log('og_pidImage', this.og_pidImage);
    // console.log('pidImageFile', this.pidImageFile);

    // console.log('og_pidWithPersonImage', this.og_pidWithPersonImage);
    // console.log('pidWithPersonImageFile', this.pidWithPersonImageFile);

    // console.log('isPidImageInvalid', this.isPidImageInvalid);
    // console.log(
    //   'isPidWithPersonImageInvalid',
    //   this.isPidWithPersonImageInvalid
    // );

    // console.log('result', havePidImage && havePidWithPersonImage);

    return havePidImage && havePidWithPersonImage;
  }

  Submit() {
    this.isSubmit = true;

    if (this.bankAccountForm.invalid || !this.checkValidateImage()) {
      return;
    }

    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = this._translateService.instant(
      'Modal.ConfirmCreate'
    );
    modalRef.componentInstance.detail = this._translateService.instant(
      'Modal.AreYouSureToCreate'
    );
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.SaveKYCData();
      console.log(res);
    });
  }

  Cancel() {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = this._translateService.instant(
      'Modal.CancelManagePage'
    );
    modalRef.componentInstance.detail = this._translateService.instant(
      'Modal.PressSubmitToCancel'
    );
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.isEditingSubject.next(false);
      this.SetDisabledForm();
      this.closeModal();
    });
  }

  SavePicture() {
    if (this.selectedFileEnum == ekycFileNameEnum.PidImage) {
      this.pidImage = this.previewIMG;
      this.pidImageFile = this.previewIMG_file;

      this.isPidImageChanged = true;
    } else if (this.selectedFileEnum == ekycFileNameEnum.PidWithPersonImage) {
      this.pidWithPersonImage = this.previewIMG;
      this.pidWithPersonImageFile = this.previewIMG_file;

      this.isPidWithPersonImageChanged = true;
    } else if (this.selectedFileEnum == ekycFileNameEnum.BookBankImage) {
      this.bookBankImage = this.previewIMG;
      this.bookBankImageFile = this.previewIMG_file;

      this.isBookBankImageChanged = true;
    }

    this.isDataChanged = true;
    this.selectedFileEnum = '';
    // this._modalService.dismissAll();
  }

  async SaveKYCData() {
    this.blockUI.start();
    let promiseAll = [];

    if (this.isPidImageChanged) {
      console.log('isPidImageChanged');
      promiseAll.push(
        await this.UploadImage(
          'ekyc',
          this.customerId,
          ekycFileNameEnum.PidImage,
          0,
          this.pidImageFile
        )
          .then()
          .catch((error) => {
            console.error('Error uploading PID image:', error);
            // Return a resolved promise to ensure Promise.all continues execution
            return Promise.resolve();
          })
      );
    }

    if (this.isPidWithPersonImageChanged) {
      console.log('isPidWithPersonImageChanged');
      promiseAll.push(
        await this.UploadImage(
          'ekyc',
          this.customerId,
          ekycFileNameEnum.PidWithPersonImage,
          0,
          this.pidWithPersonImageFile
        )
          .then()
          .catch((error) => {
            console.error('Error uploading PID with person image:', error);
            return Promise.resolve();
          })
      );
    }

    if (this.isBookBankImageChanged) {
      console.log('isBookBankImageChanged');
      promiseAll.push(
        await this.UploadImage(
          'ekyc',
          this.customerId,
          ekycFileNameEnum.BookBankImage,
          0,
          this.bookBankImageFile
        )
          .then()
          .catch((error) => {
            console.error('Error uploading book bank image:', error);
            return Promise.resolve();
          })
      );
    }

    if (this.isBookBankDetailChanged) {
      promiseAll.push(
        await this.UpdateBankDetail().catch((error) => {
          console.error('Error updating bank detail:', error);
          return Promise.resolve();
        })
      );
    }

    if (this.isPidStatusChanged) {
      promiseAll.push(
        this.patchFileStatusEnum('idcardimage', this.pidImageStateEnum).catch(
          (error) => {
            return Promise.resolve();
          }
        )
      );
    }

    if (this.isPidWithPersonStatusChanged) {
      promiseAll.push(
        this.patchFileStatusEnum(
          'personalwithidcardimage',
          this.pidWithPersonImageStateEnum
        ).catch((error) => {
          return Promise.resolve();
        })
      );
    }

    if (this.isBookBankImageStatusChanged) {
      promiseAll.push(
        this.patchFileStatusEnum(
          'bookbankimage',
          this.bookBankImageStateEnum
        ).catch((error) => {
          return Promise.resolve();
        })
      );
    }

    if (this.isBookBankDetailStatusChanged) {
      promiseAll.push(
        this.patchFileStatusEnum(
          'bookbankdetail',
          this.bookBankDetailStateEnum
        ).catch((error) => {
          return Promise.resolve();
        })
      );
    }

    for (const promise of promiseAll) {
      try {
        await promise;
      } catch (error) {
        console.error('Error occurred:', error);
        // Handle error if needed
        this.blockUI.stop();
        this._componentsService.ErrorSwal();
      }
    }

    this.blockUI.stop();
    // this.isDataSavedSubject.next(true);
    this._modalService.dismissAll();
    this.callBackFunc.emit();
    this._componentsService.SuccessSwal();

    // Promise.all(promiseAll)
    //   .then(async () => {
    //     this.blockUI.stop();
    //     // this.isDataSavedSubject.next(true);
    //     this._modalService.dismissAll();
    //     this.callBackFunc.emit();
    //     this._componentsService.SuccessSwal();
    //     console.log('success');
    //   })
    //   .catch(() => {
    //     this.blockUI.stop();
    //     console.log(promiseAll);
    //     console.log('broke');
    //     this._componentsService.ErrorSwal();
    //   });
  }

  UpdateBankDetail(): Promise<void> {
    let bankDetailBody = {
      masterBankNameId: this.bankAccountForm.controls.bankId.value,
      bankAccountName: this.bankAccountForm.controls.accountName.value,
      bankAccountNo: this.bankAccountForm.controls.accountNumber.value,
    };
    return new Promise((resolve, rejects) => {
      this._apiService
        .UpdateDataById('ekyc/bank/customer', this.customerId, bankDetailBody)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            resolve();
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  async UploadImage(
    tablename: string,
    contentId: string,
    fileproperty: string,
    ordinal: number,
    file: File
  ): Promise<void> {
    let base64str: string | undefined;

    await new Promise<void>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        base64str = reader.result?.toString().split(',')[1];
        resolve();
      };
      reader.onerror = () => {
        reject(new Error('Failed to read the file.'));
      };
      reader.readAsDataURL(file);
    });

    if (!base64str) {
      throw new Error('Failed to convert file to Base64.');
    }

    let filepath = '';

    switch (fileproperty) {
      case ekycFileNameEnum.PidImage:
        filepath = 'idcard';
        break;
      case ekycFileNameEnum.PidWithPersonImage:
        filepath = 'personalwithidcard';
        break;
      case ekycFileNameEnum.BookBankImage:
        filepath = 'bookbank';
        break;

      default:
        break;
    }

    return new Promise((resolve, rejects) => {
      this._apiService
        .post(`ekyc/file/upload/${filepath}`, {
          customerId: this.customerId,
          fileDataBase64: base64str,
          fileExtension: FileMimeType[file.type],
          fileName: file.name,
        })
        .subscribe(
          (res) => {
            resolve();
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  getBankImg(bankId: number) {
    return this._globalFuncService.getBankImg(bankId.toString());
  }

  getStatusLabel(state: number): any {
    switch (state) {
      case this.stateEnum.WaitForVerify:
        return this._translateService.instant(
          'BookingCalendar.Status.WaitApprove'
        );
      case this.stateEnum.Verified:
        return this._translateService.instant(
          'BookingCalendar.Status.Approved'
        );
      case this.stateEnum.Rejected:
        return this._translateService.instant('BookingCalendar.Status.Reject');

      default:
        return this._translateService.instant('BookingCalendar.Status.None');
    }
  }

  onFileStatusChange(fileEnum: string, value: number) {
    console.log(fileEnum);
    console.log(value);
    if (fileEnum == ekycFileNameEnum.PidImage) {
      this.pidImageStateEnum = value;
      this.isPidStatusChanged = true;
    } else if (fileEnum == ekycFileNameEnum.PidWithPersonImage) {
      this.pidWithPersonImageStateEnum = value;
      this.isPidWithPersonStatusChanged = true;
    } else if (fileEnum == ekycFileNameEnum.BookBankImage) {
      this.bookBankImageStateEnum = value;
      this.isBookBankImageStatusChanged = true;
    }
  }

  confirmFileStatusChange(fileEnum: string, value: number) {
    this.onFileStatusChange(fileEnum, value);
  }

  private patchFileStatusEnum(
    filename: string,
    status: number | string
  ): Promise<void> {
    let statusPath: string;

    if (typeof status == 'string') {
      status = parseInt(status);
    }

    console.log(filename, status);

    if (status === this.stateEnum.Verified) {
      statusPath = 'approve';
    } else if (status === this.stateEnum.Rejected) {
      statusPath = 'reject';
    } else if (status === this.stateEnum.WaitForVerify) {
      statusPath = 'waitforverify';
    }

    if (!statusPath) {
      return;
    }

    return new Promise((resolve, rejects) => {
      this._apiService
        .patch(`ekyc/${statusPath}/${filename}/customer/${this.customerId}`)
        .subscribe(
          (res: CommonResponse<any>) => {
            resolve();
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  onBankStatusChange(value) {
    this.bookBankDetailStateEnum = parseInt(value);
    this.isBookBankDetailStatusChanged = true;
  }
}
