import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SettingsApiService {
  private apiBaseUrl: string;
  private apiPath: string;
  private httpHeaders: HttpHeaders;

  get API_URL() {
    return this.apiBaseUrl;
  }

  get API_HEADERS() {
    return this.httpHeaders;
  }

  constructor(private http: HttpClient, private _apiService: ApiService) {
    this.apiBaseUrl = this._apiService.API_URL;
    this.httpHeaders = this._apiService.API_HEADERS;
  }

  GetGeneralSettings(): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/Setting/General`;
    return this.http
      .get(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  UpdateGeneralSettings(body: any): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/Setting/General`;
    return this.http
      .put(API_URL, body, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  UpdateBankForDocumentSettings(body: any): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/Setting/BankAccount/UseForDocument`;
    return this.http
      .put(API_URL, body, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }
}
