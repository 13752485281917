import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DocumentStatus } from 'app/main/model/DocumentStatus';

@Component({
  selector: 'app-document-status-badge',
  templateUrl: './document-status-badge.component.html',
  styleUrls: ['./document-status-badge.component.scss'],
})
export class DocumentStatusBadgeComponent implements OnInit, OnChanges {
  @Input() status: number;
  @Input() isDelete: boolean;

  cssClass: string;
  label: string;

  constructor() { }

  displayStatusOn(status: number, isDelete: boolean) {
    switch (status) {
      case DocumentStatus.Draft:
        this.cssClass = 'badge-light-info';
        this.label = 'Status.Draft';
        break;
      case DocumentStatus.AwaitApproval:
        this.cssClass = 'badge-light-dark';
        this.label = 'Status.AwaitApproval';
        break;
      case DocumentStatus.Accepted:
        this.cssClass = 'badge-light-success';
        this.label = 'Status.Accepted';
        break;
      case DocumentStatus.AwaitPayment:
        this.cssClass = 'badge-light-warning';
        this.label = 'Status.AwaitPayment';
        break;
      case DocumentStatus.Expired:
        this.cssClass = 'badge-light-dark';
        this.label = 'Status.Expired';
        break;
      case DocumentStatus.Overdue:
        this.cssClass = 'badge-light-dark';
        this.label = 'Status.Overdue';
        break;
      case DocumentStatus.Paid:
        this.cssClass = 'badge-light-dark';
        this.label = 'Status.Paid';
        break;
      case DocumentStatus.Voided:
        this.cssClass = 'badge-light-danger';
        this.label = 'Status.Voided';
        break;
      case DocumentStatus.Cancel:
        this.cssClass = 'badge-light-danger';
        this.label = 'Status.Cancel';
        break;
      case DocumentStatus.GotReceipt:
        this.cssClass = 'badge-light-success';
        this.label = 'Status.GotReceipt';
        break;
      default:
        this.label = '-';
        break;
    }
  }

  ngOnInit(): void {
    this.displayStatusOn(this.status, this.isDelete);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.displayStatusOn(this.status, this.isDelete);
  }
}
