import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupFormpageComponent } from './group-formpage/group-formpage.component';
import { GroupComponent } from './group.component';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '../../../components/components.module';
import { ContentHeaderModule } from '../../../../layout/components/content-header/content-header.module';
import { AuthGuard } from '../../../../auth/helpers';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSummernoteModule } from 'ngx-summernote';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';
import { environment } from 'environments/environment';

const routes = !environment.menuList.itemManage ? [] : [
  {
    path: 'item-manage/group',
    component: GroupComponent,
    canActivate: [AuthGuard],
    data: { animation: 'group-manage' },
  },
  {
    path: 'item-manage/group/create',
    component: GroupFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'group-create' },
  },
  {
    path: 'item-manage/group/edit/:id',
    component: GroupFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'group-edit' },
  },
];

@NgModule({
  declarations: [GroupComponent, GroupFormpageComponent],
  imports: [
    RouterModule.forChild(routes),
    ComponentsModule,
    ContentHeaderModule,
    CoreCommonModule,
    CommonModule,
    CorePipesModule,
    TranslateModule,
    NgxDropzoneModule,
    NgxSummernoteModule,
    FormsModule,
    ReactiveFormsModule,
    CoreTouchspinModule,
    NgbPaginationModule,
  ],
  exports: [],
})
export class GroupModule {}
