<div class="d-flex flex-column" style="height: 100%">
  <div class="modal-header">
    <h5 *ngIf="isBranchData" class="modal-title" id="exampleModalCenterTitle">
      {{
        isEditing
          ? ("Branch.Modal.EditBranch" | translate)
          : ("Branch.Modal.CreateBranch" | translate)
      }}
    </h5>
    <button
      type="button"
      class="close"
      (click)="closeModal()"
      aria-label="Close"
    >
      <span aria-hidden="true">
        <i data-feather="x" size="22"></i>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <app-branch-manage-form
      (callBackFormData)="receiveFormData($event)"
      [isSubmit]="isSubmit"
      [editData]="editData"
      [isEditing]="isEditing"
    ></app-branch-manage-form>
  </div>
  <div class="modal-footer justify-content-between">
    <button
      type="button"
      class="btn btn-secondary m-0"
      (click)="openCancelModal()"
      rippleEffect
    >
      {{ "Form.Cancel" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary m-0"
      (click)="openConfirmModal()"
      rippleEffect
    >
      {{ "Form.Confirm" | translate }}
    </button>
  </div>
</div>

