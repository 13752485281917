import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonResponse } from 'app/main/model/CommonResponse';
import Customer from 'app/main/model/Customer';
import { ApiService } from 'app/main/service/api.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-member-detail',
  templateUrl: './member-detail.component.html',
  styleUrls: ['./member-detail.component.scss'],
})
export class MemberDetailComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  contentHeader: object;
  currentLang: string;

  customerObj: Customer;
  customerId: string;

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'range',
  };

  constructor(
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _apiService: ApiService
  ) {
    this.currentLang = this._translateService.currentLang;

    this._route.paramMap.subscribe((val) => {
      this.customerId = this._route.snapshot.paramMap.get('id');
    });

    this.getMemberDetail();
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Member Data Trackings',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getMemberDetail(): Promise<Customer> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetDataById('Customer', this.customerId)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<Customer>) => {
            this.customerObj = res.data.resultData[0];
            resolve(this.customerObj);
          },
          (err) => {
            rejects();
          }
        );
    });
  }
}
