<div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
    <app-loading></app-loading>
</div>

<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <a [routerLink]="[pathUrl]">
                    <i data-feather="arrow-left" class="text-primary cursor-pointer back-to-manage-btn"></i>
                    {{ 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroethosome' | translate }}
                </a>

                <ul ngbNav class="nav nav-pills">
                    <li ngbNavItem *ngFor="let lang of langList">
                        <a ngbNavLink (click)="selectedLanguage(lang)" [class.active]="selectedLang === lang">{{ lang |
                            uppercase }}</a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <app-hydroethosome-highlight-form *ngIf="!isLoading" [callbackUrl]="'/cms/repove-tools/technology/hydroethosome/highlight-tech'" 
                [selectedLang]="selectedLang" [isUseMultipleLang]="true"></app-hydroethosome-highlight-form>
            </div>
        </div>
    </div>
</div>