class CMSSignIn {
  id: number;
  bannerNewsDescription: string;
  isActive?: boolean;
  isDelete: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  deleteBy: string;
  deleteDate: string;
}

export default CMSSignIn;
