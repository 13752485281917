import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { EkycDetailComponent } from './ekyc-detail/ekyc-detail.component';
import { getTimeZone } from '@amcharts/amcharts5/.internal/core/util/Utils';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customer-ekyc',
  templateUrl: './customer-ekyc.component.html',
  styleUrls: ['./customer-ekyc.component.scss'],
})
export class CustomerEkycComponent implements OnInit, AfterViewChecked {
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewChecked(): void {
   
  }
}


