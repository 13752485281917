<section>
  <p class="text-primary">{{'EKYC.FileHistory' | translate }}</p>
  <table class="table">
    <thead>
      <tr>
        <th class="text-center">#</th>
        <th>{{ 'EKYC.Table.Detail' | translate }}</th>
        <th>{{ 'EKYC.Table.File' | translate }}</th>
        <th>{{ 'General.Status' | translate }}</th>
        <th>{{ 'EKYC.Table.UpdatedBy' | translate }}</th>
        <th>{{ 'EKYC.Table.Date' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="w-100" *ngIf="isLoading">
        <td class="text-center" *ngFor="let num of [].constructor(5)">
          <div class="skeleton-loading"></div>
        </td>
      </tr>

      <tr *ngIf="fileDetailList.length < 1">
        <td colspan="100%" class="text-center">
          <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
        </td>
      </tr>

      <tr
        *ngFor="let fileDetail of fileDetailList | slice: (pageFile * pageFileSize) - pageFileSize : pageFile * pageFileSize; let ind = index">
        <td class="text-center">{{ (pageFile - 1) * pageFileSize + ind + 1 }}</td>

        <td>{{ getVerifyTypeLabel(fileDetail.verifyTypeEnum) }}</td>

        <td>
          <a [href]="getImage(fileDetail.fileId)" target="_blank">
            <!-- <img [src]="fileDetail.fileId" class="image-evidence" alt=""> -->
            Show Image
          </a>
        </td>
        <td [ngClass]="{'text-success' : fileDetail.verifyStatusEnum == stateEnum.Approved,
        'text-warning' : fileDetail.verifyStatusEnum == stateEnum.WaitApprove,
        'text-danger' : fileDetail.verifyStatusEnum == stateEnum.Reject }">
          {{ getStatusLabel(fileDetail.verifyStatusEnum) }}
        </td>
        <td>{{ fileDetail.createBy }}</td>
        <td>
          <div class="text-dark">
            <span *ngIf="currentLang == 'th'">
              {{ fileDetail.createDate | thaidate : 'DD MMM YYYY' }}</span>
            <span *ngIf="currentLang == 'en'">
              {{ fileDetail.createDate | date : 'dd MMM YYYY' }}
            </span>

          </div>
          <small>{{ fileDetail.createDate | date : 'HH:mm' }} <span *ngIf="currentLang == 'th'">น.</span></small>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-end mt-2">
    <ngb-pagination [collectionSize]="fileDetailList.length" [(page)]="pageFile" [pageSize]="pageFileSize"
      aria-label="Custom pagination">
      <ng-template ngbPaginationFirst>First</ng-template>
      <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
          [class]="'font-weight-bolder'"></span>Prev
      </ng-template>
      <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
      </ng-template>
      <ng-template ngbPaginationLast>Last</ng-template>
    </ngb-pagination>
  </div>

  <p class="text-primary mt-3">{{'EKYC.BankAccount' | translate }}</p>
  <table class="table">
    <thead>
      <tr>
        <th class="text-center">#</th>
        <th>{{ 'EKYC.Table.BankAccountNo' | translate }}</th>
        <th>{{ 'EKYC.Table.BankAccountName' | translate }}</th>
        <th>{{ 'EKYC.Table.BankName' | translate }}</th>
        <th>{{ 'General.Status' | translate }}</th>
        <th>{{ 'EKYC.Table.Date' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="w-100" *ngIf="isLoading">
        <td class="text-center" *ngFor="let num of [].constructor(5)">
          <div class="skeleton-loading"></div>
        </td>
      </tr>

      <tr *ngIf="bankAccountList.length < 1">
        <td colspan="100%" class="text-center">
          <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
        </td>
      </tr>

      <tr
        *ngFor="let bankAccount of bankAccountList | slice: (pageBank * pageBankSize) - pageBankSize : pageBank * pageBankSize;  let ind = index ">
        <td class="text-center">{{ (pageBank - 1) * pageBankSize + ind + 1 }}</td>
        <td>{{ bankAccount.bankAccountNo }}</td>
        <td>{{ bankAccount.bankAccountName }}</td>
        <td>{{ bankAccount.bankName }}</td>
        <td [ngClass]="{'text-success' : bankAccount.verifyStatusEnum == stateEnum.Approved,
        'text-warning' : bankAccount.verifyStatusEnum == stateEnum.WaitApprove,
        'text-danger' : bankAccount.verifyStatusEnum == stateEnum.Reject }">
          {{ getStatusLabel(bankAccount.verifyStatusEnum) }}
        </td>
        <td>
          <div class="text-dark">
            <span *ngIf="currentLang == 'th'">
              {{ bankAccount.createDate | thaidate : 'DD MMM YYYY' }}</span>
            <span *ngIf="currentLang == 'en'">
              {{ bankAccount.createDate | date : 'dd MMM YYYY' }}
            </span>

          </div>
          <small>{{ bankAccount.createDate | date : 'HH:mm' }} <span *ngIf="currentLang == 'th'">น.</span></small>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-end mt-2">
    <ngb-pagination [collectionSize]="bankAccountList.length" [(page)]="pageBank" [pageSize]="pageBankSize"
      aria-label="Custom pagination">
      <ng-template ngbPaginationFirst>First</ng-template>
      <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
          [class]="'font-weight-bolder'"></span>Prev
      </ng-template>
      <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
      </ng-template>
      <ng-template ngbPaginationLast>Last</ng-template>
    </ngb-pagination>
  </div>

</section>