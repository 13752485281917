import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentService } from 'app/main/pages/document/document.service';
import { ApiService } from 'app/main/service/api.service';
import { DocumentApiService } from 'app/main/service/document-api.service';
import Stepper from 'bs-stepper';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-select-credit-debit',
  templateUrl: './select-credit-debit.component.html',
  styleUrls: ['./select-credit-debit.component.scss'],
})
export class SelectCreditDebitComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();

  @Input() titleDoc: string;
  @Input() apiPath: string;
  @Output() creditDebitDataEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() getDataCallback: EventEmitter<any> = new EventEmitter<any>();
  @Output() formValidityChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  debitNoteStepper: Stepper;
  contentHeader: object;
  invoiceApiPath: string;

  invoiceDocList: any[];

  docObj: any = {};

  selectedCustomer: any;
  // selectedDocType: any;
  selectedDocCode: any;

  selectedCustomerControl: FormControl;
  // selectedDocTypeControl: FormControl;
  selectedDocCodeControl: FormControl;

  formGroup: FormGroup;

  customerList: any[] = [];

  loadingCustomerList: boolean = false;
  loadingDocCodeList: boolean = false;
  // docTypeList: any[] = [
  //   {
  //     label: "Invoice",
  //     value: "invoice"
  //   },
  //   {
  //     label: "Receipt",
  //     value: "receipt"
  //   }
  // ]

  docCodeList: any[] = [];

  constructor(
    private _apiService: ApiService,
    private _documentApiService: DocumentApiService,
    private _documentService: DocumentService
  ) {}

  ngOnInit(): void {
    this.getCustomerList();

    this.selectedCustomerControl = new FormControl('', Validators.required);
    // this.selectedDocTypeControl = new FormControl('', Validators.required);
    this.selectedDocCodeControl = new FormControl('', Validators.required);

    this.formGroup = new FormGroup({
      selectedCustomer: this.selectedCustomerControl,
      // selectedDocType: this.selectedDocTypeControl,
      selectedDocCode: this.selectedDocCodeControl,
    });

    this.formGroup.valueChanges.subscribe(() => {
      this.formValidityChanged.emit(this.formGroup.valid);
    });
  }

  onCustomerSelectionChange() {
    this.resetDocCodeList();
    // if (this.selectedCustomer && this.selectedDocType) {
    //   this.getDocCodeList();
    // }
    if (this.selectedCustomer) {
      this.getDocCodeList();
    }
  }

  // onDocTypeSelectionChange() {
  //   this.resetDocCodeList();
  //   if (this.selectedCustomer && this.selectedDocType) {
  //     this.getDocCodeList();
  //   }
  // }

  onDocCodeSelectionChange() {}

  resetDocCodeList() {
    this.docCodeList = [];
    this.selectedDocCode = null;
  }

  isValid(): boolean {
    // return this.selectedCustomer && this.selectedDocType && this.selectedDocCode;
    return this.selectedCustomer && this.selectedDocCode;
  }

  getCustomerList() {
    let param = {
      IsActive: true,
      IsDelete: false,
      SortPath: 'detail',
    };
    this.loadingCustomerList = true;
    this._apiService
      .GetAllData('customer', param)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.customerList = res.data.resultData;
        this.loadingCustomerList = false;
      });
  }

  getDocCodeList() {
    this.loadingDocCodeList = true;
    if (this.apiPath === 'creditnote') {
      let param = {
        IsActive: true,
        IsDelete: false,
        IsVoid: false,
        IsCreditNoteCreatable: true,
        CustomerId: this.selectedCustomer,
        SortPath: 'invoiceCode',
        Direction: 1
      };
      this._apiService
        .GetAllData('invoice', param)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          this.docCodeList = res.data.resultData.map(
            (docCodeList: { [x: string]: any }) => {
              return {
                docCode: docCodeList.invoiceCode,
                docCodeId: docCodeList.id,
              };
            }
          );
          this.loadingDocCodeList = false;
        });
    } else {
      let param = {
        IsActive: true,
        IsDelete: false,
        IsVoid: false,
        IsDebitNoteCreatable: true,
        CustomerId: this.selectedCustomer,
        SortPath: 'invoiceCode',
        Direction: 1
      };
      this._apiService
        .GetAllData('invoice', param)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          this.docCodeList = res.data.resultData.map(
            (docCodeList: { [x: string]: any }) => {
              return {
                docCode: docCodeList.invoiceCode,
                docCodeId: docCodeList.id,
              };
            }
          );
          this.loadingDocCodeList = false;
        });
    }
  }

  mergeDataIntoDocObj() {
    const docObj = {
      customerId: this.selectedCustomer,
      docType: 'invoice',
      docCode: this.selectedDocCode,
    };

    return docObj;
  }

  getData(): any {
    const data = this.mergeDataIntoDocObj();
    this.getDataCallback.emit(data);
    // console.log(data);
    return data;
  }
}
