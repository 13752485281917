import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import Category from 'app/main/model/Category';
import Class from 'app/main/model/Class';
import SubCategory from 'app/main/model/SubCategory';
import SubClass from 'app/main/model/SubClass';
import { ApiService } from 'app/main/service/api.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import moment from 'moment';
import { Group } from 'ng2-dragula';
import { FlatpickrOptions } from 'ng2-flatpickr';

@Component({
  selector: 'app-search-panel-document',
  templateUrl: './search-panel-document.component.html',
  styleUrls: ['./search-panel-document.component.scss'],
})
export class SearchPanelDocumentComponent implements OnInit {
  enumShowMode = {
    Child: 'child',
    Item: 'item',
    Content: 'content',
  };

  searchText: string;
  searchName: string;
  searchId: string;
  activeStatus: string;

  activeTab: string = 'all';
  state: any;
  transactionStatusId: number;

  currentDate: Date = new Date();
  nextWeekDate: Date = new Date();

  groupId: any = 'All';
  categoryId: any = 'All';
  subCategoryId: any = 'All';
  classId: any = 'All';
  subClassId: any = 'All';

  groupList: Group[] = [];
  categoryList: Category[] = [];
  subCategoryList: SubCategory[] = [];
  classList: Class[] = [];
  subClassList: SubClass[] = [];

  @Input() pathUrl: string;
  @Input() isGridView: boolean;
  @Input() forCompanentName: string;
  @Input() isChildItemView: boolean;
  @Input() isContentView: boolean;
  @Input() isForGroupTemplate: boolean;
  @Input() isShowChildList: boolean;
  @Input() showMode: string;
  @Input() tableCode: string;
  @Input() isShowSortByGroup: boolean = false;
  @Input() notShowSwitchView: boolean = false;
  @Input() notForContentMode: boolean = false;

  @Input() isShowGroup: boolean;
  @Input() isShowCategory: boolean;
  @Input() isShowSubCategory: boolean;
  @Input() isShowClass: boolean;
  @Input() isShowSubClass: boolean;

  @Input() loadAllGroupData: boolean;
  @Input() loadAllCategoryData: boolean;
  @Input() loadAllSubCategoryData: boolean;
  @Input() loadAllClassData: boolean;
  @Input() loadAllSubClassData: boolean;

  @Input() statusList: any[] = [];

  @Output('SearchFilter') searchFilterFunc: EventEmitter<any> =
    new EventEmitter();
  @Output('ActiveStatusFilter') activeStatusFilterFunc: EventEmitter<any> =
    new EventEmitter();
  @Output('SortFilter') sortFilterFunc: EventEmitter<any> = new EventEmitter();

  @Output('childListView') childListViewFunc: EventEmitter<any> =
    new EventEmitter();
  @Output('itemListView') itemListViewFunc: EventEmitter<any> =
    new EventEmitter();
  @Output('contentView') contentViewFunc: EventEmitter<any> =
    new EventEmitter();

  @Output('listView') listViewFunc: EventEmitter<any> = new EventEmitter();
  @Output('gridView') gridViewFunc: EventEmitter<any> = new EventEmitter();
  @Output('SetLoading') setLoadingFunc: EventEmitter<any> = new EventEmitter();
  @Output('exportXLSX') exportXLSXFunc: EventEmitter<any> = new EventEmitter();
  @Output('setPageSize') setPageSizeFunc: EventEmitter<any> =
    new EventEmitter();
  @Output('callDataFromAPI') callDataFromAPIFunc: EventEmitter<any> =
    new EventEmitter();

  @ViewChild('searchname') searchNameFil: ElementRef;
  @ViewChild('searchid') searchIdFil: ElementRef;
  @ViewChild('searchpricefrom') searchPriceFrom: ElementRef;
  @ViewChild('searchpriceto') searchPriceTo: ElementRef;
  @ViewChild('createDate') createDate: any;
  @ViewChild('expriedDate') expriedDate: any;

  @ViewChild('activeFil') activeFil: ElementRef;
  @ViewChild('sortFil') sortFil: ElementRef;

  @ViewChild('selectGroup') selectGroup: ElementRef;
  @ViewChild('selectCategory') selectCategory: ElementRef;
  @ViewChild('selectSubCategory') selectSubCategory: ElementRef;
  @ViewChild('selectClass') selectClass: ElementRef;
  @ViewChild('selectSubClass') selectSubClass: ElementRef;

  constructor(
    private _apiService: ApiService,
    private _globalService: GlobalFuncService
  ) {
    if (this.isShowSortByGroup) {
      this.getGroupData();
    }
  }

  timeout: any = null;
  isTyping: boolean = false;

  ngOnInit(): void {
    if (this.loadAllCategoryData) {
      this.getCategoryData();
    }

    if (this.loadAllSubCategoryData) {
      this.getSubCategoryData();
    }

    if (this.loadAllClassData) {
      this.getClassData();
    }

    if (this.loadAllSubClassData) {
      this.getSubClassData();
    }
  }

  getDatabyState(state, tab: string) {
    var self = this;
    this.activeTab = tab;
    this.state = state;
    this.transactionStatusId = state.TransactionStatusId;

    console.log(state);
    this.ResetSearch();
  }

  ResetSearch(): void {
    console.log(this.pathUrl);
    this.setLoadingFunc.emit();

    //inputsearch reset
    this.searchNameFil.nativeElement.value = '';
    this.searchIdFil.nativeElement.value = '';
    this.searchPriceFrom.nativeElement.value = '';
    this.searchPriceTo.nativeElement.value = '';
    this.createDate.flatpickr?.setDate(null);
    this.expriedDate.flatpickr?.setDate(null);

    this.resetGroup();
    this.resetCategory();
    this.resetSubCategory();
    this.resetClass();
    this.resetSubClass();

    console.log(this.state);
    this.callDataFromAPIFunc.emit(this.state);
  }

  Submit(): void {
    this.setLoadingFunc.emit();

    let params: any = {};
    if (this.groupId != 'All') {
      params.groupId = this.groupId;
    }

    if (this.categoryId != 'All') {
      params.categoryId = this.categoryId;
    }

    if (this.subCategoryId != 'All') {
      params.subCategoryId = this.subCategoryId;
    }

    if (this.classId != 'All') {
      params.classId = this.classId;
    }

    if (this.subClassId != 'All') {
      params.subClassId = this.subClassId;
    }

    const CustomerName = this.searchNameFil.nativeElement.value || '';

    if (CustomerName) {
      params.CustomerName = CustomerName;
    }

    const codePropertyName = this.tableCode + 'Code';
    const codeValue = this.searchIdFil.nativeElement.value || '';
    if (codeValue) {
      params[codePropertyName] = codeValue;
    }

    const GrandTotalFrom = this.searchPriceFrom.nativeElement.value
      ? parseFloat(this.searchPriceFrom.nativeElement.value.replace(/,/g, ''))
      : '';
    const GrandTotalTo = this.searchPriceTo.nativeElement.value
      ? parseFloat(this.searchPriceTo.nativeElement.value.replace(/,/g, ''))
      : '';
    if (GrandTotalFrom) {
      params.GrandTotalFrom = GrandTotalFrom;
    }
    if (GrandTotalTo) {
      params.GrandTotalTo = GrandTotalTo;
    }

    const createDateValue: Date = this.createDate.flatpickr?.selectedDates[0];
    const expriedDateValue: Date = this.expriedDate.flatpickr?.selectedDates[0];

    if (createDateValue) {
      let utcStartDate = moment(createDateValue).startOf('day').toISOString();
      params.DocumentCreateDateFrom = utcStartDate;
    }

    if (expriedDateValue) {
      let utcEndDate = moment(expriedDateValue).endOf('day').toISOString();
      params.DocumentCreateDateTo = utcEndDate;
    }

    if (this.transactionStatusId) {
      params.transactionStatusId = this.transactionStatusId;
    }

    // const obj = {
    //   params: params,
    // };

    this.callDataFromAPIFunc.emit(params);
  }

  createDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
  };

  expiredDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
  };

  DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'range',
    dateFormat: 'd/m/Y',
    enableTime: false,
  };

  generateOptionValue(SortPath, Direction): string {
    const value = {
      SortPath: SortPath,
      Direction: Direction,
      isDelete: false,
    };

    return JSON.stringify(value);
  }

  formatToMoney(elementId: string, value: string) {
    const inputElement = document.getElementById(elementId) as HTMLInputElement;
    if (inputElement) {
      inputElement.value = this._globalService.FormatToMoney(
        this._globalService.MoneyFormatToNumber(value)
      );
    }
  }

  onDateFromChange(event) {
    //console.log(this.quotationForm.controls.documentCreateDateFrom.value);
    // let dateform = this.quotationForm.controls.documentCreateDateFrom.value;
    this.expriedDate.flatpickr.set({
      minDate: this.createDate.flatpickr?.selectedDates[0],
    });
  }

  ExportXLSX(): void {
    this.exportXLSXFunc.emit();
  }

  childListView(): void {
    this.childListViewFunc.emit();
  }

  itemListView(): void {
    this.itemListViewFunc.emit();
  }

  contentView(): void {
    this.contentViewFunc.emit();
  }

  listView(): void {
    this.listViewFunc.emit();
  }

  gridView(): void {
    this.gridViewFunc.emit();
  }

  getGroupData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('group', { isDelete: false })
        .subscribe((res) => {
          self.groupList = res.data.resultData;
        });
    });
  }

  resetGroup(): void {
    this.groupId = 'All';
  }

  resetCategory(): void {
    this.categoryId = 'All';
    this.categoryList = [];
  }

  resetSubCategory(): void {
    this.subCategoryId = 'All';
    this.subCategoryList = [];
  }

  resetClass(): void {
    this.classId = 'All';
    this.classList = [];
  }

  resetSubClass(): void {
    this.subClassId = 'All';
    this.subClassList = [];
  }

  getCategoryData(selectedGroupId?): void {
    var self = this;
    var params: { [k: string]: any } = {};

    this.resetCategory();
    this.resetSubCategory();
    this.resetClass();
    this.resetSubClass();

    if (selectedGroupId) {
      params.groupId = selectedGroupId;
    }
    params.isDelete = false;

    this._apiService.GetAllData('category', params).subscribe((res) => {
      self.categoryList = res.data.resultData;
    });
  }

  getSubCategoryData(selectedCategoryId?): void {
    var self = this;
    var params: { [k: string]: any } = {};

    this.resetSubCategory();
    this.resetClass();
    this.resetSubClass();

    if (selectedCategoryId) {
      params.categoryId = selectedCategoryId;
    }
    params.isDelete = false;

    this._apiService.GetAllData('subcategory', params).subscribe((res) => {
      self.subCategoryList = res.data.resultData;
    });
  }

  getClassData(selectedSubCategoryId?): void {
    var self = this;
    var params: { [k: string]: any } = {};

    this.resetClass();
    this.resetSubClass();

    if (selectedSubCategoryId) {
      params.subCategoryId = selectedSubCategoryId;
    }

    params.isDelete = false;

    this._apiService.GetAllData('class', params).subscribe((res) => {
      self.classList = res.data.resultData;
    });
  }

  getSubClassData(selectedClassId?): void {
    var self = this;
    var params: { [k: string]: any } = {};

    this.resetSubClass();

    if (selectedClassId) {
      params.classId = selectedClassId;
    }
    params.isDelete = false;

    this._apiService.GetAllData('subclass', params).subscribe((res) => {
      self.subClassList = res.data.resultData;
    });
  }
}
