import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-hydropartisome',
  templateUrl: './hydropartisome.component.html',
  styleUrls: ['./hydropartisome.component.scss']
})
export class HydropartisomeComponent implements OnInit {

  config: ICMSConfig = {
    apiPath: 'Hydropartisome',
    tableName: 'Content',
    pathUrl: 'cms/repove-tools/technology/hydropartisome',
    model: new Object(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.Title',
    formConfig: {
      bannerTitle: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Banner Title',

        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.BannerTitle',
      },
      bannerDescription: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Banner Description',

        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.BannerDescription',
      },
      bannerImage: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Banner Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.BannerImage',
      },

      technologyText0: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Paragraph Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.TechParagraph1',
      },
      technologyText1: {
        type: FormType.RichText,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Paragraph one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.TechParagraph2',
      },
      technologyText2: {
        type: FormType.RichText,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Paragraph two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.TechParagraph3',
      },
      technologyText3: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Paragraph three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.TechParagraph4',
      },

      substance1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Chemical one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.Substance1',
      },
      substance2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Chemical two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.Substance2',
      },
      substance3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Chemical three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.Substance3',
      },


      exampleImage: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.Example',
      },


      propertyIcon1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Property Icon one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.PropertyIcon1',
      },
      propertyDetail1: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Property Detail one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.PropertyDetail1',
      },
      propertyIcon2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Property Icon two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.PropertyIcon2',
      },
      propertyDetail2: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Property Detail two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.PropertyDetail2',
      },
      propertyIcon3: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Property Icon three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.PropertyIcon3',
      },
      propertyDetail3: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Property Detail three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.PropertyDetail3',
      },
      propertyIcon4: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Property Icon four',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.PropertyIcon4',
      },
      propertyDetail4: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Property Detail four',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.PropertyDetail4',
      },
      propertyIcon5: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Property Icon five',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.PropertyIcon5',
      },
      propertyDetail5: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Property Detail five',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydropartisome.PropertyDetail5',
      },
    }
  }

  constructor() { }

  ngOnInit(): void {
  }
}
