import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OurTechnologyComponent } from './our-technology.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { TranslateModule } from '@ngx-translate/core';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSummernoteModule } from 'ngx-summernote';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { ComponentsModule } from 'app/main/components/components.module';

const routes: Routes = [
  {
    path: 'cms/our-technology',
    component: OurTechnologyComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-our-technology' },
  },
];

@NgModule({
  declarations: [
    OurTechnologyComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ContentHeaderModule,
    FormsModule,
    NgxSummernoteModule,
    ReactiveFormsModule,
    CorePipesModule,
    ComponentsModule,
    RouterModule.forChild(routes),
  ]
})
export class OurTechnologyModule { }
