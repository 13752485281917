import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Event from 'app/main/model/Event';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  config: ICMSConfig = {
    apiPath: 'event',
    pathUrl: '/cms/event',
    tableName: 'Content',
    model: new Event(),
    isList: true,
    componentName: 'Event',
    formConfig: {
      name: {
        type: FormType.Text,
        validator: [Validators.required],
      },
      title: {
        type: FormType.Text,
        validator: [Validators.required],
      },
      subTitle: {
        type: FormType.Textarea,
        validator: [Validators.required],
      },
      detail: {
        type: FormType.RichText,
        validator: [Validators.required],
      },
      dateFrom: {
        type: FormType.Datepicker,
        validator: [Validators.required],
        label: 'Date From',
      },
      dateTo: {
        type: FormType.Datepicker,
        validator: [Validators.required],
        label: 'Date To',
      },
      contentDate: {
        type: FormType.Datepicker,
        validator: [Validators.required],
        label: 'Content Date',
      },
      linkBtnCaption: {
        type: FormType.Text,
        label: 'Button Caption',
        description: 'Text that show on button',
      },
      linkBtnUrl: {
        type: FormType.Text,
        label: 'Button URL',
        description: 'URL that linked when press button',
      },
      isActive: {
        type: FormType.Radio,
        radioList: [
          { label: 'Active', translate: 'Status.Active', value: true },
          { label: 'Inactive', translate: 'Status.Inactive', value: false },
        ],
        label: 'Active Status',
        labelTranslate: 'Informative.ActiveStatus',
      },
    },
  };

  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'name';

  constructor() {}
}
