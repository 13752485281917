<div class="content-wrapper container-xxl p-0">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div class="content-body">
        <div class="card">
            <div class="card-body">

                <hr>
                <div class="d-flex justify-content-center mb-1">
                    <div class="col-2">
                        <label for="">{{ 'General.Status' | translate }}</label>
                        <select class="custom-select" [(ngModel)]="searchByStatus">
                            <option [ngValue]="3">{{ 'General.All' | translate }}</option>
                            <option [ngValue]="0">{{'BookingCalendar.Status.WaitApprove' | translate }}</option>
                            <option [ngValue]="1">{{ 'BookingCalendar.Status.Approved' | translate }}</option>
                            <option [ngValue]="2">{{ 'BookingCalendar.Status.Reject' | translate }}</option>
                        </select>
                    </div>
                    <div class="col-2">
                        <label for="">{{ 'General.SearchBy' | translate }}</label>
                        <select class="custom-select" [(ngModel)]="searchByEventName">
                            <option [ngValue]="0">{{ 'ViewTable.CustomerName' | translate }}</option>
                            <option [ngValue]="1">{{ 'ViewTable.EventName' | translate }}</option>
                            <option [ngValue]="2">{{ 'ViewTable.CustomerCode' | translate }}</option>
                        </select>
                    </div>
                    <div class="col-5">
                        <label for="">{{ 'General.SearchKeyword' | translate }}</label>
                        <input class="form-control mr-1" type="text" [(ngModel)]="searchText">
                    </div>
                </div>

                <div class="d-flex justify-content-center my-2">
                    <button class="btn btn-secondary mr-1" [disabled]="isLoading" (click)="Reset()">
                        <i data-feather='refresh-ccw' class="mr-1"></i>
                        {{ "General.Reset" | translate }}
                    </button>
                    <button class="btn btn-primary mr-1" [disabled]="isLoading" (click)="Search()">
                        <i data-feather="search" class="mr-1"></i>
                        {{ "General.Search" | translate }}
                    </button>

                </div>

                <hr>

                <table class="table" id="eventTable" datatable [dtOptions]="BookingdtOptions">
                    <thead>
                        <tr>
                            <th class="text-center" width="8%">
                                #
                            </th>
                            <th width="30%">{{"BookingCalendar.Approval.Event"| translate}}</th>
                            <th width="15%">{{"BookingCalendar.Approval.Remarks"| translate}}</th>
                            <th width="15%">{{"BookingCalendar.Approval.Time"| translate}}</th>
                            <th width="15%">{{"BookingCalendar.Approval.Customer"| translate}}</th>
                            <th width="15%">{{"BookingCalendar.Approval.Status"| translate}}</th>
                            <th width="15%">{{"BookingCalendar.Approval.Action"| translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="w-100" *ngIf="isLoading">
                            <td class="text-center" *ngFor="let num of [].constructor(7)">
                                <div class="skeleton-loading"></div>
                            </td>
                        </tr>

                        <tr *ngIf="bookingData.length < 1 && !isLoading">
                            <td colspan="100%" class="text-center">
                                <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
                            </td>
                        </tr>

                        <tr *ngFor="let booking of bookingData; index as ind">
                            <td>
                                <div class="text-center">
                                    {{ (page - 1) * pageLength + ind + 1 }}
                                </div>
                            </td>
                            <td>
                                <div class="text-primary">{{ booking.topic }}</div>
                                <small class="">{{ getBranchName(booking.branchId) }}</small>
                            </td>
                            <td>
                                <div class="text-truncate pr-2 " ngbTooltip="{{ booking.remarks }}">
                                    {{ booking.remarks ? booking.remarks : '-' }}
                                </div>
                            </td>
                            <td>

                                <div class="text-dark">
                                    <span *ngIf="currentLang == 'th'">
                                        {{ booking.startDate | thaidate : 'DD MMM YYYY' }}</span>
                                    <span *ngIf="currentLang == 'en'">
                                        {{ booking.startDate | date : 'dd MMM YYYY' }}
                                    </span>

                                </div>
                                <small [ngClass]="{'text-danger' : CheckIsOvertime(booking.startDate)}">{{
                                    booking.startDate | date : 'HH:mm' }} <span *ngIf="currentLang == 'th'">น.</span> -
                                    {{ booking.endDate | date : 'HH:mm'
                                    }} <span *ngIf="currentLang == 'th'">น.</span></small>

                            </td>
                            <td>
                                <a [routerLink]="['/customer/detail/' + booking.customerId]" target="_blank"
                                    ngbTooltip="{{ booking.customerName }}">
                                    <span class="text-primary text-truncate"> {{ booking.customerName }}</span> <br>
                                    <small> <span class="text-dark">Code:</span> {{booking.repoveCode ?
                                        booking.repoveCode : '-'}} </small>

                                </a>
                            </td>
                            <td>
                                <div [ngClass]="{'text-success' : booking.stateEnum == bookingEnum.Approved,
                                'text-warning' : booking.stateEnum == bookingEnum.WaitApprove,
                                'text-danger' : booking.stateEnum == bookingEnum.Reject }">{{
                                    getBookingStatusLabel(booking.stateEnum) }}</div>
                            </td>
                            <td>
                                <div class="d-flex">
                                    <button [disabled]="CheckIsOvertime(booking.startDate)"
                                        [ngClass]="{'btn-success': !CheckIsOvertime(booking.startDate), 'btn-secondary': CheckIsOvertime(booking.startDate)}"
                                        *ngIf="booking.stateEnum == bookingEnum.WaitApprove"
                                        class="btn btn-success mr-1" (click)="openApprovedModal(booking)">
                                        <i data-feather="check"></i>
                                    </button>
                                    <button class="btn btn-warning mr-1" (click)="openEditModal(booking,true)">
                                        <i data-feather="edit"></i>
                                    </button>
                                    <button class="btn btn-danger mr-1" *ngIf="booking.stateEnum != bookingEnum.Reject"
                                        (click)="openRejectedModal(booking)">
                                        <i data-feather="x"></i>
                                    </button>
                                </div>

                                <!-- <div *ngIf="CheckIsOvertime(booking.startDate)">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="mb-0 text-danger mx-2">Overtime</p>
                                        <button class="btn btn-danger mr-1" (click)="openRejectedModal(booking)">
                                            <i data-feather="x"></i>
                                        </button>
                                    </div>
                                </div> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>