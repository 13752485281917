import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  componentName: string = 'Categories';
  apiPath: string = 'HelpCenterDetail';
  pathUrl: string = '/cms/categories';

  constructor() {}
}
