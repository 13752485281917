import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  config: ICMSConfig = {
    apiPath: 'repovetoolbanner',
    tableName: 'Content',
    pathUrl: '/cms/repove-tools/landing',
    model: new Object(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.RepoveTools.Landing.Title',
    formConfig: {
      bannerAboutTitle1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Landing.BannerTitle',
      },
      bannerAboutDescription1: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Banner Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Landing.BannerDescription',
      },
      bannerImg1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Banner Background',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Landing.BannerBackground',
      },

      bannerAboutTitle2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Technology and Products Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Landing.TPBannerTitle',
      },
      bannerAboutDescription2: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Technology and Products Banner Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Landing.TPBannerDescription',
      },
      bannerImg2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Technology and Products Banner Background',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Landing.TPBannerBackground',
      },
      bannerAboutTitle3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Bonus Plan Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Landing.OurCompanyBannerTitle',
      },
      bannerAboutDescription3: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Bonus Plan Banner Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Landing.OurCompanyBannerDescription',
      },
      bannerImg3: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Bonus Plan Banner Background',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Landing.OurCompanyBannerBackground',
      },
      bannerAboutTitle4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Our Company Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Landing.BonusPlanBannerTitle',
      },
      bannerAboutDescription4: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Our Company Banner Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Landing.BonusPlanBannerDescription',
      },
      bannerImg4: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Our Company Banner Background',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Landing.BonusPlanBannerBackground',
      },
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
