export enum Gender {
    Male = 0,
    Female = 1,
    NotSpecified = 2,
}

export const kGender =  [
    {value: Gender.Male, name: 'Male'},
    {value: Gender.Female, name: 'Female'},
    {value: Gender.NotSpecified, name: 'NotSpecified'},
]
  