<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <!-- Left Text-->
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
      <div
        class="w-100 d-lg-flex align-items-center justify-content-center px-5"
      >
        <img
          class="img-fluid"
          style="width: 75%"
          [src]="coreConfig.app.appLogoImage"
          alt="Login V2"
        />
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Forgot password-->
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title font-weight-bold mb-1">Sign Up 🔒</h2>
        <p class="card-text mb-2">Complete field to register</p>

        <form
          class="auth-forgot-password-form mt-2"
          [formGroup]="registerForm"
          (ngSubmit)="Submit()"
        >
          <div class="form-group">
            <label class="form-label" for="login-email">Username</label>
            <input
              type="text"
              formControlName="username"
              class="form-control"
              [ngClass]="{ 'is-invalid': isSubmitted && f.username.errors }"
              placeholder="johndoe1234"
              aria-describedby="login-email"
              autofocus=""
              tabindex="1"
            />
            <div
              *ngIf="isSubmitted && f.username.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': f.username.errors && f.username.touched }"
            >
              <div *ngIf="f.username.errors?.required && f.username.touched">
                Username is required
              </div>
              <div
                *ngIf="isSubmitted && isUsernameValid && !notUsernameDuplicate"
              >
                This username has already used
              </div>
              <div *ngIf="f.username.errors.minlength && f.username.touched">
                This username minimun length is 8 character
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label" for="forgot-password-email">Email</label>
            <input
              class="form-control"
              type="email"
              formControlName="email"
              placeholder="john@example.com"
              aria-describedby="forgot-password-email"
              autofocus=""
              tabindex="1"
              [ngClass]="{ 'is-invalid': f.email?.errors && f.email.touched }"
            />
            <div
              *ngIf="f.email?.errors && f.email.touched"
              class="invalid-feedback"
            >
              <div *ngIf="f.email.errors?.required && f.email.touched">
                Email is required
              </div>
              <div
                *ngIf="
                  (f.email.errors?.email ||
                    f.email.errors?.pattern ||
                    isEmailValid === false) &&
                  f.email.touched
                "
              >
                Email must be a valid email address
              </div>
              <div *ngIf="isSubmitted && isEmailValid && !notEmailDuplicate">
                This email has already used
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="login-password">Password</label>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="passwordTextType ? 'text' : 'password'"
                formControlName="password"
                class="form-control form-control-merge"
                [ngClass]="{
                  'is-invalid error':
                    (f.password.errors ||
                      f.password.hasError('passwordRequirements')) &&
                    f.password.touched
                }"
                placeholder="············"
                aria-describedby="login-password"
                tabindex="2"
              />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"
                  ><i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                    (click)="togglePasswordTextType()"
                  ></i
                ></span>
              </div>
            </div>
            <div
              *ngIf="
                (f.password.errors ||
                  f.password.hasError('passwordRequirements')) &&
                f.password.touched
              "
              class="invalid-feedback"
              [ngClass]="{
                'd-block':
                  (f.password.errors ||
                    f.password.hasError('passwordRequirements')) &&
                  f.password.touched
              }"
            >
              <div *ngIf="f.password.errors?.required">
                Password is required
              </div>
              <div *ngIf="f.password.hasError('passwordRequirements')">
                Password must contain 1 uppercase letter and minimun length is 8
                charecter
              </div>
            </div>
          </div>
          <button
            [disabled]="isLoading"
            class="btn btn-primary btn-block"
            tabindex="2"
            rippleEffect
          >
            <span
              *ngIf="isLoading"
              class="spinner-border spinner-border-sm mr-1"
            ></span
            >Sign Up
          </button>
        </form>

        <p class="text-center mt-2">
          <a routerLink="/login"
            ><i data-feather="chevron-left" class="mb-25"></i>Back to login</a
          >
        </p>
      </div>
    </div>
    <!-- /Forgot password-->
  </div>
</div>
