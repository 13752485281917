<div class="modal-header">
  <h5 class="modal-title" id="exampleModalCenterTitle">
    {{ "General.RelatedDocument" | translate }}
  </h5>
  <button type="button" class="close" (click)="closeModal()" aria-label="Close">
    <span aria-hidden="true">
      <i data-feather="x" size="22"></i>
    </span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <div *ngIf="isLoading" class="my-5 d-flex justify-content-center">
    <app-loading></app-loading>
  </div>

  <app-content-child-document
    [orderStateData]="orderStatus[0]"
    [notShowTitle]="true"
  >
  </app-content-child-document>
</div>
