<div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">
        Return Request
    </h5>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">
            <i data-feather="x" size="22"></i>
        </span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>

    <form [formGroup]="formGroup">
        <div class="row">
            <div class="col-12" style="margin-bottom: 0.5rem">
                {{ 'ContentDocument.Customer' | translate }}: {{ invoice.customerName }}
            </div>

            <!-- <div class="col-12">
                <label class="col-form-label"> เลือกประเภทการทำรายการ </label>
                <div class="d-flex align-items-center">
                    <div class="custom-control custom-radio mr-2">
                        <input type="radio" id="returnType" name="returnType" class="custom-control-input" [value]="0"
                            formControlName="returnType" checked />
                        <label class="custom-control-label" for="returnType"> ขอคึนเงิน </label>
                    </div>
                    <div class="custom-control custom-radio mr-2">
                        <input type="radio" id="returnType2" name="returnType" class="custom-control-input" [value]="1"
                            formControlName="returnType" />
                        <label class="custom-control-label" for="returnType2"> ขอคืนสินค้า </label>
                    </div>
                </div>
            </div> -->

            <div class="col-12">
                <label class="col-form-label"> {{ 'ReturnProduct.ReturnStatus' | translate }} </label>
                <select class="custom-select" formControlName="returnState">
                    <option [ngValue]="stateEnum.WaitApprove">{{ getStatusLabel(stateEnum.WaitApprove) }}</option>
                    <option [ngValue]="stateEnum.Approved">{{ getStatusLabel(stateEnum.Approved) }}</option>
                    <option [ngValue]="stateEnum.Reject">{{ getStatusLabel(stateEnum.Reject) }}</option>
                </select>
            </div>

            <div class="col-12">
                <label class="col-form-label"> {{ 'ReturnProduct.Reasons' | translate }} <span
                        class="text-danger">*</span> </label>
                <input type="text" class="form-control" formControlName="returnReasons" required [ngClass]="{
                    'is-invalid error':
                      (isSubmit && f.returnReasons?.invalid) ||
                      (f.returnReasons?.invalid && (f.returnReasons?.dirty || f.returnReasons?.touched))
                  }">
                <div class="invalid-feedback" [ngClass]="{
                    'd-block':
                    (isSubmit && f.returnReasons?.invalid) ||
                      (f.returnReasons?.invalid && (f.returnReasons?.dirty || f.returnReasons?.touched))
                  }" *ngIf="
                    (isSubmit && f.returnReasons?.invalid) ||
                      (f.returnReasons?.invalid && (f.returnReasons?.dirty || f.returnReasons?.touched))
                  ">
                    <div *ngIf="f.returnReasons?.errors?.required">
                        {{ 'Alert.Invalid' | translate }}
                    </div>
                </div>
                <label class="col-form-label"> {{ 'ReturnProduct.OtherReasons' | translate }} </label>
                <input type="text" class="form-control" formControlName="returnOtherRemarks">

            </div>

            <div class="col-12">
                <label class="col-form-label"> {{ 'ReturnProduct.AttachmentFile' | translate }} </label>
                <app-custom-dropzone #CustomDropzone [typeFiles]="FileTypeFilter.ImagesAndVideo" [countMedia]="7"
                    (filesUploaded)="onFilesUploaded($event)"></app-custom-dropzone>
            </div>
        </div>
    </form>

    <div class="col-12 text-center">
        <div class="text-danger">*** {{ 'ReturnProduct.CheckBeforeSave' | translate }} ***</div>
    </div>

</div>
<div class="modal-footer justify-content-between" *ngIf="!invoice.isReturn">
    <button type="button" class="btn btn-secondary m-0" (click)="closeModal()" rippleEffect>
        {{ "Form.Cancel" | translate }}
    </button>
    <a type="button" class="btn btn-primary m-0" (click)="Submit()" rippleEffect>
        {{ "Form.Submit" | translate }}
    </a>
</div>