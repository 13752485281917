import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoosterComponent } from './product/booster/booster.component';
import { SerumComponent } from './product/serum/serum.component';
import { CreamComponent } from './product/cream/cream.component';
import { SunProtectorComponent } from './product/sun-protector/sun-protector.component';
import { BodywashComponent } from './product/bodywash/bodywash.component';
import { TimelineCreateComponent } from './our-company/timeline/timeline-create/timeline-create.component';
import { CompanyCardComponent } from './our-company/company-card/company-card.component';
import { CompanyCardCreateComponent } from './our-company/company-card/company-card-create/company-card-create.component';
import { TimelineComponent } from './our-company/timeline/timeline.component';
import { HydropartisomeComponent } from './technology/hydropartisome/hydropartisome.component';
import { HydroextractionComponent } from './technology/hydroextraction/hydroextraction.component';
import { PartibiomeComponent } from './technology/partibiome/partibiome.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';
import { DetailComponent } from './technology/hydroethosome/detail/detail.component';
import { HighlightTechComponent } from './technology/hydroethosome/highlight-tech/highlight-tech.component';
import { HighlightTechCreateComponent } from './technology/hydroethosome/highlight-tech/highlight-tech-create/highlight-tech-create.component';
import { ETCTechComponent } from './technology/hydroethosome/etctech/etctech.component';
import { CmsListModule } from "../cms-list/cms-list.module";
import { TechProductLandingPageComponent } from './tech-product-landing-page/tech-product-landing-page.component';
import { CertificationsCardModule } from './certifications-card/certifications-card.module';
import { CertificationsFileModule } from './certifications-file/certifications-file.module';
import { BonusPlanModule } from './bonus-plan/bonus-plan.module';
import { ProductListComponent } from './product/product-list/product-list.component';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { ProductListCreateComponent } from './product/product-list/product-list-create/product-list-create.component';
import { ComponentsModule } from 'app/main/components/components.module';
import { CoreCommonModule } from '@core/common.module';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSummernoteModule } from 'ngx-summernote';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';


const pathProduct: string = 'cms/repove-tools/product'
const pathhydroethosome: string = 'cms/repove-tools/technology/hydroethosome'
const routes: Routes = [
  { // Product ===============================
    path: `${pathProduct}/booster`,
    component: BoosterComponent,
    canActivate: [AuthGuard],
    data: { animation: 'product/booster' },
  },
  {
    path: `${pathProduct}/serum`,
    component: SerumComponent,
    canActivate: [AuthGuard],
    data: { animation: 'product/serum' },
  },
  {
    path: `${pathProduct}/cream`,
    component: CreamComponent,
    canActivate: [AuthGuard],
    data: { animation: 'product/cream' },
  },
  {
    path: `${pathProduct}/sun-protector`,
    component: SunProtectorComponent,
    canActivate: [AuthGuard],
    data: { animation: 'product/sun-protector' },
  },
  {
    path: `${pathProduct}/bodywash`,
    component: BodywashComponent,
    canActivate: [AuthGuard],
    data: { animation: 'product/bodywash' },
  },
  { // Technology hydroethosome ===============
    path: `${pathhydroethosome}/detail`,
    component: DetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'technology/hydroethosome/detail' },
  },
  {
    path: `${pathhydroethosome}/highlight-tech`,
    component: HighlightTechComponent,
    canActivate: [AuthGuard],
    data: { animation: 'highlight-tech-manage' },
  },
  {
    path: `${pathhydroethosome}/highlight-tech/create`,
    component: HighlightTechCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'highlight-tech-create' },
  },
  {
    path: `${pathhydroethosome}/highlight-tech/edit/:id`,
    component: HighlightTechCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'highlight-tech-edit' },
  },
  {
    path: `${pathhydroethosome}/etc-tech`,
    component: ETCTechComponent,
    canActivate: [AuthGuard],
    data: { animation: 'technology/hydroethosome/etc-tech' },
  },
  {
    path: `cms/repove-tools/product-list`,
    component: ProductListComponent,
    canActivate: [AuthGuard],
    data: { animation: 'product-management' },
  },
  {
    path: `cms/repove-tools/product-list/create`,
    component: ProductListCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'product-create' },
  },
  {
    path: `cms/repove-tools/product-list/edit/:id`,
    component: ProductListCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'product-edit' },
  },
];

@NgModule({
  declarations: [
    BoosterComponent,
    CreamComponent,
    SunProtectorComponent,
    BodywashComponent,
    TimelineComponent,
    TimelineCreateComponent,
    CompanyCardComponent,
    CompanyCardCreateComponent,
    HydropartisomeComponent,
    HydroextractionComponent,
    PartibiomeComponent,
    BoosterComponent,
    SerumComponent,
    CreamComponent,
    SunProtectorComponent,
    BodywashComponent,
    TimelineComponent,
    TimelineCreateComponent,
    CompanyCardComponent,
    CompanyCardCreateComponent,
    DetailComponent,
    ETCTechComponent,
    TechProductLandingPageComponent,
    ProductListComponent,
    ProductListCreateComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    CmsFormpageModule,
    CmsListModule,
    CertificationsCardModule,
    CertificationsFileModule,
    BonusPlanModule,
    ComponentsModule,
    CoreCommonModule,
    CorePipesModule,
    TranslateModule,
    NgxDropzoneModule,
    NgxSummernoteModule,
    FormsModule,
    ReactiveFormsModule,
    CoreTouchspinModule,
    NgbPaginationModule,
    NgbModule,
  ]
})
export class RepoveToolsModule { }
