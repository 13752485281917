import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CoreCommonModule } from '@core/common.module';
import { VerifyComponent } from './verify/verify.component';
import { SignupComponent } from './signup/signup.component';
import { ComponentsModule } from 'app/main/components/components.module';

// routing
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { animation: 'auth' },
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
    data: { animation: 'forget-password' },
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: { animation: 'reset-password' },
  },
  {
    path: 'verify',
    component: VerifyComponent,
    data: { animation: 'verify' },
  },
  {
    path: 'sign-up',
    component: SignupComponent,
    data: { animation: 'sign-up' },
  },
];

@NgModule({
  declarations: [
    LoginComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    VerifyComponent,
    SignupComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CoreCommonModule,
    ComponentsModule
  ],
})
export class AuthenticationModule {}
