export const creditNoteReasons = [
    {
        value: 1,
        label:
          'ลดราคาสินค้า เนื่องจากผิดจากที่ตกลงกัน ชำรุดเสียหายย ไม่ครบ หรือคำนวณราคาผิด',
      },
      {
        value: 2,
        label:
          'ลดราคาค่าบริการ เนื่องจากผิดจากที่ตกลงกัน ให้บริการไม่ครบ หรือคำนวณราคาผิด',
      },
      {
        value: 3,
        label:
          'ได้รับสินค้า เนื่องจากผิดจากชำรุดเสียหาย ไม่ครบ ไม่ตรงตามตัวอย่าง หรือไม่ตรงตามคำบรรยาย',
      },
      {
        value: 4,
        label:
          'จ่ายเงินชดเชย ให้แก่ผู้ซื้อสินค้า หรือผู้รับบริการตามข้อผูกพัน/ข้อตกลงกัน',
      },
      {
        value: 5,
        label:
          'จ่ายเงินคืนเงินล่วงหน้า เงินมัดจำ เงินประกัน เงินจอง ให้แก่ผู้ซื้อสินค้า หรือผู้รับบริการตามข้อผูกพัน/ข้อตกลงกัน',
      },
      {
        value: 6,
        label:
          'ได้รับคืนสินค้า หรือแลกเปลี่ยนสินค้า ระหว่างผู้ประกอบการ VAT ด้วยกัน',
      },
      {
        value: 7,
        label:
          'ได้รับสินค้า หรือแลกเปลี่ยนสินค้า ระหว่างผู้ขายกับลูกค้า (เฉพาะภายในเวลาอันเหมาะสม)',
      },
      {
        value: 8,
        label: 'บอกเลิกบริการ เนื่องจากผิดจากที่ตกลงกัน',
      },
      {
        value: 9,
        label: 'บอกเลิกบริการ เนื่องจากไม่ได้มีการให้บริการตามสัญญา',
      },
      {
        value: 10,
        label: 'อื่นๆ',
      },
]