import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-hydroextraction',
  templateUrl: './hydroextraction.component.html',
  styleUrls: ['./hydroextraction.component.scss']
})
export class HydroextractionComponent implements OnInit {

  config: ICMSConfig = {
    apiPath: 'Hydroextraction',
    tableName: 'Content',
    pathUrl: 'cms/repove-tools/technology/hydroextraction',
    model: new Object(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Title',
    formConfig: {
      bannerTitle: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Banner Title',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.BannerTitle',
      },
      bannerSubtitle: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Banner Description',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.BannerSubtitle',
      },
      bannerImage:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Banner Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.BannerImage',
      },
      bannerDescription:{
        type: FormType.Textarea,
        validator: [Validators.required],
        // label: 'Banner Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.BannerDescription',
      },

      stepCount1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step one Label',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepLabel1',
      },
      stepTitle1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step one Title',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepTitle1',
      },
      stepDescription1: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step one Description',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepDescription1',
      },
      stepImage1:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Step one Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepImage1',
      },
      stepCount2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step two Label',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepLabel2',
      },
      stepTitle2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step two Title',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepTitle2',
      },
      stepDescription2: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step two Description',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepDescription2',
      },
      stepImage2:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Step two Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepImage2',
      },
      stepCount3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step three Label',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepLabel3',
      },
      stepTitle3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step three Title',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepTitle3',
      },
      stepDescription3: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step three Description',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepDescription3',
      },
      stepImage3:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Step three Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepImage3',
      },
      stepCount4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step four Label',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepLabel4',
      },
      stepTitle4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step four Title',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepTitle4',
      },
      stepDescription4: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step four Description',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepDescription4',
      },
      stepImage4:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Step four Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepImage4',
      },
      stepCount5: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step five Label',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepLabel5',
      },
      stepTitle5: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step five Title',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepTitle5',
      },
      stepDescription5: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step five Description',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepDescription5',
      },
      stepImage5:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Step five Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepImage5',
      },
      stepCount6: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step six Label',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepLabel6',
      },
      stepTitle6: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step six Title',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepTitle6',
      },
      stepDescription6: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Step six Description',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepDescription6',
      },
      stepImage6:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Step six Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.StepImage6',
      },

      extractionTitle1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction one Title',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionTitle1',
      },
      extractionImage1:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction one Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionImage1',
      },
      extractionTitle2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction two Title',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionTitle2',
      },
      extractionImage2:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction two Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionImage2',
      },
      extractionTitle3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction three Title',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionTitle3',
      },
      extractionImage3:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction three Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionImage3',
      },
      extractionTitle4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction four Title',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionTitle4',
      },
      extractionImage4:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction four Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionImage4',
      },
      extractionTitle5: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction five Title',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionTitle5',
      },
      extractionImage5:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction five Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionImage5',
      },

      extractionDescription:{
        type: FormType.Textarea,
        validator: [Validators.required],
        // label: 'Extraction Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDescription',
      },

      extractionDetailImage1:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction Detail Image one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailImage1',
      },
      extractionDetailScale1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Scale one',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailScale1',
      },
      extractionDetailTitle1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Title one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailTitle1',
      },
      extractionDetailSubtitle1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Subtitle one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailSubtitle1',
      },
      extractionDetailDescription1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Description one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailDescription1',
      },
      extractionOnePropertyIcon1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction one Property Icon one',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex1PropertyIcon1',
      },
      extractionOnePropertyTitle1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction one Property Title one',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex1PropertyTitle1',
      },
      extractionOnePropertyIcon2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction one Property Icon two',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex1PropertyIcon2',
      },
      extractionOnePropertyTitle2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction one Property Title two',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex1PropertyTitle2',
      },
      extractionOnePropertyIcon3: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction one Property Icon three',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex1PropertyIcon3',
      },
      extractionOnePropertyTitle3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction one Property Title three',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex1PropertyTitle3',
      },
      extractionOnePropertyIcon4: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction one Property Icon four',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex1PropertyIcon4',
      },
      extractionOnePropertyTitle4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction one Property Title four',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex1PropertyTitle4',
      },
      
      extractionDetailImage2:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Extraction Detail Image two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailImage2',
      },
      extractionDetailScale2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Scale two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailScale2',
        // description: 'Banner Title',
      },
      extractionDetailTitle2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Title two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailTitle2',
      },
      extractionDetailSubtitle2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Subtitle two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailSubtitle2',
      },
      extractionDetailDescription2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Description two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailDescription2',
      },
      extractionTwoPropertyIcon1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction two Property Icon one',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex2PropertyIcon1',
      },
      extractionTwoPropertyTitle1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction two Property Title one',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex2PropertyTitle1',
      },
      extractionTwoPropertyIcon2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction two Property Icon two',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex2PropertyIcon2',
      },
      extractionTwoPropertyTitle2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction two Property Title two',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex2PropertyTitle2',
      },
      extractionTwoPropertyIcon3: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction two Property Icon three',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex2PropertyIcon3',
      },
      extractionTwoPropertyTitle3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction two Property Title three',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex2PropertyTitle3',
      },
      extractionTwoPropertyIcon4: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction two Property Icon four',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex2PropertyIcon4',
      },
      extractionTwoPropertyTitle4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction two Property Title four',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex2PropertyTitle4',
      },

      extractionDetailImage3:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction Detail Image three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailImage3',
      },
      extractionDetailScale3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Scale three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailScale3',
        // description: 'Banner Title',
      },
      extractionDetailTitle3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Title three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailTitle3',
      },
      extractionDetailSubtitle3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Subtitle three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailSubtitle3',
      },
      extractionDetailDescription3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Description three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailDescription3',
      },
      extractionThreePropertyIcon1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction three Property Icon one',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex3PropertyIcon1',
      },
      extractionThreePropertyTitle1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction three Property Title one',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex3PropertyTitle1',
      },
      extractionThreePropertyIcon2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction three Property Icon two',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex3PropertyIcon2',
      },
      extractionThreePropertyTitle2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction three Property Title two',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex3PropertyTitle2',
      },
      extractionThreePropertyIcon3: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction three Property Icon three',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex3PropertyIcon3',
      },
      extractionThreePropertyTitle3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction three Property Title three',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex3PropertyTitle3',
      },
      extractionThreePropertyIcon4: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction three Property Icon four',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex3PropertyIcon4',
      },
      extractionThreePropertyTitle4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction three Property Title four',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex3PropertyTitle4',
      },

      extractionDetailImage4:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction Detail Image four',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailImage4',
      },
      extractionDetailScale4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Scale four',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailScale4',
        // description: 'Banner Title',
      },
      extractionDetailTitle4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Title four',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailTitle4',
      },
      extractionDetailSubtitle4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Subtitle four',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailSubtitle4',
      },
      extractionDetailDescription4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Description four',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailDescription4',
      },
      extractionFourPropertyIcon1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction four Property Icon one',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex4PropertyIcon1',
      },
      extractionFourPropertyTitle1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction four Property Title one',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex4PropertyTitle1',
      },
      extractionFourPropertyIcon2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction four Property Icon two',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex4PropertyIcon2',
      },
      extractionFourPropertyTitle2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction four Property Title two',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex4PropertyTitle2',
      },
      extractionFourPropertyIcon3: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction four Property Icon three',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex4PropertyIcon3',
      },
      extractionFourPropertyTitle3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction four Property Title three',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex4PropertyTitle3',
      },
      extractionFourPropertyIcon4: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction four Property Icon four',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex4PropertyIcon4',
      },
      extractionFourPropertyTitle4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction four Property Title four',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex4PropertyTitle4',
      },

      extractionDetailImage5:{
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction Detail Image five',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailImage5',
      },
      extractionDetailScale5: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Scale five',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailScale5',
        // description: 'Banner Title',
      },
      extractionDetailTitle5: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Title five',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailTitle5',
      },
      extractionDetailSubtitle5: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Subtitle five',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailSubtitle5',
      },
      extractionDetailDescription5: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Detail Description five',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.ExtractionDetailDescription5',
      },
      extractionFivePropertyIcon1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction five Property Icon one',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex5PropertyIcon1',
      },
      extractionFivePropertyTitle1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction five Property Title one',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex5PropertyTitle1',
      },
      extractionFivePropertyIcon2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction five Property Icon two',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex5PropertyIcon2',
      },
      extractionFivePropertyTitle2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction five Property Title two',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex5PropertyTitle2',
      },
      extractionFivePropertyIcon3: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction five Property Icon three',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex5PropertyIcon3',
      },
      extractionFivePropertyTitle3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction five Property Title three',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex5PropertyTitle3',
      },
      extractionFivePropertyIcon4: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction five Property Icon four',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex5PropertyIcon4',
      },
      extractionFivePropertyTitle4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction five Property Title four',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroextraction.Ex5PropertyTitle4',
      },
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
