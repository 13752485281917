class Contact {
  id: any;
  bannerTitle: string;
  bannerImage: any;
  contactDetail1: string;
  contactDetail2: string;
  contactDetail3: string;
  contactDetail4: string;
  askUsImage1: any;
  askUsImage2: any;
}

export default Contact;
