import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../../../../auth/models';
import { CoreConfig } from '@core/types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreConfigService } from '@core/services/config.service';
import { FilesApiService } from 'app/main/service/files-api.service';
import { ComponentsService } from 'app/main/components/components.service';
import { AuthenticationService } from 'app/auth/service';
import { filesPropNameConfig } from 'app/main/config/MainConfig';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {
  private _unsubscribeAll: Subject<any>;

  componentName: string;
  contentHeader: object;

  currentLang: string;

  public coreConfig: CoreConfig;

  isLoading: boolean;

  user: User;
  @Output() getUserData: EventEmitter<any> = new EventEmitter();
  @BlockUI() blockUI: NgBlockUI

  profileIMG: any;
  profileIMGName: string;

  previewIMG: any;
  previewIMGName: string;
  previewIMGBlob: Blob;

  imageBase64: string;
  activeModal: any;

  filesPropName = filesPropNameConfig;

  constructor(
    private _modalService: NgbModal,
    private _translateService: TranslateService,
    private _coreConfigService: CoreConfigService,
    private _fileApiService: FilesApiService,
    private _componentsService: ComponentsService,
    private _authenticationService: AuthenticationService
  ) {
    this.SetLoadingState();
    this._unsubscribeAll = new Subject();
    this._authenticationService.currentUser.subscribe((x) => {
      this.user = x;

      if (this.profileIMG) {
        this.profileIMG = this._fileApiService.getImage(
          this.user.mediaFiles[this.filesPropName.user.profileImage][0].id
        );
        this.SetLoadedState();
      }
    });
    this.componentName = this._translateService.instant('User.General');
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  uploadProfilePicture(event, modal) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (event: any) => {
        this.previewIMG = event.target.result;
        // this.data.imageBase64 = event.target.result.replace(/^data:(.*,)?/, '');
      };
      reader.readAsDataURL(event.target.files[0]);
      this.previewIMGName = event.target.files[0].name;
      this.previewIMGBlob = event.target.files[0];
      this.openChangePictureModal(modal);
    }
  }

  openChangePictureModal(modal) {
    this.activeModal = this._modalService.open(modal, {
      centered: true,
      backdrop: 'static',
    });
  }

  SaveProfilePicture() {
    var self = this;

    this.activeModal.close();
    this.blockUI.start()

    const formData = new FormData();
    formData.append('file', this.previewIMGBlob);

    this._fileApiService
      .UpdateFile(
        'user',
        this.user.id,
        this.filesPropName.user.profileImage,
        0,
        formData
      )
      .subscribe(
        (res) => {
          let title = this._translateService.instant(
            'Alert.ImageUploadSuccessfully'
          );
          let token = self._authenticationService.tokenValue;
          self._authenticationService.getAccountInfo(token).subscribe((x) => {
            this.blockUI.stop()
            self._componentsService.SuccessSwal(
              title,
              this._translateService.instant('Alert.Confirm'),
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              () => {
                // window.location.reload();
              }
            );
          },(err) => {
            this.blockUI.stop()
            self._componentsService.ErrorSwal();
          });
        },
        (err) => {
          this.blockUI.stop()
          self._componentsService.ErrorSwal();
        }
      );
  }

  getProfileImage(guid): void {
    this.profileIMG = this._fileApiService.getImage(guid);
    this.SetLoadedState();
  }

  ngOnInit(): void {
    this._authenticationService.currentUser.subscribe((x) => {
      this.user = x;
      if (this.user.mediaFiles[this.filesPropName.user.profileImage]) {
        this.profileIMG = this._fileApiService.getImage(
          this.user.mediaFiles[this.filesPropName.user.profileImage][0].id
        );
      }
    });

    this.contentHeader = {
      headerTitle: this.componentName,
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });

    if (this.user.mediaFiles[this.filesPropName.user.profileImage]) {
      this.getProfileImage(
        this.user.mediaFiles[this.filesPropName.user.profileImage][0].id
      );
      console.log('load with profile');
      this.SetLoadedState();
    } else {
      console.log('load with no profile');
      this.SetLoadedState();
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
