import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { ApiService } from 'app/main/service/api.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-truncate-detail',
  templateUrl: './truncate-detail.component.html',
  styleUrls: ['./truncate-detail.component.scss'],
})
export class TruncateDetailComponent implements OnInit {
  contentHeader: object;
  currentLang: string;

  isLoading: boolean;

  @Input() apiPath: string;

  itemList: any[];

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _translateService: TranslateService
  ) {
    this.apiPath = this._route.snapshot.paramMap.get('apiPath');
    this.initData();
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: this.apiPath,
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  initData(): void {
    this.SetLoadingState();
    this.getTruncateData(this.apiPath);
  }

  getTruncateData(apiPath: string): Promise<any> {
    var self = this;
    // let tmpItemList;
    let params = {
      isDelete: true,
    }
    return new Promise((resolve, rejects) => {
      this._apiService.GetAllData(apiPath,params).subscribe((res) => {
        this.itemList = res.data.resultData;
        // this.itemList = tmpItemList.filter((item) => item.isDelete === true);
        self.SetLoadedState();
      });
    });
  }

  drawDatatables(): void {
    this.dtTrigger.next();

    if (this.itemList) {
      this.dtOptions = {
        pagingType: 'full_numbers',
        // responsive: true,
        scrollX: true,
        info: true,
        autoWidth: false,
        // data: this.userList,
        searching: false,
        order: [[1, 'asc']],
        rowCallback: (row: Node, data: any[] | object, dataIndex: number) => {
          row.childNodes[0].textContent = String(dataIndex + 1);
        },
      };
    }
  }
}
