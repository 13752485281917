import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { User } from 'app/auth/models';
import { FilesApiService } from 'app/main/service/files-api.service';
import { UsersApiService } from 'app/main/service/users-api.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-select-user-modal',
  templateUrl: './select-user-modal.component.html',
  styleUrls: ['./select-user-modal.component.scss'],
})
export class SelectUserModalComponent implements OnInit {
  searchText: string = '';
  searchType: number = 1;

  searchData: any = {};

  userList: User[] = [];
  isLoading: boolean = false;

  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private _userApiService: UsersApiService,
    private _fileApiService: FilesApiService
  ) {}

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: 7,
      order: [[1, 'asc']],
      ordering: true,
      serverSide: true,
      lengthMenu: [10, 25, 50, 100],
      lengthChange: true,
      dom: '<"float-left"B><"float-right"f>rt<"row"<"col-sm-4"i><"col-sm-4 text-center"l><"col-sm-4"p>>',
      columnDefs: [
        { orderable: false, targets: 0 },
        { name: 'Username', targets: 1 },
        { name: 'RoleSetName', targets: 2 },
        { orderable: false, targets: 3 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.userList = [];
        self.SetLoadingState();

        let defaultSortName = 'Username';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        } else {
          self.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.searchData.Direction = order?.dir == 'asc' ? 0 : 1;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.searchData.PageLength = dataTablesParameters.length;
        this.searchData.isDelete = false;

        console.log(this.searchData);
        this._userApiService.GetAll(this.searchData).subscribe((response) => {
          console.log(response);
          this.userList = response.data.resultData;
          self.SetLoadedState();
          callback({
            recordsTotal: response.data.itemCount,
            recordsFiltered: response.data.totalItem,
            data: [],
          });
        });
      },
    };
  }

  closeModal(): void {
    this.activeModal.close();
  }

  callBack(): void {
    this.callBackFunc.emit();
    this.activeModal.close();
  }

  getImages(guid: string, params): string {
    return this._fileApiService.getImage(guid, params);
  }

  SearchUser() {
    if (this.searchType == 1) {
      this.searchData.Email = ''
      this.searchData.Username = this.searchText
    } else if (this.searchType == 2) {
      this.searchData.Username = ''
      this.searchData.Email = this.searchText
    }

    this.getUserList()
  }

  getUserList() {
    if (this.dtTrigger) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          this.SetLoadedState();
        });
      });
    }
  }
}
