import { Component } from '@angular/core';
import { MultiFileUploadService } from './multi-file-upload-service.service';
import { log } from 'console';
interface SelectedFile {
  file: File;
  url: string;
}
@Component({
  selector: 'app-multi-file-upload',
  templateUrl: './multi-file-upload.component.html',
  styleUrls: ['./multi-file-upload.component.scss']
})
export class MultiFileUploadComponent {
  constructor(private multiFileUploadService: MultiFileUploadService) {

  }

  ngOnInit(): void {
    this.multiFileUploadService.uploadTriggered.subscribe(() => {
      this.uploadFiles();
    });
  }

  selectedFiles: SelectedFile[] = [];
  onFilesSelected(event: any): void {
    const files: FileList | null = event.addedFiles;

    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file: File = files[i];
        const selectedFile: SelectedFile = {
          file: file,
          url: URL.createObjectURL(file)
        };
        this.selectedFiles.push(selectedFile);
      }
    }
  }

  onRemoveAtteched(file: SelectedFile): void {
    const index = this.selectedFiles.indexOf(file);
      console.log(file);

    if (index !== -1) {
      this.selectedFiles.splice(index, 1);
    }
  }


  uploadFiles(): void {
  }
}
