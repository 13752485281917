import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormTemplateComponent } from 'app/main/components/form/form-template/form-template.component';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';
import { ApiService } from 'app/main/service/api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cms-formpage',
  templateUrl: './cms-formpage.component.html',
  styleUrls: ['./cms-formpage.component.scss'],
})
export class CmsFormpageComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();
  private dataSubscription: Subscription;

  contentHeader: object;
  isLoadingData: boolean = false;

  apiPath: string;
  itemID: string;

  itemObj: any;
  @Input() config!: ICMSConfig;
  @BlockUI() blockUI: NgBlockUI
  @ViewChild('formTemplate') formTemplate: FormTemplateComponent;

  constructor(
    private _route: ActivatedRoute,
    private _apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: this.config.componentName,
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this.itemID = this._route.snapshot.paramMap.get('id');

    this.apiPath = this.config.apiPath;
    this.itemObj = this.config.model;

    if (this.config.isList) {
      if (this.itemID) {
        this.blockUI.start()
        this.GetItemData(this.apiPath, this.itemID);
      } else {
        // this.GetItemData(this.apiPath, '');
      }
    } else {
      this.blockUI.start()
      this.GetItemData(this.apiPath, '');
    }
  }

  GetItemData(apiPath, itemID): void {
    var self = this;
    
    this.dataSubscription = this._apiService
      .GetDataById(apiPath, itemID)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res.data.resultData[0]) {
          self.itemObj = res.data.resultData[0];
          self.formTemplate.setFormValue(self.itemObj);
        }
        self.isLoadingData = false;
        self.blockUI.stop()
      },(err) => {
        self.blockUI.stop()
      });
  }

  setFormValue(obj): void {
    this.formTemplate.setFormValue(obj);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }
}
