<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header class="text-capitalize" [contentHeader]="contentHeader"></app-content-header>

    <div class="card">
      <div class="card-body">

        <a routerLink="/admin/trash" class="btn btn-primary mb-2">Back to truncate</a>

        <div *ngIf="isLoading" class="text-center">
          <app-loading></app-loading>
        </div>

        <div  *ngIf="!isLoading">
          <table datatable class="table" [dtOptions]="dtOptions">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Delete By</th>
                <th>Delete Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of itemList; let ind = index">
                <td>{{ ind + 1 }}</td>
                <td>{{ apiPath === 'user' ? item.username : item.name }}</td>
                <td>{{ item.deleteBy }}</td>
                <td>
                  <span *ngIf="currentLang == 'th'">
                    {{ item.deleteDate | thaidate : "DD MMM YYYY HH:mm" }} น.
                  </span>
                  <span *ngIf="currentLang != 'th'">
                    {{ item.deleteDate | date : "dd MMM YYYY HH:mm" }}
                  </span>
                </td>
              </tr>
              <!-- <tr *ngIf="itemList.length < 1">
                <td colspan="100%" class="text-center">
                  There is no item to preview
                </td>
              </tr> -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
