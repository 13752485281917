<div class="content-header">
  <h5 class="mb-0">{{ "CreditDebit.Create" | translate }}{{ titleDoc }}</h5>
  <small class="text-muted"
    >{{ "CreditDebit.EnterYour" | translate }}{{ titleDoc }}</small
  >
</div>

<div class="row">
  <div class="col-sm-3 col-form-label">
    {{ "CreditDebit.SelectDocument" | translate }}
  </div>
  <div class="form-group col-sm-9">
    <label for="customerSelect">{{ "CreditDebit.Business" | translate }}</label>
    <ng-select
      [formControl]="selectedCustomerControl"
      [(ngModel)]="selectedCustomer"
      (ngModelChange)="onCustomerSelectionChange()"
      bindLabel="customerName"
      bindValue="customerId"
      [placeholder]="'CreditDebit.SelectBusiness' | translate"
      required
      [loading]="loadingCustomerList"
      [notFoundText]="'CreditDebit.BusinessNotFound' | translate"
    >
      <ng-option
        *ngFor="let customer of customerList"
        [value]="customer.id"
        >{{ customer.repoveCode }} -
        {{
          customer.businessName
            ? customer.businessName
            : customer.customerFirstName + " " + customer.customerLastName
        }}
        ({{
          customer.isHeadBranch
            ? ("Customer.headOffice" | translate)
            : customer.branchNumber.toString().padStart(5, "0")
        }})</ng-option
      >
    </ng-select>
    <div
      *ngIf="selectedCustomerControl.invalid && selectedCustomerControl.dirty"
      class="text-danger"
      style="padding-top: 4px"
    >
      {{ "CreditDebit.PleaseSelectBusiness" | translate }}
    </div>
  </div>
  <div class="col-sm-3"></div>
  <div class="form-group col-sm-9">
    <label for="docCodeSelect">{{
      "CreditDebit.DocumentNo" | translate
    }}</label>
    <ng-select
      [items]="docCodeList"
      bindLabel="docCode"
      bindValue="docCodeId"
      [formControl]="selectedDocCodeControl"
      required
      [placeholder]="'CreditDebit.SelectDocumentNo' | translate"
      [(ngModel)]="selectedDocCode"
      (ngModelChange)="onDocCodeSelectionChange()"
      [notFoundText]="'CreditDebit.DocumentNotFound' | translate"
      [loading]="loadingDocCodeList"
    >
      <ng-template ng-option-tmp let-item="item">
        {{ item.docCode }}
      </ng-template>
    </ng-select>
    <div
      *ngIf="selectedDocCodeControl.invalid && selectedDocCodeControl.dirty"
      class="text-danger"
      style="padding-top: 4px"
    >
      {{ "CreditDebit.PleaseSelectDocumentNo" | translate }}
    </div>
  </div>
</div>
