<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section>
      <app-horizontal-timeline
        [timelineItems]="timelineData"
        [currentStep]="currentStep"
        [currentStepNumber]="currentStep"
        [lastItems]="{
          label: 'Cancel',
          descript: 'ยกเลิก',
          stepCount: 5
        }"
      ></app-horizontal-timeline>
    </section>

    <section>
      <div class="card border" *ngIf="docStatus !== 8 && docStatus !== 9">
        <div class="card-body">
          <div
            class="d-flex justify-content-between w-100 px-2 align-items-center"
          >
            <span>
              {{ "Form.CreateGRFormPO" | translate }}
            </span>
            <div class="d-flex align-items-center">
              <!-- <div class="custom-control custom-checkbox mr-1">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="checkIncludeQo"
                    [checked]="isIncludeQo"
                    [disabled]="disableButton"
                    (change)="toggleIncludeQo()"
                  />
                  <label class="custom-control-label" for="checkIncludeQo"
                    >ให้ใบแจ้งหนี้เป็นใบกำกับภาษี</label
                  >
                </div> -->
              <button
                class="btn btn-primary"
                (click)="openApproveModal()"
                [disabled]="orderStatus[0].isCreatedGR == true || isApproving"
              >
                {{ "SaleOrder.Approve" | translate }}
              </button>

              <a
                *ngIf="purchaseOrderObj.isCreateable == true && isSplitGRfromPO"
                class="btn btn-success ml-1"
                [routerLink]="[
                  '/document/goods-receive/create/ref/' + purchaseOrderObj.id
                ]"
              >
                <i data-feather="scissors" class="mr-1"></i>
                {{ "Document.SeparateGR" | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <!-- Nav tabs -->
        <div
          class="card-header"
          style="border-bottom: 1px dashed rgba(161, 161, 161, 0.3)"
        >
          <span></span>

          <ul
            ngbNav
            #navFilled="ngbNav"
            [activeId]="activeTab"
            class="nav nav-tabs mb-0 bg-white border"
            [destroyOnHide]="false"
          >
            <li [ngbNavItem]="'detail'" class="nav-item">
              <a ngbNavLink routerLink="." [fragment]="'detail'">{{
                "General.DocumentInfo" | translate
              }}</a>
              <ng-template ngbNavContent>
                <app-content-document
                  [customerAddressObj]="customerAddressObj"
                  [titleDoc]="'Document.PurchaseOrder' | translate"
                  [apiPath]="apiPath"
                  [typeDocName]="typeDocName"
                  [detailObj]="purchaseOrderObj"
                  [invoiceDetailObj]="purchaseOrderObj"
                  [docId]="docID"
                  [docStatus]="docStatus"
                  [editPathUrl]="editPathUrl"
                  [detailPathUrl]="detailPathUrl"
                  [refDetailUrl]="refDetailUrl"
                  [refDocID]="'purchaseRequisitionId'"
                  [isDisabledEditBtn]="true"
                  (refreshData)="refreshData()"
                >
                </app-content-document>
              </ng-template>
            </li>

            <li [ngbNavItem]="'paymentInfo'">
              <a ngbNavLink routerLink="." [fragment]="'paymentInfo'">{{ "General.PaymentInfo" | translate }}</a>
              <ng-template ngbNavContent>
                <div class="card m-2">
                  <app-payment-detail
                    [titleDoc]="'Document.PurchaseOrder' | translate"
                    [isPurchaseOrder]="true"
                    [docId]="docID"
                    [apiPath]="apiPath"
                    [docStatus]="docStatus"
                    [detailObj]="purchaseOrderObj"
                    [typeDocName]="typeDocName"
                    [refDocID]="'purchaseRequisitionId'"
                    [refDetailUrl]="refDetailUrl"
                    (submitData)="handlePaymentDetailSubmit($event)"
                    (refreshData)="handlePaymentDetailRefresh($event)"
                  >
                  </app-payment-detail>
                </div>
              </ng-template>
            </li>

            <li [ngbNavItem]="'historyInfo'">
              <a ngbNavLink routerLink="." [fragment]="'historyInfo'">{{
                "General.HistoryInfo" | translate
              }}</a>
              <ng-template ngbNavContent>
                <app-action-history
                  [documentId]="docID"
                  [title]="'Document.PurchaseOrder'"
                  [apiPath]="apiPath"
                  [detailObj]="purchaseOrderObj"
                  [typeDocName]="typeDocName"
                  [docStatus]="docStatus"
                  [refDetailUrl]="refDetailUrl"
                  [refDocID]="'purchaseRequisitionId'"
                >
                </app-action-history>
              </ng-template>
            </li>
            <li [ngbNavItem]="'relatedDoc'">
              <a ngbNavLink routerLink="." [fragment]="'relatedDoc'">{{
                "General.RelatedDocument" | translate
              }}</a>
              <ng-template ngbNavContent>
                <app-content-child-document
                  [orderStateData]="orderStatus[0]"
                  (refreshData)="refreshData($event)"
                  [currentDocCode]="purchaseOrderObj[typeDocName]"
                >
                </app-content-child-document>
              </ng-template>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div [ngbNavOutlet]="navFilled"></div>
        </div>
      </div>
    </section>
  </div>
</div>
