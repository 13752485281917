import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-cream',
  templateUrl: './cream.component.html',
  styleUrls: ['./cream.component.scss']
})
export class CreamComponent implements OnInit {

  config = {
    apiPath: 'cream',  //?ชื่อ api
    pathUrl: '/cms/repove-tools/product/cream',  //?path
    tableName: 'Content', 
    model: new Object(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.RepoveTools.Cream', //Manage Home Page
    formConfig: {
      // * Section 1 ==============================================================
      presenterIMG: {
        type: FormType.SingleImage,
        label: 'Presenter Image',
        description: 'Resolution: 920px*670px',
        labelTranslate: 'Informative.RepoveToolsProduct.PresenterImage',
        descriptionTranslate: 'Informative.RepoveToolsProduct.PresenterImageDescription',
        validator: [Validators.required],
      },
      // ProductIMG: {
      //   type: FormType.SingleImage,
      //   label: 'Product Image',
      //   description: '',
      //   labelTranslate: 'Informative.RepoveToolsProduct.ProductImage',
      //   descriptionTranslate: '',
      //   validator: [Validators.required],
      // },
      productNameTH: {
        type: FormType.Text,
        label: 'Product name Thai',
        description: 'Property names in Thai at the top',
        labelTranslate: 'Informative.RepoveToolsProduct.ProductNameTH',
        descriptionTranslate: 'Informative.RepoveToolsProduct.ProductNameTHDescription',
        validator: [Validators.required],
      },
      productNameEN: {
        type: FormType.Text,
        label: 'Product Name EN',
        description: 'English product names below Thai product names',
        labelTranslate: 'Informative.RepoveToolsProduct.ProductNameEN',
        descriptionTranslate: 'Informative.RepoveToolsProduct.ProductNameENDescription',
        validator: [Validators.required],
      },
      productPrice: {
        type: FormType.Text,
        label: 'Product Price',
        // description: 'Price', 
        labelTranslate: 'Informative.RepoveToolsProduct.ProductPrice',
        // descriptionTranslate: 'Informative.RepoveToolsProduct.ProductPriceDescription',
        validator: [Validators.required],
      },
      productVolume: {
        type: FormType.Text,
        label: 'Product Volume',
        description: 'Product quantity next to price',
        labelTranslate: 'Informative.RepoveToolsProduct.ProductVolume',
        descriptionTranslate: 'Informative.RepoveToolsProduct.ProductVolumeDetail',
        validator: [Validators.required],
      },
      productDetail: {
        type: FormType.Text,
        isMultipleLang: true,
        label: 'Product Detail',
        description: 'Product details below product price',
        labelTranslate: 'Informative.RepoveToolsProduct.ProductDetail',
        descriptionTranslate: 'Informative.RepoveToolsProduct.ProductDetailDiscription',
        validator: [Validators.required],
      },

      // * Section 2 ==============================================================
      technologyDetails: {
        type: FormType.Textarea,
        isMultipleLang: true,
        label: 'Technology Details',
        description: 'Technology details on the left',
        labelTranslate: 'Informative.RepoveToolsProduct.TechnologyDetails',
        descriptionTranslate: 'Informative.RepoveToolsProduct.TechnologyDetailsDiscription',
        validator: [Validators.required],
      },
      featureTitle: {
        type: FormType.Text,
        isMultipleLang: true,
        label: 'Feature Title',
        description: 'Topic property title',
        labelTranslate: 'Informative.RepoveToolsProduct.FeatureTitle',
        descriptionTranslate: 'Informative.RepoveToolsProduct.FeatureTitleDiscription',
        validator: [Validators.required],
      },
      otherFeaturesTitle: {
        type: FormType.Text,
        isMultipleLang: true,
        label: 'Other Features Title',
        description: 'Other properties are listed below',
        labelTranslate: 'Informative.RepoveToolsProduct.OtherFeaturesTitle',
        descriptionTranslate: 'Informative.RepoveToolsProduct.OtherFeaturesTitleDiscription',
        validator: [Validators.required],
      },
      featurIcon1: {
        type: FormType.SingleImage,
        label: 'Featur Icon 1',
        description: 'Resolution: 50px*50px',
        labelTranslate: 'Informative.RepoveToolsProduct.FeaturIcon1',
        descriptionTranslate: 'Informative.RepoveToolsProduct.FeaturIconDiscription',
        validator: [Validators.required],
      },
      featurDetail1: {
        type: FormType.Textarea,
        isMultipleLang: true,
        label: 'Featur Detail 1',
        labelTranslate: 'Informative.RepoveToolsProduct.FeaturDetail1',
        validator: [Validators.required],

      },
      featurIcon2: {
        type: FormType.SingleImage,
        label: 'Featur Icon 2',
        description: 'Resolution: 50px*50px',
        labelTranslate: 'Informative.RepoveToolsProduct.FeaturIcon2',
        descriptionTranslate: 'Informative.RepoveToolsProduct.FeaturIconDiscription',
        validator: [Validators.required],

      },
      featurDetail2: {
        type: FormType.Textarea,
        isMultipleLang: true,
        label: 'Featur Detail 2',
        labelTranslate: 'Informative.RepoveToolsProduct.FeaturDetail2',
        validator: [Validators.required],

      },
      featurIcon3: {
        type: FormType.SingleImage,
        label: 'Featur Icon 3',
        description: 'Resolution: 50px*50px',
        labelTranslate: 'Informative.RepoveToolsProduct.FeaturIcon3',
        descriptionTranslate: 'Informative.RepoveToolsProduct.FeaturIconDiscription',
        validator: [Validators.required],

      },
      featurDetail3: {
        type: FormType.Textarea,
        isMultipleLang: true,
        label: 'Featur Detail 3',
        labelTranslate: 'Informative.RepoveToolsProduct.FeaturDetail3',
        validator: [Validators.required],

      },
      otherFeaturesIcon1: {
        type: FormType.SingleImage,
        label: 'Other Features Icon 1',
        description: 'Resolution: 50px*50px',
        labelTranslate: 'Informative.RepoveToolsProduct.OtherFeaturesIcon1',
        descriptionTranslate: 'Informative.RepoveToolsProduct.FeaturIconDiscription',
        validator: [Validators.required],

      },
      otherFeaturesDetail1: {
        type: FormType.Textarea,
        isMultipleLang: true,
        label: 'Other Features Detail 1',
        labelTranslate: 'Informative.RepoveToolsProduct.OtherFeaturesDetail1',
        validator: [Validators.required],

      },
      otherFeaturesIcon2: {
        type: FormType.SingleImage,
        label: 'Other Features Icon 2',
        description: 'Resolution: 50px*50px',
        labelTranslate: 'Informative.RepoveToolsProduct.OtherFeaturesIcon2',
        descriptionTranslate: 'Informative.RepoveToolsProduct.FeaturIconDiscription',
        validator: [Validators.required],

      },
      otherFeaturesDetail2: {
        type: FormType.Textarea,
        isMultipleLang: true,
        label: 'Other Features Detail 2',
        labelTranslate: 'Informative.RepoveToolsProduct.OtherFeaturesDetail2',
        validator: [Validators.required],
      },
      otherFeaturesIcon3: {
        type: FormType.SingleImage,
        label: 'Other Features Icon 3',
        description: 'Resolution: 50px*50px',
        labelTranslate: 'Informative.RepoveToolsProduct.OtherFeaturesIcon3',
        descriptionTranslate: 'Informative.RepoveToolsProduct.FeaturIconDiscription',
        validator: [Validators.required],
      },
      otherFeaturesDetail3: {
        type: FormType.Textarea,
        isMultipleLang: true,
        label: 'Other Features Detail 3',
        labelTranslate: 'Informative.RepoveToolsProduct.OtherFeaturesDetail3',
        validator: [Validators.required],

      },
      otherFeaturesIcon4: {
        type: FormType.SingleImage,
        label: 'Other Features Icon 4',
        description: 'Resolution: 50px*50px',
        labelTranslate: 'Informative.RepoveToolsProduct.OtherFeaturesIcon4',
        descriptionTranslate: 'Informative.RepoveToolsProduct.FeaturIconDiscription',
        validator: [Validators.required],
      },
      otherFeaturesDetail4: {
        type: FormType.Textarea,
        isMultipleLang: true,
        label: 'Other Features Detail 4',
        labelTranslate: 'Informative.RepoveToolsProduct.OtherFeaturesDetail4',
        validator: [Validators.required],

      },

      // * Section 3 ==============================================================
      componentImg: {
        type: FormType.SingleImage,
        label: 'Component Image',
        description: 'Resolution: 920px*670px',
        labelTranslate: 'Informative.RepoveToolsProduct.ComponentImage',
        descriptionTranslate: 'Informative.RepoveToolsProduct.ComponentImageDescription',
        validator: [Validators.required],
      },
      componentDetails: {
        type: FormType.RichText,
        isMultipleLang: true,
        label: 'Component Details',
        description: 'Component details on the right',
        labelTranslate: 'Informative.RepoveToolsProduct.ComponentDetails',
        descriptionTranslate: 'Informative.RepoveToolsProduct.ComponentDetailsDiscription',
        validator: [Validators.required],
      },

      // * Section 4 ==============================================================
      testResults: {
        type: FormType.RichText,
        isMultipleLang: true,
        label: 'Test results from the clinic',
        // description: 'Test results from the clinic',
        labelTranslate: 'Informative.RepoveToolsProduct.TestResults',
        // descriptionTranslate: 'Informative.RepoveToolsProduct.WhoWeAreQuote.Description',
        validator: [Validators.required],
      },
      testResultsTablet: {
        type: FormType.RichText,
        isMultipleLang: true,
        label: 'Test results from the clinic Tablet',
        // description: 'Test results from the clinic',
        labelTranslate: 'Informative.RepoveToolsProduct.TestResultsTablet',
        // descriptionTranslate: 'Informative.RepoveToolsProduct.WhoWeAreQuote.Description',
        validator: [Validators.required],
      },
      testResultsMobile: {
        type: FormType.RichText,
        isMultipleLang: true,
        label: 'Test results from the clinic Mobile',
        // description: 'Test results from the clinic',
        labelTranslate: 'Informative.RepoveToolsProduct.TestResultsMobile',
        // descriptionTranslate: 'Informative.RepoveToolsProduct.WhoWeAreQuote.Description',
        validator: [Validators.required],
      },
      // * Section 5 ==============================================================
      beforeAfterImage1: {
        type: FormType.SingleImage,
        label: 'Before After Image 1',
        description: 'Resolution 480px*250px',
        labelTranslate: 'Informative.RepoveToolsProduct.BeforeAfterImage1',
        descriptionTranslate: 'Informative.RepoveToolsProduct.BeforeAfterImageDiscription',
        validator: [Validators.required],
      },
      beforeAfterImage2: {
        type: FormType.SingleImage,
        label: 'Before After Image 2',
        description: 'Resolution 480px*250px',
        labelTranslate: 'Informative.RepoveToolsProduct.BeforeAfterImage2',
        descriptionTranslate: 'Informative.RepoveToolsProduct.BeforeAfterImageDiscription',
        validator: [Validators.required],
      },
      beforeAfterImage3: {
        type: FormType.SingleImage,
        label: 'Before After Image 3',
        description: 'Resolution 480px*250px',
        labelTranslate: 'Informative.RepoveToolsProduct.BeforeAfterImage3',
        descriptionTranslate: 'Informative.RepoveToolsProduct.BeforeAfterImageDiscription',
        validator: [Validators.required],
      },
      beforeAfterImage4: {
        type: FormType.SingleImage,
        label: 'Before After Image 4',
        description: 'Resolution 480px*250px',
        labelTranslate: 'Informative.RepoveToolsProduct.BeforeAfterImage4',
        descriptionTranslate: 'Informative.RepoveToolsProduct.BeforeAfterImageDiscription',
        validator: [Validators.required],
      },

    },
  }

  constructor() { }

  ngOnInit(): void {
  }

}
