import { Component, OnInit } from '@angular/core';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import PDPA from 'app/main/model/PDPA';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-pdpa',
  templateUrl: './pdpa.component.html',
  styleUrls: ['./pdpa.component.scss'],
})
export class PdpaComponent implements OnInit {
  config: ICMSConfig = {
    apiPath: 'PrivacyPolicy',
    tableName: 'Content',
    pathUrl: '/cms/privacy-policy',
    model: new PDPA(),
    isList: false,
    componentName: 'Privacy Policy',
    formConfig: {
      pdpaDate: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Date',
        description: 'Date of Privacy Policy',
        labelTranslate: 'Informative.PrivacyPolicy.Date.Label',
        descriptionTranslate: 'Informative.PrivacyPolicy.Date.Description',
      },
      pdpaName: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Title',
        description: 'Title of Privacy Policy',
        labelTranslate: 'Informative.PrivacyPolicy.Title.Label',
        descriptionTranslate: 'Informative.PrivacyPolicy.Title.Description',
      },
      pdpaDescription: {
        type: FormType.RichText,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Description',
        description: 'Description of Privacy Policy',
        labelTranslate: 'Informative.PrivacyPolicy.Description.Label',
        descriptionTranslate:
          'Informative.PrivacyPolicy.Description.Description',
      },
    },
  };

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
