import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormTemplateComponent } from 'app/main/components/form/form-template/form-template.component';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';
import { ApiService } from 'app/main/service/api.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  
  config = {
    apiPath: 'home',
    pathUrl: '/cms/home',
    tableName: 'Content',
    model: new Home(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.Home',
    formConfig: {
      bannerIMG: {
        type: FormType.SingleImage,
        label: 'Banner Picture',
        description: 'Resolution: 1920px*730px',
        labelTranslate: 'Informative.Home.BannerImage',
        descriptionTranslate: 'Informative.Home.BannerImageDescription',
        validator: [Validators.required],
      },

      bannerVDO: {
        type: FormType.Video,
        label: 'Banner Video',
        description: 'Resolution: 1920px*730px',
        labelTranslate: 'Informative.Home.BannerVideo',
        descriptionTranslate: 'Informative.Home.BannerVideoDescription',
        validator: [Validators.required],
      },
      isBannerVDO: {
        label: 'Use Banner as Video',
        labelTranslate: 'Informative.Home.UseBannerAsVideo',
        type: FormType.Switch,
      },

      whoWeAreQuote: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Who We Are Quote',
        description: 'Quote',
        labelTranslate: 'Informative.Home.WhoWeAreQuote.Label',
        descriptionTranslate: 'Informative.Home.WhoWeAreQuote.Description',
      },
      whoWeAreDescription: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Who We Are Description',
        description: 'Description',
        labelTranslate: 'Informative.Home.WhoWeAreDescription.Label',
        descriptionTranslate:
          'Informative.Home.WhoWeAreDescription.Description',
      },

      whoWeArePic1: {
        label: 'Who We Are Picture 1',
        description: 'Resolution: 260px*260px',
        labelTranslate: 'Informative.Home.WhoWeAreImage1.Label',
        descriptionTranslate: 'Informative.Home.WhoWeAreImage1.Description',
        type: FormType.SingleImage,
        validator: [Validators.required],
      },
      whoWeArePic2: {
        label: 'Who We Are Picture 2',
        description: 'Resolution: 440px*640px',
        labelTranslate: 'Informative.Home.WhoWeAreImage2.Label',
        descriptionTranslate: 'Informative.Home.WhoWeAreImage2.Description',
        type: FormType.SingleImage,
        validator: [Validators.required],
      },
      whoWeArePic3: {
        label: 'Who We Are Picture 3',
        description: 'Resolution: 480px*300px',
        labelTranslate: 'Informative.Home.WhoWeAreImage3.Label',
        descriptionTranslate: 'Informative.Home.WhoWeAreImage3.Description',
        type: FormType.SingleImage,
        validator: [Validators.required],
      },

      newProductSectionDescription: {
        type: FormType.Text,
        isMultipleLang: true,
        label: 'New Product Section Description',
        description: 'Description',
        labelTranslate: 'Informative.Home.NewProductSection',
        descriptionTranslate: 'Informative.Description',
        validator: [Validators.required],
      },
      newProductSectionPic: {
        label: 'New Product Background',
        description: 'Resolution: 940px*750px',
        labelTranslate: 'Informative.Home.NewProductBG',
        descriptionTranslate: 'Informative.Home.NewProductBGDescription',
        type: FormType.SingleImage,
        validator: [Validators.required],
      },
      newProductWordingButton: {
        type: FormType.Text,
        isMultipleLang: true,
        label: 'New Product Wording Button',
        description: 'Wording on button',
        labelTranslate: 'Informative.Home.NewProductWordingButton',
        descriptionTranslate: 'Informative.Home.WordingOnButton',
        validator: [Validators.required],
      },
      newProductURLButton: {
        type: FormType.Text,
        label: 'New Product URL',
        description: 'URL to redirect',
        labelTranslate: 'Informative.Home.NewProductURL',
        descriptionTranslate: 'Informative.Home.NewProductURLDescription',
        validator: [Validators.required],
      },

      ourTechQuote: {
        type: FormType.Text,
        isMultipleLang: true,
        label: 'Our Technology Quote',
        description: 'Our Technology Quote',
        labelTranslate: 'Informative.Home.OurTechnologyQuote',
        descriptionTranslate: 'Informative.Home.OurTechnologyQuote',
        validator: [Validators.required],
      },
      ourTechVDO: {
        type: FormType.Video,
        label: 'Our Technology Video',
        description: 'Resolution: 1280px*600px',
        labelTranslate: 'Informative.Home.OurTechnologyVideo',
        descriptionTranslate: 'Informative.Home.OurTechnologyVideoDescription',
        validator: [Validators.required],
      },

      membershipPic1: {
        type: FormType.SingleImage,
        label: 'Member Promotion Picture 1',
        description: 'Resolution: 660px*447px',
        labelTranslate: 'Informative.Home.MemberPromotionImage1',
        descriptionTranslate:
          'Informative.Home.MemberPromotionImage1Description',
        validator: [Validators.required],
      },
      membershipPic2: {
        type: FormType.SingleImage,
        label: 'Member Promotion Picture 2',
        description: 'Resolution: 660px*447px',
        labelTranslate: 'Informative.Home.MemberPromotionImage2',
        descriptionTranslate:
          'Informative.Home.MemberPromotionImage2Description',
        validator: [Validators.required],
      },
    },
  };

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
