<div class="content-wrapper container-xxl p-0">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div class="content-body">
        <div class="card">
            <div class="card-body">

                <ul ngbNav #nav="ngbNav" class="nav nav-pills" [destroyOnHide]="false">
                    <li ngbNavItem>
                        <a ngbNavLink (click)="ChangeTab(2)">Ready for AP</a>
                        <ng-template ngbNavContent>

                            <ng-container *ngTemplateOutlet="memberListTable;context: {
                                    customerList: APCustomerList, page: pageAP, dtOptions: APdtOptions,tableId: 'APTable'
                                    }"></ng-container>
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink (click)="ChangeTab(3)">Ready for MP</a>
                        <ng-template ngbNavContent>

                            <ng-container *ngTemplateOutlet="memberListTable;context: {
                                customerList: MPCustomerList, page: pageMP, dtOptions: MPdtOptions, tableId: 'MPTable'
                                }"></ng-container>
                        </ng-template>
                    </li>

                </ul>
                <div [ngbNavOutlet]="nav" class="mt-1"></div>
            </div>
        </div>
    </div>
</div>

<ng-template #memberListTable let-customerList="customerList" let-page="page" let-dtOptions="dtOptions"
    let-tableId="tableId">
    <hr>
    <div class="d-flex justify-content-center mb-1">
        <div class="col-2">
            <label for="">{{ 'General.SearchBy' | translate }}</label>
            <select class="custom-select" [(ngModel)]="searchByCode">
                <option [ngValue]="false">{{ 'ViewTable.CustomerName' | translate }}</option>
                <option [ngValue]="true">{{ 'ViewTable.CustomerCode' | translate }}</option>
            </select>
        </div>
        <div class="col-5">
            <label for="">{{ 'General.SearchKeyword' | translate }}</label>
            <input class="form-control mr-1" type="text" [(ngModel)]="searchText">
        </div>
    </div>

    <div class="d-flex justify-content-center my-2">
        <button class="btn btn-secondary mr-1" [disabled]="isLoading" (click)="Reset()">
            <i data-feather='refresh-ccw' class="mr-1"></i>
            {{ "General.Reset" | translate }}
        </button>
        <button class="btn btn-primary mr-1" [disabled]="isLoading" (click)="Search()">
            <i data-feather="search" class="mr-1"></i>
            {{ "General.Search" | translate }}
        </button>

    </div>

    <hr>

    <p class="mb-1">รายการค้นหา</p>
    <table class="table" #readyForRankUpTable [id]="tableId" datatable [dtOptions]="dtOptions">
        <thead>
            <tr>
                <th class="text-center" width="8%">
                    #
                </th>
                <th width="10%">Code</th>
                <th width="40%">{{ 'Form.Name' | translate }}</th>
                <th width="15%">Rank</th>
                <th width="15%">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr class="w-100" *ngIf="isLoading">
                <td class="text-center" *ngFor="let num of [].constructor(5)">
                    <div class="skeleton-loading"></div>
                </td>
            </tr>

            <tr *ngIf="customerList.length < 1 && !isLoading">
                <td colspan="100%" class="text-center">
                    <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
                </td>
            </tr>

            <tr *ngFor="let customer of customerList; index as ind">
                <td>
                    <div class="text-center">
                        {{ (page - 1) * pageSize + ind + 1 }}
                    </div>
                </td>
                <td>
                    <a [routerLink]="['/customer/detail/' + customer.id]" target="_blank">
                        {{ customer.repoveCode }}
                    </a>
                </td>
                <td>
                    <a [routerLink]="['/customer/detail/' + customer.id]" target="_blank">
                        {{ customer.customerName }}
                    </a>
                </td>
                <td>
                    {{ customer.rankName }}
                </td>
                <td>
                    <div class="d-flex">
                        <button class="btn btn-success mr-1" (click)="openApprovalRankModal(customer)">
                            <i data-feather="check"></i>
                        </button>
                        <button class="btn btn-primary" (click)="openRankManageModal(customer)">
                            <i data-feather="more-vertical"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</ng-template>