export enum StockType {
  FG = 1,
  FOC = 2,
  Claim = 3,
  Clear = 4,
  FGReserve = 5,
  FOCReserve = 6,
  CenterIn = 7,
  CenterOut = 8,
}

export const kStockType = [
  { value: StockType.FG, name: 'FG' },
  { value: StockType.FOC, name: 'FOC' },
  { value: StockType.Claim, name: 'Claim' },
  { value: StockType.Clear, name: 'Clear' },
  { value: StockType.FGReserve, name: 'FGReserve' },
  { value: StockType.FOCReserve, name: 'FOCReserve' },
  { value: StockType.CenterIn, name: 'CenterIn' },
  { value: StockType.CenterOut, name: 'CenterOut' },
];
