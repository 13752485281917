import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { DocumentService } from '../../../document.service';
import { ApiService } from 'app/main/service/api.service';
import { Quotation } from 'app/main/model/Quotation';
import THBText from 'thai-baht-text';
import CustomerAddress from 'app/main/model/CustomerAddress';
import Customer from 'app/main/model/Customer';
import { TimelineItem } from 'app/main/components/horizontal-timeline/horizontal-timeline.component';
import { User } from 'app/auth/models';
import { DebitNote, TempRowDebitNote } from 'app/main/model/DebitNote';
import { AuthenticationService } from 'app/auth/service';
import { CurrencyPipe } from '@angular/common';
import { CalculateFuncService } from 'app/main/service/calculate-func.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';

@Component({
  selector: 'app-debit-note-detail-export',
  templateUrl: './debit-note-detail-export.component.html',
  styleUrls: ['./debit-note-detail-export.component.scss'],
})
export class DebitNoteDetailExportComponent implements OnInit {
  contentHeader: object;
  typeDocName: string;
  currentUser: User;
  timelineData: TimelineItem[];
  passObj: object;
  debitNoteObj: DebitNote;
  customerAddressObj: CustomerAddress;
  customerObj: Customer;

  apiPath: string;
  apiPathPreAmount: string;
  editPathUrl: string;

  docID: string;
  isLoading: boolean;

  sumNetPrice: number;
  sumVatPrice: number;
  titleDoc: string = 'ใบเพิ่มหนี้';
  PreAmount: number;
  refDetailUrl: string;

  constructor(
    private _documentService: DocumentService,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _documentApiService: DocumentApiService,
    private _route: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private _apiService: ApiService,
    private _calculateService: CalculateFuncService,
    private _globalFuncService: GlobalFuncService
  ) {
    this.apiPath = this._documentService.debitNote.ApiPath;
    this.apiPathPreAmount = this._documentService.invoice.ApiPath;
    this.initData();
  }
  SetLoadingState() {
    this.isLoading = true;
  }
  SetLoadedState() {
    this.isLoading = false;
  }
  ngOnInit(): void {
    var self = this;

    this._authenticationService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    this.contentHeader = {
      headerTitle: 'Document.DebitNote',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
          //check path
          {
            name: this._translateService.instant('Document.DebitNote'),
            isLink: true,
            link: '/document/debit-note/view',
          },
          {
            name:
              'Detail ' + this._translateService.instant('Document.DebitNote'),
            isLink: false,
            link: '/document/debit-note/view',
          },
        ],
      },
    };
  }
  initData(): void {
    this.SetLoadingState();
    this.typeDocName = 'debitNoteCode'; //code
    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
    });
    console.log('Doc id : ' + this.docID);
    console.log(this.apiPath);

    this.getDebitNoteData();
  }

  getDebitNoteData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocData(self.apiPath, self.docID)
        .subscribe((res) => {
          self.debitNoteObj = res.data.resultData[0];
          self.calculateSummary();
          self.SetLoadedState();
          self.getCustomer(self.debitNoteObj.customerId);
          self.getPreAmount(self.debitNoteObj.invoiceId);
        });
    });
  }
  getCustomer(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService.GetDataById('customer', customerId).subscribe((res) => {
        self.customerObj = res.data.resultData[0];
        self.getCustomerAddress(customerId);
      });
    });
  }
  getPreAmount(invoiceId) {
    this._documentApiService
      .GetDocData('invoice', invoiceId)
      .subscribe((res) => {
        let find = res.data.resultData[0];
        console.log('Invoice', find.grandTotal);
        this.PreAmount = find.preTaxAmountTotal;
      });
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData[0];
          self.customerAddressObj = res.data.resultData.find((it) => {
            return it['useForShipping'] == true;
          });

          this.passObj = {
            quotationObj: this.debitNoteObj,
            titleTh: 'ใบเพิ่มหนี้',
            titleEn: 'Debit Note',
            customerObj: this.customerObj,
            customerAddressObj: this.customerAddressObj[0],
          };

          console.log(this.passObj);
        });
    });
  }

  //change model
  calculateSummary() {
    let itemList = [];

    this.debitNoteObj.item.forEach((element, index) => {
      let tempRow: TempRowDebitNote = new TempRowDebitNote();
      tempRow.rowType = 'item';
      tempRow.itemId = element.itemId;
      tempRow.description = element.description[0].description;
      tempRow.discount = element.discount;
      tempRow.quantity = element.quantity;
      tempRow.unit = element.unit;
      tempRow.unitPrice = element.unitPrice;
      tempRow.whtRate = 0.0;

      itemList.push(tempRow);
    });

    this.sumNetPrice = this._calculateService.calculateNet(
      itemList,
      true,
      this.debitNoteObj.taxRate,
      this.debitNoteObj.discount
    );

    this.sumVatPrice = this._calculateService.calculateVat(
      this.sumNetPrice,
      this.debitNoteObj.taxRate
    );
  }

  convertNumbertoMoneyFormat(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }
}
