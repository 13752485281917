<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <section id="knowledge-base-search">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body row">
                            <div class="col-12 col-sm-12 col-md-4">
                                <div class="scope-custom">
                                    <label for="">{{ 'General.SearchBy' | translate }}</label>
                                    <select class="custom-select" [(ngModel)]="searchByName">
                                        <option [ngValue]="0">{{ 'ViewTable.CustomerName' | translate }}</option>
                                        <option [ngValue]="1">{{ 'ViewTable.CustomerCode' | translate }}</option>
                                        <option [ngValue]="2">{{ 'ViewTable.OrderCode' | translate }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4">
                                <div class="scope-custom">
                                    <label for="first-name-vertical">{{
                                        "General.Search" | translate
                                        }}</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" #searchbar name="searchbar"
                                            placeholder="{{ 'General.Search' | translate }}" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4">
                                <div class="scope-custom">
                                    <label for="first-name-vertical">{{
                                        "General.Status" | translate
                                        }}</label>
                                    <select class="custom-select" [(ngModel)]="searchByStatus">
                                        <option [ngValue]="null">{{'General.All' | translate }}
                                        </option>
                                        <option [ngValue]="0">{{'BookingCalendar.Status.WaitApprove' | translate }}
                                        </option>
                                        <option [ngValue]="1">{{ 'BookingCalendar.Status.Approved' | translate }}
                                        </option>
                                        <option [ngValue]="2">{{ 'BookingCalendar.Status.Reject' | translate }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="card-body row">
                            <div class="col-12 px-0 d-flex justify-content-between">
                                <div class="col-12 px-0 d-flex justify-content-center">
                                    <button (click)="!isLoading ? ResetSearch() : false" [disabled]="isLoading"
                                        class="btn btn-secondary" style="margin-right: 0.5rem">
                                        <i data-feather="refresh-ccw" class="mr-1"></i>{{ "General.Reset" | translate }}
                                    </button>

                                    <button (click)="!isLoading ? SubmitSearch() : false" [disabled]="isLoading"
                                        class="btn btn-primary" style="margin-left: 0.5rem">
                                        <i data-feather="search" class="mr-1"></i>{{ "General.Search" | translate }}
                                    </button>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="card">
            <div class="card-body">
                <table class="table" datatable [dtOptions]="dtOptions">
                    <thead>
                        <tr>
                            <th width="8%" class="text-center">No</th>
                            <th>Order No.</th>
                            <th class="text-end pr-1">
                                <div style="padding-right: 1.5rem !important">Price</div>
                            </th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Docuemnt Status</th>
                            <th>From Customer</th>


                            <th>Date</th>
                            <th width="15%">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="w-100" *ngIf="isLoading">
                            <td class="text-center" *ngFor="let num of [].constructor(8)">
                                <div class="skeleton-loading"></div>
                            </td>
                        </tr>

                        <tr *ngIf="returnInvoiceList.length < 1 && !isLoading">
                            <td colspan="100%" class="text-center">
                                <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
                            </td>
                        </tr>


                        <tr *ngFor="let returnInvoice of returnInvoiceList; index as ind">
                            <td class="text-center">{{ (page - 1) * pageSize + ind + 1 }}</td>
                            <td><a class="text-primary" (click)="openRefundModal(returnInvoice)">{{
                                    returnInvoice.invoiceCode
                                    }}</a></td>
                            <td>
                                <div class="text-end" style="padding-right: 1.5rem !important">
                                    {{ returnInvoice.grandTotal | number : '1.2-2' }}
                                </div>
                            </td>
                            <td class="text-center">
                                <div [ngClass]="{'text-success' : returnInvoice.returnState == stateEnum.Approved,
                                'text-warning' : returnInvoice.returnState == stateEnum.WaitApprove,
                                'text-danger' : returnInvoice.returnState == stateEnum.Reject }">{{
                                    getStatusLabel(returnInvoice.returnState) }}</div>
                            </td>
                            <td class="text-center">
                                <app-document-status-badge [status]="returnInvoice.transactionStatusId"
                                    [isDelete]="returnInvoice.isDelete"></app-document-status-badge>
                            </td>
                            <td><a [routerLink]="['/customer/detail/' + returnInvoice.customerId]" target="_blank"
                                    ngbTooltip="{{ returnInvoice.customerName }}">
                                    <span class="text-primary text-truncate"> {{ returnInvoice.customerName }}</span>

                                </a></td>


                            <td>
                                <div class="text-dark">
                                    <span *ngIf="currentLang == 'th'">
                                        {{ returnInvoice.createDate | thaidate : 'DD MMM YYYY' }}</span>
                                    <span *ngIf="currentLang == 'en'">
                                        {{ returnInvoice.createDate | date : 'dd MMM YYYY' }}
                                    </span>

                                </div>
                                <small>{{ returnInvoice.createDate | date : 'HH:mm' }} <span
                                        *ngIf="currentLang == 'th'">น.</span></small>
                            </td>
                            <td>
                                <div class="d-flex">
                                    <a class="btn btn-warning mr-1" (click)="openRefundModal(returnInvoice)"><i
                                            data-feather="edit" ngbTooltip="Adjust"></i></a>
                                    <a *ngIf="!returnInvoice.isVoid && returnInvoice.returnState == stateEnum.Approved"
                                        class="btn btn-primary mr-1" (click)="voidDocumentModal(returnInvoice)">
                                        <i data-feather="x-square" ngbTooltip="Adjust"></i>
                                        {{ 'ReturnProduct.Claim' | translate }}
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>