import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { ApiService } from 'app/main/service/api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class GeneralComponent implements OnInit {
  formGroup: FormGroup;
  formDisabled: boolean = true;

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private _formBuilder: FormBuilder,
    private _apiService: ApiService,
    private _modalService: NgbModal,
    private _componentsService: ComponentsService
  ) {
  }


  ngOnInit(): void {}
}
