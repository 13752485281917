import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { ComponentsModule } from 'app/main/components/components.module';
import { RolesetComponent } from './roleset.component';
import { AuthGuard } from 'app/auth/helpers';
import { RolesetFormComponent } from './roleset-form/roleset-form.component';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';

const routes: Routes = [
  {
    path: 'admin/roleset',
    component: RolesetComponent,
    canActivate: [AuthGuard],
    data: { animation: 'roles-manage', roles: ['SuperAdmin'] },
  },
  {
    path: 'admin/roleset/create',
    component: RolesetFormComponent,
    canActivate: [AuthGuard],
    data: { animation: 'roles-create', roles: ['SuperAdmin'] },
  },
  {
    path: 'admin/roleset/edit/:id',
    component: RolesetFormComponent,
    canActivate: [AuthGuard],
    data: { animation: 'roles-create', roles: ['SuperAdmin'] },
  },
];
@NgModule({
  declarations: [RolesetComponent,RolesetFormComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    CoreCommonModule,
    ComponentsModule,
    ContentHeaderModule,
    TranslateModule,
    NgbModule,
    NgbPaginationModule,
    DataTablesModule
  ],
  exports: [RolesetComponent,RolesetFormComponent],
})
export class RolesetModule {}
