import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CompanyVideoComponent } from './company-video.component';
import { CompanyVideoCreateComponent } from './company-video-create/company-video-create.component';
import { AuthGuard } from 'app/auth/helpers';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';
import { CmsListModule } from '../cms-list/cms-list.module';

const path: string = 'cms/company-video';

const routes: Routes = [
  {
    path: `${path}`,
    component: CompanyVideoComponent,
    canActivate: [AuthGuard],
    data: { animation: 'company-video-manage' },
  },
  {
    path: `${path}/create`,
    component: CompanyVideoCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'company-video-create' },
  },
  {
    path: `${path}/edit/:id`,
    component: CompanyVideoCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'company-video-edit' },
  },
];

@NgModule({
  declarations: [CompanyVideoComponent, CompanyVideoCreateComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CmsFormpageModule,
    CmsListModule,
  ],
  exports: [CompanyVideoComponent, CompanyVideoCreateComponent],
})
export class CompanyVideoModule {}
