<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>



    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-body">
            <ng-container *ngTemplateOutlet="
                tableTopFive;
                context: {
                  title: 'Dashboard.Member.BestAllTime',
                  dataList: bestTeamListAllTime
                }
              "></ng-container>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-body">
            <ng-container *ngTemplateOutlet="
                tableTopFive;
                context: {
                  title: 'Dashboard.Member.WorstAllTime',
                  dataList: worstTeamListAllTime
                }
              "></ng-container>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-body">
            <ng-container *ngTemplateOutlet="
                tableTopFiveSearch;
                context: {
                  title: 'Dashboard.Member.BestInMonth',
                  dataList: bestTeamList,
                  mode: 1,
                  selectedYear: selectedBestYear,
                  selectedMonth: selectedBestMonth
                }
              "></ng-container>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-body">
            <ng-container *ngTemplateOutlet="
                tableTopFiveSearch;
                context: {
                  title: 'Dashboard.Member.WorstInMonth',
                  dataList: worstTeamList,
                  mode: 2,
                  selectedYear: selectedWorstYear,
                  selectedMonth: selectedWorstMonth
                }
              "></ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div class="col-6 border align-self-center py-3">
            <h4 class="text-center">{{ 'MLM.TotalMember' | translate }}</h4>
          </div>
          <div class="col-6 border align-self-center py-3">
            <h4 class="text-center"><span class="text-primary">{{ allMemberCount | number }}</span> {{ 'MLM.MemberUnit'
              | translate }}</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="row demographic-map">
      <div class="col-12 col-lg-6">
        <div class="card card-body">
          <h5 class="general-title mb-3 pb-2">{{ 'MLM.MemberDemographicMap' | translate }}</h5>

          <div id="chartdiv" style="width: 100%; height: 663px"></div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <ng-container *ngTemplateOutlet="resultDisplay"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #tableTopFive let-title="title" let-dataList="dataList">
  <div>
    <h4 class="general-title mb-1 pb-2">{{ title | translate }}</h4>
    <table class="fixed-layout w-100">
      <thead>
        <tr>
          <th width="10%" class="text-center">#</th>
          <th width="40%">{{ 'Form.Name' | translate }}</th>
          <th class="text-end">{{ 'Dashboard.Revenue.Title' | translate }}</th>
          <th class="text-end">{{ 'BonusPlan.PersonalBonus' | translate }}</th>

          <th width="10%"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of dataList; index as ind">
          <td class="text-center">{{ ind + 1 }}</td>
          <td>
            <div>
              <span class="text-primary">{{ data.name ? data.name : '-' }}</span> <br>
              <small> <span class="text-dark">Code:</span> {{data.repoveCode ?
                data.repoveCode : '-'}} </small>
            </div>
          </td>
          <td class="text-end">{{ data.amount | number : "1.2-2" }}</td>
          <td class="text-end text-primary">{{ data.performance | number : "1.2-2" }}</td>
          <td class="text-center">
            <!-- [routerLink]="['/dashboard/member/detail/' + data.id]" -->
            <a [routerLink]="['/customer/detail/' + data.id]" target="_blank"
              class="btn btn-icon btn-icon rounded-circle btn-outline-success mr-50">
              <i data-feather="eye"></i>
            </a>
          </td>
        </tr>

        <ng-container *ngIf="(memberPerformanceListLength - dataList.length ) > 0">
          <tr *ngFor="let num of [].constructor(memberPerformanceListLength - dataList.length); index as ind">
            <td class="text-center">{{ dataList.length + (ind + 1) }}</td>
            <td class="text-start">
              <div class="d-flex align-items-center">
                <div>
                  -
                </div>
                <div class="d-block">
                  &nbsp;<br><span>&nbsp;</span>
                </div>
              </div>
            </td>
            <td class="text-end">-</td>
            <td class="text-end">-</td>
            <td class="text-center">-</td>
          </tr>
        </ng-container>

      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #tableTopFiveSearch let-title="title" let-dataList="dataList" let-mode="mode"
  let-selectedYear="selectedYear" let-selectedMonth="selectedMonth">
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="mb-0">{{ title | translate }}
        <span *ngIf="currentLang == 'en'">
          {{this.months[selectedMonth].nameEN}}/{{selectedYear}}
        </span>
        <span *ngIf="currentLang == 'th'">
          {{this.months[selectedMonth].nameTH}}/{{selectedYear}}
        </span>
        <!-- <span> In {{this.months[selectedMonth].name}}/{{selectedYear}}</span> -->
      </h4>

      <!-- <button class="btn btn-primary" (click)="exportReport(mode,selectedYear,selectedMonth)">Export</button> -->
    </div>
    <hr class="general-title">
    <div class="search-panal-top-5">

      <div class="d-flex col-12 justify-content-center align-items-center">
        <div class="mr-1">
          <label for="">{{ 'Dashboard.Revenue.Year' | translate }}</label>
          <select class="custom-select mr-1" (change)="onYearChange(mode, $event.target.value)">
            <option *ngFor="let year of yearList" [value]="year" [selected]="year == currentDate.getFullYear()">{{ year
              }}</option>
          </select>
        </div>
        <div>
          <label for="">{{ 'Dashboard.Revenue.Month' | translate }}</label>
          <select class="custom-select mr-1" (change)="onMonthChange(mode, $event.target.value)">
            <option *ngFor="let month of months" [value]="month.value"
              [selected]="month.value == currentDate.getMonth()">
              <span *ngIf="currentLang == 'en'">
                {{ month.nameEN }}
              </span>
              <span *ngIf="currentLang == 'th'">
                {{ month.nameTH }}
              </span>
            </option>
          </select>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-2 mb-2">
        <button class="btn btn-secondary mr-1" (click)="resetSearch()"><i data-feather="refresh-ccw" class="mr-1"></i>{{
          "General.Reset" | translate }}</button>
        <button class="btn btn-primary" (click)="SearchMemberPerformanceResult(mode)">
          <i data-feather="search" class="mr-1"></i>{{
          "General.Search" | translate }}
        </button>
      </div>

    </div>
    <table class="fixed-layout w-100">
      <thead>
        <tr>
          <th width="10%" class="text-center">#</th>
          <th width="40%">{{ 'Form.Name' | translate }}</th>
          <th class="text-end">{{ 'Dashboard.Revenue.Title' | translate }}</th>
          <th class="text-end">{{ 'BonusPlan.PersonalBonus' | translate }}</th>
          <th width="10%"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of dataList; index as ind">
          <td class="text-center">{{ ind + 1 }}</td>
          <td>
            <div>
              <span class="text-primary">{{ data.name ? data.name : '-' }}</span> <br>
              <small> <span class="text-dark">Code:</span> {{data.repoveCode ?
                data.repoveCode : '-'}} </small>
            </div>
          </td>
          <td class="text-end">{{ data.amount | number : "1.2-2" }}</td>
          <td class="text-end text-primary">{{ data.performance | number : "1.2-2" }}</td>
          <td class="text-center">
            <a [routerLink]="['/customer/detail/' + data.id]" target="_blank"
              class="btn btn-icon btn-icon rounded-circle btn-outline-success mr-50">
              <i data-feather="eye"></i>
            </a>
          </td>
        </tr>

        <ng-container *ngIf="(memberPerformanceListLength - dataList.length ) > 0">
          <tr *ngFor="let num of [].constructor(memberPerformanceListLength - dataList.length); index as ind">
            <td class="text-center">{{ dataList.length + (ind + 1) }}</td>
            <td class="text-start">
              <div class="d-flex align-items-center">
                <div>
                  -
                </div>
                <div class="d-block">
                  &nbsp;<br><span>&nbsp;</span>
                </div>
              </div>
            </td>
            <td class="text-end">-</td>
            <td class="text-end">-</td>
            <td class="text-center">-</td>
          </tr>
        </ng-container>

      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #resultDisplay>
  <div class="card">
    <div class="card-body">
      <h4 class="general-title mb-1 pb-2">{{ 'MLM.Filter' | translate }}</h4>
      <div class="form-group row">
        <div class="col-md-6">
          <div class="col-form-lable">{{ 'Region.Title' | translate }}</div>
          <ng-select [multiple]="true" [closeOnSelect]="false" [searchable]="false" [(ngModel)]="regionSelected"
            (change)="onChangeRegion()">
            <!-- <ng-option [value]="'all'">ทั้งหมด</ng-option> -->
            <ng-option [value]="1">{{ 'Region.Northern' | translate }}</ng-option>
            <ng-option [value]="2">{{ 'Region.Central' | translate }}</ng-option>
            <ng-option [value]="3">{{ 'Region.Northeastern' | translate }}</ng-option>
            <ng-option [value]="4">{{ 'Region.Western' | translate }}</ng-option>
            <ng-option [value]="5">{{ 'Region.Eastern' | translate }}</ng-option>
            <ng-option [value]="6">{{ 'Region.Southern' | translate }}</ng-option>
          </ng-select>
        </div>

        <div class="col-md-6">
          <div class="col-form-lable">{{ 'Address.province' | translate }}</div>
          <ng-select [multiple]="true" [closeOnSelect]="false" [(ngModel)]="provinceSelected"
            [disabled]="regionSelected.length > 1" (change)="onChangeProvince()">
            <ng-option [value]="province" *ngFor="let province of provinceList">
              <span *ngIf="currentLang != 'en'">
                {{ province.provinceName }}
              </span>
              <span *ngIf="currentLang != 'th'">
                {{ province.provinceNameEn }}
              </span>
            </ng-option>
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="col-form-lable">{{'Stock.From' | translate}}</div>

          <div class="d-flex align-items-center mt-md-0 mt-1 range-datepicker">
            <div class="calendar-datepicker-icon">
              <i class="font-medium-2" data-feather="calendar"></i>
            </div>
            <ng2-flatpickr class="w-100 date-form-control" #DemographicStartDatePicker
              [setDate]="StartDateOptions.defaultDate" (change)="onDateFromChange($event)" [config]="StartDateOptions"
              name="StartDate" [(ngModel)]="StartDate"></ng2-flatpickr>
          </div>


        </div>

        <div class="col-md-6">
          <div class="col-form-lable">{{'Stock.To' | translate}}</div>

          <div class="d-flex align-items-center mt-md-0 mt-1 range-datepicker">
            <div class="calendar-datepicker-icon">
              <i class="font-medium-2" data-feather="calendar"></i>
            </div>

            <ng2-flatpickr class="w-100 date-form-control" [hidden]="!StartDate[0]" #DemographicEndDatePicker
              [setDate]="EndDateOptions.defaultDate" [config]="EndDateOptions" name="EndDate"
              [(ngModel)]="EndDate"></ng2-flatpickr>


            <input disabled [hidden]="StartDate[0]" class="form-control">


          </div>

        </div>
      </div>


      <!-- <ng2-flatpickr class="w-100 date-form-control" #DemographicDateRangePicker
        [setDate]="DateRangeOptions.defaultDate" [config]="DateRangeOptions" name="DateRange"
        [(ngModel)]="DateRange"></ng2-flatpickr> -->

      <div class="d-flex justify-content-center align-items-center mt-2">
        <a (click)="resetResult()" class="btn btn-secondary ml-3"><i data-feather="refresh-cw" class="mr-1"></i>{{
          'General.Reset' | translate }}</a>
        <a (click)="searchResult()" class="btn btn-primary ml-1"><i data-feather="search" class="mr-1"></i>{{
          'General.Search' | translate }}</a>
      </div>
    </div>
  </div>

  <div class="card card-body">
    <h4 class="general-title mb-2 pb-2">{{ 'MLM.Result' | translate }}</h4>
    <div class="d-flex total-member-row">
      <div class="col-6 title-box">
        <h5 class="text-center">{{ 'MLM.TotalMember' | translate }}</h5>
      </div>
      <div class="col-6 align-self-center">
        <h5 class="text-center amount-text">{{ totalMemberCount | number }}</h5>
        <p class="text-center mb-0 unit-text">{{ 'MLM.MemberUnit' | translate }}</p>
      </div>
    </div>

    <div class="d-flex">
      <div class="col-8 pl-0 align-content-between">
        <div class="d-flex new-member-row">
          <div class="col-6 title-box">
            <h5 class="text-center">{{ 'MLM.NewMember' | translate }}</h5>
          </div>
          <div class="col-6 align-self-center">
            <h5 class="text-center amount-text">{{ newMemberCount | number }}</h5>
            <p class="text-center mb-0 unit-text">{{ 'MLM.MemberUnit' | translate }}</p>
          </div>
        </div>
        <div class="d-flex existing-member-row">
          <div class="col-6 title-box">
            <h5 class="text-center">{{ 'MLM.ExistMember' | translate }}</h5>
          </div>
          <div class="col-6 align-self-center">
            <h5 class="text-center amount-text">{{ existingMemberCount | number }}</h5>
            <p class="text-center mb-0 unit-text">{{ 'MLM.MemberUnit' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="col-4 px-0">
        <div class="h-100 percentage-member" style="display: grid; place-items: center">
          <h3>{{ percentageMember | number : "1.2-2" }}%</h3>
        </div>
      </div>
    </div>

    <hr class="hr-primary" />

    <div class="d-flex sale-total-row">
      <div class="col-6 title-box">
        <h5 class="text-center">{{ 'MLM.SaleTotal' | translate }}</h5>
      </div>
      <div class="col-6 align-self-center">
        <h5 class="text-center amount-text">
          {{ saleTotalCount | number : "1.2-2" }}
        </h5>
        <p class="text-center mb-0 unit-text">{{ "General.Baht" | translate }}</p>
      </div>
    </div>
  </div>
</ng-template>