import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Customer from 'app/main/model/Customer';
import { ApiService } from 'app/main/service/api.service';
@Component({
  selector: 'app-customer-acc',
  templateUrl: './customer-acc.component.html',
  styleUrls: ['./customer-acc.component.scss'],
})
export class CustomerAccComponent implements OnInit {
  registerForm: FormGroup;
  currentLang: string;
  constructor(
    private _apiService: ApiService,

    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _translateService: TranslateService,
    private _router: Router
  ) {
    this.currentLang = this._translateService.currentLang;

    this.registerForm = this._formBuilder.group({
      id: [null],
      // 'ข้อมูลผู้ติดต่อ'
      //ที่อยู่
      debtor: ['', Validators.required],
      creditor: ['', Validators.required],
    });
  }
  get f() {
    return this.registerForm.controls;
  }

  ngOnInit(): void {}
}
