import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { NewsService } from '../news.service';

@Component({
  selector: 'app-news-create',
  templateUrl: './news-create.component.html',
  styleUrls: ['./news-create.component.scss'],
})
export class NewsCreateComponent implements OnInit, OnDestroy {
  
  config: ICMSConfig;

  constructor(private _newsService: NewsService) {
    this.config = this._newsService.config;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
