<form [formGroup]="branchForm" id="branchFrom">
  <div class="row">

    <div class="col-12" style="margin-bottom: 0.5rem">
      <label class="form-label" for="name">{{
        "Branch.Modal.BranchName.Label" | translate
        }}</label>
      <span class="text-danger">&nbsp;*</span>
      <div class="w-100">
        <input type="text" class="form-control" formControlName="name" placeholder="{{
              'Branch.Modal.BranchName.Placeholder' | translate
            }}" id="name" maxlength="50" [ngClass]="{
              'is-invalid error':
                (isSubmit && f.name?.invalid) ||
                (f.name?.invalid &&
                  (f.name?.dirty || f.name?.touched))
            }" />
        <div class="invalid-feedback">
          <div *ngIf="
                isSubmit ||
                (f.name.invalid && (f.name.dirty || f.name.touched))
              ">
            {{ "Branch.Modal.BranchName.Error" | translate }}
          </div>
        </div>
      </div>
    </div>


    <div class="col-12">
      <div class="form-group row ctm-addr">
        <div class="col-md-6">
          <label class="form-label">{{ "Address.address" | translate }}</label>
          <textarea class="form-control" formControlName="addressDetail" rows="8" [ngClass]="{
            'is-invalid error':
              (isSubmit && f.addressDetail?.invalid) ||
              (f.addressDetail?.invalid &&
                (f.addressDetail?.dirty || f.addressDetail?.touched))
          }"></textarea>

          <div class="invalid-feedback">
            <div *ngIf="
        isSubmit ||
        (f.addressDetail.invalid && (f.addressDetail.dirty || f.addressDetail.touched))
      ">
              {{ "Branch.Modal.BranchAddressNumber.Error" | translate }}
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <label class="form-label">
            {{ "Address.province" | translate }}
            <span class="text-danger">*</span>
          </label>
          <div class="skeleton-select" *ngIf="isLoadingProvince">
            <div class="spinner-border spinner-border-sm mr-50" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <ng-select *ngIf="!isLoadingProvince" class="custom-select" [clearable]="false"
            [style.background]="formDisabled ? '#efefef' : '#fff'"
            placeholder="   -- {{ 'Address.selectProvince' | translate }} --" #c_provinceInput
            formControlName="provinceId" [ngClass]="{
              'is-invalid error':
                (isSubmit && f.provinceId?.invalid) ||
                (f.provinceId?.invalid &&
                  (f.provinceId?.dirty || f.provinceId?.touched)) ||
                (f.provinceId?.value == '' &&
                  (f.provinceId?.dirty || f.provinceId?.touched))
            }" (change)="isSelectProvice($event)" [hideSelected]="false">
            <ng-option [value]="province.id" *ngFor="let province of provinceList">
              <span *ngIf="currentLang != 'en'">
                {{ province.provinceName }}
              </span>
              <span *ngIf="currentLang !=  'th'">
                {{ province.provinceNameEn }}
              </span>
            </ng-option>
          </ng-select>

          <div class="invalid-feedback" [ngClass]="{
              'd-block':
                (isSubmit && f.provinceId?.invalid) ||
                (f.provinceId.invalid &&
                  (f.provinceId.dirty || f.provinceId.touched))
            }">
            <div>
              <div *ngIf="f.provinceId.errors?.required">
                {{ "Address.cCityRequiredError" | translate }}
              </div>
            </div>
          </div>

          <label class="form-label">
            {{ "Address.district" | translate }}
            <span class="text-danger">*</span>
          </label>
          <div class="skeleton-select" *ngIf="isLoadingDistrict">
            <div class="spinner-border spinner-border-sm mr-50" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <ng-select *ngIf="!isLoadingDistrict" class="custom-select" #c_districtInput [clearable]="false"
            [style.background]="formDisabled ? '#efefef' : '#fff'"
            placeholder="-- {{ 'Address.selectDistrict' | translate }} --" formControlName="districtId"
            (change)="isSelectDistrict($event)" [ngClass]="{
              'is-invalid error':
                (isSubmit && f.districtId?.invalid) ||
                (f.districtId?.invalid &&
                  (f.districtId?.dirty || f.districtId?.touched)) ||
                (f.districtId?.value == '' &&
                  (f.districtId?.dirty || f.districtId?.touched))
            }" [hideSelected]="false">
            <ng-option [value]="district.id" *ngFor="let district of districtList">
              <span *ngIf="currentLang != 'en'">
                {{ district.districtName }}
              </span>
              <span *ngIf="currentLang != 'th'">
                {{ district.districtNameEn }}
              </span>
            </ng-option>
          </ng-select>

          <div class="invalid-feedback" [ngClass]="{
              'd-block':
                (isSubmit && f.districtId?.invalid) ||
                (f.districtId.invalid &&
                  (f.districtId.dirty || f.districtId.touched))
            }">
            <div>
              <div *ngIf="f.districtId.errors?.required">
                {{ "Address.cDistrictRequiredError" | translate }}
              </div>
            </div>
          </div>

          <label class="form-label">
            {{ "Address.subdistrict" | translate }}
            <span class="text-danger">*</span>
            <br />
          </label>
          <div class="skeleton-select" *ngIf="isLoadingSubDistrict">
            <div class="spinner-border spinner-border-sm mr-50" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <ng-select *ngIf="!isLoadingSubDistrict" class="custom-select" [clearable]="false"
            placeholder="-- {{ 'Address.selectSubDistrict' | translate }} --" #c_subDistrictInput
            [style.background]="formDisabled ? '#efefef' : '#fff'" formControlName="subDistrictId"
            (change)="getPostCode($event)" [hideSelected]="false" [ngClass]="{
              'is-invalid error':
                (isSubmit && f.subDistrictId?.invalid) ||
                (f.subDistrictId?.invalid &&
                  (f.subDistrictId?.dirty || f.subDistrictId?.touched)) ||
                (f.subDistrictId?.value == '' &&
                  (f.subDistrictId?.dirty || f.subDistrictId?.touched))
            }">
            <ng-option [value]="subDistrict.id" *ngFor="let subDistrict of subDistrictList">
              <span *ngIf="currentLang != 'en'">
                {{ subDistrict.subDistrictName }}
              </span>
              <span *ngIf="currentLang != 'th'">
                {{ subDistrict.subDistrictNameEn }}
              </span>
            </ng-option>
          </ng-select>

          <div class="invalid-feedback" [ngClass]="{
              'd-block':
                (isSubmit && f.subDistrictId?.invalid) ||
                (f.subDistrictId.invalid &&
                  (f.subDistrictId.dirty || f.subDistrictId.touched))
            }">
            <div>
              <div *ngIf="f.subDistrictId.errors?.required">
                {{ "Address.cSubDistrictRequiredError" | translate }}
              </div>
            </div>
          </div>

          <label class="form-label">
            {{ "Address.postalCode" | translate }}
            <span class="text-danger">*</span>
          </label>
          <input #c_postcodeInput class="form-control" formControlName="postCode" type="text" disabled [ngClass]="{
            'is-invalid error':(isSubmit && branchForm.get('postCode').value == '')
          }" />

          <div class="invalid-feedback">
            <div *ngIf="isSubmit ||(f.postCode?.invalid)">
              {{"Branch.Modal.BranchPostCode.Error" | translate }}

            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- BranchPhone -->
    <div class="col-6" style="margin-bottom: 0.5rem">
      <label class="form-label" for="tel">{{
        "Branch.Modal.BranchPhone.Label" | translate
        }}</label>

      <span class="text-danger">&nbsp;*</span>
      <div class="w-100">
        <input type="text" class="form-control" formControlName="tel" placeholder="{{
              'Branch.Modal.BranchPhone.Placeholder' | translate
            }}" id="phone" maxlength="50" [ngClass]="{
              'is-invalid error':
                (isSubmit && f.tel?.invalid) ||
                (f.tel?.invalid &&
                  (f.tel?.dirty || f.tel?.touched))
            }" />


        <div class="invalid-feedback">
          <div *ngIf="
                isSubmit ||
                (f.tel.invalid && (f.tel.dirty || f.tel.touched))
              ">
            {{ "Branch.Modal.BranchPhone.Error" | translate }}
          </div>
        </div>
      </div>
    </div>

    <!-- BranchEmail -->
    <div class="col-6" style="margin-bottom: 0.5rem">
      <label class="form-label" for="email">{{
        "Branch.Modal.BranchEmail.Label" | translate
        }}</label>

      <span class="text-danger">&nbsp;*</span>
      <div class="w-100">
        <input type="text" class="form-control" formControlName="email" placeholder="{{
              'Branch.Modal.BranchEmail.Placeholder' | translate
            }}" id="email" maxlength="50" [ngClass]="{
              'is-invalid error':
                (isSubmit && f.email?.invalid) ||
                (f.email?.invalid &&
                  (f.email?.dirty || f.email?.touched))
            }" />


        <div class="invalid-feedback">
          <div *ngIf="
                isSubmit ||
                (f.email.invalid && (f.email.dirty || f.email.touched))
              ">
            {{ "Branch.Modal.BranchEmail.Error" | translate }}
          </div>
        </div>
      </div>
    </div>

    <!-- BranchMapLink -->
    <div class="col-12" style="margin-bottom: 0.5rem">
      <label class="form-label" for="mapUrl">{{
        "Branch.Modal.BranchMapUrl.Label" | translate
        }}</label>

      <span class="text-danger">&nbsp;*</span>
      <div class="w-100">
        <input type="text" class="form-control" formControlName="googleMapUrl" placeholder="{{
              'Branch.Modal.BranchMapUrl.Placeholder' | translate
            }}" id="mapUrl" maxlength="50" [ngClass]="{
              'is-invalid error':
                (isSubmit && f.googleMapUrl?.invalid) ||
                (f.googleMapUrl?.invalid &&
                  (f.googleMapUrl?.dirty || f.googleMapUrl?.touched))
            }" />


        <div class="invalid-feedback">
          <div *ngIf="
                isSubmit ||
                (f.googleMapUrl.invalid && (f.googleMapUrl.dirty || f.googleMapUrl.touched))
              ">
            {{ "Branch.Modal.BranchMapUrl.Error" | translate }}
          </div>
        </div>
      </div>
    </div>


    <div class="col-12 mb-1">
      <label class="form-label" for="email">{{"Branch.Modal.Weekday.Open"| translate}}</label>


      <div class="custom-control custom-checkbox mr-1 mb-1">
        <input type="checkbox" class="custom-control-input" id="usedWeekday" (change)="isUseWeekday($event)"
          formControlName="usedWeekday" />
        <label class="custom-control-label" for="usedWeekday">{{"Branch.Modal.Weekday.Label"| translate}}</label>
      </div>

      <ng-container *ngIf="branchForm.get('usedWeekday').value">
        <div class="pl-1 mb-1" style="width: 50%">
          <label for="">{{'Branch.Modal.Weekday.Start'| translate}}</label>
          <ng2-flatpickr class="flatpickr-input" [config]="timeOptions" formControlName="weekdayStartTime"
            placeholder="{{'Branch.Modal.Weekday.Placeholder'|translate}}"
            (change)="onTimeChange($event)"></ng2-flatpickr>
        </div>
        <div class="pl-1 text-danger">
          <div
            *ngIf="isSubmit &&  (branchForm.get('usedWeekday').value == true && branchForm.get('weekdayStartTime').value == null)">
            {{ "Branch.Modal.Weekday.Error" | translate }}
          </div>
        </div>

        <div class="pl-1 mb-1" style="width: 50%">
          <label for="">{{"Branch.Modal.Weekday.End"| translate}}</label>
          <ng2-flatpickr [config]="endTimeOptions" formControlName="weekdayEndTime"
            placeholder="{{'Branch.Modal.Weekday.Placeholder'|translate}}"></ng2-flatpickr>
        </div>
        <div class="pl-1 text-danger mb-1">
          <div
            *ngIf="isSubmit &&  (branchForm.get('usedWeekday').value == true && branchForm.get('weekdayEndTime').value == null)">
            {{ "Branch.Modal.Weekday.Error" | translate }}
          </div>
        </div>

      </ng-container>

      <div class="custom-control custom-checkbox mr-1 mb-1">
        <input type="checkbox" class="custom-control-input" id="usedWeekend" formControlName="usedWeekend" />
        <label class="custom-control-label" for="usedWeekend">{{"Branch.Modal.Weekend.Label"|translate}}</label>
      </div>

      <ng-container *ngIf="branchForm.controls.usedWeekend?.value">
        <div class="pl-1" style="width: 50%">
          <label for="">{{"Branch.Modal.Weekend.Start"| translate}}</label>
          <ng2-flatpickr [config]="weekendStartTimeOptions" formControlName="weekendStartTime"
            placeholder="{{'Branch.Modal.Weekday.Placeholder'|translate}}"></ng2-flatpickr>
        </div>
        <div class="pl-1 text-danger mb-1">
          <div
            *ngIf="isSubmit &&  (branchForm.get('usedWeekend').value == true && branchForm.get('weekendStartTime').value == null)">
            {{ "Branch.Modal.Weekday.Error" | translate }}
          </div>
        </div>

        <div class="pl-1 mb-1" style="width: 50%">
          <label for="">{{"Branch.Modal.Weekend.End"| translate}}</label>
          <ng2-flatpickr [config]="weekendEndTimeOptions" formControlName="weekendEndTime"
            placeholder="{{'Branch.Modal.Weekday.Placeholder'|translate}}"></ng2-flatpickr>
        </div>
        <div class="pl-1 text-danger mb-1">
          <div
            *ngIf="isSubmit &&  (branchForm.get('usedWeekend').value == true && branchForm.get('weekendEndTime').value == null)">
            {{ "Branch.Modal.Weekday.Error" | translate }}
          </div>
        </div>
      </ng-container>

    </div>




    <!--BranchColor-->
    <div class="col-12" style="margin-bottom: 0.5rem">
      <label class="form-label" for="colorId">{{
        "Branch.Modal.BranchColor.Label" | translate
        }}</label>

      <span class="text-danger">&nbsp;*</span>
      <div class="w-100">
        <div class="branch-color-options">
          <ul class="list-unstyled mb-0">
            <span *ngFor="let item of colorList; index  as id">
              <li [ngClass]="colorClicked === item.id ? 'd-inline-block selected' : 'd-inline-block'"
                (click)="selectColor(item.id)" [value]="item.colorCode">
                <div class="color-option b" style="--main-color: {{item.colorCode}}">
                  <div class="filloption b"></div>
                </div>
              </li>
            </span>
          </ul>
        </div>


        <div class="text-danger">
          <div *ngIf="
                isSubmit && (colorClicked == 0 || colorClicked == null)
              ">
            {{ "Branch.Modal.BranchColor.Error" | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <label for="colFormLabelLg" class="form-label">
        {{ "Branch.Modal.BranchImage.Label" | translate }}
        <span class="text-danger">*</span>
      </label>
      <div class="">
        <app-image-upload #imageUploadComponent></app-image-upload>
      </div>
    </div>

  </div>
</form>