import { Component, OnInit } from '@angular/core';

import { ICMSConfig } from 'app/main/config/CMSInterface';
import { CompanyVideoService } from './company-video.service';

@Component({
  selector: 'app-company-video',
  templateUrl: './company-video.component.html',
  styleUrls: ['./company-video.component.scss'],
})
export class CompanyVideoComponent implements OnInit {
  config!: ICMSConfig;
  keyName: string;

  constructor(private _companyVideoService: CompanyVideoService) {
    this.config = this._companyVideoService.config;
    this.keyName = this._companyVideoService.keyName;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
