class CustomerAddress {
  id: string;
  customerId: string;
  businessName?: string;
  detail: string;
  addressNumber: string;
  village: string;
  alley: string;
  road: string;
  country: number;
  provinceId: string;
  provinceName?: string;
  districtId: string;
  districtName?: string;
  subDistrictId: string;
  subDistrictName?: string;
  useForShipping: boolean;
  useForBilling: boolean;
  isLegalAddr: boolean;
  isHeadBranch: boolean;
  branchNumber: number;
  branchName: string;
  firstName: string;
  lastName: string;
  taxId: any;
  addrLatitude: number;
  addrLongitude: number;
  postCode: string;
  phone: string;
  fax: string;
  taxpayerNumber: string;
  isActive: boolean;
  isDelete: boolean;
  isDefault: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  deleteBy: any;
  deleteDate: any;
}

export default CustomerAddress;
