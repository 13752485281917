import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SvExchangeRateComponent } from './sv-exchange-rate.component';
import { AuthGuard } from 'app/auth/helpers';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';

const path: string = 'mlm-config/sv-exchange-rate-config';
const routes: Routes = [
  {
    path: path,
    component: SvExchangeRateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'mlm-sv-exchange-rate' },
  },
];

@NgModule({
  declarations: [SvExchangeRateComponent],
  imports: [
    CommonModule,
    CoreCommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ContentHeaderModule,
    DataTablesModule,
    TranslateModule,
  ]
})
export class SvExchangeRateModule { }
