<div class="lang-switcher-sidebar nav-vertical" *ngIf="isUseMultipleLang">
  <ul ngbNav class="nav nav-pills flex-column text-nowrap mb-0">
    <li ngbNavItem *ngFor="let lang of langList">
      <a ngbNavLink (click)="selectedLanguage(lang)" [class.active]="selectedLang === lang">{{ lang | uppercase }}</a>
    </li>
  </ul>
</div>

<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <a [routerLink]="[pathUrl]">
          <i data-feather="arrow-left" class="text-primary cursor-pointer back-to-manage-btn"></i>
          {{ 'BreadCrumbs.CMS.Categories' | translate }}
        </a>

        <ul ngbNav class="nav nav-pills">
          <li ngbNavItem *ngFor="let lang of langList">
            <a ngbNavLink (click)="selectedLanguage(lang)" [class.active]="selectedLang === lang">{{ lang | uppercase
              }}</a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="col-md-12">
          <form [formGroup]="categoriesForm">
            <h4 class="mb-2">{{ componentName }} Title</h4>

            <!-- <div formGroupName="categoryname"> -->
            <!-- <div *ngFor="let lang of langList"> -->
            <div class="form-group row">
              <div class="col-sm-3 col-form-label">
                <label for="colFormLabelLg">
                  <!-- {{ "Form.Description" | translate }} -->
                  Category Title
                  <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-sm-9">
                <input type="text" class="form-control" formControlName="categoryname" rows="5" [ngClass]="{
                        'is-invalid error':
                      (isSubmit && f.categoryname?.invalid) ||
                      (f.categoryname?.invalid && (f.categoryname?.dirty || f.categoryname?.touched))
                      }" [placeholder]="'Form.EnterDescription' | translate">
                <div class="invalid-feedback">
                  <div *ngIf="
                          isSubmit ||
                          (f.categoryname.invalid &&
                            (f.categoryname.dirty || f.categoryname.touched))
                        ">
                    {{ "Form.EnterName" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <!-- </div> -->
            <!-- </div> -->

            <div formGroupName="helpcentertitle">
              <div *ngFor="let lang of langList">
                <div class="form-group row" [class.d-none]="selectedLang != lang">
                  <div class="col-sm-3 col-form-label">
                    <label for="colFormLabelLg">
                      Help center Title
                      <span class="text-danger">*</span>
                    </label>
                    <br />
                    <div *ngIf="langList.length > 1" class="badge badge-primary">
                      {{ lang | uppercase }}
                    </div>
                  </div>
                  <div class="col-sm-9">
                    <input type="text" class="form-control" [formControlName]="lang" rows="5" [ngClass]="{
                        'is-invalid error':
                          (isSubmit && f.helpcentertitle?.invalid) ||
                          (f.helpcentertitle?.invalid &&
                            (f.helpcentertitle?.dirty || f.helpcentertitle?.touched))
                      }" [placeholder]="'Form.EnterDescription' | translate">
                    <div class="invalid-feedback">
                      <div *ngIf="
                          isSubmit ||
                          (f.helpcentertitle.invalid &&
                            (f.helpcentertitle.dirty || f.helpcentertitle.touched))
                        ">
                        {{ "Handle.DescriptionAllLanguage" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div formGroupName="faqtitle">
            <div *ngFor="let lang of langList">
              <div class="form-group row" [class.d-none]="selectedLang != lang">
                <div class="col-sm-3 col-form-label">
                  <label for="colFormLabelLg">
                    Faq Title
                    <span class="text-danger">*</span>
                  </label>
                  <br />
                  <div *ngIf="langList.length > 1" class="badge badge-primary">
                    {{ lang | uppercase }}
                  </div>
                </div>
                <div class="col-sm-9">
                  <input type="text" class="form-control" [formControlName]="lang" rows="5" [ngClass]="{
                      'is-invalid error':
                        (isSubmit && f.faqtitle?.invalid) ||
                        (f.faqtitle?.invalid &&
                          (f.faqtitle?.dirty || f.faqtitle?.touched))
                    }" [placeholder]="'Form.EnterDescription' | translate">
                  <div class="invalid-feedback">
                    <div *ngIf="
                        isSubmit ||
                        (f.faqtitle.invalid &&
                          (f.faqtitle.dirty || f.faqtitle.touched))
                      ">
                      {{ "Handle.DescriptionAllLanguage" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

            <div class="form-group row">
              <label for="colFormLabelLg" class="col-sm-3 col-form-label">{{ "Form.Cover" | translate }}
                <span class="text-danger">*</span></label>
              <div class="col-sm-9">
                <app-image-upload #imageUploadComponent></app-image-upload>
              </div>
            </div>

            <hr />


            <h4 class="mb-2">{{ componentName }} Content</h4>

            <div formArrayName="faqdescription">
              <ng-container *ngFor="let dt of arrayForm.controls;let i = index">
                <div [formGroupName]="i">
                  <!-- {{i}} -->
                  <div class="form-group row">
                    <div class="col-md-11">
                      <ng-container *ngFor="let lang of langList;">
                        <div [class.d-none]="selectedLang != lang">

                          <div class="form-group row" formGroupName="title">
                            <div class="col-md-3">
                              <p class="col-form-label">{{ "ContentDocument.Title" | translate }}</p>
                              <div class="badge badge-primary">
                                {{ lang | uppercase }}
                              </div>
                            </div>
                            <div class="col-sm-9">
                              <input class="form-control" type="text" [formControlName]="lang"
                                [placeholder]="'Form.EnterDescription' | translate">
                            </div>
                          </div>
                          <div class="form-group row" formGroupName="description">
                            <div class="col-sm-3">
                              <p class="col-form-label">{{ "ContentDocument.Description" | translate }}</p>
                              <div class="badge badge-primary">
                                {{ lang | uppercase }}
                              </div>
                            </div>
                            <div class="col-sm-9">
                              <textarea class="form-control" [formControlName]="lang"
                                [placeholder]="'Form.EnterDescription' | translate"></textarea>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="col-sm-1">
                      <a *ngIf="categoriesForm.controls.faqdescription?.length > 1"
                        class="remove-button btn btn-primary" (click)="removeRow(i)">
                        <i data-feather="x"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="row">
              <div class="col-sm-4"></div>
              <div class="col-sm-4" style="text-align-last: center; padding-bottom: 10px">
                <a class="btn btn-outline-primary mr-1 text-primary" (click)="addItemRow()">
                  <i data-feather="plus"></i>{{ "DocTable.Button.AddItem" | translate }}
                </a>
              </div>
              <div class="col-sm-4"></div>
            </div>

            <div class="auto-form-footer">
              <a (click)="openCancelModal()" class="btn btn-secondary">{{'Form.Cancel' | translate}}</a>
              <a (click)="openConfirmModal();" class="btn btn-primary">{{'Form.Submit' | translate}}</a>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>