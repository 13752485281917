import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  componentName: string = this._translateService.instant('General.Invoice');
  apiPath: string = 'invoice';
  pathUrl: string = '/invoice';
  apiUrl: string = 'invoice';
  keyName: string = 'customerId';
  constructor(private _translateService: TranslateService) {}
}
