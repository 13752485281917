import { Injectable } from '@angular/core';
import { ApiService } from 'app/main/service/api.service';

@Injectable({
  providedIn: 'root'
})
export class MlmConfigurationService {
  componentName:'Personal Bonus';
  apiUrl: string = 'mlm/MasterPersonalBonus'
  pathUrl: string ='mlm-config/bonus-table-config'

  constructor(private _apiService: ApiService) { }
}
