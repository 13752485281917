<section id="apex-column-charts">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="d-flex justify-content-center align-items-center my-2">
          <div class="mr-1">
            <label>{{ "Stock.From" | translate }}:</label>
          </div>
          <select class="col-2 custom-select mx-1" [(ngModel)]="selectedStartYear" (change)="onStartYearChange()">
            <option *ngFor="let year of yearList" [value]="year.toString()">{{ year }}</option>
          </select>
          <select class="col-2 custom-select mx-0" [disabled]="!selectedStartYear" [(ngModel)]="selectedStartMonth"
            (change)="onStartMonthChange()">
            <option *ngFor="let month of months" [value]="month.value.toString()">{{ month.name }}</option>
          </select>


          <div class="ml-3">
            <label>{{ "Stock.To" | translate }}:</label>
          </div>
          <select class="col-2 custom-select mx-1" [disabled]="!selectedStartYear" [(ngModel)]="selectedEndYear"
            (change)="onYearChange()">
            <option *ngFor="let year of selectedEndYearOptions" [value]="year">{{ year }}</option>
          </select>
          <select class="col-2 custom-select mx-0" [disabled]="!selectedStartYear" [(ngModel)]="selectedEndMonth"
            (change)="onMonthChange()">
            <option *ngFor="let month of selectedEndMonthOptions" [value]="month.value">{{ month.name }}</option>
          </select>
        </div>

        <div class="d-flex justify-content-center">
          <button class="btn btn-secondary" (click)="resetSearchCriteria()"><i data-feather="refresh-ccw"
              class="mr-1"></i>{{
            "General.Reset" | translate }}</button>

          <button (click)="submitDate()" class="btn btn-primary mx-1" style="margin-left: 0.5rem">
            <i data-feather="search" class="mr-1"></i>{{ "General.Search" | translate }}
          </button>
        </div>

        <hr>

        <div class="card-body px-0">
          <div class="card-body px-0">
            <div class="d-flex justify-content-center align-items-center">
              <div class="col-10"></div>
              <button class="btn btn-primary" (click)="exportReport()">
                <span>{{ "General.Export" | translate }}</span>
              </button>
            </div>
          </div>
          <div id="column-chart" #apexColumnChartRef>
            <apx-chart [series]="apexColumnChart.series" [chart]="apexColumnChart.chart" [xaxis]="apexColumnChart.xaxis"
              [yaxis]="apexColumnChart.yaxis" [stroke]="apexColumnChart.stroke" [tooltip]="apexColumnChart.tooltip"
              [colors]="apexColumnChart.colors" [legend]="apexColumnChart.legend"
              [dataLabels]="apexColumnChart.dataLabels" [plotOptions]="apexColumnChart.plotOptions"
              [grid]="apexColumnChart.grid" [noData]="apexColumnChart.noData"></apx-chart>
          </div>
        </div>
        <div class="card-body px-0">
          <div id="column-chart" #newMemberApexColumnChartRef>
            <apx-chart [series]="newMemberApexColumnChart.series" [chart]="newMemberApexColumnChart.chart"
              [xaxis]="newMemberApexColumnChart.xaxis" [yaxis]="newMemberApexColumnChart.yaxis"
              [stroke]="newMemberApexColumnChart.stroke" [tooltip]="newMemberApexColumnChart.tooltip"
              [colors]="newMemberApexColumnChart.colors" [legend]="newMemberApexColumnChart.legend"
              [dataLabels]="newMemberApexColumnChart.dataLabels" [plotOptions]="newMemberApexColumnChart.plotOptions"
              [grid]="newMemberApexColumnChart.grid" [noData]="newMemberApexColumnChart.noData"></apx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>