class Event {
  id?: any;
  name: string;
  title: string;
  subTitle: string;
  detail: string;
  dateFrom: Date;
  dateTo: Date;
  contentDate: Date;
  linkBtnCaption: string;
  linkBtnUrl: string;
  isActive: boolean;
}

export default Event;
