import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { NewsComponent } from './news.component';
import { AuthGuard } from 'app/auth/helpers';
import { NewsCreateComponent } from './news-create/news-create.component';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';
import { CmsListModule } from '../cms-list/cms-list.module';

const path: string = 'cms/news'

const routes: Routes = [
  {
    path: `${path}`,
    component: NewsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'news-manage' },
  },
  {
    path: `${path}/create`,
    component: NewsCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'news-create' },
  },
  {
    path: `${path}/edit/:id`,
    component: NewsCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'news-edit' },
  },
];

@NgModule({
  declarations: [NewsComponent, NewsCreateComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CmsFormpageModule,
    CmsListModule,
  ],
  exports: [NewsComponent],
})
export class NewsModule {}
