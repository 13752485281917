import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { MainConfig } from 'app/main/config/MainConfig';
import Customer from 'app/main/model/Customer';
import { ApiService } from 'app/main/service/api.service';
import { environment } from 'environments/environment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { NotificationService } from '../notification.service';
import { AuthenticationService } from 'app/auth/service';
import { Subscription } from 'rxjs';
import Notification, {
  NotificationStatus,
  typeId,
} from 'app/main/model/Notification';
import { ComponentsService } from 'app/main/components/components.service';
import { NotificationFormConfig } from 'app/main/config/NotificationFormConfig';
import { RankEnum } from 'app/main/model/Rank';

@Component({
  selector: 'app-notification-formpage',
  templateUrl: './notification-formpage.component.html',
  styleUrls: ['./notification-formpage.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationFormpageComponent implements OnInit {
  private dataSubscription: Subscription;
  contentHeader: object;
  summernoteConfig: object;
  callbackUrl: string = '/notification';

  customerList: Customer[];
  notificationObj: Notification;

  componentName: string;
  apiPath: string;
  itemGuid: string;
  currentUser: string = '';

  pathUrl: string = '/notification';
  selectedLang: string = environment.langDefault;
  langList: string[] = environment.langContent;

  formGroup: FormGroup;
  isSubmit: boolean = false;
  isEditing: boolean = false;
  modeText: string;
  isLoading: boolean;

  notificationTypeList: {
    id: number;
    name: string;
  }[] = [
    {
      id: 0,
      name: 'Boardcast',
    },
    {
      id: 1,
      name: 'Send to Personal',
    },
    {
      id: 2,
      name: 'Send by Rank',
    },
  ];

  notificationTypeIdList: {
    id: number;
    name: string;
  }[] = [
    {
      id: 0,
      name: 'Repove TH',
    },
    {
      id: 1,
      name: 'Promotion',
    },
    {
      id: 2,
      name: 'Order',
    },
    {
      id: 3,
      name: 'Finance',
    },
  ];

  @BlockUI() blockUI: NgBlockUI;

  currentDate: Date = new Date();

  public startNotificationDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y H:i',
    time_24hr: true,
    enableTime: true,
    minuteIncrement: 1,
    minDate: new Date(),
    disabled: true,
    // altInput: true,
    altInputClass: 'form-control flat-picker flatpickr-input',
    // defaultDate: this.currentDate,
  };

  constructor(
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    private notificationForm: NotificationFormConfig,
    private modalService: NgbModal,
    private _translateService: TranslateService,
    private router: Router,
    private _route: ActivatedRoute,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private _componentsService: ComponentsService
  ) {
    this.summernoteConfig = this.notificationForm.summernoteNewsConfig;
    this.formGroup = this._formBuilder.group({
      id: [null],
      name: ['', [Validators.required, Validators.maxLength(100)]],
      // detail: ['', [Validators.required]],
      typeId: [0],
      notificationType: [0],
      sendRankMP: [false],
      sendRankAP: [false],
      sendRankP: [false],
      customerIdList: [[]],
      startNotificationDate: [null],
      sendNow: [true],
      isActive: [true],
      isShowing: [true],
      actorName: [''],
      rankNotifierIdList: [[]],
    });
    this.initConfig();
    this.initForm(this.isEditing);
    this.getCurrentUser();

    // const langDetailControls: any = {};
    // this.langList.forEach((lang) => {
    //   for (const lang of this.langList) {
    //     langDetailControls[lang] = this._formBuilder.control('', [
    //       Validators.required,
    //     ]);

    //   }
    //   this.formGroup.addControl(
    //     'detail',
    //     this._formBuilder.group(langDetailControls)
    //   );
    // });
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Notification.Title',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  initConfig(): void {
    // this._unsubscribeAll = new Subject();
    this.componentName = this.notificationService.componentName;
    this.apiPath = this.notificationService.apiPath;
    this.pathUrl = this.notificationService.pathUrl;
    this.summernoteConfig = this.notificationForm.summernoteNewsConfig;

    this.isEditing = this._route.snapshot.paramMap.get('id') ? true : false;
    this.isLoading = this.isEditing ? true : false;

    this.apiService.SetHttpHeaders(this.authenticationService.tokenValue);

    this.modeText = this.isEditing
      ? this._translateService.instant('General.Edit')
      : this._translateService.instant('General.Create');

    const langTitleControls: any = {};
    const langdescriptionControls: any = {};
    this.langList.forEach((lang) => {
      for (const lang of this.langList) {
        langTitleControls[lang] = this._formBuilder.control('', [
          Validators.maxLength(100),
        ]);

        langdescriptionControls[lang] = this._formBuilder.control('', []);
      }

      this.formGroup.addControl(
        'title',
        this._formBuilder.group(langTitleControls)
      );

      this.formGroup.addControl(
        'description',
        this._formBuilder.group(langdescriptionControls)
      );
    });
    if (!this.isEditing) {
      this.startNotificationDateOptions.defaultDate = new Date();
    }
  }

  initForm(isEditing: boolean): void {
    var self = this;
    if (isEditing) {
      this.itemGuid = this._route.snapshot.paramMap.get('id');
      this._route.paramMap.subscribe((val) => {
        self.itemGuid = self._route.snapshot.paramMap.get('id');
        self.getNotificationData(self.itemGuid);
      });
    } else {
      this.notificationObj = new Notification();
    }
  }

  get f() {
    return this.formGroup.controls;
  }

  getCustomerList() {
    this.apiService.GetAllData('customer').subscribe((res) => {
      this.customerList = res.data.resultData;
    });
  }

  getCurrentUser() {
    this.authenticationService.currentUser.subscribe((res) => {
      this.currentUser = res.username;
    });
  }

  checkNotificationType() {
    if (this.f.notificationType?.value == 1) {
      this.getCustomerList();
    }

    if (this.f.notificationType?.value != 1) {
      this.f.customerIdList.patchValue([]);
    }

    if (this.f.notificationType?.value != 2) {
      this.f.sendRankMP.patchValue(false);
      this.f.sendRankAP.patchValue(false);
      this.f.sendRankP.patchValue(false);
    }
  }

  onCancel() {
    const modalRef = this.modalService.open(ModalComponent, {
      centered: true,
    });

    modalRef.componentInstance.title = this._translateService.instant(
      'Modal.CancelManagePage'
    );
    modalRef.componentInstance.detail = this._translateService.instant(
      'Modal.PressSubmitToCancel'
    );
    modalRef.componentInstance.callBackFunc.subscribe(() => {
      this.router.navigate([this.pathUrl]);
    });
  }

  onSubmit() {
    this.isSubmit = true;

    if (!this.checkValidate()) {
      return;
    }

    const modalRef = this.modalService.open(ModalComponent, {
      centered: true,
    });

    modalRef.componentInstance.title = this._translateService.instant(
      'Modal.ConfirmCreate'
    );
    modalRef.componentInstance.detail = this._translateService.instant(
      'Modal.AreYouSureToCreate'
    );
    modalRef.componentInstance.callBackFunc.subscribe(() => {
      this.Submit();
    });
  }

  checkValidate(): boolean {
    if (this.formGroup.invalid) {
      return false;
    }

    if (
      this.f.notificationType?.value == 2 &&
      !this.f.sendRankP.value &&
      !this.f.sendRankAP.value &&
      !this.f.sendRankMP.value
    ) {
      return false;
    }

    if (
      this.f.notificationType?.value == 1 &&
      this.f.customerIdList.value.length <= 0
    ) {
      return false;
    }

    if (
      new Date(this.f.startNotificationDate.value) <= new Date() &&
      this.f.sendNow.value == false
    ) {
      return false;
    }

    return true;
  }

  getCurrentTime() {
    return new Date();
  }

  selectedLanguage(lang: string) {
    this.selectedLang = lang;
  }

  getNotificationData(guid: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this.apiService.GetDataById(this.apiPath, guid).subscribe((res) => {
        self.notificationObj = res.data.resultData[0];
        // console.log(this.notificationObj)
        self.setFormValue(this.notificationObj);
        self.isLoading = false;
      }, rejects);
    });
  }

  setFormValue(event): void {
    let tempTitle = JSON.parse(this.notificationObj.title);
    let tempDescription = JSON.parse(this.notificationObj.description);
    this.formGroup.get(`name`).patchValue(this.notificationObj.name);
    this.langList.forEach((lang) => {
      this.formGroup.get(`title.${lang}`).patchValue(tempTitle[lang]);
    });

    this.langList.forEach((lang) => {
      this.formGroup
        .get(`description.${lang}`)
        .patchValue(tempDescription[lang]);
    });

    if (this.notificationObj.notificationTypeId == 0) {
      this.formGroup.get('notificationType').patchValue(0);
    }

    if (this.notificationObj.notificationTypeId == 1) {
      this.formGroup.get('notificationType').patchValue(1);

      let customerList: any[] = [];
      this.notificationObj.customerList.forEach((element) => {
        customerList.push(element.name);
      });
      this.formGroup.get('customerIdList').patchValue(customerList);
    }

    if (this.notificationObj.notificationTypeId == 2) {
      this.formGroup.get('notificationType').patchValue(2);

      this.formGroup.get('sendRankMP').patchValue(true);
      this.formGroup.get('sendRankAP').patchValue(true);
      this.formGroup.get('sendRankP').patchValue(true);
    }

    if (this.notificationObj.notificationTypeId == 3) {
      this.formGroup.get('notificationType').patchValue(2);

      this.notificationObj.rankNotifierIdList.forEach((element) => {
        if (element == RankEnum.MasterProvider) {
          this.formGroup.get('sendRankMP').patchValue(true);
        } else if (element == RankEnum.ActiveProvider) {
          this.formGroup.get('sendRankAP').patchValue(true);
        } else if (element == RankEnum.Provider) {
          this.formGroup.get('sendRankP').patchValue(true);
        }
      });
    }

    if (
      this.notificationObj.startNotificationDate <=
      this.notificationObj.createDate
    ) {
      this.formGroup.get('sendNow').patchValue(true);
    } else {
      this.formGroup.get('sendNow').patchValue(false);

      const dateTime = new Date(this.notificationObj.startNotificationDate);
      this.startNotificationDateOptions.defaultDate = dateTime;
      this.formGroup
        .get('startNotificationDate')
        .patchValue(new Date(dateTime));
    }

    console.log('setted value');
  }

  Submit() {
    let tempForm = new FormGroup({});
    this.prepareFinalData(this.formGroup, tempForm);
    // this.blockUI.start(
    if (this.f.notificationType.value == 0) {
      this.apiService.AddData(`${this.apiPath}/All`, tempForm.value).subscribe(
        (res) => {
          console.log('DOM : ' + res.data.resultData);

          this.router.navigate([this.callbackUrl]);
          this._componentsService.SuccessSwal();
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
    } else if (this.f.notificationType.value == 2) {
      if (
        this.f.sendRankMP.value &&
        this.f.sendRankAP.value &&
        this.f.sendRankP.value
      ) {
        this.apiService
          .AddData(`${this.apiPath}/AllRank`, tempForm.value)
          .subscribe(
            (res) => {
              console.log('DOM : ' + res.data.resultData);

              this.router.navigate([this.callbackUrl]);
              this._componentsService.SuccessSwal();
            },
            (err) => {
              this._componentsService.ErrorSwal();
            }
          );
      } else {
        this.apiService
          .AddData(`${this.apiPath}/GroupRank`, tempForm.value)
          .subscribe(
            (res) => {
              console.log('DOM : ' + res.data.resultData);

              this.router.navigate([this.callbackUrl]);
              this._componentsService.SuccessSwal();
            },
            (err) => {
              this._componentsService.ErrorSwal();
            }
          );
      }
    } else if (this.f.notificationType.value == 1) {
      this.apiService
        .AddData(`${this.apiPath}/Group`, tempForm.value)
        .subscribe(
          (res) => {
            console.log('DOM : ' + res.data.resultData);

            this.router.navigate([this.callbackUrl]);
            this._componentsService.SuccessSwal();
          },
          (err) => {
            this._componentsService.ErrorSwal();
          }
        );
    }
    // this.blockUI.stop()
  }

  prepareFinalData(data: any, tempForm: FormGroup) {
    for (let controlName in data.controls) {
      tempForm.addControl(
        controlName,
        this._formBuilder.control(data.get(controlName).value)
      );
    }

    let tempTitle = this.formGroup.get(`title`).value;
    this.langList.forEach((lang) => {
      tempTitle[lang] = this.formGroup.get(`title.${lang}`).value;
    });
    tempForm.removeControl('title');
    tempForm.addControl(
      'title',
      this._formBuilder.control(JSON.stringify(tempTitle), [])
    );

    let tempdescription = this.formGroup.get(`description`).value;
    this.langList.forEach((lang) => {
      tempdescription[lang] = this.formGroup.get(`description.${lang}`).value;
    });
    tempForm.removeControl('description');
    tempForm.addControl(
      'description',
      this._formBuilder.control(JSON.stringify(tempdescription), [])
    );

    let tempNotificationType = this.formGroup.get(`notificationType`).value;
    tempForm.removeControl('notificationType');
    tempForm.addControl(
      'notificationType',
      this._formBuilder.control(tempNotificationType, [])
    );

    let tempActorName = this.currentUser;
    tempForm.removeControl('actorName');
    tempForm.addControl(
      'actorName',
      this._formBuilder.control(tempActorName, [])
    );

    if (!this.f.sendNow.value) {
      let tempArrayStartDate = this.formGroup.get(
        'startNotificationDate'
      ).value;
      let tempStartDate = tempArrayStartDate[0];
      tempForm.removeControl('startNotificationDate');
      tempForm.addControl(
        'startNotificationDate',
        this._formBuilder.control(tempStartDate, [])
      );
    } else {
      let tempStartDate = new Date();
      tempForm.removeControl('startNotificationDate');
      tempForm.addControl(
        'startNotificationDate',
        this._formBuilder.control(tempStartDate, [])
      );
    }

    if (this.f.notificationType.value == 2) {
      let tempRanList = [];
      if (this.f.sendRankMP.value) {
        tempRanList.push(RankEnum.MasterProvider);
      }
      if (this.f.sendRankAP.value) {
        tempRanList.push(RankEnum.ActiveProvider);
      }
      if (this.f.sendRankP.value) {
        tempRanList.push(RankEnum.Provider);
      }

      tempForm.removeControl('rankNotifierIdList');
      tempForm.addControl(
        'rankNotifierIdList',
        this._formBuilder.control(tempRanList, [])
      );
    }
    console.log(tempForm.value);
  }

  displayStatusOn(status: number) {
    switch (status) {
      case NotificationStatus.All:
        return 'ALL';
      case NotificationStatus.Group:
        return 'GROUP ';
      case NotificationStatus.AllRank:
        return 'ALL RANK';
      case NotificationStatus.GroupRank:
        return 'GROUP RANK';
      default:
        return '-';
    }
  }

  displayTypeIdOn(status: number) {
    switch (status) {
      case typeId.RepoveTH:
        return 'Repove TH';
      case typeId.Promotion:
        return 'Promotion ';
      case typeId.Order:
        return 'Order';
      case typeId.Finance:
        return 'Finance';
      default:
        return '-';
    }
  }
}
