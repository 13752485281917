<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section *ngIf="!config.isList">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ "General.Edit" | translate }}:
            {{
              itemObj == "th"
                ? (itemObj.updateDate | thaidate : "DD MMM YYYY HH:mm") + ' น.'
                : (itemObj.updateDate | date : "dd MMM YYYY HH:mm")
            }}
          </h4>
        </div>
      </div>
    </section>

    <section>
      <div class="card">
        <div class="card-body">
          <div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
            <app-loading></app-loading>
          </div>
          <app-form-template
            *ngIf="!isLoading"
            [config]="config.formConfig"
            [apiPath]="config.apiPath"
            [itemObj]="itemObj"
            #formTemplate
          ></app-form-template>
        </div>
      </div>
    </section>
  </div>
</div>
