import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileTypeSplit',
})
export class FileTypeSplitPipe implements PipeTransform {
  transform(fileType: string): string {
    const parts = fileType.split('/');
    if (parts.length === 2) {
      const format = parts[1].toUpperCase();
      switch (format) {
        case 'VND.OPENXMLFORMATS-OFFICEDOCUMENT.WORDPROCESSINGML.DOCUMENT':
          return 'Word Document';
        case 'VND.OPENXMLFORMATS-OFFICEDOCUMENT.SPREADSHEETML.SHEET':
          return 'Excel Spreadsheet (XLSX)';
        case 'VND.APPLE.MPEGURL':
          return 'Apple MPEG URL';
        default:
          return format.substring(0, 8);
      }
    } else {
      return 'UNKNOWN';
    }
  }
}
