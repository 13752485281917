class News {
  id?: any;
  name: string;
  title: string;
  subTitle: string;
  detail: string;
  itemNewsDate: Date;
  itemNewsName:string;
  ItemNewsDetail: string;
  itemNewsQuote: string;
  linkBtnCaption: string;
  linkBtnUrl: string;
  formFiles?: File[];
  isActive: boolean;
  isShowQuote:boolean;
  updateDate: Date;
}

export default News;
