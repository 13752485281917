import { Component, OnInit } from '@angular/core';
import { BranchService } from './branch.service';
import { ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss']
})
export class BranchComponent implements OnInit {

  config!: ICMSConfig;
  keyName: string;

  constructor(private _branchService: BranchService) {
    this.config = this._branchService.config;
    this.keyName = this._branchService.keyName;
  }

  ngOnInit(): void {
  }

}
