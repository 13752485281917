<div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
  <app-loading></app-loading>
</div>
<div *ngIf="!isLoading" class="row">
  <div class="col-md-12">
    <form [formGroup]="registerForm">
      <h4 class="mb-2">{{ "General.RolesManage" | translate }}</h4>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{ "ManageRole.RoleName" | translate }}</label>
        <div class="col-sm-9">
          <input class="form-control" type="text" formControlName="roleName" />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block': f.roleName.invalid && (f.roleName.dirty || f.roleName.touched)
            }"
          >
            <div>
              <div *ngIf="f.roleName.errors?.required">{{ "Handle.Name" | translate }}</div>
              <div *ngIf="f.roleName.errors?.maxlength">
                {{ "Handle.ExceedChar" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <div class="form-group d-flex justify-content-between">
        <button class="btn btn-secondary" (click)="openCancelModal()">
          {{ "Form.Cancel" | translate }}
        </button>

        <button class="btn btn-primary" 
        (click)="openConfirmModal(itemObj)" 
        [disabled]="f.roleName.errors?.required || f.roleName.errors?.maxlength">
          {{ "Form.Submit" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
