import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { TimelineItem } from 'app/main/components/horizontal-timeline/horizontal-timeline.component';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { Quotation, TempRowQuotation } from 'app/main/model/Quotation';
import { SaleOrder, SaleOrderItem } from 'app/main/model/SaleOrder';
import { ApiService } from 'app/main/service/api.service';
import { CalculateFuncService } from 'app/main/service/calculate-func.service';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { DocumentService } from '../../document.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsService } from 'app/main/components/components.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { environment } from 'environments/environment';
import { ModalPaymentComponent } from 'app/main/components/modal-payment/modal-payment.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApproveDocModalComponent } from 'app/main/components/approve-doc-modal/approve-doc-modal.component';

@Component({
  selector: 'app-purchase-order-detail',
  templateUrl: './purchase-order-detail.component.html',
  styleUrls: ['./purchase-order-detail.component.scss'],
})
export class PurchaseOrderDetailComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmModal') confirmModal: ModalComponent;
  contentHeader: object;
  typeDocName: string;

  currentUser: User;
  timelineData: TimelineItem[];

  purchaseOrderObj: SaleOrder;
  customerAddressObj: CustomerAddress;

  orderId: string;
  orderStatus: any;
  currentStep: number;

  apiPath: string;
  editPathUrl: string;

  docID: string;
  docStatus: number;
  isLoading: boolean;
  isIncludeQo: boolean;
  sumNetPrice: number;
  sumVatPrice: number;
  titleDoc: string = 'ใบสั่งขายสินค้า';

  checkCreateGR: boolean = false;
  disableButton: boolean = false;
  checkItemInvoice: boolean = false;
  disableSOButton: boolean = false;

  refDetailUrl: string;
  detailPathUrl: string;
  isSplitGRfromPO: boolean = true;
  isJoinIV_TX_RE: boolean = false;
  isApproving: boolean = false;

  detailGRPath: string;

  @BlockUI() blockUI: NgBlockUI

  constructor(
    private _documentService: DocumentService,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _documentApiService: DocumentApiService,
    public _route: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private _apiService: ApiService,
    private _calculateService: CalculateFuncService,
    private _modalService: NgbModal,
    private _componentService: ComponentsService,
    private _router: Router,
    private _globalFuncService: GlobalFuncService
  ) {
    this.timelineData = this._documentService.timelineBuyData;
    this.apiPath = this._documentService.purchaseOrder.ApiPath;
    // this.editPathUrl = this._documentService.purchaseOrder.EditPathURL;
    this.refDetailUrl = this._documentService.purchaseRequest.DetailPathURL;
    this.detailPathUrl = this._documentService.purchaseOrder.DetailPathURL
    this.isSplitGRfromPO = environment.transaction.isSplitGRfromPO;
    this.isJoinIV_TX_RE = environment.transaction.isJoinIV_TX_RE;
    this.detailGRPath = this._documentService.GoodsReceive.DetailPathURL;
    this.initData();
  }

  toggleIncludeQo(): void {
    this.isIncludeQo = !this.isIncludeQo;
  }

  SetLoadingState() {
    this.isLoading = true;
  }
  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;

    this._authenticationService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    this.contentHeader = {
      headerTitle: 'Document.PurchaseOrder',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Document',
            isLink: true,
            link: '/',
          },
          {
            name: 'Document.PurchaseOrder',
            isLink: true,
            link: '/document/purchase-order/view',
          },
          {
            name: 'FormName.PurchaseOrderDetail',
            isLink: false,
            link: '/document/purchase-order/view',
          },
        ],
      },
    };
  }

  activeTab: string = 'detail';
  @ViewChild('navFilled') nav: NgbNav;
  public active_fragment: BehaviorSubject<string> = new BehaviorSubject('');
  
  ngAfterViewInit(): void {
    this._route.fragment.subscribe((frag) => {
      this.active_fragment.next(frag);
      
      console.log(this.active_fragment.value)
      if (!this.active_fragment.value) {
        this.nav.select('detail')
        this.activeTab = 'detail'
        console.log('select default page')
      } else {
        this.activeTab = this.active_fragment.value
      }
      
    });
  }

  initData(): void {
    this.SetLoadingState();
    this.blockUI.start()
    this.typeDocName = 'purchaseOrderCode';
    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
    });
    console.log('Doc id : ' + this.docID);
    console.log(this.apiPath);

    this.getSaleOrderData();
  }

  refreshData() {
    this.initData();
  }

  getSaleOrderData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocData(this.apiPath, this.docID)
        .subscribe((res) => {
          self.purchaseOrderObj = res.data.resultData[0];
          console.log('purchaseOrderObj : ', this.purchaseOrderObj);

          // console.log('resssssss',res.data.resultData[0])
          self.getCustomerAddress(self.purchaseOrderObj.customerId);
          self.calculateSummary();
          self.SetLoadedState();
          self.SetDisableButton();
          self.SetDisableSOButton();
          this.docStatus = self.purchaseOrderObj.transactionStatusId;
          console.log('Current Status: ', this.docStatus);
          this.orderId = self.purchaseOrderObj.orderId;
          this.getOrderStatus(this.orderId).subscribe((orderStatusRes) => {
            this.orderStatus = orderStatusRes.data.resultData;
            this.blockUI.stop()
            console.log('Order Status: ', this.orderStatus[0]);
            this.currentStep = this._globalFuncService.GetCurrentStep(
              this.orderStatus
            );
            console.log('Current Step: ', this.currentStep);
          });
        });
    });
  }

  getOrderStatus(orderId: string): Observable<any> {
    return this._apiService.GetDataById('BuyOrder/State', orderId);
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData.find((it) => {
            return it['useForShipping'] == true;
          });
        });
    });
  }

  calculateSummary() {
    let itemList = [];

    this.purchaseOrderObj.item.forEach((element, index) => {
      let tempRow: TempRowQuotation = new TempRowQuotation();
      tempRow.rowType = 'item';
      tempRow.itemId = element.itemId;
      tempRow.description = element.description[0].description;
      tempRow.discount = element.discount;
      tempRow.quantity = element.quantity;
      tempRow.unit = element.unit;
      tempRow.unitPrice = element.unitPrice;
      tempRow.whtRate = 0.0;

      itemList.push(tempRow);
    });

    this.sumNetPrice = this._calculateService.calculateNet(
      itemList,
      true,
      this.purchaseOrderObj.taxRate,
      this.purchaseOrderObj.discount
    );

    this.sumVatPrice = this._calculateService.calculateVat(
      this.sumNetPrice,
      this.purchaseOrderObj.taxRate
    );
  }

  SetDisableButton() {
    this.checkCreateGR = this.purchaseOrderObj.isCreateInvoice;
    if (this.checkCreateGR === true) {
      this.disableButton = true;
    } else {
      this.disableButton = false;
    }
  }

  SetDisableSOButton() {
    //check item invoice if dont have item saleorder disable button
    this.checkItemInvoice = this.purchaseOrderObj.item.length > 0;
    if (this.checkItemInvoice === true) {
      this.disableSOButton = false;
    } else {
      this.disableSOButton = true;
    }
    console.log('check', this.purchaseOrderObj.item.length);
  }

  convertNumbertoMoneyFormat(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }

  handlePaymentDetailSubmit(data: any): void {
    console.log('Submitted data from AddBillComponent:', data.quotationForm);
    this.initData();
  }

  handlePaymentDetailRefresh(eventData: any) {
    // Do whatever you need to do when the refreshData event is triggered
    console.log('Received data from PaymentDetailComponent:', eventData);
    this.initData();
  }

  approveSaleOrder(remarks?: string): Promise<any> {
    this.blockUI.start()
    this.isApproving = true;
    let params: any = {};
    if (this.isJoinIV_TX_RE) {
      params.isTaxInvoice = true;
      params.isReceipt = true;
    } else {
      params.isTaxInvoice = false;
      params.isReceipt = false;
    }
    params.remarks = remarks
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .ApproveDocData(this.apiPath, this.docID, params)
        .subscribe(
          (res) => {
            console.log(res);
            let grCode = res.data.resultData[0].code;
            let grId = res.data.resultData[0].id;

            let title = this._translateService.instant('Alert.Approved');
            this.isApproving = false;
            const titleLinkDocButton =
              this._translateService.instant('Alert.GoToGR');
            const textCode = this._translateService.instant('Alert.CodeGR', {
              docCode: grCode,
            });
            this.blockUI.stop()
            this._componentService.SuccessSwal(
              title,
              undefined,
              true,
              titleLinkDocButton,
              this.detailGRPath,
              textCode,
              grId
            );
            this._router.navigate([`/document/purchase-order/view`]);
          },
          (error) => {
            let title = this._translateService.instant('Alert.ApproveFail');
            this.isApproving = false;
            this.blockUI.stop()
            this._componentService.ErrorSwal(title, error.message);
            this._router.navigate([`/document/purchase-order/view`]);
          }
        );
    });
  }

  openApproveModal(): void {
    let title = this._translateService.instant('Modal.ApprovePO');
    let detail = this._translateService.instant(
      'Modal.AreYouConfirmToApprovePO'
    );
    this.openModal(title, detail);
  }

  openModal(title: string, detail: string): void {
    const modalRef = this._modalService.open(ApproveDocModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.remarks = this.purchaseOrderObj.remarks;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.approveSaleOrder(res);
    });
  }

  openPaymentModal(): void {
    const paymentModalRef = this._modalService.open(ModalPaymentComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
    });
  }
}
