import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { CompanyCardService } from './company-card.service';

@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss']
})
export class CompanyCardComponent implements OnInit {

  config!: ICMSConfig;
  keyName: string;

  constructor(private _companyCard: CompanyCardService) {
    this.config = this._companyCard.config;
    this.keyName = this._companyCard.keyName;
  }

  ngOnInit(): void {
  }

}
