<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section>
      <app-item-detail
        [itemObj]="itemObj"
        [lastItemMovementObj]="lastItemMovementObj"
        [childItemsList]="childItemsList"
        [stockSeries]="stockSeries"
        (callBackFunc)="reloadData()"
      >
      </app-item-detail>


      <!-- <app-warehouse-table
        [itemID]="itemID"
        #warehouseTable
      ></app-warehouse-table> -->

      <app-movement-table [itemID]="itemID" #movementTable></app-movement-table>
    </section>
  </div>
</div>
