<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- <app-search-panel [isForGroupTemplate]="false" pathUrl="{{ pathUrl + '/create' }}" (SetLoading)="SetLoadingState()"
      [isShowSortByGroup]="false" (callDataFromAPI)="initData($event)" isShowCategory="true"></app-search-panel> -->

    <div class="card">
      <div class="card-body">
        <div class="d-flex mb-1">
          <div class="col-2 px-0 d-flex justify-content-start">
            <a [routerLink]="['/notification/create']" class="btn btn-primary">
              <i data-feather="plus" class="mr-1"></i>{{ "General.Create" | translate }}
            </a>
          </div>
        </div>
        <hr>

        <div class="row mb-2">
          <div class="col-6">
            <label for="">{{ 'General.SearchKeyword' | translate }}</label>
            <div class="d-flex">
              <input class="form-control mr-1" type="text" [(ngModel)]="searchText">
            </div>
          </div>

          <div class="col-2">
            <label for="">{{ 'MLM.Period.Type' | translate }}</label>
            <select class="custom-select" [(ngModel)]="searchByTypeId">
              <option [ngValue]="null">{{ "General.All" | translate }}</option>
              <option [ngValue]="0">{{ displayTypeIdOn(0) }}</option>
              <option [ngValue]="1">{{ displayTypeIdOn(1) }}</option>
              <option [ngValue]="2">{{ displayTypeIdOn(2) }}</option>
              <option [ngValue]="3">{{ displayTypeIdOn(3) }}</option>
            </select>
          </div>

          <div class="col-2">
            <label for="">{{ 'MLM.Period.SendTo' | translate }}</label>
            <select class="custom-select" [(ngModel)]="searchByType">
              <option [ngValue]="null">{{ "General.All" | translate }}</option>
              <option [ngValue]="0">{{ displayStatusOn(0) }}</option>
              <option [ngValue]="1">{{ displayStatusOn(1) }}</option>
              <option [ngValue]="2">{{ displayStatusOn(2) }}</option>
              <option [ngValue]="3">{{ displayStatusOn(3) }}</option>
            </select>
          </div>


          <div class="col-2">
            <label for="">{{ 'General.Status' | translate }}</label>
            <select class="custom-select" [(ngModel)]="searchByStatus">
              <option [ngValue]="null">{{ "General.All" | translate }}</option>
              <option [ngValue]="true">{{ 'Status.Publish' | translate }}</option>
              <option [ngValue]="false">{{ 'Status.Unpublish' | translate }}</option>
            </select>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-12 px-0 d-flex justify-content-center mt-3">
            <button (click)="Reset()" [disabled]="isLoading" class="btn btn-secondary" style="margin-right: 0.5rem">
              <i data-feather="refresh-ccw" class="mr-1"></i>{{ "General.Reset" | translate }}
            </button>

            <button (click)="Search()" [disabled]="isLoading" class="btn btn-primary" style="margin-left: 0.5rem">
              <i data-feather="search" class="mr-1"></i>{{ "General.Search" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div>
          <div class="d-flex justify-content-end mb-1">
            <!-- <button
                    (click)="exportReport()"
                    [disabled]="selectItemToReportList.length < 1"
                    class="btn btn-primary"
                  >
                    Export
                  </button> -->
          </div>
          <div>
            <table class="table" id="eventTable" datatable [dtOptions]="dtOptions">
              <thead>
                <tr>
                  <!-- <th width="3%" class="text-center">
                    <div class="custom-control custom-checkbox custom-checkbox-datatable">
                      <input
                              type="checkbox"
                              class="custom-control-input"
                              [id]="'checkAllRecord'"
                              (change)="checkAllRecord($event)"
                            />
                            <label
                              class="custom-control-label"
                              for="checkAllRecord"
                            ></label>
                    </div>
                  </th> -->
                  <th width="5%" class="text-center">#</th>
                  <th width="25%" style="padding-left: 0.75rem !important">
                    {{ "ViewTable.Name" | translate }}
                  </th>
                  <th width="20%" class="text-center">
                    {{ "MLM.Period.Type" | translate }}
                  </th>
                  <!-- <th width="20%" class="text-center">
                    {{ "ViewTable.CreateDate" | translate }}
                  </th> -->
                  <th width="20%" class="text-center">
                    {{ "Notification.StartDate" | translate }}
                  </th>
                  <th width="10%" class="text-center">
                    {{ "ViewTable.CreateBy" | translate }}
                  </th>
                  <th width="10%" class="text-center">
                    {{ "General.Status" | translate }}
                  </th>
                  <th width="10%">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr class="w-100" *ngIf="isLoading">
                  <td class="text-center" *ngFor="let num of [].constructor(6)">
                    <div class="skeleton-loading"></div>
                  </td>
                </tr>

                <tr *ngIf="itemList.length < 1 && !isLoading">
                  <td colspan="100%" class="text-center">
                    <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
                  </td>
                </tr>

                <tr *ngFor="let item of itemList; index as ind">
                  <!-- <td class="text-center">
                    <div class="custom-control custom-checkbox custom-checkbox-datatable">
                      <input type="checkbox" class="custom-control-input" [id]="item.id"
                        (change)="checkRecord($event, item.id, ind)" />
                      <label class="custom-control-label" for="{{ item.id }}"></label>
                    </div>
                  </td> -->
                  <td class="text-center">
                    {{ (page - 1) * pageSize + ind + 1 }}
                  </td>
                  <td>
                    <a [routerLink]="['/notification/edit/' + item.id]" class="text-primary text-truncate">
                      {{ item.name }}
                    </a>
                  </td>
                  <td class="">
                    <div>ประเภท: <span class='text-primary'>{{ displayTypeIdOn(item.typeId) }}</span></div>
                    <div>ส่งแบบ: <span class='text-primary'>{{ displayStatusOn(item.notificationTypeId) }}</span>
                    </div>

                  </td>
                  <!-- <td class="text-center" *ngIf="currentLang == 'en'">
                    {{ item.createDate | date : 'dd MMM YYYY hh:mm' }}
                  </td>
                  <td class="text-center" *ngIf="currentLang == 'th'">
                    {{ item.createDate | thaidate : 'DD MMM YYYY hh:mm' }}
                  </td> -->
                  <td class="text-start" *ngIf="currentLang == 'th'">
                    วันที่: {{ item.startNotificationDate | thaidate : 'DD MMM YYYY' }} <br>
                    เวลา: {{ item.startNotificationDate | thaidate : 'HH:mm' }} น.
                  </td>
                  <td class="text-start" *ngIf="currentLang == 'en'">
                    Date: {{ item.startNotificationDate | date : 'dd MMM YYYY' }}<br>
                    Time: {{ item.startNotificationDate | date : 'HH:mm' }}
                    
                  </td>

                  <td class="text-center">
                    {{ item.createBy }}
                  </td>
                  <td class="text-center">
                    <span style="display: inline-block; margin-left: 10px;">
                      <div ngbDropdown>
                        <a ngbDropdownToggle href="javascript:void(0);"
                          class="d-flex justify-content-center text-secondary" id="dropdownBrowserState"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="{
                        'text-success': item.isActive === true,
                        'text-danger': item.isActive === false
                      }">
                          <div>
                            {{
                            (item.isActive == true
                            ? "Status.Published"
                            : "Status.Unpublished"
                            ) | translate
                            }}
                          </div>
                          <i data-feather="chevron-down" class="text-dark cursor-pointer ml-50 my-auto"></i>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu-left" aria-labelledby="dropdownBrowserState">
                          <a ngbDropdownItem *ngIf="item.isActive == true" class="d-flex align-items-center"
                            (click)="openSetActiveModal(ind, false)">
                            <i data-feather="eye-off" class="mr-50"></i>
                            {{ "Status.Unpublish" | translate }}
                          </a>
                          <a ngbDropdownItem *ngIf="item.isActive == false" class="d-flex align-items-center"
                            (click)="openSetActiveModal(ind, true)">
                            <i data-feather="eye" class="mr-50"></i>
                            {{ "Status.Publish" | translate }}
                          </a>
                        </div>

                      </div>
                    </span>
                  </td>
                  <td class="text-center pr-2">
                    <a class="btn btn-icon btn-icon rounded-circle btn-outline-danger mr-50" [ngClass]="{
                      'd-none': item.isDelete
                    }" (click)="openDeleteContentModal(item)"><i data-feather="trash-2" class="text-danger"></i></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>