import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  componentName: string = 'Category';
  apiUrl: string = 'category';
  pathUrl: string = '/item-manage/category';

  parentComponentName: string = 'Group';
  parentApiUrl: string = 'group';
  parentPathUrl: string = '/item-manage/group';

  childComponentName: string = 'SubCategory';
  childApiUrl: string = 'subcategory';
  childPathUrl: string = '/item-manage/subcategory';

  itemComponentName: string = 'Item';
  itemApiUrl: string = 'item';
  itemPathUrl: string = '/item-manage/item';

  constructor() { }
}
