import { Injectable } from '@angular/core';
import { ApiService } from '../../../service/api.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  componentName: string = 'Group';
  apiUrl: string = 'group';
  pathUrl: string = '/item-manage/group';

  childComponentName: string = 'Category';
  childApiUrl: string = 'category';
  childPathUrl: string = '/item-manage/category';

  itemcomponentName: string = 'Item';
  itemApiUrl: string = 'item';
  itemPathUrl: string = '/item-manage/item';

  constructor(private _apiService: ApiService) {}
}
