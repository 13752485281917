<div style="background: #f0f0f0" class="col-md-12 form-group border">
  <div class="d-flex p-1 justify-content-between align-items-center">
    <b class="title-customer">
      <i [size]="22" class="feather_title mr-1" data-feather="dollar-sign"></i>
      {{ "Bank.reftext" | translate }}
    </b>
  </div>
</div>
<form [formGroup]="registerForm">
  <div class="form-group row">
    <div class="col-md-6">
      <div class="col-form-lable">{{ "Bank.bankName" | translate }}</div>
      <select class="custom-select" formControlName="bank_group">
        <option>เลือกธนาคารที่ต้องการ</option>
        <option value="0">อื่นๆ</option>
        <option value="1">กรุงเทพ</option>
        <option value="2">กสิกรไทย</option>
        <option value="3">ไทยพาณิชย์</option>
        <option value="4">เอสเอ็มอี</option>
        <option value="5">กรุงไทย</option>
        <option value="6">กรุงศรีอยุธยา</option>
        <option value="7">เกียรตินาคิน</option>
        <option value="8">ซีไอเอ็มบีไทย</option>
        <option value="9">ทีเอ็มบีธนชาต</option>
        <option value="10">ทิสโก้</option>
        <option value="11">ไทยเครดิตเพื่อรายย่อย</option>
        <option value="12">ธนชาต</option>
        <option value="13">ธกส</option>
        <option value="14">เอ็กซิม</option>
        <option value="15">ยูโอบี</option>
        <option value="16">แลนด์ แอนด์ เฮาส์</option>
        <option value="17">สแตนดาร์ดชาร์เตอร์ด (ไทย)</option>
        <option value="18">ออมสิน</option>
        <option value="37">อาคารสงเคราะห์</option>
        <option value="20">อิสลามแห่งประเทศไทย</option>
        <option value="21">ไอซีบีซี</option>
        <option value="22">เจพีมอร์แกน เชส</option>
        <option value="23">ซิตี้แบงก์</option>
        <option value="24">ซูมิโตโม มิตซุย</option>
        <option value="25">ดอยซ์แบงก์</option>
        <option value="26">บีเอ็นพี พารีบาส์</option>
        <option value="27">มิซูโฮ</option>
        <option value="28">เมกะ สากลพาณิชย์</option>
        <option value="29">แห่งประเทศจีน (ไทย)</option>
        <option value="30">แห่งอเมริกา</option>
        <option value="31">อาร์ บี เอส</option>
        <option value="32">อาร์ เอช บี</option>
        <option value="33">อินเดียนโอเวอร์ซีส์</option>
        <option value="34">เอช เอส บี ซี</option>
        <option value="35">เอเอ็นแซด (ไทย)</option>
        <option value="36">โอเวอร์ซี-ไชนีส</option>
      </select>

      <div
        class="invalid-feedback"
        [ngClass]="{
          'd-block':
            f.bank_group.invalid && (f.bank_group.dirty || f.bank_group.touched)
        }"
      >
        <div>
          <div *ngIf="f.bank_group.errors?.required">
            {{ "Bank.bankGroupRequired" | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="col-form-lable">{{ "Bank.branch" | translate }}</div>
      <input class="form-control" formControlName="bank_addr" type="text" />
      <div
        class="invalid-feedback"
        [ngClass]="{
          'd-block':
            f.bank_addr.invalid && (f.bank_addr.dirty || f.bank_addr.touched)
        }"
      >
        <div>
          <div *ngIf="f.bank_addr.errors?.required">
            {{ "Bank.bankAddressRequired" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-md-6">
      <div class="col-form-lable">
        {{ "Bank.accountNumber" | translate }}
      </div>
      <input class="form-control" formControlName="bank_id" type="text" />
      <div
        class="invalid-feedback"
        [ngClass]="{
          'd-block': f.bank_id.invalid && (f.bank_id.dirty || f.bank_id.touched)
        }"
      >
        <div>
          <div *ngIf="f.bank_id.errors?.required">
            {{ "Bank.bankIdRequired" | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="col-form-lable">{{ "Bank.accountName" | translate }}</div>
      <input class="form-control" formControlName="bank_name" type="text" />
      <div
        class="invalid-feedback"
        [ngClass]="{
          'd-block':
            f.bank_name.invalid && (f.bank_name.dirty || f.bank_name.touched)
        }"
      >
        <div>
          <div *ngIf="f.bank_name.errors?.required">
            {{ "Bank.bankNameRequired" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-md-6">
      <div class="col-form-lable">{{ "Bank.accountType" | translate }}</div>
      <select
        class="form-control"
        name="banktype"
        formControlName="bank_type"
        id=""
      >
        <option value="#">{{ "Bank.savings" | translate }}</option>
        <option value="#">{{ "Bank.current" | translate }}</option>
        <option value="#">{{ "Bank.fixedDeposit" | translate }}</option>
      </select>
      <div
        class="invalid-feedback"
        [ngClass]="{
          'd-block':
            f.bank_type.invalid && (f.bank_type.dirty || f.bank_type.touched)
        }"
      >
        <div>
          <div *ngIf="f.bank_type.errors?.required">
            {{ "Bank.bankTypeRequired" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <br />
  <hr />
  <br />

  <div class="form-group row">
    <div class="col-md-12 mb-1">{{ "Invoice.item" | translate }}</div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <div class="col-form-lable">
            {{ "Invoice.sellPaymentSettingLabel" | translate }}
          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <select class="custom-select" formControlName="set_sell">
                <option>
                  {{
                    "Invoice.sellPaymentSettingOptions.settingByCompany"
                      | translate
                  }}
                </option>
                <option value="#">
                  {{
                    "Invoice.sellPaymentSettingOptions.afterInvoiceXDays"
                      | translate
                  }}
                </option>
                <option value="#">
                  {{
                    "Invoice.sellPaymentSettingOptions.endOfMonthInvoiceDate"
                      | translate
                  }}
                </option>
                <option value="#">
                  {{
                    "Invoice.sellPaymentSettingOptions.endOfMonthNextMonth"
                      | translate
                  }}
                </option>
              </select>
              <div
                class="invalid-feedback"
                [ngClass]="{
                  'd-block':
                    f.set_sell.invalid &&
                    (f.set_sell.dirty || f.set_sell.touched)
                }"
              >
                <div>
                  <div *ngIf="f.set_sell.errors?.required">
                    {{ "Invoice.sellreq" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-form-lable">
            {{ "Invoice.buyPaymentSettingLabel" | translate }}
          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <select class="custom-select" formControlName="set_buy">
                <option>
                  {{
                    "Invoice.sellPaymentSettingOptions.settingByCompany"
                      | translate
                  }}
                </option>
                <option value="#">
                  {{
                    "Invoice.sellPaymentSettingOptions.afterInvoiceXDays"
                      | translate
                  }}
                </option>
                <option value="#">
                  {{
                    "Invoice.sellPaymentSettingOptions.endOfMonthInvoiceDate"
                      | translate
                  }}
                </option>
                <option value="#">
                  {{
                    "Invoice.sellPaymentSettingOptions.endOfMonthNextMonth"
                      | translate
                  }}
                </option>
              </select>
              <div
                class="invalid-feedback"
                [ngClass]="{
                  'd-block':
                    f.set_buy.invalid && (f.set_buy.dirty || f.set_buy.touched)
                }"
              >
                <div>
                  <div *ngIf="f.set_buy.errors?.required">
                    {{ "Invoice.buyreq" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
