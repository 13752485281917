import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ContentListComponent } from '../../../components/content-list/content-list.component';
import { ApiService } from '../../../service/api.service';
import { ItemSetService } from './item-set.service';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from '../../../components/components.service';
import { Subject, Subscription } from 'rxjs';
import { AuthenticationService } from 'app/auth/service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-item-set',
  templateUrl: './item-set.component.html',
  styleUrls: ['./item-set.component.scss']
})
export class ItemSetComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  private dataSubscription: Subscription;


  componentName: string;
  childComponentName: string;


  apiPath: string;
  pathUrl: string;

  contentHeader: object;
  itemSetList: any[];

  isLoading: boolean;
  isGridView: boolean = false;
  isShowChildList: boolean = false;
  isForGroupTemplate: boolean = false;
  isGoEditPage: boolean = false;

  activeStatus: string;
  searchText: string;
  searchData: {[k: string]: any} = {};

  public page;
  public pageSize;

  @ViewChild(ContentListComponent) contentListTable: ContentListComponent;

  constructor(
    private _apiService: ApiService,
    private _itemSetService: ItemSetService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _authenticationService: AuthenticationService,
  ) {
    this.SetLoadingState();
    this._unsubscribeAll = new Subject();

    this.componentName = this._itemSetService.componentName;
    this.apiPath = this._itemSetService.apiUrl;
    this.pathUrl = this._itemSetService.pathUrl;

    this.page = 1;
    this.pageSize = 20;

    this._apiService.SetHttpHeaders(this._authenticationService.tokenValue);
    this.initConfig();
    this.initData();
  }

  initConfig(): void {
    this.componentName = this._itemSetService.componentName;
    this.apiPath = this._itemSetService.apiUrl;
    this.pathUrl = this._itemSetService.pathUrl;
  }

  initData(obj?: { [key: string]: any }): void {
    this.searchData = {};
    console.log('init data in set')
    this.SetLoadingState();
    // this.selectedColumns = ['NO.', 'NAME', 'STATUS', 'UPDATE DATE'];

    this.itemSetList = [];

    if (obj) {
      console.log(obj.params);
      console.log('have searchtext: ', obj.searchText);

      this.searchText = obj.searchText ? obj.searchText : '';

      if (!obj.params) {
        this.searchData = { SortPath: 'CreateDate', Direction: 1, isDelete: false, };
      } else {
        this.searchData = obj.params;
      }
    } else {
      this.searchText = '';
      this.searchData = { SortPath: 'CreateDate', Direction: 1, isDelete: false, };
    }

    this.searchData.name = this.searchText
    this.searchData.isItemSet = true;
    this.searchData.verbose = false;

    if (this.contentListTable) {
      this.contentListTable.loadData(this.searchData)
    }
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  listView() {
    this.isGridView = false;
  }

  gridView() {
    this.isGridView = true;
  }

  SearchFilter(val: string) {
    this.searchText = val;
    this.SetLoadedState();
  }

  setPageSize(val: number): void {
    this.pageSize = val;
  }

  exportXLSX() {
    this.contentListTable.ExportXLSX();
  }

  setActiveContent(val): void {
    var self = this;
    this._apiService
      .SetIsActiveData(this.apiPath, val.id, val.status)
      .subscribe(
        (res) => {
          self._componentsService.SuccessSwal();
          self.SetLoadingState();
          self.initData();
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
  }

  deleteContent(guid: string): void {
    var self = this;
    this._apiService.SoftDelete(this.apiPath, guid).subscribe(
      (res) => {
        self._componentsService.SuccessSwal();
        self.SetLoadingState();
        self.initData();
      },
      (err) => {
        self._componentsService.ErrorSwal();
      }
    );
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'BreadCrumbs.Manage.ItemSet',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe()
    }
    
  }

  SetLoadingStateFunc(state: boolean) {
    this.isLoading = state;
  }
}
