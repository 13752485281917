import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineCreateComponent } from './timeline-create/timeline-create.component';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TranslateModule } from '@ngx-translate/core';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { ComponentsModule } from 'app/main/components/components.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { TimelineComponent } from './timeline.component';
import { CmsListModule } from '../../../cms-list/cms-list.module';
import { CmsFormpageModule } from '../../../cms-formpage/cms-formpage.module';

const path: string = 'cms/repove-tools/our-company/timeline';
const routes: Routes = [
  {
    path: path,
    component: TimelineComponent,
    canActivate: [AuthGuard],
    data: { animation: 'timeline-management' },
  },
  {
    path: `${path}/create`,
    component: TimelineCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'timeline-create' },
  },
  {
    path: `${path}/edit/:id`,
    component: TimelineCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'timeline-edit' },
  },
]

@NgModule({
  declarations: [
  ],
  exports: [
  ],
  imports: [
    RouterModule.forChild(routes),
      ComponentsModule,
      ContentHeaderModule,
      CoreCommonModule,
      CommonModule,
      CorePipesModule,
      TranslateModule,
      NgxDropzoneModule,
      NgxSummernoteModule,
      FormsModule,
      ReactiveFormsModule,
      CoreTouchspinModule,
      NgbPaginationModule,
      NgbModule,
      CmsListModule,
      CmsFormpageModule,
  ]
})
export class TimelineModule { }
