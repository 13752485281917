class Customer {
  isActive: boolean;
  isDelete: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  deleteBy: string;
  deleteDate: string;
  id: string;
  customerCode: string;
  customerName?: string;
  repoveCode: string;
  uplineCode: string;
  uplineId: string;
  uplineName: string;
  isThailand: boolean;
  taxId: string;
  taxName: string;
  isHeadBranch: boolean;
  branchNumber: number;
  branchName: string;
  businessTypeId: number;
  businessTypeName: string;
  islegalEntity: boolean;
  detail: string;
  businessName: string;
  customerFirstName: string;
  customerLastName: string;
  personalFirstName: string;
  personalLastName: string;
  personalNickName: string;
  personalPosition: string;
  personalPhone: string;
  personalEmail: string;
  contactPhone: string;
  contactEmail: string;
  contactFax: string;
  contactWebsite: string;
  genderId: number;
  genderName: string;
  userId: string;
  userName: string;
  isDefault: boolean;
  sv?: number;
  bonus?: number;
  status?: string;
  dateOfBirth?: any;
  rankId?: number;
  rankName?: string;
}
export default Customer;
