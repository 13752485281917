import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/auth/service';
import { MainConfig } from 'app/main/config/MainConfig';
import News from 'app/main/model/News';
import { NewsService } from 'app/main/pages/cms/news/news.service';
import { ApiService } from 'app/main/service/api.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Subject } from 'rxjs';
import { ComponentsService } from '../../components.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-news-form',
  templateUrl: './news-form.component.html',
  styleUrls: ['./news-form.component.scss'],
})
export class NewsFormComponent implements OnInit {
  private _unsubscribeAll: Subject<any>;
  contentHeader: object;

  registerForm: FormGroup;
  itemGuid: string;
  itemObj: News;

  isEditing: boolean;
  isLoading: boolean;
  isSubmitted: boolean;

  componentName: string;
  apiPath: string;
  pathUrl: string;

  modeText: string;
  summernoteConfig: any;

  public dateTimeOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true,
    defaultDate: '',
  };
  public dateToOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true,
    defaultDate: '',
  };
  public dateFromOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true,
    defaultDate: '',
  };

  constructor(
    private _apiService: ApiService,
    private _formBuilder: FormBuilder,
    private _contentTemplateService: NewsService,
    private _mainConfig: MainConfig,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _modalService: NgbModal
  ) {
    this.SetLoadingState();
    this.initConfig();
    this.initForm(this.isEditing);
  }

  get f() {
    return this.registerForm.controls;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: this.modeText + ' ' + this.componentName,
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this.componentName,
            isLink: true,
            link: this.pathUrl,
          },
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  initConfig(): void {
    this._unsubscribeAll = new Subject();
    this.componentName = this._contentTemplateService.componentName;
    this.apiPath = this._contentTemplateService.apiPath;
    this.pathUrl = this._contentTemplateService.pathUrl;
    this.summernoteConfig = this._mainConfig.summernoteNewsConfig;

    this.isEditing = this._route.snapshot.paramMap.get('id') ? true : false;
    this.isLoading = this.isEditing ? true : false;

    this._apiService.SetHttpHeaders(this._authenticationService.tokenValue);

    this.modeText = this.isEditing
      ? this._translateService.instant('General.Edit')
      : this._translateService.instant('General.Create');

    this.registerForm = this._formBuilder.group({
      id: [null],
      name: ['', [Validators.required, Validators.maxLength(50)]],
      title: ['', [Validators.required, Validators.maxLength(100)]],
      subTitle: ['', [Validators.required, Validators.maxLength(100)]],
      detail: ['', [Validators.required]],
      contentDate: [null, [Validators.required]],
      linkBtnCaption: ['', [Validators.required, Validators.maxLength(50)]],
      linkBtnUrl: ['', [Validators.required]],
      isActive: [false, [Validators.required]],
    });
  }

  initForm(isEditing: boolean): void {
    var self = this;
    if (isEditing) {
      this.itemGuid = this._route.snapshot.paramMap.get('id');
      this._route.paramMap.subscribe((val) => {
        self.itemGuid = self._route.snapshot.paramMap.get('id');
        self.getitemData(self.itemGuid);
      });
    } else {
      this.itemObj = new News();
      this.setFormValue();
    }
  }

  setFormValue(): void {
    var self = this;
    this.registerForm.patchValue({
      name: self.itemObj.name,
      title: self.itemObj.title,
      subTitle: self.itemObj.subTitle,
      detail: self.itemObj.detail,
      itemNewsDate: self.itemObj.itemNewsDate,
      linkBtnCaption: self.itemObj.linkBtnCaption,
      linkBtnUrl: self.itemObj.linkBtnUrl,
    });
    console.log('setted value');
  }

  getitemData(guid: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService.GetDataById(this.apiPath, guid).subscribe((res) => {
        self.itemObj = res.data.resultData[0];
        self.setFormValue();
        self.isLoading = false;
      }, rejects);
    });
  }

  openConfirmModal(content: any): void {
    let isConfirm = true;
    let contentNameText = this.isEditing ? content.name : this.componentName;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.Edit');
    } else {
      var stateForm = this._translateService.instant('General.Create');
    }
    let title =
      this._translateService.instant('General.Confirm') +
      `${stateForm} ${this.componentName}`;
    let detail =
      this._translateService.instant('General.AreYouConfirm') +
      `${stateForm} ${this.componentName}`;
    this.openModal(title, detail, isConfirm);
  }

  openCancelModal(content: any): void {
    let isConfirm = false;
    let contentNameText = this.isEditing
      ? this.itemObj.name
      : this.componentName;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.CancelEdit');
    } else {
      var stateForm = this._translateService.instant('General.CancelCreate');
    }
    let title =
      this._translateService.instant('General.Confirm') +
      `${stateForm} ${this.componentName}`;
    let detail =
      this._translateService.instant('General.AreYouConfirm') +
      `${stateForm} ${this.componentName}`;
    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    if (!this.registerForm.invalid) {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        if (IsConfirm) {
          this.submit();
        } else {
          this._router.navigate([`${this.pathUrl}`]);
        }
      });
    } else {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        this._router.navigate([`${this.pathUrl}`]);
      });
    }
  }

  submit(): void {
    this.isSubmitted = true;
    if (this.registerForm.invalid) {
      console.log('Invalid');
      return;
    }

    const dateToValue = this.registerForm.controls.dateTo.value;
    const updatedDateToValue = dateToValue[0];
    this.registerForm.controls.dateTo.patchValue(updatedDateToValue);

    const dateFromValue = this.registerForm.controls.dateFrom.value;
    const updatedDateFromValue = dateFromValue[0];
    this.registerForm.controls.dateFrom.patchValue(updatedDateFromValue);

    const contentDateValue = this.registerForm.controls.contentDate.value;
    const updatedContentDateValue = contentDateValue[0];
    this.registerForm.controls.contentDate.patchValue(updatedContentDateValue);

    if (this.isEditing) {
      console.log('Edit mode');

      this._apiService
        .UpdateDataById(
          this.apiPath,
          this.itemObj.id.toString(),
          this.registerForm.value
        )
        .subscribe(
          (res) => {
            this._componentsService.SuccessSwal();
            this._router.navigate([`${this.pathUrl}`]);
          },
          (err) => {
            this._componentsService.ErrorSwal();
          }
        );
    } else {
      const contentCategoryIdValue = parseInt(
        this.registerForm.controls.contentCategoryId.value,
        10
      );
      this.registerForm.controls.contentCategoryId.patchValue(
        contentCategoryIdValue
      );

      this._apiService.AddData(this.apiPath, this.registerForm.value).subscribe(
        (res) => {
          this._componentsService.SuccessSwal();
          this._router.navigate([`${this.pathUrl}`]);
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
    }
  }
}
