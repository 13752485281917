import { Component, OnInit, ViewChild} from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { FormTemplateComponent } from 'app/main/components/form/form-template/form-template.component';
import { CmsFormConfig } from 'app/main/config/CMSFormConfig';
import { EventService } from '../event.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/main/service/api.service';

@Component({
  selector: 'app-event-create',
  templateUrl: './event-create.component.html',
  styleUrls: ['./event-create.component.scss']
})
export class EventCreateComponent implements OnInit {
  config: ICMSConfig;

  constructor(private _eventService: EventService) {
    this.config = this._eventService.config;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
