import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingsComponent } from './settings.component';
import { AuthGuard } from 'app/auth/helpers';
import { DetailComponent } from './section/detail/detail.component';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { CoreCommonModule } from '@core/common.module';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from 'app/main/components/components.module';
import { AccountSettingsComponent } from './section/account-settings/account-settings.component';
import { ChangePasswordComponent } from './section/change-password/change-password.component';

const routes: Routes = [
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'users-profile', isProfilePath: true },
  },
];

@NgModule({
  declarations: [
    SettingsComponent,
    AccountSettingsComponent,
    ChangePasswordComponent,
    DetailComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    ContentHeaderModule,
    CoreCommonModule,
    CommonModule,
    NgbModule,
    NgbPaginationModule,
    TranslateModule,
    FormsModule,
    ComponentsModule,
  ],
})
export class SettingsModule {



}
