import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { MainConfig, filesPropNameConfig } from 'app/main/config/MainConfig';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject, Subscription } from 'rxjs';
import { ImageUploadComponent } from '../../image-upload/image-upload.component';
import { CustomDropzoneComponent } from '../../custom-dropzone/custom-dropzone.component';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { ApiService } from 'app/main/service/api.service';
import { ProductListService } from 'app/main/pages/cms/repove-tools/product/product-list/product-list.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from '../../components.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { FilesApiService } from 'app/main/service/files-api.service';
import Swal from 'sweetalert2';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-product-detail-form',
  templateUrl: './product-detail-form.component.html',
  styleUrls: ['./product-detail-form.component.scss']
})
export class ProductDetailFormComponent implements OnInit {

  private _unsubscribeAll: Subject<any>;
  private dataSubscription: Subscription;
  private fileSubscription: Subscription;
  contentHeader: object;

  @BlockUI() blockUI: NgBlockUI;
  @Input() isUseMultipleLang: boolean = false;
  @Input() callbackUrl: string;
  @Input() currentLangFromCreatePage: string;
  ImageUploadComponent = {
    selected: false, // Replace this with the actual logic or value that sets the 'selected' variable in the ImageUploadComponent
  };
  filesPropName = filesPropNameConfig;
  @ViewChild('imageUploadComponent') imageUploadComponent: ImageUploadComponent;
  @ViewChild('landingImageUploadComponent') landingImageUploadComponent: ImageUploadComponent;
  @ViewChild('productImageUploadComponent') productImageUploadComponent: ImageUploadComponent;
  @ViewChild('technologyImageUploadComponent') technologyImageUploadComponent: ImageUploadComponent;
  @ViewChildren('detailImageUploadComponent') detailImageUploadComponent: QueryList<ImageUploadComponent>;
  @ViewChildren('featureImageUploadComponent') featureImageUploadComponent: QueryList<ImageUploadComponent>;
  @ViewChildren('otherFeatureImageUploadComponent') otherFeatureImageUploadComponent: QueryList<ImageUploadComponent>;
  @ViewChildren('beforeAfterImageUploadComponent') beforeAfterImageUploadComponent: QueryList<ImageUploadComponent>;
  @ViewChild('CustomDropzone') dropzoneComponent: CustomDropzoneComponent;

  images: Blob[] = [];
  passData: File[] = [];
  imageUrls: SafeStyle[] = [];
  handleList: any[] = [];
  files: File[] = [];
  AlertFiles: Boolean;
  countMedia: number;

  productForm: FormGroup;
  detail: string[];
  itemGuid: string;
  productObj: any;

  isEditing: boolean;
  isLoading: boolean;
  isSubmit: boolean;

  componentName: string;
  apiPath: string;
  pathUrl: string;

  modeText: string;
  summernoteConfig: any;

  isMultiLang: any = true;
  selectedLang: string = environment.langDefault;
  langList = environment.langContent;
  detailListLength: any;

  // categoriesList: Categories[] = categoriesList;

  constructor(
    private _apiService: ApiService,
    private _formBuilder: FormBuilder,
    private _productListService: ProductListService,
    private _mainConfig: MainConfig,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _modalService: NgbModal,
    private _globalFuncService: GlobalFuncService,
    private _fileService: FilesApiService,
    private sanitizer: DomSanitizer,
    private el: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {
    this.summernoteConfig = this._mainConfig.summernoteNewsConfig;
    this.SetLoadingState();
    this.initConfig();
    this.initForm(this.isEditing);
  }

  ngAfterViewChecked(): void {
    // if (this.iconImageUploadComponent.changes) {
    //   // this.setFormValue();
    // }
  }

  get f() {
    return this.productForm.controls;
  }

  ngOnInit(): void {

    this.contentHeader = {
      headerTitle: this.modeText + ' ' + this.componentName,
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this.componentName,
            isLink: true,
            link: this.pathUrl,
          },
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  initConfig(): void {

    this._unsubscribeAll = new Subject();
    this.componentName = this._productListService.componentName;
    this.apiPath = this._productListService.apiPath;
    this.pathUrl = this._productListService.pathUrl;
    this.summernoteConfig = this._mainConfig.summernoteNewsConfig;

    this.isEditing = this._route.snapshot.paramMap.get('id') ? true : false;
    this.isLoading = this.isEditing ? true : false;

    this._apiService.SetHttpHeaders(this._authenticationService.tokenValue);

    this.modeText = this.isEditing
      ? this._translateService.instant('General.Edit')
      : this._translateService.instant('General.Create');

    this.productForm = this._formBuilder.group({
      PcRowPosition: [1, [Validators.required, Validators.min(1)]],
      PcColumnPosition: [1, [Validators.required, Validators.min(1), Validators.max(3)]],
      MbRowPosition: [1, [Validators.required, Validators.min(1)]],
      MbColumnPosition: [1, [Validators.required, Validators.min(1), Validators.max(2)]],
      productNameTH: ['', [Validators.required, Validators.maxLength(100)]],
      productNameEN: ['', [Validators.required, Validators.maxLength(100)]],
      price: ['', [Validators.required, Validators.maxLength(100)]],
      volume: ['', [Validators.required, Validators.maxLength(100)]],
      isUsingDetailList: [false],
      isUsingLabel: [false],

      detailList: this._formBuilder.array([
        this._formBuilder.group({
          title: this._formBuilder.group({
            en: '',
            th: '',
          }),
          detail: this._formBuilder.group({
            en: '',
            th: '',
          })
        }),
        this._formBuilder.group({
          title: this._formBuilder.group({
            en: '',
            th: '',
          }),
          detail: this._formBuilder.group({
            en: '',
            th: '',
          })
        }),
        this._formBuilder.group({
          title: this._formBuilder.group({
            en: '',
            th: '',
          }),
          detail: this._formBuilder.group({
            en: '',
            th: '',
          })
        }),
      ]),

      beforeAfterList: this._formBuilder.array([{}, {}, {}, {}]),
    });

    const productDetailControls: any = {};

    const productTitleControls: any = {};
    const productDescriptionControls: any = {};

    const techDetailControls: any = {};
    const featureTitleControls: any = {};
    const otherFeatureControls: any = {};

    const componentDetailControls: any = {};
    const testResultControl: any = {};
    const testResultTBControl: any = {};
    const testResultMBControl: any = {};
    this.langList.forEach((lang) => {
      for (const lang of this.langList) {

        productDetailControls[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);

        productTitleControls[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);
        productDescriptionControls[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);

        techDetailControls[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);
        featureTitleControls[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);
        otherFeatureControls[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);

        componentDetailControls[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);
        testResultControl[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);
        testResultTBControl[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);
        testResultMBControl[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);
      }

      this.productForm.addControl(
        'productDetail',
        this._formBuilder.group(productDetailControls)
      );

      this.productForm.addControl(
        'techDetail',
        this._formBuilder.group(techDetailControls)
      );

      this.productForm.addControl(
        'featureTechTitle',
        this._formBuilder.group(featureTitleControls)
      );

      this.productForm.addControl(
        'otherFeatureTechTitle',
        this._formBuilder.group(otherFeatureControls)
      );

      this.productForm.addControl(
        'componentDetail',
        this._formBuilder.group(componentDetailControls)
      );

      this.productForm.addControl(
        'testResult',
        this._formBuilder.group(testResultControl)
      );

      this.productForm.addControl(
        'testResultTB',
        this._formBuilder.group(testResultTBControl)
      );

      this.productForm.addControl(
        'testResultMB',
        this._formBuilder.group(testResultMBControl)
      );

      this.productForm.addControl(
        'featureTech',
        this._formBuilder.array([])
      );

      this.productForm.addControl(
        'otherFeatureTech',
        this._formBuilder.array([])
      );
    });

    if (!this.isEditing) {
      this.addFeatureTechItemRow(0);
      this.addOtherFeatureTechItemRow(0);
    }
  }

  initForm(isEditing: boolean): void {
    var self = this;
    if (isEditing) {
      this.itemGuid = this._route.snapshot.paramMap.get('id');
      this._route.paramMap.subscribe((val) => {
        self.itemGuid = self._route.snapshot.paramMap.get('id');
        self.getProductData(self.itemGuid);
        self.getItemData(self.itemGuid)
      });
    } else {
      this.productObj = '';
    }
  }

  getItemData(guid: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataSubscription = this._apiService
        .GetDataById(this.apiPath, guid)
        .subscribe(
          (res) => {
            const self = this;
            self.productObj = res.data.resultData[0];

            if (self.productObj.mediaFiles[self.filesPropName.item.coverImage]) {
              this._fileService
                .GetFile(
                  self.productObj.mediaFiles[self.filesPropName.item.coverImage][0]
                    .id
                )
                .subscribe(
                  (res: Blob) => {
                    if (self.imageUploadComponent) {
                      self.imageUploadComponent.setCoverImage(
                        res,
                        self.productObj.mediaFiles[
                          self.filesPropName.item.coverImage
                        ][0].id
                      );
                    }
                  },
                  (err) => {
                    this.blockUI.stop();
                  }
                );
            }

            if (self.productObj.mediaFiles[self.filesPropName.item.detailImages]) {
              /* add loading บางครั้งรูปไม่โหลด */

              let detailImageArr =
                self.productObj.mediaFiles[self.filesPropName.item.detailImages];
              let tempFilesList: { file: any; ordinal: number }[] = [];
              self.passData = [];

              // self.dropzoneComponent.startLoading();

              const fileFetchPromises: Promise<void>[] = detailImageArr.map(
                (element: { id: string; ordinal: number }) => {
                  return new Promise<void>((fileResolve, fileReject) => {
                    self._fileService.GetFile(element.id).subscribe(
                      (res) => {
                        const filename = element;
                        const type = res.type.split('/')[1];
                        const name = element + '.' + type;
                        const file = new File([res], name, { type: res.type });

                        tempFilesList.push({
                          file: file,
                          ordinal: element.ordinal,
                        });
                        fileResolve();
                      },
                      (error) => {
                        fileReject(error);
                      }
                    );
                  });
                }
              );
              Promise.all(fileFetchPromises)
                .then(() => {
                  if (tempFilesList.length == detailImageArr.length) {
                    tempFilesList.sort((a, b) => a.ordinal - b.ordinal);

                    tempFilesList.forEach((element) => {
                      this.images.push(element.file);
                      this.passData.push(element.file);
                    });

                    this.dropzoneComponent.ngOnChangesConfig(self.passData);
                  }

                  this.blockUI.stop();
                  resolve();
                })
                .catch((error) => {
                  this.blockUI.stop();
                  reject(error);
                });
            } else {
              this.blockUI.stop();
              resolve();
            }
          },
          (error) => {
            this.blockUI.stop();
            reject(error);
          }
        );
    });
  }

  private setSingleImageField(itemObj: any, key: string, imageComponentTarget: any) {
    this.cdr.detectChanges()
    if (itemObj.mediaFiles[key]) {
      if (imageComponentTarget == 'detailImageUploadComponent') {
        const targetElement = this.detailImageUploadComponent.find(
          (component: ImageUploadComponent) =>
            component.elementRef.nativeElement.id === key
        );
        targetElement.startLoading();
        const Image = itemObj.mediaFiles[key][0].id;

        this._fileService.GetFile(Image).subscribe((res) => {
          targetElement.setCoverImage(res, Image);
        });
      } else if (imageComponentTarget == 'featureImageUploadComponent') {
        const targetElement = this.featureImageUploadComponent.find(
          (component: ImageUploadComponent) =>
            component.elementRef.nativeElement.id === key
        );
        targetElement.startLoading();
        const Image = itemObj.mediaFiles[key][0].id;

        this._fileService.GetFile(Image).subscribe((res) => {
          targetElement.setCoverImage(res, Image);
        });
      } else if (imageComponentTarget == 'otherFeatureImageUploadComponent') {
        const targetElement = this.otherFeatureImageUploadComponent.find(
          (component: ImageUploadComponent) =>
            component.elementRef.nativeElement.id === key
        );
        targetElement.startLoading();
        const Image = itemObj.mediaFiles[key][0].id;

        this._fileService.GetFile(Image).subscribe((res) => {
          targetElement.setCoverImage(res, Image);
        });
      } else if (imageComponentTarget == 'beforeAfterImageUploadComponent') {
        const targetElement = this.beforeAfterImageUploadComponent.find(
          (component: ImageUploadComponent) =>
            component.elementRef.nativeElement.id === key
        );
        targetElement.startLoading();
        const Image = itemObj.mediaFiles[key][0].id;

        this._fileService.GetFile(Image).subscribe((res) => {
          targetElement.setCoverImage(res, Image);
        });
      }

    }
  }

  setFormValue(): void {

    console.log(this.productObj)
    let tempFeature = JSON.parse(this.productObj.featureTech);
    let tempOtherFeature = JSON.parse(this.productObj.otherFeatureTech);

    if (this.isEditing) {
      if (tempFeature.length > 1) {
        tempFeature.forEach((element, index) => {
          this.addFeatureTechItemRow(index)
        });
      } else {
        this.addFeatureTechItemRow(0)
      }

      if (tempOtherFeature.length > 1) {
        tempFeature.forEach((element, index) => {
          this.addOtherFeatureTechItemRow(index)
        });
      } else {
        this.addOtherFeatureTechItemRow(0)
      }
    }

    this.productForm
      .get(`PcRowPosition`)
      .patchValue(this.productObj.PcRowPosition);
    this.productForm
      .get(`PcColumnPosition`)
      .patchValue(this.productObj.PcColumnPosition);
    this.productForm
      .get(`MbRowPosition`)
      .patchValue(this.productObj.MbRowPosition);
    this.productForm
      .get(`MbColumnPosition`)
      .patchValue(this.productObj.MbColumnPosition);
    this.productForm
      .get(`productNameTH`)
      .patchValue(this.productObj.productNameTH);

    this.productForm
      .get(`productNameEN`)
      .patchValue(this.productObj.productNameEN);

    this.productForm
      .get(`price`)
      .patchValue(this.productObj.price);

    this.productForm
      .get(`volume`)
      .patchValue(this.productObj.volume);

    let tempProductDetail = JSON.parse(this.productObj.productDetail);
    let tempTechDetail = JSON.parse(this.productObj.techDetail);
    let tempFeatureTechTitle = JSON.parse(this.productObj.featureTechTitle);
    let tempOtherFeatureTitle = JSON.parse(this.productObj.otherFeatureTechTitle);
    let tempComponentDetail = JSON.parse(this.productObj.componentDetail);
    let tempTestResult = JSON.parse(this.productObj.testResult);
    let tempTestResultTB = JSON.parse(this.productObj.testResultTB);
    let tempTestResultMB = JSON.parse(this.productObj.testResultMB);
    this.langList.forEach((lang) => {
      this.productForm
        .get(`productDetail.${lang}`)
        .patchValue(tempProductDetail[lang]);

      this.productForm
        .get(`techDetail.${lang}`)
        .patchValue(tempTechDetail[lang]);

      this.productForm
        .get(`featureTechTitle.${lang}`)
        .patchValue(tempFeatureTechTitle[lang]);

      this.productForm
        .get(`otherFeatureTechTitle.${lang}`)
        .patchValue(tempOtherFeatureTitle[lang]);

      this.productForm
        .get(`componentDetail.${lang}`)
        .patchValue(tempComponentDetail[lang]);

      this.productForm
        .get(`testResult.${lang}`)
        .patchValue(tempTestResult[lang]);

      this.productForm
        .get(`testResultTB.${lang}`)
        .patchValue(tempTestResultTB[lang]);

      this.productForm
        .get(`testResultMB.${lang}`)
        .patchValue(tempTestResultMB[lang]);
    });

    if (this.productObj.isUsingDetailList) {
      let tempUsingList = this.productObj.isUsingDetailList
      let tempDetailList = JSON.parse(this.productObj.detailList);
      console.log(tempDetailList)
      this.productForm
        .get(`isUsingDetailList`)
        .patchValue(tempUsingList);
      tempDetailList.forEach((element, index) => {
        this.setSingleImageField(this.productObj, `detailList${index}`, 'detailImageUploadComponent')
        this.productForm
          .get(`detailList.${index}`)
          .patchValue(tempDetailList[index]);
        // this.langList.forEach((lang) => {
        //   this.productForm
        //     .get(`detailList.${index}.${lang}`)
        //     .patchValue(tempDetailList[index][lang]);
        // })
      });
    }

    let tempUsingLabel = this.productObj.isUsingLabel
    this.productForm
      .get(`isUsingLabel`)
      .patchValue(tempUsingLabel);
    tempFeature.forEach((element, index) => {
      this.setSingleImageField(this.productObj, `featureTech${index}`, 'featureImageUploadComponent')
      this.productForm
        .get(`featureTech.${index}`)
        .patchValue(tempFeature[index]);
      // this.langList.forEach((lang) => {
      //   this.productForm
      //     .get(`featureTech.${index}`)
      //     .patchValue(tempFeatureTechList[index]);
      // })
    });


    tempOtherFeature.forEach((element, index) => {
      this.setSingleImageField(this.productObj, `otherFeatureTech${index}`, 'otherFeatureImageUploadComponent')
      this.productForm
        .get(`otherFeatureTech.${index}`)
        .patchValue(tempOtherFeature[index]);
      // this.langList.forEach((lang) => {
      //   this.productForm
      //     .get(`otherFeatureTech.${index}.${lang}`)
      //     .patchValue(tempOtherFeatureTechList[index][lang]);
      // })
    });

    this.beforeAfterFormArray.value.forEach((element, index) => {
      this.setSingleImageField(this.productObj, `beforeAfter${index}`, 'beforeAfterImageUploadComponent')
    });

    //Set Landing Image
    this._fileService
      .GetFile(
        this.productObj.mediaFiles['landingImage'][0].id
      )
      .subscribe(
        (res: Blob) => {
          if (this.landingImageUploadComponent) {
            this.landingImageUploadComponent.setCoverImage(
              res,
              this.productObj.mediaFiles['landingImage'][0].id
            );
          }
        },
        (err) => {
          this.blockUI.stop();
        }
      );

    //Set Product Image
    this._fileService
      .GetFile(
        this.productObj.mediaFiles['productImage'][0].id
      )
      .subscribe(
        (res: Blob) => {
          if (this.productImageUploadComponent) {
            this.productImageUploadComponent.setCoverImage(
              res,
              this.productObj.mediaFiles['productImage'][0].id
            );
          }
        },
        (err) => {
          this.blockUI.stop();
        }
      );

      //Set Technology Image
    this._fileService
    .GetFile(
      this.productObj.mediaFiles['technologyImage'][0].id
    )
    .subscribe(
      (res: Blob) => {
        if (this.technologyImageUploadComponent) {
          this.technologyImageUploadComponent.setCoverImage(
            res,
            this.productObj.mediaFiles['technologyImage'][0].id
          );
        }
      },
      (err) => {
        this.blockUI.stop();
      }
    );

    console.log('setted value');
  }

  getProductData(guid: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService.GetDataById(this.apiPath, guid).subscribe((res) => {
        self.productObj = res.data.resultData[0];
        self.setFormValue();
        self.isLoading = false;

      }, rejects);
    });
  }

  openConfirmModal(): void {
    let isConfirm = true;
    console.log(this.productForm.value)
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.Edit');
    } else {
      var stateForm = this._translateService.instant('General.Create');
    }
    let title =
      this._translateService.instant('General.Confirm') +
      `${stateForm} ${this.componentName}`;
    let detail =
      this._translateService.instant('General.AreYouConfirm') +
      `${stateForm} ${this.componentName}`;
    if (this.productForm.status === 'INVALID') {
      if (this.productForm.status === 'INVALID') {
        Swal.fire(this._translateService.instant('Alert.Invalid'), '', 'warning')
      }
    } else {
      this.openModal(title, detail, isConfirm);
    }
  }

  openCancelModal(): void {
    let isConfirm = false;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.CancelEdit');
    } else {
      var stateForm = this._translateService.instant('General.CancelCreate');
    }
    let title =
      this._translateService.instant('General.Confirm') +
      `${stateForm} ${this.componentName}`;
    let detail =
      this._translateService.instant('General.AreYouConfirm') +
      `${stateForm} ${this.componentName}`;
    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    this.isSubmit = true;
    console.log(this.images)

    this.imageUploadComponent.handleImage();

    if (!this.productForm.invalid) {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        if (IsConfirm) {
          this.submit();
        } else {
          this._router.navigate([`${this.pathUrl}`]);
        }
      });
    } else {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        this._router.navigate([`${this.pathUrl}`]);
      });
    }
  }

  prepareFinalData(data: any, tempForm: FormGroup) {
    for (let controlName in data.controls) {
      tempForm.addControl(
        controlName,
        this._formBuilder.control(data.get(controlName).value)
      );
    }

    console.log(tempForm)
    let tempProductNameTH = this.productForm.get(`productNameTH`).value;
    tempForm.removeControl('productNameTH');
    tempForm.addControl(
      'productNameTH',
      this._formBuilder.control(tempProductNameTH, [])
    );

    let tempProductNameEN = this.productForm.get(`productNameEN`).value;
    tempForm.removeControl('productNameEN');
    tempForm.addControl(
      'productNameEN',
      this._formBuilder.control(tempProductNameEN, [])
    );
    tempForm.addControl(
      'name',
      this._formBuilder.control(tempProductNameEN, [])
    );

    let tempPrice = this.productForm.get(`price`).value;
    tempForm.removeControl('price');
    tempForm.addControl(
      'price',
      this._formBuilder.control(tempPrice, [])
    );

    let tempVolume = this.productForm.get(`volume`).value;
    tempForm.removeControl('volume');
    tempForm.addControl(
      'volume',
      this._formBuilder.control(tempVolume, [])
    );

    let tempProductDetail = {};
    let tempTechDetail = {};
    let tempFeatureTechTitle = {};
    let tempOtherFeatureTechList = {};
    let tempComponentDetail = {};
    let tempTestResult = {};
    let tempTestResultTB = {};
    let tempTestResultMB = {};
    this.langList.forEach((lang) => {
      tempProductDetail[lang] = this.productForm.get(
        `productDetail.${lang}`
      ).value;

      tempTechDetail[lang] = this.productForm.get(
        `techDetail.${lang}`
      ).value;

      tempFeatureTechTitle[lang] = this.productForm.get(
        `featureTechTitle.${lang}`
      ).value;

      tempOtherFeatureTechList[lang] = this.productForm.get(
        `otherFeatureTechTitle.${lang}`
      ).value;

      tempComponentDetail[lang] = this.productForm.get(
        `componentDetail.${lang}`
      ).value;

      tempTestResult[lang] = this.productForm.get(
        `testResult.${lang}`
      ).value;

      tempTestResultTB[lang] = this.productForm.get(
        `testResultTB.${lang}`
      ).value;

      tempTestResultMB[lang] = this.productForm.get(
        `testResultMB.${lang}`
      ).value;
    });
    tempForm.removeControl('productDetail');
    tempForm.addControl(
      'productDetail',
      this._formBuilder.control(JSON.stringify(tempProductDetail), [])
    );

    tempForm.removeControl('techDetail');
    tempForm.addControl(
      'techDetail',
      this._formBuilder.control(JSON.stringify(tempTechDetail), [])
    );

    tempForm.removeControl('featureTechTitle');
    tempForm.addControl(
      'featureTechTitle',
      this._formBuilder.control(JSON.stringify(tempFeatureTechTitle), [])
    );

    tempForm.removeControl('otherFeatureTechTitle');
    tempForm.addControl(
      'otherFeatureTechTitle',
      this._formBuilder.control(JSON.stringify(tempOtherFeatureTechList), [])
    );

    tempForm.removeControl('componentDetail');
    tempForm.addControl(
      'componentDetail',
      this._formBuilder.control(JSON.stringify(tempComponentDetail), [])
    );

    tempForm.removeControl('testResult');
    tempForm.addControl(
      'testResult',
      this._formBuilder.control(JSON.stringify(tempTestResult), [])
    );

    tempForm.removeControl('testResultTB');
    tempForm.addControl(
      'testResultTB',
      this._formBuilder.control(JSON.stringify(tempTestResultTB), [])
    );

    tempForm.removeControl('testResultMB');
    tempForm.addControl(
      'testResultMB',
      this._formBuilder.control(JSON.stringify(tempTestResultMB), [])
    );

    let tempdetailListObj = this.detailListForm;
    tempForm.removeControl('detailList');
    tempForm.addControl(
      'detailList',
      this._formBuilder.control(JSON.stringify(tempdetailListObj.value), [])
    );

    let tempFeatureTechObj = this.featureFormArray;
    tempForm.removeControl('featureTech');
    tempForm.addControl(
      'featureTech',
      this._formBuilder.control(JSON.stringify(tempFeatureTechObj.value), [])
    );

    let tempOtherFeatureTechObj = this.otherFeatureFormArray;
    tempForm.removeControl('otherFeatureTech');
    tempForm.addControl(
      'otherFeatureTech',
      this._formBuilder.control(JSON.stringify(tempOtherFeatureTechObj.value), [])
    );

    console.log(tempForm.value)
  }

  submit(): void {
    var self = this;
    let isUpdatefile;
    this.isSubmit = true;
    if (this.productForm.invalid) {
      console.log('Invalid');
      return;
    }

    let tempForm = new FormGroup({});
    this.prepareFinalData(this.productForm, tempForm);

    if (this.isEditing) {
      console.log('Edit mode');
      isUpdatefile = true;
      this._apiService
        .UpdateDataById(
          this.apiPath,
          this.productObj.id.toString(),
          tempForm.value
        )
        .subscribe(
          (res) => {
            console.log('res from update');
            const paramsCover = {
              isEdit: true,
              idResId: res.data.resultData[0],
              tablename: 'content',
              collectionName: 'landingImage',
              ordinal: 0,
            };
            this.landingImageUploadComponent.uploadImage(paramsCover);

            const params = {
              isEdit: true,
              idResId: res.data.resultData[0],
              tablename: 'content',
              collectionName: this.filesPropName.item.coverImage,
              ordinal: 0,
            };
            this.imageUploadComponent.uploadImage(params);

            const paramsProduct = {
              isEdit: true,
              idResId: res.data.resultData[0],
              tablename: 'content',
              collectionName: 'productImage',
              ordinal: 0,
            };
            this.productImageUploadComponent.uploadImage(paramsProduct);

            const paramsTechnology = {
              isEdit: true,
              idResId: res.data.resultData[0],
              tablename: 'content',
              collectionName: 'technologyImage',
              ordinal: 0,
            };
            this.technologyImageUploadComponent.uploadImage(paramsTechnology);

            this.detailListForm.value.forEach((element, index) => {
              const paramsMultiple = {
                isEdit: true,
                idResId: res.data.resultData[0],
                tablename: 'content',
                collectionName: `detailList${index}`,
                ordinal: 0,
              };
              this.detailImageUploadComponent.find(
                (component: ImageUploadComponent) =>
                  component.elementRef.nativeElement.id === paramsMultiple['collectionName'])
                .uploadImage(paramsMultiple);
            });

            this.featureFormArray.value.forEach((element, index) => {
              const paramsMultiple = {
                isEdit: true,
                idResId: res.data.resultData[0],
                tablename: 'content',
                collectionName: `featureTech${index}`,
                ordinal: 0,
              };
              this.featureImageUploadComponent.find(
                (component: ImageUploadComponent) =>
                  component.elementRef.nativeElement.id === paramsMultiple['collectionName'])
                .uploadImage(paramsMultiple);
            });

            this.otherFeatureFormArray.value.forEach((element, index) => {
              const paramsMultiple = {
                isEdit: true,
                idResId: res.data.resultData[0],
                tablename: 'content',
                collectionName: `otherFeatureTech${index}`,
                ordinal: 0,
              };
              this.otherFeatureImageUploadComponent.find(
                (component: ImageUploadComponent) =>
                  component.elementRef.nativeElement.id === paramsMultiple['collectionName'])
                .uploadImage(paramsMultiple);
            });

            this.beforeAfterFormArray.value.forEach((element, index) => {
              const paramsMultiple = {
                isEdit: true,
                idResId: res.data.resultData[0],
                tablename: 'content',
                collectionName: `beforeAfter${index}`,
                ordinal: 0,
              };
              this.beforeAfterImageUploadComponent.find(
                (component: ImageUploadComponent) =>
                  component.elementRef.nativeElement.id === paramsMultiple['collectionName'])
                .uploadImage(paramsMultiple);
            });

            this._router.navigate([this.callbackUrl]);
            self._componentsService.SuccessSwal();
          },
          (err) => {
            self._componentsService.ErrorSwal();
          }
        );

      this._apiService
        .UpdateDataById(
          this.apiPath,
          this.productObj.id.toString(),
          tempForm.value
        )
        .subscribe(
          (res) => {
            this._componentsService.SuccessSwal();
            this._router.navigate([`${this.pathUrl}`]);
          },
          (err) => {
            this._componentsService.ErrorSwal();
          }
        );
    } else {
      isUpdatefile = false;

      this._apiService.AddData(this.apiPath, tempForm.value).subscribe(
        (res) => {
          console.log('DOM : ' + res.data.resultData);

          const paramsCover = {
            isEdit: false,
            idResId: res.data.resultData[0],
            tablename: 'content',
            collectionName: 'landingImage',
            ordinal: 0,
          };
          this.landingImageUploadComponent.uploadImage(paramsCover);

          const params = {
            isEdit: false,
            idResId: res.data.resultData[0],
            tablename: 'content',
            collectionName: this.filesPropName.item.coverImage,
            ordinal: 0,
          };

          // cover file upload
          this.imageUploadComponent.uploadImage(params);

          const paramsProduct = {
            isEdit: false,
            idResId: res.data.resultData[0],
            tablename: 'content',
            collectionName: 'productImage',
            ordinal: 0,
          };
          this.productImageUploadComponent.uploadImage(paramsProduct);

          const paramsTechnology = {
            isEdit: true,
            idResId: res.data.resultData[0],
            tablename: 'content',
            collectionName: 'technologyImage',
            ordinal: 0,
          };
          this.technologyImageUploadComponent.uploadImage(paramsTechnology);

          this.detailListForm.value.forEach((element, index) => {
            const paramsMultiple = {
              isEdit: false,
              idResId: res.data.resultData[0],
              tablename: 'content',
              collectionName: `detailList${index}`,
              ordinal: 0,
            };
            this.detailImageUploadComponent.find(
              (component: ImageUploadComponent) =>
                component.elementRef.nativeElement.id === paramsMultiple['collectionName'])
              .uploadImage(paramsMultiple);
          });

          this.featureFormArray.value.forEach((element, index) => {
            const paramsMultiple = {
              isEdit: false,
              idResId: res.data.resultData[0],
              tablename: 'content',
              collectionName: `featureTech${index}`,
              ordinal: 0,
            };
            this.featureImageUploadComponent.find(
              (component: ImageUploadComponent) =>
                component.elementRef.nativeElement.id === paramsMultiple['collectionName'])
              .uploadImage(paramsMultiple);
          });

          this.otherFeatureFormArray.value.forEach((element, index) => {
            const paramsMultiple = {
              isEdit: false,
              idResId: res.data.resultData[0],
              tablename: 'content',
              collectionName: `otherFeatureTech${index}`,
              ordinal: 0,
            };
            this.otherFeatureImageUploadComponent.find(
              (component: ImageUploadComponent) =>
                component.elementRef.nativeElement.id === paramsMultiple['collectionName'])
              .uploadImage(paramsMultiple);
          });

          this.beforeAfterFormArray.value.forEach((element, index) => {
            const paramsMultiple = {
              isEdit: false,
              idResId: res.data.resultData[0],
              tablename: 'content',
              collectionName: `beforeAfter${index}`,
              ordinal: 0,
            };
            this.beforeAfterImageUploadComponent.find(
              (component: ImageUploadComponent) =>
                component.elementRef.nativeElement.id === paramsMultiple['collectionName'])
              .uploadImage(paramsMultiple);
          });

          this._router.navigate([this.callbackUrl]);
          self._componentsService.SuccessSwal();
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
    }
  }

  uploadCoverFile(isUpdateFile: boolean, itemGuid: string, formData): void {
    // let itemGuid = this.itemObj.id.toString();
    console.log(formData);
    if (isUpdateFile) {
      this._fileService
        .UpdateCover(
          this.apiPath,
          itemGuid,
          this.filesPropName.item.coverImage,
          0,
          formData
        )
        .subscribe((res) => { });
    } else {
      this._fileService
        .AddCover(
          this.apiPath,
          itemGuid,
          this.filesPropName.item.coverImage,
          0,
          formData
        )
        .subscribe((res) => { });
    }
  }

  uploadFiles(
    isUpdateFile: boolean,
    itemGuid: string,
    collectionName: string,
    ordinal: number,
    filePool: any
  ): void {
    let fileArr = new FormData();
    filePool.forEach((file: Blob) => {
      // let fullFileName = file.name

      fileArr.append('files', file);
    });

    if (isUpdateFile) {
      this._fileService
        .UpdateFile(
          this.apiPath,
          this.productObj.id.toString(),
          collectionName,
          ordinal,
          fileArr
        )
        .subscribe((res) => {
          // this._componentsService.SuccessSwal();
          return;
        });
    } else {
      this._fileService
        .AddFile(this.apiPath, itemGuid, collectionName, ordinal, fileArr)
        .subscribe((res) => {
          // this._componentsService.SuccessSwal();
          return;
        });
    }
  }

  onSelectImage(event) {
    console.log('Test' + this.images);
    this.AlertFiles = false;
    var self = this;
    var invalidFiles = false;
    for (let i = 0; i < event.addedFiles.length; i++) {
      if (event.addedFiles.length <= this.countMedia) {
        console.log(event.addedFiles.length + this.images.length);
        if (event.addedFiles.length + this.images.length > this.countMedia) {
          this.AlertFiles = true;
        }
        const file = event.addedFiles[i];
        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            self.images.push(file);
          };
          img.src = URL.createObjectURL(file);
        };
        reader.readAsDataURL(file);
      } else {
        console.log('Media files limit 5 items');
        this.AlertFiles = true;
      }
    }
    // this.files.push(...event.addedFiles);
  }
  getBackgroundStyle(image: Blob): SafeStyle {
    const url = URL.createObjectURL(image);
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }
  onSelectAtteched(event, arr) {
    var invalidFiles = false;
    for (let i = 0; i < event.addedFiles.length; i++) {
      const file = event.addedFiles[i];
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          arr.push(file);
        };
        img.src = URL.createObjectURL(file);
      };
      reader.readAsDataURL(file);
    }
    // this.files.push(...event.addedFiles);
  }
  onDragStart(event: DragEvent, index: number) {
    event.dataTransfer?.setData('text/plain', String(index));
    console.log(event);
  }

  onDragEnter(event: DragEvent, targetIndex: number) {
    console.log(event);
    event.preventDefault();
    const dragIndex = Number(event.dataTransfer?.getData('text/plain'));
    if (dragIndex !== targetIndex) {
      // Reorder the array elements
      const [draggedItem] = this.images.splice(dragIndex, 1);
      this.images.splice(targetIndex, 0, draggedItem);

      // Update the imageUrls array accordingly
      const [draggedImageUrl] = this.images.splice(dragIndex, 1);
      this.images.splice(targetIndex, 0, draggedImageUrl);
    }
  }

  onDragEnd(event: DragEvent) {
    event.preventDefault();
  }
  onRemoveAtteched(event, arr) {
    arr.splice(arr.indexOf(event), 1);
    if ((arr.length + this.images.length) / 2 <= this.countMedia) {
      this.AlertFiles = false;
    }
    console.log((arr.length + this.images.length) / 2);
  }
  onDrop(event: DragEvent) {
    console.log(event);

    event.preventDefault();
    const index = Number(event.dataTransfer?.getData('text/plain'));
    // Implement your logic here for handling the drop event
    // For example, you can reorder the images based on the drag-and-drop action.
  }

  onDragOver(event: DragEvent) {
    console.log(event);
    event.preventDefault();
  }

  onFilesUploaded(files: File[]): void {
    // Here you have access to the files array uploaded from the child component.
    // You can now handle the files in the parent component as you desire.
    console.log('Uploaded files:', files);
    this.images = files;
  }

  get beforeAfterFormArray() {
    return this.productForm.controls["beforeAfterList"] as FormArray;
  }

  get detailListForm() {
    return this.productForm.controls['detailList'] as FormArray;
  }

  get featureFormArray() {
    return this.productForm.controls["featureTech"] as FormArray;
  }

  get otherFeatureFormArray() {
    return this.productForm.controls["otherFeatureTech"] as FormArray;
  }


  addFeatureTechItemRow(index): void {
    const descForm: any = {}
    const labelControls: any = {};
    const detailControls: any = {};
    this.langList.forEach((lang) => {
      for (const lang of this.langList) {
        labelControls[lang] = '', [Validators.required];
        detailControls[lang] = '', [Validators.required];
      }
    });
    descForm[`label`] = this._formBuilder.group(
      labelControls
    );
    descForm[`image${index}`] = this._formBuilder.control;
    descForm[`detail`] = this._formBuilder.group(
      detailControls
    );

    this.featureFormArray.push(this._formBuilder.group(descForm));
  }

  addOtherFeatureTechItemRow(index): void {
    const descForm: any = {}
    const detailControls: any = {};
    this.langList.forEach((lang) => {
      for (const lang of this.langList) {
        detailControls[lang] = '', [Validators.required];
      }
    });
    descForm[`image${index}`] = this._formBuilder.control;
    descForm[`detail`] = this._formBuilder.group(
      detailControls
    );

    this.otherFeatureFormArray.push(this._formBuilder.group(descForm));
  }

  // addItemRowButton() {
  //   // console.log(this.arrayForm.length)
  //   this.addItemRow(this.arrayForm.length + 1)
  // }

  trackBy(idx: number, item: { id: number }): number {
    return item.id;
  }

  removeRow(isFeature: boolean, index: number): void {

    if (isFeature) {
      this.featureFormArray.removeAt(index);
    } else {
      this.otherFeatureFormArray.removeAt(index);
    }
  }

  selectedLanguage(lang: string) {
    this.selectedLang = lang;
  }

  handleInputChanged(value: string, controlName: string) {
    this.handleList[controlName][0] = true;
    const element = this.el.nativeElement.querySelector(`#${controlName}`);
    if (element) {
      this.renderer.addClass(element, 'invalid_border');
    }
  }

}
