import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CmsFormpageModule } from '../../cms-formpage/cms-formpage.module';
import { AuthGuard } from 'app/auth/helpers';
import { TechProductLandingPageComponent } from './tech-product-landing-page.component';

const path: string = 'cms/repove-tools/tech-product-landing';
const routes: Routes = [
  {
    path: path,
    component: TechProductLandingPageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-tech-product-landing' },
  },
];

@NgModule({
  declarations: [
  ],
  imports: [CommonModule, RouterModule.forChild(routes), CmsFormpageModule],
  exports: [],
})
export class TechProductLandingPageModule { }
