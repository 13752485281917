import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-seller-manage-regulation',
  templateUrl: './seller-manage-regulation.component.html',
  styleUrls: ['./seller-manage-regulation.component.scss'],
})
export class SellerManageRegulationComponent implements OnInit {
  config: ICMSConfig = {
    apiPath: 'SellerManagementRegulation',
    tableName: 'Content',
    pathUrl: '/cms/seller-manage-regulation',
    model: new Object(),
    isList: false,
    componentName: 'Seller Management Regulation',
    formConfig: {
      sellerManageDate: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Date',
        description: 'Description of Seller Management Regulation',
        labelTranslate: 'Informative.SellerManagementRegulation.Date.Label',
        descriptionTranslate:
          'Informative.SellerManagementRegulation.Date.Description',
      },
      sellerManageName: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Title',
        description: 'Description of Seller Management Regulation',
        labelTranslate: 'Informative.SellerManagementRegulation.Title.Label',
        descriptionTranslate:
          'Informative.SellerManagementRegulation.Title.Description',
      },
      sellerManageDescription: {
        type: FormType.RichText,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Description',
        description: 'Description of Seller Management Regulation',
        labelTranslate:
          'Informative.SellerManagementRegulation.Description.Label',
        descriptionTranslate:
          'Informative.SellerManagementRegulation.Description.Description',
      },
    },
  };

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
