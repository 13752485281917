import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/main/service/api.service';

@Component({
  selector: 'app-truncate',
  templateUrl: './truncate.component.html',
  styleUrls: ['./truncate.component.scss'],
})
export class TruncateComponent implements OnInit {
  contentHeader: object;
  Links = [
    {
      label: 'Banner',
      routerLink: '/admin/trash/detail/banner',
    },
    {
      label: 'News',
      routerLink: '/admin/trash/detail/news',
    },
    {
      label: 'Content',
      routerLink: '/admin/trash/detail/content',
    },
    {
      label: 'Item',
      routerLink: '/admin/trash/detail/item',
    },
    {
      label: 'Group',
      routerLink: '/admin/trash/detail/group',
    },
    {
      label: 'Category',
      routerLink: '/admin/trash/detail/category',
    },
    {
      label: 'Sub-Category',
      routerLink: '/admin/trash/detail/subcategory',
    },
    {
      label: 'Class',
      routerLink: '/admin/trash/detail/class',
    },
    {
      label: 'Sub-Class',
      routerLink: '/admin/trash/detail/subclass',
    },
  ];

  @Input() apiPath: string = 'banner';

  itemList: any[];

  constructor(
    private _apiService: ApiService,
    private _route: ActivatedRoute
  ) {}

  initData(): void {
    this.apiPath = this._route.snapshot.paramMap.get('apiPath');

    this.getTruncateData(this.apiPath);
  }

  getTruncateData(apiPath: string): Promise<any> {
    var self = this;
    let tmpItemList;
    return new Promise((resolve, rejects) => {
      this._apiService.GetAllData(apiPath).subscribe((res) => {
        tmpItemList = res.data.resultData;
        this.itemList = tmpItemList.filter((item) => item.isDelete === true);
      });
    });
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'BreadCrumbs.Manage.Trash',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }
}
