<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="home-page">
      <div class="row nav-vertical d-flex">
        <div class="col-md-3 mb-2 mb-md-0">
          <ul
            ngbNav
            #navVertical="ngbNav"
            class="nav nav-pills flex-column nav-left"
          >
            <li class="nav-item" ngbNavItem>
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
              >
                <i data-feather="user" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold">{{
                  "User.General" | translate
                }}</span>
              </a>
              <ng-template ngbNavContent>
                <app-detail (getUserData)="getUserData()"></app-detail>
              </ng-template>
            </li>
            <!-- <li class="nav-item" ngbNavItem>
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
              >
                <i data-feather="settings" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold">{{
                  "User.AccountSettings" | translate
                }}</span>
              </a>
              <ng-template ngbNavContent>
                <app-account-settings></app-account-settings>
              </ng-template>
            </li>
            <li class="nav-item" ngbNavItem>
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
              >
                <i data-feather="lock" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold">{{
                  "User.Security" | translate
                }}</span>
              </a>
              <ng-template ngbNavContent>
                <app-change-password></app-change-password>
              </ng-template>
            </li> -->
          </ul>
        </div>
        <div [ngbNavOutlet]="navVertical" class="col-12 col-md-9"></div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
