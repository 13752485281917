import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { CompanyVideoService } from '../company-video.service';

@Component({
  selector: 'app-company-video-create',
  templateUrl: './company-video-create.component.html',
  styleUrls: ['./company-video-create.component.scss'],
})
export class CompanyVideoCreateComponent implements OnInit, OnDestroy {
  config: ICMSConfig;

  constructor(private _companyVideoService: CompanyVideoService) {
    this.config = this._companyVideoService.config;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
