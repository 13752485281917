<div style="background: #f0f0f0" class="col-md-12 form-group border">
  <div class="d-flex p-1 justify-content-between align-items-center">
    <b class="title-customer">
      <i [size]="22" class="feather_title mr-1" data-feather="map-pin"></i
      >{{ "Address.addrInfo" | translate }}</b
    >
  </div>
</div>

<form [formGroup]="registerForm" *ngIf="!isEditing" class="ctm-addr">
  <div class="form-group row">
    <div class="col-md-12 mb-3">
      <label for="helpInputTop"
        >{{ "Address.addrInfo" | translate }}
        <span class="text-danger">*</span></label
      >
      <small class="text-muted">
        <i> {{ "Address.AlertAddr" | translate }}</i></small
      >
    </div>
    <div class="col-md-6 col-sm-12" style="border-right: 1px dashed #636363">
      <div class="form-group row">
        <div class="col-md-12 col-form-label" style="height: 50px">
          <b>{{ "Address.registeredAddress" | translate }}</b>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          {{ "Customer.contactType" | translate }}
        </div>
        <div class="col-md-9 d-flex align-items-center">
          <div class="mr-3 custom-control custom-radio">
            <input
              type="radio"
              [value]="true"
              id="c_businessTRadio1"
              formControlName="c_isLegalAddr"
              name="c_isLegalAddr"
              class="custom-control-input"
              (change)="isBusinessType(true)"
              checked
            />
            <label class="custom-control-label" for="c_businessTRadio1">{{
              "Customer.JuristicPerson" | translate
            }}</label>
          </div>
          <div class="custom-control custom-radio">
            <input
              type="radio"
              [value]="false"
              id="c_businessTRadio2"
              formControlName="c_isLegalAddr"
              name="c_isLegalAddr"
              class="custom-control-input"
              (change)="isBusinessType(false)"
              checked=""
            />
            <label class="custom-control-label" for="c_businessTRadio2">{{
              "Customer.OrdinaryPerson" | translate
            }}</label>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div
          class="col-md-6"
          [ngClass]="{
            'col-md-12': f.c_isLegalAddr.value == true
          }"
        >
          <div class="col-form-label">
            {{
              (f.c_isLegalAddr.value == true
                ? "Address.contactPerson"
                : "Customer.firstName"
              ) | translate
            }}
            <span class="text-danger">*</span>
          </div>
          <input
            class="form-control"
            formControlName="c_fname"
            #c_nameInput
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.c_fname?.invalid) ||
                (f.c_fname?.invalid &&
                  (f.c_fname?.dirty || f.c_fname?.touched)) ||
                (f.c_fname?.value == '' &&
                  (f.c_fname?.dirty || f.c_fname?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.c_fname?.invalid) ||
                (f.c_fname.invalid && (f.c_fname.dirty || f.c_fname.touched))
            }"
          >
            <div>
              <div *ngIf="f.c_fname.errors?.required">
                {{ "Customer.nameRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-none col-md-6"
          [ngClass]="{
            'd-none': f.c_isLegalAddr.value == true
          }"
          [ngClass]="{
            'd-block': f.c_isLegalAddr.value == false
          }"
        >
          <div class="col-form-label">
            {{ "Customer.lastName" | translate }}
            <span class="text-danger">*</span>
          </div>
          <input
            class="form-control"
            formControlName="c_lname"
            #c_nameInput
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit &&
                  !f.c_lname?.value &&
                  f.c_isLegalAddr.value == false) ||
                (f.c_lname?.touched &&
                  !f.c_lname?.value &&
                  f.c_isLegalAddr.value == false)
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit &&
                  !f.c_lname?.value &&
                  f.c_isLegalAddr.value == false) ||
                (f.c_lname?.touched &&
                  !f.c_lname?.value &&
                  f.c_isLegalAddr.value == false)
            }"
          >
            <div>
              <div *ngIf="!f.c_lname?.value && f.c_isLegalAddr.value == false">
                {{ "Customer.lastNameRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="form-group row"
        [ngClass]="{
          'd-none': f.c_isLegalAddr.value == false
        }"
      >
        <div class="col-md-3 col-form-label">
          {{ "Customer.office" | translate }}
        </div>
        <div class="col-md-9 d-flex align-items-center">
          <div class="custom-control custom-radio mr-2">
            <input
              type="radio"
              id="c_havecompany2"
              value="1"
              name="c_branchType"
              class="custom-control-input"
              formControlName="c_branchType"
              checked
            />
            <label class="custom-control-label" for="c_havecompany2">{{
              "Customer.headOffice" | translate
            }}</label>
          </div>
          <div class="custom-control custom-radio mr-2">
            <input
              type="radio"
              id="c_havecompany3"
              value="2"
              name="c_branchType"
              class="custom-control-input"
              formControlName="c_branchType"
            />
            <label class="custom-control-label" for="c_havecompany3">{{
              "Customer.branch" | translate
            }}</label>
          </div>
        </div>
      </div>
      <div
        class="form-group row"
        [ngClass]="{
          'd-none': f.c_isLegalAddr.value == false
        }"
        *ngIf="f.c_branchType.value === '2'"
      >
        <div class="col-md-12">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="c_branchNumber"
                >{{ "Customer.branchCodeLabel" | translate }}
                <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="c_branchNumber"
                id="c_branchNumber"
                type="number"
                (blur)="
                  f.c_branchNumber.setValue(
                    f.c_branchNumber.value.toString().padStart(5, '0')
                  )
                "
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                [inputMode]="'numeric'"
                placeholder="{{ 'Customer.branchCodePlaceholder' | translate }}"
                pattern="\d*"
                maxlength="10"
                [ngClass]="{
                  'is-invalid error':
                    (isSubmit &&
                      f.c_branchType.value === '2' &&
                      !f.c_branchNumber?.value) ||
                    f.c_branchNumber?.value == '00000' ||
                    (!f.c_branchNumber?.value && f.c_branchNumber?.touched) ||
                    (f.c_branchNumber.invalid &&
                      (f.c_branchNumber.dirty || f.c_branchNumber.touched))
                }"
              />

              <div
                class="invalid-feedback"
                [ngClass]="{
                  'd-block':
                    f.c_branchNumber.invalid &&
                    (f.c_branchNumber.dirty || f.c_branchNumber.touched)
                }"
              >
                <div>
                  <div *ngIf="f.c_branchNumber.errors?.message">
                    {{ f.c_branchNumber.errors.message }}
                  </div>
                  <div *ngIf="f.c_branchNumber?.value == '00000'">
                    {{ "Customer.invalidAllZeroBranchNumber" | translate }}
                  </div>
                  <div *ngIf="f.c_branchNumber.errors?.maxlength">
                    {{ "Handle.Exceed" | translate }} 5
                    {{ "Handle.Char" | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group col-md-6">
              <label for="c_branchName"
                >{{ "Customer.branchNameLabel" | translate }}
                <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="c_branchName"
                placeholder="{{ 'Customer.branchNamePlaceholder' | translate }}"
                [ngClass]="{
                  'is-invalid error':
                    (isSubmit &&
                      f.c_branchType.value == '2' &&
                      !f.c_branchName?.value) ||
                    (f.c_branchType.value == '2' &&
                      !f.c_branchName?.value &&
                      f.c_branchName?.touched)
                }"
              />

              <div
                class="invalid-feedback"
                [ngClass]="{
                  'd-block':
                    (isSubmit &&
                      f.c_branchType.value == '2' &&
                      !f.c_branchName?.value) ||
                    (f.c_branchType.value == '2' &&
                      !f.c_branchName?.value &&
                      f.c_branchName?.touched)
                }"
              >
                <div>
                  <div
                    *ngIf="
                      (isSubmit &&
                        f.c_branchType.value == '2' &&
                        !f.c_branchName?.value) ||
                      (f.c_branchType.value == '2' &&
                        !f.c_branchName?.value &&
                        f.c_branchName?.touched)
                    "
                  >
                    {{ "Customer.branchNameRequiredError" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row pt-2 pb-2">
        <div class="col-md-3">{{ "Customer.idNumber" | translate }}</div>
        <div class="col-md-9">
          <div class="min-h-screen">
            <div id="c_ContactPersonalID">
              <input
                *ngFor="let i of otpLengthArray; let index = index"
                [ngClass]="'siam_input_p_id'"
                [id]="'c-otp-field' + i"
                [style.marginRight.px]="
                  index === 0 || index === 4 || index === 9 ? 10 : 0
                "
                [style.borderColor]="
                  c_check_pid_empty ? '#ea5455' : '#a1a1a180'
                "
                type="number"
                [inputMode]="'numeric'"
                [pattern]="'[0-9]*'"
                placeholder=""
                maxlength="1"
                min="0"
                (keydown)="
                  onKeyDown($event, i, 'c_ContactPersonalID', c_index_pid)
                "
                (input)="onInput($event, i, 'c_ContactPersonalID', c_index_pid)"
                (paste)="onPaste('c_ContactPersonalID')"
              />
            </div>
            <div
              class="invalid-feedback"
              [ngClass]="{
                'd-block': c_check_pid_empty
              }"
            >
              <div>{{ "Customer.fillOutCompletely" | translate }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          {{ "Address.contactPhone" | translate }}
          <span class="text-danger">*</span>
        </div>
        <div class="col-md-9">
          <input
            class="form-control"
            formControlName="c_phone"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            #c_phoneInput
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.c_phone?.invalid) ||
                (f.c_phone?.invalid &&
                  (f.c_phone?.dirty || f.c_phone?.touched)) ||
                (f.c_phone?.value == '' &&
                  (f.c_phone?.dirty || f.c_phone?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.c_phone?.invalid) ||
                (f.c_phone.invalid && (f.c_phone.dirty || f.c_phone.touched))
            }"
          >
            <div>
              <div *ngIf="f.c_phone.errors?.required">
                {{ "Address.cPhoneRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          {{ "Address.address" | translate }} <span class="text-danger">*</span>
        </div>
        <div class="col-md-9">
          <textarea
            formControlName="c_address"
            class="form-control"
            placeholder="{{ 'Address.addressDetails' | translate }}"
            id="label-textarea"
            rows="5"
            maxlength="256"
            #c_addressInput
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.c_address?.invalid) ||
                (f.c_address?.invalid &&
                  (f.c_address?.dirty || f.c_address?.touched)) ||
                (f.c_address?.value == '' &&
                  (f.c_address?.dirty || f.c_address?.touched))
            }"
          ></textarea>
          <!-- <label for="helpInputTop">{{
              "Address.addressDetails" | translate
            }}</label>
            <small class="text-muted"
              ><i> {{ "Address.addressDetailsNote" | translate }}</i></small
            > -->
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.c_address?.invalid) ||
                (f.c_address.invalid &&
                  (f.c_address.dirty || f.c_address.touched))
            }"
          >
            <div>
              <div *ngIf="f.c_address.errors?.required">
                {{ "Address.cAddressRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          {{ "Address.country" | translate }} <span class="text-danger">*</span>
        </div>
        <div class="col-md-9">
          <select
            class="custom-select"
            formControlName="c_country"
            #c_countryInput
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.c_country?.invalid) ||
                (f.c_country?.invalid &&
                  (f.c_country?.dirty || f.c_country?.touched))
            }"
          >
            <option value="">
              -- {{ "Address.selectCountry" | translate }} --
            </option>
            <option value="0" [selected]="f.c_country?.value == '0'">
              {{ "Address.thailand" | translate }}
            </option>

            <!-- <option value="1">{{ "Address.india" | translate }}</option>
              <option value="2">{{ "Address.thailand2" | translate }}</option> -->
          </select>
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.c_country?.invalid) ||
                (f.c_country.invalid &&
                  (f.c_country.dirty || f.c_country.touched))
            }"
          >
            <div>
              <div *ngIf="f.c_country.errors?.required">
                {{ "Address.cLocalRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <div class="col-form-label">
            {{ "Address.province" | translate }}
            <span class="text-danger">*</span>
          </div>

          <div class="skeleton-select" *ngIf="!ProvinceObj">
            <div class="spinner-border spinner-border-sm mr-50" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <ng-select
            *ngIf="ProvinceObj"
            class="custom-select"
            [clearable]="false"
            placeholder="   -- {{ 'Address.selectProvince' | translate }} --"
            #c_provinceInput
            formControlName="c_province"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.c_province?.invalid) ||
                (f.c_province?.invalid &&
                  (f.c_province?.dirty || f.c_province?.touched)) ||
                (f.c_province?.value == '' &&
                  (f.c_province?.dirty || f.c_province?.touched))
            }"
            (change)="isSelectProvice($event)"
            [hideSelected]="false"
          >
            <ng-option [value]="group.id" *ngFor="let group of ProvinceObj">
              <span *ngIf="currentLang != 'en'">
                {{ group.provinceName }}
              </span>
              <span *ngIf="currentLang != 'th'">
                {{ group.provinceNameEn }}
              </span>
            </ng-option>
          </ng-select>

          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.c_province?.invalid) ||
                (f.c_province.invalid &&
                  (f.c_province.dirty || f.c_province.touched))
            }"
          >
            <div>
              <div *ngIf="f.c_province.errors?.required">
                {{ "Address.cCityRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-form-label">
            {{ "Address.district" | translate }}
            <span class="text-danger">*</span>
          </div>
          <ng-select
            class="custom-select"
            #c_districtInput
            [clearable]="false"
            placeholder="-- {{ 'Address.selectDistrict' | translate }} --"
            formControlName="c_district"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.c_district?.invalid) ||
                (f.c_district?.invalid &&
                  (f.c_district?.dirty || f.c_district?.touched)) ||
                (f.c_district?.value == '' &&
                  (f.c_district?.dirty || f.c_district?.touched))
            }"
            (change)="isSelectDistrict($event)"
            [hideSelected]="false"
          >
            <ng-option [value]="group.id" *ngFor="let group of DistrictObj">
              <span *ngIf="currentLang != 'en'">
                {{ group.districtName }}
              </span>
              <span *ngIf="currentLang != 'th'">
                {{ group.districtNameEn }}
              </span>
            </ng-option>
          </ng-select>

          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.c_district?.invalid) ||
                (f.c_district.invalid &&
                  (f.c_district.dirty || f.c_district.touched))
            }"
          >
            <div>
              <div *ngIf="f.c_district.errors?.required">
                {{ "Address.cDistrictRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-6">
          <div class="col-form-label">
            {{ "Address.subdistrict" | translate }}
            <span class="text-danger">*</span>
            <br />
          </div>
          <ng-select
            class="custom-select"
            [clearable]="false"
            placeholder="-- {{ 'Address.selectSubDistrict' | translate }} --"
            #c_subDistrictInput
            formControlName="c_subDistrict"
            (change)="getPostCode($event)"
            [hideSelected]="false"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.c_subDistrict?.invalid) ||
                (f.c_subDistrict?.invalid &&
                  (f.c_subDistrict?.dirty || f.c_subDistrict?.touched)) ||
                (f.c_subDistrict?.value == '' &&
                  (f.c_subDistrict?.dirty || f.c_subDistrict?.touched))
            }"
          >
            <ng-option [value]="group.id" *ngFor="let group of SubDistrictObj">
              <span *ngIf="currentLang != 'en'">
                {{ group.subDistrictName }}
              </span>
              <span *ngIf="currentLang != 'th'">
                {{ group.subDistrictNameEn }}
              </span>
            </ng-option>
          </ng-select>

          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.c_subDistrict?.invalid) ||
                (f.c_subDistrict.invalid &&
                  (f.c_subDistrict.dirty || f.c_subDistrict.touched))
            }"
          >
            <div>
              <div *ngIf="f.c_subDistrict.errors?.required">
                {{ "Address.cSubDistrictRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="col-form-label">
            {{ "Address.postalCode" | translate }}
            <span class="text-danger">*</span>
          </div>
          <input
            #c_postcodeInput
            class="form-control"
            formControlName="c_postcode"
            type="text"
            disabled
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.c_postcode?.invalid) ||
                (f.c_postcode?.invalid &&
                  (f.c_postcode?.dirty || f.c_postcode?.touched)) ||
                (f.c_postcode?.value == '' &&
                  (f.c_postcode?.dirty || f.c_postcode?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.c_postcode?.invalid) ||
                (f.c_postcode.invalid &&
                  (f.c_postcode.dirty || f.c_postcode.touched))
            }"
          >
            <div>
              <div *ngIf="f.c_postcode.errors?.required">
                {{ "Address.cPostcodeRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="col-md-2">
        <div
          style="width: 1px; background: #dcdcdc; height: 100%; margin: auto"
        ></div>
      </div> -->
    <div class="col-md-6 col-sm-12">
      <div class="form-group row">
        <div class="col-md-12 col-form-label" style="height: 50px">
          <div class="d-flex justify-content-between">
            <b>{{ "Address.mailingAddress" | translate }}</b>
            <button (click)="copyAddress()" class="btn btn-sm btn-primary">
              <i data-feather="copy" class="mr-1"></i>{{ "Address.copy" | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          {{ "Customer.contactType" | translate }}
        </div>
        <div class="col-md-9 d-flex align-items-center">
          <div class="mr-3 custom-control custom-radio">
            <input
              type="radio"
              [value]="true"
              id="s_businessTRadio1"
              formControlName="s_isLegalAddr"
              name="s_isLegalAddr"
              class="custom-control-input"
              (change)="isBusinessType(true)"
              checked
            />
            <label class="custom-control-label" for="s_businessTRadio1">{{
              "Customer.JuristicPerson" | translate
            }}</label>
          </div>
          <div class="custom-control custom-radio">
            <input
              type="radio"
              [value]="false"
              id="s_businessTRadio2"
              formControlName="s_isLegalAddr"
              name="s_isLegalAddr"
              class="custom-control-input"
              (change)="isBusinessType(false)"
              checked=""
            />
            <label class="custom-control-label" for="s_businessTRadio2">{{
              "Customer.OrdinaryPerson" | translate
            }}</label>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div
          class="col-md-6"
          [ngClass]="{
            'col-md-12': f.s_isLegalAddr.value == true
          }"
        >
          <div class="col-form-label">
            {{
              (f.s_isLegalAddr.value == true
                ? "Address.contactPerson"
                : "Customer.firstName"
              ) | translate
            }}
            <span class="text-danger">*</span>
          </div>
          <input
            class="form-control"
            formControlName="s_fname"
            #s_nameInput
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.s_fname?.invalid) ||
                (f.s_fname?.invalid &&
                  (f.s_fname?.dirty || f.s_fname?.touched)) ||
                (f.s_fname?.value == '' &&
                  (f.s_fname?.dirty || f.s_fname?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.s_fname?.invalid) ||
                (f.s_fname.invalid && (f.s_fname.dirty || f.s_fname.touched))
            }"
          >
            <div>
              <div *ngIf="f.s_fname.errors?.required">
                {{ "Customer.nameRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-none col-md-6"
          [ngClass]="{
            'd-none': f.s_isLegalAddr.value == true
          }"
          [ngClass]="{
            'd-block': f.s_isLegalAddr.value == false
          }"
        >
          <div class="col-form-label">
            {{ "Customer.lastName" | translate }}
            <span class="text-danger">*</span>
          </div>
          <input
            class="form-control"
            formControlName="s_lname"
            #s_nameInput
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit &&
                  !f.s_lname?.value &&
                  f.s_isLegalAddr.value == false) ||
                (f.s_lname?.touched &&
                  f.s_lname?.value == '' &&
                  f.s_isLegalAddr.value == false)
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit &&
                  !f.s_lname?.value &&
                  f.s_isLegalAddr.value == false) ||
                (f.s_lname?.touched &&
                  !f.s_lname?.value &&
                  f.s_isLegalAddr.value == false)
            }"
          >
            <div>
              <div *ngIf="!f.s_lname?.value && !f.s_isLegalAddr.value">
                {{ "Customer.lastNameRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="form-group row"
        [ngClass]="{
          'd-none': f.s_isLegalAddr.value == false
        }"
      >
        <div class="col-md-3 col-form-label">
          {{ "Customer.office" | translate }}
        </div>
        <div class="col-md-9 d-flex align-items-center">
          <div class="custom-control custom-radio mr-2">
            <input
              type="radio"
              id="addr_havecompany2"
              value="1"
              name="s_branchType"
              class="custom-control-input"
              formControlName="s_branchType"
              checked
            />
            <label class="custom-control-label" for="addr_havecompany2">{{
              "Customer.headOffice" | translate
            }}</label>
          </div>
          <div class="custom-control custom-radio mr-2">
            <input
              type="radio"
              id="addr_havecompany3"
              value="2"
              name="s_branchType"
              class="custom-control-input"
              formControlName="s_branchType"
            />
            <label class="custom-control-label" for="addr_havecompany3">{{
              "Customer.branch" | translate
            }}</label>
          </div>
        </div>
      </div>
      <div
        class="form-group row"
        [ngClass]="{
          'd-none': f.s_branchType.value == false
        }"
        *ngIf="f.s_branchType.value === '2'"
      >
        <div class="col-md-12">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="helpInputTop"
                >{{ "Customer.branchCodeLabel" | translate }}
                <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="s_branchNumber"
                id="helpInputTop"
                type="number"
                (blur)="
                  f.s_branchNumber.setValue(
                    f.s_branchNumber.value.toString().padStart(5, '0')
                  )
                "
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                [inputMode]="'numeric'"
                placeholder="{{ 'Customer.branchCodePlaceholder' | translate }}"
                pattern="\d*"
                maxlength="10"
                [ngClass]="{
                  'is-invalid error':
                    (isSubmit &&
                      f.s_branchType.value === '2' &&
                      !f.s_branchNumber?.value) ||
                    f.s_branchNumber?.value == '00000' ||
                    (!f.s_branchNumber?.value && f.s_branchNumber?.touched) ||
                    (f.s_branchNumber.invalid &&
                      (f.s_branchNumber.dirty || f.s_branchNumber.touched))
                }"
              />

              <div
                class="invalid-feedback"
                [ngClass]="{
                  'd-block':
                    f.s_branchNumber.invalid &&
                    (f.s_branchNumber.dirty || f.s_branchNumber.touched)
                }"
              >
                <div>
                  <div *ngIf="f.s_branchNumber.errors?.message">
                    {{ f.s_branchNumber.errors.message }}
                  </div>
                  <div *ngIf="f.s_branchNumber?.value == '00000'">
                    {{ "Customer.invalidAllZeroBranchNumber" | translate }}
                  </div>
                  <div *ngIf="f.s_branchNumber.errors?.maxlength">
                    {{ "Handle.Exceed" | translate }} 5
                    {{ "Handle.Char" | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group col-md-6">
              <label for="helpInputTop"
                >{{ "Customer.branchNameLabel" | translate }}
                <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="s_branchName"
                placeholder="{{ 'Customer.branchNamePlaceholder' | translate }}"
                [ngClass]="{
                  'is-invalid error':
                    (isSubmit &&
                      f.s_branchType.value == '2' &&
                      !f.s_branchName?.value) ||
                    (f.branchType.value == '2' &&
                      !f.s_branchName?.value &&
                      f.s_branchName?.touched)
                }"
              />

              <div
                class="invalid-feedback"
                [ngClass]="{
                  'd-block':
                    (isSubmit &&
                      f.s_branchType.value == '2' &&
                      !f.s_branchName?.value) ||
                    (f.s_branchType.value == '2' &&
                      !f.s_branchName?.value &&
                      f.s_branchName?.touched)
                }"
              >
                <div>
                  <div
                    *ngIf="
                      (isSubmit &&
                        f.s_branchType.value == '2' &&
                        !f.s_branchName?.value) ||
                      (f.s_branchType.value == '2' &&
                        !f.s_branchName?.value &&
                        f.s_branchName?.touched)
                    "
                  >
                    {{ "Customer.branchNameRequiredError" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row pt-2 pb-2">
        <div class="col-md-3">{{ "Customer.idNumber" | translate }}</div>
        <div class="col-md-9">
          <div class="min-h-screen">
            <div id="s_ContactPersonalID">
              <input
                *ngFor="let i of otpLengthArray; let index = index"
                [ngClass]="'siam_input_p_id'"
                [id]="'s-otp-field' + i"
                [style.marginRight.px]="
                  index === 0 || index === 4 || index === 9 ? 10 : 0
                "
                [style.borderColor]="
                  s_check_pid_empty ? '#ea5455' : '#a1a1a180'
                "
                type="number"
                [inputMode]="'numeric'"
                [pattern]="'[0-9]*'"
                placeholder=""
                maxlength="1"
                min="0"
                (keydown)="
                  onKeyDown($event, i, 's_ContactPersonalID', s_index_pid)
                "
                (input)="onInput($event, i, 's_ContactPersonalID', s_index_pid)"
                (paste)="onPaste('s_ContactPersonalID')"
              />
            </div>
            <div
              class="invalid-feedback"
              [ngClass]="{
                'd-block': s_check_pid_empty
              }"
            >
              <div>{{ "Customer.fillOutCompletely" | translate }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          {{ "Address.contactPhone" | translate }}
          <span class="text-danger">*</span>
        </div>
        <div class="col-md-9">
          <input
            class="form-control"
            formControlName="s_phone"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            #s_phoneInput
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.s_phone?.invalid) ||
                (f.s_phone?.invalid &&
                  (f.s_phone?.dirty || f.s_phone?.touched)) ||
                (f.s_phone?.value == '' &&
                  (f.s_phone?.dirty || f.s_phone?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.s_phone?.invalid) ||
                (f.s_phone.invalid && (f.s_phone.dirty || f.s_phone.touched))
            }"
          >
            <div>
              <div *ngIf="f.s_phone.errors?.required">
                {{ "Address.cPhoneRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          {{ "Address.address" | translate }} <span class="text-danger">*</span>
        </div>
        <div class="col-md-9">
          <textarea
            formControlName="s_address"
            class="form-control"
            id="label-textarea"
            placeholder="{{ 'Address.addressDetails' | translate }}"
            #s_addressInput
            rows="5"
            maxlength="256"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.s_address?.invalid) ||
                (f.s_address?.invalid &&
                  (f.s_address?.dirty || f.s_address?.touched)) ||
                (f.s_address?.value == '' &&
                  (f.s_address?.dirty || f.s_address?.touched))
            }"
          ></textarea>
          <!-- <label for="helpInputTop">{{
              "Address.addressDetails" | translate
            }}</label>
            <small class="text-muted">
              <i> {{ "Address.addressDetailsNote" | translate }}</i></small
            > -->
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.s_address?.invalid) ||
                (f.s_address.invalid &&
                  (f.s_address.dirty || f.s_address.touched))
            }"
          >
            <div>
              <div *ngIf="f.s_address.errors?.required">
                {{ "Address.cAddressRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          {{ "Address.country" | translate }} <span class="text-danger">*</span>
        </div>
        <div class="col-md-9">
          <select
            class="custom-select"
            formControlName="s_country"
            #s_countryInput
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.s_country?.invalid) ||
                (f.s_country?.invalid &&
                  (f.s_country?.dirty || f.s_country?.touched))
            }"
          >
            <option value="">
              -- {{ "Address.selectCountry" | translate }} --
            </option>
            <option value="0">{{ "Address.thailand" | translate }}</option>
            <!-- <option value="1">{{ "Address.india" | translate }}</option>
              <option value="2">{{ "Address.thailand2" | translate }}</option> -->
          </select>
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.s_country?.invalid) ||
                (f.s_country.invalid &&
                  (f.s_country.dirty || f.s_country.touched))
            }"
          >
            <div>
              <div *ngIf="f.s_country.errors?.required">
                {{ "Address.cLocalRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <div class="col-form-label">
            {{ "Address.province" | translate }}
            <span class="text-danger">*</span>
          </div>
          <div class="skeleton-select" *ngIf="isLoadingSprovice">
            <div class="spinner-border spinner-border-sm mr-50" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <ng-select
            *ngIf="!isLoadingSprovice"
            class="custom-select"
            #s_provinceInput
            formControlName="s_province"
            placeholder="   -- {{ 'Address.selectProvince' | translate }} --"
            (change)="isSelectProvice_2($event)"
            [hideSelected]="false"
            [clearable]="false"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.s_province?.invalid) ||
                (f.s_province?.invalid &&
                  (f.s_province?.dirty || f.s_province?.touched)) ||
                (f.s_province?.value == '' &&
                  (f.s_province?.dirty || f.s_province?.touched))
            }"
          >
            <ng-option
              [value]="group.id"
              *ngFor="let group of ProvinceObj2"
              [selected]="group.id === f.s_province?.value"
            >
              <span *ngIf="currentLang != 'en'">
                {{ group.provinceName }}
              </span>
              <span *ngIf="currentLang != 'th'">
                {{ group.provinceNameEn }}
              </span>
            </ng-option>
          </ng-select>

          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.s_province?.invalid) ||
                (f.s_province.invalid &&
                  (f.s_province.dirty || f.s_province.touched))
            }"
          >
            <div>
              <div *ngIf="f.s_province.errors?.required">
                {{ "Address.cCityRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-form-label">
            {{ "Address.district" | translate }}
            <span class="text-danger">*</span>
          </div>
          <div class="skeleton-select" *ngIf="isLoadingSdistrict">
            <div class="spinner-border spinner-border-sm mr-50" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <ng-select
            *ngIf="!isLoadingSdistrict"
            class="custom-select"
            #s_districtInput
            formControlName="s_district"
            placeholder="   -- {{ 'Address.selectDistrict' | translate }} --"
            (change)="isSelectDistrict_2($event)"
            [hideSelected]="false"
            [clearable]="false"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.s_district?.invalid) ||
                (f.s_district?.invalid &&
                  (f.s_district?.dirty || f.s_district?.touched)) ||
                (f.s_district?.value == '' &&
                  (f.s_district?.dirty || f.s_district?.touched))
            }"
          >
            <ng-option
              [value]="group.id"
              *ngFor="let group of DistrictObj2"
              [selected]="group.id === f.s_district?.value"
            >
              <span *ngIf="currentLang != 'en'">
                {{ group.districtName }}
              </span>
              <span *ngIf="currentLang != 'th'">
                {{ group.districtNameEn }}
              </span>
            </ng-option>
          </ng-select>

          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.s_district?.invalid) ||
                (f.s_district.invalid &&
                  (f.s_district.dirty || f.s_district.touched))
            }"
          >
            <div>
              <div *ngIf="f.s_district.errors?.required">
                {{ "Address.cDistrictRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-6">
          <div class="col-form-label">
            {{ "Address.subdistrict" | translate }}
            <span class="text-danger">*</span>
          </div>
          <div class="skeleton-select" *ngIf="isLoadingSsubDistrict">
            <div class="spinner-border spinner-border-sm mr-50" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <ng-select
            *ngIf="!isLoadingSsubDistrict"
            class="custom-select"
            #s_subDistrictInput
            formControlName="s_subDistrict"
            placeholder="   -- {{ 'Address.selectSubDistrict' | translate }} --"
            (change)="getPostCode_2($event)"
            [hideSelected]="false"
            [clearable]="false"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.s_subDistrict?.invalid) ||
                (f.s_subDistrict?.invalid &&
                  (f.s_subDistrict?.dirty || f.s_subDistrict?.touched)) ||
                (f.s_subDistrict?.value == '' &&
                  (f.s_subDistrict?.dirty || f.s_subDistrict?.touched))
            }"
          >
            <ng-option
              [value]="group.id"
              *ngFor="let group of SubDistrictObj2"
              [selected]="group.id === f.s_subDistrict?.value"
            >
              <span *ngIf="currentLang != 'en'">
                {{ group.subDistrictName }}
              </span>
              <span *ngIf="currentLang != 'th'">
                {{ group.subDistrictNameEn }}
              </span>
            </ng-option>
          </ng-select>

          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.s_subDistrict?.invalid) ||
                (f.s_subDistrict.invalid &&
                  (f.s_subDistrict.dirty || f.s_subDistrict.touched))
            }"
          >
            <div>
              <div *ngIf="f.s_subDistrict.errors?.required">
                {{ "Address.cSubDistrictRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="col-form-label">
            {{ "Address.postalCode" | translate }}
            <span class="text-danger">*</span>
          </div>
          <input
            #s_postcodeInput
            class="form-control"
            formControlName="s_postcode"
            type="text"
            disabled
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.s_postcode?.invalid) ||
                (f.s_postcode?.invalid &&
                  (f.s_postcode?.dirty || f.s_postcode?.touched)) ||
                (f.s_postcode?.value == '' &&
                  (f.s_postcode?.dirty || f.s_postcode?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.s_postcode?.invalid) ||
                (f.s_postcode.invalid &&
                  (f.s_postcode.dirty || f.s_postcode.touched))
            }"
          >
            <div>
              <div *ngIf="f.s_postcode.errors?.required">
                {{ "Address.cPostcodeRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="form-group row" *ngIf="isEditing">
  <div class="col-md-6" style="border-right: 1px dashed #636363">
    <div class="d-flex justify-content-between">
      <h5 style="align-self: center">
        {{ "Address.registeredAddress" | translate }}
      </h5>
      <a
        (click)="openAddressModal(false, null, addressType.Billing)"
        class="btn btn-primary"
        ><i data-feather="plus" class="mr-1"></i
        >{{ "Address.addNewAddress" | translate }}</a
      >
    </div>
    
    <ng-container *ngIf="billingAddressList">
      <div *ngFor="let addr of billingAddressList">
        <hr />
        <ng-container
          *ngTemplateOutlet="
            addressDetailRow;
            context: { addr: addr, addrType: addressType.Billing }
          "
        ></ng-container>
      </div>
    </ng-container>
  </div>
  <div class="col-md-6">
    <div class="d-flex justify-content-between">
      <h5 style="align-self: center">
        {{ "Address.mailingAddress" | translate }}
      </h5>
      <a
        (click)="openAddressModal(false, null, addressType.Shipping)"
        class="btn btn-primary"
        ><i data-feather="plus" class="mr-1"></i
        >{{ "Address.addNewAddress" | translate }}</a
      >
    </div>
    <ng-container *ngIf="shippingAddressList">
      <div *ngFor="let addr of shippingAddressList">
        <hr />
        <ng-container
          *ngTemplateOutlet="
            addressDetailRow;
            context: { addr: addr, addrType: addressType.Shipping }
          "
        ></ng-container>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #addressDetailRow let-addr="addr" let-addrType="addrType">
  <div class="row">
    <div class="col-8">
      <div class="custom-option-body">
        <h5
          class="custom-option-title custom-option-text"
          *ngIf="addr.isLegalAddr"
        >
          {{ addr.businessName }} |
          {{ addr.phone ? addr.phone : "-" }}
        </h5>
        <h5
          class="custom-option-title custom-option-text"
          *ngIf="!addr.isLegalAddr"
        >
          {{ addr.firstName }} {{ addr.lastName ? addr.lastName : "" }} |
          {{ addr.phone ? addr.phone : "-" }}
        </h5>
        <p class="card-text custom-option-text">
          {{ GetAddressTemplate(addr) }}
        </p>
        <div class="badge badge-primary" *ngIf="addr.isDefault">
          {{ "Address.isDefault" | translate }}
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="d-flex justify-content-end">
        <a
          (click)="openAddressModal(true, addr, addrType)"
          href="javascript: void(0)"
          >{{ "General.Edit" | translate }}</a
        >
        <a
          *ngIf="!addr.isDefault"
          (click)="openDeleteAddressModal(addr.id)"
          href="javascript: void(0)"
          class="ml-1"
          >{{ "General.Delete" | translate }}</a
        >
      </div>
      <div class="d-flex justify-content-end">
        <a
          (click)="setAsDefault(addr.id)"
          *ngIf="!addr.isDefault"
          class="btn btn-sm btn-dark mt-1"
          >{{ "Address.setAsDefault" | translate }}</a
        >
      </div>
    </div>
  </div>
</ng-template>
