import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  config: ICMSConfig = {
    apiPath: 'ProductLanding',
    tableName: 'Content',
    pathUrl: '/cms/product-landing',
    model: new Object(),
    isList: false,
    componentName: 'Product Landing',
    formConfig: {
      bannerProductList1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Banner 1 Image',
        description: 'Resolution 1920px*450px',
        labelTranslate: 'Informative.Product.BannerImage.1',
        descriptionTranslate: 'Informative.Product.Description',
      },
      bannerProductListDescription2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Banner 2 Title',
        description: 'Wording On Banner',
        labelTranslate: 'Informative.Product.BannerTitle.Label',
        descriptionTranslate: 'Informative.Product.BannerTitle.Description',
      },
      bannerProductList2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Banner 2 Image',
        description: 'Resolution 1920px*450px',
        labelTranslate: 'Informative.Product.BannerImage.2',
        descriptionTranslate: 'Informative.Product.Description',
      },
    },
  };
  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
