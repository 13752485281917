import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'app/auth/helpers';
import { CookiePolicyComponent } from './cookie-policy.component';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';

const path: string = 'cms/cookie-policy'
const routes: Routes = [
  {
    path: path,
    component: CookiePolicyComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cookie-policy' },
  },
];

@NgModule({
  declarations: [CookiePolicyComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CmsFormpageModule,
  ],
  exports: [CookiePolicyComponent],
})
export class CookiePolicyModule {}
