import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-role-badge',
  templateUrl: './role-badge.component.html',
  styleUrls: ['./role-badge.component.scss']
})
export class RoleBadgeComponent implements OnInit {

  @Input() roleSetName: string;

  constructor() { }

  ngOnInit(): void {
  }

}
