<!-- <block-ui></block-ui> -->

<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section>
      <app-horizontal-timeline
        [timelineItems]="timelineData"
        [currentStep]="0"
        [currentStepNumber]="0"
        [lastItems]="{
          label: 'Cancel',
          descript: 'ยกเลิก',
          stepCount: 5
        }"
      ></app-horizontal-timeline>
    </section>

    <section>
      <app-add-bill
        [data]="quotationObj"
        [apiPath]="apiPath"
        [docTitle]="'Document.PurchaseRequest'"
        [docCodeColumn]="'purchaseRequisitionCode'"
        (dataChange)="onDataChange($event)"
        (submitData)="handleAddBillSubmit($event)"
        [isPurchaseRequest]="true"
        [isBuyerDoc]="true"
        modalAcceptTitle="ยืนยันใบเสนอราคา"
        modalAcceptDetail="คุณแน่ใจที่จะยืนยันใบเสนอราคานี้หรือไม่?"
        modalDraftTitle="บันทึกร่างใบเสนอราคา"
        modalDraftDetail="คุณแน่ใจที่จะบันทึกร่างใบเสนอราคานี้หรือไม่?"
        modalCancelTitle="ยกเลิกการแก้ไขใบเสนอราคา"
        modalCancelDetail="คุณแน่ใจที่จะยกเลิกการแก้ไขใบเสนอราคานี้หรือไม่?"
        #addBillComponent
      ></app-add-bill>
    </section>
  </div>
</div>
