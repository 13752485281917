<div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
  <app-loading></app-loading>
</div>

<div *ngIf="!isLoading" class="w-100 mt-2 mb-3">
  <div>
    <h2 class="my-auto">
      {{ titleDoc }} {{ "ActionHistory.No" | translate }} #{{
        detailObj[typeDocName]
      }}
    </h2>
    <div class="d-flex justify-content-between">
      <div class="d-block mt-2">
        <app-document-status-badge
          [status]="detailObj.transactionStatusId"
          [isDelete]="detailObj.isDelete"
        ></app-document-status-badge>
      </div>
      <div class="d-block mt-2">
        <span>{{ "ContentDocument.Ref" | translate }}</span>
        <a
          [routerLink]="[refDetailUrl + '/' + detailObj[refDocID]]"
          target="_blank"
        >
          <span> {{ detailObj.references }} </span>
        </a>
      </div>
    </div>
  </div>
</div>

<ul class="timeline" *ngIf="paymentDetail">
  <li class="timeline-item">
    <span class="timeline-point">
      <i data-feather="dollar-sign"></i>
    </span>
    <div class="timeline-event">
      <div class="mt-2">
        <div class="card border">
          <div class="card-body py-0">
            <div class="detail-row row d-flex align-items-center">
              <div class="col-6 borderfix bordertop">
                <div class="d-flex justify-content-between pb-1">
                  <span
                    >{{ "Document.fullValue" | translate }} : ({{ titleDoc }})
                  </span>
                  <span
                    >{{
                      convertNumbertoMoneyFormat(
                        paymentDetail.preWHTAmountTotal
                      )
                    }}
                    {{ "DocFooter.Baht" | translate }}</span
                  >
                </div>
                <div
                  class="d-flex justify-content-between pb-1"
                  *ngIf="!isCreditNote && !isDebitNote && !isPurchaseOrder"
                >
                  <span
                    >{{ "Document.writtenOff" | translate }} ({{
                      creditNoteList.length
                    }})
                  </span>
                  <span
                    >{{
                      convertNumbertoMoneyFormat(
                        getSummary(creditNoteList, "preWHTAmountTotal")
                      )
                    }}
                    {{ "DocFooter.Baht" | translate }}</span
                  >
                </div>
                <div
                  class="d-flex justify-content-between pb-1"
                  *ngIf="isPurchaseOrder"
                >
                  <span
                    >{{ "Document.writtenOff" | translate }} ({{
                      goodsReceiveList.length
                    }})
                  </span>
                  <span
                    >{{
                      convertNumbertoMoneyFormat(
                        getSummary(goodsReceiveList, "preWHTAmountTotal")
                      )
                    }}
                    {{ "DocFooter.Baht" | translate }}</span
                  >
                </div>
                <strong
                  class="d-flex justify-content-between pb-1 text-success"
                >
                  <span
                    >{{
                      "Payment.TheTotalAmountOfReceivableDebt" | translate
                    }}
                    :</span
                  >
                  <span
                    *ngIf="!isCreditNote && !isDebitNote && !isPurchaseOrder"
                    >{{
                      convertNumbertoMoneyFormat(
                        paymentDetail.preWHTAmountTotal -
                          getSummary(creditNoteList, "preWHTAmountTotal")
                      )
                    }}
                    {{ "DocFooter.Baht" | translate }}</span
                  >
                  <span *ngIf="isDebitNote && !isCreditNote && !isPurchaseOrder"
                    >{{
                      convertNumbertoMoneyFormat(
                        paymentDetail.preWHTAmountTotal -
                          getSummary(debitNoteList, "preWHTAmountTotal")
                      )
                    }}
                    {{ "DocFooter.Baht" | translate }}</span
                  >
                  <span *ngIf="isCreditNote && !isDebitNote && !isPurchaseOrder"
                    >{{
                      convertNumbertoMoneyFormat(
                        paymentDetail.preWHTAmountTotal -
                          getSummary(creditNoteList, "preWHTAmountTotal")
                      )
                    }}
                    {{ "DocFooter.Baht" | translate }}</span
                  >
                  <span *ngIf="isPurchaseOrder && !isCreditNote && !isDebitNote"
                    >{{
                      convertNumbertoMoneyFormat(
                        paymentDetail.preWHTAmountTotal -
                          getSummary(goodsReceiveList, "preWHTAmountTotal")
                      )
                    }}
                    {{ "DocFooter.Baht" | translate }}</span
                  >
                </strong>
                <div class="d-flex justify-content-between">
                  <span
                    *ngIf="!isCreditNote && !isDebitNote && !isPurchaseOrder"
                    >{{ "Payment.Paid" | translate }} ({{ receiptList.length }})
                  </span>
                  <span
                    *ngIf="!isCreditNote && !isDebitNote && !isPurchaseOrder"
                    >{{
                      convertNumbertoMoneyFormat(
                        getSummary(receiptList, "preWHTAmountTotal")
                      )
                    }}
                    {{ "DocFooter.Baht" | translate }}</span
                  >

                  <span *ngIf="isDebitNote && !isCreditNote && !isPurchaseOrder"
                    >{{ "Payment.Paid" | translate }} {{ debitNoteList.length }}
                  </span>
                  <span *ngIf="isDebitNote && !isCreditNote && !isPurchaseOrder"
                    >{{
                      convertNumbertoMoneyFormat(
                        getSummary(debitNoteList, "preWHTAmountTotal")
                      )
                    }}
                    {{ "DocFooter.Baht" | translate }}</span
                  >

                  <span *ngIf="isCreditNote && !isDebitNote && !isPurchaseOrder"
                    >{{ "Payment.Paid" | translate }}
                    {{ creditNoteList.length }}
                  </span>
                  <span *ngIf="isCreditNote && !isDebitNote && !isPurchaseOrder"
                    >{{
                      convertNumbertoMoneyFormat(
                        getSummary(creditNoteList, "preWHTAmountTotal")
                      )
                    }}
                    {{ "DocFooter.Baht" | translate }}</span
                  >

                  <span *ngIf="!isCreditNote && !isDebitNote && isPurchaseOrder"
                    >{{ "Payment.Paid" | translate }}
                    {{ goodsReceiveList.length }}
                  </span>
                  <span *ngIf="!isCreditNote && !isDebitNote && isPurchaseOrder"
                    >{{
                      convertNumbertoMoneyFormat(
                        getSummary(goodsReceiveList, "preWHTAmountTotal")
                      )
                    }}
                    {{ "DocFooter.Baht" | translate }}</span
                  >
                </div>
              </div>

              <div
                class="col-6 d-flex align-items-center justify-content-center bordertop"
              >
                <div
                  *ngIf="
                    detailObj.transactionStatusId == documentStatus.Paid ||
                    detailObj.transactionStatusId == 10
                  "
                >
                  <h3 class="text-success">
                    <i data-feather="check" class="text-success" size="30"></i>
                    {{ "Payment.FullPaid" | translate }}
                  </h3>
                  <h5 class="d-flex justify-content-end">
                    <p>
                      {{ "Payment.getFullPaid" | translate }} :
                      {{ detailObj.paymentDate | date : "dd/MM/YYYY HH:mm" }}
                    </p>
                  </h5>
                </div>

                <div
                  *ngIf="
                    detailObj.transactionStatusId != 10 &&
                    (detailObj.transactionStatusId ==
                      documentStatus.AwaitPayment ||
                      detailObj.remainingPaymentAmount != 0) &&
                    detailObj.transactionStatusId != documentStatus.Paid
                  "
                >
                  <h3 class="text-warning d-flex justify-content-center">
                    <i data-feather="check" class="text-warning" size="30"></i>
                    {{ "Payment.stillPay" | translate }}
                  </h3>
                  <div
                    class="d-flex invert-grandTotal-box mb-0 align-items-center justify-content-center"
                    style="
                      justify-content: space-evenly;
                      flex-shrink: 0;
                      width: 300px;
                    "
                  >
                    <p class="mb-0 w-100 text-left">
                      <u
                        *ngIf="
                          apiPath != 'GoodsReceive' &&
                          apiPath != 'PurchaseOrder' &&
                          apiPath != 'PurchaseRequisition'
                        "
                        >{{ "General.ValueWaitToBePaid" | translate }}</u
                      >
                      <u
                        *ngIf="
                          apiPath == 'GoodsReceive' ||
                          apiPath == 'PurchaseOrder' ||
                          apiPath == 'PurchaseRequisition'
                        "
                        >{{ "General.ValueWaitToPaid" | translate }}</u
                      >
                    </p>

                    <p class="mb-0 w-100 text-right mt-1">
                      <span class="invert-grandTotal-price">{{
                        convertNumbertoMoneyFormat(
                          paymentDetail.remainingPaymentAmount
                        )
                      }}</span>

                      <span>
                        {{ "ContentDocument.Baht" | translate }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="background: #f0f0f0"
            class="card-footer d-flex py-1 align-items-center"
            [ngClass]="{
              'justify-content-end' : isPurchaseOrder || apiPath == 'GoodsReceive',
              'justify-content-between' : !isPurchaseOrder && apiPath != 'GoodsReceive'
            }"
          >
            <span *ngIf="!isPurchaseOrder && apiPath != 'GoodsReceive'">
              {{ "Payment.dueDate" | translate }} :
              {{ detailObj.documentExpireDate | date : "dd/MM/YYYY HH:mm" }}
            </span>
            <button
              *ngIf="!isPurchaseOrder"
              [disabled]="
                detailObj.transactionStatusId == 10 ||
                detailObj.transactionStatusId == documentStatus.Voided ||
                detailObj.transactionStatusId == documentStatus.Paid ||
                detailObj.transactionStatusId == documentStatus.Cancel ||
                isPaymentModalOpening
              "
              class="btn btn-primary"
              (click)="openPaymentModal(detailObj)"
            >
              <span
                *ngIf="
                  apiPath != 'GoodsReceive' && apiPath != 'PurchaseRequisition'
                "
                >{{ "General.ReceivePayment" | translate }}</span
              >
              <span
                *ngIf="
                  apiPath == 'GoodsReceive' || apiPath == 'PurchaseRequisition'
                "
                >{{ "General.Payment" | translate }}</span
              >
            </button>
            <!-- <button
              class="btn btn-success"
              (click)="openPaymentModal(detailObj)"
            >
              <span>รับชำระ</span>
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </li>
  <li
    class="timeline-item"
    *ngFor="let item of sortedItems; index as ind; count as length"
  >
    <span class="timeline-point timeline-point-secondary">
      <i data-feather="credit-card"></i>
    </span>
    <div class="timeline-event">
      <div class="rounded">
        <div class="card border">
          <div class="card-body py-0">
            <div class="detail-row row d-flex align-items-center">
              <div class="col-6 borderfix bordertop">
                <div>
                  <strong class="d-flex justify-content-between pb-1">
                    <span class="text-success"
                      >{{
                        (item.source == "receipt"
                          ? "Payment.reCPay"
                          : "Payment.cutOPay"
                        ) | translate
                      }}
                      {{ item.ordinal }}</span
                    >
                    <span *ngIf="currentLang != 'en'"
                      >{{ "Payment.getPaidOn" | translate }} :
                      {{ item.paymentDate | thaidate : "DD MMM YYYY HH:mm" }} น.</span
                    >
                    <span *ngIf="currentLang != 'th'"
                      >{{ "Payment.PaymentDate" | translate }} :
                      {{ item.paymentDate | date : "dd MMM YYYY HH:mm" }}</span
                    >
                  </strong>
                  <div class="d-flex justify-content-between pb-1">
                    <span
                      >{{
                        (item.source == "receipt"
                          ? "Payment.paymentMethod"
                          : "Payment.payWithDoc"
                        ) | translate
                      }}
                    </span>
                    <b></b>
                  </div>
                  <div class="d-flex justify-content-between pb-1">
                    <span>{{
                      "Payment." + item.paymentTypeName | translate
                    }}</span>
                    <span
                      >{{ convertNumbertoMoneyFormat(item.grandTotal) }}
                      {{ "DocFooter.Baht" | translate }}</span
                    >
                  </div>
                  <div
                    class="d-flex justify-content-between"
                    *ngIf="item.salesWHT > 0"
                  >
                    <span>{{
                      "ContentDocument.WithholdingTaxAmount" | translate
                    }}</span>
                    <span
                      >{{ convertNumbertoMoneyFormat(item.salesWHT) }}
                      {{ "DocFooter.Baht" | translate }}</span
                    >
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div>
                  <div class="d-flex flex-column align-items-center">
                    <div class="mb-1" *ngIf="!isPurchaseOrder">
                      <b
                        >{{
                          (item.source == "receipt"
                            ? "Document.Receipt"
                            : "Document.CreditNote"
                          ) | translate
                        }}:</b
                      >

                      &nbsp;
                      <a
                        [routerLink]="['/document/receipt/detail/' + item.id]"
                        target="_blank"
                        *ngIf="item.source == 'receipt'"
                        >{{ item.receiptCode }}</a
                      >
                      <a
                        [routerLink]="[
                          '/document/credit-note/detail/' + item.id
                        ]"
                        target="_blank"
                        *ngIf="item.source == 'creditNote'"
                        >{{ item.creditNoteCode }}</a
                      >
                    </div>

                    <div class="mb-1" *ngIf="isPurchaseOrder">
                      <b>{{ "Document.GoodsReceive" | translate }}:</b>

                      &nbsp;
                      <a
                        [routerLink]="[
                          '/document/goods-receive/detail/' + item.id
                        ]"
                        target="_blank"
                        >{{ item.goodsReceiveCode }}</a
                      >
                    </div>

                    <div
                      class="d-flex invert-grandTotal-box mb-0 align-items-center justify-content-center"
                      style="
                        justify-content: space-evenly;
                        flex-shrink: 0;
                        width: 300px;
                      "
                    >
                      <p class="mb-0 w-100 text-left">
                        <u>{{ "Payment.thisPrice" | translate }}</u>
                      </p>

                      <p class="mb-0 w-100 text-right mt-1">
                        <span class="invert-grandTotal-price">{{
                          convertNumbertoMoneyFormat(item.preWHTAmountTotal)
                        }}</span>

                        <span>
                          {{ "ContentDocument.Baht" | translate }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>
