<div class="card">
  <div class="card-body">
    <h3 class="mb-2 general-title">Movement</h3>
    <table class="table" [dtOptions]="dtOptions" datatable>
      <thead>
        <tr>
          <th width="3%" class="text-center">
            <div
              class="custom-control custom-checkbox custom-checkbox-datatable"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                [id]="'checkAllRecord'"
                (change)="checkAllRecord($event)"
              />
              <label
                class="custom-control-label"
                for="checkAllRecord"
              ></label>
            </div>
          </th>
          <th width="3%" class="text-center">#</th>
          <th>{{ 'Stock.Document' | translate }}</th>
          <th>{{ 'Stock.StockType' | translate }}</th>
          <th>{{ 'Stock.Type' | translate }}</th>
          <th class="text-center">{{ 'Stock.Quantity' | translate }}</th>
          <th class="text-center">{{ 'Stock.FG' | translate }}</th>
          <th class="text-center">{{ 'Stock.FGReserve' | translate }}</th>
          <th class="text-center">{{ 'Stock.FOC' | translate }}</th>
          <th class="text-center">{{ 'Stock.FOCReserve' | translate }}</th>
          <th class="text-center">{{ 'Stock.CenterIn' | translate }}</th>
          <th class="text-center">{{ 'Stock.CenterOut' | translate }}</th>
          <th class="text-center">{{ 'Stock.Balance' | translate }}</th>
          <th class="text-center">{{ 'Stock.Avaliable' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="w-100" *ngIf="isLoading">
          <td class="text-center" *ngFor="let num of [].constructor(14)">
            <div class="skeleton-loading"></div>
          </td>
        </tr>

        <tr *ngIf="itemMovementList.length < 1 && !isLoading">
          <td colspan="100%" class="text-center">
            <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
          </td>
        </tr>
        <tr *ngFor="let itemMovement of itemMovementList; let ind = index">
          <td class="text-center">
            <div
              class="custom-control custom-checkbox custom-checkbox-datatable"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                [id]="itemMovement.id"
                (change)="checkRecord($event, itemMovement.id)"
              />
              <label
                class="custom-control-label"
                for="{{ itemMovement.id }}"
              ></label>
            </div>
          </td>
          <td class="text-center">{{ (page - 1) * pageSize + ind + 1 }}</td>
          <td>
            <a
              (click)="
                routerToDocument(
                  itemMovement.documentTypeEnum,
                  itemMovement.documentId
                )
              "
              class="text-primary"
              >{{
                itemMovement.documentRef ? itemMovement.documentRef : "-"
              }}</a
            >
          </td>
          <td>{{ 'Stock.'+itemMovement.stockTypeName | translate }}</td>
          <td>{{ 'Stock.'+itemMovement.typeName | translate }}</td>
          <td
            class="text-center"
            [ngClass]="{
              'text-success': itemMovement.transferQuantity > 0,
              'text-danger': itemMovement.transferQuantity < 0
            }"
          >
            {{ itemMovement.transferQuantity | number }}
          </td>
          <td class="text-center">{{ itemMovement.fgQuantity | number }}</td>
          <td class="text-center">
            {{ itemMovement.fgReserveQuantity | number }}
          </td>
          <td class="text-center">{{ itemMovement.focQuantity | number }}</td>
          <td class="text-center">
            {{ itemMovement.focReserveQuantity | number }}
          </td>
          <td class="text-center">
            {{ itemMovement.centerInQuantity | number }}
          </td>
          <td class="text-center">
            {{ itemMovement.centerOutQuantity | number }}
          </td>
          <td class="text-center">
            {{ itemMovement.balanceQuantity | number }}
          </td>
          <td class="text-center">
            {{ itemMovement.fgAvailableQuantity | number }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
