<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="home-page">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <a (click)="backClicked()" class="text-primary">
                <i
                  data-feather="arrow-left"
                  class="text-primary cursor-pointer back-to-manage-btn"
                ></i>
                {{ "General.Manage" | translate }} {{ componentName | translate }}
              </a>
              <h4 class="card-title">
                {{ (isEditing ? "General.Edit" : "General.Add") | translate }} {{ componentName | translate }}
              </h4>
            </div>
            <div class="card-body pb-1">
              <app-customer-form></app-customer-form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
