import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ItemSetService {
  componentName: string = 'Item';
  apiUrl: string = 'item';
  pathUrl: string = '/item-manage/set';

  constructor() { }
}
