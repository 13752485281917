import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ApiService } from 'app/main/service/api.service';

@Component({
  selector: 'app-search-panel-frontend-user',
  templateUrl: './search-panel-frontend-user.component.html',
  styleUrls: ['./search-panel-frontend-user.component.scss'],
})
export class SearchPanelFrontendUserComponent implements OnInit {
  @Input() pathUrl: string;
  state: any;
  @Input() statusList: any[] = [];

  roleSetName: any = 'All';
  roleSetList: any[] = [];

  @Output('SetLoading') setLoadingFunc: EventEmitter<any> = new EventEmitter();
  @Output('callDataFromAPI') callDataFromAPIFunc: EventEmitter<any> =
    new EventEmitter();
  @ViewChild('searchname') searchBar: ElementRef;
  @ViewChild('activeFil') activeFil: ElementRef;
  @ViewChild('selectRoleSet') selectRoleSet: ElementRef;

  constructor(private _apiService: ApiService) {
    this.getRoleSet();
  }

  ngOnInit(): void {}

  ResetSearch(): void {
    this.setLoadingFunc.emit();

    //inputsearch reset
    this.searchBar.nativeElement.value = '';

    this.callDataFromAPIFunc.emit(this.state);
  }

  Submit(): void {
    let params: any = {};
    if (this.roleSetName != 'All') {
      params.roleSetName = this.roleSetName;
    }

    const activeStr = this.activeFil.nativeElement.value;
    if (activeStr === 'active') {
      params.isActive = true;
    } else if (activeStr === 'inactive') {
      params.isActive = false;
    }

    let obj = {
      searchText: this.searchBar.nativeElement.value
        ? this.searchBar.nativeElement.value
        : '',
      params: params,
    };
    console.log(obj);
    this.callDataFromAPIFunc.emit(obj);
  }

  getRoleSet(): void {
    this._apiService.GetAllData('roleSet').subscribe((res) => {
      this.roleSetList = res.data.resultData;
    });
  }
}
