export class Rank {
  id: number;
  name: string;
  label?: string;
  code: string;
  isActive: boolean;
  isDelete: boolean;
  createBy: string;
  updateBy: string;
  deleteBy: string;
}

export enum RankEnum {
  Provider = 1,
  ActiveProvider = 2,
  MasterProvider = 3,
}

export const RankList: Rank[] = [
  {
    id: 1,
    name: 'Provider',
    label: 'P',
    code: '7c',
    isActive: true,
    isDelete: false,
    createBy: '',
    updateBy: '',
    deleteBy: null,
  },
  {
    id: 2,
    name: 'Active Provider',
    label: 'AP',
    code: 'cc1c8987',
    isActive: true,
    isDelete: false,
    createBy: '',
    updateBy: '',
    deleteBy: null,
  },
  {
    id: 3,
    name: 'Master Provider',
    label: 'MP',
    code: '90e2fa2',
    isActive: true,
    isDelete: false,
    createBy: '',
    updateBy: '',
    deleteBy: null,
  },
];
