import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../../../auth/models';
import { AddBillComponent } from '../../../../components/document/template/add-bill/add-bill.component';
import { TimelineItem } from '../../../../components/horizontal-timeline/horizontal-timeline.component';
import { ModalComponent } from '../../../../components/modal/modal.component';
import Customer from '../../../../model/Customer';
import CustomerAddress from '../../../../model/CustomerAddress';
import Item from '../../../../model/Item';
import { Quotation, TempRowQuotation } from '../../../../model/Quotation';
import { ApiService } from '../../../../service/api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DragulaService } from 'ng2-dragula';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Observable, Subject } from 'rxjs';
import { DocumentService } from '../../document.service';
import { DocumentApiService } from '../../../../service/document-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../../../../auth/service';
import { ComponentsService } from '../../../../components/components.service';
import { QuatationService } from '../../quotation/quotation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CalculateFuncService } from '../../../../service/calculate-func.service';
import { GlobalFuncService } from '../../../../service/global-func.service';
import { DocumentStatus } from 'app/main/model/DocumentStatus';

@Component({
  selector: 'app-purchase-request-formpage',
  templateUrl: './purchase-request-formpage.component.html',
  styleUrls: ['./purchase-request-formpage.component.scss'],
})
export class PurchaseRequestFormpageComponent implements OnInit {
  @ViewChild('confirmModal') confirmModal: ModalComponent;
  @ViewChild('addBillComponent', { static: false })
  addBillComponent: AddBillComponent;
  @ViewChildren('formTable') formTable: QueryList<ElementRef<HTMLLIElement>>;
  private _unsubscribeAll: Subject<any> = new Subject();
  quotationData: any;

  contentHeader: object;
  currentUser: User;
  apiPath: string;
  docID: string;
  docCode: string;
  isSubmit: boolean;
  quotationForm: FormGroup;
  quotationObj: any;

  copyObj: any;

  orderId: string;
  orderStatus: any = [];
  currentStep: number;

  taxRate: number = 7.0;
  whtRate: number = 0;
  componentName: String;
  itemCounter: number = 0;
  rows: TempRowQuotation[] = [];
  draggingRow: any;
  currentDate: Date = new Date();
  nextWeekDate: Date = new Date();
  customerList: Customer[] = [];
  customerObj: Customer;
  customerAddressObj: CustomerAddress;
  itemList: Item[] = [];
  isLoading: boolean;
  isEditing: boolean;
  isVatExempted: boolean;
  isSetValueWhenInit: boolean = false;
  isExcludeTax: boolean;
  isSameWHTAllRow: boolean;
  isDiscountByPercent: boolean;

  detailPOPath: string;

  whtList: any[] = [
    // { value: 0, label: 'ยังไม่ระบุ' },
    { value: 0, label: 'ไม่มี' },
    { value: 0.75, label: '0.75%' },
    { value: 1, label: '1%' },
    { value: 1.5, label: '1.5%' },
    { value: 2, label: '2%' },
    { value: 3, label: '3%' },
    { value: 5, label: '5%' },
    { value: 10, label: '10%' },
    { value: 15, label: '15%' },
    // { value: 0, label: 'กำหนดเอง' },
  ];

  @BlockUI() blockUI: NgBlockUI;

  timelineData: TimelineItem[];

  constructor(
    private _apiService: ApiService,
    private _documentService: DocumentService,
    private _documentApiService: DocumentApiService,
    private _authenticationService: AuthenticationService,
    private _componentsService: ComponentsService,
    private _contentTemplateService: DocumentService,
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _router: Router,

    private _globalService: GlobalFuncService
  ) {
    this.componentName = 'Item';
    this._unsubscribeAll = new Subject();
    this.componentName = this._contentTemplateService.purchaseRequest.ApiPath;
    this.timelineData = this._documentService.timelineBuyData;
    this.detailPOPath = this._documentService.purchaseOrder.DetailPathURL;
    this.initData();
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }
  SetLoadedState(): void {
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.isDiscountByPercent = false;
    var self = this;
    this._authenticationService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    self.contentHeader = {
      headerTitle: 'Document.PurchaseRequest',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Document',
            isLink: false,
            link: '/',
          },
          {
            name: 'Document.PurchaseRequest',
            isLink: true,
            link: '/document/purchase-request/view',
          },
          {
            name: 'FormName.PurchaseRequestForm',
            isLink: false,
          },
        ],
      },
    };
  }
  childFunction() {
    console.log('Child function called from the parent component');
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  initData() {
    this.SetLoadingState();
    this.apiPath = this._documentService.purchaseRequest.ApiPath;

    this.getitemList();

    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
      this.copyObj = this._route.snapshot.paramMap.get('copyObj');
      if (this.copyObj) {
        this.isEditing = true;
        this.getLastCode();
      } else {
        this.isEditing = this.docID ? true : false;
      }
    });

    

    this.quotationObj = new Quotation();
    this.quotationObj.transactionStatusId = 0;
    this.quotationObj.salesWHT = 0.0;
    this.currentStep = 0;

    if (this.isEditing) {
      console.log(this.quotationObj);
      if (this.copyObj) {
        this.getLastCode().then(() => {
          setTimeout(() => {
            let tempObj = JSON.parse(this.copyObj);
            this.quotationObj = tempObj;
            this.isExcludeTax = tempObj.isExcludeTax;
            this.quotationObj.purchaseRequisitionCode = this.docCode;
            this.quotationObj.transactionStatusId = DocumentStatus.Draft
            console.log(this.quotationObj);
          }, 500);
        });
      } else {
        this.getQuatationData(this.apiPath, this.docID);
      }
    } else {
      this.getLastCode();
      this.customerObj = new Customer();
      this.quotationData = {};
      this.SetLoadedState();
    }
  }

  get f() {
    return this.quotationForm.controls;
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData[0];
        });
    });
  }

  getitemList(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('item', { isDelete: false })
        .subscribe((res) => {
          self.itemList = res.data.resultData;

          this.quotationData = {
            itemList: res.data.resultData,
          };
        });
    });
  }

  getLastCode(): Promise<void> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocRunningNumber('PurchaseRequisition')
        .subscribe((res) => {
          let docCode = document.getElementById('docCode') as HTMLFormElement;
          self.docCode = res.data.resultData[0];
          docCode.value = self.docCode;
          resolve();
        });
    });
  }

  getQuatationData(ApiPath, DocID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._documentApiService.GetDocData(ApiPath, DocID).subscribe(
        (res) => {
          const quotationObj = res.data.resultData[0];
          this.quotationObj = quotationObj;
          this.isExcludeTax = quotationObj.isExcludeTax;

          this.orderId = quotationObj.orderId;
          this.getOrderStatus(this.orderId).subscribe((orderStatusRes) => {
            this.orderStatus = orderStatusRes.data.resultData;
            console.log('Order Status: ', this.orderStatus[0]);
            this.currentStep = this._globalService.GetCurrentStep(
              this.orderStatus
            );
            console.log('Current Step: ', this.currentStep);
          });
          // resolve(quotationObj);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getOrderStatus(orderId: string): Observable<any> {
    return this._apiService.GetDataById('BuyOrder/State', orderId);
  }

  onDataChange(event: any) {
    // Handle the updated data from the add-bill component
    console.log('Event Channged', event);
    // Other logic
  }
  handleAddBillSubmit(data: any): void {
    // Handle the submitted data from the AddBillComponent
    console.log('Submitted data from AddBillComponent:', data);
    // Perform any necessary actions with the submitted data
    if (this.copyObj) {
      this.Submit(data.isDraft, data.quotationForm, false);
    } else {
      this.Submit(data.isDraft, data.quotationForm, data.isEditing, data.docID);
    }
  }

  Submit(
    isDraft?: boolean,
    quotationForm?: object,
    isEditing?: boolean,
    docID?: string
  ) {
    this.blockUI.start();

    var self = this;
    if (isEditing) {
      this._documentApiService
        .UpdateDocData(this.apiPath, quotationForm, docID)
        .subscribe(
          (res) => {
            console.log(res);
            let prCode = res.data.resultData[0].code;

            if (!isDraft) {
              this._documentApiService
                .ApproveDocData(this.apiPath, docID)
                .subscribe((res) => {
                  if (res) {
                    console.log(res);
                    let poId = res.data.resultData[0].id;

                    const titleLinkDocButton =
                      this._translateService.instant('Alert.GoToPO');
                    const textCode = this._translateService.instant(
                      'Alert.CodePR',
                      {
                        docCode: prCode,
                      }
                    );

                    self._componentsService.SuccessSwal(
                      undefined,
                      undefined,
                      true,
                      titleLinkDocButton,
                      this.detailPOPath,
                      textCode,
                      poId
                    );
                    this._router.navigate([
                      `/document/purchase-request/detail/${docID}`,
                    ]);
                  }
                });
            } else {
              self._componentsService.SuccessSwal();
              self._router.navigate([`/document/purchase-request/view`]);
            }
            this.blockUI.stop();
          },
          (err) => {
            this.blockUI.stop();
            self._componentsService.ErrorSwal();
            self._router.navigate([`/document/purchase-request/view`]);
          }
        );
    } else {
      this._documentApiService
        .AddDocData(this.apiPath, quotationForm)
        .subscribe(
          (res) => {
            console.log(res);

            let qoGuid = res.data.resultData[0].id;
            let prCode = res.data.resultData[0].code;

            if (!isDraft) {
              console.log('is approve add');
              this._documentApiService
                .ApproveDocData(this.apiPath, res.data.resultData[0].id)
                .subscribe((res) => {
                  if (res) {
                    console.log(res);
                    let poId = res.data.resultData[0].id;

                    const titleLinkDocButton =
                      this._translateService.instant('Alert.GoToPO');
                    const textCode = this._translateService.instant(
                      'Alert.CodePR',
                      {
                        docCode: prCode,
                      }
                    );

                    self._componentsService.SuccessSwal(
                      undefined,
                      undefined,
                      true,
                      titleLinkDocButton,
                      this.detailPOPath,
                      textCode,
                      poId
                    );
                    this._router.navigate([
                      `/document/purchase-request/detail/${qoGuid}`,
                    ]);
                  }
                });
            } else {
              self._componentsService.SuccessSwal();
              self._router.navigate([`/document/purchase-request/view`]);
            }
          },
          (err) => {
            self._componentsService.ErrorSwal();
            self._router.navigate([`/document/purchase-request/view`]);
          }
        );
      this.blockUI.stop();
    }
  }
}
