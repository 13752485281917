<div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">
        Rank Manage
    </h5>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">
            <i data-feather="x" size="22"></i>
        </span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>

    <form [formGroup]="formGroup">
        <div class="row">
            <div class="col-12" style="margin-bottom: 0.5rem">
                {{ 'ContentDocument.Customer' | translate }}: {{ customer.detail ? customer.detail :
                customer.customerName }}
            </div>

            <div class="col-12" style="margin-bottom: 0.5rem">
                <div class="row">
                    <div class="col">
                        <label class="form-label" for="name">{{ 'Stock.From' | translate}}</label>
                        <div style="padding-top: 0.5rem;">{{ customer.rankName }}</div>
                    </div>
                    <div class="col">
                        <label class="form-label" for="name">{{ 'Stock.To' | translate}}</label>
                        <span class="text-danger">&nbsp;*</span>
                        <div class="w-100">
                            <select class="custom-select" formControlName="selectedRank" [ngClass]="{
                                'is-invalid error':
                                  isSubmit && f.selectedRank.value == 0
                              }">
                                <option [ngValue]="0" selected>-- โปรดระบุ --</option>
                                <option [disabled]="customer.rankId == rankEnum.Provider" [ngValue]="rankEnum.Provider">
                                    {{ this.rankList[rankEnum.Provider - 1].name }}</option>
                                <option [disabled]="customer.rankId == rankEnum.ActiveProvider"
                                    [ngValue]="rankEnum.ActiveProvider">{{ this.rankList[rankEnum.ActiveProvider -
                                    1].name }}</option>
                                <option [disabled]="customer.rankId == rankEnum.MasterProvider"
                                    [ngValue]="rankEnum.MasterProvider">{{ this.rankList[rankEnum.MasterProvider -
                                    1].name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <label class="col-form-label"> เปลี่ยนระดับเมื่อ </label>
                <div class="d-flex align-items-center">
                    <div class="custom-control custom-radio mr-2">
                        <input type="radio" id="modeType" name="modeType" class="custom-control-input" [value]="0"
                            formControlName="modeType" checked />
                        <label class="custom-control-label" for="modeType"> จบ Period </label>
                    </div>
                    <div class="custom-control custom-radio mr-2">
                        <input type="radio" id="modeType2" name="modeType" class="custom-control-input" [value]="1"
                            formControlName="modeType" />
                        <label class="custom-control-label" for="modeType2"> ทันที </label>
                    </div>
                </div>
            </div>
        </div>
    </form>
    
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-secondary m-0" (click)="closeModal()" rippleEffect>
        {{ "Form.Cancel" | translate }}
    </button>
    <a type="button" class="btn btn-primary m-0" (click)="Submit()" rippleEffect>
        {{ "Form.Submit" | translate }}
    </a>
</div>