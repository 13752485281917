<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h3 class="mb-2 general-title">
          {{ itemObj.itemCode }} - {{ itemObj.name }}
        </h3>

        <div class="row">
          <div class="col-9">
            <div class="d-flex justify-content-between mb-2">
              <div class="col-12 col-md-3 d-flex align-items-center justify-content-center mb-2 mb-md-0 p-0">
                <div class="d-flex align-items-center justify-content-center">
                  <img [src]="
                      getItemCoverImage(
                        itemObj.mediaFiles[
                          this.filesPropName.item.coverImage
                        ][0].id
                      )
                    " style="border-radius: 10px" class="img-fluid product-img" alt="product image" />
                </div>
              </div>

              <div class="w-100 px-2 align-self-center">
                <div class="row">
                  <div class="col-6">
                    <div class="detail-label">รหัสสินค้า :</div>
                    <h4 class="detail-value">{{ itemObj.itemCode }}</h4>

                    <div class="detail-label">ชื่อสินค้า :</div>
                    <h4 class="detail-value">{{ itemObj.name }}</h4>

                    <div class="detail-label">
                      {{ "Form.SellingPrice" | translate }} :
                    </div>
                    <h4 class="detail-value">
                      {{ itemObj.sellingPrice | number : "1.2-2" }}
                      {{ "DocFooter.Baht" | translate }}
                    </h4>

                    <div class="detail-label">
                      {{ "Form.purchasePrice" | translate }} :
                    </div>
                    <h4 class="detail-value">
                      {{ itemObj.purchasePrice | number : "1.2-2" }}
                      {{ "DocFooter.Baht" | translate }}
                    </h4>
                  </div>

                  <div class="col-6">
                    <div class="detail-label">
                      {{ "General.Group" | translate }} :
                    </div>
                    <h4 class="detail-value">
                      {{ itemObj.groupName ? itemObj.groupName : "-" }}
                    </h4>

                    <div class="detail-label">
                      {{ "General.Category" | translate }} :
                    </div>
                    <h4 class="detail-value">
                      {{ itemObj.categoryName ? itemObj.categoryName : "-" }}
                    </h4>

                    <div class="detail-label">
                      {{ "General.SubCategory" | translate }} :
                    </div>
                    <h4 class="detail-value">
                      {{
                      itemObj.subCategoryName ? itemObj.subCategoryName : "-"
                      }}
                    </h4>

                    <ng-container *ngIf="itemObj.className">
                      <div class="detail-label">
                        {{ "General.Class" | translate }} :
                      </div>
                      <h4 class="detail-value">
                        {{ itemObj.className ? itemObj.className : "-" }}
                      </h4>
                    </ng-container>

                    <ng-container *ngIf="itemObj.subClassName">
                      <div class="detail-label">
                        {{ "General.SubClass" | translate }} :
                      </div>
                      <h4 class="detail-value">
                        {{ itemObj.subClassName ? itemObj.subClassName : "-" }}
                      </h4>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <a class="btn btn-primary mb-1 w-100" (click)="openAdjustModal()">
              {{ "Stock.AdjustStock" | translate }}
            </a>
            <br />
            <a class="btn btn-primary mb-1 w-100" (click)="openAdjustSetModal()" *ngIf="itemObj.isItemSet">
              {{ "Stock.AdjustSetStock" | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="row">
      <div class="col">
        <ng-container *ngTemplateOutlet="
            lastStockCard;
            context: {
              title: 'FGAvailable',
              data: lastItemMovementObj.fgAvailableQuantity
            }
          "></ng-container>
      </div>

      <div class="col">
        <ng-container *ngTemplateOutlet="
            lastStockCard;
            context: {
              title: 'FOCAvailable',
              data: lastItemMovementObj.focAvailableQuantity
            }
          "></ng-container>
      </div>

      <div class="col">
        <ng-container *ngTemplateOutlet="
            lastStockCard;
            context: {
              title: 'Balance',
              data: lastItemMovementObj.balanceQuantity
            }
          "></ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ng-container *ngTemplateOutlet="
            lastStockCard;
            context: { title: 'FG', data: lastItemMovementObj.fgQuantity }
          "></ng-container>
      </div>

      <div class="col">
        <ng-container *ngTemplateOutlet="
            lastStockCard;
            context: {
              title: 'FGReserve',
              data: lastItemMovementObj.fgReserveQuantity
            }
          "></ng-container>
      </div>

      <div class="col">
        <ng-container *ngTemplateOutlet="
            lastStockCard;
            context: { title: 'FOC', data: lastItemMovementObj.focQuantity }
          "></ng-container>
      </div>

      <div class="col">
        <ng-container *ngTemplateOutlet="
            lastStockCard;
            context: {
              title: 'FOCReserve',
              data: lastItemMovementObj.focReserveQuantity
            }
          "></ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ng-container *ngTemplateOutlet="
            lastStockCard;
            context: { title: 'Claim', data: lastItemMovementObj.claimQuantity }
          "></ng-container>
      </div>
      <div class="col">
        <ng-container *ngTemplateOutlet="
            lastStockCard;
            context: { title: 'Clear', data: lastItemMovementObj.clearQuantity }
          "></ng-container>
      </div>
      <div class="col">
        <ng-container *ngTemplateOutlet="
            lastStockCard;
            context: {
              title: 'CenterIn',
              data: lastItemMovementObj.centerInQuantity
            }
          "></ng-container>
      </div>
      <div class="col">
        <ng-container *ngTemplateOutlet="
            lastStockCard;
            context: {
              title: 'CenterOut',
              data: lastItemMovementObj.centerOutQuantity
            }
          "></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #lastStockCard let-title="title" let-data="data">
  <div class="card card-body last-stock-card">
    <p class="stock-topic">{{ "Stock." + title | translate }}</p>
    <p class="stock-detail text-end mb-0 mt-2">
      {{ data ? (data | number) : 0 }}
    </p>
  </div>
</ng-template>