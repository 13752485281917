import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdpaModule } from './pdpa/pdpa.module';
import { NewsModule } from './news/news.module';
import { BannerModule } from './banner/banner.module';
import { CoreCommonModule } from '@core/common.module';
import { ComponentsModule } from 'app/main/components/components.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { EventModule } from './event/event.module';
import { HomeModule } from './home/home.module';
import { TermsConditionModule } from './terms-condition/terms-condition.module';
import { CookiePolicyModule } from './cookie-policy/cookie-policy.module';
import { ContactModule } from './contact/contact.module';
import { AboutModule } from './about/about.module';
import { SignInModule } from './sign-in/sign-in.module';
import { ProductListModule } from './product-list/product-list.module';
import { ShoppingCartModule } from './shopping-cart/shopping-cart.module';
import { TranslateModule } from '@ngx-translate/core';
import { OurTechnologyModule } from './our-technology/our-technology.module';
import { NewsLandingModule } from './news-landing/news-landing.module';
import { SellerManageRegulationModule } from './seller-manage-regulation/seller-manage-regulation.module';
import { ConsumerReviewModule } from './consumer-review/consumer-review.module';

import { CompanyVideoModule } from './company-video/company-video.module';
import { CmsFormpageModule } from './cms-formpage/cms-formpage.module';
import { BonusPlanComponent } from './bonus-plan/bonus-plan.component';
import { BonusPlanModule } from './bonus-plan/bonus-plan.module';
import { FaqModule} from './faq/faq.module';
import { CategoriesModule } from './categories/categories.module';
import { LandingPageModule } from './repove-tools/landing-page/landing-page.module';
import { TimelineModule } from './repove-tools/our-company/timeline/timeline.module';
import { DetailModule } from './repove-tools/our-company/detail/detail.module';
import { BranchModule } from './repove-tools/our-company/branch/branch.module';
import { CompanyCardModule } from './repove-tools/our-company/company-card/company-card.module';
import { HydropartisomeModule } from './repove-tools/technology/hydropartisome/hydropartisome.module';
import { HydroextractionModule } from './repove-tools/technology/hydroextraction/hydroextraction.module';
import { PartibiomeModule } from './repove-tools/technology/partibiome/partibiome.module';
import { RepoveToolsModule } from './repove-tools/repove-tools.module';
import { HighlightTechModule } from './repove-tools/technology/hydroethosome/highlight-tech/highlight-tech.module';
import { TechProductLandingPageModule } from './repove-tools/tech-product-landing-page/tech-product-landing-page.module';
import { ProductDetailModule } from './product-detail/product-detail.module';





@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    CmsFormpageModule,
    CoreCommonModule,
    ComponentsModule,
    TranslateModule,
    PdpaModule,
    NewsModule,
    NewsLandingModule,
    BannerModule,
    ContentHeaderModule,
    EventModule,
    HomeModule,
    TermsConditionModule,
    CookiePolicyModule,
    ContactModule,
    AboutModule,
    SignInModule,
    ProductListModule,
    ShoppingCartModule,
    OurTechnologyModule,
    SellerManageRegulationModule,
    ConsumerReviewModule,
    CompanyVideoModule,
    BonusPlanModule,
    FaqModule,
    CategoriesModule,
    LandingPageModule,
    TimelineModule,
    DetailModule,
    BranchModule,
    CompanyCardModule,
    HydropartisomeModule,
    HydroextractionModule,
    PartibiomeModule,
    RepoveToolsModule,
    HighlightTechModule,
    TechProductLandingPageModule,
    ProductDetailModule,
  ],
  exports: [],
})
export class CmsModule {}
