import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubclassComponent } from './subclass.component';
import { SubclassCreateComponent } from './subclass-create/subclass-create.component';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ContentHeaderModule } from '../../../../layout/components/content-header/content-header.module';
import { ComponentsModule } from '../../../components/components.module';
import { AuthGuard } from '../../../../auth/helpers';
import { CoreCommonModule } from '@core/common.module';
import { environment } from 'environments/environment';

const routes: Routes = !environment.menuList.itemManage ? [] : [
  {
    path: 'item-manage/subclass',
    component: SubclassComponent,
    canActivate: [AuthGuard],
    data: { animation: 'subclass-template-manage' },
  },
  {
    path: 'item-manage/subclass/create',
    component: SubclassCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'subclass-template-create' },
  },
  {
    path: 'item-manage/subclass/edit/:id',
    component: SubclassCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'subclass-template-edit' },
  },
];

@NgModule({
  declarations: [
    SubclassComponent,
    SubclassCreateComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CoreCommonModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    ContentHeaderModule,
    ComponentsModule,
  ],
  exports: [
    SubclassComponent,
    SubclassCreateComponent,
  ],
})
export class SubclassModule {}
