<section id="knowledge-base-search">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="col-2 px-0 d-flex justify-content-start">
            <a [routerLink]="[pathUrl]" class="btn btn-primary">
              <i data-feather="plus" class="mr-1"></i
              >{{ "General.Create" | translate }}
            </a>
          </div>
        </div>

        <div class="card-body">
          <div class="row mb-1">
            <div class="col-12 col-sm-12 col-md-4">
              <div class="scope-custom">
                <label for="first-name-vertical">
                  {{ "ViewTable.UserName" | translate }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  #searchname
                  name="searchname"
                  placeholder="{{ 'ViewTable.UserName' | translate }}"
                />
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-4">
              <div class="scope-custom">
                <label for="first-name-vertical">{{
                  "General.RoleSet" | translate
                }}</label>
                <select
                  class="custom-select"
                  [(ngModel)]="roleSetName"
                  #selectRoleSet
                >
                  <option value="All" selected>
                    {{ "General.All" | translate }}
                  </option>
                  <option
                    [value]="roleSet.roleName"
                    *ngFor="let roleSet of roleSetList"
                  >
                    {{ roleSet.roleName }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-4">
              <div class="scope-custom">
                <label for="first-name-vertical">{{
                  "General.Status" | translate
                }}</label>
                <select name="sorting" class="custom-select" #activeFil>
                  <option value="all">{{ "General.All" | translate }}</option>
                  <option value="active">
                    {{ "Status.Active" | translate }}
                  </option>
                  <option value="inactive">
                    {{ "Status.Inactive" | translate }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="col-12 px-0 d-flex justify-content-between">
            <!-- <div class="col-3 d-flex justify-content-start my-auto">
              </div> -->
            <div class="col-12 px-0 d-flex justify-content-center">
              <button
                (click)="ResetSearch()"
                class="btn btm-sm btn-secondary"
                style="margin-right: 0.5rem"
              >
                <i data-feather="refresh-ccw" class="mr-1"></i
                >{{ "General.Reset" | translate }}
              </button>

              <button
                (click)="Submit()"
                class="btn btn-primary"
                style="margin-left: 0.5rem"
              >
                <i data-feather="search" class="mr-1"></i
                >{{ "General.Search" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
