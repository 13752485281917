<section>
  <div class="d-flex justify-content-end">
    <button class="btn btn-primary" (click)="openEKYCFormModal()">
      {{ 'EKYC.EditData' | translate }}
    </button>
  </div>

  <hr>

  <div class="d-flex">
    <div class="col" style="border-right: 1px solid #ebe9f1">
      <ng-container *ngTemplateOutlet="
          imagePreview;
          context: {
            title: 'EKYC.PIDImage',
            tempimage: pidImage,
            originalImage: pidImage,
            defaultImage: '../../../../assets/images/general/kyc-1.png',
            fileEnum: 'pidImage',
            isInvalid: isPidImageInvalid,
            fileStateEnum: EKYCDetail ? EKYCDetail.verifyIdCardImageStatusEnum : stateEnum.None
          }
        "></ng-container>
    </div>
    <div class="col">
      <ng-container *ngTemplateOutlet="
          imagePreview;
          context: {
            title: 'EKYC.PIDWithPersonImage',
            tempimage: pidWithPersonImage,
            defaultImage: '../../../../assets/images/general/kyc-2.png',
            originalImage: pidWithPersonImage,
            fileEnum: 'pidWithPersonImage',
            isInvalid: isPidWithPersonImageInvalid,
            fileStateEnum: EKYCDetail ? EKYCDetail.verifyPersonalWithIdCardImageStatusEnum : stateEnum.None
          }
        "></ng-container>
    </div>
  </div>

  <hr />

  <div class="d-flex justify-content-between">
    <div class="col">
      <!-- <div class="my-auto text-primary">{{ 'EKYC.Bookbank' | translate }}</div> -->
    </div>
  </div>
  <div class="d-flex">
    <div class="col">
      <ng-container *ngTemplateOutlet="
          imagePreview;
          context: {
            title: 'EKYC.Bookbank',
            tempimage: bookBankImage,
            defaultImage: '../../../../assets/images/general/kyc-3.png',
            originalImage: bookBankImage,
            fileEnum: 'bookBankImage',
            fileStateEnum: EKYCDetail ? EKYCDetail.verifyBookbankImageStatusEnum : stateEnum.None
          }
        "></ng-container>
    </div>

    <div class="col px-1">
      <div class="d-flex justify-content-between">
        <div class="my-auto text-primary">{{
          "Payment.Modal.BankAccount.Bank.Label" | translate
          }}</div>

        <div class="d-flex align-items-center">
          <p class="mr-1 mb-0">{{'General.Status' | translate}}:&nbsp;</p>
          <select #statusB class="custom-select" (change)="onBankStatusChange($event.target.value)"
            [(ngModel)]="EKYCDetail.verifyBookbankDetailStatusEnum" [ngClass]="statusB.value == stateEnum.WaitForVerify ? 'text-warning' : statusB.value == stateEnum.Verified ? 'text-success' : 'text-danger'">
            <option [value]="stateEnum.None" disabled class="text-dark">
              {{getStatusLabel(stateEnum.None)}}
            </option>
            <option [value]="stateEnum.WaitForVerify"
              [disabled]="EKYCDetail.verifyBookbankDetailStatusEnum == stateEnum.WaitForVerify" class="text-dark">
              {{getStatusLabel( stateEnum.WaitForVerify)}}
            </option>
            <option [value]="stateEnum.Verified"
              [disabled]="EKYCDetail.verifyBookbankDetailStatusEnum == stateEnum.Verified" class="text-dark">
              {{getStatusLabel(stateEnum.Verified)}}
            </option>
            <option [value]="stateEnum.Rejected"
              [disabled]="EKYCDetail.verifyBookbankDetailStatusEnum == stateEnum.Rejected" class="text-dark">
              {{getStatusLabel(stateEnum.Rejected)}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12" style="margin-bottom: 0.5rem">
        <label class="form-label text-primary" for="bankId">{{
          "Payment.Modal.BankAccount.Bank.Label" | translate
          }}</label>
        <div class="d-flex align-items-center">

          <img *ngIf="EKYCDetail.masterBankNameId" [src]="getBankImg(EKYCDetail.masterBankNameId)" [width]="30"
            [height]="30" class="mr-1">
          <h4 class="mb-0">{{ EKYCDetail.masterBankNameId ? getBankName(EKYCDetail.masterBankNameId) : '-' }}</h4>

        </div>


      </div>

      <div class="col-12" style="margin-bottom: 0.5rem">
        <label class="form-label text-primary" for="accountName">{{
          "Payment.Modal.BankAccount.AccountName.Label" | translate
          }}</label>

        <h4 class="mb-0"> {{ EKYCDetail.bankAccountName ? EKYCDetail.bankAccountName : '-' }} </h4>
      </div>

      <div class="col-12" style="margin-bottom: 0.5rem">
        <label class="form-label text-primary" for="accountNumber">{{
          "Payment.Modal.BankAccount.AccountNumber.Label" | translate
          }}</label>

        <h4 class="mb-0"> {{ EKYCDetail.bankAccountNo ? EKYCDetail.bankAccountNo : '-'}} </h4>
      </div>



    </div>
  </div>
</section>


<!-- <div class="d-flex"><p class="mr-1 mb-0">Status:&nbsp;</p>
      <p class="mb-0" [ngClass]="{'text-success' : fileStateEnum == stateEnum.Approved,
      'text-warning' : fileStateEnum == stateEnum.WaitApprove,
      'text-danger' : fileStateEnum == stateEnum.Reject }">
        {{ getStatusLabel(fileStateEnum) }}
      </p>
    </div> -->

<ng-template #imagePreview let-title="title" let-tempimage="tempimage" let-originalImage="originalImage"
  let-isInvalid="isInvalid" let-defaultImage="defaultImage" let-fileEnum="fileEnum" let-fileStateEnum="fileStateEnum">
  <div class="d-flex justify-content-between">
    <div class="my-auto text-primary">{{ title | translate }}<span class="text-danger">*</span></div>

    <div class="d-flex align-items-center">
      <p class="mr-1 mb-0">{{'General.Status' | translate}}:&nbsp;</p>
      <select #status class="custom-select" [disabled]="originalImage == null"
        (change)="onFileStatusChange(fileEnum,$event.target.value)" [ngModel]="fileStateEnum" [ngClass]="status.value == stateEnum.WaitForVerify ? 'text-warning' : status.value == stateEnum.Verified ? 'text-success' : 'text-danger'">
        <option [value]="stateEnum.None" disabled class="text-dark">
          {{getStatusLabel(stateEnum.None)}}
        </option>
        <option [value]="stateEnum.WaitForVerify" [disabled]="fileStateEnum == stateEnum.WaitForVerify" class="text-dark">{{getStatusLabel(stateEnum.WaitForVerify)}}</option>
        <option [value]="stateEnum.Verified" [disabled]="fileStateEnum == stateEnum.Verified" class="text-dark">{{getStatusLabel(stateEnum.Verified)}}</option>
        <option [value]="stateEnum.Rejected" [disabled]="fileStateEnum == stateEnum.Rejected" class="text-dark">{{getStatusLabel(stateEnum.Rejected)}}</option>
      </select>
    </div>
  </div>
  <div class="ekyc-image-preview mt-1">
    <div class="ekyc-image-preview-container w-100"
      [ngStyle]="{ 'border' : isSubmit && isInvalid ? '1px solid red' : 'none' }">


      <a *ngIf="originalImage == null">
        <img [src]="defaultImage" alt="thumbIdCard" />
      </a>
      <a *ngIf="originalImage != null" [href]="originalImage" target="_blank">
        <img [src]="originalImage" alt="thumbIdCard" class="idcard" />
      </a>

    </div>
  </div>
</ng-template>
