import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { TempRowQuotation } from 'app/main/model/Quotation';
import { DocumentService } from 'app/main/pages/document/document.service';
import { ApiService } from 'app/main/service/api.service';
import { DocumentApiService } from 'app/main/service/document-api.service';

@Component({
  selector: 'app-content-detail-quotation',
  templateUrl: './content-detail-quotation.component.html',
  styleUrls: ['./content-detail-quotation.component.scss']
})
export class ContentDetailQuotationComponent implements OnInit {
  apiPath: string;
  quotationPath: string;
  invoicePath: string;
  saleOrderPath: string;
  editPathUrl: string;
  titleDoc: string;
  infoObj: any;
  type: string;
  customerAddressObj: CustomerAddress;
  docID: string;
  typeDocName: string;

  constructor(
    private _apiService: ApiService,
    private _documentService: DocumentService,
    private _documentApiService: DocumentApiService,
    private _route: ActivatedRoute,
  ) {
    this.initData();
  }

  getQuotationData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocData(this.quotationPath, this.docID)
        .subscribe((res) => {
          self.infoObj = res.data.resultData[0];
          console.log(this.infoObj);
          self.getCustomerAddress(self.infoObj.customerId);
        });
    });
  }

  getInvoiceData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocData(this.invoicePath, this.docID)
        .subscribe((res) => {
          self.infoObj = res.data.resultData[0];
          console.log(this.infoObj);
          self.getCustomerAddress(self.infoObj.customerId);
        });
    });
  }

  getSaleOrderData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocData(this.saleOrderPath, this.docID)
        .subscribe((res) => {
          self.infoObj = res.data.resultData[0];
          console.log(this.infoObj);
          self.getCustomerAddress(self.infoObj.customerId);
        });
    });
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData[0];
        });
    });
  }

 initData(): void {
    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
    });
    this._route.paramMap.subscribe((val) => {
      this.type = this._route.snapshot.paramMap.get('type');
    });

    if(this.type === "quotation"){
      this.quotationPath = this._documentService.quotation.ApiPath;
      this.editPathUrl = this._documentService.quotation.EditPathURL;
    }else if(this.type === 'invoice'){
      this.invoicePath = this._documentService.invoice.ApiPath;
      this.editPathUrl = this._documentService.invoice.EditPathURL;
    }else if(this.type === 'saleorder'){
      this.saleOrderPath = this._documentService.saleOrder.ApiPath
      this.editPathUrl = this._documentService.saleOrder.PathURL;

    }

    if(this.quotationPath){
      this.apiPath = this.quotationPath;
      this.typeDocName = "quotationCode";
      this.getQuotationData();
    }else if(this.invoicePath){
      this.apiPath = this.invoicePath;
      this.typeDocName = "invoiceCode";
      this.getInvoiceData();
    }else if(this.saleOrderPath){
      this.apiPath = this.saleOrderPath;
      this.typeDocName = "saleOrderCode";
      this.getSaleOrderData();
    }
  }



  ngOnInit(): void {
  }

}
