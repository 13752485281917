import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubclassService {
  componentName: string = 'SubClass';
  apiUrl: string = 'subclass';
  pathUrl: string = '/item-manage/subclass';

  parentComponentName: string = 'Class';
  parentApiUrl: string = 'class';
  parentPathUrl: string = '/item-manage/class';

  itemComponentName: string = 'Item';
  itemApiUrl: string = 'item';
  itemPathUrl: string = '/item-manage/item';


  constructor() { }
}
