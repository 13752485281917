<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section>
      <app-horizontal-timeline
        [timelineItems]="timelineData"
        [currentStep]="currentStep"
        [currentStepNumber]="currentStep"
        [lastItems]="{
          label: 'Cancel',
          descript: 'ยกเลิก',
          stepCount: 5
        }"
      ></app-horizontal-timeline>
    </section>

    <section>
      <app-add-bill
        [data]="invoiceObj"
        [apiPath]="apiPath"
        [docTitle]="'Document.Invoice'"
        [docCodeColumn]="'invoiceCode'"
        [isOnlyDecrease]="true"
        [isSellerDoc]="true"
        (submitData)="handleAddBillSubmit($event)"
        [isCreateInvoice]="true"
        #addBillComponent
      ></app-add-bill>
    </section>
  </div>
</div>
