import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { ApiService } from 'app/main/service/api.service';
import { param } from 'jquery';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApprovalModalComponent } from './approval-modal/approval-modal.component';
import { HttpClient } from '@angular/common/http';
import Branch from 'app/main/model/Branch';
import { DataTableDirective } from 'angular-datatables';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';
import { CommonResponse } from 'app/main/model/CommonResponse';
import {
  BookingStatusEnum,
  MeetingRoomBooking,
} from 'app/main/model/BookingPeriodTime';
import { BookingCalendarModalComponent } from '../booking-calendar/booking-calendar-modal/booking-calendar-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-booking-approval',
  templateUrl: './booking-approval.component.html',
  styleUrls: ['./booking-approval.component.scss'],
})
export class BookingApprovalComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;
  contentHeader: ContentHeader;
  BookingdtOptions: DataTables.Settings = {};
  pageLength: number = 10;
  page: number = 1;

  searchData: any = [];
  searchByEventName: number = 0;
  searchByStatus: number = 3;
  searchByRepoveCode: boolean = false;
  searchText: string = '';

  bookingData: MeetingRoomBooking[] = [];
  isLoading: boolean = false;
  branchList: Branch[] = [];

  @BlockUI() blockUI: NgBlockUI;

  currentTime: Date = new Date();
  currentLang: string = '';

  bookingEnum = BookingStatusEnum;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private componentService: ComponentsService,
    private _translateService: TranslateService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    var self = this;
    this.contentHeader = {
      headerTitle: 'Dashboard.ApproveBookingList',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this.BookingdtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: this.pageLength,
      // order: [1, 'desc'],
      ordering: false,
      serverSide: true,
      lengthChange: false,
      ajax: (dataTablesParameters: any, callback: any) => {
        self.SetLoadingState();

        if (this.searchByEventName == 0) {
          console.log(this.searchByEventName);
          this.searchData.repoveCode = '';
          self.searchData.Topic = '';
          self.searchData.CustomerName = this.searchText;
        }
        if (this.searchByEventName == 1) {
          console.log(this.searchByEventName);
          this.searchData.repoveCode = '';
          self.searchData.Topic = this.searchText;
          self.searchData.CustomerName = '';
        }
        if (this.searchByEventName == 2) {
          console.log(this.searchByEventName);
          this.searchData.repoveCode = this.searchText;
          self.searchData.Topic = '';
          self.searchData.CustomerName = '';
        }

        let defaultSortName = 'startDate';
        // let listcol = dataTablesParameters?.columns;
        // let order = dataTablesParameters?.order[0];
        // let founded = listcol?.filter(
        //   (item: any) => item.data === order.column
        // )[0];
        // let column_filter_number = founded.data;
        // let column_filter_name = founded.name;
        // if (column_filter_number == 0) {
        //   self.searchData.SortPath = defaultSortName;
        // } else {
        //   self.searchData.SortPath = !!column_filter_name
        //     ? column_filter_name
        //     : defaultSortName;
        // }
        self.searchData.SortPath = defaultSortName;
        // console.log(order?.dir);
        // this.searchData.Direction = order?.dir == 'desc' ? 1 : 0;
        this.searchData.Direction = 1;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );

        this.searchData.PageLength = dataTablesParameters.length;
        this.searchData.isDelete = false;

        this.bookingData = [];
        this.page = this.searchData.Page;

        if (this.searchByStatus == 3) {
          this.searchData.stateEnum = '';
        } else {
          this.searchData.stateEnum = this.searchByStatus;
        }

        this.getBookingData(this.searchData).then((r) => {
          callback({
            recordsTotal: r.data.itemCount,
            recordsFiltered: r.data.totalItem,
            data: [],
          });
        });
      },
    };

    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.getBranchList();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  getBookingData(searchData?: any): Promise<any> {
    return new Promise((resolve, rejects) => {
      this.SetLoadingState();

      this.apiService
        .GetAllData('MeetingRoomBooking', searchData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<MeetingRoomBooking>) => {
            this.SetLoadedState();
            this.bookingData = res.data.resultData;
            resolve(res);
          },
          (err) => {
            this.SetLoadedState();
            rejects();
          }
        );
    });
  }

  getBranchList(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService
        .GetAllData('Branch?SortPath=createDate', this.searchData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<Branch>) => {
            this.branchList = res.data.resultData;
            console.log(this.branchList);
            resolve();
          },
          (err) => {
            reject();
          }
        );
    });
  }

  getBranchName(id: any) {
    const branchData = this.branchList.find((branch) => branch.id == id);
    return branchData.name;
  }

  openApprovedModal(booking: any) {
    console.log(booking);
    const modalRef = this.modalService.open(ApprovalModalComponent, {
      centered: true,
    });

    modalRef.componentInstance.title = 'ยืนยันการอนุมัติกิจกรรม';
    modalRef.componentInstance.detail = `คุณแน่ใจที่จะอนุมัติกิจกรรม ${booking.topic} หรือไม่`;
    modalRef.componentInstance.callBackFunc.subscribe((remark) => {
      this.blockUI.start();
      console.log(remark);
      console.log(booking.id, remark, 1);
      this.eventApproval(booking.id, remark, 1, () => {
        this.Search();
        this.blockUI.stop();
        this.componentService.SuccessSwal();
      });
    });
  }

  openRejectedModal(booking: any) {
    console.log(booking);
    const modalRef = this.modalService.open(ApprovalModalComponent, {
      centered: true,
    });

    modalRef.componentInstance.title = 'ยืนยันการปฏิเสธกิจกรรม';
    modalRef.componentInstance.detail = `คุณแน่ใจที่จะปฏิเสธกิจกรรม ${booking.topic} หรือไม่`;
    modalRef.componentInstance.callBackFunc.subscribe((remark) => {
      this.blockUI.start();
      this.eventApproval(booking.id, remark, 2, () => {
        console.log(booking.id);
        this.Search();
        this.blockUI.stop();
        this.componentService.SuccessSwal();
      });
    });
  }

  eventApproval(
    eventId: string,
    remark: string,
    stateEnum: any,
    f: () => void
  ) {
    console.log(eventId);
    this.apiService
      .patch(`MeetingRoomBooking/${eventId}/BookingStatus`, {
        stateEnum: stateEnum,
        remarks: remark,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          f();
        },
        (err) => {
          this.componentService.ErrorSwal();
        }
      );
  }

  Search() {
    let Table = this.dtElements.find((item: any) => {
      return item.el.nativeElement.id == 'eventTable';
    });

    Table.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload(() => {
        this.SetLoadedState();
      });
    });

    console.log(this.dtElements);
  }

  Reset() {
    this.searchText = '';
    this.searchByStatus = 3;
    this.Search();
  }

  CheckIsOvertime(startDateISO: string): boolean {
    let startDate: Date = new Date(startDateISO);
    return startDate < this.currentTime;
  }

  @Input() isEditData: boolean;
  finalData: any;

  openEditModal(booking) {
    const modalRef = this.modalService.open(BookingCalendarModalComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.title = 'Edit Booking';
    modalRef.componentInstance.bookingData = booking;
    modalRef.componentInstance.isEditData = true;
    modalRef.componentInstance.isWaitApproveEdit = true;
    modalRef.componentInstance.callBackFunc.subscribe((preparedData) => {
      this.finalData = preparedData;
      console.log(this.finalData);
      this.openConfirmModal(this.finalData);
    });
  }
  updateData(data, id) {
    this.apiService.UpdateDataById('MeetingRoomBooking', id, data).subscribe(
      (res) => {
        this.componentService.SuccessSwal();
        this.Search();
        this.modalService.dismissAll();
        console.log(res);
      },
      (error) => {
        console.log(error);
        console.error(error.error.data.message);
        this.componentService.ErrorSwal('Error', error.error.data.message);
      }
    );
  }

  openConfirmModal(finalData) {
    const modalRef = this.modalService.open(ModalComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.title = this._translateService.instant(
      'BookingCalendar.Modal.UpdateEvent'
    );
    modalRef.componentInstance.detail = this._translateService.instant(
      'BookingCalendar.Modal.ConfirmUpdate'
    );
    modalRef.componentInstance.callBackFunc.subscribe(() => {
      this.updateData(finalData, finalData.id);
    });
  }

  getBookingStatusLabel(stateEnum: number): any {
    switch (stateEnum) {
      case BookingStatusEnum.WaitApprove:
        return this._translateService.instant(
          'BookingCalendar.Status.WaitApprove'
        );
      case BookingStatusEnum.Approved:
        return this._translateService.instant(
          'BookingCalendar.Status.Approved'
        );
      case BookingStatusEnum.Reject:
        return this._translateService.instant('BookingCalendar.Status.Reject');

      default:
        return this._translateService.instant(
          'BookingCalendar.Status.WaitApprove'
        );
    }
  }
}
