import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsModule } from './settings/settings.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SettingsModule
  ]
})
export class GeneralModule { }
