class Class {
  id: number
  subCategoryId: number
  classCode: string
  name: string
  taxRate: number
  coverMediaFile: string
  mediaFiles: string[]
  isActive: boolean
  isDelete: boolean
  createBy: string
  createDate: string
  updateBy: string
  updateDate: string
  deleteBy: string
  deleteDate: string
  
}

export default Class;
