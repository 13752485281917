import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ChartOptions } from '../executive-summary.component';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { ApiService } from 'app/main/service/api.service';
import { List } from 'lodash';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonResponse } from 'app/main/model/CommonResponse';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { error } from 'console';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { ComponentsService } from 'app/main/components/components.service';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'path';
import { FilesApiService } from 'app/main/service/files-api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-member-chart',
  templateUrl: './member-chart.component.html',
  styleUrls: ['./member-chart.component.scss'],
})
export class MemberChartComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  @ViewChild('apexColumnChartRef') apexColumnChartRef: any;
  public apexColumnChart: Partial<ChartOptions>;
  public overallLineChart: Partial<ChartOptions>;
  @BlockUI() blockUI: NgBlockUI;
  @HostListener('window:resize', ['$event'])
  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    dateFormat: 'm/Y',
    altFormat: 'F Y',
    mode: 'range',
  };

  chartColors = {
    column: {
      series1: '#799fcb',
      series2: '#FF0000',
      // series3: 'afc7d0',
      // series4: '#FF0000',
    },
    success: {
      shade_100: '#7eefc7',
      shade_200: '#06774f',
    },
    donut: {
      series1: '#ffe700',
      series2: '#00d4bd',
      series3: '#826bf8',
      series4: '#2b9bf4',
      series5: '#FFA1A1',
    },
    area: {
      series3: '#a4f8cd',
      series2: '#60f2ca',
      series1: '#2bdac7',
    },
  };

  isInit: boolean = false;
  memberLineData: any;
  memberChartData: any;
  months: { value: number; nameEN: string; nameTH: string }[] = [
    { value: 0, nameEN: 'January', nameTH: 'มกราคม' },
    { value: 1, nameEN: 'February', nameTH: 'กุมภาพันธ์' },
    { value: 2, nameEN: 'March', nameTH: 'มีนาคม' },
    { value: 3, nameEN: 'April', nameTH: 'เมษายน' },
    { value: 4, nameEN: 'May', nameTH: 'พฤษภาคม' },
    { value: 5, nameEN: 'June', nameTH: 'มิถุนายน' },
    { value: 6, nameEN: 'July', nameTH: 'กรกฎาคม' },
    { value: 7, nameEN: 'August', nameTH: 'สิงหาคม' },
    { value: 8, nameEN: 'September', nameTH: 'กันยายน' },
    { value: 9, nameEN: 'October', nameTH: 'ตุลาคม' },
    { value: 10, nameEN: 'November', nameTH: 'พฤศจิกายน' },
    { value: 11, nameEN: 'December', nameTH: 'ธันวาคม' },
  ];
  selectedStartMonth: string = '';
  selectedEndMonth: string = '';
  selectedStartYear: number;
  selectedEndYear: number;
  minYear: any;
  maxYear: any;
  yearList: any[] = [];
  selectedEndMonthOptions: any;
  selectedEndYearOptions: any;
  targetSelect: any = '';
  targetYear: any = '';

  startDate: string;
  endDate: string;

  editingRowIndex: number = -1;

  currentTable: string = '';
  currentYearTable: string = '';
  currentLang: string;

  isCreate: boolean = false;
  setTargetForm: FormGroup;

  timeConvertObj: Date[] = [];
  classifier: string = 'Member';

  targetType: { value: number; name: string }[] = [
    { value: 1, name: 'NewMemberTarget' },
    { value: 2, name: 'ExistingMemberTarget' },
  ];

  currentDate: Date = new Date();
  default_startDate: string = moment(this.currentDate)
    .startOf('month')
    .subtract(11, 'months')
    .toISOString();
  default_endDate: string = moment(this.currentDate)
    .endOf('month')
    .toISOString();

  constructor(
    private _apiService: ApiService,
    private _globalFuncService: GlobalFuncService,
    private formBuilder: FormBuilder,
    private _modalService: NgbModal,
    private _componentService: ComponentsService,
    private translateService: TranslateService,
    private _fileApiService: FilesApiService
  ) {
    this.currentLang = this.translateService.currentLang;
    this.setTargetForm = this.formBuilder.group({
      targetValue: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    let currentDate = new Date();
    this.targetYear = currentDate.getFullYear(); // defualt on current year
    this.targetSelect = 1; /// defualt on new Member Target
    this.getTargetValue();

    this.translateService.onLangChange.subscribe((e) => {
      this.currentLang = this.translateService.currentLang;
    });

    this.getDropdownYear();
    this.getMembersData(this.default_startDate, this.default_endDate);
    console.log(this.timeConvertObj);

    this.DrawChart();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.overallLineChart = null;
    this.apexColumnChart = null;
  }

  setChart() {
    var self = this;
    this.overallLineChart = {
      series: [
        {
          name: 'Overall',
          type: 'line',
          color: '#0134a1',
          data: [],
        },
      ],
      chart: {
        type: 'line',
        height: 200,
        // width: 95,
        stacked: false,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      grid: {
        padding: {
          left: 30,
          right: 15,
          bottom: -15,
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'right',
      },
      plotOptions: {
        bar: {
          columnWidth: '70%',
        },
      },
      colors: [
        this.chartColors.column.series1,
        this.chartColors.column.series2,
      ],

      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: [this.timeConvertObj],
      },
      yaxis: {
        title: {
          text: 'Members',
        },

        labels: {
          offsetY: 0,
          // offsetX: -15,
          formatter: (val) => {
            return (
              self._globalFuncService.FormatToMoney(
                Math.round(val),
                null,
                true
              ) + ''
            );
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (
              self._globalFuncService.FormatToMoney(
                Math.round(val),
                null,
                true
              ) + (Math.round(val) > 1 ? ' Members' : ' Member')
            );
          },
        },
      },
      noData: {
        text: 'No data available.',
        align: 'center',
        verticalAlign: 'middle',
        offsetY: -10,
        offsetX: 20,
      },
    };

    this.apexColumnChart = {
      series: [
        {
          name: 'New Member Target',
          group: 'Target',
          data: [120, 140, 135, 112, 100, 130, 145, 125, 135, 140],
          color: '#80c7fd',
        },
        {
          name: 'New Member Actual',
          group: 'Actual',
          data: [130, 130, 145, 150, 170, 180, 160, 160, 180, 150],
          color: '#008FFB',
        },
        {
          name: 'Existing Member Target',
          group: 'Target',
          data: [130, 140, 150, 160, 130, 120, 150, 167, 162, 175],
          color: '#80f1cb',
        },
        {
          name: 'Existing Member Actual',
          group: 'Actual',
          data: [350, 300, 280, 300, 320, 330, 350, 360, 370],
          color: '#00E396',
        },
      ],
      chart: {
        type: 'bar',
        height: 450,
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
      },
      plotOptions: {
        bar: {
          columnWidth: '50px',
          colors: {
            backgroundBarRadius: 10,
          },
        },
      },
      colors: [
        this.chartColors.column.series1,
        this.chartColors.column.series2,
      ],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        title: {
          text: 'Month/ํYear',
        },
        categories: [],
      },
      yaxis: {
        title: {
          text: 'Members',
        },
        labels: {
          formatter: (val) => {
            return (
              self._globalFuncService.FormatToMoney(
                Math.round(val),
                null,
                true
              ) + ''
            );
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (
              self._globalFuncService.FormatToMoney(
                Math.round(val),
                null,
                true
              ) + (Math.round(val) > 1 ? ' Members' : ' Member')
            );
          },
        },
      },
      noData: {
        text: 'No data available.',
        align: 'center',
        verticalAlign: 'middle',
        offsetY: -20,
        offsetX: 20,
      },
    };
  }

  checkDataAvaliable(numArr: number[]): boolean {
    return !numArr.every((value) => value === 0);
  }

  setMemberChart() {
    this.overallLineChart.series = [
      {
        name: 'Overall',
        type: 'line',
        color: '#0134a1',
        data: this.checkDataAvaliable(this.memberChartData.lineChart.lineData)
          ? this.memberChartData.lineChart.lineData
          : [],
      },
    ];
    let formattedCategories = this.timeConvertObj.map((date) => {
      let result: string = '';
      let tempdate = new Date(date);

      result = `${tempdate.toLocaleString('en-US', {
        month: 'short',
      })}/${tempdate.getFullYear()}`;
      return result;
    });

    this.overallLineChart.xaxis.categories = formattedCategories;

    this.apexColumnChart.series = [
      {
        name: 'New Member Target',
        group: 'Target',
        data: this.checkDataAvaliable(
          this.memberChartData.columnChart.newMemberTarget
        )
          ? this.memberChartData.columnChart.newMemberTarget
          : [],
        color: '#80c7fd',
      },
      {
        name: 'New Member Actual',
        group: 'Actual',
        data: this.checkDataAvaliable(
          this.memberChartData.columnChart.newMemberActual
        )
          ? this.memberChartData.columnChart.newMemberActual
          : [],
        color: '#008FFB',
      },
      {
        name: 'Existing Member Target',
        group: 'Target',
        data: this.checkDataAvaliable(
          this.memberChartData.columnChart.existingMemberTarget
        )
          ? this.memberChartData.columnChart.existingMemberTarget
          : [],

        color: '#80f1cb',
      },
      {
        name: 'Existing Member Actual',
        group: 'Actual',
        data: this.checkDataAvaliable(
          this.memberChartData.columnChart.existingMemberActual
        )
          ? this.memberChartData.columnChart.existingMemberActual
          : [],

        color: '#00E396',
      },

      // ['#80c7fd', '#008FFB', '#80f1cb', '#00E396'],
    ];
    this.apexColumnChart.xaxis.categories = formattedCategories;
    this.apexColumnChart.xaxis.categories = formattedCategories;
  }
  onStartMonthChange() {
    console.log(this.selectedStartMonth);

    this.selectedEndMonthOptions = this.generateEndMonthOptions(
      parseInt(this.selectedStartMonth)
    );
    this.selectedEndMonth = this.selectedStartMonth;
    console.log(this.selectedEndMonth);
  }
  onStartYearChange() {
    this.selectedEndYear = this.selectedStartYear;
    this.selectedEndYearOptions = this.generateEndYearOptions(
      this.selectedStartYear
    );
    this.selectedStartMonth = '0';
    this.selectedEndMonthOptions = this.generateEndMonthOptions(
      parseInt(this.selectedStartMonth)
    );
    this.selectedEndMonth = '0';
  }
  onMonthChange() {
    console.log('Selected month:', parseInt(this.selectedStartMonth));
    console.log('Selected month: End', this.selectedEndMonth);

    this.selectedEndMonthOptions = this.generateEndMonthOptions(
      parseInt(this.selectedStartMonth)
    );
  }
  getMonth(value: number) {
    if (this.currentLang == 'en') {
      return this.months.find((month) => month.value === value)?.nameEN || '';
    } else {
      return this.months.find((month) => month.value === value)?.nameTH || '';
    }
  }
  onYearChange() {
    this.selectedEndYearOptions = this.generateEndYearOptions(
      this.selectedStartYear
    );
    // If the start year is the same as the selected end year,
    // then adjust the end month options to include only months after the start month
    console.log(this.selectedStartYear, this.selectedEndYear);
    // this.selectedEndMonthOptions = this.months;
    if (this.selectedStartYear === this.selectedEndYear) {
      this.selectedEndMonthOptions = this.generateEndMonthOptions(
        parseInt(this.selectedStartMonth)
      );
    } else if (this.selectedStartYear > this.selectedEndYear) {
      this.selectedEndMonth = null;
      this.selectedEndMonthOptions = this.months;
    } else {
      // If the start year is before the selected end year, enable all months
      this.selectedEndMonthOptions = this.months;
    }

    console.log('Selected year:', this.selectedStartYear);
    console.log('Selected year:', this.selectedEndYear);
  }

  getDropdownYear() {
    this._apiService
      .GetAllData(
        'Dashboard/ForSearch/DropDown/GetNewestDataAndOldest/Customer'
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res.data.resultData);
        this.minYear = new Date(res.data.resultData[0].min);
        this.maxYear = new Date(res.data.resultData[0].max);
        console.log(this.minYear.getFullYear());
        console.log(this.maxYear.getFullYear());
        this.yearList = this.getRangeOfYears(
          this.minYear.getFullYear(),
          this.maxYear.getFullYear() + 20
        );
        console.log(this.yearList);
      });
  }
  generateEndMonthOptions(
    startMonth: number
  ): { value: number; nameEN: string; nameTH: string }[] {
    console.log(this.selectedStartYear, this.selectedEndYear);
    // If no start month selected, enable all months
    if (!startMonth || this.selectedStartYear < this.selectedEndYear) {
      console.log(1);

      return this.months;
    }
    console.log(2);

    // Otherwise, disable past months
    return this.months.filter((month) => month.value >= startMonth);
  }
  generateEndYearOptions(startYear: number): number[] {
    // If no start year selected, enable all years
    if (!startYear) {
      return this.yearList;
    }
    // Otherwise, disable past years
    return this.yearList.filter((year) => year >= startYear);
  }
  getRangeOfYears(startYear: number, endYear: number): number[] {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  }

  submitDate() {
    this.startDate = moment({
      year: this.selectedStartYear,
      month: parseInt(this.selectedStartMonth),
    })
      .startOf('month')
      .toISOString();
    this.endDate = moment({
      year: this.selectedEndYear,
      month: parseInt(this.selectedEndMonth),
    })
      .endOf('month')
      .toISOString();

    this.getMembersData(this.startDate, this.endDate);
  }
  resetSearchCriteria() {
    // Reset all search criteria
    this.selectedStartMonth = '';
    this.selectedEndMonth = '';
    this.selectedStartYear = null;
    this.selectedEndYear = null;
    this.selectedEndMonthOptions = [];
    this.selectedEndYearOptions = [];
    this.getMembersData(this.default_startDate, this.default_endDate);
  }

  getMembersData(startDate?: string, endDate?: string) {
    this.blockUI.start();
    startDate = startDate || '';
    endDate = endDate || '';

    this._apiService
      .GetAllData('Dashboard/ExecutiveSummary/Member', {
        dateFrom: startDate,
        dateTo: endDate,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          console.log(res);

          this.memberChartData = res.data.resultData[0];
          console.log(new Date(this.memberChartData.categoriesDateTimeList[0]));
          this.timeConvertObj = this.memberChartData.categoriesDateTimeList.map(
            (dateString) => new Date(dateString)
          );
          console.log(this.timeConvertObj);

          this.setChart();
          this.setMemberChart();

          this.DrawChart();
          this.blockUI.stop();
        },
        (err) => {
          this.blockUI.stop();
        }
      );
  }

  DrawChart() {
    // Update options for apexColumnChart
    this.apexColumnChart.chart.width =
      this.apexColumnChartRef.nativeElement.offsetWidth;

    // Redraw apexColumnChart
    if (this.apexColumnChartRef && this.apexColumnChartRef.chart) {
      this.apexColumnChartRef.chart.updateOptions({
        chart: this.apexColumnChart.chart,
        xaxis: this.apexColumnChart.xaxis.categories,
        yaxis: this.apexColumnChart.yaxis,
        colors: this.apexColumnChart.colors,
        series: this.apexColumnChart.series,
        labels: this.apexColumnChart.labels,
        legend: this.apexColumnChart.legend,
        dataLabels: this.apexColumnChart.dataLabels,
        tooltip: this.apexColumnChart.tooltip,
        plotOptions: this.apexColumnChart.plotOptions,
        grid: this.apexColumnChart.grid,
        stroke: this.apexColumnChart.stroke,
        fill: this.apexColumnChart.fill,
      });
    }

    // Set isInit to true to indicate that chart has been initialized
    this.isInit = true;
    console.log(1);
  }
  onResize(event) {
    const width = event.target.innerWidth;
    console.log(width);
    this.DrawChart();
    this.setChart();
    this.setMemberChart();
  }
  getTargetType(value: number) {
    return this.targetType.find((target) => target.value === value).name;
  }

  onSetTargetChange(event) {
    console.log(event.target.value);
    console.log(this.yearList);

    console.log(this.targetSelect);
    // this.getTargetValue()
  }
  onSelectTargetYear(event) {
    console.log(event.target.value);

    this.selectedTargetYear = parseInt(event.target.value);
    console.log(this.selectedTargetYear);
  }
  targetValues: number[] = [];
  isEditing: boolean[] = [];

  setTargetStart: string;
  setTargetEnd: string;

  initializeTargetValues() {
    this.targetValues = Array(this.months.length).fill(0);
    this.isEditing = Array(this.months.length).fill(false);
  }

  selectedTargetMonth: any;
  selectedTargetYear: number;

  startEditing(index: number) {
    this.editingRowIndex = index;

    this.isEditing[index] = true;

    this.targetValues[index] = this.tableData[index].targetValue;
    this.selectedTargetMonth = this.months.find(
      (month) => month.value === index
    );
    console.log(this.selectedTargetMonth.name);

    this.setTargetStart = moment({
      year: this.selectedTargetYear,
      month: this.selectedTargetMonth.value,
    })
      .startOf('month')
      .toISOString();
    this.setTargetEnd = moment({
      year: this.selectedTargetYear,
      month: this.selectedTargetMonth.value,
    })
      .endOf('month')
      .millisecond(0)
      .toISOString();

    console.log(this.setTargetStart);
    console.log(this.setTargetEnd);
  }

  saveEditing(index: number) {
    console.log('New Target Value:', this.targetValues[index]);
    this.editingRowIndex = -1;
    this.isEditing[index] = false;

    this.confirmModal(index);
    // this.getTargetValue()
  }
  cancelEditing(index: number) {
    this.isEditing[index] = false;
    this.editingRowIndex = -1;
  }

  getTargetValueForMonth(index: number): number {
    return this.targetValues[index];
  }

  tableData: any[] = Array.from({ length: 12 }, () => ({ targetValue: 0 }));
  getTargetValue(index?) {
    this.currentTable = this.getTargetType(Number(this.targetSelect));
    console.log(this.currentTable);

    this.currentYearTable = this.targetYear;
    this.blockUI.start();
    let startDate = moment({ year: this.selectedTargetYear, month: 0 })
      .startOf('month')
      .toISOString();
    let endDate = moment({ year: this.selectedTargetYear, month: 11 })
      .endOf('month')
      .toISOString();

    let targetObj = {
      graphTypeEnumId: 0,
      targetTypeEnumId: this.targetSelect || 0,
      startDate: startDate || '',
      endDate: endDate || '',
    };

    this._apiService
      .GetAllData('Target', targetObj)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res.data.resultData);

        const responseData = res.data.resultData;
        this.tableData = Array.from({ length: 12 }, (_, index) => {
          const endDate = moment({
            year: this.selectedTargetYear,
            month: index,
          })
            .endOf('month')
            .toISOString();
          return { targetValue: 0, endDate: endDate };
        });
        responseData.forEach((item, index = 12) => {
          console.log(index);

          const monthValue = new Date(item.startDate).getMonth();
          console.log(monthValue);
          this.tableData[monthValue] = {
            ...item,
            targetValue: item.targetValue,
          };
        });
        this.blockUI.stop();

        console.log(this.tableData);
      });
  }

  onTargetSearch() {
    this.getTargetValue();
  }

  onTargetSearchReset() {
    this.selectedTargetYear = null;
    this.targetSelect = null;
  }

  createTargetValue(index) {
    console.log(this.targetType);

    let targetObj = {
      targetValue: this.targetValues[index],
      graphTypeEnumId: 0,
      targetTypeEnumId: this.targetSelect,
      startDate: this.setTargetStart,
      endDate: this.setTargetEnd,
    };
    this._apiService
      .AddData('Target', targetObj)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          console.log(res);
          this._componentService.SuccessSwal();

          this.getTargetValue();
        },
        (error) => {
          this._componentService.ErrorSwal();
          console.log(error);
        }
      );

    console.log(targetObj);
  }

  updateTargetValue(index) {
    let id = this.tableData[index].id;
    let targetObj = {
      targetValue: this.targetValues[index],
      graphTypeEnumId: 0,
      targetTypeEnumId: this.targetSelect,
      startDate: this.setTargetStart,
      endDate: this.setTargetEnd,
    };
    this._apiService
      .UpdateDataById('Target', id, targetObj)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          console.log(res);
          this._componentService.SuccessSwal();
          this.getTargetValue();
        },
        (error) => {
          console.log(error);
          this._componentService.ErrorSwal();
        }
      );

    console.log(targetObj);
    console.log(this.tableData[index].id);
  }

  confirmModal(index) {
    if (this.tableData[index].id) {
      let title = 'Edit';
      let detail = 'Are you sure to edit this target?';
      let isCreate = false;
      this.openModal(title, detail, index, isCreate);
    } else {
      let title = 'Comfirm';
      let detail = 'Are you sure to Create this target';
      let isCreate = true;
      this.openModal(title, detail, index, isCreate);
    }
  }

  openModal(title: string, detail: string, index, isCreate: boolean) {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.title = title;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.blockUI.start();
      if (isCreate) {
        this.createTargetValue(index);
        this.blockUI.stop();
      } else {
        this.updateTargetValue(index);
        this.blockUI.stop();
      }
      this.getMembersData();
    });
  }

  checkEditCondition(isoDate: string): boolean {
    let currentDate: Date = new Date();
    let tDate: Date = new Date(isoDate);

    if (tDate.getFullYear() < currentDate.getFullYear()) {
      return false;
    }

    if (
      tDate.getFullYear() == currentDate.getFullYear() &&
      tDate.getMonth() <= currentDate.getMonth()
    ) {
      return false;
    }

    return true;
  }

  exportReport() {
    let path: string = 'Excel/ExportMemberTarget';
    let startDate: string;
    let endDate: string;
    let startYear: string;
    let endYear: string;

    let exportTime = new Date().toISOString();

    if (this.startDate && this.endDate) {
      startDate = new Date(this.startDate).toLocaleString('en-US', { month: 'short' });
      endDate =  new Date(this.endDate).toLocaleString('en-US', { month: 'short' });
      startYear = this.selectedStartYear.toString();
      endYear = this.selectedEndYear.toString();
    } else {
      startDate = new Date(this.default_startDate).toLocaleString('en-US', { month: 'short' });
      endDate = new Date(this.default_endDate).toLocaleString('en-US', { month: 'short' });
      startYear = new Date(this.default_startDate).getFullYear().toString();;
      endYear = new Date(this.default_endDate).getFullYear().toString();;
    }
  
    let fileName: string = `Member Report ${startDate}/${startYear}-${endDate}/${endYear}`;
    const param = {
      exportDate: exportTime,
      DateFrom: this.startDate || this.default_startDate,
      DateTo: this.endDate || this.default_endDate,
    };
  
    this._fileApiService.getExcelReport(path, fileName, param);
  }
}
