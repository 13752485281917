import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductListService {

  componentName: string = 'Product Detail';
  apiPath: string = 'ProductContent';
  pathUrl: string = '/cms/repove-tools/product-list';

  constructor() { }
}
