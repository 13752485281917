import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../category.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-category-formpage',
  templateUrl: './category-formpage.component.html',
  styleUrls: ['./category-formpage.component.scss']
})
export class CategoryFormpageComponent implements OnInit {
  contentHeader: object;
  pathUrl: string;
  constructor(
    private _translateService: TranslateService,
    private _categoryService: CategoryService
  ) {
    this.pathUrl = this._categoryService.pathUrl;
   }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'General.Category',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Category',
            isLink: true,
            link: '/item-manage/category',
          },
          {
            name: 'General.Create',
            isLink: false,
          },
        ],
      },
    };
  }

}
