import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChartOptions } from '../executive-summary.component';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { ApiService } from 'app/main/service/api.service';
import { CommonResponse } from 'app/main/model/CommonResponse';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import moment from 'moment';
import { FilesApiService } from 'app/main/service/files-api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-year-on-year-chart',
  templateUrl: './year-on-year-chart.component.html',
  styleUrls: ['./year-on-year-chart.component.scss'],
})
export class YearOnYearChartComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  @ViewChild('apexColumnChartRef') apexColumnChartRef: any;
  public apexColumnChart: Partial<ChartOptions>;

  // public DateRangeOptions: FlatpickrOptions = {
  //   // altInput: true,
  // };
  apiPath: string = 'Dashboard/ExecutiveSummary/YearOnYear';

  chartColors = {
    column: {
      series1: '#14AE5C',
      series2: '#FFCD29',
    },
    success: {
      shade_100: '#7eefc7',
      shade_200: '#06774f',
    },
    donut: {
      series1: '#ffe700',
      series2: '#00d4bd',
      series3: '#826bf8',
      series4: '#2b9bf4',
      series5: '#FFA1A1',
    },
    area: {
      series3: '#a4f8cd',
      series2: '#60f2ca',
      series1: '#2bdac7',
    },
  };

  isInit: boolean = false;
  yearList: any[] = [];

  oldestYear: any;
  newestYear: any;

  currentDate: Date = new Date();

  default_startYear: string = moment(this.currentDate)
    .startOf('year')
    .subtract(1, 'year')
    .toISOString();
  default_endYear: string = moment(this.currentDate)
    .endOf('year')
    .toISOString();

  fromYear: any = this.currentDate.getFullYear() - 1;
  toYear: any = this.currentDate.getFullYear();

  fileNameFromYear: number;
  fileNameToYear: number;

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private apiService: ApiService,
    private _translateService: TranslateService,
    private _globalFuncService: GlobalFuncService,
    private fileService: FilesApiService
  ) {
    var self = this;
    this.fromYear = this.currentDate.getFullYear() - 1;
    this.toYear = this.currentDate.getFullYear();
    this.apexColumnChart = {
      series: [
        {
          name: `Year 2022`,
          group: 'Previous Year',
          color: '#0134a1',
          data: [
            750000, 600000, 820000, 540000, 920000, 680000, 770000, 610000,
            890000, 720000, 950000, 560000,
          ],
          // data: this.chartData[0].sumTotalList,
        },
        {
          name: `Year 2023`,
          group: 'Now Year',
          color: '#088395',
          data: [
            780000, 620000, 540000, 890000, 720000, 610000, 950000, 560000,
            830000, 670000, 580000, 910000,
          ],
        },
      ],

      chart: {
        group: undefined,
        type: 'bar',
        height: 400,
        stacked: true,

        toolbar: {
          show: false,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
          colors: {
            // backgroundBarColors: [
            //   this.chartColors.column.bg,
            //   this.chartColors.column.bg,
            //   this.chartColors.column.bg,
            //   this.chartColors.column.bg,
            //   this.chartColors.column.bg,
            // ],
            backgroundBarRadius: 10,
          },
        },
      },
      colors: [
        this.chartColors.column.series1,
        this.chartColors.column.series2,
      ],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        title: {
          text: 'Month',
        },
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return self._globalFuncService.FormatToMoney(value);
          },
        },
      },
      fill: {
        opacity: 1,
      },

      tooltip: {
        y: {
          formatter: function (value) {
            return self._globalFuncService.FormatToMoney(value) + ' Baht';
          },
        },
      },
      noData: {
        text: 'No data available.',
        align: 'center',
        verticalAlign: 'middle',
        offsetY: 0,
        offsetX: 0,
      },
    };
  }

  ngOnInit(): void {
    this.apiService
      .GetAllData('Dashboard/ForSearch/DropDown/GetNewestDataAndOldest/Invoice')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: CommonResponse<MinMaxYear>) => {
        const tempOldestYear = new Date(res.data.resultData[0].min);
        const tempNewestYear = new Date(res.data.resultData[0].max);
        this.oldestYear = tempOldestYear.getFullYear();
        // this.oldestYear = 2020
        this.newestYear = tempNewestYear.getFullYear();
        for (let i = this.oldestYear; i <= this.newestYear; i++) {
          this.yearList.push(i);
        }
      });
    this.apiService
      .GetAllData(this.apiPath, {
        DateFrom: this.default_startYear,
        DateTo: this.default_endYear,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.setChart(res.data.resultData[0].yearList);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.apexColumnChart = null;
  }

  DrawChart() {
    // this.apexColumnChart.chart.width =
    //   this.apexColumnChartRef?.nativeElement.offsetWidth;

    window.dispatchEvent(new Event('resize'));
    this.isInit = true;
  }

  checkDataAvaliable(numArr: number[]): boolean {
    return !numArr.every((value) => value === 0);
  }

  setChart(data: YearList) {
    if (data[0]) {
      this.fileNameFromYear = data[0].year;
    }
    if (data[1]) {
      this.fileNameToYear = data[1].year;
    }

    let seriesArr = [];

    if (this.fileNameFromYear && data[0]) {
      seriesArr.push({
        name: `Year ${data[0].year}`,
        group: 'Previous Year',
        color: '#0134a1',
        data: this.checkDataAvaliable(data[0].sumTotalList)
          ? data[0].sumTotalList
          : [],
      });
    }

    if (this.fileNameToYear && data[1]) {
      seriesArr.push({
        name: `Year ${data[1].year}`,
        group: 'Now Year',
        color: '#088395',
        data: this.checkDataAvaliable(data[1].sumTotalList)
          ? data[1].sumTotalList
          : [],
      });
    }
    this.apexColumnChart.series = seriesArr;
  }

  searchChart() {
    this.blockUI.start();

    let searchYearFrom: string = moment({ year: this.fromYear })
      .startOf('year')
      .toISOString();
    let searchYearTo: string = moment({ year: this.toYear })
      .endOf('year')
      .toISOString();

    this.apiService
      .get(this.apiPath, {
        params: {
          dateFrom: searchYearFrom,
          dateTo: searchYearTo,
        },
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          this.setChart(res.data.resultData[0].yearList);
          this.blockUI.stop();
        },
        (err) => {
          this.blockUI.stop();
        }
      );
  }

  resetSearch() {
    this.fromYear = this.currentDate.getFullYear() - 1;
    this.toYear = this.currentDate.getFullYear();

    this.apiService
      .get(this.apiPath, {
        params: {
          dateFrom: this.default_startYear,
          dateTo: this.default_endYear,
        },
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.setChart(res.data.resultData[0].yearList);
      });
  }

  exportToExcel() {
    let exportDate = new Date().toISOString();
    let yearFrom = this.fromYear
      ? moment({ year: this.fromYear }).startOf('year').toISOString()
      : this.default_startYear;
    let yearTo = this.toYear
      ? moment({ year: this.toYear }).endOf('year').toISOString()
      : this.default_endYear;

    let fileName: string = `YOY Report ${this.fileNameFromYear} And ${this.fileNameToYear}`;

    let param = {
      DateFrom: yearFrom,
      DateTo: yearTo,
      ExportDate: exportDate,
    };

    this.fileService.getExcelReport('Excel/ExportYOY', fileName, param);
  }
}

export interface YearOnYear {
  yearList: YearList[];
}

export interface YearList {
  year: number;
  sumTotalList: number[];
}

export interface MinMaxYear {
  min: string;
  max: string;
}
