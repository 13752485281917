import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { CertificationsFileService } from '../certifications-file.service';

@Component({
  selector: 'app-cert-file-create',
  templateUrl: './cert-file-create.component.html',
  styleUrls: ['./cert-file-create.component.scss']
})
export class CertFileCreateComponent implements OnInit {
  config: ICMSConfig;

  constructor(private _certFileService: CertificationsFileService) {
    this.config = this._certFileService.config;
  }

  ngOnInit(): void {
  }

}
