import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-qrcode-generator',
  templateUrl: './qrcode-generator.component.html',
  styleUrls: ['./qrcode-generator.component.scss']
})
export class QrcodeGeneratorComponent implements OnInit {

  @Input() value = 'https://example.com'; // The value for which you want to generate the QR code
  @Input() elementType = 'url'; // Specify the element type (url, canvas, img, svg)
  @Input() errorCorrectionLevel = 'M'; // Specify the error correction level (L, M, Q, H)
  @Input() margin = 4; // Specify the margin around the QR code
  @Input() scale = 4; // Specify the scale factor for the QR code size

  constructor() { }

  ngOnInit(): void {
  }

}
