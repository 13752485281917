import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import Group from '../model/Group';

@Injectable({
  providedIn: 'root',
})
export class NotificationFormConfig {
  dataList: Group[];

  maxLengthOfValue: number = 999999999.9999;

  summernoteNewsConfig: object = {
    placeholder: 'Begin typing here...',
    tabsize: 2,
    height: '400px',
    disableDragAndDrop: true,
    toolbar: [
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['strikethrough', 'superscript', 'subscript']],
      ['fontsize', ['fontsize']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['height', ['height']],
      ['insert', ['hr','link', 'picture', 'video']],
      ['view', ['codeview']],
    ],
    callbacks: {
      onPaste: function (e) {
        var bufferText = (
          (e.originalEvent || e).clipboardData || window.ClipboardItem
        ).getData('Text');
        e.preventDefault();
        document.execCommand('insertText', false, bufferText);
      },
    },
  };
}