import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailService {

  componentName: string = 'Product Detail';
  apiPath: string = 'ItemContent';
  pathUrl: string = '/cms/product-detail';

  constructor() { }
}
