<div class="lang-switcher-sidebar nav-vertical" *ngIf="isUseMultipleLang">
  <ul ngbNav class="nav nav-pills flex-column text-nowrap mb-0">
    <li ngbNavItem *ngFor="let lang of langList">
      <a ngbNavLink (click)="selectedLanguage(lang)" [class.active]="selectedLang === lang">{{ lang | uppercase }}</a>
    </li>
  </ul>
</div>

<div class="col-md-12">
  <form [formGroup]="highlightTechForm">

    <h4 class="mb-2">{{ "BreadCrumbs.CMS.RepoveTools.HydroethosomeHighlightTech.Title" | translate }}</h4>

    <div class="form-group row">
      <label for="colFormLabelLg" class="col-sm-3 col-form-label">{{ "Form.Cover" | translate }}
        <span class="text-danger">*</span></label>
      <div class="col-sm-9">
        <app-image-upload #imageUploadComponent></app-image-upload>
      </div>
    </div>

    <!-- <div formGroupName="name"> -->
    <!-- <div *ngFor="let lang of langList"> -->
    <div class="form-group row">
      <div class="col-sm-3 col-form-label">
        <label for="colFormLabelLg">
          <!-- {{ "Form.Description" | translate }} -->
          {{ "BreadCrumbs.CMS.RepoveTools.HydroethosomeHighlightTech.Name" | translate }}
          <span class="text-danger">*</span>
        </label>
      </div>
      <div class="col-sm-9">
        <input type="text" class="form-control" formControlName="name" rows="5" [ngClass]="{
                    'is-invalid error':
                  (isSubmit && f.name?.invalid) ||
                  (f.name?.invalid && (f.name?.dirty || f.name?.touched))
                  }" [placeholder]="'Form.EnterDescription' | translate">
        <div class="invalid-feedback">
          <div *ngIf="
                      isSubmit ||
                      (f.name.invalid &&
                        (f.name.dirty || f.name.touched))
                    ">
            {{ "Form.EnterName" | translate }}
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->

    <div formGroupName="title">
      <div *ngFor="let lang of langList">
        <div class="form-group row" [class.d-none]="selectedLang != lang">
          <div class="col-sm-3 col-form-label">
            <label for="colFormLabelLg">
              {{ "BreadCrumbs.CMS.RepoveTools.HydroethosomeHighlightTech.Subtitle" | translate }}
              <span class="text-danger">*</span>
            </label>
            <br />
            <div *ngIf="langList.length > 1" class="badge badge-primary">
              {{ lang | uppercase }}
            </div>
          </div>
          <div class="col-sm-9">
            <input type="text" class="form-control" [formControlName]="lang" rows="5" [ngClass]="{
                    'is-invalid error':
                      (isSubmit && f.title?.invalid) ||
                      (f.title?.invalid &&
                        (f.title?.dirty || f.title?.touched))
                  }" [placeholder]="'Form.EnterDescription' | translate">
            <div class="invalid-feedback">
              <div *ngIf="
                      isSubmit ||
                      (f.title.invalid &&
                        (f.title.dirty || f.title.touched))
                    ">
                {{ "Handle.DescriptionAllLanguage" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div formGroupName="subTitle">
      <div *ngFor="let lang of langList">
        <div class="form-group row" [class.d-none]="selectedLang != lang">
          <div class="col-sm-3 col-form-label">
            <label for="colFormLabelLg">
              {{ "BreadCrumbs.CMS.RepoveTools.HydroethosomeHighlightTech.Description" | translate }}
              <span class="text-danger">*</span>
              <br />
            </label>
            <br />
            <div *ngIf="langList.length > 1" class="badge badge-primary">
              {{ lang | uppercase }}
            </div>
          </div>
          <div class="col-sm-9">
            <textarea type="textarea" class="form-control" [formControlName]="lang" rows="5" [ngClass]="{
                  'is-invalid error':
                    (isSubmit && f.subTitle?.invalid) ||
                    (f.subTitle?.invalid &&
                      (f.subTitle?.dirty || f.subTitle?.touched))
                }" [placeholder]="'Form.EnterDescription' | translate">
            </textarea>
            <div class="invalid-feedback">
              <div *ngIf="
                    isSubmit ||
                    (f.subTitle.invalid &&
                      (f.subTitle.dirty || f.subTitle.touched))
                  ">
                {{ "Handle.DescriptionAllLanguage" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <hr />


    <h4 class="mb-2">{{ "BreadCrumbs.CMS.RepoveTools.HydroethosomeHighlightTech.Name" | translate }}</h4>

    <div formArrayName="detail" #multipleDescriptionContent>
      <ng-container *ngFor="let dt of arrayForm.controls;let i = index">
        <div [formGroupName]="i">
          <!-- {{i}} -->
          <div class="form-group row">
            <div class="col-md-11">
              <div class="form-group row">
                <label for="colFormLabelLg" class="col-sm-3 col-form-label">
                  {{ "BreadCrumbs.CMS.RepoveTools.HydroethosomeHighlightTech.Icon" | translate }} {{i+1}}
                </label>
                <div class="col-sm-9">
                  <app-image-upload #iconImageUploadComponent id="iconImage{{i}}"></app-image-upload>
                </div>
              </div>
              <ng-container *ngFor="let lang of langList;">
                <div [class.d-none]="selectedLang != lang">
                  <div class="form-group row" formGroupName="description">
                    <div class="col-sm-3">
                      <p class="col-form-label">{{ "BreadCrumbs.CMS.RepoveTools.HydroethosomeHighlightTech.Description"
                        | translate }} {{i+1}}</p>
                      <div class="badge badge-primary">
                        {{ lang | uppercase }}
                      </div>
                    </div>
                    <div class="col-sm-9">
                      <textarea class="form-control" [formControlName]="lang"
                        [placeholder]="'Form.EnterDescription' | translate"></textarea>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="col-sm-1">
              <a *ngIf="highlightTechForm.controls.detail?.length > 1" class="remove-button btn btn-primary"
                (click)="removeRow(i)">
                <i data-feather="x"></i>
              </a>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="row">
      <div class="col-sm-4"></div>
      <div class="col-sm-4" style="text-align-last: center; padding-bottom: 10px">
        <a class="btn btn-outline-primary mr-1 text-primary" (click)="addItemRowButton()">
          <i data-feather="plus"></i>{{ "DocTable.Button.AddItem" | translate }}
        </a>
      </div>
      <div class="col-sm-4"></div>
    </div>

    <div class="auto-form-footer">
      <a (click)="openCancelModal()" class="btn btn-secondary">{{'Form.Cancel' | translate}}</a>
      <a (click)="openConfirmModal();" class="btn btn-primary">{{'Form.Submit' | translate}}</a>
    </div>

  </form>
</div>