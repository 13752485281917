import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SellerManageRegulationComponent } from './seller-manage-regulation.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';

const path: string = 'cms/seller-manage-regulation';
const routes: Routes = [
  {
    path: path,
    component: SellerManageRegulationComponent,
    canActivate: [AuthGuard],
    data: { animation: 'seller-manage-regulation' },
  },
];

@NgModule({
  declarations: [SellerManageRegulationComponent],
  imports: [CommonModule, RouterModule.forChild(routes), CmsFormpageModule],
  exports: [SellerManageRegulationComponent],
})
export class SellerManageRegulationModule {}
