class Banner {
  id?: any;
  name: string;
  title: string;
  subTitle: string;
  linkBtnCaption: string;
  linkBtnUrl: string;
  formFiles: File[];
  isActive: boolean;
  updateDate: Date;
}

export default Banner;
