import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShoppingCartComponent } from './shopping-cart.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';

const path: string = 'cms/shopping-cart';
const routes: Routes = [
  {
    path: path,
    component: ShoppingCartComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-about' },
  },
];

@NgModule({
  declarations: [ShoppingCartComponent],
  imports: [CommonModule, RouterModule.forChild(routes), CmsFormpageModule],
  exports: [ShoppingCartComponent],
})
export class ShoppingCartModule {}
