<!-- Error page-->
<div class="misc-wrapper">
  <a class="brand-logo" href="javascript:void(0);">
    <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />
  </a>
  <div class="misc-inner p-2 p-sm-3">
    <div class="w-100 text-center">
      <h1 class="text-primary" style="font-size: 12rem;">404</h1>
      <h2 class="mb-1">Page Not Found</h2>
      <p class="mb-3">
        The requested URL was not found on this server.
      </p>
      <a class="btn btn-primary mb-2 btn-sm-block" routerLink="/" rippleEffect
        >Back to Home</a
      >
    </div>
  </div>
</div>
<!-- / Error page-->
