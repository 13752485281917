<div class="card">
  <div class="card-body">
    <div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
      <app-loading></app-loading>
    </div>

    <div *ngIf="!isLoading" class="row">
      <div class="col-md-12">
        <form
          class="validate-form"
          [formGroup]="changePasswordForm"
          (ngSubmit)="Submit()"
        >
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="account-old-password">{{
                  "User.OldPassword" | translate
                }}</label>
                <div class="input-group form-password-toggle input-group-merge">
                  <input
                    type="password"
                    class="form-control form-control-merge"
                    id="account-old-password"
                    name="password"
                    [ngClass]="{
                      'is-invalid error':
                        isSubmitted &&
                        (changePasswordForm.controls.oldPassword?.invalid ||
                          isOldPassinCorrect)
                    }"
                    placeholder="Old Password"
                    formControlName="oldPassword"
                  />
                </div>
                <small
                  class="form-text text-danger"
                  *ngIf="
                    isSubmitted &&
                    changePasswordForm.controls.oldPassword?.invalid
                  "
                  >{{ "PROFILE.FORM_REQUIRED" | translate }}</small
                >
                <small
                  class="form-text text-danger"
                  *ngIf="isSubmitted && isOldPassinCorrect"
                  >{{ "PROFILE.OLDPASS_INCORRECT" | translate }}</small
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="account-new-password">{{
                  "User.NewPassword" | translate
                }}</label>
                <div class="input-group form-password-toggle input-group-merge">
                  <input
                    type="password"
                    id="account-new-password"
                    name="new-password"
                    class="form-control form-control-merge"
                    [ngClass]="{
                      'is-invalid error':
                        isSubmitted &&
                        changePasswordForm.controls.newPassword?.invalid
                    }"
                    placeholder="New Password"
                    formControlName="newPassword"
                    (keyup)="onKeySearch($event)"
                  />
                </div>
                <small
                  class="form-text text-danger"
                  *ngIf="
                    isSubmitted &&
                    changePasswordForm.controls.newPassword?.invalid
                  "
                  >{{ "PROFILE.FORM_REQUIRED" | translate }}</small
                >
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="account-retype-new-password">{{
                  "User.ReNewPassword" | translate
                }}</label>
                <div class="input-group form-password-toggle input-group-merge">
                  <input
                    type="password"
                    class="form-control form-control-merge"
                    id="account-retype-new-password"
                    name="confirm-new-password"
                    placeholder="New Password"
                    [(ngModel)]="confPassword"
                    [ngModelOptions]="{ standalone: true }"
                    [ngClass]="{
                      'is-invalid error':
                        (isSubmitted &&
                          (confPassword == null ||
                            confPassword == undefined)) ||
                            !isConfPasswordMatch
                    }"
                    (keyup)="onKeySearch($event)"
                  />
                </div>
                <small class="form-text text-danger" *ngIf="!isConfPasswordMatch">{{
                  "PROFILE.PASS_NOTMATCH" | translate
                }}</small>
              </div>
            </div>
            <br />


          </div>


            <div class="d-flex justify-content-between">
              <button class="btn btn-secondary" (click)="openCancelModal()">
                {{ "Form.Cancel" | translate }}
              </button>

              <button class="btn btn-primary" (click)="openConfirmModal()">
                {{ "Form.Submit" | translate }}
              </button>
            </div>

        </form>
      </div>
    </div>
  </div>
</div>
