export const PaymentAction = {
    BankAccount: {
        Create: 'addBankAccount',
        Edit: 'editBankAccount'
    },
    CreditDebitCard: {
        Create: 'addCreditDebitCard',
        Edit: 'editCreditDebitCard'
    }
  }
