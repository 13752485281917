import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { ChartOptions } from '../executive-summary.component';
import { style } from '@angular/animations';
import { ApiService } from 'app/main/service/api.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonResponse } from 'app/main/model/CommonResponse';
import { DatePipe } from '@angular/common';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FilesApiService } from 'app/main/service/files-api.service';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-conversion-rate-chart',
  templateUrl: './conversion-rate-chart.component.html',
  styleUrls: ['./conversion-rate-chart.component.scss'],
})
export class ConversionRateChartComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  @ViewChild('apexColumnChartRef') apexColumnChartRef: any;
  public apexColumnChart: Partial<ChartOptions>;
  @ViewChild('newMemberApexColumnChartRef') newMemberApexColumnChartRef: any;
  public newMemberApexColumnChart: Partial<ChartOptions>;
  @BlockUI() blockUI: NgBlockUI;
  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'range',
  };

  apiPath: string = 'Dashboard/ExecutiveSummary/ConversionRate';
  timeConvertObj: Date[] = [];

  chartColors = {
    column: {
      series1: '#E03E1A',
      series2: '#0C8CE9',
      series3: '#198F51',
    },
    success: {
      shade_100: '#7eefc7',
      shade_200: '#06774f',
    },
    donut: {
      series1: '#ffe700',
      series2: '#00d4bd',
      series3: '#826bf8',
      series4: '#2b9bf4',
      series5: '#FFA1A1',
    },
    area: {
      series3: '#a4f8cd',
      series2: '#60f2ca',
      series1: '#2bdac7',
    },
  };

  isInit: boolean = false;
  memberLineData: any;
  memberChartData: any;
  months: { value: number; name: string }[] = [
    { value: 0, name: 'January' },
    { value: 1, name: 'February' },
    { value: 2, name: 'March' },
    { value: 3, name: 'April' },
    { value: 4, name: 'May' },
    { value: 5, name: 'June' },
    { value: 6, name: 'July' },
    { value: 7, name: 'August' },
    { value: 8, name: 'September' },
    { value: 9, name: 'October' },
    { value: 10, name: 'November' },
    { value: 11, name: 'December' },
  ];
  ngDate: Date[];
  startDate: string;
  endDate: string;
  yearList: any[] = [];
 

  conversionData: any;

  selectedEndMonthOptions: any;
  selectedEndYearOptions: any;

  currentDate: Date = new Date();
  default_startDate: string = moment(this.currentDate)
    .startOf('month')
    .subtract(11, 'months')
    .toISOString();
  default_endDate: string = moment(this.currentDate)
    .endOf('month')
    .toISOString();

    selectedStartMonth: string = '';
    selectedEndMonth: string = '';
    selectedStartYear: number;
    selectedEndYear: number;
    minYear: any;
    maxYear: any;

  constructor(
    private apiService: ApiService,
    private _translateService: TranslateService,
    private _globalFuncService: GlobalFuncService,
    private _fileApiService: FilesApiService
  ) {
    this.setDefaultChart();
    this.getDropdownYear();
  }

  ngOnInit(): void {
    this.apiService
      .GetAllData(this.apiPath, {
        DateFrom: this.default_startDate,
        DateTo: this.default_endDate,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: CommonResponse<ConversionRate>) => {
        this.conversionData = res.data.resultData[0];
        this.timeConvertObj = res.data.resultData[0].categoriesDateTimeList.map(
          (date) => new Date(date)
        );
        console.log(this.timeConvertObj);
        this.setDefaultChart();
        this.setChart(res.data.resultData[0]);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.apexColumnChart = null;
    this.newMemberApexColumnChart = null;
  }

  DrawChart() {
    this.apexColumnChart.chart.width =
      this.apexColumnChartRef?.nativeElement.offsetWidth;

    window.dispatchEvent(new Event('resize'));
    this.isInit = true;
  }

  setDefaultChart() {
    var self = this;
    this.apexColumnChart = {
      series: [
        {
          name: 'New Member Booking',
          group: 'Existing Member',
          color: '#1BCFB4',
          data: [280, 280, 320, 410, 490],
        },
        {
          name: 'Existing Member Booking',
          group: 'Existing Member',
          color: '#0134a1',
          data: [280, 280, 320, 410, 490],
        },
      ],

      chart: {
        group: undefined,
        type: 'bar',
        height: 400,
        stacked: true,

        toolbar: {
          show: false,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
      },
      plotOptions: {
        bar: {
          columnWidth: '30px',
          colors: {
            backgroundBarRadius: 10,
          },
        },
      },
      colors: [
        this.chartColors.column.series1,
        this.chartColors.column.series2,
        this.chartColors.column.series3,
      ],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        title: {
          text: 'Month/Year',
        },
        categories: [this.timeConvertObj],
      },
      yaxis: {
        title: {
          text: 'Event',
        },
        labels: {
          formatter: function (value) {
            // return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' Baht';
            return self._globalFuncService.FormatToMoney(value, false, true);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (value) {
            // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' Baht';
            return (
              self._globalFuncService.FormatToMoney(value, false, true) +
              ' Event'
            );
          },
        },
      },
      noData: {
        text: 'No data available.',
        align: 'center',
        verticalAlign: 'middle',
        offsetY: -20,
        offsetX: 20,
      },
    };

    this.newMemberApexColumnChart = {
      series: [
        {
          name: 'New Member',
          group: 'New Member',
          color: '#1BCFB4',
          data: [305, 220, 335, 420, 550],
        },
        {
          name: 'Existing Member',
          group: 'Existing Member',
          color: '#0134a1',
          data: [305, 220, 335, 420, 550],
        },
      ],

      chart: {
        group: undefined,
        type: 'bar',
        height: 400,
        stacked: true,

        toolbar: {
          show: false,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
      },
      plotOptions: {
        bar: {
          columnWidth: '30px',
          colors: {
            backgroundBarRadius: 10,
          },
        },
      },
      colors: [
        this.chartColors.column.series1,
        this.chartColors.column.series2,
        this.chartColors.column.series3,
      ],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        title: {
          text: 'Month/Year',
        },
        categories: [this.timeConvertObj],
      },
      yaxis: {
        title: {
          text: 'Member',
        },
        labels: {
          formatter: function (value) {
            // return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' Baht';
            return self._globalFuncService.FormatToMoney(value, false, true);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (value) {
            // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' Baht';
            return (
              self._globalFuncService.FormatToMoney(value, false, true) +
              (Math.round(value) > 1 ? ' Members' : ' Member')
            );
          },
        },
      },
      noData: {
        text: 'No data available.',
        align: 'center',
        verticalAlign: 'middle',
        offsetY: -20,
        offsetX: 20,
      },
    };
  }

  setChart(data: ConversionRate) {
    this.apexColumnChart.series = [
      {
        name: 'New Member Booking',
        group: 'Existing Member',
        color: '#1BCFB4',
        data: this.checkDataAvaliable(data.columnChart.memberBooking)
          ? data.columnChart.memberBooking
          : [],
      },
    ];

    this.newMemberApexColumnChart.series = [
      {
        name: 'New Member',
        group: 'New Member',
        color: '#1BCFB4',
        data: this.checkDataAvaliable(data.columnChart.newMember)
          ? data.columnChart.newMember
          : [],
      },
      {
        name: 'Existing Member',
        group: 'Existing Member',
        color: '#0134a1',
        data: this.checkDataAvaliable(data.columnChart.existingMember)
          ? data.columnChart.existingMember
          : [],
      },
    ];

    let formattedCategories = this.timeConvertObj.map((date) => {
      let result: string = '';
      let tempdate = new Date(date);

      result = `${tempdate.toLocaleString('en-US', {
        month: 'short',
      })}/${tempdate.getFullYear()}`;
      return result;
    });
    console.log(formattedCategories);
    this.apexColumnChart.xaxis.categories = formattedCategories;
    this.newMemberApexColumnChart.xaxis.categories = formattedCategories;
  }

  checkDataAvaliable(numArr: number[]): boolean {
    return !numArr.every((value) => value === 0);
  }

  searchChart(startDate: any, endDate: any) {
    this.apiService
      .get(this.apiPath, {
        params: {
          DateFrom: startDate,
          DateTo: endDate,
        },
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: CommonResponse<ConversionRate>) => {
        this.timeConvertObj = res.data.resultData[0].categoriesDateTimeList.map(
          (date) => new Date(date)
        );
        this.setDefaultChart();
        this.setChart(res.data.resultData[0]);
      });
  }

  resetSearchCriteria() {
    // Reset all search criteria
    this.selectedStartMonth = '';
    this.selectedEndMonth = '';
    this.selectedStartYear = null;
    this.selectedEndYear = null;
    this.selectedEndMonthOptions = [];
    this.selectedEndYearOptions = [];
    this.apiService
      .GetAllData(this.apiPath, {
        DateFrom: this.default_startDate,
        DateTo: this.default_endDate,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.setDefaultChart();
        this.timeConvertObj = res.data.resultData[0].categoriesDateTimeList.map(
          (dateString) => new Date(dateString)
        );
        this.setChart(res.data.resultData[0]);
      });
  }
  onMonthChange() {
    console.log('Selected month:', parseInt(this.selectedStartMonth));
    console.log('Selected month: End', this.selectedEndMonth);

    this.selectedEndMonthOptions = this.generateEndMonthOptions(
      parseInt(this.selectedStartMonth)
    );
  }
  onStartMonthChange() {
    console.log(this.selectedStartMonth);

    this.selectedEndMonthOptions = this.generateEndMonthOptions(
      parseInt(this.selectedStartMonth)
    );
    this.selectedEndMonth = this.selectedStartMonth;
    console.log(this.selectedEndMonth);
  }
  onStartYearChange() {
    this.selectedEndYear = this.selectedStartYear;
    this.selectedEndYearOptions = this.generateEndYearOptions(
      this.selectedStartYear
    );
    this.selectedStartMonth = '0';
    this.selectedEndMonthOptions = this.generateEndMonthOptions(
      parseInt(this.selectedStartMonth)
    );
    this.selectedEndMonth = '0';
  }
  onYearChange() {
    this.selectedEndYearOptions = this.generateEndYearOptions(
      this.selectedStartYear
    );

    console.log(this.selectedStartYear, this.selectedEndYear);

    if (this.selectedStartYear === this.selectedEndYear) {
      this.selectedEndMonthOptions = this.generateEndMonthOptions(
        parseInt(this.selectedStartMonth)
      );
    } else if (this.selectedStartYear > this.selectedEndYear) {
      this.selectedEndMonth = null;
      this.selectedEndMonthOptions = this.months;
    } else {
      this.selectedEndMonthOptions = this.months;
    }

    console.log('Selected year:', this.selectedStartYear);
    console.log('Selected year:', this.selectedEndYear);
  }

  generateEndMonthOptions(
    startMonth: number
  ): { value: number; name: string }[] {
    console.log(this.selectedStartYear, this.selectedEndYear);
    // If no start month selected, enable all months
    if (!startMonth || this.selectedStartYear < this.selectedEndYear) {
      console.log(1);

      return this.months;
    }
    console.log(2);

    // Otherwise, disable past months
    return this.months.filter((month) => month.value >= startMonth);
  }
  generateEndYearOptions(startYear: number): number[] {
    // If no start year selected, enable all years
    if (!startYear) {
      return this.yearList;
    }
    // Otherwise, disable past years
    return this.yearList.filter((year) => year >= startYear);
  }

  submitDate() {
    this.startDate = moment({
      year: this.selectedStartYear,
      month: parseInt(this.selectedStartMonth),
    })
      .startOf('month')
      .toISOString();
    this.endDate = moment({
      year: this.selectedEndYear,
      month: parseInt(this.selectedEndMonth),
    })
      .endOf('month')
      .toISOString();
    this.searchChart(this.startDate, this.endDate);
  }

  getDropdownYear() {
    this.apiService
      .GetAllData(
        'Dashboard/ForSearch/DropDown/GetNewestDataAndOldest/Customer'
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res.data.resultData);
        this.minYear = new Date(res.data.resultData[0].min);
        this.maxYear = new Date(res.data.resultData[0].max);
        console.log(this.minYear.getFullYear());
        console.log(this.maxYear.getFullYear());
        this.yearList = this.getRangeOfYears(
          this.minYear.getFullYear(),
          this.maxYear.getFullYear() + 20
        );
        console.log(this.yearList);
      });
  }
  getRangeOfYears(startYear: number, endYear: number): number[] {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  }

  exportReport() {
    let path: string = 'Excel/ExportConversionRate';
    let startDate: string;
    let endDate: string;
    let startYear: string;
    let endYear: string;

    let exportTime = new Date().toISOString();

    if (this.startDate && this.endDate) {
      startDate = new Date(this.startDate).toLocaleString('en-US', { month: 'short' });
      endDate =  new Date(this.endDate).toLocaleString('en-US', { month: 'short' });
      startYear = this.selectedStartYear.toString();
      endYear = this.selectedEndYear.toString();
    } else {
      startDate = new Date(this.default_startDate).toLocaleString('en-US', { month: 'short' });
      endDate = new Date(this.default_endDate).toLocaleString('en-US', { month: 'short' });
      startYear = new Date(this.default_startDate).getFullYear().toString();;
      endYear = new Date(this.default_endDate).getFullYear().toString();;
    }
  
    let fileName: string = `Conversion Rate Report ${startDate}/${startYear}-${endDate}/${endYear}`;
    const param = {
      exportDate: exportTime,
      DateFrom: this.startDate || this.default_startDate,
      DateTo: this.endDate || this.default_endDate,
    };
  
    this._fileApiService.getExcelReport(path, fileName, param);
  }
}

export interface ConversionRate {
  categoriesDateTimeList: string[];
  columnChart: ColumnChart;
}

export interface ColumnChart {
  newMember: number[];
  existingMember: number[];
  newMemberBooking: number[];
  existingMemberBooking: number[];
  memberBooking: number[];
}
