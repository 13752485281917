import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreCommonModule } from '@core/common.module';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { StockManageComponent } from './stock-manage.component';
import { StockFormpageComponent } from './stock-formpage/stock-formpage.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { DataTablesModule } from 'angular-datatables';
import { ComponentsModule } from 'app/main/components/components.module';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { MovementTableComponent } from './stock-formpage/movement-table/movement-table.component';
import { WarehouseTableComponent } from './stock-formpage/warehouse-table/warehouse-table.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ItemDetailComponent } from './stock-formpage/item-detail/item-detail.component';
import { environment } from 'environments/environment';

const routes: Routes =!environment.menuList.stock ? [] :  [
  {
    path: 'stock',
    component: StockManageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'stock', roles: ['SuperAdmin'] },
  },
  // {
  //   path: 'stock/adjustment/create',
  //   component: StockFormpageComponent,
  //   canActivate: [AuthGuard],
  //   data: { animation: 'stock-adjustment', roles: ['SuperAdmin'] },
  // },
  {
    path: 'stock/adjustment/edit/:id',
    component: StockFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'stock-adjustment', roles: ['SuperAdmin'] },
  },
];

@NgModule({
  declarations: [
    StockManageComponent,
    StockFormpageComponent,
    MovementTableComponent,
    WarehouseTableComponent,
    ItemDetailComponent,
  ],
  imports: [
    CorePipesModule,
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    Ng2FlatpickrModule,
    NgbModule,
    NgSelectModule,
    ContentHeaderModule,
    DataTablesModule,
    ComponentsModule,
    TranslateModule,
    NgApexchartsModule,
  ],
})
export class StockManageModule {}
