import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import { DataTableDirective } from 'angular-datatables';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalManageRankComponent } from 'app/main/components/modal-manage-rank/modal-manage-rank.component';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import Customer from 'app/main/model/Customer';
import { Rank, RankEnum, RankList } from 'app/main/model/Rank';
import { ApiService } from 'app/main/service/api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-rank-manage',
  templateUrl: './rank-manage.component.html',
  styleUrls: ['./rank-manage.component.scss'],
})
export class RankManageComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  contentHeader: object;

  APCustomerList: Customer[] = [];
  MPCustomerList: Customer[] = [];

  pageAP: number = 1;
  pageMP: number = 1;
  pageSize: number = 10;

  activeTab: number = 2;
  rankEnum = RankEnum;
  rankList: Rank[] = RankList;

  isLoading: boolean = false;

  // @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;
  APdtOptions: DataTables.Settings = {};
  MPdtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  searchData: any = {
    Direction: 1,
    Page: 1,
    PageLength: 10,
    isDelete: false,
  };

  searchByCode: boolean = false;
  searchText: string = '';

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private componentService: ComponentsService
  ) {
    // this.getAPCustomerList();
    // this.getMPCustomerList();
  }

  ngOnInit(): void {
    var self = this;

    this.contentHeader = {
      headerTitle: 'MLM.RankManage',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this.APdtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: this.pageSize,
      order: [[1, 'desc']],
      ordering: true,
      serverSide: true,
      lengthMenu: [10, 25, 50, 100],
      lengthChange: true,
      dom: '<"float-left"B><"float-right"f>rt<"row"<"col-sm-4"i><"col-sm-4 text-center"l><"col-sm-4"p>>',
      ajax: (dataTablesParameters: any, callback: any) => {
        self.SetLoadingState();

        if (this.searchByCode) {
          self.searchData.detail = '';
          self.searchData.repoveCode = this.searchText;
        } else {
          self.searchData.detail = this.searchText;
          self.searchData.repoveCode = '';
        }

        let defaultSortName = '';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        } else {
          self.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }
        console.log(order?.dir);
        this.searchData.Direction = order?.dir == 'desc' ? 1 : 0;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );

        this.searchData.PageLength = dataTablesParameters.length;
        this.searchData.isDelete = false;

        this.APCustomerList = [];
        this.pageAP = this.searchData.Page;

        this.getAPCustomerList(this.searchData).then((r) => {
          callback({
            recordsTotal: r.data.itemCount,
            recordsFiltered: r.data.totalItem,
            data: [],
          });
        });
      },
    };

    this.MPdtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: this.pageSize,
      order: [[1, 'desc']],
      ordering: true,
      serverSide: true,
      lengthMenu: [10, 25, 50, 100],
      lengthChange: true,
      dom: '<"float-left"B><"float-right"f>rt<"row"<"col-sm-4"i><"col-sm-4 text-center"l><"col-sm-4"p>>',
      ajax: (dataTablesParameters: any, callback: any) => {
        self.SetLoadingState();

        if (this.searchByCode) {
          self.searchData.detail = '';
          self.searchData.repoveCode = this.searchText;
        } else {
          self.searchData.detail = this.searchText;
          self.searchData.repoveCode = '';
        }

        let defaultSortName = '';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        } else {
          self.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }
        console.log(order?.dir);
        this.searchData.Direction = order?.dir == 'desc' ? 1 : 0;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );

        this.searchData.PageLength = dataTablesParameters.length;
        this.searchData.isDelete = false;

        this.MPCustomerList = [];
        this.pageMP = this.searchData.Page;

        this.getMPCustomerList(this.searchData).then((r) => {
          callback({
            recordsTotal: r.data.itemCount,
            recordsFiltered: r.data.totalItem,
            data: [],
          });
        });
      },
    };
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  openRankManageModal(customer: Customer) {
    const modalRef = this.modalService.open(ModalManageRankComponent, {
      centered: true,
    });

    modalRef.componentInstance.customer = customer;
    modalRef.componentInstance.selectedRank = this.activeTab;
    modalRef.componentInstance.callBackFunc.subscribe(() => {
      this.modalService.dismissAll()
      this.Search()
    })
  }

  Search() {
    if (this.activeTab == this.rankEnum.ActiveProvider) {
      let APTable = this.dtElements.find((item: any) => {
        return item.el.nativeElement.id == 'APTable';
      });

      APTable.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          this.SetLoadedState();
        });
      });
    } else if (this.activeTab == this.rankEnum.MasterProvider) {
      let MPTable = this.dtElements.find((item: any) => {
        return item.el.nativeElement.id == 'MPTable';
      });

      MPTable.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          this.SetLoadedState();
        });
      });
    }

    console.log(this.dtElements);
  }

  Reset() {
    this.searchText = '';
    this.Search();
  }

  getAPCustomerList(searchData?: any): Promise<any> {
    return new Promise((resolve, rejects) => {
      this.SetLoadingState();

      this.apiService
        .GetAllData('mlm/ActiveProvider', searchData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.SetLoadedState();
            this.APCustomerList = res.data.resultData;
            resolve(res);
          },
          (err) => {
            this.SetLoadedState();
            rejects();
          }
        );
    });
  }

  getMPCustomerList(searchData?: any): Promise<any> {
    return new Promise((resolve, rejects) => {
      this.SetLoadingState();

      this.apiService
        .GetAllData('mlm/MasterProvider', searchData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.SetLoadedState();
            this.MPCustomerList = res.data.resultData;
            resolve(res);
          },
          (err) => {
            this.SetLoadedState();
            rejects();
          }
        );
    });
  }

  ChangeTab(tab: number) {
    this.activeTab = tab;
    this.searchByCode = false;
    this.searchText = '';
  }

  openApprovalRankModal(customer: Customer) {
    const modalRef = this.modalService.open(ModalComponent, {
      centered: true,
    });

    modalRef.componentInstance.title = 'ยืนยันการปรับระดับ';
    modalRef.componentInstance.detail = `คุณแน่ใจที่จะปรับระดับให้ ${
      customer.customerName
    } เป็น ${this.rankList[this.activeTab - 1].name} หรือไม่?`;
    modalRef.componentInstance.callBackFunc.subscribe(() => {
      if (this.activeTab == this.rankEnum.ActiveProvider) {
        this.blockUI.start();
        this.rankApproval(customer.id, this.rankEnum.ActiveProvider, () => {
          this.getAPCustomerList();
          this.blockUI.stop();
          this.componentService.SuccessSwal();
        });
      } else if (this.activeTab == this.rankEnum.MasterProvider) {
        this.blockUI.start();
        this.rankApproval(customer.id, this.rankEnum.MasterProvider, () => {
          this.getMPCustomerList();
          this.blockUI.stop();
          this.componentService.SuccessSwal();
        });
      }
    });
  }

  rankApproval(customerId: string, rankId: number, f: () => void) {
    this.apiService
      .UpdateDataById('mlm/RankInstantUpdate', customerId, {
        rankId: rankId,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          f();
        },
        (err) => {
          this.componentService.ErrorSwal();
        }
      );
  }

  rankApprovalEndOfPeriod(customerId: string, rankId: number, f: () => void) {
    this.apiService
      .UpdateDataById('mlm/RankUpdateEndOfPeriod', customerId, {
        changeRankTo: rankId,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          f();
        },
        (err) => {
          this.componentService.ErrorSwal();
        }
      );
  }
}
