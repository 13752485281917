import { Component, OnInit } from '@angular/core';
import { TimelineService } from './timeline.service';
import { ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  config!: ICMSConfig;
  keyName: string;

  constructor(private _timelineService: TimelineService) {
    this.config = this._timelineService.config;
    this.keyName = this._timelineService.keyName;
  }

  ngOnInit(): void {
  }

}
