<div class="modal-header">
  <h5 class="modal-title" id="exampleModalCenterTitle">
    {{ title }}
  </h5>
  <button type="button" class="close" (click)="closeModal()" aria-label="Close">
    <span aria-hidden="true">
      <i data-feather="x" size="22"></i>
    </span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <p style="word-break: break-all">{{ detail }}</p>

  <div class="custom-control custom-checkbox">
    <input
      type="checkbox"
      class="custom-control-input"
      id="isEditingRemarks"
      [(ngModel)]="isEditingRemarks"
    /><label class="custom-control-label" for="isEditingRemarks">{{
      "ContentDocument.EditRemarks" | translate
    }}</label>
  </div>

  <div>
    <div class="col-form-label">
      {{ "DocFooter.Description" | translate }}
    </div>

    <textarea
      class="form-control"
      id="label-textarea"
      rows="5"
      maxlength="400"
      [(ngModel)]="remarks"
      [disabled]="!isEditingRemarks"
      #remarkTextarea
      style="height: 200px; max-height: 300px"
    ></textarea>
    <label for=""
      >{{ "DocFooter.Validate" | translate }}
      {{ 400 - remarkTextarea.value.length }}/400</label
    >
  </div>
</div>
<div class="modal-footer justify-content-between">
  <button
    type="button"
    class="btn btn-secondary m-0"
    (click)="closeModal()"
    rippleEffect
  >
    {{ "Form.Cancel" | translate }}
  </button>
  <a
    type="button"
    class="btn btn-primary m-0"
    (click)="callBack()"
    rippleEffect
  >
    {{ isConfirm ? ("Form.Submit" | translate) : ("Form.Confirm" | translate) }}
  </a>
</div>
