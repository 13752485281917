import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Role } from 'app/auth/models';
import { ACL } from 'app/main/model/ACL';
import { ApiService } from 'app/main/service/api.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-acl',
  templateUrl: './acl.component.html',
  styleUrls: ['./acl.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AclComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject();
  private dataSubscription: Subscription


  contentHeader: object;

  aclList: ACL[];
  roleSetList: Role[];

  isLoading: boolean;

  constructor(private _apiService: ApiService) {
    this.SetLoadingState();
    this.initForm();
    this.getAclList();
    this.getRoleSet();

    if (this.aclList && this.roleSetList) {
      this.SetLoadedState();
    }
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Access Control List',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          }
        ]
      }
    }
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next()
    this._unsubscribeAll.complete()
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe()
    }
  }

  initForm(): void {}

  getAclList(): void {
    this._apiService.GetAllData('acl').pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      this.aclList = res.data.resultData;
    });
  }

  getRoleSet(): void {
    this.dataSubscription = this._apiService.GetAllData('roleSet').pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      this.roleSetList = res.data.resultData;
    });
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }
}
