<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section>
      <app-horizontal-timeline
        [timelineItems]="timelineData"
        [currentStep]="currentStep"
        [currentStepNumber]="currentStep"
        [lastItems]="{
          label: 'Cancel',
          descript: 'ยกเลิก',
          stepCount: 5
        }"
      ></app-horizontal-timeline>

      <!-- <div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
          <app-loading></app-loading>
        </div> -->
    </section>
    <section>
      <app-add-bill
        [data]="quatationObj"
        [apiPath]="apiPath"
        [docTitle]="'Document.SaleOrder'"
        [docCodeColumn]="'saleOrderCode'"
        (dataChange)="onDataChange($event)"
        (submitData)="handleAddBillSubmit($event)"
        [isSellerDoc]="true"
        #addBillComponent
      ></app-add-bill>
    </section>
  </div>
</div>
