import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { AccountApiService } from 'app/main/service/account-api.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit {
  componentName: string;
  contentHeader: object;

  isSuperAdmin: boolean;

  user: User;

  isLoading: boolean;

  constructor(
    private _accountApiService: AccountApiService,
    private _translateService: TranslateService,
    private _authService: AuthenticationService,
    private _modalService: NgbModal,
    private _componentsService: ComponentsService
  ) {
    this.componentName = this._translateService.instant('User.AccountSettings');
    this.isSuperAdmin = this._authService.isSuperAdmin;
  }

  openConfirmModal(content: any): void {
    let isConfirm = true;
    let title = `Confirm  Content`;
    let detail = `Are you confirm to this `;

    this.openModal(title, detail, isConfirm);
  }

  openCancelModal(content: any): void {
    let isConfirm = false;
    let title = `Cancel  Content`;
    let detail = `Are you sure to cancel this `;

    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.isConfirm = IsConfirm;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.confirmUrl = '';
    modalRef.componentInstance.submitFunc.subscribe((res) => {
      if (IsConfirm) {
        this.Submit();
      }
    });
  }

  Submit(): void {
    this._accountApiService.UpdateAccount(this.user).subscribe((res) => {
      if (res.success) {
        this._componentsService.SuccessSwal();
        window.location.reload();
      } else {
        this._componentsService.ErrorSwal();
      }
    });
  }

  ngOnInit(): void {
    this.user = this._authService.currentUserValue;
    console.log(this.user);

  }
}
