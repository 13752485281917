import {
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TimelineItem } from 'app/main/components/horizontal-timeline/horizontal-timeline.component';
import { DocumentService } from '../../document.service';
import { AuthenticationService } from 'app/auth/service';
import { User } from 'app/auth/models';
import { TranslateService } from '@ngx-translate/core';

import { Invoice, InvoiceItem, TempRowInvoice } from 'app/main/model/Invoice';

import { Receipt } from 'app/main/model/Receipt';

import { ApiService } from 'app/main/service/api.service';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { CalculateFuncService } from 'app/main/service/calculate-func.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss'],
})
export class InvoiceDetailComponent
  implements OnInit, AfterViewInit, OnChanges
{
  contentHeader: object;
  typeDocName: string;
  currentUser: User;
  timelineData: TimelineItem[];

  invoiceObj: Invoice;
  customerAddressObj: CustomerAddress;
  receiptObj: Receipt;

  orderId: string;
  orderStatus: any;
  currentStep: number;

  apiPath: string;
  editPathUrl: string;

  docID: string;
  docStatus: number;
  isLoading: boolean;
  isJoinIV_TX_RE: boolean;

  sumNetPrice: number;
  sumVatPrice: number;
  titleDoc: string = 'ใบแจ้งหนี้';

  isCreateTX: boolean

  refDetailUrl: string;
  detailPathUrl: string;
 
  @BlockUI() blockUI: NgBlockUI;




  constructor(
    private _documentService: DocumentService,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _documentApiService: DocumentApiService,
    public _route: ActivatedRoute,
    public _router: Router,
    private currencyPipe: CurrencyPipe,
    private _apiService: ApiService,
    private _calculateService: CalculateFuncService,
    private _globalService: GlobalFuncService
  ) {
    this.timelineData = this._documentService.timelineData;
    this.apiPath = this._documentService.invoice.ApiPath;
    this.editPathUrl = this._documentService.invoice.EditPathURL;
    this.detailPathUrl = this._documentService.invoice.DetailPathURL;
    this.refDetailUrl = this._documentService.saleOrder.DetailPathURL;
    this.isJoinIV_TX_RE = environment.transaction.isJoinIV_TX_RE;

    
  }

  ngOnChanges(changes: SimpleChanges): void {
   
  }

  SetLoadingState() {
    this.isLoading = true;
  }
  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;
    this.initData();
    this._authenticationService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    this.contentHeader = {
      headerTitle: this.isJoinIV_TX_RE
        ? 'Document.IV_TX_RE'
        : 'Document.Invoice',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Document',
            isLink: true,
            link: '/',
          },
          {
            name: this.isJoinIV_TX_RE
              ? 'Document.IV_TX_RE'
              : 'Document.Invoice',
            isLink: true,
            link: '/document/invoice/view',
          },
          {
            name: 'FormName.InvoiceDetail',
            isLink: false,
            link: '/document/invoice/view',
          },
        ],
      },
    };
  }
  
  activeTab: string = 'detail';
  @ViewChild('navFilled') nav: NgbNav;
  public active_fragment: BehaviorSubject<string> = new BehaviorSubject('');
  
  ngAfterViewInit(): void {
    this._route.fragment.subscribe((frag) => {
      this.active_fragment.next(frag);
      
      console.log(this.active_fragment.value)
      if (!this.active_fragment.value) {
        this.nav.select('detail')
        this.activeTab = 'detail'
        console.log('select default page')
      } else {
        this.activeTab = this.active_fragment.value
      }
      
    });
  }

  //fix path
  initData(): void {
    this.SetLoadingState();
    this.blockUI.start();
    // this.activeTab = 'detail';
    this.typeDocName = 'invoiceCode'; //code
    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
    });

    this.getInvoiceData();
  }

  refreshData($event) {
    console.log('refresh Data in parent');
    console.log($event);
    this.initData();
  }

  //getinvoicepage
  getInvoiceData(): Promise<any> {
    var self = this;
    this.invoiceObj = null;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocData(this.apiPath, this.docID)
        .subscribe((res) => {
          self.invoiceObj = res.data.resultData[0];
          self.getCustomerAddress(self.invoiceObj.customerId);
          self.calculateSummary();
          self.SetLoadedState();

          this.docStatus = self.invoiceObj.transactionStatusId;
          console.log(this.docStatus);

          this.orderId = self.invoiceObj.orderId;
          self.isCreateTX = self.invoiceObj.isTaxInvoiceCreatable;
          console.log('isCreateTX: ', self.isCreateTX);
          

          this.getOrderStatus(this.orderId).subscribe((orderStatusRes) => {
            this.orderStatus = orderStatusRes.data.resultData;
            this.blockUI.stop();
            console.log('order status : ', this.orderStatus[0]);

            this.currentStep = this._globalService.GetCurrentStep(
              this.orderStatus
            );
          });
        });
    });
  }

  getOrderStatus(orderId: string): Observable<any> {
    return this._apiService.GetDataById('Order/State', orderId);
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData.find((it) => {
            return it['useForShipping'] == true;
          });
        });
    });
  }

  //change model
  calculateSummary() {
    let itemList = [];

    this.invoiceObj.item.forEach((element, index) => {
      let tempRow: TempRowInvoice = new TempRowInvoice();
      tempRow.rowType = 'item';
      tempRow.itemId = element.itemId;
      tempRow.description = element.description[0].description;
      tempRow.discount = element.discount;
      tempRow.quantity = element.quantity;
      tempRow.unit = element.unit;
      tempRow.unitPrice = element.unitPrice;
      tempRow.whtRate = 0.0;

      itemList.push(tempRow);
    });

    this.sumNetPrice = this._calculateService.calculateNet(
      itemList,
      true,
      this.invoiceObj.taxRate,
      this.invoiceObj.discount
    );

    this.sumVatPrice = this._calculateService.calculateVat(
      this.sumNetPrice,
      this.invoiceObj.taxRate
    );
  }

  convertNumbertoMoneyFormat(value: number): string {
    return this._globalService.FormatToMoney(value);
  }

  handlePaymentDetailSubmit(data: any): void {
    this.initData();
  }

  handlePaymentDetailRefresh(eventData: any) {
    // Do whatever you need to do when the refreshData event is triggered

    this.initData();
  }
}
