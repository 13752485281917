class Group {
  id: any;
  groupCode: string;
  name: string;
  mediaFiles?: any;
  isActive: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
}

export default Group;
