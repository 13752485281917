class CookiePolicy {
  id: any;
  name: string;
  title: string;
  subTitle: string;
  detail: string;
  linkBtnCaption: string;
  linkBtnUrl: string;
  updateDate: string;
}

export default CookiePolicy;
