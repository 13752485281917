import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrandService {
  componentName: string = 'BreadCrumbs.Manage.Brand';
  apiUrl: string = 'brand';
  pathUrl: string = '/item-manage/brand';

  itemcomponentName: string = 'Item';
  itemApiUrl: string = 'item';
  itemPathUrl: string = '/item-manage/item';
  constructor() { }
}
