import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { disableCursor } from '@fullcalendar/angular';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/main/service/api.service';
import flatpickr from 'flatpickr';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { ImageUploadComponent } from '../../image-upload/image-upload.component';
import { filesPropNameConfig } from 'app/main/config/MainConfig';
import { FilesApiService } from 'app/main/service/files-api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-branch-manage-form',
  templateUrl: './branch-manage-form.component.html',
  styleUrls: ['./branch-manage-form.component.scss'],
})
export class BranchManageFormComponent implements OnInit {
  @Output() callBackFormData: EventEmitter<any> = new EventEmitter();

  @Input() isSubmit: boolean = false;
  @Input() editData: any;

  @Input() isEditing: boolean;
  ImageUploadComponent = {
    selected: false, // Replace this with the actual logic or value that sets the 'selected' variable in the ImageUploadComponent
  };
  filesPropName = filesPropNameConfig;
  @ViewChild('imageUploadComponent') imageUploadComponent: ImageUploadComponent;
  @BlockUI() blockUI: NgBlockUI;

  colorList: any[];
  colorClicked: number;
  branchForm: FormGroup;
  isLoadingProvince: boolean = false;
  isLoadingDistrict: boolean = false;
  isLoadingSubDistrict: boolean = false;
  provinceList: any[] = [];
  districtList: any[] = [];
  subDistrictList: any[] = [];
  provinceName: string;
  districtName: string;
  subDistrictName: string;
  usedWeekday: boolean = false;
  usedWeekend: boolean = false;
  currentLang: string;
  branchObj: any;
  images: Blob[] = [];


  public timeOptions: FlatpickrOptions = this.createFlatpickrOptions();
  public endTimeOptions: FlatpickrOptions = this.createFlatpickrOptions();
  public weekendEndTimeOptions: FlatpickrOptions = this.createFlatpickrOptions();
  public weekendStartTimeOptions: FlatpickrOptions = this.createFlatpickrOptions();

  createFlatpickrOptions(): FlatpickrOptions {
    return {
      enableTime: true,
      time_24hr: true,
      timeFormat: 'H:i',
      format: 'H:i',
      noCalendar: true,
      altInput: true,
      altInputClass: 'form-control flat-picker flatpickr-input',
      disabled: true,
      readOnly: true,
      allowInput: true,
    };
  }


  onTimeChange(event) {
    console.log(this.branchForm.value.weekdayStartTime)
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _apiService: ApiService,
    private _translateService: TranslateService,
    private _fileService: FilesApiService,
  ) {
    this.colorList = [
      { id: 1, name: 'Strong cyan', colorCode: '#00B4D8' },
      { id: 2, name: 'Vivid blue', colorCode: '#1877F2' },
      { id: 3, name: 'Strong blue', colorCode: '#0048b9' },
      { id: 4, name: 'Dark blue', colorCode: '#19468D' },
      { id: 5, name: 'Dark violet', colorCode: '#3A0CA3' },
      { id: 6, name: 'Strong violet', colorCode: '#7209B7' },
      { id: 7, name: 'Vivid pink', colorCode: '#F72585' },
      { id: 8, name: 'Strong red', colorCode: '#D62828' },
      { id: 9, name: 'Orange', colorCode: '#F77F00' },
      { id: 10, name: 'Vivid orange', colorCode: '#FAA307' },
      { id: 11, name: 'Bright orange', colorCode: '#FCBF49' },
      { id: 12, name: 'Strong green', colorCode: '#7CB518' },
    ];
    this.branchForm = this._formBuilder.group({
      name: ['', Validators.required],
      addressDetail: ['', Validators.required],
      provinceId: ['', Validators.required],
      districtId: ['', Validators.required],
      subDistrictId: ['', Validators.required],
      postCode: [''],
      tel: ['', Validators.required],
      email: ['', Validators.required],
      usedWeekday: [false],
      weekdayStartTime: [null],
      weekdayEndTime: [null],
      usedWeekend: [false],
      weekendStartTime: [null],
      weekendEndTime: [null],
      openingTimes: [''],
      openingTimesNormaldays: [''],
      openingTimesHolidays: [''],
      googleMapUrl: ['', Validators.required],
      branchHexColor: [0, Validators.required],
    });
    this.currentLang = this._translateService.currentLang;
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.getProvince();
    this.getDistrict(this.editData.provinceId);
    this.getSubDistrict(this.editData.districtId);
    console.log('Branch Form Editttttt', this.isEditing);
    console.log(this.editData);
    this.colorClicked = this.getColorIdByCode(this.editData.branchHexColor);
    if (this.editData && this.isEditing) {
      this.branchForm.updateValueAndValidity();
      this.branchForm.patchValue({
        name: this.editData.name,
        addressDetail: this.editData.addressDetail,
        provinceId: this.editData.provinceId,
        districtId: this.editData.districtId,
        subDistrictId: this.editData.subDistrictId,
        postCode: this.editData.postCode,
        tel: this.editData.tel,
        email: this.editData.email,
        googleMapUrl: this.editData.googleMapUrl,
        branchHexColor: this.editData.branchHexColor,
      });
      const openingTimesData = JSON.parse(this.editData.openingTimes);
      this.timeOptions.defaultDate = openingTimesData?.weekdayStartTime ? new Date(openingTimesData.weekdayStartTime) : null;
      this.endTimeOptions.defaultDate = openingTimesData?.weekdayEndTime ? new Date(openingTimesData?.weekdayEndTime) : null;
      this.weekendStartTimeOptions.defaultDate = openingTimesData?.weekendStartTime ? new Date(openingTimesData?.weekendStartTime) : null;
      this.weekendEndTimeOptions.defaultDate = openingTimesData?.weekendEndTime ? new Date(openingTimesData?.weekendEndTime) : null;
      this.branchForm.patchValue({
        usedWeekday: openingTimesData.usedWeekday,
        weekdayStartTime: this.timeOptions.defaultDate,
        weekdayEndTime: new Date(openingTimesData.weekdayEndTime),
        usedWeekend: openingTimesData.usedWeekend,
        weekendStartTime: new Date(openingTimesData.weekendStartTime),
        weekendEndTime: new Date(openingTimesData.weekendEndTime),
      });
      this.setBranchImage();
    }

  }
  getColorIdByCode(colorCode: string): number {
    const color = this.colorList.find((item) => item.colorCode === colorCode);
    return color ? color.id : 0;
  }
  selectColor(id: number) {
    this.colorClicked = id;
    this.branchForm.patchValue({
      branchHexColor: this.colorList.find((item) => item.id === id)?.colorCode || '#000000',
    });
  }

  ngOnDestroy(): void { }

  get f() {
    return this.branchForm.controls;
  }

  checkInvalidForm(): boolean {
    return this.branchForm.invalid;
  }

  getProvince(): Promise<any> {
    this.isLoadingProvince = true;

    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterProvince`, {
          SortPath: 'provinceName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.provinceList = res.data.resultData;
          this.isLoadingProvince = false;

          console.log('getProvince');
        }, rejects);
    });
  }

  isSelectProvice(id: string) {
    this.branchForm.get('districtId').setValue(null);
    this.branchForm.get('subDistrictId').setValue(null);
    this.branchForm.get('postCode').setValue(null);

    this.districtList = [];
    this.subDistrictList = [];
    this.getDistrict(id);
    let tempProvice = this.provinceList.find((val) => (val.id = id));
    this.provinceName = tempProvice.provinceName;
  }



  getDistrict(id): Promise<any> {
    this.isLoadingDistrict = true;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterDistrict`, {
          ProvinceId: id,
          SortPath: 'districtName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.districtList = res.data.resultData;
          this.isLoadingDistrict = false;
        }, rejects);
    });
  }

  isSelectDistrict(id: string) {
    this.branchForm.get('subDistrictId').setValue(null);
    this.branchForm.get('postCode').setValue(null);
    this.subDistrictList = [];
    this.getSubDistrict(id);
    let tempDistrict = this.districtList.find((val) => (val.id = id));
    this.districtName = tempDistrict.districtName;
  }

  getSubDistrict(id): Promise<any> {
    this.isLoadingSubDistrict = true;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterSubDistrict`, {
          districtId: id,
          SortPath: 'subDistrictName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.subDistrictList = res.data.resultData;
          this.isLoadingSubDistrict = false;

          let tempSubDistrict = this.subDistrictList.find(
            (val) => (val.id = id)
          );
          console.log(tempSubDistrict);
          this.subDistrictName = tempSubDistrict.subDistrictName;
        }, rejects);
      return true;
    });
  }

  getPostCode(event: any): Promise<any> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetDataById(`MasterSubDistrict`, event)
        .subscribe((res) => {
          console.log(res.data.resultData[0].subDistrictPostCode);
          this.branchForm.patchValue({
            postCode: res.data.resultData[0].subDistrictPostCode,
          });
          console.log(this.branchForm)
        }, rejects);
    });
  }

  isUseWeekday(event: any) {
    this.usedWeekday = this.branchForm.get('usedWeekday').value;
    console.log(this.usedWeekday)


    if (this.usedWeekday) {
      this.branchForm.get('weekdayStartTime').enable()
      this.branchForm.get('weekdayStartTime').setValidators([Validators.required])
      this.branchForm.get('weekdayEndTime').enable()
      this.branchForm.get('weekdayEndTime').setValidators([Validators.required])
    } else {
      this.branchForm.get('weekdayStartTime').disable()
      this.branchForm.get('weekdayStartTime').clearValidators()
      this.branchForm.get('weekdayStartTime').setValue(null)
      this.branchForm.get('weekdayEndTime').disable()
      this.branchForm.get('weekdayEndTime').clearValidators()
      this.branchForm.get('weekdayEndTime').setValue(null)
    }
    if (this.usedWeekday) {
      this.branchForm.get('weekendStartTime').enable()
      this.branchForm.get('weekendStartTime').setValidators([Validators.required])
      this.branchForm.get('weekendEndTime').enable()
      this.branchForm.get('weekendEndTime').setValidators([Validators.required])
    } else {
      this.branchForm.get('weekendStartTime').disable()
      this.branchForm.get('weekendStartTime').clearValidators()
      this.branchForm.get('weekendStartTime').setValue(null)
      this.branchForm.get('weekendEndTime').disable()
      this.branchForm.get('weekendEndTime').clearValidators()
      this.branchForm.get('weekendEndTime').setValue(null)
    }
    this.branchForm.get('weekdayStart').updateValueAndValidity()
    this.branchForm.get('weekdayEnd').updateValueAndValidity()
    this.branchForm.get('weekendStart').updateValueAndValidity()
    this.branchForm.get('weekendStart').updateValueAndValidity()


    //   this.usedWeekday = this.branchForm.get('usedWeekday').value;
    //   console.log(this.usedWeekday);
    //   const flatpickrInstance = flatpickr('#timeOptions')

    //   if (this.usedWeekday) {

    //     this.branchForm.get('weekdayStartTime').enable();
    //     this.branchForm.get('weekdayEndTime').enable();
    //     console.log( this.branchForm.get('weekdayStartTime').enable)



    //     this.branchForm.get('weekdayStartTime').setValidators([Validators.required]);
    //     this.branchForm.get('weekdayEndTime').setValidators([Validators.required]);
    //   } else {

    //     this.branchForm.get('weekdayStartTime').disable();
    //     this.branchForm.get('weekdayEndTime').disable();
    //     this.branchForm.get('weekdayStartTime').clearValidators();
    //     this.branchForm.get('weekdayEndTime').clearValidators();
    //   }

    //   this.branchForm.get('weekdayStartTime').updateValueAndValidity();
    //   this.branchForm.get('weekdayEndTime').updateValueAndValidity();
  }

  prepareDataForm() {
    const form = this.branchForm.controls
    const selectedColorIndex = this.colorList.findIndex(item => item.id === this.colorClicked);
    const branchHexColor = selectedColorIndex !== -1 ? this.colorList[selectedColorIndex].colorCode : null;

    this.branchForm.patchValue({
      weekdayStartTime: form.usedWeekday.value ? form.weekdayStartTime.value : null,
      weekdayEndTime: form.usedWeekday.value ? form.weekdayEndTime.value : null,
      weekendStartTime: form.usedWeekend.value ? form.weekendStartTime.value : null,
      weekendEndTime: form.usedWeekend.value ? form.weekendEndTime.value : null,
      branchHexColor: branchHexColor,
    });





    const openingTimes: object = {
      usedWeekday: form.usedWeekday.value,
      weekdayStartTime: form.weekdayStartTime.value,
      weekdayEndTime: form.weekdayEndTime.value,
      usedWeekend: form.usedWeekend.value,
      weekendStartTime: form.weekendStartTime.value,
      weekendEndTime: form.weekendEndTime.value,
    }
    const finalData = {
      ...this.branchForm.value,
      branchHexColor: branchHexColor,
      openingTimes: JSON.stringify(openingTimes),

    }
    return finalData

  }

  addBranch() {
    this.branchForm.updateValueAndValidity();

    if (this.branchForm.valid) {
      const prepareDataForm = this.prepareDataForm()
      console.log(prepareDataForm)

    } else {
      console.log('Form invalid')
    }


  }

  setBranchImage() {
    let guid = this.editData.id
    // this._apiService
    //   .GetDataById(this.apiPath, guid)
    //   .subscribe(
    //     (res) => {
          const self = this;
          // self.branchObj = res.data.resultData[0];

          if (this.editData.mediaFiles[self.filesPropName.item.coverImage]) {
            this._fileService
              .GetFile(
                this.editData.mediaFiles[self.filesPropName.item.coverImage][0]
                  .id
              )
              .subscribe(
                (res: Blob) => {
                  if (self.imageUploadComponent) {
                    self.imageUploadComponent.setCoverImage(
                      res,
                      this.editData.mediaFiles[
                        self.filesPropName.item.coverImage
                      ][0].id
                    );
                  }
                },
                (err) => {
                  this.blockUI.stop();
                }
              );
          }
        // }
      // )
  }

  getImageComponent(resId) {
    const params = {
      isEdit: this.isEditing,
      idResId: resId,
      tablename: 'branch',
      collectionName: this.filesPropName.item.coverImage,
      ordinal: 0,
    };
    this.imageUploadComponent.uploadImage(params);
  }
}
