export class Categories{
  id?: any;
  categoryname: string;
  mediaFiles?: any[]
  helpcentertitle: string;
  faqtitle: string;
  // faqdescription: CategoriesDetail[];
  faqdescription: string;
}

class CategoriesDetail {
    id?: any;
    title: string;
    description: string;
}

export default Categories

// export const categoriesList: Categories[] = [
//   {
//     id: 1,
//     title: 'aaaaa',
//     categoriesDetail: [
//       {
//         title: '1',
//         description: '1',
//       },
//       {
//         title: '2',
//         description: '2',
//       },
//       {
//         title: '3',
//         description: '3',
//       },
//     ]
//   }
// ]