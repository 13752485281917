import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormTemplateComponent } from '../../../../components/form/form-template/form-template.component';
import { CmsFormConfig } from '../../../../config/CMSFormConfig';
import Banner from '../../../../model/Banner';
import News from '../../../../model/News';
import { ApiService } from '../../../../service/api.service';
import { DocType, ICMSConfig } from 'app/main/config/CMSInterface';
import { ContentDocumentComponent } from 'app/main/components/content-document/content-document.component';

@Component({
  selector: 'app-auto-form-detail',
  templateUrl: './auto-form-detail.component.html',
  styleUrls: ['./auto-form-detail.component.scss'],
})
export class AutoFormDetailComponent implements OnInit {
  contentHeader: object;
  isLoading: boolean;
  apiPath: string;
  itemID: string;
  itemObj: any;
  config: ICMSConfig;

  @ViewChild('formTemplate') formTemplate: FormTemplateComponent;

  constructor(
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _apiService: ApiService,
    private _cmsFormConfig: CmsFormConfig
  ) {
    this.apiPath = this._route.snapshot.paramMap.get('apiPath');
    this.itemID = this._route.snapshot.paramMap.get('id');

    this.config = this._cmsFormConfig.config[this.apiPath];
    this.itemObj = this._cmsFormConfig.config[this.apiPath].model;

    if (this.itemID) {
      this.GetItemData(this.apiPath, this.itemID);
    }
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Some Item',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  GetItemData(apiPath, itemID): void {
    var self = this;

    this._apiService.GetDataById(apiPath, itemID).subscribe((res) => {
      self.itemObj = res.data.resultData[0];
      self.formTemplate.setFormValue(self.itemObj);
      console.log(self.itemObj);
    });
  }
}
