import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SvExchangeRateService {

  componentName: string = 'SV Exchange Rate'
  apiUrl: string ='svExchangeRate';
  pahtUrl:'/mlm-config/sv-exchange-rate-config';

  constructor() { }
}
