import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-bonus-plan',
  templateUrl: './bonus-plan.component.html',
  styleUrls: ['./bonus-plan.component.scss'],
})
export class BonusPlanComponent implements OnInit {
  config: ICMSConfig = {
    apiPath: 'bonusplan',
    tableName: 'Content',
    pathUrl: '/cms/repove-tools/bonus-plan',
    model: new Object(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.RepoveTools.BonusPlan.Title',
    formConfig: {
      spDescription: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Sale Period Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.BonusPlan.SalePeriodDescription',
      },
      svDescription: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Sale Value Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.BonusPlan.saleValueDescription',
      },


      apBackgroundImage: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Active Provider Background',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.BonusPlan.APBackground',
      },

      mpBackgroundImage: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Master Provider Background',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.BonusPlan.MPBackground',
      },

      apMainBanner: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Active Provider Main Banner Background',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.BonusPlan.APMainBannerBackground',
      },
      apSubBanner: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Active Provider Sub Banner Background',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.BonusPlan.APSubBannerBackground',
      },
      apSubBannerMobile: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Active Provider Sub Banner Background',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.BonusPlan.APSubBannerBackgroundMobile',
      },

      apBannerTitle: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Active Provider Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.BonusPlan.APBannerTitle',
      },
      apBannerSubtitle: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Active Provider Banner Subtitle',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.BonusPlan.APBannerSubtitle',
      },

      leftBannerImage: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Left Banner Background',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.BonusPlan.LeftBannerBackground',
      },
      rightBannerImage: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Right Banner Background',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.BonusPlan.RightBannerBackground',
      },

      leftBannerText: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Left Banner Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.BonusPlan.LeftBannerDescription',
      },
      rightBannerText: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Right Banner Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.BonusPlan.RightBannerDescription',
      },
    },
  };

  constructor() {}

  ngOnInit(): void {}
}
