import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { ConsumerReviewService } from '../consumer-review.service';

@Component({
  selector: 'app-consumer-review-create',
  templateUrl: './consumer-review-create.component.html',
  styleUrls: ['./consumer-review-create.component.scss'],
})
export class ConsumerReviewCreateComponent implements OnInit, OnDestroy {
  config: ICMSConfig;

  constructor(private _consumerReviewService: ConsumerReviewService) {
    this.config = this._consumerReviewService.config;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
