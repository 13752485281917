<div class="">
    <ul ngbNav #navFilled="ngbNav" class="nav nav-pills" [destroyOnHide]="true" [activeId]="viewMode">
        <li [ngbNavItem]="viewModeEnum.Province">
            <a ngbNavLink (click)="changeTabs(viewModeEnum.Province)" class="justify-content-start">
                {{ 'Address.province' | translate }}
            </a>
            <ng-template ngbNavContent>
                <h3 class="general-title">{{ 'Address.province' | translate }}</h3>

                <ng-container *ngTemplateOutlet="filterPage; context: {}"></ng-container>
            </ng-template>
        </li>
        <li [ngbNavItem]="viewModeEnum.District">
            <a ngbNavLink (click)="changeTabs(viewModeEnum.District)" class="justify-content-start">
                {{ 'Address.district' | translate }}
            </a>
            <ng-template ngbNavContent>
                <h3 class="general-title">{{ 'Address.district' | translate }}</h3>

                <ng-container *ngTemplateOutlet="filterPage; context: {}"></ng-container>
            </ng-template>
        </li>

        <li [ngbNavItem]="viewModeEnum.SubDistrict">
            <a ngbNavLink (click)="changeTabs(viewModeEnum.SubDistrict)" class="justify-content-start">
                {{ 'Address.subdistrict' | translate }}
            </a>
            <ng-template ngbNavContent>
                <h3 class="general-title">{{ 'Address.subdistrict' | translate }}</h3>

                <ng-container *ngTemplateOutlet="filterPage; context: {}"></ng-container>

            </ng-template>
        </li>
    </ul>

    <hr class="my-2">
    <div [ngbNavOutlet]="navFilled" style="width: -webkit-fill-available"></div>
</div>



<ng-template #filterPage>
    <ng-container *ngTemplateOutlet="filterPanal; context: {}"></ng-container>
    <hr class="my-2">
    <ng-container *ngTemplateOutlet="resultTable; context: {}"></ng-container>
</ng-template>

<ng-template #filterPanal>

    <div class="row justify-content-center mt-2">

        <div class="col-4">
            <label>{{ 'Region.Title' | translate }}</label>
            <ng-select class="w-100" [(ngModel)]="selectedRegion" (change)="onSelectRegion()" [clearable]="false">
                <ng-option [value]="0">{{ 'General.All' | translate }}</ng-option>
                <ng-option [value]="1">{{ 'Region.Northern' | translate }}</ng-option>
                <ng-option [value]="2">{{ 'Region.Central' | translate }}</ng-option>
                <ng-option [value]="3">{{ 'Region.Northeastern' | translate }}</ng-option>
                <ng-option [value]="4">{{ 'Region.Western' | translate }}</ng-option>
                <ng-option [value]="5">{{ 'Region.Eastern' | translate }}</ng-option>
                <ng-option [value]="6">{{ 'Region.Southern' | translate }}</ng-option>
            </ng-select>
        </div>

        <ng-container *ngIf="isDistrictMode() || isSubDistrictMode()">
            <div class="col-4">
                <label>{{ 'Address.province' | translate }}</label>
                <ng-select class="w-100" [(ngModel)]="selectedProvince" (change)="onSelectProvince()"
                    [clearable]="false">
                    <ng-option *ngFor="let province of provinceList" [value]="province">

                        <span *ngIf="currentLang == 'th'">
                            {{ province.provinceName }}
                        </span>
                        <span *ngIf="currentLang == 'en'">
                            {{ province.provinceNameEn }}
                        </span>
                    </ng-option>
                </ng-select>
                <p class="text-danger" *ngIf="isProvinceInvalid">***กรุณาเลือกจังหวัด***</p>
            </div>
        </ng-container>


        <ng-container *ngIf="isSubDistrictMode()">
            <div class="col-4">
                <label>{{ 'Address.district' | translate }}</label>
                <ng-select class="w-100" [(ngModel)]="selectedDistrict" [clearable]="false">
                    <ng-option *ngFor="let district of districtList" [value]="district">
                        <span *ngIf="currentLang == 'th'">
                            {{ district.districtName }}
                        </span>
                        <span *ngIf="currentLang == 'en'">
                            {{ district.districtNameEn }}
                        </span>
                    </ng-option>
                </ng-select>
                <p class="text-danger" *ngIf="isDistrictInvalid">***กรุณาเลือกอำเภอ***</p>
            </div>
        </ng-container>
    </div>

    <div class="d-flex justify-content-center mt-1">

        <button class="btn btn-secondary mr-2" (click)="onReset()">
            <i data-feather="refresh-ccw" class="mr-1"></i>{{ "General.Reset" | translate }}
        </button>


        <button class="btn btn-primary" (click)="onSearch()">
            <i data-feather="search" class="mr-1"></i>{{ "General.Search" | translate }}
        </button>

    </div>

</ng-template>


<ng-template #resultTable>
    <table class="table">
        <thead>
            <tr>
                <th>#</th>
                <th>ชื่อ (ไทย)</th>
                <th>ชื่อ (อังกฤษ)</th>
                <ng-container *ngIf="isProvinceMode()">
                    <th>{{ 'Region.Title' | translate }}</th>
                </ng-container>

                <ng-container *ngIf="isSubDistrictMode()">
                    <th>{{ 'Address.postalCode' | translate}}</th>
                </ng-container>

                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="isProvinceMode()">
                <tr *ngFor="let province of provinceList; index as ind">
                    <td>{{ ind + 1 }}</td>
                    <td>{{ province.provinceName }}</td>
                    <td>{{ province.provinceNameEn }}</td>
                    <td>{{ getRegionLabel(province.region) | translate }}</td>
                    <td>
                        <a class="btn btn-primary" (click)="openFormModal(true,province)">
                            <i data-feather="edit"></i>
                        </a>
                    </td>
                </tr>
            </ng-container>

            <ng-container *ngIf="isDistrictMode()">
                <tr *ngIf="districtList.length < 1">
                    <td colspan="100%" class="text-center">
                        <p>กรุณาเลือกจังหวัด เพิ่อค้นหาข้อมูล</p>
                    </td>
                </tr>
                <tr *ngFor="let district of districtList; index as ind">
                    <td>{{ ind + 1 }}</td>
                    <td>{{ district.districtName }}</td>
                    <td>{{ district.districtNameEn }}</td>
                    <td>
                        <a class="btn btn-primary" (click)="openFormModal(true,district)">
                            <i data-feather="edit"></i>
                        </a>
                    </td>
                </tr>
            </ng-container>

            <ng-container *ngIf="isSubDistrictMode()">
                <tr *ngIf="subDistrictList.length < 1">
                    <td colspan="100%" class="text-center">
                        <p>กรุณาเลือกเขต/อำเภอ เพิ่อค้นหาข้อมูล</p>
                    </td>
                </tr>

                <tr *ngFor="let subDistrict of subDistrictList; index as ind">
                    <td>{{ ind + 1 }}</td>
                    <td>{{ subDistrict.subDistrictName }}</td>
                    <td>{{ subDistrict.subDistrictNameEn }}</td>
                    <td>{{ subDistrict.subDistrictPostCode }}</td>
                    <td>
                        <a class="btn btn-primary" (click)="openFormModal(true,subDistrict)">
                            <i data-feather="edit"></i>
                        </a>
                    </td>
                </tr>
            </ng-container>

        </tbody>
    </table>
</ng-template>