import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import Customer from 'app/main/model/Customer';
import { ApiService } from 'app/main/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from '../../components.service';
import { CustomerService } from 'app/main/pages/customer/customer.service';
import { ModalComponent } from '../../modal/modal.component';
import { Subject } from 'rxjs';
import { ContentService } from 'app/main/pages/item-manage/content/content.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerAddrComponent } from './customer-addr/customer-addr.component';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { environment } from 'environments/environment';
import { Gender, kGender } from 'app/main/model/Gender';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { HttpClient } from '@angular/common/http';
import { SelectUserModalComponent } from '../../select-user-modal/select-user-modal.component';
import { FlatpickrOptions } from 'ng2-flatpickr';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerFormComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  componentName: string;
  registerForm: FormGroup;
  formatForm: FormGroup;

  contactAddrForm: FormGroup;
  formatContactAddrForm: FormGroup;

  otpLengthArray: number[];
  compiledOtp: string;
  DistrictActive: Boolean;
  eventLog: any;
  check_pid_empty: Boolean;
  isCustomer: string;
  officeSelected: Boolean = false;
  currentLang: string;
  isSubmit: Boolean;
  clipboardData: string;
  byPass: Boolean;
  pathUrl: string;
  itemObj: Customer;
  BusinessType: any;
  isEditing: boolean;
  isLoading: boolean;
  itemID: string;
  apiPath: string;
  personal_id: any;
  modeText: string;
  index_pid: number[];
  selectedDate: any;

  isBussType: boolean = true;

  public isCollapsed5 = true;
  private _unsubscribeAll: Subject<any>;

  gender = Gender;
  kGender = kGender;

  provinceList: any[] = [];
  districtList: any[] = [];
  subDistrictList: any[] = [];

  isLoadingProvince: boolean;
  isLoadingDistrict: boolean;
  isLoadingSubDistrict: boolean;

  contactAddressId: string;

  provinceName: any;
  districtName: any;
  subDistrictName: any;

  @ViewChild('confirmModal') confirmModal: ModalComponent;
  @ViewChild('CustomerAddress') CustomerAddress: CustomerAddrComponent;

  isRequiredCentralContact: boolean =
    environment.customer.requiredCentralContact;
  isRequiredDetail: boolean = environment.customer.requiredDetail;

  isValidRefCode: boolean = true;
  isCheckingRefCode: boolean = false;
  isRefCodeDoChecked: boolean = false;
  isHaveUplineCode: boolean = false;

  constructor(
    private _apiService: ApiService,
    private _modalService: NgbModal,
    private clipboard: Clipboard,
    private _formBuilder: FormBuilder,
    private _componentsService: ComponentsService,
    private _CustomerServiceService: CustomerService,
    private _contentTemplateService: CustomerService,
    private _route: ActivatedRoute,
    private _translateService: TranslateService,
    private _router: Router,
    private _globalFuncService: GlobalFuncService,
    private _http: HttpClient
  ) {
    this.componentName = 'Item';
    this._unsubscribeAll = new Subject();
    this.componentName = this._contentTemplateService.componentName;

    this.index_pid = [
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
    ];
    this.otpLengthArray = Array(13)
      .fill(0)
      .map((_, index) => index);
    this._route.paramMap.subscribe((val) => {
      this.isEditing = this._route.snapshot.paramMap.get('id') ? true : false;
    });
    this.compiledOtp = '';
    this.isCustomer = '1';
    this.clipboardData = '';
    this.pathUrl = this._CustomerServiceService.pathUrl;
    this.currentLang = this._translateService.currentLang;
    this.apiPath = this._CustomerServiceService.apiPath;
    this.registerForm = this._formBuilder.group({
      id: [null],
      // 'ข้อมูลผู้ติดต่อ'
      businessName: ['', [Validators.maxLength(100)]],
      country: ['1', Validators.required],
      p_id: ['', Validators.required],
      office: ['1', Validators.required],
      office_type: [''],

      uplineCode: [''],

      islegalEntity: [false],
      genderId: ['2'],
      branchNumber: [
        '',
        [
          Validators.maxLength(5),
          this.nonZeroPositiveIntegerAndMaxLengthValidator(5),
        ],
      ],

      branchName: ['', [Validators.maxLength(50)]],

      // ข่้อมูลบุุคล
      customerFirstName: ['', [Validators.required, Validators.maxLength(100)]],
      customerLastName: ['', [Validators.maxLength(100)]],

      fname: ['', [Validators.maxLength(50)]],
      lname: ['', [Validators.maxLength(50)]],
      n_name: ['', [Validators.maxLength(30)]],
      rank: ['', [Validators.maxLength(50)]],
      email: [
        null,
        [Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')],
      ],
      phone: [
        '',
        [
          Validators.pattern(
            '^\\+?[0-9]{1,3}?[-.\\s]?\\(?[0-9]{1,3}\\)?[-.\\s]?[0-9]{1,5}[-.\\s]?[0-9]{1,5}$'
          ),
        ],
      ],

      dateOfBirth: [null, Validators.required],

      //ข้อมูลกลาง
      gb_phone: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^\\+?[0-9]{1,3}?[-.\\s]?\\(?[0-9]{1,3}\\)?[-.\\s]?[0-9]{1,5}[-.\\s]?[0-9]{1,5}$'
          ),
        ],
      ],
      gb_fax: ['', [Validators.maxLength(12)]],
      gb_email: [null, [Validators.email]],
      gb_site: [
        '',
        [
          Validators.pattern(
            '^(https?://)?[a-zA-Z0-9-.]+\\.[a-zA-Z]{2,}(?:/[^\\s]*)?$'
          ),
        ],
      ],

      addressNumber: [null, Validators.required],
      provinceId: [null, Validators.required],
      districtId: [null, Validators.required],
      subDistrictId: [null, Validators.required],
      postCode: [null, Validators.required],

      isActive: [null],
    });

    this.setValidatorWithConfig();
  }
  get f() {
    return this.registerForm.controls;
  }

  setValidatorWithConfig() {
    if (this.isRequiredDetail) {
      this.registerForm.get('fname').addValidators([Validators.required]);
      this.registerForm.get('lname').addValidators([Validators.required]);
      this.registerForm.get('n_name').addValidators([Validators.required]);
      this.registerForm.get('rank').addValidators([Validators.required]);
      this.registerForm.get('email').addValidators([Validators.required]);
      this.registerForm.get('phone').addValidators([Validators.required]);
      this.registerForm.get('dateOfBirth').addValidators([Validators.required]);
    }

    if (this.isRequiredCentralContact) {
      this.registerForm.get('gb_email').addValidators([Validators.required]);
      this.registerForm.get('gb_site').addValidators([Validators.required]);
    }
  }

  @ViewChildren('inputElements') inputElements: QueryList<ElementRef>;
  showResult: boolean = false;
  result: string = '';

  isOfficeSelected() {
    console.log('Hello');

    this.officeSelected = true;
  }

  isBusinessType(isBusiness: boolean) {
    if (!isBusiness) {
      this.registerForm.patchValue({
        office_type: 16,
      });
      this.isBussType = false;
    } else {
      this.registerForm.patchValue({
        office_type: '',
      });
      this.isBussType = true;
    }
  }

  initForm(isEditing: boolean): void {
    this.getProvince();
    if (isEditing) {
      this.blockUI.start();
      this.getitemData(this.itemID);
    } else {
      this.itemObj = new Customer();
      this.isLoading = false;
    }
  }

  getProvince(): Promise<any> {
    this.isLoadingProvince = true;

    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterProvince`, {
          SortPath: 'provinceName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.provinceList = res.data.resultData;
          this.isLoadingProvince = false;
        }, rejects);
    });
  }

  clearInvalid() {
    const branchNumberControl = this.registerForm.get('branchNumber');

    if (branchNumberControl.invalid) {
      branchNumberControl.setValue('');
      branchNumberControl.markAsPristine();
      branchNumberControl.markAsUntouched();
    }
  }

  checkRefCode(event) {
    this.isValidRefCode = false;
    this.isCheckingRefCode = true;
    this.isRefCodeDoChecked = true;

    let currentCustomerCode = this.itemObj.repoveCode;
    let refCode = this.f.uplineCode.value;
    if (refCode != currentCustomerCode && refCode) {
      this._http
        .get(
          `${this._apiService.API_URL}/${this.apiPath}/ReferralCheck/${refCode}`
        )
        .subscribe((res: any) => {
          this.isValidRefCode = res.data.resultData[0];
          this.isCheckingRefCode = false;
        });
    } else {
      this.isValidRefCode = false;
      this.isCheckingRefCode = false;
    }
  }

  nonZeroPositiveIntegerAndMaxLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (
        value === null ||
        value === undefined ||
        value === '' ||
        this.f.office.value != '2'
      ) {
        return null; // No validation if the field is empty
      }

      if (!value.match(/^(?!0+$)[0-9]+$/) && this.f.office.value == '2') {
        console.log(this.f.branchNumber);
        return { invalid: true, message: 'Invalid value' };
      }

      if (value.length > maxLength) {
        return { maxLengthExceeded: true, message: 'Max length exceeded' };
      }

      return null; // Validation passed
    };
  }

  async getitemData(guid: string): Promise<any> {
    if (this.isEditing) {
      var self = this;
      await new Promise<void>((resolve, reject) => {
        this._apiService.GetDataById(this.apiPath, guid).subscribe((res) => {
          this.CustomerAddress.buildForm(res.data.resultData[0].id, true);
          self.itemObj = res.data.resultData[0];
          self.index_pid = res.data.resultData[0].taxId.split('');

          self._apiService
            .GetAllData('CustomerAddress', {
              UseForContact: true,
              UseForShipping: false,
              UseForBilling: false,
              IsDefault: true,
              IsDelete: false,
              customerId: res.data.resultData[0].id,
            })
            .subscribe((addr_res) => {
              let tempC_addr: CustomerAddress = addr_res.data.resultData[0];

              this.contactAddressId = tempC_addr.id;

              this.registerForm.patchValue({
                addressNumber: tempC_addr.addressNumber,
                provinceId: tempC_addr.provinceId,
                districtId: tempC_addr.districtId,
                subDistrictId: tempC_addr.subDistrictId,
                postCode: tempC_addr.postCode,
              });

              this.getDistrict(tempC_addr.provinceId);
              this.getSubDistrict(tempC_addr.districtId);
            });

          this.blockUI.stop();
          resolve(); // Resolve the promise once the data is set
        }, reject);
      });

      // Now that the promise is resolved, you can proceed with building the form
      this.buildForm();
    }
    this.blockUI.stop();
    this.isLoading = false;
  }

  isSelectProvice(id: string) {
    this.registerForm.get('districtId').setValue(null);
    this.registerForm.get('subDistrictId').setValue(null);
    this.registerForm.get('postCode').setValue(null);

    this.districtList = [];
    this.subDistrictList = [];

    this.getDistrict(id);
    let tempProvice = this.provinceList.find((val) => (val.id = id));
    this.provinceName = tempProvice.provinceName;
  }

  getDistrict(id): Promise<any> {
    this.isLoadingDistrict = true;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterDistrict`, {
          ProvinceId: id,
          SortPath: 'districtName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.districtList = res.data.resultData;
          this.isLoadingDistrict = false;
        }, rejects);
    });
  }

  isSelectDistrict(id: string) {
    this.registerForm.get('subDistrictId').setValue(null);
    this.registerForm.get('postCode').setValue(null);
    this.subDistrictList = [];
    this.getSubDistrict(id);
    let tempDistrict = this.districtList.find((val) => (val.id = id));
    this.districtName = tempDistrict.districtName;
  }

  getSubDistrict(id): Promise<any> {
    this.isLoadingSubDistrict = true;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterSubDistrict`, {
          districtId: id,
          SortPath: 'subDistrictName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.subDistrictList = res.data.resultData;
          this.isLoadingSubDistrict = false;
        }, rejects);
      return true;
    });
  }

  getPostCode(event: any): Promise<any> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetDataById(`MasterSubDistrict`, event)
        .subscribe((res) => {
          console.log(res.data.resultData[0].subDistrictPostCode);
          this.registerForm.patchValue({
            postCode: res.data.resultData[0].subDistrictPostCode,
          });
        }, rejects);
    });
  }

  gmtTime = new Date();

  onDateChange(date: Date[]) {
    let tempDate = this.registerForm.controls.dateOfBirth.value;
    this.selectedDate = new Date(tempDate);
    this.gmtTime = new Date(this.selectedDate.getTime());
  }

  maxTodayDateOption: FlatpickrOptions = {
    altInput: true,
    // defaultDate: this.gmtToDate.toISOString(),
    altFormat: 'd/m/Y',
    dateFormat: 'd/m/Y',
  };

  submit(): void {
    var self = this;
    this.blockUI.start();
    var branch: boolean = this.f.office.value === '1';
    var isThai: boolean = this.f.country.value === '1';

    var taxIdsString: string = this.index_pid.join('');
    this.formatForm = this._formBuilder.group({
      isThailand: isThai,
      taxId: taxIdsString,
      isHeadBranch: branch,
      islegalEntity: this.f.islegalEntity.value,
      branchNumber: this.f.islegalEntity.value
        ? parseInt(this.f.branchNumber.value ? this.f.branchNumber.value : 0)
        : 0,
      branchName: this.f.islegalEntity.value ? this.f.branchName.value : '',
      businessTypeId: this.f.islegalEntity.value
        ? parseInt(this.f.office_type.value)
        : '16',
      businessName: this.f.islegalEntity.value
        ? this.f.customerFirstName.value
        : '',
      customerFirstName: !this.f.islegalEntity.value
        ? this.f.customerFirstName.value
        : '',
      customerLastName: !this.f.islegalEntity.value
        ? this.f.customerLastName.value
        : '',
      personalFirstName: this.f.fname.value,
      personalLastName: this.f.lname.value,
      personalNickName: this.f.n_name.value,
      personalPosition: this.f.rank.value,

      personalPhone: this.f.phone.value,
      dateOfBirth: this.f.dateOfBirth.value,
      personalEmail: this.f.email.value,

      contactPhone: this.f.gb_phone.value,
      contactEmail: this.f.gb_email.value,
      contactFax: this.f.gb_fax.value,
      contactWebsite: this.f.gb_site.value,

      genderId:
        this.f.office_type.value == '16' || this.f.office_type.value == 16
          ? parseInt(this.f.genderId.value)
          : 2,
      isActive: this.f.isActive.value,
      uplineCode: this.isHaveUplineCode ? this.f.uplineCode.value : null,
    });

    this.formatContactAddrForm = this._formBuilder.group({
      customerId: null,
      businessName:
        self.f.islegalEntity.value == true
          ? self.f.customerFirstName.value
          : '',
      firstName:
        self.f.islegalEntity.value == false
          ? self.f.customerFirstName.value
          : '',
      lastName:
        self.f.islegalEntity.value == false
          ? self.f.customerLastName.value
          : '',
      taxId: taxIdsString,
      isHeadBranch: branch,
      branchNumber: self.f.islegalEntity.value
        ? parseInt(self.f.branchNumber.value ? self.f.branchNumber.value : 0)
        : 0,
      branchName: self.f.islegalEntity.value ? self.f.branchName.value : '',
      detail: '',
      addressNumber: self.f.addressNumber.value,
      village: '',
      alley: '',
      road: '',
      country: 0,
      postCode: self.f.postCode.value,
      provinceId: self.f.provinceId.value,
      // provinceName: self.provinceName,
      districtId: self.f.districtId.value,
      // districtName: self.districtName,
      subDistrictId: self.f.subDistrictId.value,
      // subDistrictName: self.subDistrictName,
      useForShipping: false,
      useForBilling: false,
      useForContact: true,
      isLegalAddr: self.f.islegalEntity.value,
      addrLatitude: 0,
      addrLongitude: 0,
      phone: self.f.gb_phone.value,
      fax: '',
    });

    // console.log(this.formatForm);
    // this.blockUI.stop();
    // return;

    if (this.isEditing) {
      this.formatContactAddrForm.patchValue({
        customerId: this.itemObj.id.toString(),
      });
      this._apiService
        .UpdateDataById(
          this.apiPath,
          this.itemObj.id.toString(),
          this.formatForm.value
        )
        .subscribe(
          (res) => {
            this._apiService
              .SetIsActiveData(
                this.apiPath,
                this.itemObj.id.toString(),
                this.formatForm.value.isActive
              )
              .subscribe((res) => {});

            if (this.contactAddressId) {
              this._apiService
                .UpdateDataById(
                  'customerAddress',
                  this.contactAddressId,
                  this.formatContactAddrForm.value
                )
                .subscribe((c_addr_res) => {
                  this._componentsService.SuccessSwal();
                  this._router.navigate([`/customer`]);
                });
            } else {
              this._apiService
                .AddData('customerAddress', this.formatContactAddrForm.value)
                .subscribe((c_addr_res) => {
                  this._apiService
                    .SetIsDefault(
                      'customerAddress',
                      c_addr_res.data.resultData[0]
                    )
                    .subscribe((res) => {
                      this._componentsService.SuccessSwal();
                      this._router.navigate([`/customer`]);
                    });
                });
            }
          },
          (err) => {
            this._componentsService.ErrorSwal();
          }
        );
    } else {
      this.formatForm.patchValue({
        uplineCode: this.isHaveUplineCode ? this.f.uplineCode.value : null,
      });
      this._apiService.AddData(this.apiPath, this.formatForm.value).subscribe(
        (res) => {
          console.log('Added new customer....');
          this.formatContactAddrForm.patchValue({
            customerId: res.data.resultData[0],
          });

          this._apiService
            .AddData('customerAddress', this.formatContactAddrForm.value)
            .subscribe((c_addr_res) => {
              this._apiService
                .SetIsDefault('customerAddress', c_addr_res.data.resultData[0])
                .subscribe(() => {
                  this.CustomerAddress.submit(res.data.resultData[0], false);
                });
            });
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
    }
  }

  ngOnInit(): void {
    this._route.paramMap.subscribe((val) => {
      this.itemID = this._route.snapshot.paramMap.get('id');
      this.isEditing = this.itemID ? true : false;
      this.isLoading = this.isEditing ? true : false;
      this.modeText = this.isEditing
        ? this._translateService.instant('General.Edit')
        : this._translateService.instant('General.Create');
    });

    this.getBusinessType();
    this.initForm(this.isEditing);
    const input: HTMLInputElement = document.getElementById(
      'PersonalID'
    ) as HTMLInputElement;

    input.addEventListener('focusin', (event: FocusEvent) => {
      (event.target as HTMLInputElement).style.background = '#dcdcdc';
      console.log(event.target);
    });

    input.addEventListener('focusout', (event: FocusEvent) => {
      (event.target as HTMLInputElement).style.background = '';
      this.index_pid.forEach((input: any, index: number) => {
        let status;
        if (isNaN(input)) {
          status = true;
        } else {
          status = false;
        }
      });
    });
    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });
  }

  getBusinessType(): Promise<any> {
    return new Promise((resolve, rejects) => {
      this._apiService.GetAllData(`BusinessType`).subscribe((res) => {
        // this.BusinessType = res.data.resultData;
        this.BusinessType = res.data.resultData.filter(
          (item) => item.id !== 16
        );
      }, rejects);
    });
  }

  buildForm(): void {
    var self = this;
    const inputs = document.querySelectorAll('#PersonalID > *[id]');
    self.officeSelected = true;
    if (inputs.length === self.index_pid.length) {
      for (let i = 0; i < inputs.length; i++) {
        var currentInput = inputs[i] as HTMLInputElement;
        currentInput.value = self.index_pid[i].toString();
      }
    } else {
      console.error('Number of inputs does not match the length of index_id.');
    }

    console.log(self.itemObj);
    this.isBusinessType(self.itemObj.businessTypeId != 16 ? true : false);

    let tempBdate = new Date(self.itemObj.dateOfBirth);

    this.maxTodayDateOption = {
      altInput: true,
      // defaultDate: this.gmtToDate.toISOString(),
      altFormat: 'd/m/Y',
      dateFormat: 'd/m/Y',
      utc: true,
      defaultDate: tempBdate,
    };

    this.registerForm.patchValue({
      id: self.itemObj.id,

      businessName: self.itemObj.businessName,

      country: self.itemObj.isThailand ? '1' : '2',
      p_id: self.index_pid,
      office: self.itemObj.isHeadBranch
        ? '1'
        : self.itemObj.branchNumber != 0
        ? '2'
        : '0',
      branchNumber: self.itemObj.branchNumber.toString().padStart(5, '0'),
      branchName: self.itemObj.branchName,
      office_type: self.itemObj.businessTypeId,
      islegalEntity: self.itemObj.islegalEntity,

      // name:

      customerFirstName: self.itemObj.islegalEntity
        ? self.itemObj.businessName
        : self.itemObj.customerFirstName,
      customerLastName: self.itemObj.customerLastName,
      genderId: self.itemObj.genderId,
      fname: self.itemObj.personalFirstName,
      lname: self.itemObj.personalLastName,
      n_name: self.itemObj.personalNickName,
      rank: self.itemObj.personalPosition,
      email: self.itemObj.personalEmail,
      dateOfBirth: tempBdate.toISOString(),

      phone: self.itemObj.personalPhone,
      gb_phone: self.itemObj.contactPhone,
      gb_fax: self.itemObj.contactFax,
      gb_email: self.itemObj.contactEmail,
      gb_site: self.itemObj.contactWebsite,
      isActive: self.itemObj.isActive,
    });
  }
  updateBranchNumberValue() {
    if (this.byPass) {
      this.f.controls['branchNumber'].setValue(
        this.f.controls['branchNumber'].value.toString().padStart(5, '0')
      );
    }
  }

  validatePID() {
    let p_id = this.registerForm.controls.p_id.value;
    const regex = /NaN/;

    if (regex.test(p_id)) {
      this.check_pid_empty = true;
    }
  }

  async openConfirmModal(content: any): Promise<void> {
    this.check_pid_empty = false;
    const taxIdsString: string = this.index_pid.join('');
    this.registerForm.patchValue({
      p_id: taxIdsString,
    });

    console.log(this.registerForm.controls.p_id);
    this.validatePID();

    if (this.isHaveUplineCode && !this.f.uplineCode.value) {
      return;
    }

    if (!this.isBussType) {
      this.registerForm.patchValue({
        businessName:
          this.f.customerFirstName.value +
          (this.f.customerLastName.value ? ' ' : '') +
          (this.f.customerLastName.value ? this.f.customerLastName.value : ''),
      });
    }

    console.log(this.registerForm.value);

    let isConfirm = true;
    let contentNameText = this.isEditing ? content.name : this.componentName;
    if (this.isEditing) {
      var edt = this._translateService.instant('General.Edit');
      var detailedt = this._translateService.instant('General.toEdit');
    } else {
      var edt = this._translateService.instant('General.Add');
      var detailedt = this._translateService.instant('General.toCreate');
    }

    let title = edt + this._translateService.instant('General.Customer');

    let detail =
      this._translateService.instant('General.AreYouConfirm') +
      detailedt +
      this._translateService.instant('General.Customer');

    this.CustomerAddress.validator();
    console.log(this.CustomerAddress.validator());
    console.log(this.registerForm.invalid);
    console.log(this.check_pid_empty);
    if (this.f.office.value !== '2') {
      console.log('ByPassing validation');
      this.clearInvalid();
      this.byPass = true;
    } else {
      console.log('Not ByPassing validation');
      this.byPass = false;
    }

    this.isSubmit = true;
    if (this.isEditing) {
      if (!this.registerForm.invalid && !this.check_pid_empty) {
        this.openModal(title, detail, isConfirm);
      } else {
        for (const controlName in this.registerForm.controls) {
          const control = this.registerForm.get(controlName);

          if (control.invalid) {
            console.log(`Invalid control: ${controlName}`);
          }
        }
      }
    } else {
      if (this.CustomerAddress.validator()) {
        if (!this.registerForm.invalid && !this.check_pid_empty) {
          this.openModal(title, detail, isConfirm);
        } else {
          for (const controlName in this.registerForm.controls) {
            const control = this.registerForm.get(controlName);

            if (control.invalid) {
              console.log(`Invalid control: ${controlName}`);
            }
          }
        }
      } else {
        this.scrollToFirstInvalidControl();
        return;
      }
    }
  }

  openCancelModal(content: any): void {
    let isConfirm = false;
    let contentNameText = this.isEditing
      ? this.itemObj.businessName
      : this.componentName;

    var stateForm = this._translateService.instant('General.Cancel');

    let title =
      this._translateService.instant('General.CancelAction') +
      this._translateService.instant('General.Customer');
    let detail = this._translateService.instant('General.PressToCancel');

    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.Edit');
    } else {
      var stateForm = this._translateService.instant('General.Create');
    }

    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    console.log(this.registerForm);

    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.title = title;
    modalRef.componentInstance.isConfirm = IsConfirm;
    modalRef.componentInstance.detail = detail;

    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      if (!this.registerForm.invalid) {
        if (IsConfirm) {
          this.submit();
        } else {
          this._router.navigate([`${this.pathUrl}`]);
        }
      } else {
        this._router.navigate([`${this.pathUrl}`]);
      }
    });
  }

  onKeyDown(event: KeyboardEvent, index: number): void {
    const inputs = document.querySelectorAll('#PersonalID > *[id]');
    const currentInput = inputs[index] as HTMLInputElement;
    // if (event.metaKey && event.key === 'v') {
    //   console.log('Hi');

    //   this.getDataFromClipboard();
    // }
    if (event.key === 'Backspace') {
      if (currentInput.value === '') {
        if (index !== 0) {
          const previousInput = inputs[index - 1] as HTMLInputElement;
          previousInput.focus();
        }
      } else {
        this.index_pid[index] = NaN;
        currentInput.value = '';
      }
    } else if (event.key === 'ArrowLeft' && index !== 0) {
      const previousInput = inputs[index - 1] as HTMLInputElement;
      previousInput.focus();
    } else if (event.key === 'ArrowRight' && index !== inputs.length - 1) {
      const nextInput = inputs[index + 1] as HTMLInputElement;
      nextInput.focus();
    } else if (event.key === 'ArrowUp' && index !== inputs.length - 1) {
      const nextInput = inputs[index] as HTMLInputElement;
      nextInput.focus();
    } else if (event.key === 'ArrowDown' && index !== inputs.length - 1) {
      const nextInput = inputs[index] as HTMLInputElement;
      nextInput.focus();
    } else if (event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
      currentInput.value = '';
      setTimeout(() => {}, 1000);
    }
  }

  async onPaste(): Promise<void> {
    const inputs = document.querySelectorAll('#PersonalID > *[id]');

    await new Promise<void>((resolve) => {
      inputs.forEach((input: any) => {
        input.value = '';
      });

      resolve();
    });

    this.getDataFromClipboard();
  }

  onInput(event: any, index: number): void {
    this.check_pid_empty = false;
    const inputs = document.querySelectorAll('#PersonalID > *[id]');
    const currentInput = inputs[index] as HTMLInputElement;
    currentInput.value = currentInput.value.toUpperCase();
    const inputValue: string = currentInput.value;
    const parsedValue: number = parseInt(inputValue, 10);
    this.index_pid[index] = parsedValue;

    if (index === inputs.length - 1 && currentInput.value !== '') {
      return;
    } else if (currentInput.value !== '') {
      const nextInput = inputs[index + 1] as HTMLInputElement;
      nextInput.focus();
    }
  }
  getDataFromClipboard(): void {
    const inputs = document.querySelectorAll('#PersonalID > *[id]');
    console.log(inputs);

    navigator.clipboard
      .readText()
      .then((clipboardData: string) => {
        const dataArray = clipboardData.split('\n');
        const regex = /\d+/g;
        const matches = dataArray[0].match(regex);

        if (matches) {
          const only_int = matches.map((match) => parseInt(match));
          const str_num = only_int.join('').toString();

          // loop to set inputs.value is str_num
          this.index_pid = [
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
          ];
          this.index_pid.forEach((input: any, index: number) => {
            if (str_num[index]) {
              inputs.forEach((input: any, index: number) => {
                input.value = '';
                if (str_num[index]) {
                  input.value = str_num[index].toString();
                }
              });
              this.index_pid[index] = str_num.split('').map(Number)[index];
            }
          });

          // inputs.forEach((input: any, index: number) => {
          //   if (str_num[index]) {
          //     input.value = str_num[index].toString();
          //     this.index_pid[index] = str_num.split('').map(Number)[index];
          //   }
          // });

          // if (inputs.length < 13) {
          //   this.index_pid = this.index_pid.slice(0, inputs.length);
          //   while (this.index_pid.length < 13) {
          //     this.index_pid.push(null);
          //   }
          // }
        }
      })
      .catch((error: Error) => {
        console.error('Failed to read clipboard data:', error);
      });
  }

  transform(value: any[]): string {
    if (!Array.isArray(value)) {
      return '';
    }

    const flattenedArray = this.flattenArray(value);
    return flattenedArray.join('');
  }

  private flattenArray(arr: any[]): any[] {
    const result: any[] = [];

    for (const item of arr) {
      if (Array.isArray(item)) {
        result.push(...this.flattenArray(item));
      } else {
        result.push(item);
      }
    }

    return result;
  }

  private scrollToFirstInvalidControl() {
    this._globalFuncService.scrollToFirstInvalidControl('customer-form');
  }

  openSelectUserModal() {
    const modalRef = this._modalService.open(SelectUserModalComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.callBackFunc.subscribe((res) => {});
  }
}
