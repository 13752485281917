

<div class="card">
  <div class="card-body">
    <section id="detail-section">
      <div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
        <app-loading></app-loading>
      </div>

      <div *ngIf="!isLoading" class="row">
        <div class="col-md-12">
          <form>
            <div class="row">
              <div class="col-12 col-md-2">
                <div class="form-group">
                  <label for="first-name-vertical">Prefix</label>
                  <select class="form-control" name="" id="">
                    <option value="1">นาย</option>
                    <option value="2">นาง</option>
                    <option value="3">นางสาว</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <div class="form-group">
                  <label for="first-name-vertical">{{
                    "User.FirstName" | translate
                  }}</label>
                  <input
                    type="text"
                    id="first-name-vertical"
                    class="form-control"
                    [(ngModel)]="user.firstName"
                    name="firstName"
                    placeholder="First Name"
                  />
                </div>
              </div>

              <div class="col-12 col-md-5">
                <div class="form-group">
                  <label for="first-name-vertical">{{
                    "User.LastName" | translate
                  }}</label>
                  <input
                    type="text"
                    id="first-name-vertical"
                    class="form-control"
                    [(ngModel)]="user.lastName"
                    name="lastName"
                    placeholder="Last Name"
                  />
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="first-name-vertical">{{
                    "User.Email" | translate
                  }}</label>
                  <input
                    type="email"
                    id="first-name-vertical"
                    class="form-control"
                    [(ngModel)]="user.email"
                    name="email"
                    placeholder="Email"
                  />
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="first-name-vertical">{{
                    "User.Phone" | translate
                  }}</label>
                  <input
                    type="text"
                    id="first-name-vertical"
                    class="form-control"
                    [(ngModel)]="user.phone"
                    name="phone"
                    placeholder="Phone"
                  />
                </div>
              </div>

              <div class="col-12 col-md-6" *ngIf="this.user.isCompanyUser">
                <div class="form-group">
                  <label for="first-name-vertical">{{
                    "User.Organization" | translate
                  }}</label>
                  <input
                  [disabled]="!isSuperAdmin"
                    type="text"
                    id="first-name-vertical"
                    class="form-control"
                    [(ngModel)]="user.organization"
                    name="organization"
                    placeholder="Organization"
                  />
                </div>
              </div>

              <div class="col-12 col-md-6" *ngIf="this.user.isCompanyUser">
                <div class="form-group">
                  <label for="first-name-vertical">{{
                    "User.Branch" | translate
                  }}</label>
                  <input
                  [disabled]="!isSuperAdmin"
                    type="text"
                    id="first-name-vertical"
                    class="form-control"
                    [(ngModel)]="user.branch"
                    name="branch"
                    placeholder="branch"
                  />
                </div>
              </div>

              <div class="col-12 col-md-6" *ngIf="this.user.isCompanyUser">
                <div class="form-group">
                  <label for="first-name-vertical">{{
                    "User.Department" | translate
                  }}</label>
                  <input
                  [disabled]="!isSuperAdmin"
                    type="text"
                    id="first-name-vertical"
                    class="form-control"
                    [(ngModel)]="user.department"
                    name="department"
                    placeholder="department"
                  />
                </div>
              </div>

              <div class="col-12 col-md-6" *ngIf="this.user.isCompanyUser">
                <div class="form-group">
                  <label for="first-name-vertical">{{
                    "User.Position" | translate
                  }}</label>
                  <input
                    [disabled]="!isSuperAdmin"
                    type="text"
                    id="first-name-vertical"
                    class="form-control"
                    [(ngModel)]="user.position"
                    name="position"
                    placeholder="position"
                  />
                </div>
              </div>


              <br />
              <div class="col-12 d-flex justify-content-between">
                <button
                  class="btn btn-secondary"
                  (click)="openCancelModal(itemObj)"
                >
                  {{ "Form.Cancel" | translate }}
                </button>

                <button
                  class="btn btn-primary"
                  (click)="openConfirmModal(itemObj)"
                >
                  {{ "Form.Submit" | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</div>
