import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  config: ICMSConfig = {
    apiPath: 'OurCompanyBanner',
    tableName: 'Content',
    pathUrl: '/cms/repove-tools/our-company/detail',
    model: new Object(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.RepoveTools.Detail.Title',
    formConfig: {
      bannerImg: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Banner Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.BannerImage',
      },
      bannerAboutTitle1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.BannerTitle',
      },
      bannerAboutDescription1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Banner Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.BannerDescription',
      },
      bannerAboutSubDescription1: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Banner Sub Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.BannerSubdescription',
      },


      companyVideo: {
        type: FormType.Video,
        validator: [Validators.required],
        // label: 'Company Video',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.Video',
      },

      affilatedImage: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Affilated Companies Image'
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.ACImage',
      },
      affilatedLogo: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Affilated Companies Logo'
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.ACLogo',
      },
      affilatedTitle: {
        type: FormType.Text,
        validator: [Validators.required],
        // label: 'Affilated Companies Title'
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.ACTitle',
      },
      affilatedName: {
        type: FormType.Text,
        validator: [Validators.required],
        // label: 'Affilated Companies Name',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.ACName',
      },
      affilatedDetail: {
        type: FormType.Textarea,
        validator: [Validators.required],
        // label: 'Affilated Companies Detail,
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.ACDetail',
      },
      saleAmount: {
        type: FormType.Text,
        validator: [Validators.required],
        // label: 'Annual Sale',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.AnnualSale',
      },
      branchAmount: {
        type: FormType.Text,
        validator: [Validators.required],
        // label: 'Branch in Korean',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.Branch',
      },
      subscriptionAmount: {
        type: FormType.Text,
        validator: [Validators.required],
        // label: 'Subscription',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.Subscription',
      },


      bannerAboutTitle2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Timeline Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.TimelineTitle',
      },
      bannerAboutDescription2: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Timeline Banner Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.TimelineDescription',
      },
      timelineBannerImg: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Timeline Banner Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.TimelineImage',
      },

      
      wordingTitle: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Target Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.TargetBannerTitle',
      },
      targetIcon1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Target Icon one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.TargetIconOne',
      },
      wordingIcon1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Target Title one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.TargetTitleOne',
      },
      targetIcon2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Target Icon two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.TargetIconTwo',
      },
      wordingIcon2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Target Title two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.TargetTitleTwo',
      },
      targetIcon3: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Target Icon three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.TargetIconThree',
      },
      wordingIcon3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Target Title three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.TargetTitleThree',
      },
      wordingSubTitle: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Target Banner Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Detail.TargetDescription',
      },
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
