import { Component, OnInit,  } from '@angular/core';

import { BannerService } from '../banner.service';
import { ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-banner-create',
  templateUrl: './banner-create.component.html',
  styleUrls: ['./banner-create.component.scss'],
})
export class BannerCreateComponent implements OnInit {
  config: ICMSConfig;

  constructor(private _bannerService: BannerService) {
    this.config = this._bannerService.config;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
