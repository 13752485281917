import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ProductDetailComponent } from './product-detail.component';
import { ProductDetailCreateComponent } from './product-detail-create/product-detail-create.component';
import { AuthGuard } from 'app/auth/helpers';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';
import { CmsListModule } from '../cms-list/cms-list.module';
import { CertificationsCardModule } from '../repove-tools/certifications-card/certifications-card.module';
import { CertificationsFileModule } from '../repove-tools/certifications-file/certifications-file.module';
import { BonusPlanModule } from '../bonus-plan/bonus-plan.module';
import { ComponentsModule } from 'app/main/components/components.module';
import { CoreCommonModule } from '@core/common.module';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSummernoteModule } from 'ngx-summernote';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

const path: string = 'cms/product-detail'
const routes: Routes =[
    {
      path: path,
      component: ProductDetailComponent,
      canActivate: [AuthGuard],
      data: { animation: 'product-detail-management' },
    },
    {
      path: `${path}/create`,
      component: ProductDetailCreateComponent,
      canActivate: [AuthGuard],
      data: { animation: 'product-detail-create' },
    },
    {
      path: `${path}/edit/:id`,
      component: ProductDetailCreateComponent,
      canActivate: [AuthGuard],
      data: { animation: 'product-detail-edit' },
    },
  ]

@NgModule({

  declarations: [
    ProductDetailComponent,
    ProductDetailCreateComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    CmsFormpageModule,
    CmsListModule,
    CertificationsCardModule,
    CertificationsFileModule,
    BonusPlanModule,
    ComponentsModule,
    CoreCommonModule,
    CorePipesModule,
    TranslateModule,
    NgxDropzoneModule,
    NgxSummernoteModule,
    FormsModule,
    ReactiveFormsModule,
    CoreTouchspinModule,
    NgbPaginationModule,
    NgbModule,
  ]
})
export class ProductDetailModule { }
