import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubcategoryTemplateComponent } from './subcategory.component';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ContentHeaderModule } from '../../../../layout/components/content-header/content-header.module';
import { AuthGuard } from '../../../../auth/helpers';
import { SubcategoryFormpageComponent } from './subcategory-formpage/subcategory-formpage.component';
import { ComponentsModule } from '../../../components/components.module';
import { CoreCommonModule } from '@core/common.module';
import { environment } from 'environments/environment';

const routes: Routes =!environment.menuList.itemManage ? [] : [
  {
    path: 'item-manage/subcategory',
    component: SubcategoryTemplateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'subcategory-manage' },
  },
  {
    path: 'item-manage/subcategory/create',
    component: SubcategoryFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'subcategory-create' },
  },
  {
    path: 'item-manage/subcategory/edit/:id',
    component: SubcategoryFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'subcategory-edit' },
  },
];

@NgModule({
  declarations: [SubcategoryTemplateComponent, SubcategoryFormpageComponent],
  imports: [
    RouterModule.forChild(routes),
    CoreCommonModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    ContentHeaderModule,
    ComponentsModule
  ],
  exports: [SubcategoryTemplateComponent, SubcategoryFormpageComponent],
})
export class SubcategoryModule {}
