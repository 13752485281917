import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreConfig } from '@core/types';
import { CoreConfigService } from '@core/services/config.service';
import { AuthenticationService } from 'app/auth/service';
import { ComponentsService } from 'app/main/components/components.service';
import { AccountApiService } from 'app/main/service/account-api.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  isSubmitted: boolean = false;
  isPassNotMatch: boolean;

  email: string;
  tokenForgotPassword: string;
  username: string;
  password: string;
  confPassword: string;

  isLoading: boolean;

  public coreConfig: CoreConfig;
  public passwordTextType: boolean;

  private _unsubscribeAll: Subject<any>;
  constructor(
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _authService: AuthenticationService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _componentsService: ComponentsService,
    private _accountApiService: AccountApiService,
    private _translateService: TranslateService,
  ) {
    _route.queryParams.subscribe((val) => {
      this.email = val.email;
      this.tokenForgotPassword = val.token_forgotpassword;
    });


    this._unsubscribeAll = new Subject();

    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        animation: 'fadeIn',
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }
  get f() {
    return this.resetPasswordForm.controls;
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  onSubmit(): void {
    this.isSubmitted = true;
    this.SetLoadingState();

    if (this.resetPasswordForm.invalid) {
      console.log('form invalid')
      this.SetLoadedState();
      return;
    }

    // if (this.f.newPassword.value != this.f.confPassword.value) {
    //   this.isPassNotMatch = true;
    //   this.SetLoadedState();
    //   return;
    // }

    this._accountApiService
      .SentTokenForgetPassword({
        tokenForgotPassword: this.f.tokenForgotPassword.value,
        newPassword: this.f.newPassword.value,
      })
      .subscribe(
        (res) => {
          let title = this._translateService.instant('Alert.PasswordHasBeenReset');

          this._componentsService.SuccessSwal(title);
          this._router.navigate(['/login']);
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
  }

  ngOnInit(): void {
    if (!this.email || !this.tokenForgotPassword) {
      this._componentsService.ErrorSwal();
      this._router.navigate(['/login']);
    }

    this.resetPasswordForm = this._formBuilder.group({
      tokenForgotPassword: [this.tokenForgotPassword, [Validators.required]],
      // newPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[A-Z])/)]],
      newPassword: ['', [Validators.required, this.passwordValidator]],
      confPassword: ['', [Validators.required, this.passwordValidator]],
    },{
      validator: this.passwordMatchValidator
    });

    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }
  SetLoadedState(): void {
    this.isLoading = false;
  }

  passwordValidator(control) {
    const password = control.value;
    const hasValidLength = password.length >= 8 && password.length <= 20;
    const hasUppercase = /[A-Z]/.test(password);

    if (!hasValidLength || !hasUppercase) {
      return { invalidPassword: true };
    }

    return null;
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const passwordControl = formGroup.controls.newPassword;
    const confirmPasswordControl = formGroup.controls.confPassword;

    if (passwordControl.value === confirmPasswordControl.value) {
      confirmPasswordControl.setErrors(null);
    } else {
      confirmPasswordControl.setErrors({ mismatch: true });
    }
  }
}
