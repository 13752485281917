<div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
  <app-loading></app-loading>
</div>

<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
  </div>
</div>

<app-product-detail-form  [callbackUrl]="'/cms/repove-tools/product-list'" [isUseMultipleLang]="true" ></app-product-detail-form>
