import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { FaqService } from '../faq.service';

@Component({
  selector: 'app-faq-create',
  templateUrl: './faq-create.component.html',
  styleUrls: ['./faq-create.component.scss']
})
export class FaqCreateComponent implements OnInit {

  config: ICMSConfig;

  constructor(private _faqService: FaqService) {
    this.config = this._faqService.config;
  }

  ngOnInit(): void {
  }

}
