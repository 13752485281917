import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';

@Injectable({
  providedIn: 'root'
})
export class CertificationsCardService {
  config: ICMSConfig = {
    apiPath: 'certificationscard',
    pathUrl: '/cms/repove-tools/certification/card',
    tableName: 'Content',
    model: new Object(),
    isList: true,
    componentName: 'BreadCrumbs.CMS.RepoveTools.Certification.CardTitle',
    formConfig: {
      name: {
        type: FormType.Text,
        validator: [Validators.required],
        // label: 'Name',
        description: '',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Certification.Name',
        // descriptionTranslate:
        //   'Informative.NewsEvent.CompanyVideo.ContentDate.Description',
      },
      title: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Title',
        description: '',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Certification.Title',
        // descriptionTranslate:
        //   'Informative.NewsEvent.CompanyVideo.VideoTitle.Description',
      },

      detail: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Detail',
        description: '',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Certification.Detail',
        // descriptionTranslate:
        //   'Informative.NewsEvent.CompanyVideo.VideoTitle.Description',
      },

      iconImage: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Cover Image',
        description: '',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Certification.Image',
        // descriptionTranslate:
        //   'Informative.NewsEvent.CompanyVideo.VideoCoverImage.Description',
      },
     
      isActive: {
        type: FormType.IsActive,
        radioList: [
          { label: 'Active', translate: 'Status.Active', value: true },
          { label: 'Inactive', translate: 'Status.Inactive', value: false },
        ],
        // label: 'Active Status',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Certification.ActiveStatus',
      },
    },
  };

  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'name';
}
