import { CommonField } from "app/main/model/Order";

export interface Province extends CommonField {
  id: string;
  provinceName: string;
  provinceNameEn: string;
  repoveCode: string;
  provinceCode: string;
  region: number;
  mapCode: string;
}

export interface District extends CommonField {
  id: string;
  districtName: string;
  districtNameEn: string;
  provinceId: string;
  repoveCode: string;
  districtCode: string;
}

export interface SubDistrict extends CommonField {
  id: string;
  subDistrictName: string;
  subDistrictNameEn: string;
  districtId: string;
  repoveCode: string;
  subDistrictCode: string;
  subDistrictPostCode: string;
  latitude: number;
  longitude: number;
}

export enum Region {
  North = 1,
  Central = 2,
  Northeast = 3,
  West = 4,
  East = 5,
  South = 6,
}
