import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import About from 'app/main/model/About';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit, OnDestroy {
  config: ICMSConfig = {
    apiPath: 'about',
    pathUrl: '/cms/about',
    tableName: 'Content',
    model: new About(),
    isList: false,
    componentName: 'About',
    formConfig: {
      bannerAboutDescription1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Banner Title',
        description: 'Main Wording on banner',
        labelTranslate: 'Informative.About.BannerTitle',
        descriptionTranslate: 'Informative.About.MainWordingOnBanner',
      },
      bannerAboutSubDescription1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Banner SubTitle',
        description: 'Sub Wording on banner',
        labelTranslate: 'Informative.About.BannerSubTitle',
        descriptionTranslate: 'Informative.About.SubWordingOnBanner',
      },
      bannerAbout1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Banner Image',
        description: 'Banner Background Image, Resolution 1920px*450px',
        labelTranslate: 'Informative.About.BannerImage',
        descriptionTranslate: 'Informative.About.BannerImageDescription',
      },

      aboutQuote1: {
        type: FormType.Text,
        isMultipleLang: true,
        label: 'Quote Title 1',
        description: 'Quote Description',
        labelTranslate: 'Informative.About.QuoteTitle1',
        descriptionTranslate: 'Informative.About.QuoteDescription',
        validator: [Validators.required],
      },
      aboutPic: {
        type: FormType.SingleImage,
        label: 'Quote Image 1',
        description: 'Resolution 1280px*600px',
        labelTranslate: 'Informative.About.QuoteImage1',
        descriptionTranslate: 'Informative.About.QuoteImageDescription',
        validator: [Validators.required],
      },

      sustainableAboutQuote: {
        type: FormType.Textarea,
        isMultipleLang: true,
        label: 'About Description 1',
        description: 'Description on left side',
        labelTranslate: 'Informative.About.AboutDescription1',
        descriptionTranslate: 'Informative.About.DescriptionOnLeftSide',
        validator: [Validators.required],
      },
      sustainableAboutDescription: {
        type: FormType.Textarea,
        isMultipleLang: true,
        label: 'About Description 2',
        description: 'Description on right side',
        labelTranslate: 'Informative.About.AboutDescription2',
        descriptionTranslate: 'Informative.About.DescriptionOnRightSide',
        validator: [Validators.required],
      },
      sustainableAboutPic1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'About Image 1',
        description: 'Image on left side Resolution 220px*220px',
        labelTranslate: 'Informative.About.AboutImage1',
        descriptionTranslate: 'Informative.About.AboutImage1Description',
      },
      sustainableAboutPic2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'About Image 2',
        description: 'Image on middle side Resolution 400px*600px',
        labelTranslate: 'Informative.About.AboutImage2',
        descriptionTranslate: 'Informative.About.AboutImage2Description',
      },
      sustainableAboutPic3: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'About Image 3',
        description: 'Image on right side Resolution 510px*300px',
        labelTranslate: 'Informative.About.AboutImage3',
        descriptionTranslate: 'Informative.About.AboutImage3Description',
      },

      bannerAboutDescription2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Banner 2 Title',
        description: 'Main Wording on banner',
        labelTranslate: 'Informative.About.BannerTitle2',
        descriptionTranslate: 'Informative.About.MainWordingOnBanner',
      },
      bannerAbout2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Banner 2 Image',
        description: 'Banner Background Image, Resolution 1920px*450px',
        labelTranslate: 'Informative.About.BannerImage2',
        descriptionTranslate: 'Informative.About.BannerImageDescription',
      },

      aboutQuote2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Quote Title 2',
        description: 'Quote Description',
        labelTranslate: 'Informative.About.QuoteTitle2',
        descriptionTranslate: 'Informative.About.QuoteDescription',
      },
      aboutVideo: {
        type: FormType.Video,
        validator: [Validators.required],
        label: 'Quote Video',
        labelTranslate: 'Informative.About.QuoteVideo',
        // description: 'Resolution XXX*XXX',
      },
    },
  };

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
