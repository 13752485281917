import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import Group from 'app/main/model/Group';
import { ImageUploadService } from '../../image-upload/image-upload-service.service';
import { MultiFileUploadService } from '../../multi-file-upload/multi-file-upload-service.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ModalComponent } from '../../modal/modal.component';
import { ApiService } from 'app/main/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainConfig, filesPropNameConfig } from 'app/main/config/MainConfig';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from '../../components.service';
import { AuthenticationService } from 'app/auth/service';
import { FilesApiService } from 'app/main/service/files-api.service';
import Item from 'app/main/model/Item';
import { ImageUploadComponent } from '../../image-upload/image-upload.component';
import Category from 'app/main/model/Category';
import SubCategory from 'app/main/model/SubCategory';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Class from 'app/main/model/Class';
import SubClass from 'app/main/model/SubClass';
import { GroupService } from 'app/main/pages/item-manage/group/group.service';
import { CurrencyPipe } from '@angular/common';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CustomDropzoneComponent } from '../../custom-dropzone/custom-dropzone.component';
import { FileTypeFilter } from '../../custom-dropzone/file-type.enum';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-item-form',
  templateUrl: './item-form.component.html',
  styleUrls: ['./item-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ItemFormComponent implements OnInit, OnDestroy, AfterViewInit {
  private dataSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable();
  private _unsubscribeAll: Subject<any>;
  private dataSubscription: Subscription;
  private fileSubscription: Subscription;

  @BlockUI() blockUI: NgBlockUI;
  componentName: string;

  // customDropzoneComponent: CustomDropzoneComponent;
  FileTypeFilter = FileTypeFilter;

  maxLengthOfValue: number = this._mainConfig.maxLengthOfValue;

  apiPath: string;
  pathUrl: string;
  groupPathUrl: string;
  contentHeader: object;
  itemID: string;
  countMedia: number;
  brandId: any = null;
  groupId: any = null;
  categoryId: any = null;
  subCategoryId: any = null;
  classId: any = null;
  subClassId: any = null;
  isSubmit: boolean = false;
  itemBarcode: string = 'itemBarcode';
  ImageUploadComponent = {
    selected: false, // Replace this with the actual logic or value that sets the 'selected' variable in the ImageUploadComponent
  };
  BrandObjList: any[] = [];
  GroupObjList: Group[];
  categoryList: Category[] = [];
  subCategoryList: SubCategory[] = [];
  classList: Class[] = [];
  subClassList: SubClass[] = [];

  isLoadingBrand: boolean;
  isLoadingGroup: Boolean;
  isLoadingCategory: Boolean;
  isLoadingSubCategory: Boolean;
  isLoadingClass: Boolean;
  isLoadingSubClass: Boolean;

  images: Blob[] = [];
  passData: File[] = [];
  imageUrls: SafeStyle[] = [];
  files: File[] = [];

  registerForm: FormGroup;

  isEditing: boolean;
  isLoading: boolean;
  isPriceInvalid: boolean = false;

  @Input() itemObj: Item;
  @Input() callbackUrl: string;
  AlertFiles: Boolean;
  summernoteConfig: object;
  modeText: string;
  activeModal: any;
  langAlert: any[];
  formGroup: FormGroup;
  filesPropName = filesPropNameConfig;

  @ViewChild('confirmModal') confirmModal: ModalComponent;
  @ViewChild('imageUploadComponent') imageUploadComponent: ImageUploadComponent;
  @ViewChild('CustomDropzone') dropzoneComponent: CustomDropzoneComponent;

  @Input() selectedLang: string = environment.langDefault;
  langList: string[] = environment.langContent;

  constructor(
    private sanitizer: DomSanitizer,
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _modalService: NgbModal,
    private _mainConfig: MainConfig,
    private _contentTemplateService: GroupService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _fileService: FilesApiService,
    private _currencyPipe: CurrencyPipe,
    private _globalFuncService: GlobalFuncService
  ) {
    this.SetLoadingState();
    this.blockUI.start();
    this._unsubscribeAll = new Subject();
    this.componentName = 'Item';
    this.apiPath = 'item';
    this.countMedia = 5;
    this.langAlert = [];
    this.AlertFiles = false;
    this.groupPathUrl = this._contentTemplateService.pathUrl;
    this.pathUrl = this._contentTemplateService.pathUrl;
    this.summernoteConfig = this._mainConfig.summernoteNewsConfig;
    this._apiService.SetHttpHeaders(this._authenticationService.tokenValue);

    this.registerForm = this._formBuilder.group({
      id: [null],
      itemCode: [null],
      groupId: [null],
      categoryId: [null],
      subCategoryId: [null],
      classId: [null],
      subClassId: [null],
      itemBarcode: [''],
      name: ['', [Validators.required, Validators.maxLength(100)]],
      brandId: [null],
      purchasePrice: ['0.00', [Validators.required]],
      sellingPrice: ['0.00', [Validators.required]],
      repoveSV: ['0.00', [Validators.required]],
      taxRate: [null],
      vendorName: [null],
      package: [null],
      unit: [null],
      quantity: ['0.00', [Validators.required]],
      dimension: [null],
      weight: [null],
      volume: [null],
      color: [null],
      material: [null],
      texture: [null],
      isActive: [true],
      isShowing: [true],
      isBestSeller: [false],
      isNewProduct: [false],
    });

    const langDetailControls: any = {};
    const langDescControls: any = {};
    this.langList.forEach((lang) => {
      for (const lang of this.langList) {
        langDetailControls[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);

        langDescControls[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);
      }
      this.registerForm.addControl(
        'detail',
        this._formBuilder.group(langDetailControls)
      );

      this.registerForm.addControl(
        'description',
        this._formBuilder.group(langDescControls)
      );
    });
  }
  get f() {
    return this.registerForm.controls;
  }

  onSelectImage(event) {
    console.log('Test' + this.images);
    this.AlertFiles = false;
    var self = this;
    var invalidFiles = false;
    for (let i = 0; i < event.addedFiles.length; i++) {
      if (event.addedFiles.length <= this.countMedia) {
        console.log(event.addedFiles.length + this.images.length);
        if (event.addedFiles.length + this.images.length > this.countMedia) {
          this.AlertFiles = true;
        }
        const file = event.addedFiles[i];
        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            self.images.push(file);
          };
          img.src = URL.createObjectURL(file);
        };
        reader.readAsDataURL(file);
      } else {
        console.log('Media files limit 5 items');
        this.AlertFiles = true;
      }
    }
    // this.files.push(...event.addedFiles);
  }
  getBackgroundStyle(image: Blob): SafeStyle {
    const url = URL.createObjectURL(image);
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }
  onSelectAtteched(event, arr) {
    var invalidFiles = false;
    for (let i = 0; i < event.addedFiles.length; i++) {
      const file = event.addedFiles[i];
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          arr.push(file);
        };
        img.src = URL.createObjectURL(file);
      };
      reader.readAsDataURL(file);
    }
    // this.files.push(...event.addedFiles);
  }
  onDragStart(event: DragEvent, index: number) {
    event.dataTransfer?.setData('text/plain', String(index));
    console.log(event);
  }

  onDragEnter(event: DragEvent, targetIndex: number) {
    console.log(event);
    event.preventDefault();
    const dragIndex = Number(event.dataTransfer?.getData('text/plain'));
    if (dragIndex !== targetIndex) {
      // Reorder the array elements
      const [draggedItem] = this.images.splice(dragIndex, 1);
      this.images.splice(targetIndex, 0, draggedItem);

      // Update the imageUrls array accordingly
      const [draggedImageUrl] = this.images.splice(dragIndex, 1);
      this.images.splice(targetIndex, 0, draggedImageUrl);
    }
  }

  onDragEnd(event: DragEvent) {
    event.preventDefault();
  }
  onRemoveAtteched(event, arr) {
    arr.splice(arr.indexOf(event), 1);
    if ((arr.length + this.images.length) / 2 <= this.countMedia) {
      this.AlertFiles = false;
    }
    console.log((arr.length + this.images.length) / 2);
  }
  onDrop(event: DragEvent) {
    console.log(event);

    event.preventDefault();
    const index = Number(event.dataTransfer?.getData('text/plain'));
    // Implement your logic here for handling the drop event
    // For example, you can reorder the images based on the drag-and-drop action.
  }

  onDragOver(event: DragEvent) {
    console.log(event);
    event.preventDefault();
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.itemID = this._route.snapshot.paramMap.get('itemID');
    console.log('itemID : ', this.itemID);

    this.isEditing = this.itemID ? true : false;
    this.isLoading = this.isEditing ? true : false;

    this.modeText = this.isEditing
      ? this._translateService.instant('General.Edit')
      : this._translateService.instant('General.Create');

    this.contentHeader = {
      headerTitle: this.modeText + ' ' + this.componentName,
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this.componentName,
            isLink: true,
            link: this.pathUrl,
          },
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  ngAfterViewInit(): void {
    this.initForm(this.isEditing);
  }

  showValue(event) {
    console.log(event);
  }

  initForm(isEditing: boolean): void {
    this.getBrandData();
    this.getGroupData();

    if (isEditing) {
      // this.itemObj = new GroupItem();

      // this.getMediaData(this.itemID);
      this.getItemData(this.itemID).then(() => {
        console.log('loaded');
        this.blockUI.stop();
      });
    } else {
      this.itemObj = new Item();
      this.blockUI.stop();
      this.isLoading = false;
      // this.buildForm();
    }
  }

  buildForm(): void {
    var self = this;

    this.brandId = self.itemObj.brandId ? self.itemObj.brandId : 'All';

    this.groupId = self.itemObj.groupId ? self.itemObj.groupId : 'All';
    this.getCategoryData(self.itemObj.groupId);

    this.categoryId = self.itemObj.categoryId ? self.itemObj.categoryId : 'All';
    this.getSubCategoryData(self.itemObj.categoryId);

    this.subCategoryId = self.itemObj.subCategoryId
      ? self.itemObj.subCategoryId
      : 'All';
    this.getClassData(self.itemObj.subCategoryId);

    this.classId = self.itemObj.classId ? self.itemObj.classId : 'All';
    this.getSubClassData(self.itemObj.classId);

    this.subClassId = self.itemObj.subClassId ? self.itemObj.subClassId : 'All';

    this.itemBarcode = self.itemObj.itemBarcode;

    let tempDescriptionObj = JSON.parse(self.itemObj.description);
    let tempDetailObj = JSON.parse(self.itemObj.detail);
    this.langList.forEach((lang) => {
      this.registerForm
        .get(`description.${lang}`)
        .patchValue(tempDescriptionObj[lang]);

      this.registerForm.get(`detail.${lang}`).patchValue(tempDetailObj[lang]);
    });

    this.registerForm.patchValue({
      id: self.itemObj.id,
      itemCode: self.itemObj.itemCode,
      groupId: self.itemObj.groupId,
      categoryId: self.itemObj.categoryId,
      subCategoryId: self.itemObj.subCategoryId,
      classId: self.itemObj.classId,
      subClassId: self.itemObj.subClassId,
      itemBarcode: self.itemObj.itemBarcode,
      name: self.itemObj.name,
      brandId: self.itemObj.brandId,
      purchasePrice: this._currencyPipe.transform(
        self.itemObj.purchasePrice,
        '',
        '',
        '1.2-2'
      ),
      sellingPrice: this._currencyPipe.transform(
        self.itemObj.sellingPrice,
        '',
        '',
        '1.2-2'
      ),
      repoveSV: this._currencyPipe.transform(
        self.itemObj.repoveSV,
        '',
        '',
        '1.2-2'
      ),
      taxRate: self.itemObj.taxRate,
      // description: self.itemObj.description,
      // detail: self.itemObj.detail,

      quantity: this._currencyPipe.transform(
        self.itemObj.quantity,
        '',
        '',
        '1.2-2'
      ),
      dimension: self.itemObj.dimension,
      weight: self.itemObj.weight,
      volume: self.itemObj.volume,
      isActive: self.itemObj.isActive,
      isShowing: self.itemObj.isShowing,
      isNewProduct: self.itemObj.isNewProduct,
      isBestSeller: self.itemObj.isBestSeller,
    });

    this.SetLoadedState();
    this.blockUI.stop();
  }

  getItemData(guid: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataSubscription = this._apiService
        .GetDataById(this.apiPath, guid)
        .subscribe(
          (res) => {
            const self = this;
            self.itemObj = res.data.resultData[0];
            this.buildForm();

            if (self.itemObj.mediaFiles[self.filesPropName.item.coverImage]) {
              this._fileService
                .GetFile(
                  self.itemObj.mediaFiles[self.filesPropName.item.coverImage][0]
                    .id
                )
                .subscribe(
                  (res: Blob) => {
                    if (self.imageUploadComponent) {
                      self.imageUploadComponent.setCoverImage(
                        res,
                        self.itemObj.mediaFiles[
                          self.filesPropName.item.coverImage
                        ][0].id
                      );
                    }
                  },
                  (err) => {
                    this.blockUI.stop();
                  }
                );
            }

            if (self.itemObj.mediaFiles[self.filesPropName.item.detailImages]) {
              /* add loading บางครั้งรูปไม่โหลด */

              let detailImageArr =
                self.itemObj.mediaFiles[self.filesPropName.item.detailImages];
              let tempFilesList: { file: any; ordinal: number }[] = [];
              self.passData = [];

              // self.dropzoneComponent.startLoading();

              const fileFetchPromises: Promise<void>[] = detailImageArr.map(
                (element: { id: string; ordinal: number }) => {
                  return new Promise<void>((fileResolve, fileReject) => {
                    self._fileService.GetFile(element.id).subscribe(
                      (res) => {
                        const filename = element;
                        const type = res.type.split('/')[1];
                        const name = element + '.' + type;
                        const file = new File([res], name, { type: res.type });

                        tempFilesList.push({
                          file: file,
                          ordinal: element.ordinal,
                        });
                        fileResolve();
                      },
                      (error) => {
                        fileReject(error);
                      }
                    );
                  });
                }
              );
              Promise.all(fileFetchPromises)
                .then(() => {
                  if (tempFilesList.length == detailImageArr.length) {
                    tempFilesList.sort((a, b) => a.ordinal - b.ordinal);

                    tempFilesList.forEach((element) => {
                      this.images.push(element.file);
                      this.passData.push(element.file);
                    });

                    this.dropzoneComponent.ngOnChangesConfig(self.passData);
                  }

                  this.blockUI.stop();
                  resolve();
                })
                .catch((error) => {
                  this.blockUI.stop();
                  reject(error);
                });
            } else {
              this.blockUI.stop();
              resolve();
            }
            self.itemObj.purchasePrice = parseFloat(
              self.itemObj.purchasePrice.toFixed(2)
            );
            this.blockUI.stop();
          },
          (error) => {
            this.blockUI.stop();
            reject(error);
          }
        );
    });
  }

  getBrandData(): Promise<any> {
    this.isLoadingBrand = true;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`brand`, { isDelete: false })
        .subscribe((res) => {
          this.BrandObjList = res.data.resultData;
          console.log(this.BrandObjList);
          this.isLoading = false;
          this.isLoadingBrand = false;
        }, rejects);
    });
  }

  getGroupData(): Promise<any> {
    this.isLoadingGroup = true;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`group`, { isDelete: false })
        .subscribe((res) => {
          this.GroupObjList = res.data.resultData;
          console.log(this.GroupObjList);
          this.isLoading = false;
          this.isLoadingGroup = false;
        }, rejects);
    });
  }

  openConfirmModal(content: any): void {
    console.log(this.images);

    if (this.images.length < 1) {
      this.AlertFiles = true;
    } else {
      this.AlertFiles = false;
    }
    this.isPriceInvalid = false;
    console.log(this.registerForm.value);

    let purchasePrice = this._globalFuncService.MoneyFormatToNumber(
      this.f.purchasePrice.value
    );
    let sellingPrice = this._globalFuncService.MoneyFormatToNumber(
      this.f.sellingPrice.value
    );
    let repoveSV = this._globalFuncService.MoneyFormatToNumber(
      this.f.repoveSV.value
    );

    if (
      purchasePrice > sellingPrice ||
      !this.isWithinRange(purchasePrice, 0, this.maxLengthOfValue) ||
      !this.isWithinRange(sellingPrice, 0, this.maxLengthOfValue)
    ) {
      this.isPriceInvalid = true;
      // return;
    }

    let isConfirm = true;
    let title;
    let detail;
    let contentNameText = this.isEditing ? content.name : this.componentName;
    if (this.isEditing) {
      title = this._translateService.instant('ManageItem.ConfirmEditTitle');
      detail = this._translateService.instant('ManageItem.ConfirmEdit');
    } else {
      title = this._translateService.instant('ManageItem.ConfirmCreateTitle');
      detail = this._translateService.instant('ManageItem.ConfirmCreate');
    }

    this.openModal(title, detail, isConfirm);
  }

  openCancelModal(content: any): void {
    let isConfirm = false;
    let title;
    let detail;
    let contentNameText = this.isEditing
      ? this.itemObj.name
      : this.componentName;
    if (this.isEditing) {
      title = this._translateService.instant('ManageItem.CancelEditTitle');
      detail = this._translateService.instant('ManageItem.CancelEdit');
    } else {
      title = this._translateService.instant('ManageItem.CancelCreateTitle');
      detail = this._translateService.instant('ManageItem.CancelCreate');
    }

    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this._router.navigate([this.callbackUrl]);
    });
  }

  isWithinRange(value: number, min: number, max: number): boolean {
    return value >= 0 && value <= max;
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    const alertTest = [];
    this.langList.forEach((lang) => {
      if (
        this.registerForm.get(`description.${lang}` || `detail.${lang}`).invalid
      ) {
        alertTest.push(this._translateService.instant('Alert.lang') + lang);
      }
    });
    this.langAlert = alertTest;
    console.log(alertTest);

    this.isSubmit = true;
    if (this.images.length <= 0) {
      this.dropzoneComponent.handle();
    }
    this.imageUploadComponent.handleImage();

    console.log(this.AlertFiles);
    if (!this.AlertFiles) {
      if (!IsConfirm) {
        const modalRef = this._modalService.open(ModalComponent, {
          centered: true,
          backdrop: 'static',
        });
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.isConfirm = IsConfirm;
        modalRef.componentInstance.detail = detail;
        modalRef.componentInstance.callBackFunc.subscribe((res) => {
          if (IsConfirm) {
            this.submit();
          }
        });
      } else {
        if (!this.registerForm.invalid) {
          const modalRef = this._modalService.open(ModalComponent, {
            centered: true,
            backdrop: 'static',
          });
          modalRef.componentInstance.title = title;
          modalRef.componentInstance.isConfirm = IsConfirm;
          modalRef.componentInstance.detail = detail;
          modalRef.componentInstance.callBackFunc.subscribe((res) => {
            if (IsConfirm) {
              this.submit();
            }
          });
        } else {
          console.log('INVALID นะจ๊ะ');
          //this line
        }
      }
    } else {
      this.scrollToFirstInvalidControl();
    }
  }

  restrictInput(event: KeyboardEvent) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /[0-9]|\./;

    // Allow only numeric input and certain control keys
    if (!regex.test(keyValue) && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
    }
  }

  handleBlur(fieldName: string) {
    const fieldControl = this.registerForm.get(fieldName);
    if (
      fieldControl.valid &&
      fieldControl.value !== null &&
      fieldControl.value !== undefined
    ) {
      // fieldControl.setValue(parseFloat(fieldControl.value).toFixed(2));
      // fieldControl.setValue(
      //   this._decimalPipe.transform(parseFloat(fieldControl.value), '1.2-2')
      // );
      const fieldValue = fieldControl.value.toString();
      const fieldValueWithoutCommas = fieldValue.replace(/,/g, '');
      const parsedValue = parseFloat(fieldValueWithoutCommas);
      //const formattedValue = parsedValue.toLocaleString('en-US');
      fieldControl.setValue(this._globalFuncService.FormatToMoney(parsedValue));
    }
  }

  prepareFinalData(data: any, tempForm: FormGroup) {
    for (let controlName in data.controls) {
      tempForm.addControl(
        controlName,
        this._formBuilder.control(data.get(controlName).value)
      );
    }

    let tempDescriptionObj = {};
    this.langList.forEach((lang) => {
      tempDescriptionObj[lang] = this.registerForm.get(
        `description.${lang}`
      ).value;
    });

    tempForm.removeControl('description');
    tempForm.addControl(
      'description',
      this._formBuilder.control(JSON.stringify(tempDescriptionObj), [])
    );

    let tempDetailObj = {};
    this.langList.forEach((lang) => {
      tempDetailObj[lang] = this.registerForm.get(`detail.${lang}`).value;
    });

    tempForm.removeControl('detail');
    tempForm.addControl(
      'detail',
      this._formBuilder.control(JSON.stringify(tempDetailObj), [])
    );

    if (
      this.f.brandId.value == null ||
      this.f.brandId.value == 'All' ||
      !this.f.brandId.value
    ) {
      this.registerForm.patchValue({ brandId: null });
      tempForm.patchValue({ brandId: null });
    }

    if (
      this.f.groupId.value == null ||
      this.f.groupId.value == 'All' ||
      !this.f.groupId.value
    ) {
      this.registerForm.patchValue({ groupId: null });
      tempForm.patchValue({ groupId: null });
    }
    if (
      this.f.categoryId.value == null ||
      this.f.categoryId.value == 'All' ||
      !this.f.categoryId.value
    ) {
      this.registerForm.patchValue({ categoryId: null });
      tempForm.patchValue({ categoryId: null });
    }
    if (
      this.f.subCategoryId.value == null ||
      this.f.subCategoryId.value == 'All' ||
      !this.f.subCategoryId.value
    ) {
      this.registerForm.patchValue({ subCategoryId: null });
      tempForm.patchValue({ subCategoryId: null });
    }
    if (
      this.f.classId.value == null ||
      this.f.classId.value == 'All' ||
      !this.f.classId.value
    ) {
      this.registerForm.patchValue({ classId: null });
      tempForm.patchValue({ classId: null });
    }
    if (
      this.f.subClassId.value == null ||
      this.f.subClassId.value == 'All' ||
      !this.f.subClassId.value
    ) {
      this.registerForm.patchValue({ subClassId: null });
      tempForm.patchValue({ subClassId: null });
    }

    let tempQuantity = parseFloat(
      this.registerForm.value.quantity.replace(/,/g, '')
    ).toFixed(2);
    let temppurchasePrice = parseFloat(
      this.registerForm.value.purchasePrice.replace(/,/g, '')
    ).toFixed(2);
    let tempsellingPrice = parseFloat(
      this.registerForm.value.sellingPrice.replace(/,/g, '')
    ).toFixed(2);
    let tempRepoveSV = parseFloat(
      this.registerForm.value.repoveSV.replace(/,/g, '')
    ).toFixed(2);

    tempForm.patchValue({ quantity: tempQuantity });
    tempForm.patchValue({ purchasePrice: temppurchasePrice });
    tempForm.patchValue({ sellingPrice: tempsellingPrice });
    tempForm.patchValue({ repoveSV: tempRepoveSV });
  }

  submit(): void {
    var self = this;
    let isUpdatefile;
    this.blockUI.start();
    console.log(this.registerForm.value);

    if (this.registerForm.invalid) {
      let title = this._translateService.instant('Alert.FormValidationFailed');
      this._componentsService.ErrorSwal(title);
    }

    let tempForm = new FormGroup({});
    this.prepareFinalData(this.registerForm, tempForm);

    if (this.isEditing) {
      isUpdatefile = true;
      this._apiService
        .UpdateDataById(
          this.apiPath,
          this.itemObj.id.toString(),
          tempForm.value
        )
        .subscribe(
          (res) => {
            console.log('res from update');
            const params = {
              isEdit: true,
              idResId: res.data.resultData[0],
              tablename: this.apiPath,
              collectionName: this.filesPropName.item.coverImage,
              ordinal: 0,
            };
            this.imageUploadComponent.uploadImage(params);

            if (self.images.length >= 0) {
              self.uploadFiles(
                isUpdatefile,
                self.itemObj.id.toString(),
                this.filesPropName.item.detailImages,
                0,
                self.images
              );
            }
            this._router.navigate([this.callbackUrl]);
            self._componentsService.SuccessSwal();
          },
          (err) => {
            self._componentsService.ErrorSwal();
          }
        );

      this._apiService
        .SetIsActiveData(
          this.apiPath,
          this.itemObj.id.toString(),
          this.registerForm.controls.isActive.value
        )
        .subscribe((res) => {
          return;
        });

      this._apiService
        .SetIsShowing(
          this.apiPath,
          this.itemObj.id.toString(),
          this.registerForm.controls.isShowing.value
        )
        .subscribe((res) => {
          return;
        });
    } else {
      isUpdatefile = false;

      this._apiService.AddData(this.apiPath, tempForm.value).subscribe(
        (res) => {
          console.log('DOM : ' + res.data.resultData);

          const params = {
            isEdit: false,
            idResId: res.data.resultData[0],
            tablename: this.apiPath,
            collectionName: this.filesPropName.item.coverImage,
            ordinal: 0,
          };

          // cover file upload
          this.imageUploadComponent.uploadImage(params);

          // multiple picture upload
          if (self.images.length > 0) {
            let guid = res.data.resultData[0];
            self.uploadFiles(
              isUpdatefile,
              guid,
              self.filesPropName.item.detailImages,
              0,
              self.images
            );
          }

          this._apiService
            .SetIsActiveData(
              this.apiPath,
              res.data.resultData[0],
              this.registerForm.controls.isActive.value
            )
            .subscribe((res) => {
              return;
            });

          this._apiService
            .SetIsShowing(
              this.apiPath,
              res.data.resultData[0],
              this.registerForm.controls.isShowing.value
            )
            .subscribe((res) => {
              return;
            });

          this._router.navigate([this.callbackUrl]);
          self._componentsService.SuccessSwal();
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
    }
  }
  onFilesUploaded(files: File[]): void {
    // Here you have access to the files array uploaded from the child component.
    // You can now handle the files in the parent component as you desire.
    console.log('Uploaded files:', files);
    this.images = files;
  }

  uploadCoverFile(isUpdateFile: boolean, itemGuid: string, formData): void {
    // let itemGuid = this.itemObj.id.toString();
    console.log(formData);
    if (isUpdateFile) {
      this._fileService
        .UpdateCover(
          this.apiPath,
          itemGuid,
          this.filesPropName.item.coverImage,
          0,
          formData
        )
        .subscribe((res) => {});
    } else {
      this._fileService
        .AddCover(
          this.apiPath,
          itemGuid,
          this.filesPropName.item.coverImage,
          0,
          formData
        )
        .subscribe((res) => {});
    }
  }

  uploadFiles(
    isUpdateFile: boolean,
    itemGuid: string,
    collectionName: string,
    ordinal: number,
    filePool: any
  ): void {
    let fileArr = new FormData();
    filePool.forEach((file: Blob) => {
      // let fullFileName = file.name

      fileArr.append('files', file);
    });

    if (isUpdateFile) {
      this._fileService
        .UpdateFile(
          this.apiPath,
          this.itemObj.id.toString(),
          collectionName,
          ordinal,
          fileArr
        )
        .subscribe((res) => {
          // this._componentsService.SuccessSwal();
          return;
        });
    } else {
      this._fileService
        .AddFile(this.apiPath, itemGuid, collectionName, ordinal, fileArr)
        .subscribe((res) => {
          // this._componentsService.SuccessSwal();
          return;
        });
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.blockUI.stop();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.dataSubject.next({});
    this.dataSubject.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.fileSubscription) {
      this.fileSubscription.unsubscribe();
    }
  }

  getCategoryData(selectedGroupId): void {
    var self = this;
    self.isLoadingCategory = true;
    console.log('getCategoryData');

    this.resetCategory();
    this.resetSubCategory();
    this.resetClass();
    this.resetSubClass();

    this._apiService
      .GetAllData('category', {
        groupId: selectedGroupId,
        isDelete: false,
        includeMediaURL: false,
      })
      .subscribe((res) => {
        self.categoryList = res.data.resultData;
        this.isLoadingCategory = false;
      });
  }

  getSubCategoryData(selectedCategoryId): void {
    this.isLoadingSubCategory = true;
    var self = this;
    this.resetSubCategory();
    this.resetClass();
    this.resetSubClass();

    this._apiService
      .GetAllData('subcategory', {
        categoryId: selectedCategoryId,
        isDelete: false,
        includeMediaURL: false,
      })
      .subscribe((res) => {
        self.subCategoryList = res.data.resultData;
        this.isLoadingSubCategory = false;
      });
  }

  getClassData(selectedSubCategoryId): void {
    var self = this;
    self.isLoadingClass = true;
    this.resetClass();
    this.resetSubClass();

    this._apiService
      .GetAllData('class', {
        subCategoryId: selectedSubCategoryId,
        isDelete: false,
        includeMediaURL: false,
      })
      .subscribe((res) => {
        self.classList = res.data.resultData;
        self.isLoadingClass = false;
      });
  }

  getSubClassData(selectedClassId): void {
    var self = this;
    self.isLoadingSubCategory = true;
    this.resetSubClass();

    if (selectedClassId) {
      this._apiService
        .GetAllData('subclass', {
          classId: selectedClassId,
          isDelete: false,
          includeMediaURL: false,
        })
        .subscribe((res) => {
          self.subClassList = res.data.resultData;
          self.isLoadingSubCategory = false;
        });
    }
  }

  setFormatMoney(amount: string, absolute?: boolean): string {
    let formattedAmount = amount.toLocaleString();

    const moneyPattern = /^-?(\d{1,3}(,\d{3})*|\d+)(\.\d+)?$/;
    let isMoney = moneyPattern.test(formattedAmount);
    if (!isMoney) {
      formattedAmount = this.convertNumbertoMoneyFormat(0);
      console.log('FFFFFalse');
      return;
    }
    formattedAmount = formattedAmount.replace(/,/g, '');
    formattedAmount = this._currencyPipe.transform(formattedAmount, '');
    return formattedAmount;
  }

  convertNumbertoMoneyFormat(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }

  resetGroup(): void {
    this.groupId = null;
  }

  resetCategory(): void {
    this.categoryId = null;
    this.categoryList = [];
  }

  resetSubCategory(): void {
    this.subCategoryId = null;
    this.subCategoryList = [];
  }

  resetClass(): void {
    this.classId = null;
    this.classList = [];
  }

  resetSubClass(): void {
    this.subClassId = null;
    this.subClassList = [];
  }

  private scrollToFirstInvalidControl() {
    this._globalFuncService.scrollToFirstInvalidControl('item-form');
  }

  checkFirstLetterInvalid(text, fControl: FormControl) {
    if (this._globalFuncService.checkFirstLetterisInvalid(text)) {
      fControl.setErrors({ incorrect: true });
      return true;
    }
  }
}
