import { Injectable } from '@angular/core';
import { TimelineItem } from 'app/main/components/horizontal-timeline/horizontal-timeline.component';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private dataNote: any = {};
  private docPaperData: any = {};

  public defaultTransactionArgument: any = environment.transaction.shippingFee;

  timelineData: TimelineItem[] = [
    { label: 'Draft', descript: 'ร่าง', stepCount: 1 },
    { label: 'Accepted', descript: 'ยืนยัน', stepCount: 2 },
    { label: 'Wait Payment', descript: 'รอชำระเงิน', stepCount: 3 },
    { label: 'Payment', descript: 'ชำระเงินสำเร็จ', stepCount: 4 },
    { label: 'Completed', descript: 'เสร็จสิ้น', stepCount: 5 },
  ];

  timelineBuyData: TimelineItem[] = [
    { label: 'Draft', descript: 'ร่าง', stepCount: 1 },
    { label: 'Accepted', descript: 'ยืนยัน', stepCount: 2 },
    { label: 'Wait Payment', descript: 'รอชำระเงิน', stepCount: 3 },
    { label: 'Payment', descript: 'ชำระเงินสำเร็จ', stepCount: 4 },
    { label: 'Get a Receipt', descript: 'รับใบเสร็จแล้ว', stepCount: 5 },
  ];

  quotation = {
    ApiPath: 'Quotation',
    PathURL: '/document/quotation',
    EditPathURL: '/document/quotation/edit',
    DetailPathURL: '/document/quotation/detail',
    DetailExport: '/document/quotation/detail/export',
    ComponentsName: 'quotation',
  };

  saleOrder = {
    ApiPath: 'SaleOrder',
    PathURL: '/document/saleorder',
    EditPathURL: '/document/quotation/edit',
    DetailPathURL: '/document/saleorder/detail',
    ComponentsName: 'saleorder',
  };

  invoice = {
    ApiPath: 'Invoice',
    RefDocPath: 'SaleOrder',
    PathURL: '/document/invoice',
    EditPathURL: '/document/invoice/edit',
    DetailPathURL: '/document/invoice/detail',
    DetailExport: '/document/invoice/detail/export',
    ComponentsName: 'invoice',
  };

  Tax = {
    ApiPath: 'TaxInvoice',
    PathURL: '/document/tax',
    EditPathURL: '/document/tax/edit',
    DetailPathURL: '/document/tax/detail',
    DetailExport: '/document/tax/detail/export',
    ComponentsName: 'tax',
  };

  receipt = {
    ApiPath: 'Receipt',
    PathURL: '/document/receipt',
    EditPathURL: '/document/receipt/edit',
    DetailPathURL: '/document/receipt/detail',
    DetailExport: '/document/receipt/detail/export',
    ComponentsName: 'Receipt',
  };

  creditNote = {
    ApiPath: 'CreditNote',
    RefDocPath: 'Invoice',
    PathURL: '/document/credit-note',
    EditPathURL: '/document/credit-note/edit',
    DetailPathURL: '/document/credit-note/detail',
    DetailExport: '/document/credit-note/detail/export',
    ComponentsName: 'Credit Note',
  };

  debitNote = {
    ApiPath: 'DebitNote',
    RefDocPath: 'Invoice',
    PathURL: '/document/debit-note',
    EditPathURL: '/document/debit-note/edit',
    DetailPathURL: '/document/debit-note/detail',
    DetailExport: '/document/debit-note/detail/export',
    ComponentsName: 'Debit Note',
  };

  paymentType = {
    ApiPath: 'PaymentType',
    PathURL: '/document/invoice',
    EditPathURL: '/document/invoice/edit',
    DetailPathURL: '/document/invoice/detail',
    DetailExport: '/document/invoice/detail/export',
    ComponentsName: 'Debit Note',
  };

  returnSlip = {
    ApiPath: 'Invoice',
    PathURL: '/document/invoice',
    EditPathURL: '/document/invoice/edit',
    DetailPathURL: '/document/invoice/detail',
    DetailExport: '/document/invoice/detail/export',
    ComponentsName: 'Debit Note',
  };

  purchaseRequest = {
    ApiPath: 'PurchaseRequisition',
    PathURL: '/document/purchase-request',
    EditPathURL: '/document/purchase-request/edit',
    DetailPathURL: '/document/purchase-request/detail',
    DetailExport: '/document/purchase-request/detail/export',
    ComponentsName: 'Purschase-request',
  };

  purchaseOrder = {
    ApiPath: 'PurchaseOrder',
    PathURL: '/document/purchase-request',
    // EditPathURL: '/document/purchase-request/edit',
    DetailPathURL: '/document/purchase-order/detail',
    DetailExport: '/document/purchase-order/detail/export',
    ComponentsName: 'purchase-order',
  };
  GoodsReceive = {
    ApiPath: 'GoodsReceive',
    RefDocPath: 'PurchaseOrder',
    PathURL: '/document/goods-receive',
    EditPathURL: '/document/purchase-request/edit',
    DetailPathURL: '/document/goods-receive/detail',
    DetailExport: '/document/goods-receive/detail/export',
    ComponentsName: 'goods-receive',
  };

  constructor() {
  }

  setDataNote(key: string, data: any) {
    this.dataNote[key] = data;
  }

  getDataNote() {
    return this.dataNote;
  }

  clearDataNote() {
    this.dataNote = {};
  }

  async setDefaultArgument(data: any): Promise<void> {
    this.defaultTransactionArgument = data;
  }

  async getDefaultArgument(): Promise<any> {
    return this.defaultTransactionArgument;
  }

  clearDefaultArgument() {
    this.defaultTransactionArgument = {};
  }

  getDocPaperData() {
    return this.docPaperData;
  }

  setDocPaperData(data: any) {
    this.docPaperData = data;
  }

  clearDocPaperData() {
    this.docPaperData = {};
  }
}
