import { Component, OnInit } from '@angular/core';
import { SubcategoryService } from '../subcategory.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-subcategory-formpage',
  templateUrl: './subcategory-formpage.component.html',
  styleUrls: ['./subcategory-formpage.component.scss'],
})
export class SubcategoryFormpageComponent implements OnInit {
  contentHeader: object;
  pathUrl: string;
  constructor(
    private _translateService: TranslateService,
    private _subCategoryService: SubcategoryService
  ) {
    this.pathUrl = this._subCategoryService.pathUrl;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'General.SubCategory',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.SubCategory',
            isLink: true,
            link: '/item-manage/subcategory',
          },
          {
            name: 'General.Create',
            isLink: false,
          },
        ],
      },
    };
  }
}
