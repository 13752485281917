import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { Invoice, Order } from '../../../../model/Order';
import { ApiService } from '../../../../service/api.service';
import { FilesApiService } from '../../../../service/files-api.service';
import { RelatedDocModalComponent } from '../../../../components/related-doc-modal/related-doc-modal.component';
import { GlobalFuncService } from '../../../../service/global-func.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonResponse } from '../../../../model/CommonResponse';
import { ApprovalModalComponent } from '../../booking-approval/approval-modal/approval-modal.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ComponentsService } from '../../../../components/components.service';
import { emit } from 'process';
import { ReturnProductStatusEnum } from '../../../../model/ReturnProduct';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

declare var GLightbox: any;

@Component({
  selector: 'app-return-product-detail-modal',
  templateUrl: './return-product-detail-modal.component.html',
  styleUrls: ['./return-product-detail-modal.component.scss'],
})
export class ReturnProductDetailModalComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  isSubmit: boolean = false;
  isLoading: boolean = false;
  @Output() cancelFunc: EventEmitter<any> = new EventEmitter();
  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();
  @Output() voidInvoice: EventEmitter<any> = new EventEmitter();
  @Input() invoice: Invoice;
  refundDetail: any;
  imageEvidenceList: any[] = [];

  currentLang: string = '';

  stateEnum = ReturnProductStatusEnum;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public activeModal: NgbActiveModal,
    private _apiService: ApiService,
    private _modalService: NgbModal,
    private _fileApiService: FilesApiService,
    private _globalFuncService: GlobalFuncService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.getInvoiceDetail().then((res) => {
      this.invoice = res.data.resultData[0];
      this.imageEvidenceList = [];

      if (res.data.resultData[0].mediaFiles.returnGoodsFile) {
        this.getEvidenceFile(res.data.resultData[0].mediaFiles.returnGoodsFile);
        setTimeout(() => {
          const evidenceLightBox = new GLightbox({
            selector: '.glightbox',
            closeOnOutsideClick: true,

            // width: 'auto',
            // height: 'auto',
          });
        }, 300);
      }

      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getEvidenceFile(mediaFiles: { id: string; ordinal: number }[]) {
    // const filePromises: Promise<void>[] = mediaFiles.map(
    //   (element: { id: string; ordinal: number }, index) => {
    //     return new Promise<void>((resolve) => {
    //       this._fileApiService.GetFile(element.id).subscribe((res) => {
    //         if (res.type.startsWith('image/')) {
    //           const blob = new Blob([res], { type: 'image/*' });
    //           const img = new Image();
    //           img.src = URL.createObjectURL(blob);

    //           this.imageEvidenceList.push({
    //             file: img,
    //             ordinal: element.ordinal,
    //           });
    //         }
    //         resolve();
    //       });
    //     });
    //   }
    // );

    mediaFiles.map((f) => {
      let imgPath: string = this._fileApiService.getImage(f.id);

      this.imageEvidenceList.push({
        file: imgPath,
        ordinal: f.ordinal,
      });
    });

    this.imageEvidenceList.sort((a, b) => a.ordinal - b.ordinal);
  }

  closeModal(): void {
    this.activeModal.close();
  }

  cancel(): void {
    let modalRef = this._modalService.open(ApprovalModalComponent, {
      centered: true,
    });

    modalRef.componentInstance.title = 'Rejects Return Products';
    modalRef.componentInstance.detail =
      'Are you sure to rejects this return product request';
    modalRef.componentInstance.callBackFunc.subscribe((remarks) => {
      this.blockUI.start();
      this.updateReturnState({
        returnState: 2,
        returnRemarks: remarks,
      }).then(
        (r) => {
          this.blockUI.stop();
          this._componentsService.SuccessSwal();
          this.callBackFunc.emit();
          this.closeModal();
        },
        (err) => {
          this._componentsService.ErrorSwal();
          this.blockUI.stop();
        }
      );
    });
  }

  callBack(): void {
    this.isSubmit = true;

    let modalRef = this._modalService.open(ApprovalModalComponent, {
      centered: true,
    });

    modalRef.componentInstance.title = 'Approve Refund';
    modalRef.componentInstance.detail =
      'Are you sure to approve this return product request';
    modalRef.componentInstance.callBackFunc.subscribe((remarks) => {
      this.blockUI.start();
      this.updateReturnState({
        returnState: 1,
        returnRemarks: remarks,
      }).then(
        (r) => {
          this.blockUI.stop();
          this._componentsService.SuccessSwal();
          this.callBackFunc.emit();
          this.closeModal();
        },
        (err) => {
          this._componentsService.ErrorSwal();
          this.blockUI.stop();
        }
      );
    });

    // this.callBackFunc.emit();
    // this.closeModal();
  }

  getImages(guid: string, params): string {
    return this._fileApiService.getImage(guid, params);
  }

  openRelatedDocModal(id: string, orderCodeColumn: string) {
    const modalRef = this._modalService.open(RelatedDocModalComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
    });

    let isBuyOrder = orderCodeColumn == 'buyOrderCode' ? true : false;

    console.log(orderCodeColumn);

    modalRef.componentInstance.orderId = id;
    modalRef.componentInstance.isBuyOrder = isBuyOrder;
  }

  getStatusLabel(stateEnum: number): any {
    switch (stateEnum) {
      case ReturnProductStatusEnum.WaitApprove:
        return this._translateService.instant(
          'BookingCalendar.Status.WaitApprove'
        );
      case ReturnProductStatusEnum.Approved:
        return this._translateService.instant(
          'BookingCalendar.Status.Approved'
        );
      case ReturnProductStatusEnum.Reject:
        return this._translateService.instant('BookingCalendar.Status.Reject');

      default:
        return this._translateService.instant(
          'BookingCalendar.Status.WaitApprove'
        );
    }
  }

  getInvoiceDetail(): Promise<CommonResponse<Invoice>> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetDataById('invoice', this.invoice.id)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<Invoice>) => {
            resolve(res);
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  updateReturnState(data: {
    returnState: number;
    returnRemarks: string;
  }): Promise<CommonResponse<any>> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .AddData(`invoice/${this.invoice.id}/ReturnState`, data)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<Invoice>) => {
            resolve(res);
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  voidDocumentModal(invoice: Invoice) {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = this._translateService.instant(
      'ReturnProduct.ClaimModalTitle'
    );
    modalRef.componentInstance.detail = this._translateService.instant(
      'ReturnProduct.ClaimModalDetail'
    );
    modalRef.componentInstance.callBackFunc.subscribe(() => {
      this.blockUI.start();
      this.CallVoidDocument(invoice)
        .then(() => {
          this.getInvoiceDetail()
          this.blockUI.stop();
          this._componentsService.SuccessSwal();
          this.callBackFunc.emit();
        })
        .catch(() => {
          this.blockUI.stop();
          this._componentsService.ErrorSwal();
        });
    });
  }

  CallVoidDocument(invoice: Invoice): Promise<void> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .patch(`order/${invoice.orderId}/claim`)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<any>) => {
            resolve();
          },
          (err) => {
            rejects();
          }
        );
    });
  }
}
