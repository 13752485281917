import { Injectable } from '@angular/core';
import { ICMSConfig } from './CMSInterface';


@Injectable({
  providedIn: 'root',
})
export class CmsFormConfig {
  config: { [key: string]: ICMSConfig } = {
  };
}
