import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { CoreConfig } from '@core/types';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from 'app/main/components/components.service';
import { AccountApiService } from 'app/main/service/account-api.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SignupComponent implements OnInit {
  public coreConfig: CoreConfig;

  private _unsubscribeAll: Subject<any>;

  registerForm: FormGroup;

  isSubmitted: boolean;
  passwordTextType: boolean;

  isLoading: boolean;
  isEmailValid: boolean;
  isEmailVerified: boolean;
  isPasswordValid: boolean;
  isUsernameValid: boolean;
  notEmailDuplicate: boolean;
  notUsernameDuplicate: boolean;

  errorMsg: string;

  private passwordPattern = /^(?=.*[A-Z]).{8,}$/;

  constructor(
    private _coreConfigService: CoreConfigService,
    private _route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _accountApiService: AccountApiService,
    private _router: Router,
    private _componentsService: ComponentsService,
    private _translateService: TranslateService,
  ) {
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        animation: 'fadeIn',
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }

  ngOnInit(): void {
    this.initForm();

    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  get f() {
    return this.registerForm.controls;
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  initForm(): void {
    this.registerForm = this._formBuilder.group({
      username: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(25),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
        ],
      ],
      password: ['', [Validators.required, this.passwordValidator()]],
    });
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;
      const hasUppercase = /[A-Z]/.test(value);
      const hasValidLength = value.length >= 8;

      if (!hasUppercase || !hasValidLength) {
        return { passwordRequirements: true };
      }

      return null;
    };
  }

  Submit(): void {
    var self = this;
    this.SetLoadingState();
    this.isSubmitted = true;

    if (this.registerForm.invalid) {
      this.SetLoadedState();
      return;
    }

    this._accountApiService
      .CreateAccount(this.registerForm.value)
      .subscribe((res) => {
        console.log(res);
        console.log(this.registerForm.value);
        if (res.success) {
          let result = res.data.resultData[0];

          self.isEmailValid = result.isEmailValid;
          self.isEmailVerified = result.isEmailVerified;
          self.isPasswordValid = result.isPasswordValid;
          self.isUsernameValid = result.isUsernameValid;
          self.notEmailDuplicate = result.notEmailDuplicate;
          self.notUsernameDuplicate = result.notUsernameDuplicate;

          console.log('isEmailValid: ' + self.isEmailValid);
          console.log('isEmailVerified: ' + self.isEmailVerified);
          console.log('isPasswordValid: ' + self.isPasswordValid);
          console.log('isUsernameValid: ' + self.isUsernameValid);
          console.log('notEmailDuplicate: ' + self.notEmailDuplicate);
          console.log('notUsernameDuplicate: ' + self.notUsernameDuplicate);

          if (
            self.isEmailValid &&
            !self.isEmailVerified &&
            self.isPasswordValid &&
            self.isUsernameValid &&
            self.notEmailDuplicate &&
            self.notUsernameDuplicate
          ) {
            let title = this._translateService.instant(
              'Alert.SignUpComplete'
            );
            this._componentsService.SuccessSwal(title);
            this._router
              .navigate([`verify?email=${self.f.email.value}`])
              .then(() => {});
          } else {
            this.errorMsg = 'invalid data';
            this.SetLoadedState();
            console.log(this.errorMsg);
          }
        } else {
          this.SetLoadedState();
          return;
        }
      });
  }
}
