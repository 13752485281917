import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  componentName: string = 'Content';
  apiPath: string = 'content';
  pathUrl: string = '/content-template';

  constructor() { }


}
