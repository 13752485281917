import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'app/main/service/api.service';
import { combineLatest, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalComponent } from '../modal/modal.component';
import { TranslateService } from '@ngx-translate/core';
import Item from 'app/main/model/Item';
import { HttpClient } from '@angular/common/http';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ComponentsService } from '../components.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { StockType, kStockType } from 'app/main/model/StockType';
import ItemMovement from 'app/main/model/ItemMovement';
import { environment } from 'environments/environment';

export enum AdjustType {
  Remove = 0,
  Add = 1,
}

export enum AdjustMode {
  Adjust = 0,
  Transfer = 1,
}

@Component({
  selector: 'app-adjust-item-modal',
  templateUrl: './adjust-item-modal.component.html',
  styleUrls: ['./adjust-item-modal.component.scss'],
})
export class AdjustItemModalComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() public modalConfig: NgbModalConfig;

  @Input() itemObj: Item;
  @Input() confirmUrl: string;

  @Input() childItemsList: Item[];
  @Input() lastItemMovementObj: ItemMovement;

  isLoading: boolean;
  @Input() isItemSet: boolean;
  @Input() setMode: boolean;

  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();

  adjustForm: FormGroup;
  transferForm: FormGroup;
  adjustType = AdjustType;
  adjustMode = AdjustMode;

  stockType = StockType;
  kStockType = kStockType;

  @BlockUI() blockUI: NgBlockUI;

  stockFromQuantityBefore: string = '0.00';
  stockFromQuantityAfter: string = '0.00';

  stockToQuantityBefore: string = '0.00';
  stockToQuantityAfter: string = '0.00';

  isInitedTransferForm: boolean = false;

  mode: number = 0;

  isQuantityDecimal: boolean = environment.stock.isQuantityDecimal;

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private _apiService: ApiService,
    private _formBuilder: FormBuilder,
    private _modalService: NgbModal,
    private _translateService: TranslateService,
    private _http: HttpClient,
    private _componentsService: ComponentsService,
    private _globalFuncService: GlobalFuncService
  ) {
    this.adjustForm = this._formBuilder.group({
      adjustType: 1,
      stockType: this.stockType.FG,
      quantity: [
        this.isQuantityDecimal ? '0.00' : '0',
        [this.nonNegativeNumberValidator()],
      ],
      remark: '',
    });

    this.transferForm = this._formBuilder.group({
      stockTypeFrom: this.stockType.FG,
      stockTypeTo: this.stockType.Claim,
      quantity: [
        this.isQuantityDecimal ? '0.00' : '0',
        [this.nonNegativeNumberValidator()],
      ],
      remark: '',
    });
  }

  initTransferForm() {
    if (!this.isInitedTransferForm) {
      this.onSelectStockType(true);
      this.onSelectStockType(false);
      this.isInitedTransferForm = true;
    }
  }

  selectMode(id: number) {
    this.mode = id;
  }

  nonNegativeNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = this.formatMoneyToNumber(control.value);
      if (value !== null && value !== undefined && isNaN(value)) {
        return { nonNegativeNumber: true };
      }

      return value < 0 ? { nonNegativeNumber: true } : null;
    };
  }

  closeModal(): void {
    this.activeModal.close();
  }

  callBack(): void {
    this.blockUI.start();
    console.log('before', this.adjustForm.value);
    console.log('before', this.transferForm.value);
    this.adjustForm.patchValue({
      quantity: this.formatMoneyToNumber(
        this.adjustForm.controls.quantity.value
      ),
    });
    this.transferForm.patchValue({
      quantity: this.formatMoneyToNumber(
        this.transferForm.controls.quantity.value
      ),
    });
    console.log('after', this.adjustForm.value);
    console.log('after', this.transferForm.value);

    const formGroup =
      this.mode == AdjustMode.Transfer
        ? this.transferForm.value
        : this.adjustForm.value;

    let url = '';
    if (this.mode == AdjustMode.Transfer) {
      url = `${this._apiService.API_URL}/itemMovement/Transfer/StockType/Item/${this.itemObj.id}`;
    } else {
      url = this.setMode
        ? `${this._apiService.API_URL}/itemMovement/Adjust/Set/Item/${this.itemObj.id}`
        : `${this._apiService.API_URL}/itemMovement/Adjust/Item/${this.itemObj.id}`;
    }

    this._http
      .post(url, formGroup, {
        headers: this._apiService.API_HEADERS,
      })
      .subscribe(
        (res: any) => {
          this.handleSuccess();
        },
        (err) => {
          this.handleError();
        }
      );

    // this.callBackFunc.emit();
    // this.closeModal();
    // this.router.navigate([this.confirmUrl]);
  }

  onSelectStockType(isFrom: boolean) {
    let stockTypeControl = isFrom
      ? this.transferForm.controls.stockTypeFrom
      : this.transferForm.controls.stockTypeTo;
    let quantityValue = this.transferForm.controls.quantity.value;
    let stockTypeValue = stockTypeControl.value;

    let propertyName = `${kStockType[
      stockTypeValue - 1
    ].name.toLowerCase()}Quantity`;
    let originalQuantity = this.lastItemMovementObj[propertyName];

    let result = isFrom
      ? originalQuantity -
        this._globalFuncService.MoneyFormatToNumber(quantityValue)
      : originalQuantity +
        this._globalFuncService.MoneyFormatToNumber(quantityValue);

    let beforeElement = isFrom
      ? this.stockFromQuantityBefore
      : this.stockToQuantityBefore;
    let afterElement = isFrom
      ? this.stockFromQuantityAfter
      : this.stockToQuantityAfter;

    if (isFrom) {
      this.stockFromQuantityBefore = this._globalFuncService.FormatToMoney(
        originalQuantity,
        null,
        !this.isQuantityDecimal
      );
      this.stockFromQuantityAfter = this._globalFuncService.FormatToMoney(
        result,
        null,
        !this.isQuantityDecimal
      );
    } else {
      this.stockToQuantityBefore = this._globalFuncService.FormatToMoney(
        originalQuantity,
        null,
        !this.isQuantityDecimal
      );
      this.stockToQuantityAfter = this._globalFuncService.FormatToMoney(
        result,
        null,
        !this.isQuantityDecimal
      );
    }
  }

  SwapStockType() {
    let stockTypeFromControl = this.transferForm.controls.stockTypeFrom;
    let stockTypeToControl = this.transferForm.controls.stockTypeTo;

    let tempValue = stockTypeFromControl.value;
    stockTypeFromControl.setValue(stockTypeToControl.value);
    stockTypeToControl.setValue(tempValue);

    [this.stockFromQuantityBefore, this.stockToQuantityBefore] = [
      this.stockToQuantityBefore,
      this.stockFromQuantityBefore,
    ];
    [this.stockFromQuantityAfter, this.stockToQuantityAfter] = [
      this.stockToQuantityAfter,
      this.stockFromQuantityAfter,
    ];
  }

  private handleSuccess() {
    this.blockUI.stop();
    this.callBackFunc.emit();
    this._componentsService.SuccessSwal();
    this.closeModal();
  }

  private handleError() {
    this.blockUI.stop();
    this._componentsService.ErrorSwal();
  }

  get f() {
    return this.adjustForm.controls;
  }

  openCancelModal(): void {
    let isConfirm = false;
    let title;
    let detail;

    title = 'Cancel Adjust Item';
    detail = 'Are you sure to cancel adjust item?';
    // title = this._translateService.instant('ManageItemSet.CancelCreateTitle');
    // detail = this._translateService.instant('ManageItemSet.CancelCreate');

    this.openModal(title, detail, isConfirm);
  }

  openConfirmModal(): void {
    let title;
    let detail;
    let isConfirm = true;

    if (this.mode == this.adjustMode.Transfer) {
      if (
        this.formatMoneyToNumber(this.transferForm.controls.quantity.value) < 0
      ) {
        return;
      }
    } else {
      if (
        this.formatMoneyToNumber(this.adjustForm.controls.quantity.value) < 0
      ) {
        return;
      }
    }

    title = 'Confirm Adjust Item';
    detail = 'Are you sure to confirm adjust item?';
    // title = this._translateService.instant('ManageItemSet.ConfirmCreateTitle');
    // detail = this._translateService.instant('ManageItemSet.ConfirmCreate');

    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    // ก้อปมาจาก role
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      if (IsConfirm) {
        this.callBack();
      } else {
        this.closeModal();
      }
      // this._router.navigate([this.callbackUrl]);
    });
  }

  getQuantityToUse(guid: string): number {
    const tempItem = this.findItemByGuid(guid);
    return tempItem ? tempItem.quantity : 0;
  }

  getQuantityUsed(guid: string): number {
    const quantity = this.f.quantity.value;
    const tempItem = this.findItemByGuid(guid);
    return tempItem
      ? tempItem.quantity * this.formatMoneyToNumber(quantity)
      : 0;
  }

  getStockTypeQuantity(name: string): number {
    return this.lastItemMovementObj[`${name}Quantity`];
  }

  updateTransferQuantity() {
    this.onSelectStockType(true);
    this.onSelectStockType(false);
  }

  checkQuantity(): boolean {
    const quantity = this.f.quantity.value;

    return this.itemObj.itemSet.every((itemChild) => {
      const usedQuantity = itemChild.quantity;
      const tempItem = this.findItemByGuid(itemChild.id);
      return tempItem
        ? this.formatMoneyToNumber(quantity) * usedQuantity <= tempItem.quantity
        : true;
    });
  }

  private findItemByGuid(guid: string) {
    return this.itemObj.itemSet.find((item) => item.ingredientItemId === guid);
  }

  ngOnChanges(changes: SimpleChanges): void {}

  setAdjustType(value): void {}

  formatNumber(id: string) {
    let quantityInput = document.getElementById(id) as HTMLFormElement;
    let tempValue = this.formatMoneyToNumber(quantityInput.value);
    quantityInput.value = this.formatNumberToMoney(tempValue);
    console.log(tempValue);
  }

  private formatNumberToMoney(value: number): string {
    console.log(value);
    return this._globalFuncService.FormatToMoney(
      value,
      null,
      !this.isQuantityDecimal
    );
  }
  private formatMoneyToNumber(value: string): number {
    console.log(value);
    return this._globalFuncService.MoneyFormatToNumber(value);
  }

  ngOnInit(): void {
    console.log('childItemsList', this.childItemsList);
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}
}
