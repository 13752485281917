import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { ApiService } from 'app/main/service/api.service';
import { AddressType } from '../customer-addr/customer-addr.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-customer-addr-modal',
  templateUrl: './customer-addr-modal.component.html',
  styleUrls: ['./customer-addr-modal.component.scss'],
})
export class CustomerAddrModalComponent implements OnInit, AfterViewInit {
  @Input() addrObj: CustomerAddress;
  @Input() customerId: string;
  @Input() isEditing: boolean = false;
  @Input() targetType: number;
  @Input() defaultBilling: CustomerAddress;

  @BlockUI() blockUI: NgBlockUI;

  addressType = AddressType;

  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();

  @ViewChildren('inputElements') inputElements: QueryList<ElementRef>;

  formGroup: FormGroup;

  provinceList: any[] = [];
  districtList: any[] = [];
  subDistrictList: any[] = [];

  isSubmit: boolean;
  isLoadingProvince: boolean;
  isLoadingDistrict: boolean;
  isLoadingSubDistrict: boolean;
  currentLang: string = 'en';

  isLegalAddr: boolean = false;

  index_pid: number[];
  otpLengthArray: number[];
  check_pid_empty: boolean;
  clipboardData: any;

  isBusiness: boolean = false;

  provinceName: any;
  districtName: any;
  subDistrictName: any;

  isDisabledSetDefault: boolean = false;
  isValidTaxId: boolean;
  isCheckedTaxId: boolean = false;
  isCheckingTaxId: boolean = false;

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder,
    private _apiService: ApiService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _modalService: NgbModal,
    private _http: HttpClient
  ) {
    this.formGroup = this._formBuilder.group({
      p_id: [null],
      c_business: [null],
      c_fname: [null, Validators.required],
      c_lname: [null],
      c_phone: [null, Validators.required],
      c_address: [null, Validators.required],
      c_country: ['', Validators.required],
      c_province: [null, Validators.required],
      c_district: [null, Validators.required],
      c_subDistrict: [null, Validators.required],
      c_postcode: [null, Validators.required],
      isLegalAddr: [false],
      branchNumber: [null, [Validators.maxLength(5)]],
      branchName: [],
      branchType: ['1'],
      useForShipping: [],
      useForBilling: [],
      isDefault: [],
    });

    this.index_pid = [
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
    ];

    this.clipboardData = '';
    this.otpLengthArray = Array(13)
      .fill(0)
      .map((_, index) => index);

    this.currentLang = this._translateService.currentLang;
  }

  initForm() {
    this.getProvince();
    this.getDistrict(this.addrObj.provinceId);
    this.getSubDistrict(this.addrObj.districtId);
  }

  closeModal(): void {
    this.activeModal.close();
  }

  validatePID(value) {
    const regex = /NaN/;

    if (regex.test(value)) {
      this.check_pid_empty = true;
    }
  }

  onInput(event: any, index: number): void {
    this.check_pid_empty = false;
    this.isCheckedTaxId = false;
    this.isValidTaxId = false;
    const inputs = document.querySelectorAll('#ContactPersonalID > *[id]');
    const currentInput = inputs[index] as HTMLInputElement;
    currentInput.value = currentInput.value.toUpperCase();
    const inputValue: string = currentInput.value;
    const parsedValue: number = parseInt(inputValue, 10);
    this.index_pid[index] = parsedValue;
    console.log(this.index_pid[index]);

    console.log('ข้อมูล pid : ' + this.index_pid);
    console.log('ค่าของตำแหน่งปัจจุบัน : ' + currentInput.value);
    console.log('ตำแหน่งที่ : ' + index);
    if (index === inputs.length - 1 && currentInput.value !== '') {
      return;
    } else if (currentInput.value !== '') {
      const nextInput = inputs[index + 1] as HTMLInputElement;
      nextInput.focus();
    }
  }

  onKeyDown(event: KeyboardEvent, index: number): void {
    const inputs = document.querySelectorAll('#ContactPersonalID > *[id]');
    const currentInput = inputs[index] as HTMLInputElement;
    // if (event.metaKey && event.key === 'v') {
    //   console.log('Hi');

    //   this.getDataFromClipboard();
    // }
    if (event.key === 'Backspace') {
      if (currentInput.value === '') {
        if (index !== 0) {
          const previousInput = inputs[index - 1] as HTMLInputElement;
          previousInput.focus();
        }
      } else {
        this.index_pid[index] = NaN;
        currentInput.value = '';
      }
    } else if (event.key === 'ArrowLeft' && index !== 0) {
      const previousInput = inputs[index - 1] as HTMLInputElement;
      previousInput.focus();
    } else if (event.key === 'ArrowRight' && index !== inputs.length - 1) {
      const nextInput = inputs[index + 1] as HTMLInputElement;
      nextInput.focus();
    } else if (event.key === 'ArrowUp' && index !== inputs.length - 1) {
      const nextInput = inputs[index] as HTMLInputElement;
      nextInput.focus();
    } else if (event.key === 'ArrowDown' && index !== inputs.length - 1) {
      const nextInput = inputs[index] as HTMLInputElement;
      nextInput.focus();
    } else if (event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
      currentInput.value = '';
      setTimeout(() => {}, 1000);
    }
  }

  async onPaste(): Promise<void> {
    const inputs = document.querySelectorAll('#ContactPersonalID > *[id]');

    await new Promise<void>((resolve) => {
      inputs.forEach((input: any) => {
        input.value = '';
      });

      resolve();
    });

    this.getDataFromClipboard();
  }

  getDataFromClipboard(): void {
    const inputs = document.querySelectorAll('#ContactPersonalID > *[id]');
    console.log(inputs);

    navigator.clipboard
      .readText()
      .then((clipboardData: string) => {
        const dataArray = clipboardData.split('\n');
        const regex = /\d+/g;
        const matches = dataArray[0].match(regex);

        if (matches) {
          const only_int = matches.map((match) => parseInt(match));
          const str_num = only_int.join('').toString();

          // loop to set inputs.value is str_num
          this.index_pid = [
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
            NaN,
          ];
          this.index_pid.forEach((input: any, index: number) => {
            if (str_num[index]) {
              inputs.forEach((input: any, index: number) => {
                input.value = '';
                if (str_num[index]) {
                  input.value = str_num[index].toString();
                }
              });
              this.index_pid[index] = str_num.split('').map(Number)[index];
            }
          });
        }
      })
      .catch((error: Error) => {
        console.error('Failed to read clipboard data:', error);
      });
  }

  get f() {
    return this.formGroup.controls;
  }

  getProvince(): Promise<any> {
    this.isLoadingProvince = true;

    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterProvince`, {
          SortPath: 'provinceName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.provinceList = res.data.resultData;
          this.isLoadingProvince = false;

          console.log('getProvince');
        }, rejects);
    });
  }

  isSelectProvice(id: string) {
    this.formGroup.get('c_district').setValue(null);
    this.formGroup.get('c_subDistrict').setValue(null);
    this.formGroup.get('c_postcode').setValue(null);

    this.districtList = [];
    this.subDistrictList = [];
    this.getDistrict(id);
    let tempProvice = this.provinceList.find((val) => (val.id = id));
    this.provinceName = tempProvice.provinceName;
  }

  getDistrict(id): Promise<any> {
    this.isLoadingDistrict = true;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterDistrict`, {
          ProvinceId: id,
          SortPath: 'districtName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.districtList = res.data.resultData;
          this.isLoadingDistrict = false;
        }, rejects);
    });
  }

  isSelectDistrict(id: string) {
    this.formGroup.get('c_subDistrict').setValue(null);
    this.formGroup.get('c_postcode').setValue(null);
    this.subDistrictList = [];
    this.getSubDistrict(id);
    let tempDistrict = this.districtList.find((val) => (val.id = id));
    this.districtName = tempDistrict.districtName;
  }

  getSubDistrict(id): Promise<any> {
    this.isLoadingSubDistrict = true;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterSubDistrict`, {
          districtId: id,
          SortPath: 'subDistrictName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.subDistrictList = res.data.resultData;
          this.isLoadingSubDistrict = false;
        }, rejects);
      return true;
    });
  }

  getPostCode(event: any): Promise<any> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetDataById(`MasterSubDistrict`, event)
        .subscribe((res) => {
          console.log(res.data.resultData[0].subDistrictPostCode);
          this.formGroup.patchValue({
            c_postcode: res.data.resultData[0].subDistrictPostCode,
          });
        }, rejects);
    });
  }

  callBack(): void {
    var self = this;

    let tempObj: any = {};
    const taxIdsString: string = this.index_pid.join('');

    tempObj = {
      customerId: self.customerId,
      businessName:
        self.f.isLegalAddr.value == true ? self.f.c_fname.value : '',
      firstName: self.f.isLegalAddr.value == false ? self.f.c_fname.value : '',
      lastName: self.f.isLegalAddr.value == false ? self.f.c_lname.value : '',
      taxId: taxIdsString,
      isHeadBranch: self.f.branchType.value == '1' ? true : false,
      branchNumber: self.f.isLegalAddr.value
        ? parseInt(self.f.branchNumber.value ? self.f.branchNumber.value : 0)
        : 0,
      branchName: self.f.isLegalAddr.value ? self.f.branchName.value : '',
      detail: '',
      addressNumber: self.f.c_address.value,
      village: '',
      alley: '',
      road: '',
      country: self.f.c_country.value,
      postCode: self.f.c_postcode.value,
      provinceId: self.f.c_province.value,
      provinceName: self.provinceName,
      districtId: self.f.c_district.value,
      districtName: self.districtName,
      subDistrictId: self.f.c_subDistrict.value,
      subDistrictName: self.subDistrictName,
      useForShipping: self.targetType == AddressType.Shipping ? true : false,
      useForBilling: self.targetType == AddressType.Billing ? true : false,
      useForContact: self.targetType == AddressType.Contact ? true : false,
      isLegalAddr: self.f.isLegalAddr.value,
      addrLatitude: 0,
      addrLongitude: 0,
      phone: self.f.c_phone.value,
      fax: '',
    };

    this.isSubmit = true;
    if (self.targetType == AddressType.Billing) {
      this.validatePID(taxIdsString);

      if (!this.isCheckedTaxId || !this.isValidTaxId) {
        return
      }

    }

    if (
      this.formGroup.invalid ||
      this.check_pid_empty ||
      (!this.f.isLegalAddr.value && !this.f.c_lname.value) ||
      (this.f.isLegalAddr.value &&
        this.f.branchType.value != '1' &&
        (!this.f.branchName.value ||
          !this.f.branchNumber.value ||
          this.f.branchNumber.value == '00000'))
    ) {
      return;
    }

    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.title = 'ยืนยันการบันทึกข้อมูล';
    modalRef.componentInstance.detail =
      'คุณต้องการที่จะบันทึกข้อมูลที่อยู่นี้หรือไม่';
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.submit(tempObj);
    });

    // this.callBackFunc.emit(tempObj);
    // this.closeModal();
    // this.router.navigate([this.confirmUrl]);
  }

  ngOnInit(): void {
    this.initForm();
    const input: HTMLInputElement = document.getElementById(
      'PersonalID'
    ) as HTMLInputElement;
    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    console.log(this.targetType);
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if (this.isEditing) {
      this.setFormValue(this.addrObj);
    } else {
      this.addrObj = new CustomerAddress();
    }
  }

  isBusinessType(value) {
    this.isBusiness = value;
  }

  copyFromBilling() {
    this.setFormValue(this.defaultBilling);

    this.getDistrict(this.defaultBilling.provinceId)
    this.getSubDistrict(this.defaultBilling.districtId)
    this.getPostCode(this.defaultBilling.subDistrictId)

    console.log(this.defaultBilling);
    console.log(this.formGroup.value);
    
  }

  setFormValue(obj: CustomerAddress) {
    console.log(obj);
    
    console.log(this.addrObj);
    var self = this;

    if (obj.taxId) {
      this.index_pid = obj.taxId.split('');

      const inputs = document.querySelectorAll('#ContactPersonalID > *[id]');
      console.log(inputs);
      if (inputs.length === self.index_pid.length) {
        for (let i = 0; i < inputs.length; i++) {
          var currentInput = inputs[i] as HTMLInputElement;
          currentInput.value = self.index_pid[i].toString();
        }
      } else {
        console.error(
          'Number of inputs does not match the length of index_id.'
        );
      }
      this.check_pid_empty = false;
      this.isCheckedTaxId = true;
      this.isValidTaxId = true;
      console.log(this.index_pid);
    }

    if (obj.isDefault) {
      this.isDisabledSetDefault = true;
    }

    this.formGroup.patchValue({
      c_fname: obj.isLegalAddr ? obj.businessName : obj.firstName,
      c_lname: obj.lastName,

      c_phone: obj.phone,
      c_address: obj.addressNumber,
      c_country: obj.country,
      c_province: obj.provinceId,
      c_district: obj.districtId,
      c_subDistrict: obj.subDistrictId,
      c_postcode: obj.postCode,
      isLegalAddr: obj.isLegalAddr,
      branchNumber: obj.branchNumber,
      branchName: obj.branchName,
      branchType: obj.isHeadBranch ? '1' : '2',
      useForShipping: obj.useForShipping,
      useForBilling: obj.useForBilling,
      isDefault: obj.isDefault,
    });

    console.log(this.formGroup.value, obj)
  }

  submit(obj) {
    this.blockUI.start();

    if (this.isEditing) {
      this._apiService
        .UpdateDataById('customerAddress', this.addrObj.id, obj)
        .subscribe(
          (res) => {
            if (this.f.isDefault.value === true) {
              this._apiService
                .SetIsDefault('customerAddress', this.addrObj.id)
                .subscribe((res) => {});
            }
            this.callBackFunc.emit();
            this.blockUI.stop();
            this._componentsService.SuccessSwal().then(() => {
              this.closeModal();
            });
          },
          (err) => {
            this.blockUI.stop();
            this._componentsService.ErrorSwal();
          }
        );
    } else {
      this._apiService.AddData('customerAddress', obj).subscribe(
        (res) => {
          if (this.f.isDefault.value === true) {
            this._apiService
              .SetIsDefault('customerAddress', res.data.resultData[0])
              .subscribe((res) => {});
          }
          this.callBackFunc.emit();
          this.blockUI.stop();
          this._componentsService.SuccessSwal().then(() => {
            this.closeModal();
          });
        },
        (err) => {
          this.blockUI.stop();
          this._componentsService.ErrorSwal();
        }
      );
    }
  }

  checkDuplicateTaxId() {
    this.isCheckingTaxId = true;

    const API_URL = this._apiService.API_URL;
    const API_HEADER = this._apiService.API_HEADERS;
    const taxIdsString: string = this.index_pid.join('');

    this.validatePID(taxIdsString);

    if (!this.check_pid_empty || this.targetType == this.addressType.Shipping) {
      const body = {
        taxId: taxIdsString,
        useForEnum: 1,
      };
      setTimeout(() => {
        this._http
          .post(
            `${API_URL}/customer/TaxDuplicateCheck/${this.customerId}`,
            body,
            {
              headers: API_HEADER,
            }
          )
          .subscribe((res: any) => {
            this.isValidTaxId = res.data.resultData[0];
            this.isCheckingTaxId = false;
            this.isCheckedTaxId = true;
          });
      }, 500);
      
    } else {
      this.isCheckingTaxId = false;
      this.isCheckedTaxId = true;
    }

  }
}
