<div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">
        {{ 'ReturnProduct.ReturnRequest' | translate }}
    </h5>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">
            <i data-feather="x" size="22"></i>
        </span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="isLoading">
        <div class="d-flex justify-content-center align-items-center" style="height: 500px;">
            <div class="demo-inline-spacing">
                <div class="spinner-border text-primary" style="width: 3rem; height: 3rem" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>

    <div class="row return-product-page" *ngIf="!isLoading">
        <div class="col-12 col-lg-6">
            <ng-container *ngTemplateOutlet="refundTopic"></ng-container>
            <ng-container *ngTemplateOutlet="refundDetail"></ng-container>
            <ng-container *ngTemplateOutlet="refundHistory"></ng-container>

        </div>
        <div class="col-12 col-lg-6">
            <ng-container *ngIf="invoice.item.length >= 1">
                <ng-container *ngTemplateOutlet="itemTable"></ng-container>
            </ng-container>

        </div>
    </div>

</div>
<div class="modal-footer justify-content-between" *ngIf="!invoice.isVoid">
    <div>
        <button type="button" class="btn btn-danger m-0" (click)="cancel()"
            *ngIf="invoice.returnState != stateEnum.Reject" rippleEffect>
            {{ 'General.Rejects' | translate }}
        </button>

    </div>

    <div>
        <button type="button" class="btn btn-success m-0" (click)="callBack()"
            *ngIf="invoice.returnState != stateEnum.Approved" rippleEffect>
            {{ 'General.Approve' | translate }}
        </button>
    </div>
</div>

<ng-template #refundTopic>

    <h5 class="general-title">{{ 'ReturnProduct.ReturnRequest' | translate }}</h5>
    <div class="row mb-5">
        <div class="col">
            <label for="">{{ 'ReturnProduct.InvoiceCode' | translate }}</label>
            <div>
                <a class="text-primary" (click)="openRelatedDocModal(invoice.orderId,'orderCode')"
                    ngbTooltip="เอกสารที่เกี่ยวข้อง">
                    <p>
                        {{ invoice.invoiceCode ? invoice.invoiceCode : '-' }}
                    </p>
                </a>
            </div>

            <label for="">{{ 'ReturnProduct.CreateDate' | translate }}</label>
            <div>
                <p *ngIf="currentLang == 'th'">
                    {{ invoice.createDate | thaidate : 'DD MMM YYYY HH:mm' }} น.</p>
                <p *ngIf="currentLang == 'en'">
                    {{ invoice.createDate | date : 'dd MMM YYYY HH:mm' }}
                </p>
            </div>

            <label for="">{{ 'ReturnProduct.RequestDate' | translate }}</label>
            <div *ngIf="invoice.returnRequestDate">
                <p *ngIf="currentLang == 'th'">
                    {{ invoice.returnRequestDate | thaidate : 'DD MMM YYYY HH:mm' }} น.</p>
                <p *ngIf="currentLang == 'en'">
                    {{ invoice.returnRequestDate | date : 'dd MMM YYYY HH:mm' }}
                </p>
            </div>
            <div *ngIf="!invoice.returnRequestDate">
                <p>-</p>
            </div>
        </div>
        <div class="col">

            <label for="">{{ 'ReturnProduct.RequestBy' | translate }}</label>
            <div>
                <a [routerLink]="['/customer/detail/' + invoice.customerId]" target="_blank"
                    ngbTooltip="{{ invoice.customerName }}">
                    <p class="text-primary text-truncate"> {{ invoice.customerName }}</p>

                </a>
            </div>

            <label for="">{{ 'ReturnProduct.ReturnStatus' | translate }}</label>
            <div>
                <p [ngClass]="{'text-success' : invoice.returnState == stateEnum.Approved,
            'text-warning' : invoice.returnState == stateEnum.WaitApprove,
            'text-danger' : invoice.returnState == stateEnum.Reject }">
                    {{ getStatusLabel(invoice.returnState) }}
                </p>
            </div>


            <label for="">{{ 'ReturnProduct.Remarks' | translate }}</label>
            <ng-container *ngIf="invoice.returnState == stateEnum.Approved">
                <p>{{ invoice.returnApproveRemarks }}</p>
            </ng-container>
            <ng-container *ngIf="invoice.returnState == stateEnum.Reject">
                <p>{{ invoice.returnRejectRemarks }}</p>
            </ng-container>
            <ng-container *ngIf="invoice.returnState == stateEnum.WaitApprove">
                <p>-</p>
            </ng-container>

            <label for="">{{ 'ReturnProduct.CheckedBy' | translate }}</label>
            <ng-container *ngIf="invoice.returnState == stateEnum.Approved">
                <p>{{ invoice.returnApproveBy }}</p>
            </ng-container>
            <ng-container *ngIf="invoice.returnState == stateEnum.Reject">
                <p>{{ invoice.returnRejectBy }}</p>
            </ng-container>
            <ng-container *ngIf="invoice.returnState == stateEnum.WaitApprove">
                <p>-</p>
            </ng-container>
            <button *ngIf="invoice.returnState == stateEnum.Approved && !invoice.isVoid"
                (click)="voidDocumentModal(invoice)" class="btn btn-primary mt-2" style="margin-left: 0.5rem">
                <i data-feather="x-square" class="mr-1"></i>
                {{ 'ReturnProduct.Claim' | translate }}
            </button>



        </div>
    </div>

</ng-template>

<ng-template #itemTable>
    <h5 class="general-title">{{ 'ReturnProduct.ItemList' | translate }}</h5>
    <table class="table">
        <thead>
            <tr>
                <th width="10%">
                    <span class="table-header">{{'DocTable.No' | translate}}</span>
                </th>
                <th width="60%"><span class="table-header">{{'DocTable.Item' | translate }}</span></th>
                <th width="40%" class="text-end "><span class="table-header">{{'DocTable.Price' | translate }}</span>
                </th>
            </tr>
        </thead>
    </table>
    <div class="item-table-wrapper">
        <div class="item-table-scroll">
            <table class="table">
                <tbody>
                    <tr class="w-100" *ngIf="isLoading">
                        <td class="text-center" *ngFor="let num of [].constructor(3)">
                            <div class="skeleton-loading"></div>
                        </td>
                    </tr>

                    <tr *ngIf="invoice.item.length < 1 && !isLoading">
                        <td colspan="100%" class="text-center">
                            <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
                        </td>
                    </tr>

                    <tr *ngFor="let item of invoice.item; index as ind">
                        <td width="10%">{{ ind + 1 }}</td>
                        <td width="60%" class="text-truncate" style="max-width: 200px">
                            <div class="d-flex align-items-center text-truncate primary-color">
                                <img [src]="
                                  item.mediaFiles.coverImage[0].id
                                    ? getImages(item.mediaFiles.coverImage[0].id, {
                                        Width: 60,
                                        Height: 60
                                      })
                                    : defaultIMG
                                " [alt]="defaultIMG" width="60px" height="60px"
                                    style="border-radius: 5px; object-fit: cover" />
                                <div>
                                    <p class="my-auto ml-2">
                                        {{ item.itemName ? item.itemName : '-' }}
                                    </p>
                                    <p class="my-auto ml-2">
                                        <span class="text-dark">{{ 'Stock.Quantity' | translate }}:</span>
                                        {{ item.quantity ? item.quantity : 0 }}
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td width="40%" class="text-end">
                            {{ (item.grandTotal ? item.grandTotal : 0) | number : '1.2-2' }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="detail-row d-flex">
        <div class="col-7">
            <div class="d-flex justify-content-between total-detail-row">
                <p class="my-auto">
                    {{ "ContentDocument.Discount" | translate }}
                </p>
                <div class="d-flex">
                    <p class="ml-1 my-auto">
                        {{ (invoice.isDiscountByPercent ? invoice.percentDiscount :
                        invoice.discount) | number : '1.2-2'}}
                    </p>
                    <p class="ml-2 my-auto">
                        {{ invoice.isDiscountByPercent ? "%" : ("ContentDocument.Baht" | translate) }}
                    </p>
                </div>
            </div>

            <div class="d-flex justify-content-between total-detail-row">
                <p class="my-auto">
                    {{ "DocFooter.DeliveryPrice" | translate }}
                </p>
                <div class="d-flex">
                    <p class="ml-1 my-auto">
                        {{ invoice.preTaxShippingFee | number : "1.2-2" }}
                    </p>
                    <p class="ml-2 my-auto">
                        {{ "ContentDocument.Baht" | translate }}
                    </p>
                </div>
            </div>

            <div class="d-flex justify-content-between total-detail-row">
                <p class="my-auto">
                    {{ "ContentDocument.VatItemValue" | translate }}
                    {{ invoice.taxRate }}%
                </p>
                <div class="d-flex">
                    <p class="ml-1 my-auto" *ngIf="invoice.isDiscountByPercent">
                        {{ invoice.preTaxDiscount | number : '1.2-2' }}
                    </p>
                    <p class="ml-1 my-auto" *ngIf="!invoice.isDiscountByPercent">
                        {{ invoice.preTaxAmountTotal | number : '1.2-2' }}
                    </p>
                    <p class="ml-2 my-auto">
                        {{ "ContentDocument.Baht" | translate }}
                    </p>
                </div>
            </div>

            <div class="d-flex justify-content-between total-detail-row">
                <p class="my-auto">
                    {{ "ContentDocument.VatAmount" | translate }}
                </p>
                <div class="d-flex">
                    <p class="ml-1 my-auto">
                        {{ invoice.salesTax | number : '1.2-2' }}
                    </p>
                    <p class="ml-2 my-auto">
                        {{ "ContentDocument.Baht" | translate }}
                    </p>
                </div>
            </div>
        </div>

        <div class="col-5">
            <div class="d-flex justify-content-between total-detail-row">
                <p class="my-auto">
                    {{ "ContentDocument.NetTotal" | translate }}
                </p>
                <div class="d-flex">
                    <p class="ml-1 my-auto">
                        {{ (invoice.salesWHT != 0 && invoice.salesWHT > 0 ?
                        invoice.preWHTAmountTotal :
                        invoice.grandTotal) | number : '1.2-2' }}
                    </p>
                    <p class="ml-2 my-auto">
                        {{ "ContentDocument.Baht" | translate }}
                    </p>
                </div>
            </div>

            <ng-container *ngIf="invoice.salesWHT > 0">
                <div class="d-flex justify-content-between total-detail-row">
                    <p class="my-auto">
                        {{ "ContentDocument.WithholdingTaxAmount" | translate }}
                    </p>
                    <div class="d-flex">
                        <p class="ml-1 my-auto">
                            {{ invoice.salesWHT | number : '1.2-2' }}
                        </p>
                        <p class="ml-2 my-auto">
                            {{ "ContentDocument.Baht" | translate }}
                        </p>
                    </div>
                </div>

                <div class="d-flex justify-content-between total-detail-row">
                    <p class="my-auto">
                        {{ "ContentDocument.NetAmountToPay" | translate }}
                    </p>
                    <div class="d-flex">
                        <p class="ml-1 my-auto">
                            {{ invoice.grandTotal | number : '1.2-2' }}
                        </p>
                        <p class="ml-2 my-auto">
                            {{ "ContentDocument.Baht" | translate }}
                        </p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <hr>
</ng-template>

<ng-template #refundDetail>
    <h5 class="general-title">{{'ReturnProduct.RequestDetail' | translate}}</h5>
    <div class="row">
        <div class="col">
            <label for="">{{'ReturnProduct.ReturnType' | translate}}</label>
            <p>{{ (invoice.returnType == 0 ? 'ReturnProduct.Refund' : 'ReturnProduct.ReturnProduct') | translate }}</p>
        </div>

    </div>
    <div class="row">
        <div class="col">
            <label for="">{{'ReturnProduct.OtherReasons' | translate}}</label>
            <p>{{ invoice.returnOtherRemarks ? invoice.returnOtherRemarks : '-' }}</p>
        </div>

        <div class="col">
            <label for="">{{'ReturnProduct.Reasons' | translate}}</label>
            <p>{{ invoice.returnReasons ? invoice.returnReasons : '-' }}</p>
        </div>
    </div>


    <label for="">{{'ReturnProduct.AttachmentFile' | translate}}</label>
    <div class="d-flex mt-2">
        <ng-container *ngIf="imageEvidenceList.length >= 1">
            <ng-container *ngFor="let image of imageEvidenceList">
                <!-- data-width="500px" -->
                <a [href]="image.file" class="glightbox" data-type="image">
                    <img [src]="image.file" class="image-evidence" alt="">
                </a>
            </ng-container>
        </ng-container>
    </div>
</ng-template>