import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { DocumentStatus } from 'app/main/model/DocumentStatus';
import { Subject, Subscription } from 'rxjs';
import { DocumentService } from '../document.service';
import { ComponentsService } from 'app/main/components/components.service';
import { ApiService } from 'app/main/service/api.service';
import { CurrencyPipe } from '@angular/common';
import { GlobalFuncService } from 'app/main/service/global-func.service';

@Component({
  selector: 'app-debit-note',
  templateUrl: './debit-note.component.html',
  styleUrls: ['./debit-note.component.scss'],
})
export class DebitNoteComponent implements OnInit {
  contentHeader: object;
  componentName: string;
  currentDate: string;
  currentLang: string;

  apiPath: string;
  pathUrl: string;
  editPathUrl: string;
  detailPathUrl: string;
  itemList: any[] = [];
  isLoading: boolean;
  isGridView: boolean = false;
  isShowChildList: boolean = false;
  isForGroupTemplate: boolean = false;
  isGoEditPage: boolean = false;
  activeStatus: string;
  selectedColumns: string[];
  objectExport: any;
  searchParams: any = {};
  searchName: string;
  searchId: string;
  activeTab: string = 'all';
  docStatus: DocumentStatus;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  statusList: any[] = [];

  searchData: any = {};
  page: number = 1;
  pageSize: number = 10;


  constructor(
    private _translateService: TranslateService,
    private _documentService: DocumentService,
    private _componentsService: ComponentsService,
    private _apiService: ApiService,
    private currencyPipe: CurrencyPipe,
    private _globalFuncService: GlobalFuncService
  ) {
    this.apiPath = this._documentService.debitNote.ApiPath;
    this.pathUrl = this._documentService.debitNote.PathURL;
    this.editPathUrl = this._documentService.debitNote.EditPathURL;
    this.detailPathUrl = this._documentService.debitNote.DetailPathURL;
    this.selectedColumns = [
      'customerId',
      'invoiceCode',
      'documentCreateDate',
      'documentExpireDate',
      'grandTotal',
    ];

    this.statusList = [
      {
        value: { SortPath: 'debitNoteCode', Direction: 1 },
        name: 'all',
        translate: 'General.All',
      },
      {
        value: {
          SortPath: 'debitNoteCode',
          Direction: 1,
          TransactionStatusId: 4,
          isActive: true,
        },
        name: 'draft',
        translate: 'Status.AwaitPayment',
      },
      {
        value: {
          SortPath: 'debitNoteCode',
          Direction: 1,
          TransactionStatusId: 5,
          isActive: true,
        },
        name: 'active',
        translate: 'Status.Paid',
      },
      {
        value: {
          SortPath: 'debitNoteCode',
          Direction: 1,
          TransactionStatusId: 8,
          isActive: true,
        },
        name: 'delete',
        translate: 'Status.Voided',
      },
    ];
    this.initData();
  }

  initData(): void {
    this.SetLoadingState();
    this.searchData = {
      SortPath: this.apiPath + 'Code',
      Direction: 1,
      isDelete: false,
    }
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  getAllData(params?) {
    this.loadData(params);
  }

  loadData(searchData?: any) {
    if (searchData) {
      this.searchData = searchData;
    } else {
      this.searchData = {
        SortPath: this.apiPath + 'Code',
        Direction: 1,
        isDelete: false,
      };
    }

    if (this.dtTrigger) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          this.SetLoadedState();
        });
      });
    }
  }

  getDatabyState(state, tab: string): Promise<any> {
    var self = this;
    self.isLoading = true;
    self.activeTab = tab;
    const searchParams = this.searchParams;

    return new Promise((resolve, reject) => {
      const combinedParams = {
        ...state,
        ...searchParams?.params,
      };

      this._apiService
        .GetAllData(this.apiPath, combinedParams)
        .subscribe((res) => {
          self.itemList = res.data.resultData;
          console.log(this.itemList);
          self.isLoading = false;
          self.SetLoadedState();
          resolve(self.itemList);
        }, reject);
    });
  }

  ngOnInit(): void {
    var self = this;
    const date = new Date();
    this.currentDate = date.toISOString();
    this.contentHeader = {
      headerTitle: 'Document.DebitNote',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Document',
            isLink: true,
            link: '/',
          },
          {
            name: 'Document.DebitNote',
            isLink: false,
            link: '/document/debit-note/view',
          },
        ],
      },
    };

    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: this.pageSize,
      order: [[1, 'desc']],
      ordering: true,
      serverSide: true,
      lengthMenu: [10, 25, 50, 100],
      lengthChange: true,
      dom: '<"float-left"B><"float-right"f>rt<"row"<"col-sm-4"i><"col-sm-4 text-center"l><"col-sm-4"p>>',
      columnDefs: [
        { "orderable": false, "targets": 0 },
        { "name": this.apiPath + 'Code', "targets": 1 },
        { "name": "CustomerName", "targets": 2 },
        { "name": "TransactionStatusId", "targets": 3 },
        { "name": "DocumentCreateDate", "targets": 4 },
        { "name": "DocumentExpireDate", "targets": 5 },
        { "name": "GrandTotal", "targets": 6 },
        { "orderable": false, "targets": 7 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.itemList = [];

        self.SetLoadingState();

        let defaultSortName = this.apiPath + 'Code';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter((item: any) => item.data === order.column)[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        }
        else {
          self.searchData.SortPath = !!column_filter_name ? column_filter_name : defaultSortName;
        }

        this.searchData.Direction = order?.dir == "desc"? 1 : 0;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );

        this.page = this.searchData.Page;

        this.searchData.PageLength = dataTablesParameters.length;
        this.searchData.isDelete = false;

        this._apiService
          .GetAllData(this.apiPath, this.searchData)
          .subscribe((response) => {
            console.log(response);
            this.itemList = response.data.resultData;
            self.SetLoadedState();
            callback({
              recordsTotal: response.data.itemCount,
              recordsFiltered: response.data.totalItem,
              data: [],
            });
          });
      },
    };
  }

  ExportXLSX() {}

  convertNumbertoMoneyFormat(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }
}
