import { Component, OnInit, ViewChild } from '@angular/core';
import { ContentListComponent } from 'app/main/components/content-list/content-list.component';
import { ApiService } from 'app/main/service/api.service';
import { ConsumerReviewService } from './consumer-review.service';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from 'app/main/components/components.service';
import News from 'app/main/model/News';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-consumer-review',
  templateUrl: './consumer-review.component.html',
  styleUrls: ['./consumer-review.component.scss'],
})
export class ConsumerReviewComponent implements OnInit {
  config!: ICMSConfig;
  keyName: string;

  constructor(private _consumerReviewService: ConsumerReviewService) {
    this.config = this._consumerReviewService.config;
    this.keyName = this._consumerReviewService.keyName;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
