import { Component, OnInit } from '@angular/core';
import { User } from '../../../../../../auth/models';
import { TimelineItem } from '../../../../../components/horizontal-timeline/horizontal-timeline.component';
import Customer from '../../../../../model/Customer';
import CustomerAddress from '../../../../../model/CustomerAddress';
import { Quotation } from '../../../../../model/Quotation';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DocumentService } from '../../../document.service';
import { AuthenticationService } from '../../../../../../auth/service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentApiService } from '../../../../../service/document-api.service';
import { ApiService } from '../../../../../service/api.service';
import THBText from 'thai-baht-text';

@Component({
  selector: 'app-receipt-export',
  templateUrl: './receipt-export.component.html',
  styleUrls: ['./receipt-export.component.scss'],
})
export class ReceiptExportComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  // @BlockUI() blockUI: NgBlockUI;
  contentHeader: object;
  passObj: object;
  currentUser: User;
  timelineData: TimelineItem[];
  Excess: Boolean = false;
  quotationObj: Quotation;

  customerAddressObj: CustomerAddress;
  customerObj: Customer;

  itemList: any[];
  apiPath: string;
  editPathUrl: string;
  THBgrantotal: string;
  docID: string;
  isLoading: boolean;
  sumNetPrice: number;
  sumVatPrice: number;

  constructor(
    private _documentService: DocumentService,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _documentApiService: DocumentApiService,
    private _route: ActivatedRoute,
    private _apiService: ApiService,
    private _router: Router
  ) {
    this.apiPath = this._documentService.receipt.ApiPath;
  }

  ngOnInit(): void {
    this.blockUI.start();
    console.log('__________________', this.blockUI);

    var self = this;
    self.initData();

    this._authenticationService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    this.contentHeader = {
      headerTitle: 'Document.Receipt',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }
  printWindow(): void {
    window.print();
  }
  SetLoadingState() {
    this.isLoading = true;
    console.log('STARTTTTTTTTTTT');
  }
  SetLoadedState() {
    this.isLoading = false;
    console.log('LOADED');
    this.blockUI.stop();
  }

  initData(): void {
    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
    });
    console.log('Doc id : ' + this.docID);
    console.log(this.apiPath);

    this.getQuotationData();
  }
  getQuotationData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocData(this.apiPath, this.docID)
        .subscribe((res) => {
          self.quotationObj = res.data.resultData[0];
          console.log('Customer ID', self.quotationObj);

          this.THBgrantotal = THBText(this.quotationObj.grandTotal);

          self.getCustomer(self.quotationObj.customerId);
        });
    });
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData.filter((it) => {
            return it['useForShipping'] == true;
          });

          this.passObj = {
            quotationObj: this.quotationObj,
            titleTh: 'ใบเสร็จรับเงิน',
            titleEn: 'Receipt',
            customerObj: this.customerObj,
            customerAddressObj: this.customerAddressObj[0],
          };
          this.blockUI.stop();
          console.log(this.passObj);
        });
    });
  }

  getCustomer(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService.GetDataById('customer', customerId).subscribe((res) => {
        self.customerObj = res.data.resultData[0];
        self.getCustomerAddress(customerId);
      });
    });
  }

  openCancelModal(): void {
    this._router.navigate(['../'], { relativeTo: this._route });
  }
}
