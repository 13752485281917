import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent } from '../modal/modal.component';
import { ApiService } from 'app/main/service/api.service';
import { ComponentsService } from '../components.service';
import { BranchManageFormComponent } from './branch-manage-form/branch-manage-form.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BranchAction } from 'app/main/model/Branch';
import { filesPropNameConfig } from 'app/main/config/MainConfig';

@Component({
  selector: 'app-modal-manage-branch',
  templateUrl: './modal-manage-branch.component.html',
  styleUrls: ['./modal-manage-branch.component.scss']
})
export class ModalManageBranchComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild(BranchManageFormComponent)
  branchManageFormComponent: BranchManageFormComponent;

  @Input() public modalConfig: NgbModalConfig;
  @Input() actionBranch: string;
  @Input() editData: any = null;

  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();
  @Output() refreshData: EventEmitter<any> = new EventEmitter();

  @BlockUI() blockUI: NgBlockUI;
  
  formData: any;

  isEditing: boolean = false;

  isBranchData: boolean = false;

  isSubmit: boolean = false;
  isCheckInvalidForm: boolean = false;
  filesPropName = filesPropNameConfig;
  
  constructor(
    public activeModal: NgbActiveModal,
    private _translateService: TranslateService,
    private _modalService: NgbModal,
    private _apiService: ApiService,
    private _componentsService: ComponentsService,
  ) { }

  ngOnInit(): void {
    this.checkActionBranch();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngAfterViewInit(): void {
    console.log(this.actionBranch);
  }

  checkActionBranch() {
    switch (this.actionBranch) {
      case BranchAction.Branch.Create:
        this.isBranchData = true;
        break;
      case BranchAction.Branch.Edit:
        this.isBranchData = true;
        this.isEditing = true;
        break;
      default:
        break;
    }
  }

  closeModal(): void {
    this.callBackFunc.emit();
    this.activeModal.close();
  }

  receiveFormData(data: any) {
    this.formData = data;
  }

  openCancelModal(): void {
    let isConfirm = false;
    let title: string;
    let detail: string;

    if (this.isEditing) {
      title = this._translateService.instant(
        'Branch.Modal.CancelEditTitle'
      );
      detail = this._translateService.instant(
        'Branch.Modal.CancelEdit'
      );
    } else {
      title = this._translateService.instant(
        'Branch.Modal.CancelCreateTitle'
      );
      detail = this._translateService.instant(
        'Branch.Modal.CancelCreate'
      );
    }

    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      if (IsConfirm) {
        this.submit(this.formData);
      } else {
        this.closeModal();
      }
    });
  }

  openConfirmModal(): void {
    this.isCheckInvalidForm = this.branchManageFormComponent.checkInvalidForm();
    console.log(this.branchManageFormComponent.prepareDataForm())
    this.isSubmit = true;

    let title: string;
    let detail: string;
    let isConfirm = true;

    if (this.isEditing) {
      title = this._translateService.instant(
        'Branch.Modal.ConfirmEditTitle'
      );
      detail = this._translateService.instant(
        'Branch.Modal.ConfirmEdit'
      );
    } else {
      title = this._translateService.instant(
        'Branch.Modal.ConfirmCreateTitle'
      );
      detail = this._translateService.instant(
        'Branch.Modal.ConfirmCreate'
      );
    }

    if (!this.isCheckInvalidForm) {
      this.openModal(title, detail, isConfirm);
    } else {
      console.error('invalid form please fill all required input');
    }
  }

  submit(data) {
    this.blockUI.start();
    const apiPath = 'Branch';
    data = this.branchManageFormComponent.prepareDataForm()
    

    if (this.isEditing) {
      this._apiService
        .UpdateDataById(apiPath, this.editData.id, data)
        .subscribe(
          (res) => {
            console.log('Editting Branch....');
            console.log(res);

            this.branchManageFormComponent.getImageComponent(res.data.resultData);

            this.refreshData.emit(res.data.resultData);
            this.blockUI.stop();
            this.closeModal();
            this._componentsService.SuccessSwal();
          },
          (err) => {
            console.error('Editting Branch Error....');
            this.blockUI.stop();
            this._componentsService.ErrorSwal();
          }
        );
    } else {
      this._apiService.AddData(apiPath, data).subscribe(
        (res) => {
          console.log('Added New Branch....');
          console.log(res);

          this.branchManageFormComponent.getImageComponent(res.data.resultData);

          this.blockUI.stop();
          this.closeModal();
          this._componentsService.SuccessSwal();
          this.refreshData.emit(res.data.resultData);
        },
        (err) => {
          console.error('Added New Branch Error....');
          this.blockUI.stop();
          this._componentsService.ErrorSwal();
        }
      );
    }
  }
}
