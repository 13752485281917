<div class="card-header">
  <ul
    ngbNav
    #nav="ngbNav"
    class="nav nav-tabs mb-0 bg-white border"
    [destroyOnHide]="true"
    [activeId]="1"
  >
    <li [ngbNavItem]="1" class="nav-item">
      <a ngbNavLink>{{ 'EKYC.Data' | translate }}</a>
      <ng-template ngbNavContent>
        <app-ekyc-detail
        
          #ekycDetailComponent
        ></app-ekyc-detail>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" class="nav-item">
      <a ngbNavLink>{{ 'EKYC.History' | translate }}</a>
      <ng-template ngbNavContent>
        <app-ekyc-history></app-ekyc-history>
      </ng-template>
    </li>
  </ul>

  <!-- <button class="btn btn-primary" (click)="editMode()" [disabled]="isEditing">
    แก้ไขข้อมูล e-KYC
  </button> -->
</div>
<div [ngbNavOutlet]="nav" class="card-body"></div>
