import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Office from 'app/main/model/Office';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  public config: ICMSConfig = {
    apiPath: 'Office',
    pathUrl: '/cms/repove-tools/our-company/branch',
    tableName: 'Content',
    model: new Office(),
    isList: true,
    componentName: 'BreadCrumbs.CMS.RepoveTools.Branch.Title',
    formConfig: {
      name: {
        type: FormType.Text,
        validator: [Validators.required],
        // label: 'Name',
        // description: 'หัวข้อข่าวสาร',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Branch.Name',
      },
      title: {
        type: FormType.Text,
        validator: [Validators.required],
        // label: 'Sub Name',
        // description: 'หัวข้อข่าวสาร',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Branch.Subname',
      },
      subTitle: {
        type: FormType.RichText,
        validator: [Validators.required],
        // label: 'Detail',
        // description: 'หัวข้อข่าวสาร',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Branch.Detail',
      },
      coverImage: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Cover Image',
        // description: 'หัวข้อข่าวสาร',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Branch.CoverImage',
      },
    },
  }

  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'name';

  constructor() { }
}
