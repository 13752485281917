import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalComponent } from '../../modal/modal.component';
import { ApiService } from 'app/main/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainConfig } from 'app/main/config/MainConfig';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from '../../components.service';
import { AuthenticationService } from 'app/auth/service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FilesApiService } from 'app/main/service/files-api.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Subject } from 'rxjs';
import Content from 'app/main/model/Content';
import { ContentService } from 'app/main/pages/item-manage/content/content.service';
@Component({
  selector: 'app-content-formpage',
  templateUrl: './content-form.component.html',
  styleUrls: ['./content-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContentFormComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  public dateTimeOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true,
    defaultDate: '',
  };
  public dateToOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true,
    defaultDate: '',
  };
  public dateFromOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true,
    defaultDate: '',
  };
  private _unsubscribeAll: Subject<any>;
  componentName: string;
  apiPath: string;
  pathUrl: string;
  contentHeader: object;
  itemGuid: string;
  itemObj: Content;
  images: File[] = [];
  files: File[] = [];
  GroupObjList: Content[];
  itemID: string;
  registerForm: FormGroup;
  isEditing: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  summernoteConfig: object;
  modeText: string;
  activeModal: any;
  @ViewChild('confirmModal') confirmModal: ModalComponent;
  constructor(
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _modalService: NgbModal,
    private _fileService: FilesApiService,
    private _formBuilder: FormBuilder,
    private _mainConfig: MainConfig,
    private _contentTemplateService: ContentService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _authenticationService: AuthenticationService
  ) {
    this.componentName = 'Item';
    this._unsubscribeAll = new Subject();
    this.componentName = this._contentTemplateService.componentName;
    this.apiPath = this._contentTemplateService.apiPath;
    this.pathUrl = this._contentTemplateService.pathUrl;
    this.summernoteConfig = this._mainConfig.summernoteNewsConfig;
    this._route.paramMap.subscribe((val) => {
      this.isEditing = this._route.snapshot.paramMap.get('id') ? true : false;
    });
    this.isLoading = this.isEditing ? true : false;
    this._apiService.SetHttpHeaders(this._authenticationService.tokenValue);
    this.modeText = this.isEditing
      ? this._translateService.instant('General.Edit')
      : this._translateService.instant('General.Create');
    this.registerForm = this._formBuilder.group({
      id: [null],
      name: ['', [Validators.required, Validators.maxLength(50)]],
      title: ['', [Validators.required, Validators.maxLength(100)]],
      subTitle: ['', [Validators.required, Validators.maxLength(100)]],
      detail: ['', [Validators.required]],
      dateFrom: [null, [Validators.required]],
      dateTo: [null, [Validators.required]],
      contentDate: [null, [Validators.required]],
      linkBtnCaption: ['', [Validators.required, Validators.maxLength(50)]],
      linkBtnUrl: ['', [Validators.required]],
      isActive: [false, [Validators.required]],
    });
  }
  get f() {
    return this.registerForm.controls;
  }
  ngOnInit(): void {
    this._route.paramMap.subscribe((val) => {
      this.itemID = this._route.snapshot.paramMap.get('id');
      this.isEditing = this.itemID ? true : false;
      this.isLoading = this.isEditing ? true : false;
      this.modeText = this.isEditing
        ? this._translateService.instant('General.Edit')
        : this._translateService.instant('General.Create');
      this.initForm(this.isEditing);
    });
    this.contentHeader = {
      headerTitle: this.modeText + ' ' + this.componentName,
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this.componentName,
            isLink: true,
            link: this.pathUrl,
          },
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }
  initForm(isEditing: boolean): void {
    this.getGroupData();
    if (isEditing) {
      this.getitemData(this.itemID);
    } else {
      this.itemObj = new Content();
      this.isLoading = false;
    }
  }
  buildForm(): void {
    var self = this;
    this.registerForm.patchValue({
      id: self.itemObj.id,
      name: self.itemObj.name,
      title: self.itemObj.title,
      subTitle: self.itemObj.subTitle,
      detail: self.itemObj.detail,
      dateFrom: self.itemObj.dateFrom,
      dateTo: self.itemObj.dateTo,
      contentDate: self.itemObj.contentDate,
      linkBtnCaption: self.itemObj.linkBtnCaption,
      linkBtnUrl: self.itemObj.linkBtnUrl,
      isActive: self.itemObj.isActive,
    });
    const contentDateValue = new Date(this.f.contentDate.value);
    const dateFromValue = new Date(this.f.dateFrom.value);
    const dateToValue = new Date(this.f.dateTo.value);
    this.dateTimeOptions.defaultDate = contentDateValue.toISOString();
    this.dateFromOptions.defaultDate = dateFromValue.toISOString();
    this.dateToOptions.defaultDate = dateToValue.toISOString();
  }
  getitemData(guid: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService.GetDataById(this.apiPath, guid).subscribe((res) => {
        self.itemObj = res.data.resultData[0];
        if (self.itemObj.mediaFiles.length > 0) {
          let arr = self.itemObj.mediaFiles;
          for (let i = 0; i < arr.length; i++) {
            const element = arr[i];
          }
        }
        this.buildForm();
        this.isLoading = false;
      }, rejects);
    });
  }
  getGroupData(): Promise<any> {
    return new Promise((resolve, rejects) => {
      this._apiService.GetAllData(`group`).subscribe((res) => {
        this.GroupObjList = res.data.resultData;
        this.isLoading = false;
      }, rejects);
    });
  }

  openConfirmModal(content: any): void {
    let isConfirm = true;
    let contentNameText = this.isEditing ? content.name : this.componentName;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.Edit');
    } else {
      var stateForm = this._translateService.instant('General.Create');
    }
    let title =
      this._translateService.instant('General.Confirm') +
      `${stateForm} ${this.componentName}`;
    let detail =
      this._translateService.instant('General.AreYouConfirm') +
      `${stateForm} ${this.componentName}`;
    this.openModal(title, detail, isConfirm);
  }

  openCancelModal(content: any): void {
    let isConfirm = false;
    let contentNameText = this.isEditing
      ? this.itemObj.name
      : this.componentName;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.CancelEdit');
    } else {
      var stateForm = this._translateService.instant('General.CancelCreate');
    }
    let title =
      this._translateService.instant('General.Confirm') +
      `${stateForm} ${this.componentName}`;
    let detail =
      this._translateService.instant('General.AreYouConfirm') +
      `${stateForm} ${this.componentName}`;
    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    if (!this.registerForm.invalid) {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        if (IsConfirm) {
          this.submit();
        } else {
          this._router.navigate([`${this.pathUrl}`]);
        }
      });
    } else {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        this._router.navigate([`${this.pathUrl}`]);
      });
    }
  }
  submit(): void {
    this.blockUI.start();
    this.isSubmitted = true;
    if (this.registerForm.invalid) {
      this.blockUI.stop();
      console.log('Invalid');
      return;
    }

    const dateToValue = this.registerForm.controls.dateTo.value;
    const updatedDateToValue = dateToValue[0];
    this.registerForm.controls.dateTo.patchValue(updatedDateToValue);

    const dateFromValue = this.registerForm.controls.dateFrom.value;
    const updatedDateFromValue = dateFromValue[0];
    this.registerForm.controls.dateFrom.patchValue(updatedDateFromValue);

    const contentDateValue = this.registerForm.controls.contentDate.value;
    const updatedContentDateValue = contentDateValue[0];
    this.registerForm.controls.contentDate.patchValue(updatedContentDateValue);

    if (this.isEditing) {
      console.log('Edit mode');

      this._apiService
        .UpdateDataById(
          this.apiPath,
          this.itemObj.id.toString(),
          this.registerForm.value
        )
        .subscribe(
          (res) => {
            this._componentsService.SuccessSwal();
            this._router.navigate([`/content-template`]);
          },
          (err) => {
            this._componentsService.ErrorSwal();
          }
        );
    } else {
      const contentCategoryIdValue = parseInt(
        this.registerForm.controls.contentCategoryId.value,
        10
      );
      this.registerForm.controls.contentCategoryId.patchValue(
        contentCategoryIdValue
      );

      this._apiService.AddData(this.apiPath, this.registerForm.value).subscribe(
        (res) => {
          this._componentsService.SuccessSwal();
          this._router.navigate([`${this.pathUrl}`]);
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
    }
  }
}
