import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Role } from 'app/auth/models';
import { ACL } from 'app/main/model/ACL';
import { ApiService } from 'app/main/service/api.service';

@Component({
  selector: 'app-acl-form',
  templateUrl: './acl-form.component.html',
  styleUrls: ['./acl-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AclFormComponent implements OnInit {

  contentHeader: object;

  aclList: ACL[];
  roleSetList: Role[];

  isLoading: boolean;

  constructor(private _apiService: ApiService) {
    this.SetLoadingState();
    this.initForm();
    this.getAclList();
    this.getRoleSet();

    if (this.aclList && this.roleSetList) {
      this.SetLoadedState();
    }
  }

  ngOnInit(): void {}

  initForm(): void {}

  getAclList(): void {
    this._apiService.GetAllData('acl').subscribe((res) => {
      this.aclList = res.data.resultData;
    });
  }

  getRoleSet(): void {
    this._apiService.GetAllData('roleSet').subscribe((res) => {
      this.roleSetList = res.data.resultData;
    });
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }
}
