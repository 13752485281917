import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import Banner from 'app/main/model/Banner';
import { ModalComponent } from '../../modal/modal.component';
import { ApiService } from 'app/main/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainConfig } from 'app/main/config/MainConfig';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from '../../components.service';
import { AuthenticationService } from 'app/auth/service';
import { Subject } from 'rxjs';
import { BannerService } from 'app/main/pages/cms/banner/banner.service';

@Component({
  selector: 'app-banner-form',
  templateUrl: './banner-form.component.html',
  styleUrls: ['./banner-form.component.scss'],
})
export class BannerFormComponent implements OnInit {
  private _unsubscribeAll;
  componentName: string;

  apiPath: string;
  pathUrl: string;
  contentHeader: object;
  itemGuid: string;

  images: File[] = [];
  files: File[] = [];

  registerForm: FormGroup;

  summernoteConfig: object;
  modeText: string;
  activeModal: any;

  @Input() itemObj: Banner;
  @Input() isLoading: boolean;
  @Input() callbackUrl: string;
  @Input() isEditing: boolean;

  @ViewChild('confirmModal') confirmModal: ModalComponent;

  constructor(
    private _apiService: ApiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _modalService: NgbModal,
    private _mainConfig: MainConfig,
    private _contentTemplateService: BannerService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder
  ) {
    this.SetLoadingState();
    this.initConfig();
    this.initForm(this.isEditing);
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  get f() {
    return this.registerForm.controls;
  }

  initConfig(): void {
    this._unsubscribeAll = new Subject();
    this.componentName = this._contentTemplateService.componentName;
    this.apiPath = this._contentTemplateService.apiPath;
    this.pathUrl = this._contentTemplateService.pathUrl;
    this.summernoteConfig = this._mainConfig.summernoteNewsConfig;

    this.isEditing = this._route.snapshot.paramMap.get('id') ? true : false;
    this.isLoading = this.isEditing ? true : false;

    this._apiService.SetHttpHeaders(this._authenticationService.tokenValue);

    this.modeText = this.isEditing
      ? this._translateService.instant('General.Edit')
      : this._translateService.instant('General.Create');

    this.registerForm = this._formBuilder.group({
      name: ['', [Validators.required]],
      title: ['', [Validators.required]],
      subTitle: ['', [Validators.required]],
      linkBtnCaption: ['', []],
      linkBtnUrl: ['', []],
    });
  }

  initForm(isEditing: boolean): void {
    var self = this;
    if (isEditing) {
      this.itemGuid = this._route.snapshot.paramMap.get('id');
      this._route.paramMap.subscribe((val) => {
        self.itemGuid = self._route.snapshot.paramMap.get('id');
        self.getitemData(self.itemGuid);
      });
    } else {
      this.itemObj = new Banner();
      this.setFormValue();
    }
  }

  setFormValue(): void {
    var self = this;
    this.registerForm.patchValue({
      name: self.itemObj.name,
      title: self.itemObj.title,
      subTitle: self.itemObj.subTitle,
      linkBtnCaption: self.itemObj.linkBtnCaption,
      linkBtnUrl: self.itemObj.linkBtnUrl,
      isActive: self.itemObj.isActive,
    });
  }

  getitemData(guid: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService.GetDataById(this.apiPath, guid).subscribe((res) => {
        self.itemObj = res.data.resultData[0];
        self.setFormValue();
        self.isLoading = false;
      }, rejects);
    });
  }

  openConfirmModal(content: any): void {
    let isConfirm = true;
    let contentNameText = this.isEditing ? content.name : this.componentName;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.Edit');
    } else {
      var stateForm = this._translateService.instant('General.Create');
    }
    let title =
      this._translateService.instant('General.Confirm') +
      `${stateForm} ${this.componentName}`;
    let detail =
      this._translateService.instant('General.AreYouConfirm') +
      `${stateForm} ${this.componentName}`;

    this.openModal(title, detail, isConfirm);
  }

  openCancelModal(content: any): void {
    let isConfirm = false;
    let contentNameText = this.isEditing
      ? this.itemObj.name
      : this.componentName;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.CancelEdit');
    } else {
      var stateForm = this._translateService.instant('General.CancelCreate');
    }
    let title =
      this._translateService.instant('General.Confirm') +
      `${stateForm} ${this.componentName}`;
    let detail =
      this._translateService.instant('General.AreYouConfirm') +
      `${stateForm} ${this.componentName}`;
    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    var self = this;
    if (!IsConfirm) {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        self._router.navigate([`${this.pathUrl}`]);
      });
    } else {
      if (!this.registerForm.invalid) {
        const modalRef = this._modalService.open(ModalComponent, {
          centered: true,
          backdrop: 'static',
        });
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.isConfirm = IsConfirm;
        modalRef.componentInstance.detail = detail;
        modalRef.componentInstance.callBackFunc.subscribe((res) => {
          if (IsConfirm) {
            self.Submit();
          } else {
            self._router.navigate([`${this.pathUrl}`]);
          }
        });
      }
    }
  }

  onSelectAtteched(event, arr) {
    var invalidFiles = false;
    for (let i = 0; i < event.addedFiles.length; i++) {
      const file = event.addedFiles[i];
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          arr.push(file);
        };
        img.src = URL.createObjectURL(file);
      };
      reader.readAsDataURL(file);
    }
    // this.files.push(...event.addedFiles);
  }

  onRemoveAtteched(event, arr) {
    arr.splice(arr.indexOf(event), 1);
    console.log(arr);
  }

  Submit(): void {
    var self = this;

    if (this.registerForm.invalid) {
      return;
    }

    if (
      !this.itemObj.isActive ||
      this.itemObj.isActive === null ||
      this.itemObj.isActive === undefined
    ) {
      this.itemObj.isActive = false;
    }

    if (this.isEditing) {
      this._apiService
        .UpdateDataById(
          this.apiPath,
          this.itemObj.id.toString(),
          this.registerForm.value
        )
        .subscribe(
          (res) => {
            this._apiService
              .SetIsActiveData(
                this.apiPath,
                this.itemObj.id.toString(),
                this.itemObj.isActive
              )
              .subscribe((res) => {});

            self._componentsService.SuccessSwal();
            self._router.navigate([`/banner`]);
          },
          (err) => {
            self._componentsService.ErrorSwal();
          }
        );
    } else {
      this._apiService.AddData(this.apiPath, this.registerForm.value).subscribe(
        (res) => {
          this._apiService
            .SetIsActiveData(
              this.apiPath,
              res.data.resultData[0],
              this.itemObj.isActive
            )
            .subscribe((res) => {});

          self._componentsService.SuccessSwal();
          self._router.navigate([`/banner`]);
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
    }
  }

  ngOnInit(): void {}
}
