<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mt-1 text-primary">
            {{ customerObj.rankName }} |

            {{
            customerObj.islegalEntity
            ? customerObj.businessName
            : customerObj.customerFirstName +
            " " +
            customerObj.customerLastName
            }}

            <span *ngIf="customerObj.islegalEntity">
              (
              {{
              customerObj.isHeadBranch
              ? ("Customer.headOffice" | translate)
              : customerObj.branchNumber.toString().padStart(5, "0") +
              ( customerObj.branchName
              ? " (" + customerObj.branchName + ")"
              : "")
              }}
              )
            </span>
          </h3>

          <a [routerLink]="['/customer/detail/' + customerId]" class="btn btn-primary" target="_blank">
            {{ 'General.Detail' | translate }}
          </a>
        </div>
        <app-downline-detail></app-downline-detail>
      </div>
    </div>
  </div>
</div>