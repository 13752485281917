<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div class="card">
      <div class="card-body">
        <ng-container *ngTemplateOutlet="mockChart"></ng-container>

        <div class="d-flex">
          <div class="col-6">

            <div class="pb-1">
              <label class="form-label">{{ 'Dashboard.Revenue.Item'| translate}} 1</label>
              <div class="d-flex">
                <ng-select (change)="onChange(1,$event)" [(ngModel)]="selectedItem1" [clearable]="false"
                  #selectItemOption placeholder="-- {{ 'Dashboard.Revenue.SelectItem'| translate}} --"
                  style="width: 90%; margin-right: 0.5rem">
                  <ng-option value="">-- {{ 'Dashboard.Revenue.SelectItem'| translate}} --</ng-option>
                  <ng-option *ngFor="let item of itemList" [value]="item" [disabled]="checkItemWasSelected(item)">
                    {{ item.name }}
                  </ng-option>
                </ng-select>

                <div class="text-center align-self-center" style="width: 30px; height: 30px; border-radius: 3px"
                  [ngStyle]="{ 'background-color': chartColors.column.series1 }"></div>
              </div>
            </div>

            <div class="pb-1">
              <label class="form-label">{{ 'Dashboard.Revenue.Item'| translate}} 2</label>
              <div class="d-flex">
                <ng-select (change)="onChange(2,$event)" [(ngModel)]="selectedItem2" [clearable]="false"
                  #selectItemOption placeholder="-- {{ 'Dashboard.Revenue.SelectItem'| translate}} --"
                  style="width: 90%; margin-right: 0.5rem">
                  <ng-option value="">-- {{ 'Dashboard.Revenue.SelectItem'| translate}} --</ng-option>
                  <ng-option *ngFor="let item of itemList" [value]="item" [disabled]="checkItemWasSelected(item)">
                    {{ item.name }}
                  </ng-option>
                </ng-select>

                <div class="text-center align-self-center" style="width: 30px; height: 30px; border-radius: 3px"
                  [ngStyle]="{ 'background-color': chartColors.column.series2 }"></div>
              </div>
            </div>

            <div class="pb-1">
              <label class="form-label">{{ 'Dashboard.Revenue.Item'| translate}} 3</label>
              <div class="d-flex">
                <ng-select (change)="onChange(3,$event)" [(ngModel)]="selectedItem3" [clearable]="false"
                  #selectItemOption placeholder="-- {{ 'Dashboard.Revenue.SelectItem'| translate}} --"
                  style="width: 90%; margin-right: 0.5rem">
                  <ng-option value="">-- {{ 'Dashboard.Revenue.SelectItem'| translate}} --</ng-option>
                  <ng-option *ngFor="let item of itemList" [value]="item" [disabled]="checkItemWasSelected(item)">
                    {{ item.name }}
                  </ng-option>
                </ng-select>

                <div class="text-center align-self-center" style="width: 30px; height: 30px; border-radius: 3px"
                  [ngStyle]="{ 'background-color': chartColors.column.series3 }"></div>
              </div>
            </div>

            <div class="pb-1">
              <label class="form-label">{{ 'Dashboard.Revenue.Item'| translate}} 4</label>
              <div class="d-flex">
                <ng-select (change)="onChange(4,$event)" [(ngModel)]="selectedItem4" [clearable]="false"
                  #selectItemOption placeholder="-- {{ 'Dashboard.Revenue.SelectItem'| translate}} --"
                  style="width: 90%; margin-right: 0.5rem">
                  <ng-option value="">-- {{ 'Dashboard.Revenue.SelectItem'| translate}} --</ng-option>
                  <ng-option *ngFor="let item of itemList" [value]="item" [disabled]="checkItemWasSelected(item)">
                    {{ item.name }}
                  </ng-option>
                </ng-select>

                <div class="text-center align-self-center" style="width: 30px; height: 30px; border-radius: 3px"
                  [ngStyle]="{ 'background-color': chartColors.column.series4 }"></div>
              </div>
            </div>

            <div class="pb-1">
              <label class="form-label">{{ 'Dashboard.Revenue.Item'| translate}} 5</label>
              <div class="d-flex">
                <ng-select (change)="onChange(5,$event)" [(ngModel)]="selectedItem5" [clearable]="false"
                  #selectItemOption placeholder="-- {{ 'Dashboard.Revenue.SelectItem'| translate}} --"
                  style="width: 90%; margin-right: 0.5rem">
                  <ng-option value="">-- {{ 'Dashboard.Revenue.SelectItem'| translate}} --</ng-option>
                  <ng-option *ngFor="let item of itemList" [value]="item" [disabled]="checkItemWasSelected(item)">
                    {{ item.name }}
                  </ng-option>
                </ng-select>

                <div class="text-center align-self-center" style="width: 30px; height: 30px; border-radius: 3px"
                  [ngStyle]="{ 'background-color': chartColors.column.series5 }"></div>
              </div>
            </div>



          </div>

          <div class="col-6">
            <div class="d-flex justify-content-between">
              <div class="mr-1 col-6 px-0">
                <label>{{'Stock.From' | translate}}:</label>
                <div class="d-flex align-items-center">

                  <select class="custom-select mr-1 w-100" [(ngModel)]="selectedStartYear" (change)="onStartYearChange()">
                    <option *ngFor="let year of yearList" [value]="year.toString()">{{ year }}</option>
                  </select>
                  <select class="custom-select mr-1 w-100" [disabled]="!selectedStartYear" [(ngModel)]="selectedStartMonth"
                    (change)="onStartMonthChange()">
                    <option *ngFor="let month of months" [value]="month.value.toString()">
                      <span *ngIf="currentLang == 'en'">
                        {{ month.nameEN }}
                      </span>
                      <span *ngIf="currentLang == 'th'">
                        {{ month.nameTH }}
                      </span>
                    </option>
                  </select>
                </div>
              </div>


              <div class="col-6 px-0">
                <label class="mr-1">{{'Stock.To' | translate}}:</label>
                <div class="d-flex align-items-center">

                  <select class="custom-select mr-1 w-100" [disabled]="!selectedStartYear" [(ngModel)]="selectedEndYear"
                    (change)="onYearChange()">
                    <option *ngFor="let year of selectedEndYearOptions" [value]="year">{{ year }}</option>
                  </select>
                  <select class="custom-select mr-1 w-100" [disabled]="!selectedStartYear" [(ngModel)]="selectedEndMonth"
                    (change)="onMonthChange()">
                    <option *ngFor="let month of selectedEndMonthOptions" [value]="month.value">
                      <span *ngIf="currentLang == 'en'">
                        {{ month.nameEN }}
                      </span>
                      <span *ngIf="currentLang == 'th'">
                        {{ month.nameTH }}
                      </span>
                    </option>
                  </select>
                </div>
              </div>
              <!-- <div class="col-2"></div> -->

            </div>


            <div class="d-flex justify-content-center mt-2">
              <button class="btn btn-secondary mr-1" (click)="resetSearch()"><i data-feather="refresh-ccw"
                  class="mr-1"></i>{{ "General.Reset" | translate }}</button>
              <button class="btn btn-primary" (click)="Search()">
                <i data-feather="search" class="mr-1"></i>{{
                "General.Search" | translate }}
              </button>
            </div>

            <hr>

            <div class="d-flex mt-2">
              <div class="col-4 border align-self-center py-auto title-box" style="padding-block: 0.7rem;">
                <h3>{{ 'Dashboard.Revenue.Title'| translate}}</h3>
              </div>
              <div class="col-8 border align-self-center py-auto value-box"
                style="padding-block: 0.7rem;     border-radius: 0px 5px 5px 0px;">
                <h3 class="text-end">
                  <span class="text-primary mr-1">{{ sumRevenue | number : "1.2-2" }}</span>{{ "DocFooter.Baht" |
                  translate
                  }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #mockChart>
  <section id="apex-column-charts" class="revenue-data-chart">
    <div class="row">
      <div class="col-12">
        <div class="card">

          <div class="card-body">
            <div id="column-chart" #apexColumnChartRef>
              <apx-chart [series]="apexColumnChart.series" [chart]="{
                  type: 'bar',
                  height: 400,
                  width:
                    isMenuToggled === false
                      ? apexColumnChartRef.offsetWidth
                      : apexColumnChart.chart.width,
                  stacked: true,
                  toolbar: {
                    show: false
                  }
                }" [xaxis]="apexColumnChart.xaxis" [yaxis]="apexColumnChart.yaxis" [stroke]="apexColumnChart.stroke"
                [tooltip]="apexColumnChart.tooltip" [colors]="apexColumnChart.colors" [legend]="apexColumnChart.legend"
                [dataLabels]="apexColumnChart.dataLabels" [plotOptions]="apexColumnChart.plotOptions"
                [grid]="apexColumnChart.grid"></apx-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>