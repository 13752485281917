<section id="apex-column-charts">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <!-- <div
          class="card-header d-flex flex-md-row flex-column justify-content-md-between justify-content-start align-items-md-center align-items-start"> -->
        <!-- <div class="col-4">
            <h4 class="card-title mb-50">Data Science</h4>
          </div> -->
        <div class="d-flex align-items-center justify-content-center  my-2 px-0 range-datepicker col-12">
          <div>
            <label class="d-flex justify-content-center mx-1 mb-0">{{ "Dashboard.YOY.Compare" | translate }}</label>
          </div>
          <div class="calendar-datepicker-icon">
            <i class="font-medium-2" data-feather="calendar"></i>
          </div>

          <select class="form-control col-2" [(ngModel)]="fromYear">
            <!-- <option selected [ngValue]="currentDate.getFullYear() - 1">{{currentDate.getFullYear() - 1}}</option> -->
            <option *ngFor="let year of yearList" [ngValue]="year" [selected]="year == currentDate.getFullYear() - 1">
              {{ year }}</option>
          </select>
          <div>
            <label class="d-flex justify-content-center mx-1 mb-0">{{ "Dashboard.YOY.With" | translate }}</label>
          </div>
          <div class="calendar-datepicker-icon">
            <i class="font-medium-2" data-feather="calendar"></i>
          </div>
          <select class="form-control col-2" [(ngModel)]="toYear">
            <option *ngFor="let year of yearList" [ngValue]="year" [selected]="year == currentDate.getFullYear()">{{
              year }}</option>
          </select>
        </div>

        <div class="d-flex justify-content-center">
          <div class="d-flex justify-content-center mx-1">
            <button class="btn btn-secondary d-flex" (click)="resetSearch()"><i data-feather="refresh-ccw" class="mr-1"></i>{{
              "General.Reset" | translate }}</button>
          </div>
          <div class="d-flex justify-content-center">
            <button class="btn btn-primary d-flex" (click)="searchChart()"><i data-feather="search" class="mr-1"></i>{{
              "General.Search" | translate }}</button>
          </div>
        </div>
        <!-- </div> -->

        <hr>
        <div class="d-flex justify-content-end align-items-center">
          <button class="btn btn-primary" (click)="exportToExcel()">
            <span>{{ "General.Export" | translate }}</span>
          </button>
        </div>
        <div class="card-body">
          <div id="column-chart" #apexColumnChartRef>
            <apx-chart [series]="apexColumnChart.series" [chart]="apexColumnChart.chart" [xaxis]="apexColumnChart.xaxis"
              [yaxis]="apexColumnChart.yaxis" [stroke]="apexColumnChart.stroke" [tooltip]="apexColumnChart.tooltip"
              [colors]="apexColumnChart.colors" [legend]="apexColumnChart.legend"
              [dataLabels]="apexColumnChart.dataLabels" [plotOptions]="apexColumnChart.plotOptions"
              [grid]="apexColumnChart.grid" [noData]="apexColumnChart.noData"></apx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>