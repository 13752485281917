import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { CertificationsCardService } from '../certifications-card.service';

@Component({
  selector: 'app-cert-card-create',
  templateUrl: './cert-card-create.component.html',
  styleUrls: ['./cert-card-create.component.scss']
})
export class CertCardCreateComponent implements OnInit {

  config: ICMSConfig;

  constructor(private _certCardService: CertificationsCardService) {
    this.config = this._certCardService.config;
  }

  ngOnInit(): void {
  }

}
