import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import News from 'app/main/model/News';

@Component({
  selector: 'app-news-landing',
  templateUrl: './news-landing.component.html',
  styleUrls: ['./news-landing.component.scss'],
})
export class NewsLandingComponent implements OnInit {
  config: ICMSConfig = {
    apiPath: 'NewsEventBanner',
    pathUrl: '/cms/news-landing',
    tableName: 'Content',
    model: new News(),
    isList: false,
    componentName: 'News',
    formConfig: {
      bannerNews: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'News Banner',
        description: 'Banner Background',
        labelTranslate: 'Informative.NewsEvent.NewsBanner.Label',
        descriptionTranslate: 'Informative.NewsEvent.NewsBanner.Description',
      },
      bannerNewsDescription: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'News Title',
        description: 'Banner Title',
        labelTranslate: 'Informative.NewsEvent.NewsTitle.Label',
        descriptionTranslate: 'Informative.NewsEvent.NewsTitle.Description',
      },
    },
  };

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
