import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Timeline from 'app/main/model/Timeline';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {

  public config: ICMSConfig = {
    apiPath: 'Timeline',
    pathUrl: '/cms/repove-tools/our-company/timeline',
    tableName: 'Content',
    model: new Timeline(),
    isList: true,
    componentName: 'BreadCrumbs.CMS.RepoveTools.Timeline.Title',
    formConfig: {
      name: {
        type: FormType.Text,
        validator: [Validators.required],
        // label: 'Year Timeline',
        // description: 'ปีที่ดำเนินการ',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Year',
      },
      title1: {
        type: FormType.Text,
        isMultipleLang: true,
        // validator: [Validators.required],
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Month1',
        // description: 'ปีที่ดำเนินการ',
        // labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Year',
      },
      detail1: {
        type: FormType.RichText,
        isMultipleLang: true,
        // validator: [Validators.required],
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Desecription1',
        // description: 'เนื้อหาไทม์ไลน์',
        // labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Description',
        
      },
      title2: {
        type: FormType.Text,
        isMultipleLang: true,
        // validator: [Validators.required],
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Month2',
        // description: 'ปีที่ดำเนินการ',
        // labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Year',
      },
      detail2: {
        type: FormType.RichText,
        isMultipleLang: true,
        // validator: [Validators.required],
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Desecription2',        // description: 'เนื้อหาไทม์ไลน์',
        // labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Description',
        
      },
      title3: {
        type: FormType.Text,
        isMultipleLang: true,
        // validator: [Validators.required],
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Month3',
        // description: 'ปีที่ดำเนินการ',
        // labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Year',
      },
      detail3: {
        type: FormType.RichText,
        isMultipleLang: true,
        // validator: [Validators.required],
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Desecription3',        // description: 'เนื้อหาไทม์ไลน์',
        // labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Description',
        
      },
      title4: {
        type: FormType.Text,
        isMultipleLang: true,
        // validator: [Validators.required],
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Month4',
        // description: 'ปีที่ดำเนินการ',
        // labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Year',
      },
      detail4: {
        type: FormType.RichText,
        isMultipleLang: true,
        // validator: [Validators.required],
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Desecription4',        // description: 'เนื้อหาไทม์ไลน์',
        // labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Description',
        
      },
      title5: {
        type: FormType.Text,
        isMultipleLang: true,
        // validator: [Validators.required],
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Month5',
        // description: 'ปีที่ดำเนินการ',
        // labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Year',
      },
      detail5: {
        type: FormType.RichText,
        isMultipleLang: true,
        // validator: [Validators.required],
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Desecription5',
        // description: 'เนื้อหาไทม์ไลน์',
        // labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Timeline.Description',
        
      },
    }
  }

  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'name';

  constructor() { }
}
