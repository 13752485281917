import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { DetailComponent } from './detail.component';
import { CmsFormpageModule } from '../../../cms-formpage/cms-formpage.module';

const path: string = 'cms/repove-tools/our-company/detail';
const routes: Routes = [
  {
    path: path,
    component: DetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'our-company-detail' },
  },
];


@NgModule({
  declarations: [DetailComponent],
  imports: [
    CommonModule, RouterModule.forChild(routes), CmsFormpageModule
  ],
  exports:[DetailComponent]
})
export class DetailModule { }
