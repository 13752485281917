import { Component, OnInit } from '@angular/core';
import { RoleSetService } from '../roleset.service';

@Component({
  selector: 'app-roleset-form',
  templateUrl: './roleset-form.component.html',
  styleUrls: ['./roleset-form.component.scss'],
})
export class RolesetFormComponent implements OnInit {
  contentHeader: object;
  callbackUrl: string;

  constructor(private _roleSetService: RoleSetService) {
    this.callbackUrl = this._roleSetService.pathUrl;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'General.RolesManage',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }
}
