import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from 'app/main/service/api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import am5geodata_lang_TH from '@amcharts/amcharts5-geodata/lang/TH';
import thailandLow from '@amcharts/amcharts5-geodata/thailandLow';
import Province from 'app/main/model/Province';
import { TranslateService } from '@ngx-translate/core';
import { trim } from 'lodash';
import {
  FlatpickrOptions,
  Ng2FlatpickrComponent,
  Ng2FlatpickrDirective,
} from 'ng2-flatpickr';
import { CommonResponse } from 'app/main/model/CommonResponse';
import { throws } from 'assert';
import moment from 'moment';
import Customer from 'app/main/model/Customer';
import { FilesApiService } from 'app/main/service/files-api.service';
import flatpickr from 'flatpickr';
import { FlatpickrInstance } from 'ng2-flatpickr/src/flatpickr-instance';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

enum Region {
  North = 1,
  Central = 2,
  Northeast = 3,
  West = 4,
  East = 5,
  South = 6,
}

const regionColors: Record<Region, any> = {
  [Region.North]: 0xffe599,
  [Region.Central]: 0x77ba66,
  [Region.Northeast]: 0xb4a7d6,
  [Region.West]: 0xea9999,
  [Region.East]: 0x4c2f4,
  [Region.South]: 0xb6d7a8,
};

interface MemberDemographicMap {
  totalMember: number;
  newMember: number;
  existingMember: number;
  saleTotal: number;
}

export interface MemberByProvinceResponse {
  memberByProvinceList: MemberByProvinceList[];
}

export interface MemberByProvinceList {
  provinceId: string;
  regionId: number;
  provinceName: string;
  provinceNameEn: string;
  sumTotal: number;
}

export interface MemberPerformanceData {
  id: string;
  repoveCode: string;
  name: string;
  performance: number;
  amount: number;
  total: number;
}

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberComponent implements OnInit, AfterViewInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  contentHeader: object;
  currentLang: string;
  currentDate: Date = new Date();

  bestTeamListAllTime: MemberPerformanceData[] = [];
  worstTeamListAllTime: MemberPerformanceData[] = [];

  bestTeamList: MemberPerformanceData[] = [];
  worstTeamList: MemberPerformanceData[] = [];

  provinceList: MemberByProvinceList[];
  provinceOriginalList: MemberByProvinceList[];
  provinceColorMap: {
    id: string;
    value: number;
    provinceId: number;
    regionId: number;
  }[] = [];

  regionSelected: number[] = [];
  provinceSelected: MemberByProvinceList[] = [];
  MemberByProvince: MemberByProvinceResponse;

  StartDate: Date[] = [];
  EndDate: Date[] = [];

  @BlockUI() blockUI: NgBlockUI;

  chart: am5map.MapChart;
  polygonSeries: am5map.MapPolygonSeries;
  heatLegend: am5.HeatLegend;
  private root!: am5.Root;

  // @ViewChild('DemographicDateRangePicker') DemographicDateRangePicker: any;
  @ViewChild('DemographicStartDatePicker') DemographicStartDatePicker: any;
  @ViewChild('DemographicEndDatePicker') DemographicEndDatePicker: any;

  public DateRangeOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    // altInput: true,
    mode: 'range',
  };

  public StartDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    // altInput: true,
    // mode: 'range',
  };

  public EndDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    // altInput: true,
    // mode: 'range',
  };

  allMemberCount: number = 0;
  totalMemberCount: number = 0;
  newMemberCount: number = 0;
  existingMemberCount: number = 0;
  percentageMember: number = 0;
  saleTotalCount: number = 0;

  default_totalMemberCount: number = 0;
  default_newMemberCount: number = 0;
  default_existingMemberCount: number = 0;
  default_saleTotalCount: number = 0;

  DateRange: Date[] = [];
  yearList: number[] = [];
  months: { value: number; nameEN: string; nameTH: string }[] = [
    { value: 0, nameEN: 'January', nameTH: 'มกราคม' },
    { value: 1, nameEN: 'February', nameTH: 'กุมภาพันธ์' },
    { value: 2, nameEN: 'March', nameTH: 'มีนาคม' },
    { value: 3, nameEN: 'April', nameTH: 'เมษายน' },
    { value: 4, nameEN: 'May', nameTH: 'พฤษภาคม' },
    { value: 5, nameEN: 'June', nameTH: 'มิถุนายน' },
    { value: 6, nameEN: 'July', nameTH: 'กรกฎาคม' },
    { value: 7, nameEN: 'August', nameTH: 'สิงหาคม' },
    { value: 8, nameEN: 'September', nameTH: 'กันยายน' },
    { value: 9, nameEN: 'October', nameTH: 'ตุลาคม' },
    { value: 10, nameEN: 'November', nameTH: 'พฤศจิกายน' },
    { value: 11, nameEN: 'December', nameTH: 'ธันวาคม' },
  ];

  selectedBestYear: number = 0;
  selectedBestMonth: number = 0;
  selectedWorstYear: number = 0;
  selectedWorstMonth: number = 0;

  memberPerformanceListLength: number = 5;

  constructor(
    private _apiService: ApiService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone,
    private _translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private _filesApiService: FilesApiService
  ) {
    this.blockUI.start();
    console.log(this.currentDate.getMonth());
    this.getDropdownYear();
    this.currentLang = this._translateService.currentLang;

    this.selectedBestYear = this.currentDate.getFullYear();
    this.selectedBestMonth = this.currentDate.getMonth();
    this.selectedWorstYear = this.currentDate.getFullYear();
    this.selectedWorstMonth = this.currentDate.getMonth();

    this.getBestMemberPerformanceResultList();
    this.getWorstMemberPerformanceResultList();
    this.SearchMemberPerformanceResult(1);
    this.SearchMemberPerformanceResult(2);

    this.getProviceMemberResultList();

    // this.getProvince();
    this.getResult().then(() => {
      this.allMemberCount = this.totalMemberCount;
      this.default_totalMemberCount = this.totalMemberCount;
      this.default_newMemberCount = this.newMemberCount;
      this.default_existingMemberCount = this.existingMemberCount;
      this.default_saleTotalCount = this.saleTotalCount;
      this.percentageMember = this.calculateMemberPercent(
        this.totalMemberCount
      );
    });
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Dashboard.Member.TrackingTitle',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });
  }

  ngAfterViewInit(): void {
    this.initChart();
  }

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });

      // this.zone.run(() => {

      // });
    }
  }

  initChart(): void {
    var self = this;
    this.browserOnly(() => {
      self.root = am5.Root.new('chartdiv');

      self.root.setThemes([am5themes_Animated.new(self.root)]);

      self.chart = self.root.container.children.push(
        am5map.MapChart.new(self.root, {
          panX: 'translateX',
          panY: 'translateY',
          projection: am5map.geoMercator(),
          layout: self.root.horizontalLayout,
        })
      );

      self.polygonSeries = self.chart.series.push(
        am5map.MapPolygonSeries.new(self.root, {
          geoJSON: thailandLow,
          geodataNames: am5geodata_lang_TH,
          valueField: 'value',
          calculateAggregates: true,
          fill: am5.color('#e6e6e6'), //0134A1
        })
      );

      self.polygonSeries.mapPolygons.template.setAll({
        tooltipText: '{name}: {value}',
        toggleKey: 'active',
        interactive: true,
      });

      self.polygonSeries.set('heatRules', [
        {
          target: self.polygonSeries.mapPolygons.template,
          dataField: 'value',
          min: am5.color(0xff621f),
          max: am5.color(0x661f00),
          key: 'fill',
          customFunction: function (sprite: am5.Sprite, min, max, value) {
            let dataContext = sprite.dataItem.dataContext as any;
            switch (dataContext.regionId) {
              case 1: //ภาคเหนือ
                (sprite as am5.Graphics).set(
                  'fill',
                  am5.color(regionColors[Region.North])
                );
                break;
              case 2: // ภาคกลาง
                (sprite as am5.Graphics).set(
                  'fill',
                  am5.color(regionColors[Region.Central])
                );
                break;
              case 3: //ภาคตะวันออกเฉียงเหนือ
                (sprite as am5.Graphics).set(
                  'fill',
                  am5.color(regionColors[Region.Northeast])
                );
                break;
              case 4: //ภาคตะวันตก
                (sprite as am5.Graphics).set(
                  'fill',
                  am5.color(regionColors[Region.West])
                );
                break;
              case 5: //ภาคตะวันออก
                (sprite as am5.Graphics).set(
                  'fill',
                  am5.color(regionColors[Region.East])
                );
                break;
              case 6: //ภาคใต้
                (sprite as am5.Graphics).set(
                  'fill',
                  am5.color(regionColors[Region.South])
                );
                break;
              case 7: //แบบถูกเลือก
                (sprite as am5.Graphics).set('fill', am5.color(0x0134a1));
                break;

              default: //ไม่ได้เลือก
                (sprite as am5.Graphics).set('fill', am5.color(0xe6e6e6));
                break;
            }
          },
        },
      ]);

      self.polygonSeries.data.processor = am5.DataProcessor.new(self.root, {
        colorFields: ['columnSettings.fill'],
      });

      self.polygonSeries.mapPolygons.template.states.create('hover', {
        fill: self.root.interfaceColors.get('primaryButtonHover'),
      });

      self.polygonSeries.mapPolygons.template.states.create('active', {
        fill: self.root.interfaceColors.get('primaryButtonActive'),
      });

      self.polygonSeries.mapPolygons.template.on(
        'active',
        function (active, target) {
          let selected_id = (target?.dataItem?.dataContext as any).id;
          let trimId = selected_id.substr(3, 2);
          let selectedP = self.provinceOriginalList.find(
            (i) => i.provinceId == trimId
          );

          if (!self.provinceList.includes(selectedP)) {
            self.resetResult()
          }

          self.zone.run(() => {
            if (self.provinceSelected.includes(selectedP)) {
              const index = self.provinceSelected.indexOf(selectedP);
              self.provinceSelected.splice(index, 1);
              self.provinceSelected = [...self.provinceSelected];
            } else {
              self.provinceSelected = [...self.provinceSelected, selectedP];
            }

            self.onChangeProvince();
            self.cdr.detectChanges();
          });
        }
      );

      self.heatLegend.startLabel.setAll({
        fontSize: 12,
        fill: self.heatLegend.get('startColor'),
      });

      self.heatLegend.endLabel.setAll({
        fontSize: 12,
        fill: self.heatLegend.get('endColor'),
      });

      // change this to template when possible
      self.polygonSeries.events.on('datavalidated', function () {
        self.heatLegend.set(
          'startValue',
          self.polygonSeries.getPrivate('valueLow')
        );
        self.heatLegend.set(
          'endValue',
          self.polygonSeries.getPrivate('valueHigh')
        );
      });

      // Set clicking on "water" to zoom out
      self.chart?.chartContainer
        ?.get('background')
        ?.events.on('click', function () {
          // self.resetSearch();
          self.chart.goHome();
        });

      // Make stuff animate on load
      self.chart.appear(1000, 100);
    });
  }

  ngOnDestroy(): void {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChangeRegion(): void {
    let mapdata: any[] = [];

    if (this.regionSelected.length >= 1) {
      this.provinceSelected = [];
      this.provinceList = this.provinceOriginalList.filter((provice) =>
        this.regionSelected.includes(provice.regionId)
      );

      this.provinceList.forEach((element, index) => {
        let id = trim(element.provinceId, ' ');
        let data = {
          id: `TH-${id}`,
          value: element.sumTotal,
          provinceId: element.provinceId,
          regionId: element.regionId,
        };
        mapdata.push(data);
      });
      this.receiveMapsData(mapdata);
    } else {
      this.provinceList = this.provinceOriginalList;
      this.setDefaultMapColor();
    }
  }

  onChangeProvince(): void {
    let mapdata: any[] = [];

    if (this.regionSelected.length > 1) {
      this.regionSelected = [];
      this.provinceList = this.provinceOriginalList;
    }

    if (this.provinceSelected.length >= 1) {
      mapdata = this.provinceList.map((value) => {
        let id = trim(value.provinceId, ' ');

        if (this.provinceSelected.includes(value)) {
          return {
            id: `TH-${id}`,
            value: value.sumTotal,
            provinceId: value.provinceId,
            regionId: value.regionId,
          };
        } else {
          return {
            id: `TH-${id}`,
            value: value.sumTotal,
            provinceId: value.provinceId,
            regionId: null,
          };
        }
      });

      this.receiveMapsData(mapdata);
    } else {
      if (this.regionSelected.length < 1) {
        this.setDefaultMapColor();
      } else {
        this.onChangeRegion();
      }
    }
  }

  receiveMapsData(mapdata): void {
    console.log('set all mapdata');
    this.polygonSeries.data.setAll(mapdata);
  }

  setDefaultMapColor() {
    console.log('set default');
    this.receiveMapsData(this.provinceColorMap);
  }

  resetResult() {
    this.blockUI.start();
    this.regionSelected = [];
    this.provinceSelected = [];
    this.setDefaultMapColor();
    this.DateRange = [];
    
    this.DemographicStartDatePicker.flatpickr.clear();
    this.DemographicEndDatePicker.flatpickr.clear();
    this.totalMemberCount = this.default_totalMemberCount;
    this.newMemberCount = this.default_newMemberCount;
    this.existingMemberCount = this.default_existingMemberCount;
    this.saleTotalCount = this.default_saleTotalCount;
    this.provinceList = this.provinceOriginalList
    this.percentageMember = this.calculateMemberPercent(this.totalMemberCount);

    this.blockUI.stop();
  }

  searchResult() {
    this.blockUI.start();
    this.getResult()
      .then(() => {
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });
  }

  SearchMemberPerformanceResult(mode: number) {
    if (mode == 1) {
      this.getMemberPerformanceResultListByMonth(
        1,
        this.memberPerformanceListLength,
        this.selectedBestYear,
        this.selectedBestMonth
      ).then((res) => {
        this.bestTeamList = res;
      });
    } else if (mode == 2) {
      this.getMemberPerformanceResultListByMonth(
        0,
        this.memberPerformanceListLength,
        this.selectedWorstYear,
        this.selectedWorstMonth
      ).then((res) => {
        this.worstTeamList = res;
      });
    }
  }

  onYearChange(mode: number, value: number) {
    if (mode == 1) {
      this.selectedBestYear = value;
    } else if (mode == 2) {
      this.selectedWorstYear = value;
    }
  }

  onDateFromChange($event) {
    this.DemographicEndDatePicker.flatpickr.set({
      minDate: new Date(this.StartDate[0]),
    });

    if (this.StartDate[0] > this.EndDate[0] || !this.EndDate[0]) {
      this.EndDateOptions.defaultDate = new Date(this.StartDate[0])
    }
  }
 

  onMonthChange(mode: number, value: number) {
    if (mode == 1) {
      this.selectedBestMonth = value;
    } else if (mode == 2) {
      this.selectedWorstMonth = value;
    }
  }

  calculateMemberPercent(memberCount: number): number {
    return (memberCount / this.allMemberCount) * 100;
  }

  getBestMemberPerformanceResultList(): void {
    this.getMemberPerformanceResultList(
      1,
      this.memberPerformanceListLength
    ).then((res) => {
      this.bestTeamListAllTime = res;
    });
  }

  getWorstMemberPerformanceResultList(): void {
    this.getMemberPerformanceResultList(
      0,
      this.memberPerformanceListLength
    ).then((res) => {
      this.worstTeamListAllTime = res;
    });
  }

  getMemberPerformanceResultList(
    direction: number,
    length: number
  ): Promise<MemberPerformanceData[]> {
    let searchData: any = {
      params: {
        Direction: direction,
        // SortPath: 'total',
        PageLength: length,
      },
    };

    return new Promise((resolve, rejects) => {
      this._apiService
        .get('Dashboard/Member/Performance', searchData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<MemberPerformanceData>) => {
            resolve(res.data.resultData);
            this.blockUI.stop();
          },
          (err) => {
            rejects(err);
          }
        );
    });
  }

  getMemberPerformanceResultListByMonth(
    direction: number,
    length: number,
    year?: number,
    month?: number
  ): Promise<MemberPerformanceData[]> {
    this.blockUI.start();
    let searchData: any = {
      params: {
        Direction: direction,
        // SortPath: 'total',
        PageLength: length,
      },
    };

    if (year && month) {
      let dateFrom: string = moment()
        .set('year', year)
        .set('month', month)
        .startOf('month')
        .toISOString();
      let dateTo: string = moment()
        .set('year', year)
        .set('month', month)
        .endOf('month')
        .toISOString();

      searchData.params.DateFrom = dateFrom;
      searchData.params.DateTo = dateTo;
    }

    return new Promise((resolve, rejects) => {
      this._apiService
        .get('Dashboard/Member/PerformanceByMonth', searchData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<MemberPerformanceData>) => {
            resolve(res.data.resultData);
            this.blockUI.stop();
          },
          (err) => {
            rejects(err);
            this.blockUI.stop();
          }
        );
    });
  }

  getProviceMemberResultList(): Promise<void> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .get('Dashboard/Member/ByProvince')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<MemberByProvinceResponse>) => {
            this.provinceList = res.data.resultData[0].memberByProvinceList;
            this.provinceOriginalList = this.provinceList;
            this.provinceList.forEach((element, index) => {
              this.provinceList[index].provinceId = trim(
                element.provinceId,
                ' '
              );
              this.provinceColorMap.push({
                id: `TH-${element.provinceId}`,
                value: element.sumTotal,
                provinceId: parseInt(element.provinceId),
                regionId: element.regionId,
              });
            });
            this.receiveMapsData(this.provinceColorMap);
            resolve();
          },
          (err) => {
            rejects(err);
          }
        );
    });
  }

  getResult(): Promise<void> {
    let searchData: {
      provinceId?: string;
      regionId?: string;
      dateFrom: string;
      dateTo: string;
    } = { provinceId: '', regionId: '', dateFrom: '', dateTo: '' };

    if (this.provinceSelected.length >= 1) {
      let provinceList: string[] = this.provinceSelected.map(
        (p) => p.provinceId
      );

      provinceList.forEach((e, i) => {
        if (provinceList.length > 1)
          searchData.provinceId += (i > 0 ? '+' : '') + e;
        else searchData.provinceId = e;
      });
    } else if (this.regionSelected.length >= 1) {
      this.regionSelected.forEach((e, i) => {
        if (this.regionSelected.length > 1)
          searchData.regionId += (i > 0 ? '+' : '') + e.toString();
        else searchData.regionId = e.toString();
      });
    }

    // if (this.DateRange.length >= 1) {
    //   if (this.DateRange[0]) {
    //     let dateFrom: Date = this.DateRange[0];
    //     searchData.dateFrom = moment(dateFrom).startOf('day').toISOString();
    //   }

    //   if (this.DateRange[1]) {
    //     let dateTo: Date = this.DateRange[1];
    //     searchData.dateTo = moment(dateTo).endOf('day').toISOString();
    //   }
    // }

    if (this.StartDate[0]) {
      let dateFrom: Date = this.StartDate[0];
      searchData.dateFrom = moment(dateFrom).startOf('day').toISOString();
    }

    if (this.EndDate[0]) {
      let dateTo: Date = this.EndDate[0];
      searchData.dateTo = moment(dateTo).endOf('day').toISOString();
    }

    return new Promise((resolve, rejects) => {
      this._apiService
        .get('Dashboard/Member/DemographicMap', { params: searchData })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<MemberDemographicMap>) => {
            let data = res.data.resultData[0];
            this.totalMemberCount = data.totalMember;
            this.newMemberCount = data.newMember;
            this.existingMemberCount = data.existingMember;
            this.saleTotalCount = data.saleTotal;
            this.percentageMember = this.calculateMemberPercent(
              data.totalMember
            );
            resolve();
          },
          (err) => {
            rejects(err);
          }
        );
    });
  }

  getDropdownYear() {
    this._apiService
      .GetAllData(
        'Dashboard/ForSearch/DropDown/GetNewestDataAndOldest/Customer'
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: CommonResponse<{ min: string; max: string }>) => {
        console.log(res.data.resultData);
        let minYear = new Date(res.data.resultData[0].min);
        let maxYear = new Date(res.data.resultData[0].max);

        this.yearList = this.getRangeOfYears(
          minYear.getFullYear(),
          maxYear.getFullYear()
        );
      });
  }

  getRangeOfYears(startYear: number, endYear: number): number[] {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  }

  exportReport(mode: number, selectedYear: number, selectedMonth: number) {
    let path: string = '';
    let filename: string = '';
    let selectedTime: string = '';
    let params: { params: any } = {
      params: {},
    };

    if (selectedMonth && selectedYear) {
      if ((this.currentLang = 'en')) {
        selectedTime = ` ${this.months[selectedMonth].nameEN}/${selectedYear}`;
      } else {
        selectedTime = ` ${this.months[selectedMonth].nameTH}/${selectedYear}`;
      }
    }

    if (mode == 1) {
      path = `excel/Export`;
      filename = `Top 5 Best Performance${selectedTime}`;
    } else {
      path = `excel/Export`;
      filename = `Top 5 Worst Performance${selectedTime}`;
    }

    console.log(filename, params);

    return;

    this._filesApiService.getExcelReport(path, filename, params);
  }
}
