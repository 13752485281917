import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { TimelineItem } from 'app/main/components/horizontal-timeline/horizontal-timeline.component';
import { DocumentService } from '../../document.service';
import { AuthenticationService } from 'app/auth/service';
import { User } from 'app/auth/models';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/main/service/api.service';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { CalculateFuncService } from 'app/main/service/calculate-func.service';

import {
  DebitNote,
  DebitNoteItem,
  TempRowDebitNote,
} from 'app/main/model/DebitNote';
import { Invoice, InvoiceItem, TempRowInvoice } from 'app/main/model/Invoice';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-debit-note-detail',
  templateUrl: './debit-note-detail.component.html',
  styleUrls: ['./debit-note-detail.component.scss'],
})
export class DebitNoteDetailComponent implements OnInit,AfterViewInit {
  @Input() titleDoc: string;

  contentHeader: object;
  typeDocName: string;
  currentUser: User;
  timelineData: TimelineItem[];

  debitNoteObj: DebitNote;
  invoiceObj: Invoice;
  customerAddressObj: CustomerAddress;

  orderId: string;
  orderStatus: any;
  currentStep: number;

  apiPath: string;
  editPathUrl: string;

  docID: string;
  docStatus: number;
  invoiceID: any;
  isLoading: boolean;

  sumNetPrice: number;
  sumVatPrice: number;

  refDetailUrl: string;
  detailPathUrl: string;

  debitnotedetail: string = 'debit-note-detail';
  apiInvoicePath: string;

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private _documentService: DocumentService,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _documentApiService: DocumentApiService,
    public _route: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private _apiService: ApiService,
    private _calculateService: CalculateFuncService,
    private _globalFuncService: GlobalFuncService
  ) {
    this.timelineData = this._documentService.timelineData;
    this.apiPath = this._documentService.debitNote.ApiPath;
    this.editPathUrl = this._documentService.debitNote.EditPathURL;
    this.detailPathUrl = this._documentService.debitNote.DetailPathURL;
    this.refDetailUrl = this._documentService.invoice.DetailPathURL;
    this.apiInvoicePath = this._documentService.invoice.ApiPath;

    this.initData();
  }
  SetLoadingState() {
    this.isLoading = true;
  }
  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;

    this._authenticationService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    this.contentHeader = {
      headerTitle: 'Document.DebitNote',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Document',
            isLink: true,
            link: '/',
          },
          {
            name: 'Document.DebitNote',
            isLink: true,
            link: '/document/debit-note/view',
          },
          {
            name: 'FormName.DebitNoteDetail',
            isLink: false,
            link: '/document/debit-note/view',
          },
        ],
      },
    };
  }

  activeTab: string = 'detail';
  @ViewChild('navFilled') nav: NgbNav;
  public active_fragment: BehaviorSubject<string> = new BehaviorSubject('');
  
  ngAfterViewInit(): void {
    this._route.fragment.subscribe((frag) => {
      this.active_fragment.next(frag);
      
      console.log(this.active_fragment.value)
      if (!this.active_fragment.value) {
        this.nav.select('detail')
        this.activeTab = 'detail'
        console.log('select default page')
      } else {
        this.activeTab = this.active_fragment.value
      }
      
    });
  }

  refreshData() {
    this.initData();
  }

  initData(): void {
    this.SetLoadingState();
    this.blockUI.start();
    this.typeDocName = 'debitNoteCode'; //code
    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
    });
    console.log('Doc id : ' + this.docID);
    console.log(this.apiPath);

    this.getDebitNoteData();
  }

  getDebitNoteData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocData(this.apiPath, this.docID)
        .subscribe((res) => {
          self.debitNoteObj = res.data.resultData[0];
          self.getCustomerAddress(self.debitNoteObj.customerId);
          self.calculateSummary();
          self.SetLoadedState();
          console.log(self.debitNoteObj);

          this.docStatus = self.debitNoteObj.transactionStatusId;
          console.log('Current Status: ', this.docStatus);

          this.orderId = self.debitNoteObj.orderId;

          this.getOrderStatus(this.orderId).subscribe((orderStatusRes) => {
            this.orderStatus = orderStatusRes.data.resultData;
            this.blockUI.stop();
            console.log('Order Status: ', this.orderStatus[0]);
            this.currentStep =
              this.orderStatus[0].invoice[0].transactionStatusId;
          });

          this.invoiceID = this.debitNoteObj.invoiceId;
          console.log('invoiceid : ' + this.invoiceID);
          console.log(this.apiInvoicePath);
          this._documentApiService
            .GetDocData(this.apiInvoicePath, this.invoiceID)
            .subscribe((res) => {
              self.invoiceObj = res.data.resultData[0];
              self.getCustomerAddress(self.invoiceObj.customerId);
              console.log(self.invoiceObj);
            });
        });
    });
  }

  getOrderStatus(orderId: string): Observable<any> {
    return this._apiService.GetDataById('Order/State', orderId);
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData.find((it) => {
            return it['useForShipping'] == true;
          });
        });
    });
  }

  //change model
  calculateSummary() {
    let itemList = [];

    this.debitNoteObj.item.forEach((element, index) => {
      let tempRow: TempRowDebitNote = new TempRowDebitNote();
      tempRow.rowType = 'item';
      tempRow.itemId = element.itemId;
      tempRow.description = element.description[0].description;
      tempRow.discount = element.discount;
      tempRow.quantity = element.quantity;
      tempRow.unit = element.unit;
      tempRow.unitPrice = element.unitPrice;
      tempRow.whtRate = 0.0;

      itemList.push(tempRow);
    });

    this.sumNetPrice = this._calculateService.calculateNet(
      itemList,
      true,
      this.debitNoteObj.taxRate,
      this.debitNoteObj.discount
    );

    this.sumVatPrice = this._calculateService.calculateVat(
      this.sumNetPrice,
      this.debitNoteObj.taxRate
    );
  }

  convertNumbertoMoneyFormat(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }

  handlePaymentDetailRefresh() {
    this.initData();
    console.log('update new data!!');
  }
}
