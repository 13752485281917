import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
} from '@angular/core';
import { environment } from 'environments/environment';

@Directive({
  selector: 'img',
})
export class DefaultImageDirective {

  constructor(private el: ElementRef) {}

  @HostListener('error')
  onImageError() {
    this.el.nativeElement.src = environment.defaultIMG;
  }
}
