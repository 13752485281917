<div style="background: #f0f0f0" class="col-md-12 form-group border">
  <div class="d-flex p-1 justify-content-between align-items-center">
    <b class="title-customer">
      <i [size]="22" class="feather_titlemr-1" data-feather="user-check"></i>
      {{ "Customer.personalInfo" | translate }}
    </b>
  </div>
</div>

<div class="form-group row">
  <div class="col-md-12 col-form-lable mb-1">
    <u>{{ "Customer.primaryContact" | translate }}</u> <br />
    <label for="helpInputTop">{{
      "Customer.specifyContactInfo" | translate
    }}</label>
    <small class="text-muted">
      <i>{{ "Customer.pleaseSpecify" | translate }}</i></small
    >
  </div>
  <div class="col-md-3 col-form-label">
    {{ "Customer.creditorLabel" | translate }}
  </div>
  <div class="col-md-9">
    <select
      class="custom-select"
      formControlName="debtor"
      [ngClass]="{
        'is-invalid error':
          (f.debtor?.invalid && (f.debtor?.dirty || f.debtor?.touched)) ||
          (f.debtor?.value == '' && (f.debtor?.dirty || f.debtor?.touched))
      }"
    >
      <option>113101 - ลูกหนี้การค้า</option>
      <option>113202 - เงินให้กู้ยืม-บุคคลอื่น</option>
      <option>113201 - เงินให้กู้ยืม-บุคคลที่เกี่ยวข้องกัน</option>
    </select>
    <div
      class="invalid-feedback"
      [ngClass]="{
        'd-block': f.debtor.invalid && (f.debtor.dirty || f.debtor.touched)
      }"
    >
      <div>
        <div *ngIf="f.debtor.errors?.required">
          {{ "Customer.debtorRq" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <div class="col-md-3 col-form-label">
    {{ "Customer.accountsPayableRecord" | translate }}
  </div>
  <div class="col-md-9">
    <select
      class="custom-select"
      formControlName="creditor"
      [ngClass]="{
        'is-invalid error':
          (f.creditor?.invalid &&
            (f.debcreditortor?.dirty || f.creditor?.touched)) ||
          (f.creditor?.value == '' &&
            (f.creditor?.dirty || f.creditor?.touched))
      }"
    >
      <option value="58">212101 - เจ้าหนี้การค้า</option>
      <option value="234">212103 - รายได้รับล่วงหน้า</option>
      <option value="235">212104 - เงินรับล่วงหน้า - เงินมัดจำ</option>
      <option value="236">212105 - เงินรับล่วงหน้า - เงินประกัน</option>
      <option value="60">
        212201 - เงินกู้ยืมระยะสั้นจากบุคคลที่เกี่ยวข้องกัน
      </option>
      <option value="61">212202 - เงินกู้ยืมระยะสั้นจากบุคคลอื่น</option>
      <option value="215">212301 - เงินเดือนค้างจ่าย</option>
      <option value="216">212302 - กองทุนสำรองเลี้ยงชีพค้างจ่าย</option>
      <option value="217">212303 - ค่าสอบบัญชีค้างจ่าย</option>
      <option value="218">212304 - ค่าทำบัญชีค้างจ่าย</option>
      <option value="219">212305 - ค่าใช้จ่ายค้างจ่ายอื่น</option>
      <option value="63">212306 - เจ้าหนี้อื่น</option>
      <option value="237">212307 - เจ้าหนี้เช่าซื้อ</option>
      <option value="66">215101 - ภาษีขาย ภ.พ.30</option>
      <option value="67">215102 - ภาษีขายรอเรียกเก็บ</option>
      <option value="68">215103 - ภาษีขาย ภ.พ.36</option>
      <option value="69">215201 - ภ.ง.ด. 1 ค้างจ่าย</option>
      <option value="70">215202 - ภ.ง.ด. 2 ค้างจ่าย</option>
      <option value="71">215203 - ภ.ง.ด. 3 ค้างจ่าย</option>
      <option value="72">215204 - ภ.ง.ด. 53 ค้างจ่าย</option>
      <option value="73">215205 - ภ.ง.ด. 54 ค้างจ่าย</option>
      <option value="74">215301 - ภาษีเงินได้ค้างจ่าย</option>
      <option value="230">215302 - เจ้าหนี้สรรพากร</option>
      <option value="31914">215351 - เจ้าหนี้สรรพากร ภ.พ.30 รอชำระ</option>
      <option value="46507">215352 - เจ้าหนี้สรรพากร ภ.ง.ด. 1 รอชำระ</option>
      <option value="46508">215353 - เจ้าหนี้สรรพากร ภ.ง.ด. 2 รอชำระ</option>
      <option value="46509">215354 - เจ้าหนี้สรรพากร ภ.ง.ด. 3 รอชำระ</option>
      <option value="46510">215355 - เจ้าหนี้สรรพากร ภ.ง.ด. 53 รอชำระ</option>
      <option value="231">215401 - ภ.ธ. 40 ค้างจ่าย</option>
      <option value="75">215501 - ประกันสังคมค้างจ่าย</option>
      <option value="238">216101 - ดอกเบี้ยค้างจ่าย</option>
      <option value="76">216102 - หนี้สินหมุนเวียนอื่น</option>
    </select>
    <div
      class="invalid-feedback"
      [ngClass]="{
        'd-block':
          f.creditor.invalid && (f.creditor.dirty || f.creditor.touched)
      }"
    >
      <div>
        <div *ngIf="f.creditor.errors?.required">
          {{ "Customer.creditorRq" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
