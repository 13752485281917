import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  config = {
    apiPath: 'DetailHydroethosome',  //?ชื่อ api
    pathUrl: 'cms/repove-tools/technology/hydroethosome/detail',  //?path
    tableName: 'Content',
    model: new Object(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.RepoveTools.Technology.HydroethosomeDetail',
    formConfig: {
      bannerImg: {
        type: FormType.SingleImage,
        label: 'Banner Image',
        description: 'Resolution: 1800px*600px',
        labelTranslate: 'Informative.RepoveToolsTechnology.BannerImg',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.BannerImgDescription',
        validator: [Validators.required],
      },
      bannerDescription: {
        type: FormType.Text,
        isMultipleLang: true,
        label: 'Banner Description',
        labelTranslate: 'Informative.RepoveToolsTechnology.BannerDescription',
        validator: [Validators.required],
      },
      bannerSubDescription: {
        type: FormType.Textarea,
        isMultipleLang: true,
        label: 'Banner Sub Description',
        description: 'The secondary banner description is below the banner description.',
        labelTranslate: 'Informative.RepoveToolsTechnology.BannerSubDescription',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.BannerSubDescriptionDes',
        validator: [Validators.required],
      },
      technoTitle: {
        type: FormType.Text,
        isMultipleLang: true,
        label: 'Quote Title 1',
        description:'Quote Description',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.QuoteTitle1',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.QuoteDescription',
        validator: [Validators.required],
      },
      technoImg: {
        type: FormType.SingleImage,
        label: 'Quote Image 1',
        description:'Resolution 400px*405px',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.QuoteImage1',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.QuoteImageDescription',
        validator: [Validators.required],
      },
      technoFeatureCardIcon1:{
        type: FormType.SingleImage,
        label: 'Feature card icon 1',
        description:'Resolution 60px*60px',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardIcon1',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardIconDescription',
        validator: [Validators.required],
      },
      technoFeatureCardDetail1: {
        type: FormType.Textarea,
        isMultipleLang: true,
        label: 'Feature card detail 1',
        description: 'Details of features below icon',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardDetail1',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardDetailDescription',
        validator: [Validators.required],
      },
      technoFeatureCardIcon2:{
        type: FormType.SingleImage,
        label: 'Feature card icon 2',
        description:'Resolution 60px*60px',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardIcon2',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardIconDescription',
        validator: [Validators.required],
      },
      technoFeatureCardDetail2: {
        type: FormType.Textarea,
        isMultipleLang: true,
        label: 'Feature card detail 2',
        description: 'Details of features below icon',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardDetail2',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardDetailDescription',
        validator: [Validators.required],
      },
      technoFeatureCardIcon3:{
        type: FormType.SingleImage,
        label: 'Feature card icon 3',
        description:'Resolution 60px*60px',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardIcon3',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardIconDescription',
        validator: [Validators.required],
      },
      technoFeatureCardDetail3: {
        type: FormType.Textarea,
        isMultipleLang: true,
        label: 'Feature card detail 3',
        description: 'Details of features below icon',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardDetail3',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardDetailDescription',
        validator: [Validators.required],
      },
      technoFeatureCardIcon4:{
        type: FormType.SingleImage,
        label: 'Feature card icon 4',
        description:'Resolution 60px*60px',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardIcon4',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardIconDescription',
        validator: [Validators.required],
      },
      technoFeatureCardDetail4: {
        type: FormType.Textarea,
        isMultipleLang: true,
        label: 'Feature card detail 4',
        description: 'Details of features below icon',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardDetail4',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardDetailDescription',
        validator: [Validators.required],
      },
      technoFeatureCardIcon5:{
        type: FormType.SingleImage,
        label: 'Feature card icon 5',
        description:'Resolution 60px*60px',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardIcon5',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardIconDescription',
        validator: [Validators.required],
      },
      technoFeatureCardDetail5: {
        type: FormType.Textarea,
        isMultipleLang: true,
        label: 'Feature card detail 5',
        description: 'Details of features below icon',
        labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardDetail5',
        descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.FeatureCardDetailDescription',
        validator: [Validators.required],
      },
    }
  };

  constructor() { }

  ngOnInit(): void {
  }

}
