import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isActiveFilter',
})

export class IsActivePipe implements PipeTransform {
  transform(items: any[], status: string, key: string): any[] {
    if (!items) return [];
    if (status == 'all') return items;

    else if (status == 'active')
      return items.filter((it) => {
        return it[key] == true;
      });
    else if (status == 'inactive')
      return items.filter((it) => {
        return it[key] == false;
      });
    else return items;
  }
}
