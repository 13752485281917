export interface TimeOnly {
  hours: number;
  minutes: number;
  seconds: number;
}

export interface BookingPeriodTime {
  Period: number;
  StartTime: TimeOnly;
  EndTime: TimeOnly;
}

export const bookingPeriodTime: BookingPeriodTime[] = [
  {
    Period: 0,
    StartTime: { hours: 0, minutes: 0, seconds: 0 },
    EndTime: { hours: 23, minutes: 59, seconds: 59 },
  },
  {
    Period: 1,
    StartTime: { hours: 8, minutes: 0, seconds: 0 },
    EndTime: { hours: 10, minutes: 0, seconds: 0 },
  },
  {
    Period: 2,
    StartTime: { hours: 10, minutes: 0, seconds: 0 },
    EndTime: { hours: 12, minutes: 0, seconds: 0 },
  },
  {
    Period: 3,
    StartTime: { hours: 12, minutes: 0, seconds: 0 },
    EndTime: { hours: 14, minutes: 0, seconds: 0 },
  },
  {
    Period: 4,
    StartTime: { hours: 14, minutes: 0, seconds: 0 },
    EndTime: { hours: 16, minutes: 0, seconds: 0 },
  },
  {
    Period: 5,
    StartTime: { hours: 16, minutes: 0, seconds: 0 },
    EndTime: { hours: 18, minutes: 0, seconds: 0 },
  },
  {
    Period: 6,
    StartTime: { hours: 20, minutes: 0, seconds: 0 },
    EndTime: { hours: 22, minutes: 0, seconds: 0 },
  },
];

export enum BookingStatusEnum {
  WaitApprove = 0,
  Approved = 1,
  Reject = 2,
}

export interface MeetingRoomBooking {
  isActive: boolean;
  isDelete: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  deleteBy: string;
  deleteDate: string;
  id: string;
  branchId: string;
  meetingRoomId: string;
  customerId: string;
  customerName: string;
  topic: string;
  bookingHexColor: string;
  stateEnum: number;
  periodTimeEnum: number;
  startDate: string;
  startDateUnixTime: number;
  endDate: string;
  endDateUnixTime: number;
  approvedDate: string;
  rejectDate: string;
  remarks: string;
}
