import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-partibiome',
  templateUrl: './partibiome.component.html',
  styleUrls: ['./partibiome.component.scss']
})
export class PartibiomeComponent implements OnInit {

  config: ICMSConfig = {
    apiPath: 'partibiome',
    tableName: 'Content',
    pathUrl: 'cms/repove-tools/technology/partibiome',
    model: new Object(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.Title',
    formConfig: {
      bannerTitle: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Banner Title',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.BannerTitle',
      },
      bannerSubtitle: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Banner Subtitle',
        // description: 'Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.BannerSubtitle',
      },
      bannerImage: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Banner Image',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.BannerImage',
      },


      contentTitle: {
        type: FormType.Text,
        validator: [Validators.required],
        // label: 'Content Detail one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.ContentDetail1',
      },
      contentSubtitle: {
        type: FormType.Textarea,
        validator: [Validators.required],
        // label: 'Content Detail two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.ContentDetail2',
      },

      cardTitle1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Card Title one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.CardTitle1',
      },
      cardDetail1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Card Detail one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.CardDetail1',
      },
      cardTitle2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Card Title two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.CardTitle2',
      },
      cardDetail2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Card Detail two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.CardDetail2',
      },
      cardTitle3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Card Title three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.CardTitle3',
      },
      cardDetail3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Card Detail three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.CardDetail3',
      },
      cardTitle4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Card Title four',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.CardTitle4',
      },
      cardDetail4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Card Detail four',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.CardDetail4',
      },


      bottleTitle: {
        type: FormType.Textarea,
        validator: [Validators.required],
        // label: 'Bottle Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.BottleTitle',
      },
      bottleSubTitle: {
        type: FormType.Textarea, 
        validator: [Validators.required],
        // label: 'Bottle Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.BottleSubtitle',
      },


      stepBackground: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction Step Background',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepBackground',
      },

      stepIcon1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction Step Icon one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepIcon1',
      },
      stepIconHover1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction Step Hover Icon one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepHoverIcon1',
      },
      steptitle1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Step Mini Title one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepTitle1',
      },
      stepSubtitle1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Step Title one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepSubtitle1',
      },
      stepIcon2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction Step Icon two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepIcon2',
      },
      stepIconHover2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction Step Hover Icon two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepHoverIcon2',
      },
      steptitle2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Step Mini Title two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepTitle2',
      },
      stepSubtitle2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Step Title two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepSubtitle2',
      },
      stepIcon3: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction Step Icon three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepIcon3',
      },
      stepIconHover3: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction Step Hover Icon three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepHoverIcon3',
      },
      steptitle3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Step Mini Title three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepTitle3',
      },
      stepSubtitle3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Step Title three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepSubtitle3',
      },
      stepIcon4: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction Step Icon four ',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepIcon4',
      },
      stepIconHover4: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction Step Hover Icon four',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepHoverIcon4',
      },
      steptitle4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Step Mini Title four',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepTitle4',
      },
      stepSubtitle4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Step Title four',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepSubtitle4',
      },
      stepIcon5: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction Step Icon five',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepIcon5',
      },
      stepIconHover5: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Extraction Step Hover Icon five',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepHoverIcon5',
      },
      steptitle5: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Step Mini Title five',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepTitle5',
      },
      stepSubtitle5: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Extraction Step Title five',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.StepSubtitle5',
      },


      labTestImage1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Lab Test Image Left',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.LeftImage',
      },
      labTestImage2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Lab Test Image Right',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.Technology.Partibiome.RightImage',
      }
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}