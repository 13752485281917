import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-approve-doc-modal',
  templateUrl: './approve-doc-modal.component.html',
  styleUrls: ['./approve-doc-modal.component.scss']
})
export class ApproveDocModalComponent implements OnInit {
  @Input() public modalConfig: NgbModalConfig;

  @Input() isConfirm: boolean;
  @Input() title: string;
  @Input() detail: string;
  @Input() remarks: string;
  @Input() confirmUrl: string;

  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();

  isEditingRemarks: boolean = false;

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal
  ) {}

  closeModal(): void {
    this.activeModal.close();
  }
  callBack(): void {
    this.callBackFunc.emit(this.remarks);
    this.closeModal();
    // this.router.navigate([this.confirmUrl]);
  }

  ngOnInit(): void {
      
  }

}
