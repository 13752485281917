import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Invoice } from 'app/main/model/Order';
import { ReturnProductStatusEnum } from 'app/main/model/ReturnProduct';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FileTypeFilter } from '../custom-dropzone/file-type.enum';
import { CustomDropzoneComponent } from '../custom-dropzone/custom-dropzone.component';
import { ApiService } from 'app/main/service/api.service';
import { FilesApiService } from 'app/main/service/files-api.service';
import { CommonResponse } from 'app/main/model/CommonResponse';
import { ComponentsService } from '../components.service';
import { ModalComponent } from '../modal/modal.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-return-product',
  templateUrl: './modal-return-product.component.html',
  styleUrls: ['./modal-return-product.component.scss'],
})
export class ModalReturnProductComponent implements OnInit {
  @Input() invoice: Invoice;
  @ViewChild('CustomDropzone') dropzoneComponent: CustomDropzoneComponent;

  stateEnum = ReturnProductStatusEnum;
  formGroup: FormGroup;
  isSubmit: boolean = false;
  FileTypeFilter = FileTypeFilter;

  images: Blob[] = [];
  isImagesChanged: boolean = false;
  isReturn: boolean = false;

  @BlockUI() blockUI: NgBlockUI;
  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private fileService: FilesApiService,
    private componentsService: ComponentsService,
    private _translateService: TranslateService,
    private router: Router
  ) {
    this.formGroup = this.formBuilder.group({
      returnType: [1, []],
      returnState: [0, []],
      returnReasons: ['', [Validators.required]],
      returnOtherRemarks: ['', []],
      returnApproveRemarks: ['', []],
      returnRejectRemarks: ['', []],
    });
  }

  ngOnInit(): void {
    if (this.invoice) {
      this.formGroup.patchValue({
        returnType: this.invoice.returnType,
        returnState: this.invoice.returnState,
        returnReasons: this.invoice.returnReasons,
        returnOtherRemarks: this.invoice.returnOtherRemarks,
      });
      this.isReturn = this.invoice.isReturn;

      if (this.isReturn) {
        this.disableForm();
      }
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  get f() {
    return this.formGroup.controls;
  }

  disableForm() {
    this.formGroup.disable();
  }

  callBack() {
    this.callBackFunc.emit();
  }

  Submit() {
    this.isSubmit = true;

    if (this.formGroup.invalid) {
      return;
    }

    const modalRef = this.modalService.open(ModalComponent, { centered: true });
    modalRef.componentInstance.title = this._translateService.instant(
      'ReturnProduct.SaveRequestModalTitle'
    );
    modalRef.componentInstance.detail = this._translateService.instant(
      'ReturnProduct.SaveRequestModalDetail'
    );
    modalRef.componentInstance.callBackFunc.subscribe(() => {
      this.saveData();
    });
  }

  saveData(): void {
    this.blockUI.start();

    let savePromise: Promise<void>[] = [];

    this.saveReturnProductRequest().then((res) => {
      savePromise.push(
        new Promise((r) => {
          r();
        })
      );
    });

    if (this.isImagesChanged && this.images.length >= 1) {
      this.saveFileToRequest(this.images).then((res) => {
        savePromise.push(
          new Promise((r) => {
            r();
          })
        );
      });
    }

    Promise.all(savePromise).then(
      (suc) => {
        this.blockUI.stop();
        this.modalService.dismissAll();
        this.callBack();
        this.componentsService.SuccessSwal();
      },
      (err) => {
        this.blockUI.stop();
        this.componentsService.ErrorSwal();
      }
    );
  }

  onFilesUploaded(files: File[]): void {
    this.isImagesChanged = true;
    this.images = files;
  }

  saveReturnProductRequest(): Promise<CommonResponse<any>> {
    return new Promise((resolve, rejects) => {
      this.apiService
        .AddData(`invoice/${this.invoice.id}/return`, this.formGroup.value)
        .subscribe(
          (res: CommonResponse<any>) => {
            resolve(res);
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  saveFileToRequest(filePool: Blob[]): Promise<CommonResponse<any>> {
    let fileArr = new FormData();
    filePool.forEach((file: Blob) => {
      fileArr.append('files', file);
    });

    return new Promise((resolve, rejects) => {
      this.fileService
        .AddFile(`invoice`, this.invoice.id, 'returnGoodsFile', 0, fileArr)
        .subscribe(
          (res: CommonResponse<any>) => {
            resolve(res);
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  getStatusLabel(state: number): any {
    switch (state) {
      case this.stateEnum.WaitApprove:
        return this._translateService.instant(
          'BookingCalendar.Status.WaitApprove'
        );
      case this.stateEnum.Approved:
        return this._translateService.instant(
          'BookingCalendar.Status.Approved'
        );
      case this.stateEnum.Reject:
        return this._translateService.instant('BookingCalendar.Status.Reject');

      default:
        return this._translateService.instant(
          'BookingCalendar.Status.WaitApprove'
        );
    }
  }
}
