import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSummernoteModule } from 'ngx-summernote';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { ComponentsModule } from 'app/main/components/components.module';
import { CmsFormpageComponent } from './cms-formpage.component';

@NgModule({
  declarations: [CmsFormpageComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ContentHeaderModule,
    FormsModule,
    NgxSummernoteModule,
    ReactiveFormsModule,
    CorePipesModule,
    ComponentsModule,
  ],
  exports: [CmsFormpageComponent],
})
export class CmsFormpageModule {}
