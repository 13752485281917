import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
  ViewChild,
} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Customer from 'app/main/model/Customer';
import { ApiService } from 'app/main/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-customer-bank',
  templateUrl: './customer-bank.component.html',
  styleUrls: ['./customer-bank.component.scss'],
})
export class CustomerBankComponent implements OnInit {
  registerForm: FormGroup;
  otpLengthArray: number[];
  compiledOtp: string;
  DistrictActive: Boolean;
  eventLog: any;
  currentLang: string;
  clipboardData: string;
  itemObj: Customer;
  isEditing: boolean;
  index_pid: number[];
  public isCollapsed5 = true;

  constructor(
    private _apiService: ApiService,
    private clipboard: Clipboard,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _translateService: TranslateService,
    private _router: Router
  ) {
    this.currentLang = this._translateService.currentLang;

    this.registerForm = this._formBuilder.group({
      id: [null],
      //ข้อมูลธนาคาร
      bank_name: ['', Validators.required],
      bank_addr: ['', Validators.required],
      bank_id: ['', Validators.required],
      bank_group: ['', Validators.required],
      bank_type: ['', Validators.required],
      // ใบแจ้งหนี้
      set_sell: ['', Validators.required],
      set_buy: ['', Validators.required],
    });
  }
  get f() {
    return this.registerForm.controls;
  }
  @ViewChildren('inputElements') inputElements: QueryList<ElementRef>;
  showResult: boolean = false;
  result: string = '';

  initForm(isEditing: boolean): void {
    if (isEditing) {
      // this.getitemData(this.itemID);
    } else {
      this.itemObj = new Customer();
      // this.isLoading = false;
    }
  }

  ngOnInit(): void {
    this.initForm(this.isEditing);
    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });
  }
  // buildForm(): void {
  //   var self = this;
  //   this.registerForm.patchValue({
  //     id: self.itemObj.id,

  //     p_id: self.itemObj.p_id,
  //     office: self.itemObj.office,
  //     office_type: self.itemObj.office_type,
  //     debtor: self.itemObj.debtor,
  //     creditor: self.itemObj.creditor,
  //     fname: self.itemObj.fname,
  //     lname: self.itemObj.lname,
  //     n_name: self.itemObj.n_name,
  //     rank: self.itemObj.rank,
  //     gb_phone: self.itemObj.gb_phone,
  //     gb_fax: self.itemObj.gb_fax,
  //     gb_email: self.itemObj.gb_email,
  //     gb_site: self.itemObj.gb_site,
  //     c_name: self.itemObj.c_name,
  //     c_address: self.itemObj.c_address,
  //     c_country: self.itemObj.c_country,
  //     c_province: self.itemObj.c_province,
  //     c_subDistrict: self.itemObj.c_subDistrict,
  //     c_district: self.itemObj.c_district,
  //     c_postcode: self.itemObj.c_postcode,
  //     s_name: self.itemObj.s_name,
  //     s_address: self.itemObj.s_address,
  //     s_country: self.itemObj.s_country,
  //     s_province: self.itemObj.s_province,
  //     s_subDistrict: self.itemObj.s_subDistrict,
  //     s_district: self.itemObj.s_district,
  //     s_postcode: self.itemObj.s_postcode,
  //     bank_name: self.itemObj.bank_name,
  //     bank_addr: self.itemObj.bank_addr,
  //     bank_id: self.itemObj.bank_id,
  //     bank_group: self.itemObj.bank_group,
  //     bank_type: self.itemObj.bank_type,
  //     set_sell: self.itemObj.set_sell,
  //     set_buy: self.itemObj.set_buy,
  //     country: self.itemObj.country,

  //     isActive: self.itemObj.isActive,
  //   });
  // }
}
