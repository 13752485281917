import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'app/main/service/api.service';
import { FilesApiService } from 'app/main/service/files-api.service';
import { ComponentsService } from '../../components.service';

@Component({
  selector: 'app-attached-file-uploader',
  templateUrl: './attached-file-uploader.component.html',
  styleUrls: ['./attached-file-uploader.component.scss'],
})
export class AttachedFileUploaderComponent implements OnInit {
  @Input() tablename: string;
  @Input() contentId: string;
  @Input() collectionName: string;
  @Input() ordinal: string;
  listAttachedFile: any[];

  constructor(
    private _apiService: ApiService,
    private _fileService: FilesApiService,
    private _componentsService: ComponentsService
  ) {
    this.listAttachedFile = [];
  }

  onSelectAttachedFile(event) {
    if (event) {
      for (let i = 0; i < event.addedFiles.length; i++) {
        const file = event.addedFiles[i];
        const reader = new FileReader();
        reader.onload = () => {
          file.src = reader.result;
          if (this.listAttachedFile.length < 10) {
            this.listAttachedFile.push(file);
          } else {
            this._componentsService.ErrorSwal(
              'The number of files must not exceed 10'
            );
          }
          console.log(this.listAttachedFile);
        };
        reader.onerror = (error) => {
          console.error('File reading error:', error);
        };

        reader.readAsDataURL(file);
      }
    }
  }

  onRemoveAttachedFile(indexFile) {
    this.listAttachedFile.splice(indexFile, 1);
  }

  uploadFiles(
    tablename: string,
    contentId: string,
    collectionName: string,
    ordinal: number,
    fileList: any
  ): void {
    let fileArr = new FormData();
    fileList.forEach((file: Blob) => {
      fileArr.append('files', file);
    });

    console.log(fileArr);
    this._fileService
      .AddFile(tablename, contentId, collectionName, ordinal, fileArr)
      .subscribe((res) => {});
  }

  onSubmit() {
    if (this.listAttachedFile.length > 0) {
      this.uploadFiles(null,null,null, null, this.listAttachedFile);
    }
  }

  ngOnInit(): void {}
}
