import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DownlineBonusTableComponent } from './downline-bonus-table.component';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreCommonModule } from '@core/common.module';
import { DataTablesModule } from 'angular-datatables';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

const path: string = 'mlm-config/downline-bonus-table-config';
const routes: Routes = [
  {
    path: path,
    component: DownlineBonusTableComponent,
    canActivate: [AuthGuard],
    data: { animation: 'mlm-dowline-bonus-plan' },
  },
];

@NgModule({
  declarations: [DownlineBonusTableComponent],
  imports: [
    CommonModule,RouterModule.forChild(routes),FormsModule
    ,ReactiveFormsModule,CoreCommonModule,DataTablesModule
    ,ContentHeaderModule,NgbModule,TranslateModule
  ]
})
export class DownlineBonusModule { }
