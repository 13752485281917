<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <div class="lang-switcher-sidebar nav-vertical">
            <ul ngbNav class="nav nav-pills flex-column text-nowrap mb-0">
                <li ngbNavItem *ngFor="let lang of langList">
                    <a ngbNavLink (click)="selectedLanguage(lang)" [class.active]="selectedLang === lang">{{ lang |
                        uppercase }}</a>
                </li>
            </ul>
        </div>

        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <a [routerLink]="[pathUrl]">
                    <i data-feather="arrow-left" class="text-primary cursor-pointer back-to-manage-btn"></i>
                    {{ "Notification.Manage" | translate }}
                </a>
                <ul ngbNav class="nav nav-pills">
                    <li ngbNavItem *ngFor="let lang of langList">
                        <a ngbNavLink (click)="selectedLanguage(lang)" [class.active]="selectedLang === lang">{{ lang |
                            uppercase }}</a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <form [formGroup]="formGroup" id="notification-form">

                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">{{ "Form.Name" | translate }}
                            <span class="text-danger">*</span>
                        </label>
                        <div class="col-sm-9">
                            <input class="form-control" formControlName="name" [readonly]="isEditing" type="text"
                                [ngClass]="{
                              'is-invalid error':
                                (isSubmit && f.name?.invalid) ||
                                (f.name?.invalid && (f.name?.dirty || f.name?.touched)),
                                'disabled-form': isEditing 
                            }" [placeholder]="'Form.EnterName' | translate" />
                            <div class="invalid-feedback" [ngClass]="{
                              'd-block': f.name.invalid && (f.name.dirty || f.name.touched)
                            }">
                                <div>
                                    <div *ngIf="f.name.errors?.required">
                                        {{ "Handle.Name" | translate }}
                                    </div>
                                    <div *ngIf="f.name.errors?.maxlength">
                                        {{ "Handle.Exceed" | translate }}
                                        100
                                        {{ "Handle.Char" | translate }}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div formGroupName="title">
                        <div *ngFor="let lang of langList">
                            <div class="form-group row" [class.d-none]="selectedLang != lang">
                                <label class="col-sm-3 col-form-label">{{ "Form.Title" | translate }}
                                    <span class="text-danger">*</span>
                                    <br />
                                    <div *ngIf="langList.length > 1" class="badge badge-primary">
                                        {{ lang | uppercase }}
                                    </div>
                                </label>
                                <div class="col-sm-9">
                                    <input class="form-control" [formControlName]="lang" type="text"
                                        [readonly]="isEditing" [ngClass]="{
                              'is-invalid error':
                                (isSubmit && f.title?.invalid) ||
                                (f.title?.invalid && (f.title?.dirty || f.title?.touched)),
                                'disabled-form': isEditing 
                            }" [placeholder]="'Form.EnterName' | translate" />
                                    <div class="invalid-feedback" [ngClass]="{
                              'd-block': f.title.invalid && (f.title.dirty || f.title.touched)
                            }">
                                        <div>
                                            <div *ngIf="f.title.errors?.required">
                                                {{ "Handle.Name" | translate }}
                                            </div>
                                            <div *ngIf="f.title.errors?.maxlength">
                                                {{ "Handle.Exceed" | translate }}
                                                100
                                                {{ "Handle.Char" | translate }}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div formGroupName="description">
                        <div *ngFor="let lang of langList">
                            <div class="form-group row" [class.d-none]="selectedLang != lang">
                                <div class="col-sm-3 col-form-label">
                                    <label for="colFormLabelLg">
                                        {{ "Form.Description" | translate }}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <br />
                                    <div *ngIf="langList.length > 1" class="badge badge-primary">
                                        {{ lang | uppercase }}
                                    </div>
                                </div>
                                <div class="col-sm-9">
                                    <div [ngClass]="{
                                  'invalid-border':
                                    (isSubmit && f.description?.invalid) ||
                                    (f.description?.invalid &&
                                      (f.description?.dirty || f.description?.touched))
                                }">
                                        <textarea class="form-control" [ngxSummernote]="summernoteConfig"
                                            [formControlName]="lang" [ngClass]="{
                                        'is-invalid error':
                                          (isSubmit && f.description?.invalid) ||
                                          (f.description?.invalid && (f.description?.dirty || f.description?.touched))
                                      }" [ngxSummernoteDisabled]="isEditing"></textarea>

                                        <div class="invalid-feedback" [ngClass]="{
                              'd-block': f.description.invalid && (f.description.dirty || f.description.touched)
                            }">
                                            <div>
                                                <div *ngIf="f.description.errors?.required">
                                                    {{ "Handle.description" | translate }}
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">{{ "Notification.NotificationType" | translate }}
                            <span class="text-danger">*</span></label>
                        <div class="col-sm-9">

                            <div>
                                <ng-select formControlName="typeId" placeholder="" [clearable]="false"
                                    [readonly]="isEditing">
                                    <ng-option *ngFor="let typeId of notificationTypeIdList" [value]="typeId.id"
                                        [selected]="typeId.id == typeId">
                                        {{ typeId.name }}
                                    </ng-option>
                                </ng-select>
                            </div>

                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">{{ "MLM.Period.SendTo" | translate }}
                            <span class="text-danger">*</span></label>
                        <div class="col-sm-9">


                            <ng-select formControlName="notificationType" placeholder="" [clearable]="false"
                                [readonly]="isEditing" [closeOnSelect]="true" [ngClass]="{
                                'is-invalid error':
                                  (isSubmit && f.notificationType?.value == null) ||
                                  (isSubmit && f.notificationType?.invalid) ||
                                  (f.notificationType?.invalid &&
                                    (f.notificationType?.dirty || f.notificationType?.touched)) ||
                                  (f.notificationType?.value == null &&
                                    (f.notificationType?.dirty || f.notificationType?.touched))
                              }" (change)="checkNotificationType()">
                                <!-- <option value="All">{{ "General.All" | translate }}</option> -->
                                <ng-option [value]="notificationType.id"
                                    *ngFor="let notificationType of notificationTypeList"
                                    [selected]="notificationType.id == notificationType">
                                    {{ notificationType.name }}
                                </ng-option>
                            </ng-select>
                            <div>
                                <label for="helpInputTop"></label>
                                <small style="color: rgb(180, 180, 180)" [ngClass]="{
                                  'text-danger':
                                    (isSubmit && f.notificationType?.value == null) ||
                                    (isSubmit && f.notificationType?.invalid) ||
                                    (f.notificationType?.invalid &&
                                      (f.notificationType?.dirty || f.notificationType?.touched)) ||
                                    (f.notificationType?.value == null &&
                                      (f.notificationType?.dirty || f.notificationType?.touched))
                                }">
                                    <i>{{ "iTem.Validate" | translate }}</i></small>
                            </div>

                            <div *ngIf="f.notificationType?.value == 1">
                                <ng-select formControlName="customerIdList" placeholder="" [multiple]="true"
                                    [readonly]="isEditing"
                                    [ngClass]="{
                                'is-invalid error': f.notificationType?.value == 1 && f.customerIdList.value.length <= 0}">
                                    <ng-option *ngFor="let customer of customerList" [value]="customer.id">
                                        {{ customer.repoveCode }} - {{ customer.detail }}
                                    </ng-option>
                                </ng-select>
                                <div *ngIf="(f.notificationType?.value == 1 && f.customerIdList.value.length <= 0)">
                                    <label for="helpInputTop"></label>
                                    <small style="color: rgb(180, 180, 180)" [ngClass]="{
                                      'text-danger':
                                        (f.notificationType?.value == 1 &&
                                        f.customerIdList.value.length <= 0)
                                    }">
                                        <i>{{ "iTem.Validate" | translate }}</i></small>
                                </div>
                            </div>

                            <div *ngIf="f.notificationType?.value == 2" [ngClass]="{
                                'disabled-form': isEditing 
                              }">
                                <div class="custom-control custom-checkbox mr-1 mb-1">
                                    <input type="checkbox" class="custom-control-input" id="sendRankP"
                                        formControlName="sendRankP" [ngClass]="{
                                            'disabled-form': isEditing 
                                          }" />
                                    <label class="custom-control-label" for="sendRankP">P</label>
                                </div>

                                <div class="custom-control custom-checkbox mr-1 mb-1">
                                    <input type="checkbox" class="custom-control-input" id="sendRankAP"
                                        formControlName="sendRankAP" [ngClass]="{
                                            'disabled-form': isEditing 
                                          }" />
                                    <label class="custom-control-label" for="sendRankAP">AP</label>
                                </div>

                                <div class="custom-control custom-checkbox mr-1 mb-1">
                                    <input type="checkbox" class="custom-control-input" id="sendRankMP"
                                        formControlName="sendRankMP" [ngClass]="{
                                            'disabled-form': isEditing 
                                          }" />
                                    <label class="custom-control-label" for="sendRankMP">MP</label>
                                </div>

                                <div *ngIf="(f.notificationType?.value == 2 && 
                                    (!f.sendRankMP.value && !f.sendRankAP.value && !f.sendRankP.value))">
                                    <label for="helpInputTop"></label>
                                    <small style="color: rgb(180, 180, 180)" [ngClass]="{
                                      'text-danger':
                                        (f.notificationType?.value == 2 &&
                                          (!f.sendRankMP.value && !f.sendRankAP.value && !f.sendRankP.value))
                                    }">
                                        <i>{{ "iTem.Validate" | translate }}</i></small>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label for="colFormLabelLg">
                                {{ "Notification.StartDate" | translate }}
                            </label>
                        </div>
                        <div class="col-sm-9">
                            <div class="d-flex align-items-center" [ngClass]="{'disabled-form': isEditing }">
                                <div class="custom-control custom-radio mr-2">
                                    <input type="radio" id="sendNow1" name="sendNow" class="custom-control-input"
                                        [value]="true" formControlName="sendNow" checked />
                                    <label class="custom-control-label" for="sendNow1">{{ "Notification.SendNow" |
                                        translate }}</label>
                                </div>
                                <div class="custom-control custom-radio mr-2">
                                    <input type="radio" [value]="false" name="sendNow" class="custom-control-input"
                                        id="sendNow2" formControlName="sendNow" />
                                    <label class="custom-control-label" for="sendNow2">{{ "Notification.SendLater" |
                                        translate }}</label>
                                </div>
                            </div>

                            <div class="mt-1" [hidden]="formGroup.controls.sendNow?.value == true">
                                <div>
                                    <label for="">{{ "General.Date" | translate }}</label>
                                    <ng2-flatpickr [setDate]="startNotificationDateOptions.defaultDate"
                                        class="date-form-control" [config]="startNotificationDateOptions"
                                        name="startNotificationDate" formControlName="startNotificationDate"
                                        [ngClass]="{ 'disabled-form': isEditing }">
                                    </ng2-flatpickr>
                                    <div class="col-6 text-danger">
                                        <div
                                            *ngIf="isSubmit && (formGroup.get('startNotificationDate').value < getCurrentTime()) && (formGroup.controls.sendNow?.value == false)">
                                            {{ "Branch.Modal.PastTimeError" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="d-flex justify-content-between" *ngIf="!isEditing">
                        <button class="btn btn-secondary" (click)="onCancel()">{{ "Form.Cancel" | translate }}</button>
                        <button class="btn btn-primary" (click)="onSubmit()">{{ 'Form.Submit' | translate }}</button>
                    </div>

                </form>



            </div>
        </div>
    </div>
</div>