import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { ComponentsService } from 'app/main/components/components.service';
import { TimelineItem } from 'app/main/components/horizontal-timeline/horizontal-timeline.component';
import Customer from 'app/main/model/Customer';
import Item from 'app/main/model/Item';
import {
  Quotation,
  QuotationItem,
  TempRowQuotation,
} from 'app/main/model/Quotation';
import { ApiService } from 'app/main/service/api.service';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { DragulaService } from 'ng2-dragula';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { DocumentService } from '../../document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { QuatationService } from '../quotation.service';
import { CalculateFuncService } from 'app/main/service/calculate-func.service';
import { AddBillComponent } from 'app/main/components/document/template/add-bill/add-bill.component';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { log } from 'console';
import { DocumentStatus } from 'app/main/model/DocumentStatus';

@Component({
  selector: 'app-quotation-formpage',
  templateUrl: './quotation-formpage.component.html',
  styleUrls: ['./quotation-formpage.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuotationFormpageComponent implements OnInit, OnDestroy {
  @ViewChild('confirmModal') confirmModal: ModalComponent;
  @ViewChild('addBillComponent', { static: false })
  addBillComponent: AddBillComponent;
  @ViewChildren('formTable') formTable: QueryList<ElementRef<HTMLLIElement>>;
  private _unsubscribeAll: Subject<any> = new Subject();
  quotationData: any;

  copyObj: any;

  contentHeader: object;
  currentUser: User;
  apiPath: string;
  docID: string;
  docCode: string;
  isSubmit: boolean;
  quotationForm: FormGroup;
  quotationObj: Quotation;

  orderId: string;
  orderStatus: any = [];
  currentStep: number;

  detailSOPath: string;

  taxRate: number = 7.0;
  whtRate: number = 0;
  componentName: String;
  itemCounter: number = 0;
  rows: TempRowQuotation[] = [];
  draggingRow: any;
  currentDate: Date = new Date();
  nextWeekDate: Date = new Date();
  customerList: Customer[] = [];
  customerObj: Customer;
  customerAddressObj: CustomerAddress;
  itemList: Item[] = [];
  isLoading: boolean;
  isEditing: boolean;
  isVatExempted: boolean;
  isSetValueWhenInit: boolean = false;
  isExcludeTax: boolean;
  isSameWHTAllRow: boolean;
  isDiscountByPercent: boolean;
  whtList: any[] = [
    // { value: 0, label: 'ยังไม่ระบุ' },
    { value: 0, label: 'ไม่มี' },
    { value: 0.75, label: '0.75%' },
    { value: 1, label: '1%' },
    { value: 1.5, label: '1.5%' },
    { value: 2, label: '2%' },
    { value: 3, label: '3%' },
    { value: 5, label: '5%' },
    { value: 10, label: '10%' },
    { value: 15, label: '15%' },
    // { value: 0, label: 'กำหนดเอง' },
  ];

  @BlockUI() blockUI: NgBlockUI;

  timelineData: TimelineItem[];

  createDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: this.currentDate,
  };

  expiredDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: new Date(
      this.nextWeekDate.setDate(this.currentDate.getDate() + 7)
    ),
  };

  constructor(
    private _translateService: TranslateService,
    private _dragulaService: DragulaService,
    private _apiService: ApiService,
    private _documentService: DocumentService,
    private _documentApiService: DocumentApiService,
    private _modalService: NgbModal,

    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _componentsService: ComponentsService,
    private _contentTemplateService: QuatationService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _calculateService: CalculateFuncService,
    private _globalService: GlobalFuncService
  ) {
    this.componentName = 'Item';
    this._unsubscribeAll = new Subject();
    this.componentName = this._contentTemplateService.componentName;
    this.timelineData = this._documentService.timelineData;
    this.detailSOPath = this._documentService.saleOrder.DetailPathURL;

    this.initData();

    console.log('detailSOPath : ', this.detailSOPath);
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }
  SetLoadedState(): void {
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.isDiscountByPercent = false;
    var self = this;
    this._authenticationService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    self.contentHeader = {
      headerTitle: 'Document.Quotation',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Document',
            isLink: false,
            link: '/',
          },
          {
            name: 'Document.Quotation',
            isLink: true,
            link: '/document/quotation/view',
          },
          {
            name: 'FormName.QuotationForm',
            isLink: false,
          },
        ],
      },
    };
  }
  childFunction() {
    console.log('Child function called from the parent component');
  }

  ngOnDestroy() {
    console.log('hello from destroy');
    this._dragulaService.destroy('handle-list');
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  initData() {
    this.SetLoadingState();
    this.apiPath = this._documentService.quotation.ApiPath;

    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
      this.copyObj = this._route.snapshot.paramMap.get('copyObj');
      if (this.copyObj) {
        this.isEditing = true;
        this.getLastCode();
      } else {
        this.isEditing = this.docID ? true : false;
      }
    });

    this.quotationObj = new Quotation();
    this.quotationObj.transactionStatusId = 0;
    this.quotationObj.salesWHT = 0.0;
    this.currentStep = 0;

    if (this.isEditing) {
      if (this.copyObj) {
       
        this.getLastCode().then(() => {
          setTimeout(() => {
            let tempObj = JSON.parse(this.copyObj);
            this.quotationObj = tempObj;
            this.isExcludeTax = tempObj.isExcludeTax;
            this.quotationObj.quotationCode = this.docCode
            this.quotationObj.transactionStatusId = DocumentStatus.Draft
          }, 500);
        })
        // this.quotationObj.quotationCode = this.docCode
      } else {
        console.log('is editing');
        this.getQuotationData(this.apiPath, this.docID);
      }
    } else {
      this.quotationData = {};
      // this.getLastCode();
      this.customerObj = new Customer();
      
      this.SetLoadedState();
    }

    console.log(this.quotationObj);
  }

  get f() {
    return this.quotationForm.controls;
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData[0];
        });
    });
  }
  
  getLastCode(): Promise<void> {
    // console.log('getting last code');
    
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocRunningNumber('Quotation')
        .subscribe((res) => {
          let docCode = document.getElementById('docCode') as HTMLFormElement;
          self.docCode = res.data.resultData[0];
          // console.log('loaded last code');
          docCode.value = self.docCode;
          self.quotationObj.quotationCode = res.data.resultData[0]
          resolve()
        });
    });
  }

  getQuotationData(ApiPath, DocID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._documentApiService.GetDocData(ApiPath, DocID).subscribe(
        (res) => {
          const quotationObj = res.data.resultData[0];
          this.quotationObj = quotationObj;
          console.log(this.quotationObj);
          this.isExcludeTax = quotationObj.isExcludeTax;

          // this.orderId = quotationObj.orderId;
          // this.getOrderStatus(this.orderId).subscribe((orderStatusRes) => {
          //   this.orderStatus = orderStatusRes.data.resultData;
          //   console.log('Order Status: ', this.orderStatus[0]);
          //   this.currentStep = this._globalService.GetCurrentStep(
          //     this.orderStatus
          //   );
          //   console.log('Current Step: ', this.currentStep);
          // });
          // resolve(quotationObj);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  // getOrderStatus(orderId: string): Observable<any> {
  //   return this._apiService.GetDataById('Order/State', orderId);
  // }

  onDataChange(event: any) {
    // Handle the updated data from the add-bill component
    console.log('Event Changed', event);
    // Other logic
  }
  handleAddBillSubmit(data: any): void {
    // Handle the submitted data from the AddBillComponent
    console.log('Submitted data from AddBillComponent:', data);
    // Perform any necessary actions with the submitted data
    if (this.copyObj) {
      this.Submit(data.isDraft, data.quotationForm, false);
    } else {
      this.Submit(data.isDraft, data.quotationForm, data.isEditing, data.docID);
    }
    
  }

  Submit(
    isDraft?: boolean,
    quotationForm?: object,
    isEditing?: boolean,
    docID?: string
  ) {
    this.blockUI.start();

    var self = this;
    if (isEditing) {
      this._documentApiService
        .UpdateDocData(this.apiPath, quotationForm, docID)
        .subscribe(
          (res) => {
            console.log('res', res);
            let qoCode = res.data.resultData[0].code;

            if (!isDraft) {
              this._documentApiService
                .ApproveDocData(this.apiPath, docID)
                .subscribe((res) => {
                  console.log('res', res);
                  if (res) {
                    let soId = res.data.resultData[0].id;

                    const titleLinkDocButton =
                      this._translateService.instant('Alert.GoToSO');

                    const textCode = this._translateService.instant(
                      'Alert.CodeQO',
                      {
                        docCode: qoCode,
                      }
                    );

                    self._componentsService.SuccessSwal(
                      undefined,
                      undefined,
                      true,
                      titleLinkDocButton,
                      this.detailSOPath,
                      textCode,
                      soId
                    );
                    this._router.navigate([
                      `/document/quotation/detail/${docID}`,
                    ]);
                  }
                });
            } else {
              self._componentsService.SuccessSwal();
              self._router.navigate([`/document/quotation/view`]);
            }
            this.blockUI.stop();
          },
          (err) => {
            this.blockUI.stop();
            self._componentsService.ErrorSwal();
            self._router.navigate([`/document/quotation/view`]);
          }
        );
    } else {
      this._documentApiService
        .AddDocData(this.apiPath, quotationForm)
        .subscribe(
          (res) => {
            console.log('res', res); /* return QO */
            let qoCode = res.data.resultData[0].code;
            let qoGuid = res.data.resultData[0].id;

            if (!isDraft) {
              console.log('is approve add');
              this._documentApiService
                .ApproveDocData(this.apiPath, res.data.resultData[0].id)
                .subscribe((res) => {
                  console.log('res', res); /* return SO */
                  let soId = res.data.resultData[0].id;

                  if (res) {
                    const titleLinkDocButton =
                      this._translateService.instant('Alert.GoToSO');

                    const textCode = this._translateService.instant(
                      'Alert.CodeQO',
                      {
                        docCode: qoCode,
                      }
                    );

                    self._componentsService.SuccessSwal(
                      undefined,
                      undefined,
                      true,
                      titleLinkDocButton,
                      this.detailSOPath,
                      textCode,
                      soId
                    );
                    this._router.navigate([
                      `/document/quotation/detail/${qoGuid}`,
                    ]);
                  }
                });
            } else {
              self._componentsService.SuccessSwal();
              self._router.navigate([`/document/quotation/view`]);
            }
          },
          (err) => {
            self._componentsService.ErrorSwal();
            self._router.navigate([`/document/quotation/view`]);
          }
        );
      this.blockUI.stop();
    }
  }
}
