export class User {
  id: string;
  username: string;
  userCode: string;
  roleSetId: string;
  roleSetName: string;
  coverMediaFile: any[];
  mediaFiles: any[];
  email: string;
  firstName: string;
  lastName: string;
  isCompanyUser: boolean;
  socialMediaLink: string;
  namePrefixId: string;
  genderId: number;
  personalId: string;
  // organization: string;
  // branch: string;
  // department: string;
  // position: string;
  phone: string;
  businessTypeId: number;
  // bankId: any;
  // cartId: any;
  lastLogin: string;
  isActive: boolean;
  isDelete: boolean;
  isVerify: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  deleteBy: any;
  deleteDate: any;
}
