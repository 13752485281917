import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/main/service/api.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import Content from 'app/main/model/Content';
import { CustomerService } from '../customer.service';
import { takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-content-formpage',
  templateUrl: './customer-form-page.component.html',
  styleUrls: ['./customer-form-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerFormPageComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  private datasubscription: Subscription;
  componentName: string;

  apiPath: string;
  pathUrl: string;
  contentHeader: object;

  itemGuid: string;
  itemObj: Content;

  isEditing: boolean;
  isLoading: boolean;
  modeText: string;

  @ViewChild('confirmModal') confirmModal: ModalComponent;

  constructor(
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _customerService: CustomerService,
    private _translateService: TranslateService,
    private _location: Location
  ) {
    this.SetLoadingState();
    this.initConfig();
    this.initForm(this.isEditing);
    console.log(this._customerService.componentName);
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  initConfig(): void {
    this._unsubscribeAll = new Subject();
    this.componentName = this._customerService.componentName;
    this.apiPath = this._customerService.apiPath;
    this.pathUrl = this._customerService.pathUrl;

    this._route.paramMap.subscribe((val) => {
      let id = this._route.snapshot.paramMap.get('id');
      this.isEditing = id ? true : false;
    });

    // this._apiService.SetHttpHeaders(this._authenticationService.tokenValue);

    this.modeText = this.isEditing
      ? this._translateService.instant('General.Edit')
      : this._translateService.instant('General.Create');
  }

  backClicked() {
    this._location.back();
  }

  initForm(isEditing: boolean): void {
    var self = this;
    if (isEditing) {
      this._route.paramMap.subscribe((val) => {
        self.itemGuid = this._route.snapshot.paramMap.get('id');
        self.getitemData(this.itemGuid);
        self.SetLoadedState();
      });
    } else {
      this.itemObj = new Content();
      this.SetLoadedState();
    }
  }

  getitemData(guid: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this.datasubscription = this._apiService
        .GetDataById(this.apiPath, guid)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          self.itemObj = res.data.resultData[0];
          self.isLoading = false;
        }, rejects);
    });
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: this.componentName,
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this.componentName,
            isLink: true,
            link: this.pathUrl,
          },
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.datasubscription) {
      this.datasubscription.unsubscribe();
    }
  }
}
