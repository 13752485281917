import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'app/main/service/api.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-payment-evidence',
  templateUrl: './payment-evidence.component.html',
  styleUrls: ['./payment-evidence.component.scss']
})
export class PaymentEvidenceComponent implements OnInit {

  paymentPic!: any;
  isLoading: boolean;
  imgSrc: string;
  currentLang: string;
  bankInfo: any;

  accountNumber: string;
  bankName: string;
  accountName: string;

  @Input() titleDoc: string;
  @Input() pppp!: string;
  @Input() detailObj!: any;

  isQuantityDecimal: boolean = environment.stock.isQuantityDecimal;


  constructor(
    private _translateService: TranslateService,
    private _apiService: ApiService,
    private _globalFuncService: GlobalFuncService,
  ) { }

  ngOnInit(): void {

    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });
  }

  ngAfterViewInit() {
    this.getBankInfo(this.detailObj.attachPaymentEvidenceBankAccountId);
  }

  SetLoadingState() {
    this.isLoading = true;
  }
  SetLoadedState() {
    this.isLoading = false;
  }

  getPic(id: string) {
    return `${this._apiService.API_URL}/file/image/${id}?width=200&height=200&KeepRatio=true&Format=webp`;

    // this._fileApiService.GetFile(id).subscribe((data) => {
    //   this.paymentPic = data;
    // })

    // return this._fileApiService.GetFile(id)
  }

  getBankInfo(id: string) {
    this._apiService.GetDataById('BankAccount', id).subscribe((res) => {
      this.bankInfo = res.data.resultData[0];
      console.log(this.bankInfo);
      // this.accountNumber = this.bankInfo.accountNumber;
      this.accountNumber = this.formatNumberToBankNumber(this.bankInfo.accountNumber);
      this.bankName = this.bankInfo.bankName;
      this.accountName = this.bankInfo.accountName;
      
    });
  }
  bankImages = {
    'ธนาคารแห่งประเทศไทย': 'Bank_of_Thailand.png',
    'ธนาคารกรุงเทพ': 'BKK-2.svg',
    'ธนาคารกสิกรไทย': 'Kbank.svg',
    'ธนาคารกรุงไทย': 'krungthai-4.svg',
    'ธนาคารทหารไทยธนชาต': 'ttb-5.png',
    'ธนาคารไทยพาณิชย์': 'SCB-6.svg',
    'ธนาคารซิตี้แบงก์': 'citi-7.png',
    'ธนาคารสแตนดาร์ดชาร์เตอร์ด': 'Standard-8.png',
    'ธนาคารซีไอเอ็มบีไทย': 'cimb-9.png',
    'ธนาคารยูโอบี':'uob-10.png',
    'ธนาคารกรุงศรีอยุธยา':'krungsri-11.png',
    'ธนาคารเมกะ สากลพาณิชย์':'MegaInter-12.png',
    'ธนาคารออมสิน':'oomsin-13.png',
    'ธนาคารอาคารสงเคราะห์':'ghbank-14.png',
    'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร':'BAAC-15.png',
    'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย':'EXIM-16.png',
    'ธนาคารแห่งประเทศจีน (ไทย)':'Bankchina-17.png',
    'ธนาคารอิสลามแห่งประเทศไทย':'islambank-18.png',
    'ธนาคารทิสโก้':'Tisco-19.png',
    'ธนาคารเกียรตินาคินภัทร':'kiatnakin-20.png',
    'ธนาคารไอซีบีซี (ไทย)':'icbc-21.png',
    'ธนาคารไทยเครดิตเพื่อรายย่อย':'thaicredit-22.png',
    'ธนาคารแลนด์ แอนด์ เฮ้าส์':'lhbank-23.png',
    'ธนาคารเอเอ็นแซด (ไทย)':'anz-24.png',
    'ธนาคารซูมิโตโม มิตซุย ทรัสต์ (ไทย)':'smbc-25.png',
    'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย':'sme-26.jpg'
  };

  getBankImg(bankName: string) {
    let imageName = this.bankImages[bankName];

    if (!imageName) {
      imageName = '';
    }

    return `../../../../assets/images/banks/${imageName}`;
  }

  formatNumberToMoney(value: number): string {
    // console.log(value);
    return this._globalFuncService.FormatToMoney(
      value,
      null,
      !this.isQuantityDecimal
    );
  }

  formatNumberToBankNumber(value: string): string {
    return `${value.slice(0, 3)}-${value.slice(3, 4)}-${value.slice(4, 9)}-${value.slice(9)}`;
  }
}
