import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';

@Component({
  selector: 'app-horizontal-timeline',
  templateUrl: './horizontal-timeline.component.html',
  styleUrls: ['./horizontal-timeline.component.scss'],
})
export class HorizontalTimelineComponent implements OnInit, OnDestroy {
  private _timelineItems: TimelineItem[] = [];
  @Input()
  set timelineItems(value: TimelineItem[]) {
    this._timelineItems = value.slice(0, 6);
  }
  get timelineItems(): TimelineItem[] {
    return this._timelineItems;
  }

  @Input() currentStep: TimelineItem;
  @Input() currentStepNumber: number;
  @Input() lastItems: any;
  @Output() activeStep = new EventEmitter<TimelineItem>();

  setActiveStep(step: TimelineItem) {
    const currentIndex = this.timelineItems.indexOf(step);
    if (currentIndex >= this.timelineItems.indexOf(this.currentStep)) {
      this.currentStep = step;
      this.activeStep.emit(step);
    }
  }

  isDisabledStatus(index: number): boolean {
    return this.currentStepNumber === 5
      ? index > this.currentStepNumber
      : index < this.currentStepNumber;
  }

  isDisabledLine(index: number): boolean {
    return this.currentStepNumber === 5
      ? index < this.currentStepNumber
      : index > this.currentStepNumber - 1;
  }

  constructor() {}

  ngOnInit(): void {
    console.log(this.currentStep)
  }
  ngOnDestroy(): void {
    this.currentStep = null;
  }
}

export interface TimelineItem {
  label: string;
  descript: string;
  stepCount: number;
}
