<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section>
      <app-horizontal-timeline
        [timelineItems]="timelineData"
        [currentStep]="docStatus == 8 ? 5 : docStatus == 5 ? 4 : undefined"
        [currentStepNumber]="
          docStatus == 8 ? 5 : docStatus == 5 ? 4 : undefined
        "
        [lastItems]="{
          label: 'Void',
          descript: 'ยกเลิก',
          stepCount: 5
        }"
      ></app-horizontal-timeline>
    </section>

    <section>
      <div class="card">
        <!-- Nav tabs -->
        <div
          class="card-header"
          style="border-bottom: 1px dashed rgba(161, 161, 161, 0.3)"
        >
          <span></span>
          <!-- <h2>{{ "Document.DebitNote" | translate }}</h2> -->
          <ul
            ngbNav
            #navFilled="ngbNav"
            [activeId]="activeTab"
            class="nav nav-tabs mb-0 bg-white border"
            [destroyOnHide]="false"
          >
            <li [ngbNavItem]="'detail'" class="nav-item">
              <a ngbNavLink routerLink="." [fragment]="'detail'">{{
                "General.DocumentInfo" | translate
              }}</a>
              <ng-template ngbNavContent>
                <app-content-document
                  [customerAddressObj]="customerAddressObj"
                  [titleDoc]="'Document.DebitNote' | translate"
                  [apiPath]="apiPath"
                  [typeDocName]="typeDocName"
                  [detailObj]="debitNoteObj"
                  [invoiceDetailObj]="invoiceObj"
                  [docId]="docID"
                  [docStatus]="docStatus"
                  [editPathUrl]="editPathUrl"
                  [detailPathUrl]="detailPathUrl"
                  [refDocID]="'invoiceId'"
                  [refDetailUrl]="refDetailUrl"
                  [pageName]="debitnotedetail"
                  [isDisabledEditBtn]="true"
                  (refreshData)="refreshData($event)"
                >
                </app-content-document>
              </ng-template>
            </li>
            <li [ngbNavItem]="'paymentInfo'">
              <a ngbNavLink routerLink="." [fragment]="'paymentInfo'">{{
                "General.PaymentInfo" | translate
              }}</a>
              <ng-template ngbNavContent>
                <app-payment-detail
                  [titleDoc]="'Document.Invoice' | translate"
                  [docId]="docID"
                  [titleDoc]="'Document.DebitNote' | translate"
                  [apiPath]="apiPath"
                  [docStatus]="docStatus"
                  [detailObj]="debitNoteObj"
                  [typeDocName]="typeDocName"
                  [refDocID]="'invoiceId'"
                  [refDetailUrl]="refDetailUrl"
                  [isDebitNote]="true"
                  (submitData)="handlePaymentDetailSubmit($event)"
                  (refreshData)="handlePaymentDetailRefresh($event)"
                >
                </app-payment-detail>
              </ng-template>
            </li>

            <li [ngbNavItem]="'historyInfo'">
              <a ngbNavLink routerLink="." [fragment]="'historyInfo'">{{
                "General.HistoryInfo" | translate
              }}</a>
              <ng-template ngbNavContent>
                <app-action-history
                  [documentId]="docID"
                  [title]="'Document.DebitNote'"
                  [apiPath]="apiPath"
                  [detailObj]="debitNoteObj"
                  [typeDocName]="typeDocName"
                  [refDetailUrl]="refDetailUrl"
                  [refDocID]="'invoiceId'"
                >
                </app-action-history>
              </ng-template>
            </li>

            <li [ngbNavItem]="'relatedDoc'">
              <a ngbNavLink routerLink="." [fragment]="'relatedDoc'">{{
                "General.RelatedDocument" | translate
              }}</a>
              <ng-template ngbNavContent>
                <app-content-child-document
                  [orderStateData]="orderStatus[0]"
                  (refreshData)="refreshData($event)"
                  [currentDocCode]="debitNoteObj[typeDocName]"
                >
                </app-content-child-document>
              </ng-template>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div [ngbNavOutlet]="navFilled"></div>
        </div>
      </div>
    </section>
  </div>
</div>
