<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <!-- content-header component -->
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
  
      <div class="card">
        <div class="card-body">
  
          <app-user-form [callbackUrl]="callbackUrl" [apiPath]="apiPath"></app-user-form>
  
        </div>
      </div>
    </div>
  </div>
  