<div class="content-wrapper container-xxl p-0">
  <app-content-header [contentHeader]="contentHeader"></app-content-header>

  <div class="content-body">
    <div class="card">
      <div class="card-body">
        <div class="">
          <form [formGroup]="exchangeForm">
            <h4 class="mb-2">{{ "BonusPlan.EditSV" | translate }}</h4>
            <div class="form-group row justify-content-center">
              <!-- <div class="col-2">
                <div class="">
                  <label for="first-name-vertical">THB</label>
                  <h3 class=""> 1 THB :</h3>
                </div>
              </div> -->

              <div class="col-4">
                <!-- <label for="first-name-vertical"></label> -->
                <div class="d-flex justify-content-between" style="align-items: center;">
                  <div class="">
                    <h3 class="mb-0">1 SV</h3>
                  </div>
                  <div class="">
                    <h3 class="mb-0">:</h3>
                  </div>

                  <div class="d-flex col-8 justify-content-between" style="align-items: center;">
                    <div class="">
                      <input type="number" [min]="0" class="form-control d-flex text-right" formControlName="SVToBaht"
                        disabled [ngClass]="{
                            'is-invalid error': isPriceInvalid
                          }" />
                    </div>
                    <div class="">
                      <h3 class="mb-0 ml-2">THB</h3>
                    </div>
                  </div>
                </div>
                <p *ngIf="isPriceInvalid" class="text-danger text-center mt-1 mb-0">*** ไม่สามารถใส่เลขจำนวนลบได้ ***
                </p>
              </div>
            </div>
          </form>
          <div class="row justify-content-center">

            <div class="d-flex justify-content-center m-1" *ngIf="isEditing == false">
              <a (click)="onEdit()" class="btn btn-primary">{{ "General.Edit" | translate }}</a>
            </div>

            <div class="d-flex justify-content-center m-1" *ngIf="isEditing == true">
              <a (click)="onCancel()" class="btn btn-secondary">{{ "Form.Cancel" | translate }}</a>
            </div>

            <div class="d-flex justify-content-center m-1" *ngIf="isEditing == true">
              <a (click)="openConfirmModal()" class="btn btn-primary">{{ "Form.Submit" | translate }}</a>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">{{ "BonusPlan.SVHistory" | translate }}</div>
      <div class="card-body" *ngIf="logDataList.length >= 1">
        <table class="table" datatable [dtOptions]="dtOptions">
          <thead>
            <tr>
              <th class="text-center" width="5%">#</th>

              <th class="text-center" width="30%">{{ 'General.Detail' | translate }}</th>
              <th class="text-center" width="15%">{{ "BonusPlan.UpdateBy" | translate }}</th>
              <th class="text-center" width="15%">{{ "BonusPlan.UpdateDate" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="w-100" *ngIf="isLoading">
              <td class="text-center" *ngFor="let num of [].constructor(6)">
                <div class="skeleton-loading"></div>
              </td>
            </tr>

            <tr *ngIf="logDataList.length < 1 && !isLoading">
              <td colspan="100%" class="text-center">
                <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
              </td>
            </tr>

            <tr *ngFor="let log of logDataList; index as ind">
              <td class="text-center">{{ ind + 1 }}</td>
              <td class="text-center">{{ log.changeFrom | number }} THB <i data-feather="arrow-right" class="mx-1"></i>
                {{
                log.changeTo | number }} THB</td>
              <td class="text-center">{{ log.createBy ? log.createBy : '-'}}</td>
              <td class="text-center">
                <div *ngIf="currentLang != 'th'">
                  {{ log.createDate | date : "dd MMM YYYY HH:mm" }}
                </div>
                <div *ngIf="currentLang != 'en'">
                  {{ log.createDate | thaidate : "DD MMM YYYY HH:mm" }} น.
                </div>

              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>