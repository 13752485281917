import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { CoreConfig } from '@core/types';
import { AccountApiService } from 'app/main/service/account-api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComponentsService } from 'app/main/components/components.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VerifyComponent implements OnInit {
  public coreConfig: CoreConfig;

  email: string;
  tokenVerify: string;

  haveVerifyData: boolean;

  isVerifyComplete: boolean;
  isSubmitted: boolean;
  isLoading: boolean;
  isSendedVerifyEmail: boolean;

  emailForm: FormGroup;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _coreConfigService: CoreConfigService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _accountApiService: AccountApiService,
    private _formBuilder: FormBuilder,
    private _componentsService: ComponentsService,
    private _translateService: TranslateService,
  ) {
    this.initForm();

    _route.queryParams.subscribe((val) => {
      this.email = val.email;
      this.tokenVerify = val.token_verify;

      if (this.email && this.tokenVerify) {
        this.SetLoadingState();
        this.haveVerifyData = true;

        this.verifyAccount();
      }
    });

    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        animation: 'fadeIn',
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }

  ngOnInit(): void {
    var self = this;
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  get f() {
    return this.emailForm.controls;
  }

  initForm(): void {
    this.emailForm = this._formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
    });
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }
  SetLoadedState(): void {
    this.isLoading = false;
  }

  sentVerifyEmail() {
    var self = this;

    this.isSubmitted = true;
    this.SetLoadingState();

    if (this.emailForm.invalid) {
      this.SetLoadedState();
      return;
    }

    this._accountApiService.SentVerifyEmail(this.emailForm.value).subscribe(
      (res) => {
        let title = this._translateService.instant(
          'Alert.VerifyEmailSendSuccessfully'
        );
        self._componentsService.SuccessSwal(title);
        self.isSendedVerifyEmail = true;
        self.SetLoadedState();
      },
      (err) => {
        let title = this._translateService.instant(
          'Alert.EmailNotExist'
        );
        let text = this._translateService.instant(
          'Alert.PleaseTryAgain'
        );
        console.log(err);
        self._componentsService.ErrorSwal(
          title,
          text
        );
        self.SetLoadedState();
      }
    );
  }

  verifyAccount(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._accountApiService
        .SentVerifyTokenAccount({
          tokenVerify: self.tokenVerify,
        })
        .subscribe((res) => {
          if (res.success) {
            self.isVerifyComplete = true;
            self.SetLoadedState();

            setTimeout(() => {
              self._router.navigate(['/login']).then(() => {});
            }, 5000);
          }
        }, err => {
          self._router.navigate(['/login']).then(() => {});
        });
    });
  }
}
