<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <!-- Left Text-->
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
      <div
        class="w-100 d-lg-flex align-items-center justify-content-center px-5"
      >
        <img
          class="img-fluid"
          style="width: 75%"
          [src]="coreConfig.app.appLogoImage"
          alt="Login V2"
        />
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Login-->
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title font-weight-bold mb-1">
          {{ coreConfig.app.appTitle }}
        </h2>
        <p class="card-text mb-2">Please sign-in to your account</p>

        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
          <div class="alert-body">
            <p>{{ error }}</p>
          </div>
        </ngb-alert>

        <form
          class="auth-login-form mt-2"
          [formGroup]="loginForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-group">
            <label class="form-label" for="login-email"
              >Username or Email</label
            >
            <input
              type="text"
              formControlName="userName"
              class="form-control"
              [ngClass]="{ 'is-invalid': isSubmitted && f.userName.errors }"
              placeholder="username"
              aria-describedby="login-email"
              autofocus=""
              tabindex="1"
            />
            <div
              *ngIf="isSubmitted && f.userName.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.userName.errors.required">Username is required</div>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="login-password">Password</label
              ><a routerLink="/forget-password"
                ><small>Forgot Password?</small></a
              >
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="passwordTextType ? 'text' : 'password'"
                formControlName="password"
                class="form-control form-control-merge"
                [ngClass]="{
                  'is-invalid error': isSubmitted && f.password.errors
                }"
                placeholder="············"
                aria-describedby="login-password"
                tabindex="2"
              />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"
                  ><i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                    (click)="togglePasswordTextType()"
                  ></i
                ></span>
              </div>
            </div>
            <div
              *ngIf="isSubmitted && f.password.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': isSubmitted && f.password.errors }"
            >
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
          </div>
          <!-- <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input"
                id="remember-me"
                type="checkbox"
                tabindex="3"
              />
              <label class="custom-control-label" for="remember-me">
                Remember Me</label
              >
            </div>
          </div> -->
          <button
            [disabled]="isLoading"
            class="btn btn-primary btn-block"
            tabindex="4"
            rippleEffect
          >
            <span
              *ngIf="isLoading"
              class="spinner-border spinner-border-sm mr-1"
            ></span
            >Sign in
          </button>
        </form>

        <!-- <p class="text-center mt-2">
          <span>New on our platform?</span>
          <a routerLink="/sign-up">
            <span>&nbsp;Create an account</span>
          </a>
        </p> -->


        <!-- <div class="divider my-2">
          <div class="divider-text">or</div>
        </div>
        <div class="auth-footer-btn d-flex justify-content-center">
          <a class="btn btn-facebook" href="javascript:void(0)"
            ><i data-feather="facebook"></i></a
          ><a class="btn btn-twitter white" href="javascript:void(0)"
            ><i data-feather="twitter"></i></a
          ><a class="btn btn-google" href="javascript:void(0)"
            ><i data-feather="mail"></i></a
          ><a class="btn btn-github" href="javascript:void(0)"
            ><i data-feather="github"></i
          ></a>
        </div> -->
      </div>
    </div>
    <!-- /Login-->
  </div>
</div>
