import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Branch from 'app/main/model/Branch';
import { ApiService } from 'app/main/service/api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-select-branch-modal',
  templateUrl: './select-branch-modal.component.html',
  styleUrls: ['./select-branch-modal.component.scss'],
})
export class SelectBranchModalComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  searchData: any = {
    isDelete: false,
    isActive: true,
  };
  branchList: Branch[] = [];
  selectedBranchId: string;

  @Input() title: string;
  @Output() callBackFunc: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private _apiService: ApiService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.getBranchList();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  closeModal(): void {}

  callBack() {}

  onBranchChange(event) {
    console.log(event);
    this.selectedBranchId = event;
  }

  getBranchList() {
    this._apiService
      .GetAllData('Branch?SortPath=createDate', this.searchData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.branchList = res.data.resultData;
        console.log(this.branchList);
      });
  }

  confirmModal() {
    this.callBackFunc.emit(this.selectedBranchId);
    this.activeModal.close();
  }
}
