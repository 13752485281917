import { Injectable } from '@angular/core';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';

@Injectable({
  providedIn: 'root'
})
export class HighlightTechService {
  // public config: ICMSConfig = {
  //   apiPath: '',  //?ชื่อ api
  //   pathUrl: '/cms/repove-tools/technology/hydroethosome/highlight-tech',  //?path
  //   tableName: 'Content',
  //   model: new Object(),
  //   isList: false,
  //   componentName: 'BreadCrumbs.CMS.RepoveTool.HydroethosomeHighlightTech', 
  //   formConfig: {
  //     // hydroethosomeFunctionImg Image
  //     // hydroethosomeFunctionName Text isMultipleLang: true,
  //     // hydroethosomeFunctionDes Text isMultipleLang: true,
  //     // hydroethosomeFunctionSubDes Text isMultipleLang: true,
  //     // hydroSubstancesCardIcon1 Image
  //     // hydroSubstancesCardDetail1 Text isMultipleLang: true,
  //     // hydroSubstancesCardIcon2 Image
  //     // hydroSubstancesCardDetail2 Text isMultipleLang: true,
  //     // hydroSubstancesCardIcon3 Image
  //     // hydroSubstancesCardDetail3 Text isMultipleLang: true,
  //     hydroethosomeFunctionImg:{
  //       type: FormType.SingleImage,
  //       label: 'Extract Image',
  //       description: 'Resolution 350px*350px',
  //       labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroethosomeFunctionImg',
  //       descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroethosomeFunctionImgDescription',
  //     },
  //     hydroethosomeFunctionName:{
  //       type: FormType.Text,
  //       isMultipleLang: true,
  //       label: 'Extract Name',
  //       labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroethosomeFunctionName',
  //     },
  //     hydroethosomeFunctionDetail:{
  //       type: FormType.Text,
  //       isMultipleLang: true,
  //       label: 'Extract Detail',
  //       labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroethosomeFunctionDetail',
  //     },
  //     hydroethosomeFunctionSubDetail:{
  //       type: FormType.Text,
  //       isMultipleLang: true,
  //       label: 'Extract Sub Detail',
  //       description: 'Secondary extract details below Extract details',
  //       labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroethosomeFunctionSubDetail',
  //       descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroethosomeFunctionSubDetailDes',
  //     },
  //     hydroFunctionCardIcon1:{
  //       type: FormType.SingleImage,
  //       label: 'Substances Card Icon 1',
  //       description: 'Resolution 40px*40px',
  //       labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardIcon1',
  //       descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardDescription',
  //     },
  //     hydroFunctionCardDetail1:{
  //       type: FormType.Text,
  //       isMultipleLang: true,
  //       label: 'Substances Card Detail 1',
  //       description: 'Details of substances below icon',
  //       labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardDetail1',
  //       descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardDetailDescription',
  //     },
  //     hydroFunctionCardIcon2:{
  //       type: FormType.SingleImage,
  //       label: 'Substances Card Icon 2',
  //       description: 'Resolution 40px*40px',
  //       labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardIcon2',
  //       descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardDescription',
  //     },
  //     hydroFunctionCardDetail2:{
  //       type: FormType.Text,
  //       isMultipleLang: true,
  //       label: 'Substances Card Detail 2',
  //       description: 'Details of substances below icon',
  //       labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardDetail2',
  //       descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardDetailDescription',
  //     },
  //     hydroFunctionCardIcon3:{
  //       type: FormType.SingleImage,
  //       label: 'Substances Card Icon 3',
  //       description: 'Resolution 40px*40px',
  //       labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardIcon3',
  //       descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardDescription',
  //     },
  //     hydroFunctionCardDetail3:{
  //       type: FormType.Text,
  //       isMultipleLang: true,
  //       label: 'Substances Card Detail 3',
  //       description: 'Details of substances below icon',
  //       labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardDetail3',
  //       descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardDetailDescription',
  //     },
  //     // hydroFunctionCardIcon4:{
  //     //   type: FormType.SingleImage,
  //     //   label: 'Substances Card Icon 4',
  //     //   description: 'Resolution 40px*40px',
  //     //   labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardIcon4',
  //     //   descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardDescription',
  //     // },
  //     // hydroFunctionCardDetail4:{
  //     //   type: FormType.Text,
  //     //   isMultipleLang: true,
  //     //   label: 'Substances Card Detail 4',
  //     //   description: 'Details of substances below icon',
  //     //   labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardDetail4',
  //     //   descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardDetailDescription',
  //     // },
  //     // hydroFunctionCardIcon5:{
  //     //   type: FormType.SingleImage,
  //     //   label: 'Substances Card Icon 5',
  //     //   description: 'Resolution 40px*40px',
  //     //   labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardIcon5',
  //     //   descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardDescription',
  //     // },
  //     // hydroFunctionCardDetail5:{
  //     //   type: FormType.Text,
  //     //   isMultipleLang: true,
  //     //   label: 'Substances Card Detail 5',
  //     //   description: 'Details of substances below icon',
  //     //   labelTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardDetail5',
  //     //   descriptionTranslate: 'Informative.RepoveToolsTechnology.Hydroethosome.hydroFunctionCardDetailDescription',
  //     // },
  //   }
  // }

  componentName: string = 'Highlight Tech';
  apiPath: string = 'HighlightTech';
  pathUrl: string = '/cms/repove-tools/technology/hydroethosome/highlight-tech';

  constructor() { }
}
