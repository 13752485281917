import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ProductListService } from '../product-list.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-product-list-create',
  templateUrl: './product-list-create.component.html',
  styleUrls: ['./product-list-create.component.scss']
})
export class ProductListCreateComponent implements OnInit {

  private _unsubscribeAll: Subject<any> = new Subject();

  componentName: string;
  apiPath: string;
  pathUrl: string;
  contentHeader: object;

  isLoading: boolean;

  selectedLang: string = environment.langDefault;
  langList: string[] = environment.langContent;


  constructor(
    private _route: ActivatedRoute,
    private productListService: ProductListService,
    private _translateService: TranslateService
  ) {
    this.SetLoadingState();
    this._unsubscribeAll = new Subject();
    this.pathUrl = this.productListService.pathUrl;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'ProductForm.Product',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'ProductForm.Product',
            isLink: true,
            link: '/cms/repove-tools/product-list',
          },
          {
            name: 'General.Create',
            isLink: false,
          },
        ],
      },
    };
    this.SetLoadedState();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  selectedLanguage(lang: string) {
    this.selectedLang = lang;
  }

}
