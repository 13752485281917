<div class="modal-header">
  <h5 class="modal-title" id="exampleModalCenterTitle">
    {{ "BookingCalendar.Modal.Header" | translate }}
  </h5>
  <button type="button" class="close" (click)="closeModal()" aria-label="Close">
    <span aria-hidden="true">
      <i data-feather="x" size="22"></i>
    </span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <form [formGroup]="formGroup" class="booking-modal">
    <div class="row">
      <div class="col-12 d-flex justify-content-end mb-1" *ngIf="isEditData">
        <button type="button" class="btn btn-primary"
          *ngIf="formGroup.controls.stateEnum?.value != bookingEnum.Reject" (click)="onEditButtonClick()"
          rippleEffect>
          {{ "BookingCalendar.Modal.UpdateEvent" | translate }}
        </button>
      </div>
      <div class="col-12 d-flex justify-content-end">
        <div class="text-danger" *ngIf="isPast">
          *{{"BookingCalendar.Modal.EditPastEvent" | translate}}
        </div>
      </div>

      <div class="col-12" style="margin-bottom: 0.5rem">
        <label class="form-label"> {{ "BookingCalendar.Modal.EventName" | translate }} </label>
        <span class="text-danger">&nbsp;*</span>
        <input type="text" class="form-control" maxlength="50" formControlName="topic" required [ngClass]="{
          'is-invalid error':
            (isSubmit && f.topic?.invalid) ||
            (f.topic?.invalid &&
              (f.topic?.dirty || f.topic?.touched)) ||
            (f.topic?.value == '' &&
              (f.topic?.dirty || f.topic?.touched))
          }" />
        <div class="invalid-feedback">
          <div *ngIf="
                isSubmit ||
                (f.topic.invalid && (f.topic.dirty || f.topic.touched))
              ">
            {{"BookingCalendar.Modal.Error.EventName"| translate}}
          </div>
        </div>
      </div>

      <div class="col-12" style="margin-bottom: 0.5rem">
        <label class="form-label"> {{ "BookingCalendar.Modal.Customer" | translate }} </label>

        <span class="text-danger">&nbsp;*</span>
        <div class="skeleton-select" *ngIf="isCustomerListLoading">
          <div class="spinner-border spinner-border-sm mr-50" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <ng-select *ngIf="!isCustomerListLoading" formControlName="customerId" (change)="onCustomerChange($event)"
          [ngClass]="{'is-invalid error':
            (isSubmit && f.customerId?.invalid) ||
            (f.customerId?.invalid &&
              (f.customerId?.dirty || f.customerId?.touched))
        }">
          <ng-option *ngFor="let customer of customerList" [value]="customer.id" class="mb-1">
            {{customer.repoveCode}} - {{customer.detail}}


          </ng-option>
        </ng-select>
        <div class="invalid-feedback">
          <div *ngIf="
                isSubmit ||
                (f.customerId.invalid && (f.customerId.dirty || f.customerId.touched))
              ">
            {{"BookingCalendar.Modal.Error.Customer"| translate}}
          </div>
        </div>
      </div>

      <div class="col-12" style="margin-bottom: 0.5rem">
        <label class="form-label"> {{ "BookingCalendar.Modal.Location" | translate }} </label>

        <span class="text-danger">&nbsp;*</span>
        <div class="skeleton-select" *ngIf="isBranchListLoading">
          <div class="spinner-border spinner-border-sm mr-50" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <ng-select *ngIf="!isBranchListLoading" formControlName="branchId" (change)="onBranchChange($event)" [ngClass]="{'is-invalid error':
        (isSubmit && f.branchId?.invalid) ||
        (f.branchId?.invalid &&
        (f.branchId?.dirty || f.branchId?.touched))}">
          <ng-option *ngFor="let branch of branchList" [value]="branch.id">{{
            branch.name
            }}</ng-option>
        </ng-select>
        <div class="invalid-feedback">
          <div *ngIf="
                isSubmit ||
                (f.branchId.invalid && (f.branchId.dirty || f.branchId.touched))
              ">
            {{"BookingCalendar.Modal.Error.Location"| translate}}
          </div>
        </div>
      </div>

      <div class="col-12" style="margin-bottom: 0.5rem">
        <div class="d-flex">
          <div style="width: 44%; margin-right: 0.5rem">
            <label for="start-date" class="form-label"> {{ "BookingCalendar.Modal.Date" | translate }} </label>
            <ng2-flatpickr class="date-form-control" [setDate]="dateOptions.defaultDate" [config]="dateOptions"
              name="date" formControlName="date" #startDatePicker required
              [ngClass]="{ 'disabled-form': formDisable }"></ng2-flatpickr>

          </div>

          <div class="invalid-feedback">
            <div *ngIf="isSubmit && f.date.errors">
              {{"BookingCalendar.Modal.Error.Time"| translate}}
            </div>
          </div>
          <!-- <div class="col-2 text-danger">
            <div
              *ngIf="isSubmit &&  (formGroup.get('date').value == null)">
              {{ "Branch.Modal.Weekday.Error" | translate }}
            </div>
          </div> -->

          <div style="width: 56%; margin-left: 0.5rem">
            <label class="form-label"> {{ "BookingCalendar.Modal.PeriodTime" | translate }} </label>

            <span class="text-danger">&nbsp;*</span>
            <select class="form-control" (change)="onPeriodChange($event)" formControlName="periodTimeEnum">
              <option *ngFor="let period of periods" [value]="period.value">{{ period.label }}</option>
            </select>

            <div class="col-6 text-danger">
              <div *ngIf="isSubmit &&  f.periodTimeEnum.errors">
                {{ "Branch.Modal.Weekday.Error" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12" style="margin-bottom: 0.5rem">
        <div class="d-flex">
          <div class="pr-1" style="width: 50%"></div>
          <div class="d-flex" *ngIf="formGroup.controls.periodTimeEnum?.value == 0">
            <div class="" style="width: 50%">
              <label for="">{{ "BookingCalendar.Modal.StartTime" | translate }}</label>
              <ng2-flatpickr [ngClass]="{ 'disabled-form': formDisable }" class="date-form-control"
                [config]="startTimeOptions" name="time" formControlName="startDate"></ng2-flatpickr>
              <div class="col-8 text-danger">
                <div
                  *ngIf="isSubmit &&formGroup.controls.startDate?.value == null && formGroup.controls.periodTimeEnum?.value == 0">
                  {{ "Branch.Modal.Weekday.Error" | translate }}
                </div>
              </div>

            </div>
            <div class="pl-1" style="width: 50%">
              <label for="">{{ "BookingCalendar.Modal.EndTime" | translate }}</label>
              <ng2-flatpickr [ngClass]="{ 'disabled-form': formDisable }" class="date-form-control"
                [config]="endTimeOptions" name="time" formControlName="endDate"></ng2-flatpickr>
              <div class="col-8 text-danger">
                <div
                  *ngIf="isSubmit &&formGroup.controls.endDate?.value == null && formGroup.controls.periodTimeEnum?.value == 0">
                  {{ "Branch.Modal.Weekday.Error" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


      <div class="col-12" style="margin-bottom: 0.5rem">
        <label class="form-label"> {{ "BookingCalendar.Modal.Remarks" | translate }}</label>

        <!-- <span class="text-danger">&nbsp;*</span> -->
        <textarea class="form-control" formControlName="remarks" id="" cols="30" rows="5"></textarea>
      </div>

      <div class="col-12" style="margin-bottom: 0.5rem">
        <label class="form-label" for="colorId">{{
          "BookingCalendar.Modal.EventColor" | translate
          }}</label>

        <span class="text-danger">&nbsp;*</span>
        <div class="w-100">
          <div class="branch-color-options">
            <ul class="list-unstyled mb-0">
              <span *ngFor="let item of colorList; index  as id">
                <li [ngClass]="colorClicked === item.id ? 'd-inline-block selected' : 'd-inline-block'"
                  (click)="selectColor(item.id)" [value]="item.colorCode">
                  <div class="color-option b" style="--main-color: {{item.colorCode}}">
                    <div class="filloption b"></div>
                  </div>

                </li>
              </span>
            </ul>
          </div>


          <div class="text-danger">
            <div *ngIf="
                  isSubmit && (colorClicked == 0 || colorClicked == null)
                ">
              {{ "BookingCalendar.Modal.Error.EventColor" | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 text-center" *ngIf="!isWaitApproveEdit&&isEditData"> <!-- Centering the <a> element -->
        <a (click)="openDeleteModal(event)" *ngIf="formGroup.controls.stateEnum?.value != bookingEnum.Reject"
          class="text-danger"><u>{{"BookingCalendar.Modal.CancelEvent"| translate}}</u></a>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-secondary m-0" (click)="closeModal()" rippleEffect>
    {{ "Form.Cancel" | translate }}
  </button>
  <button type="button" class="btn btn-primary m-0" *ngIf="formGroup.controls.stateEnum?.value != bookingEnum.Reject"
    (click)="callBack()" [disabled]="formDisable" rippleEffect>
    {{ "Form.Submit" | translate }}
  </button>
</div>