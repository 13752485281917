<div class="modal-header">
  <h5 class="modal-title" id="exampleModalCenterTitle">
    {{ "Stock.Adjust" | translate }} {{ itemObj.name }}
  </h5>
  <button type="button" class="close" (click)="closeModal()" aria-label="Close">
    <span aria-hidden="true">
      <i data-feather="x" size="22"></i>
    </span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li ngbNavItem>
      <a ngbNavLink (click)="selectMode(adjustMode.Adjust)">{{
        "Stock.Adjust" | translate
        }}</a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="adjustStockForm"></ng-container>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="!this.setMode">
      <a ngbNavLink (click)="selectMode(adjustMode.Transfer); initTransferForm()">{{ "Stock.Transfer" | translate }}</a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="transferStockForm"></ng-container>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-secondary m-0" (click)="openCancelModal()" rippleEffect>
    {{ "Form.Cancel" | translate }}
  </button>
  <button type="button" class="btn btn-primary m-0" [disabled]="adjustForm.invalid" (click)="openConfirmModal()"
    rippleEffect>
    {{ "Form.Confirm" | translate }}
  </button>
</div>

<ng-template #transferStockForm>
  <form [formGroup]="transferForm">
    <div class="d-flex justify-content-between" style="padding-inline: 1rem">
      <div class="w-100">
        <label for="">{{ "Stock.From" | translate }}</label>
        <select class="custom-select" formControlName="stockTypeFrom" (change)="onSelectStockType(true)">
          <option [ngValue]="type.value" *ngFor="let type of kStockType">
            {{ "Stock." + type.name | translate }}
          </option>
        </select>

        <div class="d-block mt-2">
          <div class="d-flex">
            <p>{{ "Stock.Before" | translate }}: &nbsp;</p>
            <p>{{ stockFromQuantityBefore }}</p>
          </div>
          <div class="d-flex">
            <p>{{ "Stock.After" | translate }}: &nbsp;</p>
            <p>{{ stockFromQuantityAfter }}</p>
          </div>
        </div>
      </div>
      <div class="mx-2 align-self-center">
        <a (click)="SwapStockType()">
          <i data-feather="repeat"></i>
        </a>
      </div>
      <div class="w-100">
        <label for="">{{ "Stock.To" | translate }}</label>
        <select class="custom-select" formControlName="stockTypeTo" (change)="onSelectStockType(false)">
          <option [ngValue]="type.value" *ngFor="let type of kStockType">
            {{ "Stock." + type.name | translate }}
          </option>
        </select>

        <div class="d-block mt-2">
          <div class="d-flex">
            <p>{{ "Stock.Before" | translate }}: &nbsp;</p>
            <p>{{ stockToQuantityBefore }}</p>
          </div>
          <div class="d-flex">
            <p>{{ "Stock.After" | translate }}: &nbsp;</p>
            <p>{{ stockToQuantityAfter }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <label for="quantity">{{ "Stock.Quantity" | translate }}</label>
      <input [ngClass]="{
          'is-invalid error':
            mode == adjustMode.Transfer &&
            transferForm.get('quantity').hasError('nonNegativeNumber')
        }" formControlName="quantity" id="quantityTransferInput" class="form-control" type="text"
        (blur)="updateTransferQuantity(); formatNumber('quantityTransferInput')" />

      <div [ngClass]="{
          'd-block':
            mode == adjustMode.Transfer &&
            transferForm.get('quantity').hasError('nonNegativeNumber')
        }" class="invalid-feedback">
        Number must be a non-negative value.
      </div>
    </div>

    <div class="col-12">
      <label class="mt-2" for="quantity">{{
        "Stock.Remarks" | translate
        }}</label>
      <textarea formControlName="remark" class="form-control"></textarea>
    </div>
  </form>
</ng-template>

<ng-template #adjustStockForm>
  <form [formGroup]="adjustForm">
    <div class="d-flex">
      <div class="col-12">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">{{
            "Stock.Adjusted" | translate
            }}</label>
          <div class="col-sm-9 d-flex align-items-center">
            <div class="custom-control custom-radio mr-2">
              <input type="radio" id="adjustType" name="adjustType" class="custom-control-input" [value]="1"
                formControlName="adjustType" checked />
              <label class="custom-control-label" for="adjustType">{{
                "Stock.Increase" | translate
                }}</label>
            </div>
            <div class="custom-control custom-radio mr-2">
              <input type="radio" [value]="0" name="adjustType" class="custom-control-input" id="adjustType2"
                formControlName="adjustType" />
              <label class="custom-control-label" for="adjustType2">{{
                "Stock.Decrease" | translate
                }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="col-6">
        <label for="quantity">{{ "Stock.Quantity" | translate }}</label>
        <input [ngClass]="{
            'is-invalid error':
              mode == adjustMode.Adjust &&
              adjustForm.get('quantity').hasError('nonNegativeNumber')
          }" formControlName="quantity" id="quantityAdjustInput" class="form-control" type="text"
          (blur)="checkQuantity(); formatNumber('quantityAdjustInput')" />

        <div [ngClass]="{
            'd-block':
              mode == adjustMode.Adjust &&
              adjustForm.get('quantity').hasError('nonNegativeNumber')
          }" class="invalid-feedback">
          Number must be a non-negative value.
        </div>
      </div>

      <div class="col-6">
        <label for="quantity">{{ "Stock.StockType" | translate }}</label>
        <select formControlName="stockType" class="custom-select">
          <option [ngValue]="type.value" *ngFor="let type of kStockType">
            {{ "Stock." + type.name | translate }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-12">
      <label class="mt-2" for="quantity">{{
        "Stock.Remarks" | translate
        }}</label>
      <textarea formControlName="remark" class="form-control"></textarea>
    </div>

    <div *ngIf="this.setMode">
      <table class="table table-borderless mt-3">
        <thead>
          <tr>
            <th class="text-center" width="3%">#</th>
            <th>{{ "Stock.Name" | translate }}</th>
            <th class="text-center">{{ "Stock.Remain" | translate }}</th>
            <th class="text-center">{{ "Stock.Used" | translate }}</th>
            <th class="text-center">
              {{ "Stock.QuantityForAdd" | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let childItem of childItemsList; index as ind">
            <td class="text-center">{{ ind + 1 }}</td>
            <td>{{ childItem.itemCode }} - {{ childItem.name }}</td>
            <td class="text-center">
              {{ childItem.quantity | number }}
            </td>

            <td class="text-center">
              {{ getQuantityToUse(childItem.id) | number }}
            </td>

            <td class="text-center">
              {{ getQuantityUsed(childItem.id) | number }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</ng-template>