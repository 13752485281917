<div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
  <app-loading></app-loading>
</div>
<div *ngIf="!isLoading" class="row">
  <div class="col-md-12">
    <form [formGroup]="registerForm">
      <h4 class="mb-2">{{ componentName }} Name</h4>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{
          "Form.Name" | translate
        }}</label>
        <div class="col-sm-9">
          <input
            class="form-control"
            type="text"
            formControlName="name"
            [ngClass]="{
              'is-invalid error':
                f.name?.invalid && (f.name?.dirty || f.name?.touched)
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block': f.name.invalid && (f.name.dirty || f.name.touched)
            }"
          >
            <div>
              <div *ngIf="f.name.errors?.required">
                {{ "Handle.Name" | translate }}
              </div>
              <div *ngIf="f.name.errors?.maxlength">
                {{ "Handle.Exceed" | translate }}
                50
                {{ "Handle.Char" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <h4 class="mb-2">{{ componentName }} Content</h4>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{
          "Form.Title" | translate
        }}</label>
        <div class="col-sm-9">
          <input
            class="form-control"
            type="text"
            formControlName="title"
            [ngClass]="{
              'is-invalid error':
                f.title?.invalid && (f.title?.dirty || f.title?.touched)
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block': f.title.invalid && (f.title.dirty || f.title.touched)
            }"
          >
            <div>
              <div *ngIf="f.title.errors?.required">
                {{ "Handle.Title" | translate }}
              </div>
              <div *ngIf="f.title.errors?.maxlength">
                {{ "Handle.Exceed" | translate }}
                50
                {{ "Handle.Char" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{
          "Form.Subtitle" | translate
        }}</label>
        <div class="col-sm-9">
          <input
            class="form-control"
            type="text"
            formControlName="subTitle"
            [ngClass]="{
              'is-invalid error':
                f.subTitle?.invalid &&
                (f.subTitle?.dirty || f.subTitle?.touched)
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                f.subTitle.invalid && (f.subTitle.dirty || f.subTitle.touched)
            }"
          >
            <div>
              <div *ngIf="f.subTitle.errors?.required">
                {{ "Handle.SubTitle" | translate }}
              </div>
              <div *ngIf="f.subTitle.errors?.maxlength">
                {{ "Handle.Exceed" | translate }}
                50
                {{ "Handle.Char" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">
          {{ "Form.ButtonCaption" | translate }}
        </label>
        <div class="col-sm-9">
          <input
            class="form-control"
            type="text"
            formControlName="linkBtnCaption"
            [ngClass]="{
              'is-invalid error':
                f.linkBtnCaption?.invalid &&
                (f.linkBtnCaption?.dirty || f.linkBtnCaption?.touched)
            }"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">
          {{ "Form.ButtonURL" | translate }}
        </label>
        <div class="col-sm-9">
          <input
            class="form-control"
            type="text"
            formControlName="linkBtnUrl"
            [ngClass]="{
              'is-invalid error':
                f.linkBtnUrl?.invalid &&
                (f.linkBtnUrl?.dirty || f.linkBtnUrl?.touched)
            }"
          />
        </div>
      </div>

      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-3 col-form-label">{{
          "Form.Status" | translate
        }}</label>
        <div class="col-sm-9">
          <div
            class="custom-control custom-switch custom-control-inline col-form-label"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitch1"
              [(ngModel)]="itemObj.isActive"
              [ngModelOptions]="{ standalone: true }"
              name="isActive"
              [checked]="itemObj.isActive"
            />
            <label class="custom-control-label" for="customSwitch1"></label>
          </div>
        </div>
      </div>

      <br />
      <div class="d-flex justify-content-between">
        <button class="btn btn-secondary" (click)="openCancelModal(itemObj)">
          {{ "Form.Cancel" | translate }}
        </button>

        <button class="btn btn-primary" (click)="openConfirmModal(itemObj)">
          {{ "Form.Submit" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
