import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { BrandComponent } from './brand.component';
import { BrandFormpageComponent } from './brand-formpage/brand-formpage.component';
import { AuthGuard } from 'app/auth/helpers';
import { ComponentsModule } from 'app/main/components/components.module';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { environment } from 'environments/environment';

const routes: Routes = !environment.menuList.itemManage ? [] : [
  {
    path: 'item-manage/brand',
    component: BrandComponent,
    canActivate: [AuthGuard],
    data: { animation: 'brand-manage' },
  },
  {
    path: 'item-manage/brand/create',
    component: BrandFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'brand-create' },
  },
  {
    path: 'item-manage/brand/edit/:id',
    component: BrandFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'brand-edit' },
  },
]

@NgModule({
  declarations: [
    BrandComponent,
    BrandFormpageComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ComponentsModule,
    CoreCommonModule,
    ContentHeaderModule
  ],
  exports: [
    BrandComponent,
    BrandFormpageComponent
  ]
})
export class BrandModule { }
