import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalManagePaymentComponent } from 'app/main/components/modal-manage-payment/modal-manage-payment.component';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { PaymentAction } from 'app/main/model/PaymentAction';
import { ApiService } from 'app/main/service/api.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import {
  CdkDrag,
  CdkDragDrop,
  DragRef,
  DropListRef,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { BankAccount } from 'app/main/model/BankAccount';
import { take } from 'rxjs/operators';
import { SettingsApiService } from 'app/main/service/settings-api.service';

@Component({
  selector: 'app-bank-manage',
  templateUrl: './bank-manage.component.html',
  styleUrls: ['./bank-manage.component.scss'],
})
export class BankManageComponent implements OnInit {
  contentHeader: object;
  allBankList: BankAccount[] = [];
  defaultBankList: BankAccount[] = [];
  countDefaultBank: number = 0;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  searchData: any = {
    isDelete: false,
    isActive: true,
  };
  isLoading: boolean = false;

  apiPath: string = 'BankAccount';

  selectedBankAccount: any = null;
  isNewRow: boolean = false;

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private _apiService: ApiService,
    private _globalFuncService: GlobalFuncService,
    private _modalService: NgbModal,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _toastService: ToastrService,
    private _settingsApiService: SettingsApiService
  ) {
    this.getBankAccountData();
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;

    this.contentHeader = {
      headerTitle: 'BreadCrumbs.Manage.BankAccount',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  getBankAccountData(id?: number) {
    this._apiService
      .GetAllData(this.apiPath, this.searchData)
      .subscribe((res) => {
        this.allBankList = [];
        this.defaultBankList = [];
        this.allBankList = res.data.resultData;

        const objectsToMove = this.allBankList.filter(
          (item) => item.isUseForDocument === true
        );

        this.countDefaultBank = objectsToMove.length;

        if (objectsToMove.length > 0) {
          objectsToMove.forEach((objectToMove) => {
            const indexToMove = this.allBankList.indexOf(objectToMove);
            this.allBankList.splice(indexToMove, 1);
            this.defaultBankList.push(objectToMove);
          });
        }
      });
  }

  onOpenAddBankAccountModal(event: string) {
    if (event === PaymentAction.BankAccount.Create) {
      console.log('Open Modal Create Bank Account');
      this.openManagePaymentModal(event);
    } else {
      // Handle the selection of a regular bank account option.
      // The selected bank account ID is available in this.selectedBankAccount.
    }
  }

  openManagePaymentModal(event: string, data?: any) {
    const modalRef = this._modalService.open(ModalManagePaymentComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
    });
    modalRef.componentInstance.actionPayment = event;
    modalRef.componentInstance.editData = data;
    modalRef.componentInstance.setMode = false;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.selectedBankAccount = null;
    });
    modalRef.componentInstance.refreshData.subscribe((res) => {
      this.getBankAccountData(res[0]);
    });
  }

  openDeleteModal(item: any) {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title =
      this._translateService.instant('Brand.DeleteTitle');
    modalRef.componentInstance.detail =
      this._translateService.instant('Brand.Delete');
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.setDeleteItem(item.id);
    });
  }

  setDeleteItem(id: number): void {
    this.blockUI.start();
    var self = this;

    this._apiService.SoftDelete(this.apiPath, id.toString()).subscribe(
      (res) => {
        this._apiService
          .SetIsActiveData(this.apiPath, id.toString(), false)
          .subscribe((res) => {
            this.blockUI.stop();
            this.allBankList = this.allBankList.filter(
              (bank) => bank.id !== id
            );
            this._componentsService.SuccessSwal();
          });
      },
      (err) => {
        this._componentsService.ErrorSwal();
      }
    );
  }

  copyCode(inputTextValue) {
    const selectBox = document.createElement('textarea');
    selectBox.style.position = 'fixed';
    selectBox.value = inputTextValue;
    document.body.appendChild(selectBox);
    selectBox.focus();
    selectBox.select();
    document.execCommand('copy');
    document.body.removeChild(selectBox);

    this._componentsService.toastrSuccess(
      this._translateService.instant('Settings.Copied'),
      this._translateService.instant('Settings.CopyBankAccountToClipboard')
    );
  }

  errorToastActive: boolean = false;

  openSetDefaultModal(id: number): Promise<boolean> {
    let result = false;

    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });

    return new Promise((resolve, reject) => {
      modalRef.componentInstance.title = this._translateService.instant(
        'Settings.Modal.SetDefaultBankAccountTitle'
      );
      modalRef.componentInstance.detail = this._translateService.instant(
        'Settings.Modal.SetDefaultBankAccountDetail'
      );
      modalRef.componentInstance.cancelFunc.subscribe((res) => {
        result = false;
        resolve(result);
      });

      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        let tempDefault = this.defaultBankList.map((a) => a.id);
        tempDefault.push(id);
        this._settingsApiService
          .UpdateBankForDocumentSettings({ bankAccountIdList: tempDefault })
          .subscribe((res) => {
            this._componentsService.toastrSuccess(
              this._translateService.instant('Settings.Toast.Success'),
              this._translateService.instant('Settings.Toast.SuccessDefaultBankAccountDetail')
            );
            this.getBankAccountData();
          });
        result = true;
        resolve(result);
      });
    });
  }

  openRemoveDefaultModal(id: number): Promise<boolean> {
    let result = false;

    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });

    return new Promise((resolve, reject) => {
      modalRef.componentInstance.title = this._translateService.instant(
        'Settings.Modal.RemoveDefaultBankAccountTitle'
      );
      modalRef.componentInstance.detail = this._translateService.instant(
        'Settings.Modal.RemoveDefaultBankAccountDetail'
      );
      modalRef.componentInstance.cancelFunc.subscribe((res) => {
        result = false;
        resolve(result);
      });

      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        let tempDefault = this.defaultBankList
          .filter((bank) => bank.id !== id)
          .map((a) => a.id);
        this._settingsApiService
          .UpdateBankForDocumentSettings({ bankAccountIdList: tempDefault })
          .subscribe((res) => {
            this._componentsService.toastrSuccess(
              this._translateService.instant('Settings.Toast.Success'),
              this._translateService.instant('Settings.Toast.SuccessRemoveDefaultBankAccountDetail')
            );
            this.getBankAccountData();
          });
        result = true;
        resolve(result);
      });
    });
  }
}
