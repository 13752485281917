import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-single-file-upload',
  templateUrl: './single-file-upload.component.html',
  styleUrls: ['./single-file-upload.component.scss']
})
export class SingleFileUploadComponent implements OnInit {
  selectedFileName:string;
  selectedFile: any;

  cover:string;

  uploadForm: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

  onFileSelected(event: any): void {
    this.selectedFileName = event.target.files[0].name;
    const file = event.target.files[0];
    const reader = new FileReader();
    const file_input = event.target.files[0];
    this.uploadForm.patchValue({ file: file_input });
    if (file && file.size <= 20 * 1024 * 1024) {
      reader.onloadend = () => {
        this.selectedFile = reader.result as string;
      };
      reader.readAsDataURL(file);
      this.cover = file;
      console.log(this.cover);
    } else {
      console.error('File size exceeds the maximum limit of 20MB.');
    }
  }

}
