import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccountApiService {
  private apiBaseUrl: string;
  private apiPath: string = 'account';
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient, private _apiService: ApiService) {
    this.apiBaseUrl = this._apiService.API_URL;
    this.httpHeaders = this._apiService.API_HEADERS;
  }

  CreateAccount(body: object): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${this.apiPath}`;
    return this.http
      .post(API_URL, body, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  UpdateAccount(body: object): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${this.apiPath}`;
    return this.http
      .put(API_URL, body, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  UpdateAccountPassword(body: object): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${this.apiPath}/password`;
    return this.http
      .patch(API_URL, body, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  SentForgetPassword(body: object): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${this.apiPath}/forgotPassword`;
    return this.http
      .post(API_URL, body, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  SentTokenForgetPassword(body: object): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${this.apiPath}/forgotPassword`;
    return this.http
      .patch(API_URL, body, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  SentVerifyEmail(body: object): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${this.apiPath}/verify`;
    return this.http
      .post(API_URL, body, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  SentVerifyTokenAccount(body: object): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${this.apiPath}/verify`;
    return this.http
      .patch(API_URL, body, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }
}
