export interface BankAccount {
  id: number;
  name: string;
  bankName: string;
  bankId: number;
  accountNumber: string;
  accountName: string;
  bankBranch: string;
  bankAccountType: number;
  bankAccountTypeName: string;
  isUseForDocument: boolean;
}

export const BankList  = [
  { id: 1, name: 'ธนาคารแห่งประเทศไทย' },
  { id: 2, name: 'ธนาคารกรุงเทพ' },
  { id: 3, name: 'ธนาคารกสิกรไทย' },
  { id: 4, name: 'ธนาคารกรุงไทย' },
  { id: 5, name: 'ธนาคารทหารไทยธนชาต' },
  { id: 6, name: 'ธนาคารไทยพาณิชย์' },
  { id: 7, name: 'ธนาคารซิตี้แบงก์' },
  { id: 8, name: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)' },
  { id: 9, name: 'ธนาคารซีไอเอ็มบีไทย' },
  { id: 10, name: 'ธนาคารยูโอบี' },
  { id: 11, name: 'ธนาคารกรุงศรีอยุธยา' },
  { id: 12, name: 'ธนาคารเมกะ สากลพาณิชย์' },
  { id: 13, name: 'ธนาคารออมสิน' },
  { id: 14, name: 'ธนาคารอาคารสงเคราะห์' },
  { id: 15, name: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร' },
  { id: 16, name: 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย' },
  { id: 17, name: 'ธนาคารแห่งประเทศจีน (ไทย)' },
  { id: 18, name: 'ธนาคารอิสลามแห่งประเทศไทย' },
  { id: 19, name: 'ธนาคารทิสโก้' },
  { id: 20, name: 'ธนาคารเกียรตินาคินภัทร' },
  { id: 21, name: 'ธนาคารไอซีบีซี (ไทย)' },
  { id: 22, name: 'ธนาคารไทยเครดิตเพื่อรายย่อย' },
  { id: 23, name: 'ธนาคารแลนด์ แอนด์ เฮ้าส์' },
  { id: 24, name: 'ธนาคารเอเอ็นแซด (ไทย)' },
  { id: 25, name: 'ธนาคารซูมิโตโม มิตซุย ทรัสต์ (ไทย)' },
  { id: 26, name: 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย' },
];