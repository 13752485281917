<div class="container-xxl export-header-qo">
  <app-content-header [contentHeader]="contentHeader"></app-content-header>
</div>

<app-document-papers
  [isPayments]="true"
  [isDebit]="true"
  [PreAmount]="PreAmount"
  [data]="passObj"
  docCodeColumn="debitNoteCode"
  [isShowPayment]="true"
  [showValidDate]="false"
  [isPaymentDataInDocument]="true"
></app-document-papers>
