<block-ui> </block-ui>

<!-- แท็บสั่งปรี้นท์ -->
<div class="paper-tool-btn container-xxl">
  <button class="btn btn-secondary" (click)="goBackPage()">
    {{ "General.Back" | translate }}
  </button>

  <div>
    {{ data.titleTh }}: {{ quotationObj ? quotationObj[docCodeColumn] : "-" }}
  </div>

  <div>
    <button class="btn mr-1" [ngClass]="{
        'btn-outline-primary': showPaperTool,
        'btn-dark': !showPaperTool
      }" (click)="showPaperTool = !showPaperTool">
      <i data-feather="settings"></i>
      {{ "DocumentPaper.FixedPage" | translate }}
    </button>
    <button class="btn btn-warning" (click)="printWindow()">
      <i data-feather="printer"></i>
      {{ "DocumentPaper.Print" | translate }}
    </button>
  </div>
</div>

<!-- แผงควบคุม -->
<div class="paper-tool shadow" [ngClass]="{
    'show-paper': showPaperTool
  }">
  <div class="paper-tool-header">
    {{ "DocumentPaper.HideObject" | translate }}
  </div>
  <div>
    <button (click)="showPageNumber = !showPageNumber" [ngClass]="{
        'active-pt-btn': !showPageNumber
      }">
      <span>{{ "DocumentPaper.PageNumber" | translate }}</span>
    </button>
    <button (click)="showManuScr = !showManuScr" [ngClass]="{
        'active-pt-btn': !showManuScr
      }">
      <span>{{ "DocumentPaper.Manuscript" | translate }}</span>
    </button>

    <button (click)="showDummyRow = !showDummyRow" [ngClass]="{
        'active-pt-btn': !showDummyRow
      }">
      <span>{{ "DocumentPaper.BGColor" | translate }}</span>
    </button>

    <button (click)="showWaterMsk = !showWaterMsk" [ngClass]="{
        'active-pt-btn': !showWaterMsk
      }">
      <span>{{ "DocumentPaper.Watermark" | translate }}</span>
    </button>
    <button (click)="fxTable = !fxTable" [ngClass]="{
        'active-pt-btn': !fxTable
      }">
      <span>{{ "DocumentPaper.TableColor" | translate }}</span>
    </button>

    <button (click)="showDiscount = !showDiscount" [ngClass]="{
        'active-pt-btn': !showDiscount
      }">
      <span>{{ "DocumentPaper.Discount" | translate }}</span>
    </button>

    <button (click)="isOriginalDoc = !isOriginalDoc" [ngClass]="{
        'active-pt-btn': !isOriginalDoc
      }">
      <span>{{ "DocumentPaper.IsOriginal" | translate }}</span>
    </button>

    <button (click)="showWithheldtax = !showWithheldtax" [ngClass]="{
        'active-pt-btn': !showWithheldtax
      }">
      <span>{{ "DocumentPaper.WithheldTax" | translate }}</span>
    </button>
    <button *ngIf="isShowShippingFee" (click)="showShippingFee = !showShippingFee" [ngClass]="{
        'active-pt-btn': !showShippingFee
      }">
      <span>{{ "DocumentPaper.ShippingFee" | translate }}</span>
    </button>
  </div>
  <div class="paper-tool-footer">
    <button (click)="showAllObj()">
      <span>{{ "DocumentPaper.ClearAll" | translate }}</span>
    </button>
  </div>
</div>

<!-- กระดาษใบเดียว  ไม่มีการตัดหน้าการดาษ -->
<div *ngIf="!Excess">
  <div class="online-scope">
    <div name="online-view-v1" class="online-view-v1">
      <div class="watermasks" *ngIf="showWaterMsk">
        <p class="inner-watermasks">
          {{ "Form.Cancel" | translate }}
        </p>
      </div>
      <table class="qo-slip">
        <tbody>
          <!-- header -->
          <ng-container *ngTemplateOutlet="headerPaper"></ng-container>

          <!-- #endregion -->
          <!-- #region Body -->
          <tr class="body" *ngIf="quotationObj">
            <td #parentTable>
              <div class="qo-main-scope" #mainElement>
                <table class="qo-main-table" #tableElement [ngClass]="{
                    qo_main_table_fx: !fxTable
                  }">
                  <tbody>
                    <tr class="trc-header" #trcHeaderElement>
                      <td>
                        <strong>รหัส </strong>
                        <br /><span> ID no. </span>
                      </td>
                      <td style="width: 300px">
                        <strong>คำอธิบาย</strong>
                        <br /><span> Description </span>
                      </td>
                      <td>
                        <strong>จำนวน</strong>
                        <br /><span> Quantity </span>
                      </td>
                      <td>
                        <strong>หน่วย</strong>
                        <br /><span> Unit </span>
                      </td>
                      <td>
                        <strong>ราคาต่อหน่วย</strong>
                        <br /><span> Unit Price </span>
                      </td>
                      <td *ngIf="showDiscount">
                        <strong>ส่วนลด</strong>
                        <br /><span> Discount </span>
                      </td>
                      <td>
                        <strong>มูลค่าก่อนภาษี</strong>
                        <br /><span> Pre-Tax Amount </span>
                      </td>
                    </tr>

                    <tr *ngFor="let item of quotationObj.item" #itemListElements class="trc">
                      <td class="text-center">
                        <span class="text-dark"> {{ item.itemCode }} </span>
                      </td>
                      <td class="text-start" style="white-space: pre-line">
                        <p *ngFor="let desc of item.description" class="m-0">
                          {{ desc.description }}
                        </p>
                      </td>
                      <td class="text-end">
                        {{ convertNumbertoMoneyFormat(item.quantity) }}
                      </td>
                      <!-- <td class="text-center">{{ item.unit }}</td> -->
                      <td class="text-center">ชิ้น</td>
                      <td class="text-end">
                        {{ convertNumbertoMoneyFormat(item.unitPrice) }}
                      </td>
                      <td class="text-end" *ngIf="showDiscount">
                        {{
                        item.isDiscountByPercent
                        ? convertNumbertoMoneyFormat(item.percentDiscount)
                        : convertNumbertoMoneyFormat(item.discount)
                        }}

                        <span *ngIf="item.isDiscountByPercent">%</span>
                      </td>
                      <td class="text-end">
                        {{ convertNumbertoMoneyFormat(item.preTaxAmount) }}
                      </td>
                    </tr>

                    <tr class="dummy-row" [ngClass]="{
                        'd-none': !showDummyRow
                      }" *ngFor="let num of [].constructor(dummyRowAmountList[0])">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <!-- #endregion -->
          <!-- #region Footer -->
          <ng-container *ngTemplateOutlet="footerPaper"></ng-container>
          <ng-container *ngTemplateOutlet="subFooterPaper"></ng-container>

          <tr>
            <hr />
            <div class="qo-credit">
              <div>
                <span></span>
              </div>
              <div>
                <strong>จัดเตรียมโดย /</strong> Prepared by
                {{ quotationObj ? quotationObj.preparedByName : "-" }}
              </div>
            </div>
          </tr>
          <ng-container *ngIf="showLogoFooter">
            <ng-container *ngTemplateOutlet="subFooterLogoPaper"></ng-container>
          </ng-container>


        </tbody>
      </table>

      <!-- show when document has been deleted. -->
    </div>
  </div>
</div>

<!-- กระดาษหลายใบ  มีการตัดหน้ากระดาษ -->
<div *ngIf="Excess">
  <div class="online-scope" *ngFor="let list of itemList; let lst = last; let ind = index">
    <div name="online-view-v1" class="online-view-v1" #mainElement>
      <div class="watermasks" *ngIf="showWaterMsk">
        <p class="inner-watermasks">
          {{ "Form.Cancel" | translate }}
        </p>
      </div>
      <table class="qo-slip" #tableElement>
        <tbody>
          <ng-container *ngTemplateOutlet="headerPaper"></ng-container>
          <!-- #endregion -->
          <!-- #region Body -->
          <tr class="body" #overElement *ngIf="quotationObj">
            <td>
              <div class="qo-main-scope" #mainElement [ngClass]="{
                  overhigh: !lst
                }">
                <table class="qo-main-table" #tableElement [ngClass]="{
                    qo_main_table_fx: !fxTable
                  }">
                  <tbody>
                    <tr class="trc-header" #trcHeaderElement>
                      <td>
                        <strong>รหัส</strong>
                        <br /><span> ID no. </span>
                      </td>
                      <td style="width: 300px">
                        <strong>คำอธิบาย</strong>
                        <br /><span> Description </span>
                      </td>
                      <td>
                        <strong>จำนวน</strong>
                        <br /><span> Quantity </span>
                      </td>
                      <td>
                        <strong>หน่วย</strong>
                        <br /><span> Unit </span>
                      </td>
                      <td>
                        <strong>ราคาต่อหน่วย</strong>
                        <br /><span> Unit Price </span>
                      </td>
                      <td *ngIf="showDiscount">
                        <strong>ส่วนลด</strong>
                        <br /><span> Discount </span>
                      </td>
                      <td>
                        <strong>มูลค่าก่อนภาษี</strong>
                        <br /><span> Pre-Tax Amount </span>
                      </td>
                    </tr>

                    <tr *ngFor="let l of list" class="trc" #trClild>
                      <td class="text-center">
                        <a class="text-dark"> {{ l.itemCode }} </a>
                      </td>
                      <td class="text-start">
                        <p *ngFor="let desc of l.description" class="m-0">
                          {{ desc.description }}
                        </p>
                      </td>
                      <td class="text-end">
                        {{ convertNumbertoMoneyFormat(l.quantity) }}
                      </td>
                      <!-- <td class="text-center">{{ l.unit }}</td> -->
                      <td class="text-center">ชิ้น</td>
                      <td class="text-end">
                        {{ convertNumbertoMoneyFormat(l.unitPrice) }}
                      </td>
                      <td class="text-end" *ngIf="showDiscount">
                        {{ convertNumbertoMoneyFormat(l.discount) }}
                        <span *ngIf="l.isDiscountByPercent">%</span>
                      </td>
                      <td class="text-end">
                        {{ convertNumbertoMoneyFormat(l.preTaxAmount) }}
                      </td>
                    </tr>

                    <tr class="dummy-row" [ngClass]="{
                        'd-none': !showDummyRow
                      }" *ngFor="
                        let num of [].constructor(dummyRowAmountList[ind])
                      ">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <!-- #endregion -->
          <!-- #region Footer -->

          <ng-container *ngIf="lst">
            <ng-container *ngTemplateOutlet="footerPaper"></ng-container>
            <ng-container *ngTemplateOutlet="subFooterPaper"></ng-container>
          </ng-container>


          <tr>
            <hr />
            <div class="qo-credit">
              <div>
                <span *ngIf="showPageNumber">หน้า : {{ ind + 1 }}/{{ itemList.length }}</span>
              </div>
              <div>
                <strong>จัดเตรียมโดย /</strong> Prepared by
                {{ quotationObj ? quotationObj.preparedByName : "-" }}
              </div>
            </div>
          </tr>
          <ng-container *ngIf="showLogoFooter">
            <ng-container *ngTemplateOutlet="subFooterLogoPaper"></ng-container>
          </ng-container>

        </tbody>
      </table>

      <!-- show when document has been deleted. -->
    </div>
  </div>
</div>

<!-- หัวกระดาษ -->
<ng-template #headerPaper>
  <tr class="header">
    <td>
      <!-- HEADER -->
      <div class="document-header">
        <div class="type">
          <table class="qo-title">
            <tbody>
              <tr>
                <td class="name" *ngIf="data">
                  <h1>
                    {{ data.titleTh }}
                    <br />
                    {{ data.titleEn }}
                  </h1>
                </td>

                <td class="duplicate">
                  <p class="set"></p>
                  <p class="duplicate">
                    <span *ngIf="showManuScr">{{
                      isOriginalDoc
                      ? "( ต้นฉบับ / Original )"
                      : "( สำเนา / Copy )"
                      }}</span>
                  </p>
                </td>
                <td class="logo">
                  <div class="logo-scope" style="text-align: center">
                    <img [src]="logoOnPaper" alt="" srcset="" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="clear"></div>
        </div>
        <div class="contact-info-header">
          <table class="cih-left">
            <tbody>
              <tr>
                <td><strong>ลูกค้า / Customer</strong></td>
                <td colspan="2">
                  {{
                  quotationObj
                  ? quotationObj.customerName
                  ? quotationObj.customerName
                  : "-"
                  : "-"
                  }}

                  <span *ngIf="quotationObj">(
                    <span *ngIf="!quotationObj.isHeadBranch">
                      <span>สาขา</span>
                    </span>
                    <span>
                      {{
                      quotationObj.isHeadBranch
                      ? " สำนักงานใหญ่ "
                      : quotationObj.branchNumber
                      .toString()
                      .padStart(5, "0")
                      }}
                    </span>
                    )</span>
                </td>
              </tr>
              <tr>
                <td style="width: 160px">
                  <strong>ที่อยู่ / Address</strong>
                </td>
                <td colspan="2">
                  {{
                  quotationObj
                  ? quotationObj.taxInvoiceAddressDetail
                  ? quotationObj.taxInvoiceAddressDetail
                  : "-"
                  : "-"
                  }}
                </td>
              </tr>
              <tr>
                <td><strong>เลขผู้เสียภาษี/ Tax ID</strong></td>
                <td> {{
                  quotationObj
                  ? quotationObj.taxInvoiceCustomerTaxId
                  ? quotationObj.taxInvoiceCustomerTaxId
                  : "-"
                  : "-"
                  }}</td>

                <!-- <td>
                  <strong>E:</strong>
                  {{ customer ? customer.contactEmail : "-" }}
                </td> -->
              </tr>

              <tr>
                <td><strong>ผู้ติดต่อ/ Attention</strong></td>
                <td>
                  {{
                  quotationObj
                  ? quotationObj.taxInvoiceCustomerName
                  ? quotationObj.taxInvoiceCustomerName
                  : "-"
                  : "-"
                  }}
                </td>

                <!-- <td>
                  <strong>T:</strong>
                  {{
                    quotationObj ? quotationObj.taxInvoicePhone : "-"
                  }}
                </td> -->
              </tr>
            </tbody>
          </table>
          <table class="cih-right">
            <tbody>
              <tr>
                <td><strong>เลขที่ / No.</strong></td>
                <td>
                  {{ quotationObj ? quotationObj[docCodeColumn] : "-" }}
                </td>
              </tr>
              <tr>
                <td><strong>วันที่ / Issue</strong></td>
                <td>
                  {{
                  quotationObj
                  ? (quotationObj.documentCreateDate | date : "d/M/yyyy")
                  : "-"
                  }}
                </td>
              </tr>
              <tr *ngIf="showValidDate">
                <td><strong>ใช้ได้ถึง / Valid</strong></td>
                <td>
                  {{
                  quotationObj
                  ? (quotationObj.documentExpireDate | date : "d/M/yyyy")
                  : "-"
                  }}
                </td>
              </tr>
              <tr>
                <td><strong>อ้างอิง / Ref</strong></td>
                <td>
                  {{ quotationObj ? (quotationObj.references ? quotationObj.references : '-') : "-" }}
                </td>
              </tr>
              <tr *ngIf="isRevenue">
                <td><strong>ผู้ขาย / Seller</strong></td>
                <td>
                  {{ quotationObj ? (quotationObj.uplineCode ? quotationObj.uplineCode : '-') : "-" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <table class="cih-bottom">
          <tbody>
            <tr>
              <td>
                <strong>ผู้ออก</strong>
              </td>
              <td *ngIf="quotationObj">
                <!-- {{ quotationObj.customerName }} -->
                {{
                quotationObj
                ? quotationObj.issuerName
                ? quotationObj.issuerName
                : "-"
                : "-"
                }}
                <!-- <span *ngIf="quotationObj.isHeadBranch">
                  (สำนักงานใหญ่)
                </span> -->
              </td>
              <td colspan="2">
                <strong>เลขผู้เสียภาษี</strong><span>/ Tax ID</span>
                <span>:
                  {{
                  quotationObj
                  ? quotationObj.issuerTaxId
                  ? quotationObj.issuerTaxId
                  : "-"
                  : "-"
                  }}</span>
              </td>
            </tr>
            <tr>
              <td>issuer</td>
              <td></td>
              <td>
                <strong>T: </strong><span>{{
                  quotationObj
                  ? quotationObj.issuerPhone
                  ? quotationObj.issuerPhone
                  : "-"
                  : "-"
                  }}</span>
              </td>
              <td style="width: 200px">
                <strong>E: </strong><span>{{
                  quotationObj
                  ? quotationObj.issuerEmail
                  ? quotationObj.issuerEmail
                  : "-"
                  : "-"
                  }}</span>
              </td>
            </tr>
            <tr>
              <td style="line-height: 15px">
                <strong>ที่อยู่</strong>
              </td>
              <td rowspan="2">
                {{
                quotationObj
                ? quotationObj.issuerAddressDetail
                ? quotationObj.issuerAddressDetail
                : "-"
                : "-"
                }}
              </td>
              <td class="d-flex">
                <strong>W: </strong><span>&nbsp;{{
                  quotationObj
                  ? quotationObj.issuerWebsite
                  ? quotationObj.issuerWebsite
                  : "-"
                  : "-"
                  }}</span>
              </td>
            </tr>
            <tr>
              <td>Address</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </td>
  </tr>
</ng-template>

<!-- ท้ายกระดาษ 1 -->
<ng-template #footerPaper>
  <tr class="footer">
    <td>
      <table class="quo-slip-summary">
        <td>
          <strong>หมายเหตุ</strong>
          <span> /Remarks </span>
          <p class="ml-3" style="word-break: break-word">
            {{
            quotationObj
            ? quotationObj.remarks
            ? quotationObj.remarks
            : "-"
            : "-"
            }}
          </p>
        </td>
        <td>
          <table class="quo-slip-summary-scope">
            <tr *ngIf="isPayments">
              <td colspan="4">
                <strong>รวมมูลค่าตามใบกำกับภาษีเดิม /</strong>
                Previous Amount
              </td>

              <td colspan="1">
                {{ PreAmount ? convertNumbertoMoneyFormat(PreAmount) : "-" }}
              </td>
            </tr>

            <tr>
              <td colspan="4" *ngIf="!isPayments">
                <strong>ราคาสุทธิสินค้าที่เสียภาษี (บาท) /</strong>
                Pre-VAT Amount
              </td>

              <td colspan="4" *ngIf="isPayments">
                <strong>มูลค่าที่ถูกต้อง /</strong>
                Corrected Amount
              </td>

              <td colspan="1" *ngIf="isDebit && isPayments">
                {{
                quotationObj
                ? convertNumbertoMoneyFormat(
                PreAmount + quotationObj.preTaxAmountTotal
                )
                : "-"
                }}
              </td>

              <td colspan="1" *ngIf="!isDebit && isPayments">
                {{
                quotationObj
                ? convertNumbertoMoneyFormat(
                PreAmount - quotationObj.preTaxAmountTotal
                )
                : "-"
                }}
              </td>

              <td colspan="1" *ngIf="!isPayments">
                {{
                quotationObj
                ? convertNumbertoMoneyFormat(quotationObj.preTaxAmountTotal)
                : "-"
                }}
              </td>
            </tr>

            <tr *ngIf="isPayments">
              <td colspan="4">
                <strong>ผลต่าง /</strong>
                Difference
              </td>

              <td colspan="1">
                {{
                quotationObj
                ? convertNumbertoMoneyFormat(quotationObj.preTaxAmountTotal)
                : "-"
                }}
              </td>
            </tr>

            <tr *ngIf="isPayments">
              <td colspan="4">
                <strong>ราคาสุทธิสินค้าที่เสียภาษี (บาท) /</strong>
                Pre-VAT Amount
              </td>

              <td colspan="1">
                {{
                quotationObj
                ? convertNumbertoMoneyFormat(quotationObj.preTaxAmountTotal)
                : "-"
                }}
              </td>
            </tr>

            <tr>
              <td colspan="4"><strong>ภาษีมูลค่าเพิ่ม (บาท) /</strong> VAT</td>

              <td colspan="1">
                {{
                quotationObj
                ? convertNumbertoMoneyFormat(quotationObj.salesTax)
                : "-"
                }}
              </td>
            </tr>
            <tr *ngIf="showShippingFee && isShowShippingFee">
              <td colspan="4">
                <strong>ค่าจัดส่ง (บาท) /</strong> Shipping Fee
              </td>

              <td colspan="1">
                {{
                quotationObj
                ? convertNumbertoMoneyFormat(quotationObj.shippingFee)
                : "-"
                }}
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <strong>ราคานี้เป็นค่าเงิน (บาท) / </strong><span>Grand Total</span>
              </td>
              <td colspan="1">
                <span class="grandTotal-price mb-0">
                  {{
                  quotationObj
                  ? quotationObj.salesWHT != 0 && quotationObj.salesWHT > 0
                  ? convertNumbertoMoneyFormat(
                  quotationObj.preWHTAmountTotal
                  )
                  : convertNumbertoMoneyFormat(quotationObj.grandTotal)
                  : "-"
                  }}
                </span>
              </td>
            </tr>
            <tr *ngIf="!isPayments">
              <td colspan="4">
                <strong *ngIf="quotationObj">มูลค่าส่วนลดรวม
                  <span *ngIf="!quotationObj.isDiscountByPercent">(บาท)</span>
                  <span *ngIf="quotationObj.isDiscountByPercent">(เปอร์เซ็นต์)</span>
                  /</strong>
                Discount Amount
              </td>
              <td colspan="1">
                {{
                quotationObj
                ? convertNumbertoMoneyFormat(quotationObj.discount)
                : "-"
                }}

                <span *ngIf="quotationObj">
                  <span *ngIf="quotationObj.isDiscountByPercent">%</span>
                </span>
              </td>
            </tr>
            <tr *ngIf="showWithheldtax && !isPayments">
              <td colspan="4">
                <strong>ภาษีหัก ณ ที่จ่าย (บาท) / </strong>
                <span>Withheld tax </span>
              </td>

              <td colspan="1">
                {{
                quotationObj
                ? convertNumbertoMoneyFormat(quotationObj.salesWHT)
                : "-"
                }}
              </td>
            </tr>
            <tr *ngIf="showWithheldtax && !isPayments">
              <td colspan="4">
                <strong>จำนวนที่ต้องจ่าย (บาท) / </strong>
                <span> Net to pay </span>
              </td>

              <td colspan="1">
                <b>
                  {{
                  quotationObj
                  ? convertNumbertoMoneyFormat(quotationObj.grandTotal)
                  : "-"
                  }}
                </b>
              </td>
            </tr>
            <tr style="background: #f0f0f0">
              <td colspan="5">
                <div class="summary-read-price">
                  <strong> จำนวนที่ต้องจ่ายทั้งสิ้น</strong>
                  <strong>{{ THBgrantotal }}</strong>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </table>
    </td>
  </tr>
</ng-template>

<!-- ท้ายกระดาษ 2 -->
<ng-template #subFooterPaper>
  <tr class="cm">
    <td>
      <div class="qo-cn">
        <div class="qo-cn-remarks">
          <div *ngIf="isShowPayment" [ngClass]="{
              ispayments: isPayments
            }">
            <div class="ispayments-title">
              <div style="margin-top: 10px; margin-bottom: 10px">
                <div class="qo-input">
                  <strong>การชำระเงิน /</strong><span>Payment </span>
                </div>
              </div>
            </div>
            <div>
              <table class="w-100">
                <tbody *ngIf="!isPaymentDataInDocument && bankAccountList">
                  <tr class="ispayments-items" *ngFor="
                      let bankAcc of bankAccountList | slice : 0 : 2;
                      index as ind
                    ">
                    <td class="d-block" style="padding-top: 7px">
                      {{ ind + 1 }}
                    </td>
                    <td>
                      <div class="qo-input" style="background: #f0f0f0">
                        <span>ธนาคาร : {{ bankAcc.bankName }}</span>
                      </div>
                      <div class="qo-input">
                        <span>เลขที่บัญชี : {{ bankAcc.accountNumber }}</span>
                      </div>
                      <div class="qo-input">
                        <span>ชื่อบัญชี : {{ bankAcc.accountName }}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody *ngIf="isPaymentDataInDocument && quotationObj">
                  <div class="qo-input" style="background: #f0f0f0">
                    <span>ประเภทการชำระ :
                      {{
                      "Payment." + quotationObj.paymentTypeName | translate
                      }}</span>
                  </div>
                  <ng-container *ngIf="quotationObj.paymentTypeEnum != 1">
                    <div class="qo-input" style="background: #f0f0f0">
                      <span>ธนาคาร : {{ quotationObj.bankName }}</span>
                    </div>
                    <div class="qo-input">
                      <span>เลขที่บัญชี :
                        {{ quotationObj.bankAccountNumber }}</span>
                    </div>
                    <div class="qo-input">
                      <span>ชื่อบัญชี : {{ quotationObj.bankAccountName }}</span>
                    </div>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>

          <div class="ispayment-signature" *ngIf="isPayments">
            <span>เหตุผลของการลดหนี้</span>
            <span *ngIf="isPayments">
              {{ quotationObj ? quotationObj.reason : "-" }}
            </span>
          </div>
        </div>
        <div style="width: 100%">
          <div class="qo-cn-signature" [ngClass]="{
              'payments-s-box': isPayments
            }">
            <div class="qo-cn-signature-box">
              <div><strong>อนุมัติโดย /</strong><span> Approved by </span></div>
              <div>
                <div class="qo-s-line"></div>
                <div class="qo-s-date">
                  <span> วันที่ / Date </span>
                  <div class="qo-s-line"></div>
                </div>
              </div>
            </div>
            <div class="qo-cn-signature-box">
              <div><strong>อนุมัติโดย /</strong><span> Accepted by </span></div>
              <div>
                <div class="qo-s-line"></div>
                <div class="qo-s-date">
                  <span> วันที่ / Date </span>
                  <div class="qo-s-line"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="ispayments-check" *ngIf="isPayments">
            <div class="ispayments-check-box"></div>
            <span>หัก ณ ที่จ่าย</span>
          </div>
        </div>
      </div>
    </td>
  </tr>
</ng-template>

<!-- logo ที่ท้ายกระดาษ -->
<ng-template #subFooterLogoPaper>
  <hr class="mb-0" />
  <tr>
    <div class="d-flex justify-content-between align-items-center" style="height: 55px">
      <div class="col-2 logo-paper-footer-left">
        <img [src]="logoOnFooterLeftPaper" alt="" srcset="" />
      </div>
      <div class="col-8 address-footer text-center">
        <span>
          {{
          quotationObj
          ? quotationObj.issuerAddressDetail
          ? quotationObj.issuerAddressDetail
          : "-"
          : "-"
          }}
        </span>
      </div>
      <div class="col-2 logo-paper-footer-right">
        <img [src]="logoOnFooterRightPaper" alt="" srcset="" />
      </div>
    </div>
  </tr>
</ng-template>