import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ModalPaymentComponent } from 'app/main/components/modal-payment/modal-payment.component';
import { ApiService } from '../../../service/api.service';

@Component({
  selector: 'app-auto-form',
  templateUrl: './auto-form.component.html',
  styleUrls: ['./auto-form.component.scss'],
})

export class AutoFormComponent implements OnInit {
  contentHeader: object;
  isLoading: boolean;


  apiPath: string;
  itemID: string;

  itemObj: any;
  @ViewChild('paymentModal') confirmModal: ModalPaymentComponent;
  constructor(
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _apiService: ApiService,
    private _modalService: NgbModal,
  ) {
  }

  openModal(){
    const modalRef = this._modalService.open(ModalPaymentComponent, {
      size: 'lg',
      backdrop: 'static',
      centered: true,
    });
    modalRef.componentInstance.title = "TEST";
    modalRef.componentInstance.isConfirm = "CONFIRM";
    modalRef.componentInstance.detail = "DETAIL";

  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Some Item',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }
}
