import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-tech-product-landing-page',
  templateUrl: './tech-product-landing-page.component.html',
  styleUrls: ['./tech-product-landing-page.component.scss']
})
export class TechProductLandingPageComponent implements OnInit {

  config: ICMSConfig = {
    apiPath: 'techandproduct',
    tableName: 'Content',
    pathUrl: '/cms/repove-tools/tech-product-landing',
    model: new Object(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.Title',
    formConfig: {
      techBannerTitle: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Tech Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.TechBannerTitle',
      },
      techBannerDescription: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Tech Banner Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.TechBannerDescription',
      },
      techBannerImage: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Tech Banner Background',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.TechBannerBackground',
      },

      techTitle1: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Tech Title one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.TechTitleOne',
      },
      techImage1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Tech Description one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.TechDescriptionOne',
      },
      techBackground1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Tech Background one',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.TechBackgroundOne',
      },
      techTitle2: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Tech Title two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.TechTitleTwo',
      },
      techImage2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Tech Description two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.TechDescriptionTwo',
      },
      techBackground2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Tech Background two',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.TechBackgroundTwo',
      },
      techTitle3: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Tech Title three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.TechTitleThree',
      },
      techImage3: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Tech Description three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.TechDescriptionThree',
      },
      techBackground3: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Tech Background three',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.TechBackgroundThree',
      },
      techTitle4: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Tech Title four',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.TechTitleFour',
      },
      techImage4: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Tech Description four',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.TechDescriptionFour',
      },
      techSubImage4: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Tech Description four',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.TechDescriptionFour',
      },
      techBackground4: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Tech Background four',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.TechBackgroundFour',
      },

      productBannerTitle: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Product Banner Title',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.ProductBannerTitle',
      },
      productBannerDescription: {
        type: FormType.Textarea,
        isMultipleLang: true,
        validator: [Validators.required],
        // label: 'Product Banner Description',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.ProductBannerDescription',
      },
      productBannerImage: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        // label: 'Product Banner Background',
        labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.ProductBannerBackground',
      },

      // productTitle1: {
      //   type: FormType.Text,
      //   isMultipleLang: true,
      //   validator: [Validators.required],
      //   // label: 'Product Title one',
      //   labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.ProductTitleOne',
      // },
      // productBackground1: {
      //   type: FormType.SingleImage,
      //   validator: [Validators.required],
      //   // label: 'Product Background one',
      //   labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.ProductBackgroundOne',
      // },
      // productTitle2: {
      //   type: FormType.Text,
      //   isMultipleLang: true,
      //   validator: [Validators.required],
      //   // label: 'Product Title two',
      //   labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.ProductTitleTwo',
      // },
      // productBackground2: {
      //   type: FormType.SingleImage,
      //   validator: [Validators.required],
      //   // label: 'Product Background two',
      //   labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.ProductBackgroundTwo',
      // },
      // productTitle3: {
      //   type: FormType.Text,
      //   isMultipleLang: true,
      //   validator: [Validators.required],
      //   // label: 'Product Title three',
      //   labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.ProductTitleThree',
      // },
      // productBackground3: {
      //   type: FormType.SingleImage,
      //   validator: [Validators.required],
      //   // label: 'Product Background three',
      //   labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.ProductBackgroundThree',
      // },
      // productTitle4: {
      //   type: FormType.Text,
      //   isMultipleLang: true,
      //   validator: [Validators.required],
      //   // label: 'Product Title four',
      //   labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.ProductTitleFour',
      // },
      // productBackground4: {
      //   type: FormType.SingleImage,
      //   validator: [Validators.required],
      //   // label: 'Product Background four',
      //   labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.ProductBackgroundFour',
      // },
      // productTitle5: {
      //   type: FormType.Text,
      //   isMultipleLang: true,
      //   validator: [Validators.required],
      //   // label: 'Product Title five',
      //   labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.ProductTitleFive',
      // },
      // productBackground5: {
      //   type: FormType.SingleImage,
      //   validator: [Validators.required],
      //   // label: 'Product Background five',
      //   labelTranslate: 'BreadCrumbs.CMS.RepoveTools.TechAndProductLanding.ProductBackgroundFive',
      // },
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
