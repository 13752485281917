<div class="lang-switcher-sidebar nav-vertical" *ngIf="isUseMultipleLang">
    <ul ngbNav class="nav nav-pills flex-column text-nowrap mb-0">
        <li ngbNavItem *ngFor="let lang of langList">
            <a ngbNavLink (click)="selectedLanguage(lang)" [class.active]="selectedLang === lang">{{ lang | uppercase
                }}</a>
        </li>
    </ul>
</div>

<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        
        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <a [routerLink]="[pathUrl]">
                    <i data-feather="arrow-left" class="text-primary cursor-pointer back-to-manage-btn"></i>
                    {{ "ProductForm.Product" | translate }}
                </a>

                <ul ngbNav class="nav nav-pills">
                    <li ngbNavItem *ngFor="let lang of langList">
                        <a ngbNavLink (click)="selectedLanguage(lang)" [class.active]="selectedLang === lang">{{ lang |
                            uppercase
                            }}</a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="col-md-12">
                    <form [formGroup]="productForm">

                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label for="colFormLabelLg">
                                    {{ "ProductForm.PCRow" | translate }}
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-sm-9">
                                <input type="number" class="form-control" formControlName="PcRowPosition" rows="5"
                                    [ngClass]="{
                                    'is-invalid error':
                                    (isSubmit && f.PcRowPosition?.invalid) ||
                                    (f.PcRowPosition?.invalid && (f.PcRowPosition?.dirty || f.PcRowPosition?.touched))
                                    }" [placeholder]="'Form.EnterDescription' | translate">
                                <div class="invalid-feedback">
                                    <div *ngIf="
                                        isSubmit ||
                                        (f.PcRowPosition.invalid &&
                                        (f.PcRowPosition.dirty || f.PcRowPosition.touched))
                                    ">
                                        {{ "Form.Required" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label for="colFormLabelLg">
                                    {{ "ProductForm.PCColumn" | translate }}
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-sm-9">
                                <input type="number" class="form-control" formControlName="PcColumnPosition" rows="5"
                                    [ngClass]="{
                                    'is-invalid error':
                                    (isSubmit && f.PcColumnPosition?.invalid) ||
                                    (f.PcColumnPosition?.invalid && (f.PcColumnPosition?.dirty || f.PcColumnPosition?.touched))
                                    }" [placeholder]="'Form.EnterDescription' | translate">
                                <div class="invalid-feedback">
                                    <div *ngIf="
                                        isSubmit ||
                                        (f.PcColumnPosition.invalid &&
                                        (f.PcColumnPosition.dirty || f.PcColumnPosition.touched))
                                    ">
                                        {{ "Form.Required" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label for="colFormLabelLg">
                                    {{ "ProductForm.MBRow" | translate }}
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-sm-9">
                                <input type="number" class="form-control" formControlName="MbRowPosition" rows="5"
                                    [ngClass]="{
                                    'is-invalid error':
                                    (isSubmit && f.MbRowPosition?.invalid) ||
                                    (f.MbRowPosition?.invalid && (f.MbRowPosition?.dirty || f.MbRowPosition?.touched))
                                    }" [placeholder]="'Form.EnterDescription' | translate">
                                <div class="invalid-feedback">
                                    <div *ngIf="
                                        isSubmit ||
                                        (f.MbRowPosition.invalid &&
                                        (f.MbRowPosition.dirty || f.MbRowPosition.touched))
                                    ">
                                        {{ "Form.Required" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label for="colFormLabelLg">
                                    {{ "ProductForm.MBColumn" | translate }}
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-sm-9">
                                <input type="number" class="form-control" formControlName="MbColumnPosition" rows="5"
                                    [ngClass]="{
                                    'is-invalid error':
                                    (isSubmit && f.MbColumnPosition?.invalid) ||
                                    (f.MbColumnPosition?.invalid && (f.MbColumnPosition?.dirty || f.MbColumnPosition?.touched))
                                    }" [placeholder]="'Form.EnterDescription' | translate">
                                <div class="invalid-feedback">
                                    <div *ngIf="
                                        isSubmit ||
                                        (f.MbColumnPosition.invalid &&
                                        (f.MbColumnPosition.dirty || f.MbColumnPosition.touched))
                                    ">
                                        {{ "Form.Required" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="colFormLabelLg" class="col-sm-3 col-form-label">
                                {{ "ProductForm.LandingCoverImage" | translate }}
                                <span class="text-danger">*</span></label>
                            <div class="col-sm-9">
                                <app-image-upload #landingImageUploadComponent id="landingImage"></app-image-upload>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="colFormLabelLg" class="col-sm-3 col-form-label">
                                {{ "ProductForm.PresenterImage" | translate }}
                                <span class="text-danger">*</span></label>
                            <div class="col-sm-9">
                                <app-image-upload #imageUploadComponent></app-image-upload>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="colFormLabelLg" class="col-sm-3 col-form-label">
                                {{ "ProductForm.ProductImage" | translate }}
                                <span class="text-danger">*</span></label>
                            <div class="col-sm-9">
                                <app-image-upload #productImageUploadComponent id="productImage"></app-image-upload>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label for="colFormLabelLg">
                                    {{ "ProductForm.ThaiName" | translate }}
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" formControlName="productNameTH" rows="5"
                                    [ngClass]="{
                                    'is-invalid error':
                                    (isSubmit && f.productNameTH?.invalid) ||
                                    (f.productNameTH?.invalid && (f.productNameTH?.dirty || f.productNameTH?.touched))
                                    }" [placeholder]="'Form.EnterDescription' | translate">
                                <div class="invalid-feedback">
                                    <div *ngIf="
                                        isSubmit ||
                                        (f.productNameTH.invalid &&
                                        (f.productNameTH.dirty || f.productNameTH.touched))
                                    ">
                                        {{ "Form.EnterName" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label for="colFormLabelLg">
                                    {{ "ProductForm.EnglishName" | translate }}
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" formControlName="productNameEN" rows="5"
                                    [ngClass]="{
                                    'is-invalid error':
                                    (isSubmit && f.productNameEN?.invalid) ||
                                    (f.productNameEN?.invalid && (f.productNameEN?.dirty || f.productNameEN?.touched))
                                    }" [placeholder]="'Form.EnterDescription' | translate">
                                <div class="invalid-feedback">
                                    <div *ngIf="
                                        isSubmit ||
                                        (f.productNameEN.invalid &&
                                        (f.productNameEN.dirty || f.productNameEN.touched))
                                    ">
                                        {{ "Form.EnterName" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label for="colFormLabelLg">
                                    {{ "ProductForm.Price" | translate }}
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" formControlName="price" rows="5" [ngClass]="{
                                    'is-invalid error':
                                    (isSubmit && f.price?.invalid) ||
                                    (f.price?.invalid && (f.price?.dirty || f.price?.touched))
                                    }" [placeholder]="'Form.EnterDescription' | translate">
                                <div class="invalid-feedback">
                                    <div *ngIf="
                                        isSubmit ||
                                        (f.price.invalid &&
                                        (f.price.dirty || f.price.touched))
                                    ">
                                        {{ "Form.Required" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label for="colFormLabelLg">
                                    {{ "ProductForm.Volume" | translate }}
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" formControlName="volume" rows="5" [ngClass]="{
                                    'is-invalid error':
                                    (isSubmit && f.volume?.invalid) ||
                                    (f.volume?.invalid && (f.volume?.dirty || f.volume?.touched))
                                    }" [placeholder]="'Form.EnterDescription' | translate">
                                <div class="invalid-feedback">
                                    <div *ngIf="
                                        isSubmit ||
                                        (f.volume.invalid &&
                                        (f.volume.dirty || f.volume.touched))
                                    ">
                                        {{ "Form.Required" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div formGroupName="productDetail">
                            <div *ngFor="let lang of langList">
                                <div class="form-group row" [class.d-none]="selectedLang != lang">
                                    <div class="col-sm-3 col-form-label">
                                        <label for="colFormLabelLg">
                                            {{ "ProductForm.ProductDetail" | translate }}
                                            <span class="text-danger">*</span>
                                        </label>
                                        <br />
                                        <div *ngIf="langList.length > 1" class="badge badge-primary">
                                            {{ lang | uppercase }}
                                        </div>
                                    </div>
                                    <div class="col-sm-9">
                                        <textarea type="text" class="form-control" [formControlName]="lang" rows="5"
                                            [ngClass]="{
                                    'is-invalid error':
                                        (isSubmit && f.productDetail?.invalid) ||
                                        (f.productDetail?.invalid &&
                                        (f.productDetail?.dirty || f.productDetail?.touched))
                                    }" [placeholder]="'Form.EnterDescription' | translate"></textarea>
                                        <div class="invalid-feedback">
                                            <div *ngIf="
                                        isSubmit ||
                                        (f.productDetail.invalid &&
                                        (f.productDetail.dirty || f.productDetail.touched))
                                    ">
                                                {{ "Handle.DescriptionAllLanguage" | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr>

                        <div class="form-group row">
                            <label for="colFormLabelLg" class="col-sm-3 col-form-label">
                                {{ "ProductForm.UsingDetailList" | translate }}</label>
                            <div class="col-sm-9">
                                <div class="custom-control custom-switch custom-control-inline col-form-label">
                                    <input type="checkbox" class="custom-control-input" id="isUsingDetailList"
                                        formControlName="isUsingDetailList" />
                                    <label class="custom-control-label" for="isUsingDetailList"></label>
                                </div>
                            </div>
                        </div>

                        <div formArrayName="detailList">
                            <div *ngFor="let list of detailListForm.controls | keyvalue, let ind = index">
                                <div [formGroupName]="ind">

                                    <div class="form-group row">
                                        <label for="colFormLabelLg" class="col-sm-3 col-form-label">
                                            {{ "ProductForm.DetailIcon" | translate }} {{ind + 1}}
                                            <!-- <span class="text-danger">*</span> -->
                                        </label>
                                        <div class="col-sm-9">
                                            <app-image-upload #detailImageUploadComponent id="detailList{{ind}}"
                                                [ngClass]="{'disabled-form': f.isUsingDetailList?.value == false}">
                                            </app-image-upload>
                                        </div>
                                    </div>

                                    <div *ngFor="let lang of langList">
                                        <div [class.d-none]="selectedLang != lang">
                                            <!-- Title -->
                                            <div formGroupName="title" class="form-group row"
                                                *ngIf="selectedLang == lang">
                                                <div class="col-sm-3 col-form-label">
                                                    <label for="colFormLabelLg">
                                                        {{ "ProductForm.Title" | translate }}
                                                        <!-- <span class="text-danger">*</span> -->
                                                    </label>
                                                    <br />
                                                    <div *ngIf="langList.length > 1" class="badge badge-primary">
                                                        {{ lang | uppercase }}
                                                    </div>
                                                </div>
                                                <div class="col-sm-9">
                                                    <!-- <input type="text" class="form-control" [formControlName]="lang" rows="5" [ngClass]="{
                                                    'is-invalid error': (isSubmit && f.detailList?.invalid) || (f.detailList?.invalid && (f.detailList?.dirty || f.detailList?.touched))
                                                    }" [placeholder]="'Form.EnterDescription' | translate"> -->
                                                    <input type="text" class="form-control" [formControlName]="lang"
                                                        rows="5"
                                                        [ngClass]="{'disabled-form': f.isUsingDetailList?.value == false}"
                                                        [placeholder]="'Form.EnterDescription' | translate">
                                                    <!-- <div class="invalid-feedback">
                                                    <div
                                                        *ngIf="isSubmit || (f.detailList.invalid && (f.detailList.dirty || f.detailList.touched))">
                                                        {{ "Handle.DescriptionAllLanguage" | translate }}
                                                    </div>
                                                </div> -->
                                                </div>
                                            </div>

                                            <!-- Description-->
                                            <div formGroupName="detail" class="form-group row"
                                                *ngIf="selectedLang == lang">
                                                <div class="col-sm-3 col-form-label">
                                                    <label for="colFormLabelLg">
                                                        {{ "ProductForm.Detail" | translate }}
                                                        <!-- <span class="text-danger">*</span> -->
                                                    </label>
                                                    <br />
                                                    <div *ngIf="langList.length > 1" class="badge badge-primary">
                                                        {{ lang | uppercase }}
                                                    </div>
                                                </div>
                                                <div class="col-sm-9">
                                                    <!-- <input type="text" class="form-control" [formControlName]="lang" rows="5" [ngClass]="{
                                                    'is-invalid error': (isSubmit && f.detailList?.invalid) || (f.detailList?.invalid && (f.detailList?.dirty || f.detailList?.touched))
                                                    }" [placeholder]="'Form.EnterDescription' | translate"> -->
                                                    <textarea type="text" class="form-control" [formControlName]="lang"
                                                        rows="5"
                                                        [ngClass]="{'disabled-form': f.isUsingDetailList?.value == false}"
                                                        [placeholder]="'Form.EnterDescription' | translate"></textarea>
                                                    <!-- <div class="invalid-feedback">
                                                    <div
                                                        *ngIf="isSubmit || (f.detailList.invalid && (f.detailList.dirty || f.detailList.touched))">
                                                        {{ "Handle.DescriptionAllLanguage" | translate }}
                                                    </div>
                                                </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr>

                        <div class="form-group row">
                            <label for="colFormLabelLg" class="col-sm-3 col-form-label">
                                {{ "ProductForm.TechnologyCoverImage" | translate }}
                                <span class="text-danger">*</span></label>
                            <div class="col-sm-9">
                                <app-image-upload #technologyImageUploadComponent id="technologyImage"></app-image-upload>
                            </div>
                        </div>

                        <div formGroupName="techDetail">
                            <div *ngFor="let lang of langList">
                                <div class="form-group row" [class.d-none]="selectedLang != lang">
                                    <div class="col-sm-3 col-form-label">
                                        <label for="colFormLabelLg">
                                            {{ "ProductForm.TechnologyDetail" | translate }}
                                            <span class="text-danger">*</span>
                                        </label>
                                        <br />
                                        <div *ngIf="langList.length > 1" class="badge badge-primary">
                                            {{ lang | uppercase }}
                                        </div>
                                    </div>
                                    <div class="col-sm-9">
                                        <textarea type="text" class="form-control" [formControlName]="lang" rows="5"
                                            [ngClass]="{
                                    'is-invalid error':
                                        (isSubmit && f.techDetail?.invalid) ||
                                        (f.techDetail?.invalid &&
                                        (f.techDetail?.dirty || f.techDetail?.touched))
                                    }" [placeholder]="'Form.EnterDescription' | translate"></textarea>
                                        <div class="invalid-feedback">
                                            <div *ngIf="
                                        isSubmit ||
                                        (f.techDetail.invalid &&
                                        (f.techDetail.dirty || f.techDetail.touched))
                                    ">
                                                {{ "Handle.DescriptionAllLanguage" | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr>

                        <div formGroupName="featureTechTitle">
                            <div *ngFor="let lang of langList">
                                <div class="form-group row" [class.d-none]="selectedLang != lang">
                                    <div class="col-sm-3 col-form-label">
                                        <label for="colFormLabelLg">
                                            {{ "ProductForm.FeatureTechTitle" | translate }}
                                            <span class="text-danger">*</span>
                                        </label>
                                        <br />
                                        <div *ngIf="langList.length > 1" class="badge badge-primary">
                                            {{ lang | uppercase }}
                                        </div>
                                    </div>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" [formControlName]="lang" rows="5"
                                            [ngClass]="{
                                    'is-invalid error':
                                        (isSubmit && f.featureTechTitle?.invalid) ||
                                        (f.featureTechTitle?.invalid &&
                                        (f.featureTechTitle?.dirty || f.featureTechTitle?.touched))
                                    }" [placeholder]="'Form.EnterDescription' | translate">
                                        <div class="invalid-feedback">
                                            <div *ngIf="
                                        isSubmit ||
                                        (f.featureTechTitle.invalid &&
                                        (f.featureTechTitle.dirty || f.featureTechTitle.touched))
                                    ">
                                                {{ "Handle.DescriptionAllLanguage" | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="colFormLabelLg" class="col-sm-3 col-form-label">
                                {{ "ProductForm.UsingLabel" | translate }}</label>
                            <div class="col-sm-9">
                                <div class="custom-control custom-switch custom-control-inline col-form-label">
                                    <input type="checkbox" class="custom-control-input" id="isUsingLabel"
                                        formControlName="isUsingLabel" />
                                    <label class="custom-control-label" for="isUsingLabel"></label>
                                </div>
                            </div>
                        </div>

                        <div formArrayName="featureTech">
                            <div *ngFor="let list of featureFormArray.controls, let ind = index">
                                <div [formGroupName]="ind">

                                    <div class="form-group row">
                                        <div class="col-md-11">
                                            <div class="form-group row">
                                                <label for="colFormLabelLg" class="col-sm-3 col-form-label">
                                                    {{ "ProductForm.Icon" | translate }}
                                                    <span class="text-danger">*</span></label>
                                                <div class="col-sm-9">
                                                    <app-image-upload #featureImageUploadComponent
                                                        id="featureTech{{ind}}"></app-image-upload>
                                                </div>
                                            </div>

                                            <div *ngFor="let lang of langList">
                                                <div [class.d-none]="selectedLang != lang">
                                                    <!-- Title -->
                                                    <div formGroupName="label" class="form-group row"
                                                        *ngIf="selectedLang == lang">
                                                        <div class="col-sm-3 col-form-label">
                                                            <label for="colFormLabelLg">
                                                                {{ "ProductForm.Label" | translate }}
                                                                <!-- <span class="text-danger">*</span> -->
                                                            </label>
                                                            <br />
                                                            <div *ngIf="langList.length > 1"
                                                                class="badge badge-primary">
                                                                {{ lang | uppercase }}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-9">
                                                            <!-- <input type="text" class="form-control" [formControlName]="lang" rows="5"
                                                            [ngClass]="{
                                                                'is-invalid error': (isSubmit && f.featureTech?.invalid) || (f.featureTech?.invalid && (f.featureTech?.dirty || f.featureTech?.touched))
                                                            }" [placeholder]="'Form.EnterDescription' | translate"> -->
                                                            <input type="text" class="form-control"
                                                                [formControlName]="lang" rows="5"
                                                                [ngClass]="{'disabled-form': f.isUsingLabel?.value == false}"
                                                                [placeholder]="'Form.EnterDescription' | translate">
                                                            <div class="invalid-feedback">
                                                                <div
                                                                    *ngIf="isSubmit || (f.featureTech.invalid && (f.featureTech.dirty || f.featureTech.touched))">
                                                                    {{ "Handle.DescriptionAllLanguage" | translate }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Description-->
                                                    <div formGroupName="detail" class="form-group row"
                                                        *ngIf="selectedLang == lang">
                                                        <div class="col-sm-3 col-form-label">
                                                            <label for="colFormLabelLg">
                                                                {{ "ProductForm.Detail" | translate }}
                                                                <span class="text-danger">*</span>
                                                            </label>
                                                            <br />
                                                            <div *ngIf="langList.length > 1"
                                                                class="badge badge-primary">
                                                                {{ lang | uppercase }}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-9">
                                                            <textarea type="text" class="form-control"
                                                                [formControlName]="lang" rows="5" [ngClass]="{
                                                                    'is-invalid error': (isSubmit && f.featureTech?.invalid) || (f.featureTech?.invalid && (f.featureTech?.dirty || f.featureTech?.touched))
                                                                }" [placeholder]="'Form.EnterDescription' | translate"></textarea>
                                                            <div class="invalid-feedback">
                                                                <div
                                                                    *ngIf="isSubmit || (f.featureTech.invalid && (f.featureTech.dirty || f.featureTech.touched))">
                                                                    {{ "Handle.DescriptionAllLanguage" | translate }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">
                                            <a *ngIf="featureFormArray.length > 1" class="remove-button btn btn-primary"
                                                (click)="removeRow(true, ind)">
                                                <i data-feather="x"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-4"></div>
                            <div class="col-sm-4" style="text-align-last: center; padding-bottom: 10px">
                                <a class="btn btn-outline-primary mr-1 text-primary"
                                    (click)="addFeatureTechItemRow(featureFormArray.length + 1)">
                                    <i data-feather="plus"></i>{{ "DocTable.Button.AddItem" | translate }}
                                </a>
                            </div>
                            <div class="col-sm-4"></div>
                        </div>

                        <hr>

                        <div formGroupName="otherFeatureTechTitle">
                            <div *ngFor="let lang of langList">
                                <div class="form-group row" [class.d-none]="selectedLang != lang">
                                    <div class="col-sm-3 col-form-label">
                                        <label for="colFormLabelLg">
                                            {{ "ProductForm.OtherFeatureTechTitle" | translate }}
                                            <span class="text-danger">*</span>
                                        </label>
                                        <br />
                                        <div *ngIf="langList.length > 1" class="badge badge-primary">
                                            {{ lang | uppercase }}
                                        </div>
                                    </div>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" [formControlName]="lang" rows="5"
                                            [ngClass]="{
                                    'is-invalid error':
                                        (isSubmit && f.otherFeatureTechTitle?.invalid) ||
                                        (f.otherFeatureTechTitle?.invalid &&
                                        (f.otherFeatureTechTitle?.dirty || f.otherFeatureTechTitle?.touched))
                                    }" [placeholder]="'Form.EnterDescription' | translate">
                                        <div class="invalid-feedback">
                                            <div *ngIf="
                                        isSubmit ||
                                        (f.otherFeatureTechTitle.invalid &&
                                        (f.otherFeatureTechTitle.dirty || f.otherFeatureTechTitle.touched))
                                    ">
                                                {{ "Handle.DescriptionAllLanguage" | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div formArrayName="otherFeatureTech">
                            <div *ngFor="let list of otherFeatureFormArray.controls, let ind = index">
                                <div [formGroupName]="ind">

                                    <div class="form-group row">
                                        <div class="col-md-11">
                                            <div class="form-group row">
                                                <label for="colFormLabelLg" class="col-sm-3 col-form-label">
                                                    {{ "ProductForm.Icon" | translate }}
                                                    <span class="text-danger">*</span></label>
                                                <div class="col-sm-9">
                                                    <app-image-upload #otherFeatureImageUploadComponent
                                                        id="otherFeatureTech{{ind}}"></app-image-upload>
                                                </div>
                                            </div>

                                            <div *ngFor="let lang of langList">
                                                <div [class.d-none]="selectedLang != lang">

                                                    <!-- Description-->
                                                    <div formGroupName="detail" class="form-group row"
                                                        *ngIf="selectedLang == lang">
                                                        <div class="col-sm-3 col-form-label">
                                                            <label for="colFormLabelLg">
                                                                {{ "ProductForm.Detail" | translate }}
                                                                <span class="text-danger">*</span>
                                                            </label>
                                                            <br />
                                                            <div *ngIf="langList.length > 1"
                                                                class="badge badge-primary">
                                                                {{ lang | uppercase }}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-9">
                                                            <textarea type="text" class="form-control"
                                                                [formControlName]="lang" rows="5" [ngClass]="{
                                                                    'is-invalid error': (isSubmit && f.otherFeatureTech?.invalid) || (f.otherFeatureTech?.invalid && (f.otherFeatureTech?.dirty || f.otherFeatureTech?.touched))
                                                                }" [placeholder]="'Form.EnterDescription' | translate"></textarea>
                                                            <div class="invalid-feedback">
                                                                <div
                                                                    *ngIf="isSubmit || (f.otherFeatureTech.invalid && (f.otherFeatureTech.dirty || f.otherFeatureTech.touched))">
                                                                    {{ "Handle.DescriptionAllLanguage" | translate }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">
                                            <a *ngIf="otherFeatureFormArray.length > 1"
                                                class="remove-button btn btn-primary" (click)="removeRow(false, ind)">
                                                <i data-feather="x"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-4"></div>
                            <div class="col-sm-4" style="text-align-last: center; padding-bottom: 10px">
                                <a class="btn btn-outline-primary mr-1 text-primary"
                                    (click)="addOtherFeatureTechItemRow(otherFeatureFormArray.length + 1)">
                                    <i data-feather="plus"></i>{{ "DocTable.Button.AddItem" | translate }}
                                </a>
                            </div>
                            <div class="col-sm-4"></div>
                        </div>

                        <hr>

                        <div formGroupName="componentDetail">
                            <div *ngFor="let lang of langList">
                                <div class="form-group row" [class.d-none]="selectedLang != lang">
                                    <div class="col-sm-3 col-form-label">
                                        <label for="colFormLabelLg">
                                            {{ "ProductForm.ComponentDetail" | translate }}
                                            <span class="text-danger">*</span>
                                        </label>
                                        <br />
                                        <div *ngIf="langList.length > 1" class="badge badge-primary">
                                            {{ lang | uppercase }}
                                        </div>
                                    </div>
                                    <div class="col-sm-9">
                                        <textarea class="form-control" [ngxSummernote]="summernoteConfig"
                                            [formControlName]="lang" [ngClass]="{
                                    'is-invalid error':
                                        (isSubmit && f.componentDetail?.invalid) ||
                                        (f.componentDetail?.invalid &&
                                        (f.componentDetail?.dirty || f.componentDetail?.touched))
                                    }" [placeholder]="'Form.EnterDescription' | translate"></textarea>
                                        <div class="invalid-feedback">
                                            <div *ngIf="
                                            isSubmit ||
                                            (f.componentDetail.invalid &&
                                            (f.componentDetail.dirty || f.componentDetail.touched))
                                        ">
                                                {{ "Handle.DescriptionAllLanguage" | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr>

                        <div formGroupName="testResult">
                            <div *ngFor="let lang of langList">
                                <div class="form-group row" [class.d-none]="selectedLang != lang">
                                    <div class="col-sm-3 col-form-label">
                                        <label for="colFormLabelLg">
                                            {{ "ProductForm.TestResult" | translate }}
                                            <span class="text-danger">*</span>
                                        </label>
                                        <br />
                                        <div *ngIf="langList.length > 1" class="badge badge-primary">
                                            {{ lang | uppercase }}
                                        </div>
                                    </div>
                                    <div class="col-sm-9">
                                        <textarea class="form-control" [ngxSummernote]="summernoteConfig"
                                            [formControlName]="lang" [ngClass]="{
                                    'is-invalid error':
                                        (isSubmit && f.testResult?.invalid) ||
                                        (f.testResult?.invalid &&
                                        (f.testResult?.dirty || f.testResult?.touched))
                                    }" [placeholder]="'Form.EnterDescription' | translate"></textarea>
                                        <div class="invalid-feedback">
                                            <div *ngIf="
                                            isSubmit ||
                                            (f.testResult.invalid &&
                                            (f.testResult.dirty || f.testResult.touched))
                                        ">
                                                {{ "Handle.DescriptionAllLanguage" | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div formGroupName="testResultTB">
                            <div *ngFor="let lang of langList">
                                <div class="form-group row" [class.d-none]="selectedLang != lang">
                                    <div class="col-sm-3 col-form-label">
                                        <label for="colFormLabelLg">
                                            {{ "ProductForm.TBTestResult" | translate }}
                                            <span class="text-danger">*</span>
                                        </label>
                                        <br />
                                        <div *ngIf="langList.length > 1" class="badge badge-primary">
                                            {{ lang | uppercase }}
                                        </div>
                                    </div>
                                    <div class="col-sm-9">
                                        <textarea class="form-control" [ngxSummernote]="summernoteConfig"
                                            [formControlName]="lang" [ngClass]="{
                                    'is-invalid error':
                                        (isSubmit && f.testResultTB?.invalid) ||
                                        (f.testResultTB?.invalid &&
                                        (f.testResultTB?.dirty || f.testResultTB?.touched))
                                    }" [placeholder]="'Form.EnterDescription' | translate"></textarea>
                                        <div class="invalid-feedback">
                                            <div *ngIf="
                                            isSubmit ||
                                            (f.testResultTB.invalid &&
                                            (f.testResultTB.dirty || f.testResultTB.touched))
                                        ">
                                                {{ "Handle.DescriptionAllLanguage" | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div formGroupName="testResultMB">
                            <div *ngFor="let lang of langList">
                                <div class="form-group row" [class.d-none]="selectedLang != lang">
                                    <div class="col-sm-3 col-form-label">
                                        <label for="colFormLabelLg">
                                            {{ "ProductForm.MBTestResult" | translate }}
                                            <span class="text-danger">*</span>
                                        </label>
                                        <br />
                                        <div *ngIf="langList.length > 1" class="badge badge-primary">
                                            {{ lang | uppercase }}
                                        </div>
                                    </div>
                                    <div class="col-sm-9">
                                        <textarea class="form-control" [ngxSummernote]="summernoteConfig"
                                            [formControlName]="lang" [ngClass]="{
                                    'is-invalid error':
                                        (isSubmit && f.testResultMB?.invalid) ||
                                        (f.testResultMB?.invalid &&
                                        (f.testResultMB?.dirty || f.testResultMB?.touched))
                                    }" [placeholder]="'Form.EnterDescription' | translate"></textarea>
                                        <div class="invalid-feedback">
                                            <div *ngIf="
                                        isSubmit ||
                                        (f.testResultMB.invalid &&
                                        (f.testResultMB.dirty || f.testResultMB.touched))
                                    ">
                                                {{ "Handle.DescriptionAllLanguage" | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr>

                        <div class="form-group row">
                            <label for="colFormLabelLg" class="col-sm-3 col-form-label">
                                {{ "ProductForm.BeforeAfterImage" | translate }}
                                <span class="text-danger">*</span></label>
                            <div class="col-sm-9">
                                <app-image-upload #beforeAfterImageUploadComponent id="beforeAfter0"></app-image-upload>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="colFormLabelLg" class="col-sm-3 col-form-label">
                                {{ "ProductForm.BeforeAfterImage" | translate }}
                                <span class="text-danger">*</span></label>
                            <div class="col-sm-9">
                                <app-image-upload #beforeAfterImageUploadComponent id="beforeAfter1"></app-image-upload>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="colFormLabelLg" class="col-sm-3 col-form-label">
                                {{ "ProductForm.BeforeAfterImage" | translate }}
                                <span class="text-danger">*</span></label>
                            <div class="col-sm-9">
                                <app-image-upload #beforeAfterImageUploadComponent id="beforeAfter2"></app-image-upload>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="colFormLabelLg" class="col-sm-3 col-form-label">
                                {{ "ProductForm.BeforeAfterImage" | translate }}
                                <span class="text-danger">*</span></label>
                            <div class="col-sm-9">
                                <app-image-upload #beforeAfterImageUploadComponent id="beforeAfter3"></app-image-upload>
                            </div>
                        </div>

                        <div class="auto-form-footer">
                            <a (click)="openCancelModal()" class="btn btn-secondary">{{'Form.Cancel' | translate}}</a>
                            <a (click)="openConfirmModal();" class="btn btn-primary">{{'Form.Submit' | translate}}</a>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>