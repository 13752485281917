import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ChartOptions } from '../executive-summary.component';
import { FlatpickrOptions } from 'ng2-flatpickr';
import Item from 'app/main/model/Item';
import { ApiService } from 'app/main/service/api.service';
import { CommonResponse } from 'app/main/model/CommonResponse';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { ComponentsService } from 'app/main/components/components.service';
import { FilesApiService } from 'app/main/service/files-api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-revenue-chart',
  templateUrl: './revenue-chart.component.html',
  styleUrls: ['./revenue-chart.component.scss'],
})
export class RevenueChartComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  @ViewChild('apexColumnChartRef') apexColumnChartRef: ElementRef;
  @ViewChild('apexLineChartRef') apexLineChartRef: ElementRef;

  @BlockUI() blockUI: NgBlockUI;

  public apexColumnChart: Partial<ChartOptions>;
  public apexLineChart: Partial<ChartOptions>;

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'range',
  };

  ngDate: Date[];
  startDate: string;
  endDate: string;
  yearList: any[] = [];
  targetYearList: any[] = [];
  selectedStartMonth: string = '';
  selectedEndMonth: string = '';
  selectedStartYear: number;
  selectedEndYear: number;
  minYear: any;
  maxYear: any;
  form: FormGroup;
  isEditData: boolean = false;

  selectedEndMonthOptions: any;
  selectedEndYearOptions: any;
  targetSelect: number = 4;
  targetYear: any = new Date().getFullYear();
  classifier: string = 'Baht';
  selectedRowIndex: number = -1;
  tableListData: Target[] = [];
  fetchListData: Target[] = [];

  currentTable: string = '';
  currentYearTable: string = '';
  currentYear: number = new Date().getFullYear();
  currentMonth: number = new Date().getMonth();
  currentLang: string;

  fileNameFromDate: string = '';
  fileNameToDate: string = '';

  months: { value: number; nameEN: string; nameTH: string }[] = [
    { value: 0, nameEN: 'January', nameTH: 'มกราคม' },
    { value: 1, nameEN: 'February', nameTH: 'กุมภาพันธ์' },
    { value: 2, nameEN: 'March', nameTH: 'มีนาคม' },
    { value: 3, nameEN: 'April', nameTH: 'เมษายน' },
    { value: 4, nameEN: 'May', nameTH: 'พฤษภาคม' },
    { value: 5, nameEN: 'June', nameTH: 'มิถุนายน' },
    { value: 6, nameEN: 'July', nameTH: 'กรกฎาคม' },
    { value: 7, nameEN: 'August', nameTH: 'สิงหาคม' },
    { value: 8, nameEN: 'September', nameTH: 'กันยายน' },
    { value: 9, nameEN: 'October', nameTH: 'ตุลาคม' },
    { value: 10, nameEN: 'November', nameTH: 'พฤศจิกายน' },
    { value: 11, nameEN: 'December', nameTH: 'ธันวาคม' },
  ];

  targetType: { value: number; name: string }[] = [
    // { value: 3, name: 'TargetAccum' },
    { value: 4, name: 'TargetSales' },
  ];

  chartColors = {
    line: {
      series1: '#00ffff',
      series2: '#ff9900',
    },
    column: {
      series1: '#1C4E80',
      series2: '#EA6A47',
      bg: '#f8d3ff',
    },
    success: {
      shade_100: '#7eefc7',
      shade_200: '#06774f',
    },
    donut: {
      series1: '#ffe700',
      series2: '#00d4bd',
      series3: '#826bf8',
      series4: '#2b9bf4',
      series5: '#FFA1A1',
    },
    area: {
      series3: '#a4f8cd',
      series2: '#60f2ca',
      series1: '#2bdac7',
    },
  };

  isInit: boolean = false;

  targetSalesDataSum: number[] = [90, 210, 265, 365, 445, 570, 745, 815];
  achievedSalesDataSum: number[] = [85, 185, 215, 255, 350, 380, 490, 552];

  currentDate: Date = new Date();
  default_startDate: string = moment(this.currentDate)
    .startOf('month')
    .subtract(11, 'months')
    .toISOString();
  default_endDate: string = moment(this.currentDate)
    .endOf('month')
    .toISOString();

  constructor(
    private apiService: ApiService,
    private _globalFuncService: GlobalFuncService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private _componentsService: ComponentsService,
    private fileService: FilesApiService
  ) {
    this.currentLang = this.translateService.currentLang;
    (this.form = this.formBuilder.group({
      targetValue: [0, Validators.required],
      graphTypeEnumId: [1, Validators.required],
      targetTypeEnumId: [4, Validators.required],
      startDate: [new Date(), Validators.required],
      endDate: [new Date(), Validators.required],
    })),
      this.setChart();
    this.getChartData(this.default_startDate, this.default_endDate);
    this.getDropdownYear();
    this.getTargetResultListByYear(this.targetYear);
  }

  setChart() {
    var self = this;
    this.apexColumnChart = {
      series: [
        {
          name: 'Target Rate',
          data: [],
        },
        {
          name: 'Achievement Rate',
          data: [],
        },
      ],
      chart: {
        type: 'line',
        height: 400,
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
      },
      plotOptions: {
        bar: {
          columnWidth: '10px',
          colors: {
            backgroundBarColors: [],
            backgroundBarRadius: 10,
          },
        },
      },
      colors: [
        this.chartColors.column.series1,
        this.chartColors.column.series2,
      ],
      dataLabels: {
        enabled: true,
        enabledOnSeries: [2, 3],
      },

      stroke: {
        show: true,
        width: [0, 2],
        curve: 'straight',
      },
      xaxis: {
        title: {
          text: 'Date/Month',
        },
        categories: [
          '31/01',
          '28/02',
          '31/03',
          '30/04',
          '31/05',
          '30/06',
          '31/07',
          '31/08',
          '30/09',
          '31/10',
          '30/11',
          '31/12',
        ],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return self._globalFuncService.FormatToMoney(value);
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return self._globalFuncService.FormatToMoney(val) + ` Baht`;
          },
        },
      },
      noData: {
        text: 'No data available.',
        align: 'center',
        verticalAlign: 'middle',
        offsetY: -20,
        offsetX: 20,
      },
    };

    this.apexLineChart = {
      series: [
        {
          name: 'Target Rate',
          type: 'line',
          data: this.targetSalesDataSum,
        },
        {
          name: 'Achievement Rate',
          type: 'line',
          data: this.achievedSalesDataSum,
        },
      ],
      chart: {
        type: 'line',
        height: 300,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
      },
      colors: [
        this.chartColors.column.series1,
        this.chartColors.column.series2,
      ],
      dataLabels: {
        enabled: false,
        // enabledOnSeries:[2]
      },
      stroke: {
        show: true,
        curve: 'straight',
      },

      yaxis: {
        labels: {
          formatter: function (value) {
            return self._globalFuncService.FormatToMoney(value);
            // return value.toString()
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return self._globalFuncService.FormatToMoney(val) + ` %`;
          },
        },
      },
      noData: {
        text: 'No data available.',
        align: 'center',
        verticalAlign: 'middle',
        offsetY: -10,
        offsetX: 20,
      },
    };
  }

  findMaxFromArrays(arrays: number[][]): number {
    if (arrays.length === 0) {
      return 0;
    }

    let min = Infinity;
    let max = -Infinity;

    for (let array of arrays) {
      if (array.length === 0) {
        return 0;
      }

      let arrayMax = Math.max(...array);

      if (arrayMax > max) {
        max = arrayMax;
      }
    }

    return this.roundUpToNearestPowerOfTen(max);
  }

  roundUpToNearestPowerOfTen(num: number): number {
    let digits = Math.floor(Math.log10(num));
    let powerOfTen = Math.pow(10, digits);

    let multiplier = 1;
    if (digits > 0) {
      multiplier = Math.ceil(num / powerOfTen);
    }

    return multiplier * powerOfTen;
  }

  onStartMonthChange() {
    this.selectedEndMonthOptions = this.generateEndMonthOptions(
      parseInt(this.selectedStartMonth)
    );
    this.selectedEndMonth = this.selectedStartMonth;
  }
  onStartYearChange() {
    this.selectedEndYear = this.selectedStartYear;
    this.selectedEndYearOptions = this.generateEndYearOptions(
      this.selectedStartYear
    );
    this.selectedStartMonth = '0';
    this.selectedEndMonthOptions = this.generateEndMonthOptions(
      parseInt(this.selectedStartMonth)
    );
    this.selectedEndMonth = '0';
  }
  onMonthChange() {
    this.selectedEndMonthOptions = this.generateEndMonthOptions(
      parseInt(this.selectedStartMonth)
    );
  }

  onYearChange() {
    this.selectedEndYearOptions = this.generateEndYearOptions(
      this.selectedStartYear
    );

    if (this.selectedStartYear === this.selectedEndYear) {
      this.selectedEndMonthOptions = this.generateEndMonthOptions(
        parseInt(this.selectedStartMonth)
      );
    } else if (this.selectedStartYear > this.selectedEndYear) {
      this.selectedEndMonth = null;
      this.selectedEndMonthOptions = this.months;
    } else {
      this.selectedEndMonthOptions = this.months;
    }
  }

  getDropdownYear() {
    this.apiService
      .GetAllData(
        'Dashboard/ForSearch/DropDown/GetNewestDataAndOldest/Customer'
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.minYear = new Date(res.data.resultData[0].min);
        this.maxYear = new Date(res.data.resultData[0].max);

        this.yearList = this.getRangeOfYears(
          this.minYear.getFullYear(),
          // this.maxYear.getFullYear()
          this.currentYear + 20
        );

        this.targetYearList = this.getRangeOfYears(
          this.minYear.getFullYear(),
          this.currentYear + 20
        );
      });
  }

  generateEndMonthOptions(
    startMonth: number
  ): { value: number; nameEN: string; nameTH: string }[] {
    console.log(this.selectedStartYear, this.selectedEndYear);

    if (!startMonth || this.selectedStartYear < this.selectedEndYear) {
      return this.months;
    }

    return this.months.filter((month) => month.value >= startMonth);
  }

  generateEndYearOptions(startYear: number): number[] {
    if (!startYear) {
      return this.yearList;
    }
    return this.yearList.filter((year) => year >= startYear);
  }

  getRangeOfYears(startYear: number, endYear: number): number[] {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  }

  submitDate() {
    this.startDate = moment({
      year: this.selectedStartYear,
      month: parseInt(this.selectedStartMonth),
    })
      .startOf('month')
      .toISOString();
    this.endDate = moment({
      year: this.selectedEndYear,
      month: parseInt(this.selectedEndMonth),
    })
      .endOf('month')
      .toISOString();

    this.getChartData(this.startDate, this.endDate);
  }
  resetSearchCriteria() {
    // Reset all search criteria
    this.selectedStartMonth = '';
    this.selectedEndMonth = '';
    this.selectedStartYear = null;
    this.selectedEndYear = null;
    this.selectedEndMonthOptions = [];
    this.selectedEndYearOptions = [];
    this.getChartData(this.default_startDate, this.default_endDate);
  }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((e) => {
      this.currentLang = this.translateService.currentLang;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.apexColumnChart = null;
    this.apexLineChart = null;
  }

  DrawChart() {
    // this.apexColumnChart.chart.width =
    //   this.apexColumnChartRef?.nativeElement.offsetWidth;
    // this.apexLineChart.chart.width =
    //   this.apexColumnChartRef?.nativeElement.offsetWidth;

    window.dispatchEvent(new Event('resize'));
    this.isInit = true;
  }

  getChartData(startDate?: string, endDate?: string): Promise<void> {
    this.blockUI.start();
    startDate = startDate || '';
    endDate = endDate || '';
    return new Promise((resolve, rejects) => {
      this.apiService
        .GetAllData('Dashboard/ExecutiveSummary/Revenue', {
          dateFrom: startDate,
          dateTo: endDate,
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<ExecRevenueData>) => {
            this.updateChartData(res.data.resultData[0]);
            this.blockUI.stop();
          },
          (err) => {
            this.blockUI.stop();
          }
        );
    });
  }

  checkDataAvaliable(numArr: number[]): boolean {
    return !numArr.every((value) => value === 0);
  }

  updateChartData(data: ExecRevenueData) {
    var self = this;

    let formattedCategories = data.categoriesDateTimeList.map((date) => {
      let result: string = '';
      let tempdate = new Date(date);

      result = `${tempdate.toLocaleString('en-US', {
        month: 'short',
      })}/${tempdate.getFullYear()}`;
      return result;
    });

    //prepare file name for export excel
    let fromDate = new Date(data.categoriesDateTimeList[0]);
    let toDate = new Date(
      data.categoriesDateTimeList[data.categoriesDateTimeList.length - 1]
    );
    this.fileNameFromDate = `${fromDate.toLocaleString('en-US', {
      month: 'short',
    })}/${fromDate.getFullYear()}`;
    this.fileNameToDate = `${toDate.toLocaleString('en-US', {
      month: 'short',
    })}/${toDate.getFullYear()}`;

    let formattedColumnChartData = [
      {
        name: 'Target Sales',
        type: 'column',
        data: this.checkDataAvaliable(data.columnChart.revenueTarget)
          ? data.columnChart.revenueTarget
          : [],
      },
      {
        name: 'Achieved Sales',
        type: 'column',
        data: this.checkDataAvaliable(data.columnChart.revenueActual)
          ? data.columnChart.revenueActual
          : [],
      },
    ];

    let formattedLineChartData = [
      {
        name: 'Target Accum',
        type: 'line',
        data: this.checkDataAvaliable(data.lineChart.revenueTarget)
          ? data.lineChart.revenueTarget
          : [],
      },
      {
        name: 'Achieved Accum',
        type: 'line',
        data: this.checkDataAvaliable(data.lineChart.revenueActual)
          ? data.lineChart.revenueActual
          : [],
      },
    ];

    this.apexColumnChart.series = formattedColumnChartData;
    this.apexLineChart.series = formattedLineChartData;

    this.apexColumnChart.xaxis = {
      categories: formattedCategories,
    };

    this.apexLineChart.xaxis = {
      categories: formattedCategories,
    };
  }

  getMonthName(value: number) {
    if (this.currentLang == 'en') {
      return this.months.find((month) => month.value === value)?.nameEN || '';
    } else {
      return this.months.find((month) => month.value === value)?.nameTH || '';
    }
  }

  getTargetType(value: number) {
    return this.targetType.find((target) => target.value === value)?.name || '';
  }

  getDate(date: string) {
    return new Date(date);
  }

  getTargetResultListByYear(year?: number) {
    if (!year) {
      year = this.targetYear;
    }
    this.blockUI.start();
    this.tableListData = [];
    let TargetTypeEnumId = this.targetSelect;
    this.currentTable = this.getTargetType(Number(this.targetSelect));
    this.currentYearTable = this.targetYear;
    let searchData: any = {
      params: {
        graphTypeEnumId: 1,
        sortPath: 'startDate',
        TargetTypeEnumId: TargetTypeEnumId,
      },
    };

    let startDate: string = moment()
      .set('year', year)
      .startOf('year')
      .toISOString();
    let endDate: string = moment()
      .set('year', year)
      .endOf('year')
      .toISOString();

    searchData.params.startDate = startDate;
    searchData.params.endDate = endDate;
    searchData.params.targetTypeEnumId = this.targetSelect;
    this.apiService
      .get('Target', searchData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: CommonResponse<Target>) => {
          this.fetchListData = res.data.resultData;
          this.createTempDataList();
          this.blockUI.stop();
        },
        (err) => {
          this.blockUI.stop();
        }
      );
  }

  createTempDataList() {
    let year = this.targetYear;
    this.months.forEach((element) => {
      let month = element.value;
      this.tableListData.push({
        id: null,
        targetValue: 0,
        graphTypeEnumId: 1,
        targetTypeEnumId: this.targetSelect,
        startDate: moment()
          .set('year', year)
          .set('month', month)
          .startOf('month')
          .toISOString(),
        endDate: moment()
          .set('year', year)
          .set('month', month)
          .endOf('month')
          .toISOString(),
      });
    });
    this.findMatchData();
  }

  findMatchData() {
    this.fetchListData.forEach((element) => {
      // let tempData = this.tableListData.find(data => data.startDate === element.startDate)
      let index = new Date(element.startDate).getMonth();
      this.tableListData[index].id = element.id;
      this.tableListData[index].targetValue = element.targetValue;
    });
  }

  openUpdateDataModal(index) {
    const modalRef = this.modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = this.translateService.instant(
      'BonusPlan.UpdateTitle'
    );
    modalRef.componentInstance.detail =
      this.translateService.instant('BonusPlan.Update');
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.updateData(index);
      this.selectedRowIndex = -1;
      this.getTargetResultListByYear(this.targetYear);
    });
  }

  exportToExcel() {
    let dateFrom = this.startDate ? this.startDate : this.default_startDate;
    let dateTo = this.endDate ? this.endDate : this.default_endDate;
    let exportDate = new Date().toISOString();

    let fileName: string = `Revenue Report ${this.fileNameFromDate}-${this.fileNameToDate}`;

    let param = {
      DateFrom: dateFrom,
      DateTo: dateTo,
      ExportDate: exportDate,
    };

    this.fileService.getExcelReport(
      'Excel/ExportRevenueTarget',
      fileName,
      param
    );
  }

  editData(index: number): void {
    this.isEditData = true;

    this.selectedRowIndex = index;

    console.log(this.tableListData[index]);

    this.form.get('targetValue').disable();
    const originalData = this.tableListData[index];
    this.form = this.formBuilder.group({
      targetValue: originalData.targetValue,
      graphTypeEnumId: originalData.graphTypeEnumId,
      targetTypeEnumId: originalData.targetTypeEnumId,
      startDate: originalData.startDate,
      endDate: originalData.endDate,
    });
  }

  updateData(index: number): void {
    this.blockUI.start();

    var self = this;
    const updatedData = this.form.value;
    const targetValueToUpdate = this.tableListData[index];
    console.log(this.tableListData.some((a) => a.targetValue));

    updatedData.targetValue = parseInt(updatedData.targetValue, 10);

    if (targetValueToUpdate.id == null) {
      const formData = {
        ...updatedData,
        targetValue: updatedData.targetValue,
      };
      this.apiService
        .post('Target', formData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((newData: Target) => {
          this.tableListData.push(newData);
          this.form.reset();
          this.getTargetResultListByYear();
          if (
            this.selectedStartMonth == '' &&
            this.selectedEndMonth == '' &&
            this.selectedStartYear == null &&
            this.selectedEndYear == null
          ) {
            this.getChartData();
          } else {
            this.submitDate();
          }
          this.blockUI.stop();
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Update Success',
          });
        });
    } else if (updatedData.targetValue) {
      const formData = {
        ...updatedData,
        targetValue: updatedData.targetValue,
      };
      this.apiService
        .UpdateDataById('Target', targetValueToUpdate.id, formData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((newData: Target) => {
          this.tableListData.push(newData);
          this.form.reset();
          this.getTargetResultListByYear();
          if (
            this.selectedStartMonth == '' &&
            this.selectedEndMonth == '' &&
            this.selectedStartYear == null &&
            this.selectedEndYear == null
          ) {
            this.getChartData();
          } else {
            this.submitDate();
          }
          this.blockUI.stop();
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Update Success',
          });
        });
    } else {
      (error) => {
        console.error('Error adding data:', error);
        self._componentsService.ErrorSwal();
        this.blockUI.stop();
      };
    }

    this.blockUI.stop();
    this.isEditData = false;
  }

  cancelEdit(index: number): void {
    this.selectedRowIndex = -1;
    this.isEditData = false;
  }

  checkEditCondition(isoDate: string): boolean {
    let currentDate: Date = new Date();
    let tDate: Date = new Date(isoDate);

    if (tDate.getFullYear() < currentDate.getFullYear()) {
      return false;
    }

    if (
      tDate.getMonth() <= currentDate.getMonth() &&
      tDate.getFullYear() == currentDate.getFullYear()
    ) {
      return false;
    }

    return true;
  }
}

export interface ExecRevenueData {
  categoriesDateTimeList: string[];
  lineChart: LineChart;
  columnChart: ColumnChart;
}

export interface LineChart {
  revenueTarget: number[];
  revenueActual: number[];
}

export interface ColumnChart {
  revenueTarget: number[];
  revenueActual: number[];
  lineRevenueTarget: number[];
  lineRevenueActual: number[];
}

export interface Target {
  id?: string;
  targetValue: number;
  graphTypeEnumId: number;
  targetTypeEnumId: number;
  startDate: string;
  endDate: string;
}
