import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import CookiePolicy from 'app/main/model/CookiePolicy';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss'],
})
export class CookiePolicyComponent implements OnInit {
  config: ICMSConfig = {
    apiPath: 'CookiePolicy',
    tableName: 'Content',
    pathUrl: '/cms/cookie-policy',
    model: new CookiePolicy(),
    isList: false,
    componentName: 'Cookie Policy',
    formConfig: {
      cookieDate: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Date',
        description: 'Description of Cookie Policy',
        labelTranslate: 'Informative.CookiePolicy.Date.Label',
        descriptionTranslate: 'Informative.CookiePolicy.Date.Description',
      },
      cookieName: {
        type: FormType.Text,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Title',
        description: 'Description of Cookie Policy',
        labelTranslate: 'Informative.CookiePolicy.Title.Label',
        descriptionTranslate: 'Informative.CookiePolicy.Title.Description',
      },
      cookieDescription: {
        type: FormType.RichText,
        isMultipleLang: true,
        validator: [Validators.required],
        label: 'Description',
        description: 'Description of Cookie Policy',
        labelTranslate: 'Informative.CookiePolicy.Description.Label',
        descriptionTranslate:
          'Informative.CookiePolicy.Description.Description',
      },
    },
  };

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
