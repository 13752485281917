import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './landing-page.component';
import { RouterModule, Routes } from '@angular/router';
import { CmsFormpageModule } from '../../cms-formpage/cms-formpage.module';
import { AuthGuard } from 'app/auth/helpers';

const path: string = 'cms/repove-tools/landing';
const routes: Routes = [
  {
    path: path,
    component: LandingPageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-repove-tools-landing' },
  },
];

@NgModule({
  declarations: [
    LandingPageComponent
  ],
  imports: [CommonModule, RouterModule.forChild(routes), CmsFormpageModule],
  exports: [LandingPageComponent],
})
export class LandingPageModule { }
