import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { ModalComponent } from './modal/modal.component';
import { BehaviorSubject } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ComponentsService {
  @BlockUI() loadingScreen: NgBlockUI;
  contentListSubject = new BehaviorSubject([]);
  currentContentList = this.contentListSubject.asObservable();

  constructor(
    private _modalService: NgbModal,
    private _translateService: TranslateService,
    private _router: Router,
    private toastr: ToastrService
  ) {}

  setContentList(contentList: any[]): void {
    this.contentListSubject.next(contentList);
  }

  clearContentList(): void {
    this.contentListSubject.next([]);
  }

  SuccessSwal(
    title: string = this._translateService.instant('Alert.Success'),
    confirmButtonText: string = this._translateService.instant('Alert.Confirm'),
    showLinkDocButton?: boolean,
    linkDocButtonText?: string,
    childDocPath?: string,
    text?: string,
    childId?: string,
    callback?: () => void
  ): Promise<SweetAlertResult> {
    return new Promise((resolve) => {
      this.loadingScreen.stop();

      console.log('childDocPath : ', childDocPath);

      Swal.fire({
        title: title,
        icon: 'success',
        confirmButtonText: confirmButtonText,
        showCancelButton: showLinkDocButton,
        cancelButtonText: linkDocButtonText,
        text: text,
        customClass: {
          actions: 'my-actions',
          confirmButton: 'order-2',
          cancelButton: 'btn-warning right-gap order-1',
        },
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          this._router.navigate([childDocPath + '/' + childId]); /* รอ + id */
        }
        if (callback && result.isConfirmed) {
          callback(); // Execute the callback function when the confirm button is pressed
        }
        resolve(result);
      });
    });
  }

  ErrorSwal(
    title: string = this._translateService.instant('Alert.Error'),
    text: string = this._translateService.instant('Alert.SomethingWentWrong'),
    confirmButtonText: string = this._translateService.instant('Alert.Confirm'),
    html?: string
  ): void {
    this.loadingScreen.stop();

    Swal.fire({
      title: title,
      text: text,
      icon: 'error',
      confirmButtonText: confirmButtonText,
      html: html,
    });
  }

  toastrSuccess(title: string, detail: string) {
    return this.toastr.success(detail, title, {
      toastClass: 'toast ngx-toastr',
      closeButton: true,
    });
  }

  // Info
  toastrInfo(title: string, detail: string) {
    return this.toastr.info(detail, title, {
      toastClass: 'toast ngx-toastr',
      closeButton: true,
    });
  }

  // Warning
  toastrWarning(title: string, detail: string) {
    return this.toastr.warning(detail, title, {
      toastClass: 'toast ngx-toastr',
      closeButton: true,
    });
  }

  // Error
  toastrError(title: string, detail: string) {
    return this.toastr.error(detail, title, {
      toastClass: 'toast ngx-toastr',
      closeButton: true,
    });
  }

  toastrClear() {
    return this.toastr.clear();
  }
}
