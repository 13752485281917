import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../modal/modal.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/main/service/api.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { ComponentsService } from '../components.service';
import { PaymentAction } from 'app/main/model/PaymentAction';
import { TransferFormComponent } from './transfer-form/transfer-form.component';
import { ImageUploadComponent } from '../image-upload/image-upload.component';

@Component({
  selector: 'app-modal-manage-payment',
  templateUrl: './modal-manage-payment.component.html',
  styleUrls: ['./modal-manage-payment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalManagePaymentComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @ViewChild(TransferFormComponent)
  transferFormComponent: TransferFormComponent;

  @Input() public modalConfig: NgbModalConfig;
  @Input() actionPayment: string;
  @Input() editData: any = null;

  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();
  @Output() refreshData: EventEmitter<any> = new EventEmitter();

  @BlockUI() blockUI: NgBlockUI;

  formData: any;

  isEditing: boolean = false;

  isBankAccount: boolean = false;
  isCreditCard: boolean = false;

  isSubmit: boolean = false;
  isCheckInvalidForm: boolean = false;

  @ViewChild('imageUploadComponent') imageUploadComponent: ImageUploadComponent;

  constructor(
    public activeModal: NgbActiveModal,
    private _apiService: ApiService,
    private _formBuilder: FormBuilder,
    private _modalService: NgbModal,
    private _translateService: TranslateService,
    private _http: HttpClient,
    private _componentsService: ComponentsService,
    private _globalFuncService: GlobalFuncService
  ) {}

  ngOnInit(): void {
    this.checkActionPayment();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngAfterViewInit(): void {
    console.log(this.actionPayment);
  }

  checkActionPayment() {
    switch (this.actionPayment) {
      case PaymentAction.BankAccount.Create:
        this.isBankAccount = true;
        break;
      case PaymentAction.BankAccount.Edit:
        this.isBankAccount = true;
        this.isEditing = true;
        break;
      default:
        break;
    }
  }

  receiveFormData(data: any) {
    this.formData = data;
  }

  closeModal(): void {
    this.callBackFunc.emit();
    this.activeModal.close();
  }

  openCancelModal(): void {
    let isConfirm = false;
    let title: string;
    let detail: string;

    if (this.isEditing) {
      title = this._translateService.instant(
        'Payment.Modal.BankAccount.CancelEditTitle'
      );
      detail = this._translateService.instant(
        'Payment.Modal.BankAccount.CancelEdit'
      );
    } else {
      title = this._translateService.instant(
        'Payment.Modal.BankAccount.CancelCreateTitle'
      );
      detail = this._translateService.instant(
        'Payment.Modal.BankAccount.CancelCreate'
      );
    }

    this.openModal(title, detail, isConfirm);
  }

  openConfirmModal(): void {
    this.isCheckInvalidForm = this.transferFormComponent.checkInvalidForm();
    this.isSubmit = true;

    let title: string;
    let detail: string;
    let isConfirm = true;

    if (this.isEditing) {
      title = this._translateService.instant(
        'Payment.Modal.BankAccount.ConfirmEditTitle'
      );
      detail = this._translateService.instant(
        'Payment.Modal.BankAccount.ConfirmEdit'
      );
    } else {
      title = this._translateService.instant(
        'Payment.Modal.BankAccount.ConfirmCreateTitle'
      );
      detail = this._translateService.instant(
        'Payment.Modal.BankAccount.ConfirmCreate'
      );
    }

    if (!this.isCheckInvalidForm) {
      this.openModal(title, detail, isConfirm);
    } else {
      console.error('invalid form please fill all required input');
    }
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      if (IsConfirm) {
        this.submit(this.formData);
      } else {
        this.closeModal();
      }
    });
  }

  submit(data) {
    this.blockUI.start();
    const apiPath = 'BankAccount';

    if (this.isEditing) {
      this._apiService
        .UpdateDataById(apiPath, this.editData.id, data)
        .subscribe(
          (res) => {
            console.log('Editting Bank Account....');
            console.log(res);

            this.refreshData.emit(res.data.resultData);
            this.blockUI.stop();
            this.closeModal();
            const params = {
              isEdit: false,
              idResId: res.data.resultData[0],
              tablename: 'BankAccount',
              collectionName: 'qrCodeImage',
              ordinal: 0,
            };
            this.imageUploadComponent.uploadImage(params);
            this._componentsService.SuccessSwal();
          },
          (err) => {
            console.error('Editting Bank Account Error....');
            this.blockUI.stop();
            this._componentsService.ErrorSwal();
          }
        );
    } else {
      this._apiService.AddData(apiPath, data).subscribe(
        (res) => {
          console.log('Added New Bank Account....');
          console.log(res);

          this.blockUI.stop();
          this.closeModal();
          const params = {
            isEdit: false,
            idResId: res.data.resultData[0],
            tablename: 'BankAccount',
            collectionName: 'qrCodeImage',
            ordinal: 0,
          };
          this.imageUploadComponent.uploadImage(params);
          this._componentsService.SuccessSwal();
          this.refreshData.emit(res.data.resultData);
        },
        (err) => {
          console.error('Added New Bank Account Error....');
          this.blockUI.stop();
          this._componentsService.ErrorSwal();
        }
      );
    }
  }
}
