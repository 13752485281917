class SubClass {
  id: number;
  classId: number;
  subClassCode: string;
  name: string;
  taxRate: number;
  coverMediaFile: string;
  mediaFiles: string[];
  isActive: boolean;
  isDelete: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  deleteBy: string;
  deleteDate: string;
}

export default SubClass;
