import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from '../../../../../components/components.service';
import { ModalComponent } from '../../../../../components/modal/modal.component';
import { AccountApiService } from '../../../../../service/account-api.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  componentName: string;
  contentHeader: object;
  isLoading: boolean;

  confPassword: string;

  timeout: any = null;
  isTyping: boolean = false;

  isSubmitted:boolean;
  isConfPasswordMatch: boolean;

  changePasswordForm;

  constructor(
    private _translateService: TranslateService,
    private _accountApiService: AccountApiService,
    private _formBuilder: FormBuilder,
    private _componentsService: ComponentsService,
    private _modalService: NgbModal
  ) {
    this.changePasswordForm = this._formBuilder.group({
      newPassword: ['', [Validators.required]],
      oldPassword: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: this.componentName,
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  openConfirmModal(content: any): void {
    let isConfirm = true;
    let title = `Confirm  Content`;
    let detail = `Are you confirm to this `;

    this.openModal(title, detail, isConfirm);
  }

  openCancelModal(content: any): void {
    let isConfirm = false;
    let title = `Cancel  Content`;
    let detail = `Are you sure to cancel this `;

    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.isConfirm = IsConfirm;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.confirmUrl = '';
    modalRef.componentInstance.submitFunc.subscribe((res) => {
      if (IsConfirm) {
        this.Submit();
      }
    });
  }

  Submit(): void {
    this._accountApiService
      .UpdateAccountPassword(this.changePasswordForm)
      .subscribe((res) => {
        if (res.success) {
          this._componentsService.SuccessSwal();
          window.location.reload();
        } else {
          this._componentsService.ErrorSwal();
        }
      });
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var self = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        self.checkConfirmPassword();
      }
    }, 2000);
  }

  checkConfirmPassword(): void {
    let newPass = this.changePasswordForm.controls.newPassword.value;
    let confPass = this.confPassword;

    this.isConfPasswordMatch = newPass === confPass;
    console.log(this.isConfPasswordMatch)
  }
}
