import { HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import Item from 'app/main/model/Item';
import { ApiService } from 'app/main/service/api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Subject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { MovementTableComponent } from './movement-table/movement-table.component';
import { WarehouseTableComponent } from './warehouse-table/warehouse-table.component';

@Component({
  selector: 'app-stock-formpage',
  templateUrl: './stock-formpage.component.html',
  styleUrls: ['./stock-formpage.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StockFormpageComponent implements OnInit {
  contentHeader: object;
  componentName: string = 'Stock'; // fixed ไว้ก่อนนน
  itemObj: Item;
  childItemsList: Item[] = [];
  lastItemMovementObj: any;

  itemID: string;
  isEditing: boolean = false;
  isIncrease: boolean = true;

  isLoading: boolean = false;

  itemWareHouseList: any[] = [];
  itemMovementList: any[] = [];

  stockSeries: any[] = [];

  itemMovementSearchData: any = {};

  @Input() callbackUrl: string;

  @BlockUI() blockUI: NgBlockUI;

  adjustmentDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: new Date(),
    maxDate: new Date(),
  };

  prodDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: new Date(),
  };

  expDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: new Date(),
  };

  formgroup: FormGroup;

  @ViewChild('movementTable') movementTable: MovementTableComponent;
  @ViewChild('warehouseTable') warehouseTable: WarehouseTableComponent;

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = { searching: false, lengthChange: false };
  itemMovementDTOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _translateService: TranslateService,
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _modalService: NgbModal,
    private http: HttpClient
  ) {
    this._route.paramMap.subscribe((val) => {
      this.itemID = this._route.snapshot.paramMap.get('id');
      this.isEditing = this.itemID ? true : false;
    });

    this.formgroup = this._formBuilder.group({
      itemID: [null, []],
      isIncrease: [true, []],
    });

    this.initData();
  }

  initData(): void {
    this.blockUI.start();
    this.getItemData();
    this.getLastItemMovement();
  }

  reloadData(): void {
    this.getLastItemMovement();
    this.movementTable.loadData();
    this.warehouseTable.loadData();
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;
    this.contentHeader = {
      headerTitle: 'General.Stock',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  getItemData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService.GetDataById('item', this.itemID,{ verbose: false }).subscribe((res) => {
        self.itemObj = res.data.resultData[0];
        if (self.itemObj.isItemSet) {
          this.getItemChildData();
        } else {
          this.blockUI.stop();
        }
      });
    });
  }

  getItemChildData() {
    const childItemsPromises: Promise<void>[] = this.itemObj.itemSet.map(
      (item, index) => {
        const id = item.ingredientItemId;
        return new Promise<void>((resolve) => {
          this._apiService
            .GetDataById('item', id, { verbose: false })
            .subscribe((res) => {
              this.childItemsList.push(res.data.resultData[0]);
              resolve();
            });
        });
      }
    );

    Promise.all(childItemsPromises).then(() => {
      if (this.childItemsList.length === this.itemObj.itemSet.length) {
        this.blockUI.stop();
      }
    });
  }

  getLastItemMovement(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this.http
        .get(
          `${this._apiService.API_URL}/itemMovement/item/${this.itemID}/last`,
          {
            headers: this._apiService.API_HEADERS,
          }
        )
        .subscribe((res: any) => {
          this.lastItemMovementObj = res.data.resultData[0];
        });
    });
  }

  Submit(): void {}

  openConfirmModal(): void {
    let isConfirm = true;
    let contentNameText = this.isEditing
      ? this.itemObj.name
      : this.componentName;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('Stock.Edit');
    } else {
      var stateForm = this._translateService.instant('Stock.Create');
    }
    let title =
      this._translateService.instant('Stock.Confirm') +
      `${stateForm} ${this.componentName}`;
    let detail =
      this._translateService.instant('Stock.AreYouSureTo') +
      `${stateForm} ${this.componentName}`;
    this.openModal(title, detail, isConfirm);
  }

  openCancelModal(): void {
    let isConfirm = false;
    let contentNameText = this.isEditing
      ? this.itemObj.name
      : this.componentName;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('Stock.Edit');
    } else {
      var stateForm = this._translateService.instant('Stock.Create');
    }
    let title =
      this._translateService.instant('Stock.Cancel') +
      `${stateForm} ${this.componentName}`;
    let detail =
      this._translateService.instant('Stock.AreYouSureToCancel') +
      `${stateForm} ${this.componentName}`;
    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    // ก้อปมาจาก role
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      if (IsConfirm) {
        this.Submit();
      }
      this._router.navigate([this.callbackUrl]);
    });
  }
}
