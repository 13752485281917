import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { MainConfig } from '../../../../config/MainConfig';
import { ApiService } from '../../../../service/api.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from '../../../../components/components.service';
import { GroupService } from '../group.service';
import Group from '../../../../model/Group';
import { AuthenticationService } from '../../../../../auth/service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-group-formpage',
  templateUrl: './group-formpage.component.html',
  styleUrls: ['./group-formpage.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GroupFormpageComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();
  private dataSubscription: Subscription;
  componentName: string;

  apiPath: string;
  pathUrl: string;

  contentHeader: object;

  itemGuid: string;
  itemObj: Group;

  images: File[] = [];
  files: File[] = [];

  registerForm: FormGroup;

  @Input() isEditing: boolean;

  isLoading: boolean;
  isSubmitted: boolean = false;

  summernoteConfig: object;
  modeText: string;
  activeModal: any;

  @ViewChild('confirmModal') confirmModal: ModalComponent;

  constructor(
    private _apiService: ApiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _modalService: NgbModal,
    private _mainConfig: MainConfig,
    private _contentTemplateService: GroupService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder
  ) {
    this.SetLoadingState();
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }


  ngOnInit(): void {

    this.contentHeader = {
      headerTitle: 'General.Group',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Group',
            isLink: true,
            link: '/item-manage/group',
          },
          {
            name: 'General.Create',
            isLink: false,
          },
        ],
      },
    };
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
