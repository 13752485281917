import { Component, OnInit } from '@angular/core';
import { EventService } from './event.service';
import { ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit {
  config!: ICMSConfig;
  keyName: string;

  constructor(private _eventService: EventService) {
    this.config = this._eventService.config;
    this.keyName = this._eventService.keyName;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
