<div class="container-xxl export-header-qo">
  <app-content-header [contentHeader]="contentHeader"></app-content-header>
</div>

<app-document-papers
  [isPayments]="false"
  [PreAmount]="20000"
  [data]="passObj"
  docCodeColumn="quotationCode"
></app-document-papers>
