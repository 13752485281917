import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'app/main/service/api.service';

@Component({
  selector: 'app-related-doc-modal',
  templateUrl: './related-doc-modal.component.html',
  styleUrls: ['./related-doc-modal.component.scss'],
})
export class RelatedDocModalComponent implements OnInit {
  @Input() public modalConfig: NgbModalConfig;

  @Input() orderId: string;
  @Input() isBuyOrder: boolean = false;
  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();

  orderStatus: any[] = [];

  isLoading: boolean = false;

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private _apiService: ApiService
  ) {}

  closeModal(): void {
    this.activeModal.close();
  }
  callBack(): void {
    this.closeModal();
  }

  ngOnInit(): void {
    this.isLoading = true

    let path = this.isBuyOrder ? 'BuyOrder/State' : 'Order/State'

    console.log(path)

    this._apiService
      .GetDataById(path, this.orderId)
      .subscribe((res) => {
        this.orderStatus = res.data.resultData;
        this.isLoading = false
      });
  }
}
