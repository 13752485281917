import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { ContentHeader } from '../../../../layout/components/content-header/content-header.component';
import { RelatedDocModalComponent } from '../../../components/related-doc-modal/related-doc-modal.component';
import { CommonResponse } from '../../../model/CommonResponse';
import { ApiService } from '../../../service/api.service';
import { Subject } from 'rxjs';
import { ReturnProductDetailModalComponent } from './return-product-detail-modal/return-product-detail-modal.component';
import { Invoice } from 'app/main/model/Order';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { ReturnProductStatusEnum } from 'app/main/model/ReturnProduct';
import { takeUntil } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ComponentsService } from 'app/main/components/components.service';

@Component({
  selector: 'app-return-product',
  templateUrl: './return-product.component.html',
  styleUrls: ['./return-product.component.scss'],
})
export class ReturnProductComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  contentHeader: ContentHeader;
  currentLang: string;

  searchByName: number = 0;
  isLoading: boolean = false;

  returnInvoiceList: Invoice[] = [];

  page = 1;
  pageSize = 10;
  searchData: any = {};
  searchText: string = '';
  approvedStatus: boolean = false;

  searchByStatus: number = null;

  keyName: string = '';
  apiPath: string = 'Invoice';

  @BlockUI() blockUI: NgBlockUI;

  @ViewChild('searchbar') searchBar: ElementRef;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  stateEnum = ReturnProductStatusEnum;

  constructor(
    private _translateService: TranslateService,
    private apiService: ApiService,
    private _modalService: NgbModal,
    private _componentsService: ComponentsService
  ) {}

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;

    this.contentHeader = {
      headerTitle: 'Dashboard.ReturnProductRequest',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: this.pageSize,
      // order: [[2, 'asc']],
      serverSide: true,
      lengthMenu: [10, 25, 50, 100],
      lengthChange: true,
      dom: '<"float-left"B><"float-right"f>rt<"row"<"col-sm-4"i><"col-sm-4 text-center"l><"col-sm-4"p>>',
      columnDefs: [
        { orderable: false, targets: 0 },
        { name: 'InvoiceCode', targets: 1 },
        { name: 'Price', targets: 2 },
        { name: 'Status', targets: 3 },
        { name: 'IsActive', targets: 4 },
        { orderable: false, targets: 5 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.returnInvoiceList = [];
        self.SetLoadingState();

        let defaultSortName = 'BusinessName';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        } else {
          self.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.searchData.Direction = order?.dir == 'asc' ? 0 : 1;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.searchData.PageLength = dataTablesParameters.length;
        this.searchData.isDelete = false;

        this.searchData.returnState =
          typeof this.searchByStatus !== 'number' ? '' : this.searchByStatus;

        if (this.searchByStatus === 1) {
          this.approvedStatus = true;
        } else {
          this.approvedStatus = false;
        }

        this.searchData.isReturn = true;

        // this.searchData.returnType = this.returnType
        // ? this.returnType
        // : 0;

        console.log(this.searchData);
        this.getRefundRequestList().then((res) => {
          self.returnInvoiceList = res.data.resultData;

          self.SetLoadedState();
          callback({
            recordsTotal: res.data.itemCount,
            recordsFiltered: res.data.totalItem,
            data: [],
          });
        });
      },
    };
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ResetSearch() {
    this.searchByName = 0;
    this.searchByStatus = null;
    this.searchBar.nativeElement.value = '';
    this.initData();
  }

  SubmitSearch() {
    this.initData();
  }

  initData(obj?: { [key: string]: any }): void {
    this.SetLoadingState();
    this.returnInvoiceList = [];

    this.searchData = {
      SortPath: '',
      Direction: 1,
      isDelete: false,
    };

    if (obj) {
      this.searchText = obj.searchText ? obj.searchText : '';

      if (!obj.params) {
        this.searchData = {
          SortPath: this.keyName,
          Direction: 1,
          isDelete: false,
        };
      } else {
        this.searchData = obj.params;
      }
    } else {
      this.searchText = '';
      this.searchData = {
        SortPath: this.keyName,
        Direction: 1,
        isDelete: false,
      };
    }

    if (this.searchByName == 0) {
      this.searchData.Detail = this.searchText;
    } else if (this.searchByName == 1) {
      this.searchData.CustomerCode = this.searchText;
    } else if (this.searchByName == 2) {
      this.searchData.OrderCode = this.searchText;
    }

    this.loadData(this.searchData);
  }

  getRefundRequestList(): Promise<CommonResponse<Invoice>> {
    return new Promise((resolve, rejects) => {
      this.apiService
        .GetAllData(this.apiPath, this.searchData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<Invoice>) => {
            resolve(res);
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  loadData(searchData?: any) {
    if (searchData) {
      this.searchData = searchData;
    } else {
      this.searchData = {
        SortPath: '',
        Direction: 1,
        isDelete: false,
      };
    }

    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          this.SetLoadedState();
        });
      });
    }
  }

  openRelatedDocModal(refundReq: any, orderCodeColumn: string) {
    const modalRef = this._modalService.open(RelatedDocModalComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
    });

    let isBuyOrder = orderCodeColumn == 'buyOrderCode' ? true : false;

    console.log(orderCodeColumn);

    modalRef.componentInstance.orderId = refundReq.orderId;
    // modalRef.componentInstance.order = refundReq;
    modalRef.componentInstance.isBuyOrder = isBuyOrder;
  }

  getStatusLabel(stateEnum: number): any {
    switch (stateEnum) {
      case ReturnProductStatusEnum.WaitApprove:
        return this._translateService.instant(
          'BookingCalendar.Status.WaitApprove'
        );
      case ReturnProductStatusEnum.Approved:
        return this._translateService.instant(
          'BookingCalendar.Status.Approved'
        );
      case ReturnProductStatusEnum.Reject:
        return this._translateService.instant('BookingCalendar.Status.Reject');

      default:
        return this._translateService.instant(
          'BookingCalendar.Status.WaitApprove'
        );
    }
  }

  openRefundModal(returnInvoice: Invoice) {
    const modalRef = this._modalService.open(
      ReturnProductDetailModalComponent,
      {
        centered: true,
        size: 'xl',
      }
    );
    modalRef.componentInstance.invoice = returnInvoice;
    modalRef.componentInstance.callBackFunc.subscribe(() => {
      this.initData();
    });
  }

  voidDocumentModal(returnInvoice: Invoice) {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = this._translateService.instant(
      'ReturnProduct.ClaimModalTitle'
    );
    modalRef.componentInstance.detail = this._translateService.instant(
      'ReturnProduct.ClaimModalDetail'
    );
    modalRef.componentInstance.invoice = returnInvoice;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.CallVoidDocument(returnInvoice)
        .then(() => {
          this.initData();
          this.blockUI.stop();
          this._componentsService.SuccessSwal();
        })
        .catch(() => {
          this.blockUI.stop();
          this._componentsService.ErrorSwal();
        });

      console.log(returnInvoice);
    });
  }

  CallVoidDocument(invoice: Invoice): Promise<void> {
    return new Promise((resolve, rejects) => {
      this.apiService
        .patch(`order/${invoice.orderId}/claim`)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<any>) => {
            resolve();
          },
          (err) => {
            rejects();
          }
        );
    });
  }
}
