import { Component, Input, OnInit } from '@angular/core';
import {

  District,
  Province,
  Region,
  SubDistrict,
} from '../master-addr-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ViewMasterAddrMode } from '../master-addr-crud.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2';
import { ApiService } from 'app/main/service/api.service';
import { CommonResponse } from 'app/main/model/CommonResponse';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from 'app/main/components/components.service';

@Component({
  selector: 'app-master-addr-form-modal',
  templateUrl: './master-addr-form-modal.component.html',
  styleUrls: ['./master-addr-form-modal.component.scss'],
})
export class MasterAddrFormModalComponent implements OnInit {
  @Input() viewMode: number;
  @Input() isEditing: boolean;
  @Input() data: Province | District | SubDistrict;
  @Input() og_provinceList: Province[] = [];

  isSubmit: boolean = false;
  formGroup: FormGroup;

  @Input() provinceList: Province[] = [];
  @Input() districtList: District[] = [];

  @Input() selectedRegion: number = 0;
  @Input() selectedProvince: Province;
  @Input() selectedDistrict: District;

  @BlockUI() blockUI: NgBlockUI;

  currentLang: string

  constructor(
    private _modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _apiService: ApiService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService
  ) {
    this.formGroup = this._formBuilder.group({
      id: [''],
      nameTH: ['', [Validators.required]],
      nameEN: ['', [Validators.required]],
      region: [''],
      province: [''],
      district: [''],
      subDistrict: [''],
      latitude: [0],
      longitude: [0],
      postCode: ['', []],
    });
  }

  get f() {
    return this.formGroup.controls;
  }

  isProvinceMode(): boolean {
    return this.viewMode == ViewMasterAddrMode.Province;
  }

  isDistrictMode(): boolean {
    return this.viewMode == ViewMasterAddrMode.District;
  }

  isSubDistrictMode(): boolean {
    return this.viewMode == ViewMasterAddrMode.SubDistrict;
  }

  ngOnInit(): void {
    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    if (this.data) {
      this.setFormValue();
    }

    switch (this.viewMode) {
      case ViewMasterAddrMode.Province:
        this.setValidatorsForProvince();
        break;
      case ViewMasterAddrMode.District:
        this.setValidatorsForDistrict();
        break;
      case ViewMasterAddrMode.SubDistrict:
        this.setValidatorsForSubDistrict();
        break;
      default:
        break;
    }
  }

  closeModal() {
    this._modalService.dismissAll();
  }

  callBack() {
    this.isSubmit = true;
    if (this.formGroup.invalid) return;

    console.log('saved');
    this.blockUI.start();
    switch (this.viewMode) {
      case ViewMasterAddrMode.Province:
        this.SaveData('MasterProvince', this.f.id.value, this.prepareData()).then(() => {
          this.blockUI.stop();
        });
        break;
      case ViewMasterAddrMode.District:
        this.SaveData('MasterDistrict', this.f.id.value, this.prepareData()).then(() => {
          this.blockUI.stop();
        });
        break;
      case ViewMasterAddrMode.SubDistrict:
        this.SaveData('MasterSubDistrict', this.f.id.value, this.prepareData()).then(() => {
          this.blockUI.stop();
        });
        break;

      default:
        this.blockUI.stop();
        this._componentsService.ErrorSwal()

        break;
    }
  }

  SaveData(path: string, id: string, body: any): Promise<void> {
    return new Promise((resolve, rejects) => {
      this._apiService.UpdateDataById(path, id, body).subscribe(
        (res) => {
          this.closeModal()
          this._componentsService.SuccessSwal()
          resolve();
        },
        (err) => {
          this._componentsService.ErrorSwal()
          rejects();
        }
      );
    });
  }

  onSelectRegion() {
    this.f.province.setValue(null);
    this.f.district.setValue(null);

    if (this.viewMode != ViewMasterAddrMode.Province) {
      console.log(this.f.region.value);
      this.provinceList = [
        ...this.og_provinceList.filter((p) => p.region == this.f.region.value),
      ];
    }
  }

  onSelectProvince() {
    this.getDistrict(this.f.province.value).then((res) => {
      this.districtList = res.data.resultData;
    });
  }

  setFormValue() {
    switch (this.viewMode) {
      case ViewMasterAddrMode.Province:
        this.setProvinceFormValues(this.data as Province);
        break;
      case ViewMasterAddrMode.District:
        this.setDistrictFormValues(this.data as District);
        break;
      case ViewMasterAddrMode.SubDistrict:
        this.setSubDistrictFormValues(this.data as SubDistrict);
        break;
      default:
        break;
    }

    this.provinceList = [
      ...this.og_provinceList.filter((p) => p.region == this.f.region.value),
    ];
  }

  private setValidatorsForProvince() {
    this.formGroup.get('region').setValidators(Validators.required);
    this.formGroup.get('province').clearValidators();
    this.formGroup.get('district').clearValidators();
  }

  private setValidatorsForDistrict() {
    this.formGroup.get('region').setValidators(Validators.required);
    this.formGroup.get('province').setValidators(Validators.required);
    this.formGroup.get('district').clearValidators();
  }

  private setValidatorsForSubDistrict() {
    this.formGroup.get('region').setValidators(Validators.required);
    this.formGroup.get('province').setValidators(Validators.required);
    this.formGroup.get('district').setValidators(Validators.required);
    this.formGroup.get('postCode').setValidators(Validators.required);
  }

  private setProvinceFormValues(provinceData: Province) {
    this.formGroup.patchValue({
      id: provinceData.id,
      nameTH: provinceData.provinceName,
      nameEN: provinceData.provinceNameEn,
      region: provinceData.region,
    });
  }

  private setDistrictFormValues(districtData: District) {
    this.formGroup.patchValue({
      id: districtData.id,
      nameTH: districtData.districtName,
      nameEN: districtData.districtNameEn,
      region: this.selectedProvince.region,
      province: districtData.provinceId,
    });
  }

  private setSubDistrictFormValues(subDistrictData: SubDistrict) {
    this.formGroup.patchValue({
      id: subDistrictData.id,
      nameTH: subDistrictData.subDistrictName,
      nameEN: subDistrictData.subDistrictNameEn,
      region: this.selectedProvince.region,
      province: this.selectedProvince.id,
      district: subDistrictData.districtId,
      postCode: subDistrictData.subDistrictPostCode,
      latitude: subDistrictData.latitude,
      longitude: subDistrictData.longitude,
    });
  }

  getDistrict(id: string): Promise<CommonResponse<District>> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterDistrict`, {
          ProvinceId: id,
          SortPath: 'districtName',
          Direction: 0,
        })
        .subscribe(
          (res: CommonResponse<District>) => {
            resolve(res);
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  prepareData(): any {
    switch (this.viewMode) {
      case ViewMasterAddrMode.Province:
        let provinceData: Province = this.data as Province;

        provinceData.id = this.f.id.value;
        provinceData.provinceName = this.f.nameTH.value;
        provinceData.provinceNameEn = this.f.nameEN.value;
        provinceData.region = this.f.region.value;

        return provinceData;

      case ViewMasterAddrMode.District:
        let districtData: District = this.data as District;

        districtData.id = this.f.id.value;
        districtData.districtName = this.f.nameTH.value;
        districtData.districtNameEn = this.f.nameEN.value;
        districtData.provinceId = this.f.province.value;

        return districtData;

      case ViewMasterAddrMode.SubDistrict:
        let subDistrictData: SubDistrict = this.data as SubDistrict;

        subDistrictData.id = this.f.id.value;
        subDistrictData.subDistrictName = this.f.nameTH.value;
        subDistrictData.subDistrictNameEn = this.f.nameEN.value;
        subDistrictData.districtId = this.f.district.value;
        subDistrictData.subDistrictPostCode = this.f.postCode.value;
        subDistrictData.latitude = this.f.latitude.value;
        subDistrictData.longitude = this.f.longitude.value;

        return subDistrictData;

      default:
        break;
    }
  }
}
