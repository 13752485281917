import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { CertificationsFileService } from './certifications-file.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/main/service/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { takeUntil } from 'rxjs/operators';
import { ComponentsService } from 'app/main/components/components.service';

@Component({
  selector: 'app-certifications-file',
  templateUrl: './certifications-file.component.html',
  styleUrls: ['./certifications-file.component.scss'],
})
export class CertificationsFileComponent implements OnInit {
  config!: ICMSConfig;
  keyName: string;

  contentHeader: object;

  currentLang: string;
  activeStatus: string;

  itemObjList: any[] = [];
  isLoading: boolean = false;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild('searchbar') searchBar: ElementRef;
  @ViewChild('activeFil') activeFil: ElementRef;

  searchData: any = {};
  searchText: string = '';

  categoryId: string = 'All';
  categoryList: any[] = [];

  page: number = 1;
  pageSize: number = 10;

  @BlockUI() blockUI: NgBlockUI
  apiPath: string = ''

  constructor(
    private _certFileService: CertificationsFileService,
    private _translateService: TranslateService,
    private _apiService: ApiService,
    private _modalService: NgbModal,
    private _componentsService: ComponentsService
  ) {
    this.config = this._certFileService.config;
    this.keyName = this._certFileService.keyName;
    this.apiPath = this._certFileService.apiPath

    this._apiService
      .GetAllData('certificationscard', { isActive: true, isDelete: false })
      .subscribe((res) => {
        this.categoryList = res.data.resultData;
      });
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: this.config.componentName,
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    var self = this;
    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: 10,

      order: [[3, 'desc']],
      ordering: true,
      serverSide: true,

      lengthMenu: [10, 25, 50, 100],
      lengthChange: true,
      dom: '<"float-left"B><"float-right"f>rt<"row"<"col-sm-4"i><"col-sm-4 text-center"l><"col-sm-4"p>>',
      columnDefs: [
        { orderable: false, targets: 0 },
        { name: this.keyName ? this.keyName : 'name', targets: 1 },
        { name: 'IsActive', targets: 2 },
        { name: 'UpdateDate', targets: 3 },
        { name: 'CreateBy', targets: 4 },
        { orderable: false, targets: 5 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.itemObjList = [];
        self.SetLoadingState();

        let defaultSortName = 'UpdateDate';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        } else {
          self.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.searchData.Direction = order?.dir == 'desc' ? 1 : 0;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.pageSize = dataTablesParameters.length;

        this.searchData.PageLength = dataTablesParameters.length;

        this.searchData.isDelete = false;

        this._apiService
          .GetAllData(this.config.apiPath, this.searchData)
          .subscribe(
            (response) => {
              console.log(response);
              this.itemObjList = response.data.resultData;

              self.SetLoadedState();
              callback({
                recordsTotal: response.data.itemCount,
                recordsFiltered: response.data.totalItem,
                data: [],
              });
            },
            (err) => {
              this.itemObjList = [];
              self.SetLoadedState();
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }
          );
      },
    };
  }

  loadData(searchData?: any) {
    if (searchData) {
      this.searchData = searchData;
    }

    if (this.dtTrigger) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          this.SetLoadedState();
        });
      });
    }
  }

  ExportXLSX() {}

  ActiveStatusFilter(val: string) {
    this.activeStatus = val;
  }

  setPageSize(val: number): void {
    this.pageSize = val;
  }

  initData(obj?): void {
    this.SetLoadingState();
    this.itemObjList = [];

    if (obj) {
      this.searchText = obj.searchText ? obj.searchText : '';

      if (!obj.params) {
        this.searchData = {
          sortPath: this.keyName,
          direction: 1,
          isDelete: false,
        };
      } else {
        this.searchData = obj.params;
      }
    } else {
      this.searchText = '';
      this.searchData = {
        sortPath: this.keyName,
        direction: 1,
        isDelete: false,
      };
    }

    this.searchData.name = this.searchText;
    this.searchData.verbose = false;
    this.searchData.includeMediaURL = false;

    this.loadData(this.searchData);
  }

  ResetSearch() {
    this.searchBar.nativeElement.value = '';
    this.activeFil.nativeElement.value = 'all';

    this.categoryId = 'All';
    // this.categoryList = [];
  }

  Search() {
    let params: any = {};
    if (this.categoryId != 'All') {
      params.Reserved1 = this.categoryId;
    }

    const activeStr = this.activeFil.nativeElement.value;
    if (activeStr === 'active') {
      params.isActive = true;
    } else if (activeStr === 'inactive') {
      params.isActive = false;
    }

    let obj = {
      searchText: this.searchBar.nativeElement.value
        ? this.searchBar.nativeElement.value
        : '',
      params: params,
    };
    this.initData(obj);
  }

  openDeleteContentModal(itemObj: any) {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title =
      this._translateService.instant('Brand.DeleteTitle') + `${itemObj.name}`;
    modalRef.componentInstance.detail =
      this._translateService.instant('Brand.Delete') + `${itemObj.name}`;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.setDeleteContent(itemObj.id);
    });
  }

  openIsActiveModal(itemObj, status): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    let modeText = status
      ? this._translateService.instant('Status.Published')
      : this._translateService.instant('Status.Unpublished');
    modalRef.componentInstance.title = `${modeText}`;
    modalRef.componentInstance.detail =
      this._translateService.instant('Brand.Active') +
      `${modeText} ${itemObj.name}`;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.setIsActiveContent(itemObj.id, status);
    });
  }

  setIsActiveContent(id, status): void {
    this.blockUI.start();
    // this.isActiveFunc.emit({ id: id, status: status });
    this.setActiveContent({ id: id, status: status })
  }

  setDeleteContent(id): void {
    this.blockUI.start();
    // this.deleteFunc.emit(id);
    this.deleteContent(id)
  }

  setActiveContent(val): void {
    this._apiService
      .SetIsActiveData(this.apiPath, val.id, val.status)
      .pipe()
      .subscribe(
        (res) => {
          this._componentsService.SuccessSwal();
          this.isLoading = true;
          this.initData();
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
  }

  deleteContent(guid: string): void {
    console.log('delele Complete' + guid);
    this._apiService
      .SoftDelete(this.apiPath, guid)
      .pipe()
      .subscribe(
        (res) => {
          this._componentsService.SuccessSwal();
          this.isLoading = true;
          this.initData();
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
  }
}
