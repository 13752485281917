<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section>
      <app-horizontal-timeline
        [timelineItems]="timelineData"
        [currentStep]="currentStep"
        [currentStepNumber]="currentStep"
        [lastItems]="{
          label: 'Cancel',
          descript: 'ยกเลิก',
          stepCount: 5
        }"
      ></app-horizontal-timeline>
    </section>

    <section>
      <div class="card border" *ngIf="docStatus !== 8 && docStatus !== 9">
        <div class="card-body">
          <div
            class="d-flex justify-content-between w-100 px-2 align-items-center"
          >
            <span>{{ "SaleOrder.CreateIVFormSO" | translate }}</span>
            <div class="d-flex align-items-center">
              <!-- <div class="custom-control custom-checkbox mr-1">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="checkIncludeQo"
                  [checked]="isIncludeQo"
                  [disabled]="disableButton"
                  (change)="toggleIncludeQo()"
                />
                <label class="custom-control-label" for="checkIncludeQo"
                  >ให้ใบแจ้งหนี้เป็นใบกำกับภาษี</label
                >
              </div> -->
              <button
                class="btn btn-primary"
                (click)="openApproveModal()"
                [disabled]="disableButton || isApproving"
              >
                <span
                  *ngIf="isApproving"
                  class="spinner-border spinner-border-sm mr-1"
                ></span>
                {{ "SaleOrder.Approve" | translate }}
              </button>

              <a
                *ngIf="orderStatus[0].isAllIVCreated != true && isSplitIVfromSO"
                class="btn btn-success ml-1"
                [routerLink]="[
                  '/document/invoice/create/ref/' + saleOrderObj.id
                ]"
              >
                <i data-feather="scissors" class="mr-1"></i>
                {{ "Document.SeparateIV" | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="card">
        <div class="card-body">
          <div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
            <app-loading></app-loading>
          </div>

          <div *ngIf="!isLoading">
            <app-content-document
              [titleDoc]="'Document.SaleOrder' | translate"
              [customerAddressObj]="customerAddressObj"
              [apiPath]="apiPath"
              [typeDocName]="typeDocName"
              [detailObj]="saleOrderObj"
              [docId]="docID"
              [docStatus]="docStatus"
              [editPathUrl]="editPathUrl"
              [isDisabledEditBtn]="disableButton"
              [refDocID]="'quotationId'"
              [refDetailUrl]="refDetailUrl"
            >
            </app-content-document>
          </div>
        </div>
      </div> -->

      <div class="card">
        <!-- Nav tabs -->
        <div
          class="card-header"
          style="border-bottom: 1px dashed rgba(161, 161, 161, 0.3)"
        >
          <span></span>
          <!-- <h2>{{ "Document.DebitNote" | translate }}</h2> -->
          <ul
            ngbNav
            #navFilled="ngbNav"
            [activeId]="activeTab"
            class="nav nav-tabs mb-0 bg-white border"
            [destroyOnHide]="false"
          >
            <li [ngbNavItem]="'detail'">
              <a ngbNavLink routerLink="." [fragment]="'detail'">{{
                "General.DocumentInfo" | translate
              }}</a>
              <ng-template ngbNavContent>
                <app-content-document
                  [customerAddressObj]="customerAddressObj"
                  [titleDoc]="'Document.SaleOrder' | translate"
                  [apiPath]="apiPath"
                  [typeDocName]="typeDocName"
                  [detailObj]="saleOrderObj"
                  [invoiceDetailObj]="saleOrderObj"
                  [docId]="docID"
                  [docStatus]="docStatus"
                  [editPathUrl]="editPathUrl"
                  [refDetailUrl]="refDetailUrl"
                  [refEditUrl]="refEditUrl"
                  [refDocID]="'quotationId'"
                  [isDisabledEditBtn]="true"
                  (refreshData)="refreshData()"
                  [isRevenueDoc]="true"
                >
                </app-content-document>
              </ng-template>
            </li>

            <li [ngbNavItem]="'historyInfo'">
              <a ngbNavLink routerLink="." [fragment]="'historyInfo'">{{
                "General.HistoryInfo" | translate
              }}</a>
              <ng-template ngbNavContent>
                <app-action-history
                  [documentId]="docID"
                  [title]="'Document.SaleOrder'"
                  [apiPath]="apiPath"
                  [detailObj]="saleOrderObj"
                  [typeDocName]="typeDocName"
                  [docStatus]="docStatus"
                  [refDetailUrl]="refDetailUrl"
                  [refDocID]="'quotationId'"
                >
                </app-action-history>
              </ng-template>
            </li>
            <li [ngbNavItem]="'relatedDoc'">
              <a ngbNavLink routerLink="." [fragment]="'relatedDoc'">{{
                "General.RelatedDocument" | translate
              }}</a>
              <ng-template ngbNavContent>
                <app-content-child-document
                  [orderStateData]="orderStatus[0]"
                  (refreshData)="refreshData($event)"
                  [currentDocCode]="saleOrderObj[typeDocName]"
                >
                </app-content-child-document>
              </ng-template>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div [ngbNavOutlet]="navFilled"></div>
        </div>
      </div>
    </section>
  </div>
</div>
