class About {
  id: any;
  
  bannerTitle: string;
  bannerSubTitle: string;
  bannerImage: any;

  quoteText: string;
  quoteImage: any;

  aboutText1: string;
  aboutText2: string;
  aboutImage1: any;
  aboutImage2: any;
  aboutImage3: any;

  banner2Title: string;
  banner2SubTitle: string;
  banner2Image: any;

  quote2Text: string;
  quote2Image: any;
}

export default About;
