<block-ui> </block-ui>
<div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
    <app-loading></app-loading>
</div>
<div *ngIf="!isLoading" class="row">
    <div class="col-md-12">
        <form [formGroup]="registerForm">
            <h4 class="mb-2">{{ componentName | translate }}</h4>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">
                    {{ "PromotionForm.PromotionName" | translate }}
                </label>
                <div class="col-sm-9">
                    <input class="form-control" type="text" formControlName="codeName" [ngClass]="{
                        'is-invalid error':
                            (isSubmit && f.codeName?.invalid) || 
                            (f.codeName?.invalid && (f.codeName?.dirty || f.codeName?.touched))}" />
                    <div class="invalid-feedback"
                        [ngClass]="{'d-block': f.codeName.invalid && (f.codeName.dirty || f.codeName.touched)}">
                        <div>
                            <div *ngIf="f.codeName.errors?.required">
                                {{ "Handle.Name" | translate }}
                            </div>
                            <div *ngIf="f.codeName.errors?.maxlength">
                                {{ "Handle.Exceed" | translate }}
                                50
                                {{ "Handle.Char" | translate }}
                            </div>
                            <div *ngIf="f.codeName.errors?.whitespace && !f.codeName.errors?.required">
                                {{ "Handle.noWhitespaceValidator" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ===================================== -->
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">
                    {{ "PromotionForm.RedeemCode" | translate }}
                </label>
                <div class="col-sm-9">
                    <input class="form-control" type="text" formControlName="redeemCode" [ngClass]="{
                        'is-invalid error':
                            (isSubmit && f.redeemCode?.invalid) || 
                            (f.redeemCode?.invalid && (f.redeemCode?.dirty || f.redeemCode?.touched))}" />
                    <div class="invalid-feedback"
                        [ngClass]="{'d-block': f.redeemCode.invalid && (f.redeemCode.dirty || f.redeemCode.touched)}">
                        <div>
                            <div *ngIf="f.redeemCode.errors?.required">
                                {{ "Handle.RedeemCode" | translate }}
                            </div>
                            <div *ngIf="f.redeemCode.errors?.maxlength">
                                {{ "Handle.Exceed" | translate }}
                                50
                                {{ "Handle.Char" | translate }}
                            </div>
                            <div *ngIf="f.redeemCode.errors?.whitespace && !f.redeemCode.errors?.required">
                                {{ "Handle.noWhitespaceValidator" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ===================================== -->
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">
                    {{ "PromotionForm.Amount" | translate }}
                </label>
                <div class="col-sm-9">
                    <input class="form-control" type="number" formControlName="amount" [ngClass]="{
                        'is-invalid error':
                            (isSubmit && f.amount?.invalid) || 
                            (f.amount?.invalid && (f.amount?.dirty || f.amount?.touched))}" />
                            
                    <div class="invalid-feedback"
                        [ngClass]="{'d-block': f.amount.invalid && (f.amount.dirty || f.amount.touched)}">
                        <div>
                            <div *ngIf="f.amount.errors?.required">
                                {{ "Handle.Amount" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ===================================== -->
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">
                    {{ "PromotionForm.UnlimitedCode" | translate }}
                </label>
                <div class="col-sm-9">
                    <div class="custom-control custom-switch custom-control-inline col-form-label">
                        <!-- <input type="checkbox" class="custom-control-input" id="customSwitch1"
                            [(ngModel)]="itemObj.isActive" [ngModelOptions]="{ standalone: true }" name="isActive"
                            [checked]="itemObj.isActive" /> -->
                        <input type="checkbox" class="custom-control-input" id="isAmountUnlimit"
                            formControlName="isAmountUnlimit" />
                        <label class="custom-control-label" for="isAmountUnlimit"></label>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">
                    {{ "PromotionForm.Discount" | translate }}
                </label>
                <div class="col-sm-9">
                    <input class="form-control" type="number" formControlName="discount" [ngClass]="{
                        'is-invalid error':
                            (isSubmit && f.discount?.invalid) || 
                            (f.discount?.invalid && (f.discount?.dirty || f.discount?.touched))}" />
                    <div class="invalid-feedback"
                        [ngClass]="{'d-block': f.discount.invalid && (f.discount.dirty || f.discount.touched)}">
                         
                        <div>
                            
                            <div *ngIf="f.discount.errors?.required">
                                {{ "Handle.Discount" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">
                    {{ "PromotionForm.PercentDiscount" | translate }}
                </label>
                <div class="col-sm-9">
                    <div class="custom-control custom-switch custom-control-inline col-form-label">
                        <!-- <input type="checkbox" class="custom-control-input" id="customSwitch1"
                            [(ngModel)]="itemObj.isActive" [ngModelOptions]="{ standalone: true }" name="isActive"
                            [checked]="itemObj.isActive" /> -->
                        <input type="checkbox" class="custom-control-input" id="isDiscountByPercent"
                            formControlName="isDiscountByPercent" />
                        <label class="custom-control-label" for="isDiscountByPercent"></label>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">
                    {{ "PromotionForm.NumberCanReuse" | translate }}
                </label>
                <div class="col-sm-9">
                    <input class="form-control" type="number" formControlName="amountPerCustomer"
                        [ngClass]="{
                        'is-invalid error':
                            (isSubmit && f.amountPerCustomer?.invalid) || 
                            (f.amountPerCustomer?.invalid && (f.amountPerCustomer?.dirty || f.amountPerCustomer?.touched))}" />
                    <div class="invalid-feedback"
                        [ngClass]="{'d-block': f.amountPerCustomer.invalid && (f.amountPerCustomer.dirty || f.amountPerCustomer.touched)}">
                        <div>
                            <div *ngIf="f.amountPerCustomer.errors?.required">
                                {{ "Handle.AmountPerCustomer" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">
                    {{ "PromotionForm.UnlimitedReusable" | translate }}
                </label>
                <div class="col-sm-9">
                    <div class="custom-control custom-switch custom-control-inline col-form-label">
                        <!-- <input type="checkbox" class="custom-control-input" id="customSwitch1"
                            [(ngModel)]="itemObj.isActive" [ngModelOptions]="{ standalone: true }" name="isActive"
                            [checked]="itemObj.isActive" /> -->
                        <input type="checkbox" class="custom-control-input" id="isUsedUnlimit"
                            formControlName="isUsedUnlimit" />
                        <label class="custom-control-label" for="isUsedUnlimit"></label>
                    </div>
                </div>
            </div>

            <div class="form-group row ">
                <label class="col-sm-3 col-form-label">
                    {{ "PromotionForm.StartDate" | translate }}
                </label>
                <div class="col-sm-5">
                    <!-- <input class="form-control" type="datetime-local" formControlName="startDate" /> -->
                    <ng2-flatpickr class="date-form-control "
                        [config]="startDateOptions" [setDate]="startDateOptions.defaultDate" 
                        formControlName="startDate"
                        (change)="onDateFromChange($event)"
                        [ngClass]="{
                            'is-invalid error':
                                (isSubmit && f.startDate?.invalid) || 
                                (f.startDate?.invalid && (f.startDate?.dirty || f.startDate?.touched))}"></ng2-flatpickr>
                                   <div class="invalid-feedback"
                        [ngClass]="{'d-block': f.startDate.invalid && (f.startDate.dirty || f.startDate.touched)}">
                        <div>
                            <div *ngIf="f.startDate.errors?.required">
                                {{ "Handle.StartDate" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">
                    {{ "PromotionForm.EndDate" | translate }}
                </label>
                <div class="col-sm-5">
                    <!-- <input class="form-control" type="datetime-local" formControlName="endDate" /> -->
                    <ng2-flatpickr class="date-form-control" [class.invalid-date]="invalidDate"
                        [setDate]="endDateOptions.defaultDate" [config]="endDateOptions" 
                        formControlName="endDate"
                        (change)="onDateFromChange($event)" [ngClass]="{
                            'is-invalid error':
                                (isSubmit && f.endDate?.invalid) || 
                                (f.endDate?.invalid && (f.endDate?.dirty || f.endDate?.touched))}"></ng2-flatpickr>
                                   <div class="invalid-feedback"
                        [ngClass]="{'d-block': f.endDate.invalid && (f.endDate.dirty || f.endDate.touched)}">
                        <div>
                            <div *ngIf="f.endDate.errors?.required">
                                {{ "Handle.EndDate" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">
                    {{ "PromotionForm.DiscountNoExpire" | translate }}
                </label>
                <div class="col-sm-9">
                    <div class="custom-control custom-switch custom-control-inline col-form-label">
                        <!-- <input type="checkbox" class="custom-control-input" id="customSwitch1"
                            [(ngModel)]="itemObj.isActive" [ngModelOptions]="{ standalone: true }" name="isActive"
                            [checked]="itemObj.isActive" /> -->
                        <input type="checkbox" class="custom-control-input" id="isExpireUnlimit"
                            formControlName="isExpireUnlimit" />
                        <label class="custom-control-label" for="isExpireUnlimit"></label>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="colFormLabelLg" class="col-sm-3 col-form-label">
                    {{"Form.Status" | translate}}</label>
                <div class="col-sm-9">
                    <div class="custom-control custom-switch custom-control-inline col-form-label">
                        <!-- <input type="checkbox" class="custom-control-input" id="customSwitch1"
                            [(ngModel)]="itemObj.isActive" [ngModelOptions]="{ standalone: true }" name="isActive"
                            [checked]="itemObj.isActive" /> -->
                        <input type="checkbox" class="custom-control-input" id="customSwitchStatus"
                            formControlName="isActive" />
                        <label class="custom-control-label" for="customSwitchStatus"></label>
                    </div>
                </div>
            </div>

            <br />
            <div class="form-group d-flex justify-content-between">
                <a class="btn btn-secondary" (click)="openCancelModal()">
                    {{ "Form.Cancel" | translate }}
                </a>

                <a class="btn btn-primary" (click)="openConfirmModal(itemObj)">
                    {{ "Form.Submit" | translate }}
                </a>
            </div>
        </form>
    </div>
</div>