<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section class="modern-horizontal-wizard">
      <div
        id="debit-stepper"
        class="bs-stepper wizard-modern modern-wizard-example"
      >
        <div class="bs-stepper-header">
          <div class="step" data-target="#account-details-modern">
            <button type="button" class="step-trigger disabled-button" disabled>
              <span class="bs-stepper-box">
                <i data-feather="file-text" class="font-medium-3"></i>
              </span>
              <span class="bs-stepper-label">
                <span class="bs-stepper-title">เอกสารอ้างอิง</span>
                <span class="bs-stepper-subtitle">Ref. Document</span>
              </span>
            </button>
          </div>
          <div class="line">
            <i data-feather="chevron-right" class="font-medium-2"></i>
          </div>
          <div class="step" data-target="#personal-info-modern">
            <button type="button" class="step-trigger disabled-button" disabled>
              <span class="bs-stepper-box">
                <i data-feather="user" class="font-medium-3"></i>
              </span>
              <span class="bs-stepper-label">
                <span class="bs-stepper-title">ระบุสาเหตุ</span>
                <span class="bs-stepper-subtitle">Document Detail</span>
              </span>
            </button>
          </div>
          <div class="line">
            <i data-feather="chevron-right" class="font-medium-2"></i>
          </div>
          <div class="step" data-target="#address-step-modern">
            <button type="button" class="step-trigger disabled-button" disabled>
              <span class="bs-stepper-box">
                <i data-feather="map-pin" class="font-medium-3"></i>
              </span>
              <span class="bs-stepper-label">
                <span class="bs-stepper-title">สร้างใบเพิ่มหนี้</span>
                <span class="bs-stepper-subtitle">Create Debit Note</span>
              </span>
            </button>
          </div>

          <div class="line">
            <i data-feather="chevron-right" class="font-medium-2"></i>
          </div>
          <div class="step" data-target="#social-links-modern">
            <button type="button" class="step-trigger disabled-button" disabled>
              <span class="bs-stepper-box">
                <i data-feather="link" class="font-medium-3"></i>
              </span>
              <span class="bs-stepper-label">
                <span class="bs-stepper-title">รับชำระ</span>
                <span class="bs-stepper-subtitle">Payment</span>
              </span>
            </button>
          </div>
        </div>

        <!-- content -->
        <div class="bs-stepper-content">
          <div id="account-details-modern" class="content">
            <app-select-credit-debit
              #selectCreditDebitComponent
              (getDataCallback)="handleCreditDebitData($event)"
              [titleDoc]="'Document.DebitNote' | translate"
              [apiPath]="'debitnote'"
              (formValidityChanged)="handleFormValidityChanged($event)"
            >
            </app-select-credit-debit>

            <div class="d-flex justify-content-end">
              <button
                class="btn btn-primary btn-next"
                (click)="nextPage()"
                [disabled]="!this.selectCreditDebitComponent.isValid()"
                rippleEffect
                [disabled]="nextButtonDisabled"
              >
                <span class="align-middle d-sm-inline-block d-none">{{
                  "General.Next" | translate
                }}</span>
                <i
                  data-feather="arrow-right"
                  class="align-middle ml-sm-25 ml-0"
                ></i>
              </button>
            </div>
          </div>

          <div id="personal-info-modern" class="content">
            <div class="content-header">
              <h5 class="mb-0">
                {{ "CreditDebit.DebitNoteInfo" | translate }}
              </h5>

              <small>{{ "CreditDebit.EnterDebitNoteInfo" | translate }}</small>
            </div>

            <form [formGroup]="personalInfoForm">
              <div class="row">
                <div class="col-sm-3 col-form-label">
                  {{ "CreditDebit.ReasonForIssuingDebitNote" | translate }}
                </div>
                <div class="form-group col-sm-9">
                  <ng-select
                    [items]="reasonList"
                    bindLabel="label"
                    bindValue="value"
                    formControlName="reasonId"
                    required
                  >
                  </ng-select>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-3 col-form-label">
                  {{ "CreditDebit.DescriptionOfReason" | translate }}
                </div>
                <div class="form-group col-sm-9">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="description"
                    required
                    maxlength="50"
                  />
                </div>
              </div>
            </form>
            <div class="d-flex justify-content-between">
              <button
                class="btn btn-primary btn-prev"
                (click)="previousPage()"
                rippleEffect
              >
                <i
                  data-feather="arrow-left"
                  class="align-middle mr-sm-25 mr-0"
                ></i>
                <span class="align-middle d-sm-inline-block d-none">{{
                  "General.Previous" | translate
                }}</span>
              </button>
              <button
                class="btn btn-primary btn-next"
                (click)="nextPageAddBill()"
                [disabled]="personalInfoForm.invalid"
                rippleEffect
              >
                <span class="align-middle d-sm-inline-block d-none">{{
                  "General.Next" | translate
                }}</span>
                <i
                  data-feather="arrow-right"
                  class="align-middle ml-sm-25 ml-0"
                ></i>
              </button>
            </div>
          </div>

          <div id="address-step-modern" class="content">
            <app-add-bill
              [data]="passObj"
              [isEditing]="true"
              [isEditForCreating]="true"
              [hideSubmit]="true"
              [docTitle]="'Document.DebitNote'"
              [docCodeColumn]="'debitNoteCode'"
              (dataChange)="onDataChange($event)"
              [isSellerDoc]="true"
              [isCreditDebitMode]="true"
              [isDebitNote]="true"
              [apiPath]="apiPath"
              [isDateChange]="true"
              [documentCode]="debitNoteCode"
              [isUseValidDate]="false"
              (itemInvalidChange)="onItemInvalidChange($event)"
              (sumTotalInvalidChange)="onSumTotalInvalidChange($event)"
              (totalDiscountInvalidChange)="
                onTotalDiscountInvalidChange($event)
              "
              (descriptionInvalidChange)="onDescriptionInvalidChange($event)"
              #addBillComponent
            ></app-add-bill>
            <div class="d-flex justify-content-between">
              <button
                class="btn btn-primary btn-prev"
                (click)="previousPage()"
                rippleEffect
              >
                <i
                  data-feather="arrow-left"
                  class="align-middle mr-sm-25 mr-0"
                ></i>
                <span class="align-middle d-sm-inline-block d-none">{{
                  "General.Previous" | translate
                }}</span>
              </button>
              <button
                class="btn btn-primary btn-next"
                (click)="nextPage(3); setPayObj()"
                rippleEffect
                [disabled]="
                  hasInvalidItem() ||
                  descriptionInvalid ||
                  hasInvalidSumTotal() ||
                  hasInvalidTotalDiscount()
                "
              >
                <span class="align-middle d-sm-inline-block d-none">{{
                  "General.Next" | translate
                }}</span>
                <i
                  data-feather="arrow-right"
                  class="align-middle ml-sm-25 ml-0"
                ></i>
              </button>
            </div>
          </div>

          <div id="social-links-modern" class="content">
            <app-payment
              [title]="title"
              [objData]="passObj"
              [payData]="payObj"
              [isDebitNote]="true"
              [runningNumber]="debitNoteCode"
              (callBackFunc)="handlePayment($event)"
            >
            </app-payment>

            <!-- <app-payment
            [isConfirm]="true"
            [title]="'Debit Note'"
            [objData]="quatationObj"
            [title]="title"
            (callBackFunc)="handlePayment($event)"></app-payment> -->

            <div class="d-flex justify-content-between">
              <button
                class="btn btn-primary btn-prev"
                (click)="previousFinalPage()"
                rippleEffect
              >
                <i
                  data-feather="arrow-left"
                  class="align-middle mr-sm-25 mr-0"
                ></i>
                <span class="align-middle d-sm-inline-block d-none">{{
                  "General.Previous" | translate
                }}</span>
              </button>
              <!-- <button
                type="submit"
                class="btn btn-success btn-submit"
                rippleEffect
                (click)="Submit(false, formDocument.value, false)"
              >
                {{ "Form.Submit" | translate }}
              </button> -->
              <button
                class="btn btn-success btn-submit"
                rippleEffect
                (click)="openConfirmModal(itemObj)"
              >
                {{ "Form.Submit" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
