import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment/locale/th';

@Pipe({
  name: 'thaidate',
})
export class ThaidatePipe implements PipeTransform {
  isUseBuddhistYear: boolean = false;

  transform(date: any, format: string, utc?: string): string {
    const momentdate = moment(date).locale('th');

    if (utc == 'UTC') {
      momentdate.utc();
    }

    const christianYear = momentdate.format('YYYY');
    const buddhistYear = (
      Number(christianYear) + (this.isUseBuddhistYear ? 543 : 0)
    ).toString();
    // const buddhistYear = (Number(christianYear)).toString();
    return momentdate
      .format(
        format
          .replace('YYYY', buddhistYear)
          .replace('YY', buddhistYear.substring(2, 4))
      )
      .replace(christianYear, buddhistYear);
  }
}
