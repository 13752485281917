import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { TruncateComponent } from './truncate.component';
import { AuthGuard } from 'app/auth/helpers';
import { TruncateDetailComponent } from './truncate-detail/truncate-detail.component';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { ComponentsModule } from 'app/main/components/components.module';
import { DataTablesModule } from 'angular-datatables';

const routes: Routes = [
  {
    path: 'admin/trash',
    component: TruncateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'truncate', roles: ['SuperAdmin'] },
  },
  {
    path: 'admin/trash/detail/:apiPath',
    component: TruncateDetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'truncate-detail', roles: ['SuperAdmin'] },
  },
];

@NgModule({
  declarations: [TruncateComponent, TruncateDetailComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    CoreCommonModule,
    ContentHeaderModule,
    ComponentsModule,
    DataTablesModule,
  ],
  exports: [TruncateComponent, TruncateDetailComponent],
})
export class TruncateModule {}
