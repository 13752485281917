import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { ApiService } from 'app/main/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsService } from '../../components.service';
import { MainConfig, filesPropNameConfig } from 'app/main/config/MainConfig';
import { Categories } from 'app/main/model/Categories';
import { CategoriesService } from 'app/main/pages/cms/categories/categories.service';
import { AuthenticationService } from 'app/auth/service';
import { ModalComponent } from '../../modal/modal.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2';
import { environment } from 'environments/environment';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { ImageUploadComponent } from '../../image-upload/image-upload.component';
import { FilesApiService } from 'app/main/service/files-api.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CustomDropzoneComponent } from '../../custom-dropzone/custom-dropzone.component';

@Component({
  selector: 'app-categories-form',
  templateUrl: './categories-form.component.html',
  styleUrls: ['./categories-form.component.scss']
})
export class CategoriesFormComponent implements OnInit {
  private _unsubscribeAll: Subject<any>;
  private dataSubscription: Subscription;
  private fileSubscription: Subscription;
  contentHeader: object;

  @BlockUI() blockUI: NgBlockUI;
  @Input() isUseMultipleLang: boolean = false;
  @Input() callbackUrl: string;
  ImageUploadComponent = {
    selected: false, // Replace this with the actual logic or value that sets the 'selected' variable in the ImageUploadComponent
  };
  filesPropName = filesPropNameConfig;
  @ViewChild('imageUploadComponent') imageUploadComponent: ImageUploadComponent;
  @ViewChild('CustomDropzone') dropzoneComponent: CustomDropzoneComponent;

  images: Blob[] = [];
  passData: File[] = [];
  imageUrls: SafeStyle[] = [];
  files: File[] = [];
  AlertFiles: Boolean;
  countMedia: number;

  categoriesForm: FormGroup;
  faqdescription: string[];
  itemGuid: string;
  categoriesObj: Categories;

  isEditing: boolean;
  isLoading: boolean;
  isSubmit: boolean;

  componentName: string;
  apiPath: string;
  pathUrl: string;

  modeText: string;
  summernoteConfig: any;

  isMultiLang: any = true;
  selectedLang: string = environment.langDefault;
  langList = environment.langContent;

  // categoriesList: Categories[] = categoriesList;

  constructor(
    private _apiService: ApiService,
    private _formBuilder: FormBuilder,
    private _categoriesService: CategoriesService,
    private _mainConfig: MainConfig,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _modalService: NgbModal,
    private _globalFuncService: GlobalFuncService,
    private _fileService: FilesApiService,
    private sanitizer: DomSanitizer,
  ) {
    this.SetLoadingState();
    this.initConfig();
    this.initForm(this.isEditing);
  }

  get f() {
    return this.categoriesForm.controls;
  }

  get arrayF() {
    let self = this.categoriesForm.get('faqdescription') as FormArray;
    return self.controls;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: this.modeText + ' ' + this.componentName,
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this.componentName,
            isLink: true,
            link: this.pathUrl,
          },
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  initConfig(): void {
    this._unsubscribeAll = new Subject();
    this.componentName = this._categoriesService.componentName;
    this.apiPath = this._categoriesService.apiPath;
    this.pathUrl = this._categoriesService.pathUrl;
    this.summernoteConfig = this._mainConfig.summernoteNewsConfig;

    this.isEditing = this._route.snapshot.paramMap.get('id') ? true : false;
    this.isLoading = this.isEditing ? true : false;

    this._apiService.SetHttpHeaders(this._authenticationService.tokenValue);

    this.modeText = this.isEditing
      ? this._translateService.instant('General.Edit')
      : this._translateService.instant('General.Create');

    this.categoriesForm = this._formBuilder.group({
      categoryname: ['', [Validators.required, Validators.maxLength(100)]],
    });

    const langCategoryNameControls: any = {};
    const langHelpCenterTitleControls: any = {};
    const langFaqTitleControls: any = {};
    const langFaqDescControls: any = {};
    this.langList.forEach((lang) => {
      for (const lang of this.langList) {
        langCategoryNameControls[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);

        langHelpCenterTitleControls[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);

        langFaqTitleControls[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);
      }
      // this.categoriesForm.addControl(
      //   'categoryname',
      //   this._formBuilder.group(langCategoryNameControls)
      // );

      this.categoriesForm.addControl(
        'helpcentertitle',
        this._formBuilder.group(langHelpCenterTitleControls)
      );

      // this.categoriesForm.addControl(
      //   'faqtitle',
      //   this._formBuilder.group(langFaqTitleControls)
      // );

      this.categoriesForm.addControl(
        'faqdescription',
        this._formBuilder.array([])
      );
    });

    if (!this.isEditing) {
      this.addItemRow();
    }
  }

  initForm(isEditing: boolean): void {
    var self = this;
    if (isEditing) {
      this.itemGuid = this._route.snapshot.paramMap.get('id');
      this._route.paramMap.subscribe((val) => {
        self.itemGuid = self._route.snapshot.paramMap.get('id');
        self.getCategoriesData(self.itemGuid);
        self.getItemData(self.itemGuid)
      });
    } else {
      this.categoriesObj = new Categories();
    }
  }

  getItemData(guid: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataSubscription = this._apiService
        .GetDataById(this.apiPath, guid)
        .subscribe(
          (res) => {
            const self = this;
            self.categoriesObj = res.data.resultData[0];

            if (self.categoriesObj.mediaFiles[self.filesPropName.item.coverImage]) {
              this._fileService
                .GetFile(
                  self.categoriesObj.mediaFiles[self.filesPropName.item.coverImage][0]
                    .id
                )
                .subscribe(
                  (res: Blob) => {
                    if (self.imageUploadComponent) {
                      self.imageUploadComponent.setCoverImage(
                        res,
                        self.categoriesObj.mediaFiles[
                          self.filesPropName.item.coverImage
                        ][0].id
                      );
                    }
                  },
                  (err) => {
                    this.blockUI.stop();
                  }
                );
            }

            if (self.categoriesObj.mediaFiles[self.filesPropName.item.detailImages]) {
              /* add loading บางครั้งรูปไม่โหลด */

              let detailImageArr =
                self.categoriesObj.mediaFiles[self.filesPropName.item.detailImages];
              let tempFilesList: { file: any; ordinal: number }[] = [];
              self.passData = [];

              // self.dropzoneComponent.startLoading();

              const fileFetchPromises: Promise<void>[] = detailImageArr.map(
                (element: { id: string; ordinal: number }) => {
                  return new Promise<void>((fileResolve, fileReject) => {
                    self._fileService.GetFile(element.id).subscribe(
                      (res) => {
                        const filename = element;
                        const type = res.type.split('/')[1];
                        const name = element + '.' + type;
                        const file = new File([res], name, { type: res.type });

                        tempFilesList.push({
                          file: file,
                          ordinal: element.ordinal,
                        });
                        fileResolve();
                      },
                      (error) => {
                        fileReject(error);
                      }
                    );
                  });
                }
              );
              Promise.all(fileFetchPromises)
                .then(() => {
                  if (tempFilesList.length == detailImageArr.length) {
                    tempFilesList.sort((a, b) => a.ordinal - b.ordinal);

                    tempFilesList.forEach((element) => {
                      this.images.push(element.file);
                      this.passData.push(element.file);
                    });

                    this.dropzoneComponent.ngOnChangesConfig(self.passData);
                  }

                  this.blockUI.stop();
                  resolve();
                })
                .catch((error) => {
                  this.blockUI.stop();
                  reject(error);
                });
            } else {
              this.blockUI.stop();
              resolve();
            }
          },
          (error) => {
            this.blockUI.stop();
            reject(error);
          }
        );
    });
  }

  setFormValue(): void {
    let tempHelpcentertitle = JSON.parse(this.categoriesObj.helpcentertitle);
    // let tempFaqtitle = JSON.parse(this.categoriesObj.faqtitle);
    let tempFaqdescription = JSON.parse(this.categoriesObj.faqdescription);
    console.log(tempFaqdescription)
    if (this.isEditing) {
      if (tempFaqdescription.length > 1) {
        tempFaqdescription.forEach(element => {
          this.addItemRow()
        });
      } else {
        this.addItemRow()
      }
    }
    this.categoriesForm
      .get(`categoryname`)
      .patchValue(this.categoriesObj.categoryname);
    this.langList.forEach((lang) => {
      this.categoriesForm
        .get(`helpcentertitle.${lang}`)
        .patchValue(tempHelpcentertitle[lang]);
      // this.categoriesForm
      //   .get(`faqtitle.${lang}`)
      //   .patchValue(tempFaqtitle[lang]);
    });

    tempFaqdescription.forEach((element, index) => {
      let tempFaqDescTitle = element['title']
      let tempFaqDescDescription = element['description']
      this.langList.forEach((lang) => {
        this.categoriesForm
          .get(`faqdescription.${index}.title.${lang}`)
          .patchValue(tempFaqDescTitle[lang]);
        this.categoriesForm
          .get(`faqdescription.${index}.description.${lang}`)
          .patchValue(tempFaqDescDescription[lang]);
      })
    })

    console.log('setted value');
  }

  getCategoriesData(guid: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService.GetDataById(this.apiPath, guid).subscribe((res) => {
        self.categoriesObj = res.data.resultData[0];
        self.setFormValue();
        self.isLoading = false;
      }, rejects);
    });
  }

  openConfirmModal(): void {
    let isConfirm = true;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.Edit');
    } else {
      var stateForm = this._translateService.instant('General.Create');
    }
    let title =
      this._translateService.instant('General.Confirm') +
      `${stateForm} ${this.componentName}`;
    let detail =
      this._translateService.instant('General.AreYouConfirm') +
      `${stateForm} ${this.componentName}`;
    if (this.categoriesForm.status === 'INVALID') {
      if (this.categoriesForm.status === 'INVALID') {
        Swal.fire(this._translateService.instant('Alert.Invalid'), '', 'warning')
      }
    } else {
      this.openModal(title, detail, isConfirm);
    }
  }

  openCancelModal(): void {
    let isConfirm = false;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.CancelEdit');
    } else {
      var stateForm = this._translateService.instant('General.CancelCreate');
    }
    let title =
      this._translateService.instant('General.Confirm') +
      `${stateForm} ${this.componentName}`;
    let detail =
      this._translateService.instant('General.AreYouConfirm') +
      `${stateForm} ${this.componentName}`;
    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    this.isSubmit = true;
    console.log(this.images)

    this.imageUploadComponent.handleImage();

    if (!this.categoriesForm.invalid) {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        if (IsConfirm) {
          this.submit();
        } else {
          this._router.navigate([`${this.pathUrl}`]);
        }
      });
    } else {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        this._router.navigate([`${this.pathUrl}`]);
      });
    }
  }

  prepareFinalData(data: any, tempForm: FormGroup) {
    for (let controlName in data.controls) {
      tempForm.addControl(
        controlName,
        this._formBuilder.control(data.get(controlName).value)
      );
    }

    let tempCateTitleObj = this.categoriesForm.get(`categoryname`).value;;
    // this.langList.forEach((lang) => {
    //   tempCateTitleObj[lang] = this.categoriesForm.get(
    //     `categoryname.${lang}`
    //   ).value;
    // });

    tempForm.removeControl('categoryname');
    tempForm.addControl(
      'categoryname',
      this._formBuilder.control(tempCateTitleObj, [])
    );

    let tempHelpTitleObj = {};
    this.langList.forEach((lang) => {
      tempHelpTitleObj[lang] = this.categoriesForm.get(
        `helpcentertitle.${lang}`
      ).value;
    });

    tempForm.removeControl('helpcentertitle');
    tempForm.addControl(
      'helpcentertitle',
      this._formBuilder.control(JSON.stringify(tempHelpTitleObj), [])
    );

    // let tempFaqTitleObj = {};
    // this.langList.forEach((lang) => {
    //   tempFaqTitleObj[lang] = this.categoriesForm.get(
    //     `faqtitle.${lang}`
    //   ).value;
    // });

    // tempForm.removeControl('faqtitle');
    tempForm.addControl(
      'faqtitle',
      this._formBuilder.control('', [])
    );

    let faqdescriptionArray = this.categoriesForm.get('faqdescription') as FormArray;
    tempForm.removeControl('faqdescription');
    tempForm.addControl(
      'faqdescription',
      this._formBuilder.control(JSON.stringify(faqdescriptionArray.value), [])
    );
    console.log(tempForm.value)
  }

  testPrePareData() {
    let tempForm = new FormGroup({});
    this.prepareFinalData(this.categoriesForm, tempForm);
  }

  submit(): void {
    var self = this;
    let isUpdatefile;
    this.isSubmit = true;
    if (this.categoriesForm.invalid) {
      console.log('Invalid');
      return;
    }

    let tempForm = new FormGroup({});
    this.prepareFinalData(this.categoriesForm, tempForm);

    if (this.isEditing) {
      console.log('Edit mode');
      isUpdatefile = true;
      this._apiService
        .UpdateDataById(
          this.apiPath,
          this.categoriesObj.id.toString(),
          tempForm.value
        )
        .subscribe(
          (res) => {
            console.log('res from update');
            const params = {
              isEdit: true,
              idResId: res.data.resultData[0],
              tablename: 'content',
              collectionName: this.filesPropName.item.coverImage,
              ordinal: 0,
            };
            this.imageUploadComponent.uploadImage(params);


            this._router.navigate([this.callbackUrl]);
            self._componentsService.SuccessSwal();
          },
          (err) => {
            self._componentsService.ErrorSwal();
          }
        );

      this._apiService
        .UpdateDataById(
          this.apiPath,
          this.categoriesObj.id.toString(),
          tempForm.value
        )
        .subscribe(
          (res) => {
            this._componentsService.SuccessSwal();
            this._router.navigate([`${this.pathUrl}`]);
          },
          (err) => {
            this._componentsService.ErrorSwal();
          }
        );
    } else {
      isUpdatefile = false;

      this._apiService.AddData(this.apiPath, tempForm.value).subscribe(
        (res) => {
          console.log('DOM : ' + res.data.resultData);

          const params = {
            isEdit: false,
            idResId: res.data.resultData[0],
            tablename: 'content',
            collectionName: this.filesPropName.item.coverImage,
            ordinal: 0,
          };

          // cover file upload
          this.imageUploadComponent.uploadImage(params);

          this._router.navigate([this.callbackUrl]);
          self._componentsService.SuccessSwal();
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
    }
  }

  uploadCoverFile(isUpdateFile: boolean, itemGuid: string, formData): void {
    // let itemGuid = this.itemObj.id.toString();
    console.log(formData);
    if (isUpdateFile) {
      this._fileService
        .UpdateCover(
          this.apiPath,
          itemGuid,
          this.filesPropName.item.coverImage,
          0,
          formData
        )
        .subscribe((res) => { });
    } else {
      this._fileService
        .AddCover(
          this.apiPath,
          itemGuid,
          this.filesPropName.item.coverImage,
          0,
          formData
        )
        .subscribe((res) => { });
    }
  }

  uploadFiles(
    isUpdateFile: boolean,
    itemGuid: string,
    collectionName: string,
    ordinal: number,
    filePool: any
  ): void {
    let fileArr = new FormData();
    filePool.forEach((file: Blob) => {
      // let fullFileName = file.name

      fileArr.append('files', file);
    });

    if (isUpdateFile) {
      this._fileService
        .UpdateFile(
          this.apiPath,
          this.categoriesObj.id.toString(),
          collectionName,
          ordinal,
          fileArr
        )
        .subscribe((res) => {
          // this._componentsService.SuccessSwal();
          return;
        });
    } else {
      this._fileService
        .AddFile(this.apiPath, itemGuid, collectionName, ordinal, fileArr)
        .subscribe((res) => {
          // this._componentsService.SuccessSwal();
          return;
        });
    }
  }

  onSelectImage(event) {
    console.log('Test' + this.images);
    this.AlertFiles = false;
    var self = this;
    var invalidFiles = false;
    for (let i = 0; i < event.addedFiles.length; i++) {
      if (event.addedFiles.length <= this.countMedia) {
        console.log(event.addedFiles.length + this.images.length);
        if (event.addedFiles.length + this.images.length > this.countMedia) {
          this.AlertFiles = true;
        }
        const file = event.addedFiles[i];
        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            self.images.push(file);
          };
          img.src = URL.createObjectURL(file);
        };
        reader.readAsDataURL(file);
      } else {
        console.log('Media files limit 5 items');
        this.AlertFiles = true;
      }
    }
    // this.files.push(...event.addedFiles);
  }
  getBackgroundStyle(image: Blob): SafeStyle {
    const url = URL.createObjectURL(image);
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }
  onSelectAtteched(event, arr) {
    var invalidFiles = false;
    for (let i = 0; i < event.addedFiles.length; i++) {
      const file = event.addedFiles[i];
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          arr.push(file);
        };
        img.src = URL.createObjectURL(file);
      };
      reader.readAsDataURL(file);
    }
    // this.files.push(...event.addedFiles);
  }
  onDragStart(event: DragEvent, index: number) {
    event.dataTransfer?.setData('text/plain', String(index));
    console.log(event);
  }

  onDragEnter(event: DragEvent, targetIndex: number) {
    console.log(event);
    event.preventDefault();
    const dragIndex = Number(event.dataTransfer?.getData('text/plain'));
    if (dragIndex !== targetIndex) {
      // Reorder the array elements
      const [draggedItem] = this.images.splice(dragIndex, 1);
      this.images.splice(targetIndex, 0, draggedItem);

      // Update the imageUrls array accordingly
      const [draggedImageUrl] = this.images.splice(dragIndex, 1);
      this.images.splice(targetIndex, 0, draggedImageUrl);
    }
  }

  onDragEnd(event: DragEvent) {
    event.preventDefault();
  }
  onRemoveAtteched(event, arr) {
    arr.splice(arr.indexOf(event), 1);
    if ((arr.length + this.images.length) / 2 <= this.countMedia) {
      this.AlertFiles = false;
    }
    console.log((arr.length + this.images.length) / 2);
  }
  onDrop(event: DragEvent) {
    console.log(event);

    event.preventDefault();
    const index = Number(event.dataTransfer?.getData('text/plain'));
    // Implement your logic here for handling the drop event
    // For example, you can reorder the images based on the drag-and-drop action.
  }

  onDragOver(event: DragEvent) {
    console.log(event);
    event.preventDefault();
  }

  onFilesUploaded(files: File[]): void {
    // Here you have access to the files array uploaded from the child component.
    // You can now handle the files in the parent component as you desire.
    console.log('Uploaded files:', files);
    this.images = files;
  }

  get arrayForm() {
    return this.categoriesForm.controls["faqdescription"] as FormArray;
  }

  addItemRow(): void {
    const descForm: any = {}
    const langTitleControls: any = {};
    const langDescControls: any = {};
    this.langList.forEach((lang) => {
      for (const lang of this.langList) {
        langTitleControls[lang] = '', [Validators.required];

        langDescControls[lang] = '', [Validators.required];
      }
    });
    descForm['title'] = this._formBuilder.group(
      langTitleControls
    );
    descForm['description'] = this._formBuilder.group(
      langDescControls
    );
    this.arrayForm.push(this._formBuilder.group(descForm));
  }

  trackBy(idx: number, item: { id: number }): number {
    return item.id;
  }

  removeRow(index: number): void {

    this.arrayForm.removeAt(index);

  }

  selectedLanguage(lang: string) {
    this.selectedLang = lang;
  }
}
