<div class="form-group">
  <label for="basicInputFile"
    >Choose only .jpg, .jpeg, .png, .doc, .docx, .xlsx, .pdf</label
  >
  <div class="custom-file">
    <input
      (change)="onFileSelected($event)"
      accept="image/jpeg, image/png, image/jpeg, application/msword, application/vnd.ms-excel, application/pdf"
      type="file"
      class="custom-file-input"
      id="customFile"
    />
    <label class="custom-file-label" for="customFile">{{ selectedFileName }}</label>
  </div>
</div>
