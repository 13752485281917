import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'app/main/service/api.service';
import { ModalComponent } from '../modal/modal.component';
import { TranslateService } from '@ngx-translate/core';
import Content from 'app/main/model/Content';

@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
})
export class ContentCardComponent implements OnInit {
  @Input() itemObjList: Content[];
  @Input() mainPathUrl: string;
  @Input() editPathUrl: string;

  @Input() isForGroupTemplate: boolean;
  @Input() isGoEditPage:boolean;

  @Output('isActiveFunc') isActiveFunc: EventEmitter<any> = new EventEmitter();
  @Output('deleteFunc') deleteFunc: EventEmitter<any> = new EventEmitter();
  @Output('refreshDataFunc') refreshDataFunc: EventEmitter<any> = new EventEmitter();

  activeModal: any;
  currentLang: string;

  constructor(
    private _modalService: NgbModal,
    private _translateService: TranslateService
  ) {
    this.currentLang = this._translateService.currentLang;
  }

  openDeleteContentModal(content: any) {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = 'Delete';
    modalRef.componentInstance.detail = `are you confirm to delete ${content.name}`;
    // modalRef.componentInstance.confirmUrl = this.pathUrl;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.deleteContent(content.id);
    });
  }

  openIsActiveModal(itemObj, status): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    let modeText = status
      ? this._translateService.instant('Status.Published')
      : this._translateService.instant('Status.Unpublished');
    modalRef.componentInstance.title = `${modeText}`;
    modalRef.componentInstance.detail = `are you confirm to ${modeText} ${itemObj.name}`;
    // modalRef.componentInstance.confirmUrl = this.pathUrl;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.setIsActiveContent(itemObj.id, status);
    });
  }

  setIsActiveContent(id, status): void {
    this.isActiveFunc.emit({ id: id, status: status });
  }

  deleteContent(id): void {
    this.deleteFunc.emit(id);
  }

  refreshData(): void {
    this.refreshDataFunc.emit();
  }

  ngOnInit(): void {
    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });
  }
}
