import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { DocumentService } from 'app/main/pages/document/document.service';
import { ApiService } from 'app/main/service/api.service';
import { CalculateFuncService } from 'app/main/service/calculate-func.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { CurrencyPipe } from '@angular/common';
import { AddBillComponent } from '../document/template/add-bill/add-bill.component';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { PaymentMethodList } from 'app/main/model/PaymentMethod';
import { PaymentAction } from 'app/main/model/PaymentAction';
import { ModalManagePaymentComponent } from '../modal-manage-payment/modal-manage-payment.component';
import { BankAccount } from 'app/main/model/BankAccount';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  @Input() isConfirm: boolean;
  @Input() title: string;
  @Input() objData: any;
  @Input() payData: any;
  @Input() isDebitNote: boolean = false;
  @Input() runningNumber: string;
  //@Input() detail: string;
  //@Input() confirmUrl: string;
  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();

  balance: number = 0.0;
  isCheckPayment: boolean = true;
  isCheckWhtTax: boolean = false;
  payForm: FormGroup;
  discountWthTax: number;
  payAmount: number;
  whtList: any[] = [
    // { value: 0, label: 'ยังไม่ระบุ' },
    { value: 0, label: 'ไม่มี' },
    { value: 0.75, label: '0.75%' },
    { value: 1, label: '1%' },
    { value: 1.5, label: '1.5%' },
    { value: 2, label: '2%' },
    { value: 3, label: '3%' },
    { value: 5, label: '5%' },
    { value: 10, label: '10%' },
    { value: 15, label: '15%' },
    // { value: 0, label: 'กำหนดเอง' },
  ];

  paymentTypeApiPath: string;

  paymentData: any;
  loadingPaymentList: boolean = false;
  isUseWHT: boolean = environment.transaction.isUseWht;

  isMaxTodayDate: boolean = true;

  currentDate: any = new Date().toISOString();
  selectedDate: any;

  DefaultDateOption: FlatpickrOptions = {
    defaultDate: this.currentDate,
    altInput: true,
    altFormat: 'd/m/Y',
    dateFormat: 'd/m/Y',
  };

  maxTodayDateOption: FlatpickrOptions = {
    defaultDate: this.currentDate,
    altInput: true,
    altFormat: 'd/m/Y',
    dateFormat: 'd/m/Y',
    maxDate: this.currentDate,
  };

  paymentRemarks: string;

  isSubmit: boolean = false;

  selectedPayment: any = null;
  selectedBankAccount: any = null;

  paymentList: any[];
  bankAccountList: BankAccount[];

  isLoadingBankAccount: boolean = false;

  isTransfer: boolean = false;
  isCreditDebitCard: boolean = false;
  isCheque: boolean = false;
  isOther: boolean = false;

  constructor(
    private router: Router,
    private _globalFuncService: GlobalFuncService,
    private formBuilder: FormBuilder,
    private _calculateService: CalculateFuncService,
    private _apiService: ApiService,
    private _documentService: DocumentService,
    private _translateServeice: TranslateService,
    private _modalService: NgbModal
  ) {
    this.payForm = this.formBuilder.group({
      paymentDate: [null, []],
      selectedPayment: 0,
      selectedBankAccount: null,
      bankAccountId: null,
      payAmount: 0.0,
      whtRate: 0.0,
      discountWthTax: 0.0,
      paymentRemarks: '',
    });
    this.paymentTypeApiPath = this._documentService.paymentType.ApiPath;
    this.initData();
  }

  initData() {
    this.balance = 0.0;
    this.getPayment();
  }

  callBack(): void {
    // this.payForm.patchValue({
    //   paymentTypeId: this.selectedPayment,
    //   paymentDate: this.selectedDate.toISOString(),
    // });

    console.log('calllback payForm value : ', this.payForm.value);
    this.callBackFunc.emit(this.payForm.value);
  }

  removeComma(element) {
    let formattedAmount: string;
    formattedAmount = element.target.value;
    element.target.value = formattedAmount.replace(/,/g, '');
  }

  convertNumbertoMoneyFormat(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }

  convertMoneytoNumber(value: string): number {
    return this._globalFuncService.MoneyFormatToNumber(value);
  }

  togglePayment(): void {
    this.isCheckPayment = !this.isCheckPayment;
  }

  toggleWhtTax(): void {
    this.isCheckWhtTax = !this.isCheckWhtTax;
  }

  clearWhtRate(): void {
    this.discountWthTax = 0.0;
  }

  get f() {
    return this.payForm.controls;
  }

  calculateBalance() {
    const inputElement = document.getElementById(
      'discountWthTax'
    ) as HTMLInputElement;
    const inputValue = inputElement.value;
    const grandTotal = this.convertMoneytoNumber(
      this.payData ? this.payData.grandTotalPrice : '0.0'
    );
    const inputNumber = parseFloat(inputValue.replace(/,/g, ''));

    if (!isNaN(inputNumber)) {
      const balance = grandTotal - inputNumber;
      // Do something with the calculated balance, such as updating a variable or displaying it on the UI
      console.log('Grand Total:', grandTotal);
      console.log('Input Number:', inputNumber);

      console.log('Balance:', balance);

      // If you want to update the UI with the calculated balance, you can do something like this:
      const balanceElement = document.getElementById(
        'calculatedBalance'
      ) as HTMLElement;
      balanceElement.innerText = this.convertNumbertoMoneyFormat(balance); // Assuming you have a function to convert the number to a formatted string.
    }
  }

  ngOnChanges() {
    console.log('test', this.payData);

    this.payAmount = this.convertMoneytoNumber(
      this.payForm.get('payAmount').value
    );
    this.discountWthTax = this.convertMoneytoNumber(
      this.payForm.get('discountWthTax').value
    );

    //calculate balance when discountWthTax change
    this.calculateBalance();
  }

  getBankAccount(id?: number) {
    this.isLoadingBankAccount = true;

    const params = {
      isActive: true,
      isDelete: false,
    };
    const apiPath = 'BankAccount';

    this._apiService.GetAllData(apiPath, params).subscribe((res) => {
      this.bankAccountList = res.data.resultData;
      this.bankAccountList.sort((a, b) =>
        a.isUseForDocument === b.isUseForDocument
          ? 0
          : a.isUseForDocument
          ? -1
          : 1
      );

      console.log('bankAccountList : ', this.bankAccountList);

      if (id) {
        let matchingBankAccount = this.bankAccountList.find(
          (item) => item.id == id
        );
        console.log('matchingBankAccount : ', matchingBankAccount);
        this.selectedBankAccount = matchingBankAccount
          ? matchingBankAccount.id
          : null;
      } else {
        this.selectedBankAccount = this.bankAccountList[0].id;
      }

      this.isLoadingBankAccount = false;
    });
  }

  getPayment() {
    this.loadingPaymentList = true;

    let params = {
      isActive: true,
      isDelete: false,
    };
    // this._apiService
    //   .GetAllData(this.paymentTypeApiPath, params)
    //   .subscribe((res) => {
    //     this.paymentList = res.data.resultData.map(
    //       (payment: { id: any; name: string }) => ({
    //         id: payment.id,
    //         name: this._translateServeice.instant('Payment.' + payment.name),
    //       })
    //     );

    this.paymentList = Object.values(PaymentMethodList).map(
      (payment: { id: any; name: string }) => ({
        id: payment.id,
        name: this._translateServeice.instant('Payment.' + payment.name),
      })
    );

    if (this.paymentList && this.paymentList.length > 0) {
      this.selectedPayment = this.paymentList[0].id;
      this.isMaxTodayDate = true;
    }

    this.loadingPaymentList = false;
    console.log('isMaxTodayDate : ', this.isMaxTodayDate);
    console.log('paymentList : ', this.paymentList);
    console.log('selectedPayment : ', this.selectedPayment);
    // });
  }

  onDateChange(date: Date[]) {
    let tempDate = this.payForm.get('paymentDate').value;
    this.selectedDate = new Date(tempDate);
    console.log(this.selectedDate);
    console.log(this.selectedDate.toISOString());

    this.payForm.patchValue({
      paymentDate: this.selectedDate.toISOString(),
    });
  }

  onPaymentMethodChange() {
    switch (this.selectedPayment.toLowerCase()) {
      case PaymentMethodList.Transfer.id.toLowerCase():
        this.getBankAccount();
        this.setFlags(true, false, false, false);
        break;
      // case PaymentMethodList.CreditDebit.id.toLowerCase():
      //   this.setFlags(false, true, false, false);
      //   break;
      // case PaymentMethodList.Cheque.id.toLowerCase():
      //   this.setFlags(false, false, true, false);
      //   this.isMaxTodayDate = false;
      //   break;
      // case PaymentMethodList.Other.id.toLowerCase():
      //   this.setFlags(false, false, false, true);
      //   break;
      default:
        this.isMaxTodayDate = true;
        this.setFlags(false, false, false, false);
        this.payForm.get('paymentDate').patchValue(new Date().toISOString());
        break;
    }

    console.log('selectedPayment : ', this.selectedPayment);
    console.log('isMaxTodayDate : ', this.isMaxTodayDate);
  }

  setFlags(
    transfer: boolean,
    creditDebit: boolean,
    cheque: boolean,
    other: boolean
  ) {
    this.isTransfer = transfer;
    this.isCreditDebitCard = creditDebit;
    this.isCheque = cheque;
    this.isOther = other;
  }

  onPaymentCanCreateChange(event: string) {
    if (event === PaymentAction.BankAccount.Create) {
      console.log('Open Modal Create Bank Account');
      this.openManagePaymentModal(event);
    } else {
      // Handle the selection of a regular bank account option.
      // The selected bank account ID is available in this.selectedBankAccount.
    }
  }

  onPaymentRemarks() {
    this.paymentRemarks = this.payForm.get('paymentRemarks').value;
    this.payForm.get('paymentRemarks').patchValue(this.paymentRemarks);
  }

  openManagePaymentModal(event: string) {
    const modalRef = this._modalService.open(ModalManagePaymentComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
    });
    modalRef.componentInstance.actionPayment = event;
    modalRef.componentInstance.setMode = false;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.selectedBankAccount = null;
    });
    modalRef.componentInstance.refreshData.subscribe((res) => {
      this.getBankAccount(res[0]);
    });
  }

  onFormatAmountBlur() {
    const inputField = document.getElementById('payAmount') as HTMLInputElement;
    const value = parseFloat(inputField.value.replace(/,/g, ''));
    inputField.value = this.convertNumbertoMoneyFormat(value);
    this.calculateBalance();
  }

  calculateAmountwithTaxRate() {
    const whtRate = this.payForm.get('whtRate').value;
    console.log(
      this._calculateService.calculateWHT(
        this.convertMoneytoNumber(this.payForm.get('payAmount').value),
        whtRate
      )
    );
    this.discountWthTax = this._calculateService.calculateWHT(
      this.convertMoneytoNumber(this.payForm.get('discountWthTax').value),
      whtRate
    );
  }

  ngOnInit(): void {
    this.payForm = this.formBuilder.group({
      docId: this.objData.id,
      paymentDate: this.selectedDate,
      paymentTypeId: [this.selectedPayment, Validators.required],
      payAmount: this.payData ? this.payData.preWHTAmountTotal : 0.0,
      bankAccountId: [this.selectedBankAccount, Validators.required],
      whtRate: 0.0,
      discountWthTax: this.payData ? this.payData.sumWhtTax : 0.0,
      paymentRemarks: this.paymentRemarks,
    });

    this.callBackFunc.emit(this.payForm.value);
    if (this.payData && this.payData.summaryPrice) {
      this.payAmount = parseFloat(this.payData.summaryPrice.replace(/,/g, ''));
    } else {
      this.payAmount = null;
    }
  }

  ngOnDestroy(): void {}

  checkInvalidForm(): boolean {
    this.isSubmit = true;

    if (this.selectedBankAccount == null && this.isTransfer) {
      return true;
    } else {
      return false;
    }
  }

  getTypePaymentId(): string {
    return this.payForm.get('paymentTypeId').value;
  }

  getResult(): FormGroup {
    console.log('payForm value : ', this.payForm.value);
    return this.payForm;
  }
}
