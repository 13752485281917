import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleSet } from 'app/auth/models/roleSet';
import { ApiService } from 'app/main/service/api.service';
import { ComponentsService } from '../../components.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../modal/modal.component';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss'],
})
export class RoleFormComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  componentName: string = 'RoleSet';
  modeText: string;

  isLoading: boolean;
  isSubmitted: boolean;
  isEditing: boolean;

  apiPath: string = 'roleSet';

  roleGuid: string;
  roleObj: RoleSet;
  registerForm: FormGroup;

  @Input() callbackUrl: string;

  constructor(
    private _formBuilder: FormBuilder,
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _componentsService: ComponentsService,
    private _modalService: NgbModal,
    private _translateService: TranslateService
  ) {
    this.registerForm = this._formBuilder.group({
      roleName: ['', [Validators.required, Validators.maxLength(50)]],
    });

    this.roleGuid = this._route.snapshot.paramMap.get('id');

    this.isEditing = this.roleGuid ? true : false;

    this.modeText = this.isEditing
      ? this._translateService.instant('General.Edit')
      : this._translateService.instant('General.Create');

    if (this.isEditing) {
      this.getRoleData(this.roleGuid);
    } else {
      this.roleObj = new RoleSet();
      this.setFormValue();
    }
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  ngOnInit(): void {}

  get f() {
    return this.registerForm.controls;
  }

  getRoleData(id: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService.GetDataById(this.apiPath, id).subscribe((res) => {
        self.roleObj = res.data.resultData[0];
        self.setFormValue();
      });
    });
  }

  setFormValue(): void {
    var self = this;
    this.registerForm.patchValue({
      roleName: self.roleObj.roleName,
    });
  }

  Submit(): void {
    this.blockUI.start();
    this.isSubmitted = true;

    if (this.registerForm.invalid) {
      this._componentsService.ErrorSwal();
      return;
    }

    if (this.isEditing) {
      this._apiService
        .UpdateDataById(this.apiPath, this.roleGuid, this.registerForm.value)
        .subscribe(
          (res) => {
            this._componentsService.SuccessSwal();
            this._router.navigate([this.callbackUrl]);
          },
          (err) => {
            this._componentsService.ErrorSwal();
          }
        );
    } else {
      this._apiService.AddData(this.apiPath, this.registerForm.value).subscribe(
        (res) => {
          this._componentsService.SuccessSwal();
          this._router.navigate([this.callbackUrl]);
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
    }
  }

  openConfirmModal(): void {
    let isConfirm = true;
    let title;
    let detail;
    let contentNameText = this.isEditing
      ? this.roleObj.roleName
      : this.componentName;
    if (this.isEditing) {
      title = this._translateService.instant('ManageRole.ConfirmEditTitle');
      detail = this._translateService.instant('ManageRole.ConfirmEdit');
    } else {
      title = this._translateService.instant('ManageRole.ConfirmCreateTitle');
      detail = this._translateService.instant('ManageRole.ConfirmCreate');
    }
    this.openModal(title, detail, isConfirm);
  }

  openCancelModal(): void {
    let isConfirm = false;
    let title;
    let detail;
    let contentNameText = this.isEditing
      ? this.roleObj.roleName
      : this.componentName;
    if (this.isEditing) {
      title = this._translateService.instant('ManageRole.CancelEditTitle');
      detail = this._translateService.instant('ManageRole.CancelEdit');
    } else {
      title = this._translateService.instant('ManageRole.CancelCreateTitle');
      detail = this._translateService.instant('ManageRole.CancelCreate');
    }
    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      if (IsConfirm) {
        this.Submit();
      }
      this._router.navigate([this.callbackUrl]);
    });
  }
}
