import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { IsActivePipe } from './isActiveFilter.pipe';
import { ThaidatePipe } from './thaidate.pipe';
import { KeysPipe } from './keys.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { FileTypeSplitPipe } from './file-type-split.pipe';

@NgModule({
  declarations: [
    InitialsPipe,
    FilterPipe,
    StripHtmlPipe,
    SafePipe,
    IsActivePipe,
    ThaidatePipe,
    KeysPipe,
    SafeUrlPipe,
    FileTypeSplitPipe,
  ],
  imports: [],
  exports: [
    InitialsPipe,
    FilterPipe,
    StripHtmlPipe,
    SafePipe,
    IsActivePipe,
    ThaidatePipe,
    KeysPipe,
    SafeUrlPipe,
    FileTypeSplitPipe,
  ],
})
export class CorePipesModule {}
