import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CertificationsCardComponent } from './certifications-card.component';
import { CertCardCreateComponent } from './cert-card-create/cert-card-create.component';
import { CoreCommonModule } from '@core/common.module';
import { CmsListModule } from '../../cms-list/cms-list.module';
import { CmsFormpageModule } from '../../cms-formpage/cms-formpage.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';

const path: string = 'cms/repove-tools/certification/card'

const routes: Routes = [
  {
    path: `${path}`,
    component: CertificationsCardComponent,
    canActivate: [AuthGuard],
    data: { animation: 'certifications-file' },
  },
  {
    path: `${path}/create`,
    component: CertCardCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'certifications-file-create' },
  },
  {
    path: `${path}/edit/:id`,
    component: CertCardCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'certifications-file-edit' },
  },
];

@NgModule({
  declarations: [
    CertificationsCardComponent,
    CertCardCreateComponent
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    CmsListModule,
    CmsFormpageModule,
    RouterModule.forChild(routes)
  ]
})
export class CertificationsCardModule { }
