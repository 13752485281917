import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { TimelineItem } from 'app/main/components/horizontal-timeline/horizontal-timeline.component';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { Quotation, TempRowQuotation } from 'app/main/model/Quotation';
import { ApiService } from 'app/main/service/api.service';
import { CalculateFuncService } from 'app/main/service/calculate-func.service';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { DocumentService } from '../../document.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tax-detail',
  templateUrl: './tax-detail.component.html',
  styleUrls: ['./tax-detail.component.scss'],
})
export class TaxDetailComponent implements OnInit {
  contentHeader: object;
  typeDocName: string;
  currentUser: User;
  timelineData: TimelineItem[];

  taxObj: Quotation;
  customerAddressObj: CustomerAddress;

  orderId: string;
  orderStatus: any;
  currentStep: number;

  apiPath: string;
  editPathUrl: string;
  detailPathUrl: string;

  invoiceID: string;
  docID: string;
  docStatus: number;
  isLoading: boolean;

  sumNetPrice: number;
  sumVatPrice: number;

  titleDoc: string = 'ใบกำกับภาษี';
  refDetailUrl: string;

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private _documentService: DocumentService,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _documentApiService: DocumentApiService,
    public _route: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private _apiService: ApiService,
    private _calculateService: CalculateFuncService,
    private _globalFuncService: GlobalFuncService
  ) {
    this.timelineData = this._documentService.timelineData;
    this.apiPath = this._documentService.Tax.ApiPath;
    this.editPathUrl = this._documentService.Tax.EditPathURL;
    this.detailPathUrl = this._documentService.Tax.DetailPathURL;

    this.refDetailUrl = this._documentService.invoice.DetailPathURL;

    this.initData();
  }

  SetLoadingState() {
    this.isLoading = true;
  }
  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;

    this._authenticationService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    this.contentHeader = {
      headerTitle: 'Document.Tax',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Document',
            isLink: true,
            link: '/',
          },
          {
            name: 'Document.Tax',
            isLink: true,
            link: '/document/tax/view',
          },
          {
            name: 'FormName.TaxInvoiceDetail',
            isLink: false,
            link: '/document/tax/view',
          },
        ],
      },
    };
  }

  activeTab: string = 'detail';
  @ViewChild('navFilled') nav: NgbNav;
  public active_fragment: BehaviorSubject<string> = new BehaviorSubject('');
  
  ngAfterViewInit(): void {
    this._route.fragment.subscribe((frag) => {
      this.active_fragment.next(frag);
      
      console.log(this.active_fragment.value)
      if (!this.active_fragment.value) {
        this.nav.select('detail')
        this.activeTab = 'detail'
        console.log('select default page')
      } else {
        this.activeTab = this.active_fragment.value
      }
      
    });
  }

  initData(): void {
    this.SetLoadingState();
    this.blockUI.start();
    this.typeDocName = 'taxInvoiceCode';
    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
    });
    console.log('Doc id : ' + this.docID);
    console.log(this.apiPath);

    this.getTaxInvoiceData();
  }

  refreshData() {
    this.initData();
  }

  getTaxInvoiceData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocData(this.apiPath, this.docID)
        .subscribe((res) => {
          self.taxObj = res.data.resultData[0];

          this.invoiceID = self.taxObj.invoiceId;
          console.log('taxObj invoiceID: ', this.invoiceID);

          self.getCustomerAddress(self.taxObj.customerId);
          self.calculateSummary();
          self.SetLoadedState();

          this.docStatus = self.taxObj.transactionStatusId;
          console.log('Current Status: ', this.docStatus);

          this.orderId = self.taxObj.orderId;

          this.getOrderStatus(this.orderId).subscribe((orderStatusRes) => {
            this.orderStatus = orderStatusRes.data.resultData;

            console.log('Order Status: ', this.orderStatus[0]);

            for (let orderStatus of this.orderStatus) {
              for (let invoice of orderStatus.invoice) {
                if (invoice.id === this.invoiceID) {
                  this.currentStep = invoice.transactionStatusId;
                  break;
                }
              }
              if (this.currentStep !== undefined) {
                break;
              }
            }

            if (this.currentStep === undefined) {
              console.error('No matching invoice found');
            }

            this.blockUI.stop();

            // this.currentStep = this._globalFuncService.GetCurrentStep(this.orderStatus);
            console.log('Current Step: ', this.currentStep);
          });
        });
    });
  }

  getOrderStatus(orderId: string): Observable<any> {
    return this._apiService.GetDataById('Order/State', orderId);
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData.find((it) => {
            return it['useForShipping'] == true;
          });
        });
    });
  }

  calculateSummary() {
    let itemList = [];

    this.taxObj.item.forEach((element, index) => {
      let tempRow: TempRowQuotation = new TempRowQuotation();
      tempRow.rowType = 'item';
      tempRow.itemId = element.itemId;
      tempRow.description = element.description[0].description;
      tempRow.discount = element.discount;
      tempRow.quantity = element.quantity;
      tempRow.unit = element.unit;
      tempRow.unitPrice = element.unitPrice;
      tempRow.whtRate = 0.0;

      itemList.push(tempRow);
    });

    this.sumNetPrice = this._calculateService.calculateNet(
      itemList,
      true,
      this.taxObj.taxRate,
      this.taxObj.discount
    );

    this.sumVatPrice = this._calculateService.calculateVat(
      this.sumNetPrice,
      this.taxObj.taxRate
    );
  }

  convertNumbertoMoneyFormat(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }
}
