import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/main/service/api.service';

@Component({
  selector: 'app-modal-tracking-number',
  templateUrl: './modal-tracking-number.component.html',
  styleUrls: ['./modal-tracking-number.component.scss']
})
export class ModalTrackingNumberComponent implements OnInit {
  @Input() id: number;
  @Input() title: string;
  @Input() DeliveryProvider: string;
  @Input() deany

  trackingForm: FormGroup
  Deliverys: any[] 
  currentLang: string;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private _router: Router,
    private _translateService: TranslateService,
  ) {
    this.trackingForm = this.formBuilder.group({
      trackingNumber: ['', Validators.required],
      DeliveryProviderId: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.GetDeliveryProviderName();
  }

  GetDeliveryProviderName(): void {
    let params = {
      IsUsing: 'true',
    };

    this.apiService.GetAllData('MasterDeliveryProvider',params).subscribe(res => {
      this.Deliverys = res.data.resultData;
      console.log('Deliverys', this.Deliverys);
      
    })
  }

  closeModal(): void {
    this.activeModal.close();
  }

  Submit(): void {
    console.log(this.trackingForm.value);
    
    if(this.trackingForm.valid){
      this.apiService.AddData(`Invoice/${this.id}/Tracking`, this.trackingForm.value).subscribe(res => {
        console.log(res);
        this.activeModal.close();
        this._router.navigate(['document/invoice/view']);
        
      })
    }
  }
}
