<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section>
      <app-horizontal-timeline
        [timelineItems]="timelineData"
        [currentStep]="
          docStatus == 8
            ? 5
            : currentStep == 4
            ? 2
            : currentStep == 5
            ? 4
            : undefined
        "
        [currentStepNumber]="
          docStatus == 8
            ? 5
            : currentStep == 4
            ? 2
            : currentStep == 5
            ? 4
            : undefined
        "
        [lastItems]="{
          label: 'Void',
          descript: 'ยกเลิก',
          stepCount: 5
        }"
      ></app-horizontal-timeline>
    </section>

    <section>
      <!-- <div class="card">
        <div class="card-body">
          <div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
            <app-loading></app-loading>
          </div>

          <div *ngIf="!isLoading">
            <app-content-document
              [titleDoc]="'Document.Tax' | translate"
              [customerAddressObj]="customerAddressObj"
              [apiPath]="apiPath"
              [typeDocName]="typeDocName"
              [detailObj]="taxObj"
              [docId]="docID"
              [docStatus]="docStatus"
              [editPathUrl]="editPathUrl"
              [isDisabledEditBtn]="true"
              [refDocID]="'invoiceId'"
              [refDetailUrl]="refDetailUrl"
            >
            </app-content-document>

            <div class="">
              <div class="d-flex">
                <i data-feather="file-text" class="mr-1"></i>
                <span>แนบไฟล์ในเอกสารนี้</span>
              </div>

              <app-attached-file-uploader></app-attached-file-uploader>
            </div>
          </div>
        </div>
      </div> -->
      <div class="card">
        <!-- Nav tabs -->
        <div
          class="card-header"
          style="border-bottom: 1px dashed rgba(161, 161, 161, 0.3)"
        >
          <span></span>
          <!-- <h2>{{ "Document.DebitNote" | translate }}</h2> -->
          <ul
            ngbNav
            #navFilled="ngbNav"
            [activeId]="activeTab"
            class="nav nav-tabs mb-0 bg-white border"
            [destroyOnHide]="false"
          >
            <li [ngbNavItem]="'detail'">
              <a ngbNavLink routerLink="." [fragment]="'detail'">{{
                "General.DocumentInfo" | translate
              }}</a>
              <ng-template ngbNavContent>
                <app-content-document
                  [customerAddressObj]="customerAddressObj"
                  [titleDoc]="'Document.Tax' | translate"
                  [apiPath]="apiPath"
                  [typeDocName]="typeDocName"
                  [detailObj]="taxObj"
                  [invoiceDetailObj]="taxObj"
                  [docId]="docID"
                  [docStatus]="docStatus"
                  [editPathUrl]="editPathUrl"
                  [detailPathUrl]="detailPathUrl"
                  [isDisabledEditBtn]="true"
                  [refDetailUrl]="refDetailUrl"
                  [refDocID]="'invoiceId'"
                  (refreshData)="refreshData()"
                >
                </app-content-document>
              </ng-template>
            </li>

            <li [ngbNavItem]="'historyInfo'">
              <a ngbNavLink routerLink="." [fragment]="'historyInfo'">{{
                "General.HistoryInfo" | translate
              }}</a>
              <ng-template ngbNavContent>
                <app-action-history
                  [documentId]="docID"
                  [title]="'Document.Tax'"
                  path="taxinvoice"
                  [apiPath]="apiPath"
                  [detailObj]="taxObj"
                  [typeDocName]="typeDocName"
                  [docStatus]="docStatus"
                  [refDetailUrl]="refDetailUrl"
                  [refDocID]="'invoiceId'"
                >
                </app-action-history>
              </ng-template>
            </li>

            <li [ngbNavItem]="'relatedDoc'">
              <a ngbNavLink routerLink="." [fragment]="'relatedDoc'">{{
                "General.RelatedDocument" | translate
              }}</a>
              <ng-template ngbNavContent>
                <app-content-child-document
                  [orderStateData]="orderStatus[0]"
                  (refreshData)="refreshData($event)"
                  [currentDocCode]="taxObj[typeDocName]"
                >
                </app-content-child-document>
              </ng-template>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div [ngbNavOutlet]="navFilled"></div>
        </div>
      </div>
    </section>
  </div>
</div>
