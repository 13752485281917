import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-frontend-roles',
  templateUrl: './frontend-roles.component.html',
  styleUrls: ['./frontend-roles.component.scss']
})
export class FrontendRolesComponent implements OnInit {

  

  constructor() { }

  ngOnInit(): void {
  }

}
