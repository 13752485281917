import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SvExchangeRateService } from './sv-exchange-rate.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'app/main/service/api.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { Router } from '@angular/router';
import { ComponentsService } from 'app/main/components/components.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonResponse } from 'app/main/model/CommonResponse';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-sv-exchange-rate',
  templateUrl: './sv-exchange-rate.component.html',
  styleUrls: ['./sv-exchange-rate.component.scss'],
})
export class SvExchangeRateComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject();

  contentHeader: object;
  originalValues: any;

  exchangeForm: FormGroup;

  SVToBaht: object;

  isEditing: boolean = false;
  isLoading: boolean;
  isSubmitted: boolean;

  @BlockUI() blockUI: NgBlockUI;

  data: any[] = [];
  logDataList: SVToBahtLog[] = [];
  historyData: any[] = [
    {
      id: 1,
      previousTHB: 1.1,
      currentTHB: 1,
      userUpdate: 'AAA',
      dateUpdate: '',
    },
    {
      id: 2,
      previousTHB: 1.1,
      currentTHB: 1,
      userUpdate: 'AAA',
      dateUpdate: '',
    },
  ];

  componentName: string;
  apiPath: string = 'mlm/Argument/';
  pathUrl: string;
  modeText: string;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  searchData: any = {};
  pageSize: number = 10;
  page: number = 1;

  currentLang: string = '';
  isPriceInvalid: boolean = false

  constructor(
    private formBuilder: FormBuilder,
    private _apiService: ApiService,
    private _svExchangeRateService: SvExchangeRateService,
    private http: HttpClient,
    private _router: Router,
    private _translateService: TranslateService,
    private _modalService: NgbModal,
    private _componentsService: ComponentsService
  ) {}

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  initData() {
    let promise1 = this.getData().then((res) => {
      this.data = res.data.resultData[0].SVToBaht;
      this.originalValues = this.data;
      this.exchangeForm.patchValue({ SVToBaht: this.data });
    });
    let promise2 = this.getLogData().then((res) => {
      let sortedArr = res.data.resultData.sort((a, b) => {
        const dateA = new Date(a.createDate);
        const dateB = new Date(b.createDate);

        if (dateA < dateB) {
          return 1;
        }
        if (dateA > dateB) {
          return -1;
        }
        return 0;
      });

      this.logDataList = sortedArr
      console.log(this.logDataList)
    });

    return Promise.all([promise1, promise2])
      .then(() => {
        this.blockUI.stop();
      })
      .catch((error) => {
        console.error('Error initializing data:', error);
        this.blockUI.stop();
      });
  }

  ngOnInit(): void {
    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.exchangeForm = this.formBuilder.group({
      SVToBaht: [this.data, Validators.required],
      Thb: [1],
    });

    var self = this;

    this.contentHeader = {
      headerTitle: 'BonusPlan.SVExchange',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this.componentName,
            isLink: true,
            link: this.pathUrl,
          },
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this.initData().then(() => {
      this.dtOptions = {
        pagingType: 'full_numbers',
        scrollX: false,
        info: true,
        autoWidth: false,
        searching: false,
        pageLength: this.pageSize,
        // order: [[1, 'desc']],
        ordering: false,

        lengthMenu: [10, 25, 50, 100],
        lengthChange: false,
      };
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  initConfig(): void {
    this.componentName = this._svExchangeRateService.componentName;
    this.apiPath = this._svExchangeRateService.apiUrl;
    this.pathUrl = this._svExchangeRateService.pahtUrl;
  }

  getData(): Promise<CommonResponse<any>> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('mlm/Argument?Name=SVToBaht')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<any>) => {
            resolve(res);
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  getLogData(): Promise<CommonResponse<SVToBahtLog>> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('mlm/ArgumentLog?Name=SVToBaht&Direction=1')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: CommonResponse<SVToBahtLog>) => {
            resolve(res);
          },
          (err) => {
            rejects();
          }
        );
    });
  }

  get f() {
    return this.exchangeForm.controls;
  }

  openConfirmModal(): void {
    let isConfirm = true;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.Edit');
    } else {
      var stateForm = this._translateService.instant('General.Create');
    }
    let title =
      this._translateService.instant('General.Confirm') +
      `${stateForm} ${'SV Exchange Rate'}`;
    let detail =
      this._translateService.instant('General.AreYouConfirm') +
      `${stateForm} ${'SV Exchange Rate'}`;
    this.openModal(title, detail, isConfirm);
  }

  openCancelModal(): void {
    let isConfirm = false;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.CancelEdit');
    } else {
      var stateForm = this._translateService.instant('General.CancelCreate');
    }
    let title =
      this._translateService.instant('General.Confirm') +
      `${stateForm} ${'SV Exchange Rate'}`;
    let detail =
      this._translateService.instant('General.AreYouConfirm') +
      `${stateForm} ${'SV Exchange Rate'}`;
    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    this.isPriceInvalid = false
    
    if (this.exchangeForm.controls.SVToBaht.value < 0) {
      this.isPriceInvalid = true
      return
    }

    if (!this.exchangeForm.invalid) {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        if (IsConfirm) {
          this.submit();
        } else {
        }
      });
    } else {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {});
    }
  }

  updateData() {
    const updateData = {
      value: parseFloat(this.exchangeForm.get('SVToBaht').value),
    };
    this.blockUI.start();
    this._apiService.UpdateSV('mlm/Argument/SVToBaht', updateData).subscribe(
      (res) => {
        this.blockUI.stop();
        this.exchangeForm.get('SVToBaht').disable();
        this.initData();
        this._componentsService.SuccessSwal();
      },
      (err) => {
        this.blockUI.stop();
        this._componentsService.ErrorSwal();
      }
    );
    this.isEditing = false;
  }

  submit(): void {
    this.isSubmitted = true;
    this.updateData();
    this.isEditing = false;
  }

  onEdit() {
    this.isEditing = true;
    this.exchangeForm.get('SVToBaht').enable();
  }

  onCancel() {
    // this.getData();
    this.isEditing = false;
    this.exchangeForm.patchValue({ SVToBaht: this.originalValues });
    this.exchangeForm.get('SVToBaht').disable();
  }
}

export interface SVToBahtLog {
  status: string;
  name: string;
  changeFrom: number;
  changeTo: number;
  isActive: boolean;
  isDelete: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  deleteBy: any;
  deleteDate: any;
}
