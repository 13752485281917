export enum DocumentStatus {
  Draft = 1,
  AwaitApproval = 2,
  Accepted = 3,
  AwaitPayment = 4,
  Paid = 5,
  Expired = 6,
  Overdue = 7,
  Voided = 8,
  Cancel = 9,
  GotReceipt = 10,
}
