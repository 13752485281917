import { Component, OnInit } from '@angular/core';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import { HighlightTechService } from '../highlight-tech.service';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-highlight-tech-create',
  templateUrl: './highlight-tech-create.component.html',
  styleUrls: ['./highlight-tech-create.component.scss']
})
export class HighlightTechCreateComponent implements OnInit {

  private _unsubscribeAll: Subject<any> = new Subject();

  componentName: string;
  apiPath: string;
  pathUrl: string;
  contentHeader: object;

  isLoading: boolean;

  selectedLang: string = environment.langDefault;
  langList: string[] = environment.langContent;


  constructor(
    private _route: ActivatedRoute,
    private _highlightService: HighlightTechService,
    private _translateService: TranslateService
  ) {
    this.SetLoadingState();
    this._unsubscribeAll = new Subject();
    this.pathUrl = this._highlightService.pathUrl;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroethosome',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'BreadCrumbs.CMS.RepoveTools.Technology.Hydroethosome',
            isLink: true,
            link: '/cms/repove-tools/technology/hydroethosome/highlight-tech',
          },
          {
            name: 'General.Create',
            isLink: false,
          },
        ],
      },
    };
    this.SetLoadedState();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  selectedLanguage(lang: string): void {
    this.selectedLang = lang;
  }
}
