import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import CustomerAddress from 'app/main/model/CustomerAddress';
import Province from 'app/main/model/Province';
import { CustomerService } from 'app/main/pages/customer/customer.service';
import { ApiService } from 'app/main/service/api.service';
import { CustomerAddrModalComponent } from '../customer-addr-modal/customer-addr-modal.component';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { ComponentsService } from 'app/main/components/components.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { resolve } from 'dns';

export enum AddressType {
  Billing = 1,
  Shipping = 2,
  Contact = 3,
}

@Component({
  selector: 'app-customer-addr',
  templateUrl: './customer-addr.component.html',
  styleUrls: ['./customer-addr.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerAddrComponent implements OnInit {
  currentLang: string;
  registerForm: FormGroup;
  isSubmit: Boolean;
  isEditing: boolean = false;
  apiPath: string;
  itemObj: CustomerAddress;
  pathUrl: string;
  shippingAddressList: any[];
  billingAddressList: any[];
  formatFormBilling: FormGroup;
  formatFormShipping: FormGroup;
  ProvinceObj: Province[];
  DistrictObj: Province[];
  SubDistrictObj: Province[];
  ProvinceObj2: Province[];
  DistrictObj2: Province[];
  SubDistrictObj2: Province[];

  provinceID: number;
  districtID: number;
  subDistrictID: number;

  nd_provinceID: number;
  nd_districtID: number;
  nd_subDistrictID: number;

  isLoadingCprovice: boolean;
  isLoadingSprovice: boolean;

  isLoadingCdistrict: boolean;
  isLoadingSdistrict: boolean;

  isLoadingCsubDistrict: boolean;
  isLoadingSsubDistrict: boolean;

  addressType = AddressType;
  customerId: string;

  s_index_pid: number[];
  c_index_pid: number[];
  otpLengthArray: number[];
  c_check_pid_empty: boolean = false;
  s_check_pid_empty: boolean = false;
  clipboardData: any;

  @BlockUI() blockUI: NgBlockUI

  constructor(
    private _apiService: ApiService,
    private _CustomerServiceService: CustomerService,
    private _formBuilder: FormBuilder,
    private _translateService: TranslateService,
    private _modalService: NgbModal,
    private _globalFuncService: GlobalFuncService,
    private _componentsService: ComponentsService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.currentLang = this._translateService.currentLang;
    this.pathUrl = this._CustomerServiceService.pathUrlAddr;
    this.apiPath = this._CustomerServiceService.apiPathAddr;
    this.registerForm = this._formBuilder.group({
      id: [null],
      c_fname: [null, Validators.required],
      c_lname: [null],
      c_phone: [null, Validators.required],
      c_address: [null, Validators.required],
      c_country: ['', Validators.required],
      c_province: [null, Validators.required],
      c_district: [null, Validators.required],
      c_subDistrict: [null, Validators.required],
      c_postcode: [null, Validators.required],
      c_branchType: ['1', Validators.required],
      c_branchNumber: [null,[
        Validators.maxLength(5),
      ]],
      c_branchName: [null],
      c_isLegalAddr: [false],

      s_fname: [null, Validators.required],
      s_lname: [null],
      s_phone: [null, Validators.required],
      s_address: [null, Validators.required],
      s_country: ['', Validators.required],
      s_province: [null, Validators.required],
      s_district: [null, Validators.required],
      s_subDistrict: [null, Validators.required],
      s_postcode: [null, Validators.required],
      s_branchType: ['1', Validators.required],
      s_branchNumber: [null,[
        Validators.maxLength(5),
      ]],
      s_branchName: [null],
      s_isLegalAddr: [false],
    });

    this.s_index_pid = [
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
    ];

    this.c_index_pid = [
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
    ];

    this.clipboardData = '';
    this.otpLengthArray = Array(13)
      .fill(0)
      .map((_, index) => index);

    this._route.paramMap.subscribe((val) => {
      this.customerId = this._route.snapshot.paramMap.get('id');
    });
  }
  get f() {
    return this.registerForm.controls;
  }

  @ViewChildren('inputElements') inputElements: QueryList<ElementRef>;
  showResult: boolean = false;
  result: string = '';
  @ViewChild('c_nameInput', { static: false }) c_nameInput: ElementRef;
  @ViewChild('c_addressInput', { static: false }) c_addressInput: ElementRef;
  @ViewChild('c_countryInput', { static: false }) c_countryInput: ElementRef;
  @ViewChild('c_provinceInput', { static: false }) c_provinceInput: ElementRef;
  @ViewChild('c_subDistrictInput', { static: false })
  c_subDistrictInput: ElementRef;
  @ViewChild('c_districtInput', { static: false }) c_districtInput: ElementRef;
  @ViewChild('c_postcodeInput', { static: false }) c_postcodeInput: ElementRef;
  @ViewChild('s_nameInput', { static: false }) s_nameInput: ElementRef;
  @ViewChild('s_addressInput', { static: false }) s_addressInput: ElementRef;
  @ViewChild('s_countryInput', { static: false }) s_countryInput: ElementRef;
  @ViewChild('s_provinceInput', { static: false }) s_provinceInput: ElementRef;
  @ViewChild('s_subDistrictInput', { static: false })
  s_subDistrictInput: ElementRef;
  @ViewChild('s_districtInput', { static: false }) s_districtInput: ElementRef;
  @ViewChild('s_postcodeInput', { static: false }) s_postcodeInput: ElementRef;

  initForm(isEditing: boolean): void {
    this.getProvince();
    if (isEditing) {
      // this.getitemData(this.itemID);
    } else {
      this.itemObj = new CustomerAddress();
      // this.isLoading = false;
    }
  }

  getProvince(): Promise<any> {
    this.isLoadingCprovice = true;

    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterProvince`, {
          SortPath: 'provinceName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.ProvinceObj = res.data.resultData;
          this.ProvinceObj2 = res.data.resultData;
          this.isLoadingCprovice = false;

          console.log('getProvince');
        }, rejects);
    });
  }
  getDistrict(id): Promise<any> {
    this.isLoadingCdistrict = true;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterDistrict`, {
          ProvinceId: id,
          SortPath: 'districtName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.DistrictObj = res.data.resultData;
          this.isLoadingCdistrict = false;
          if (
            !this.registerForm.controls.s_province.value &&
            !this.districtID &&
            !this.registerForm.controls.s_subDistrict.value
          ) {
            this.DistrictObj2 = res.data.resultData;
            console.log('DistrictObj2 have data');
          }

          console.log('getDistrict');
        }, rejects);
    });
  }
  getSubDistrict(id): Promise<any> {
    this.isLoadingCsubDistrict = true;
    this.registerForm.get('c_subDistrict').setValue(null);
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterDistrict`, {
          ProvinceId: id,
          SortPath: 'subDistrictName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.SubDistrictObj = res.data.resultData;
          this.isLoadingCsubDistrict = false;
        }, rejects);
      return true;
    });
  }
  getPostCode(event: any): Promise<any> {
    this.subDistrictID = event;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetDataById(`MasterSubDistrict`, event)
        .subscribe((res) => {
          console.log(res.data.resultData[0].subDistrictPostCode);
          this.registerForm.patchValue({
            c_postcode: res.data.resultData[0].subDistrictPostCode,
          });
          console.log('Province : ' + this.provinceID, typeof this.provinceID);
          console.log('District : ' + this.districtID, typeof this.districtID);
          console.log(
            'Sub Dis : ' + this.subDistrictID,
            typeof this.subDistrictID
          );
          console.log('Postcode : ' + this.c_postcodeInput.nativeElement.value);
        }, rejects);
    });
  }
  isSelectDistrictFirstPick(id): Promise<any> {
    this.isLoadingSsubDistrict = true;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterSubDistrict`, {
          districtId: id,
          SortPath: 'subDistrictName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.SubDistrictObj = res.data.resultData;
          if (
            !this.registerForm.controls.s_province.value &&
            !this.registerForm.controls.s_district.value
          ) {
            console.log('SubDistrictObj2 have data');
            this.SubDistrictObj2 = res.data.resultData;
            this.isLoadingSsubDistrict = false;
          }
          console.log('isSelectDistrict');
        }, rejects);
      this.isLoadingSsubDistrict = false;
    });
  }

  isSelectDistrict(id): Promise<any> {
    this.registerForm.get('c_subDistrict').setValue(null);
    this.subDistrictID = null;
    this.districtID = id;
    this.registerForm.patchValue({
      c_postcode: '',
    });
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterSubDistrict`, {
          districtId: id,
          SortPath: 'subDistrictName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.SubDistrictObj = res.data.resultData;
          if (
            !this.registerForm.controls.s_province.value &&
            !this.registerForm.controls.s_district.value
          ) {
            console.log('SubDistrictObj2 have data');
            this.SubDistrictObj2 = res.data.resultData;
          }
          console.log('isSelectDistrict');
        }, rejects);
    });
  }
  isSelectProviceFirstPick(event: any): void {
    console.log(event);

    this.getDistrict(event);
    this.SubDistrictObj = [];
    console.log('H : ' + this.registerForm.controls.c_subDistrict.value);
  }
  isSelectProvice(event: any): void {
    this.registerForm.get('c_district').setValue(null);
    this.registerForm.get('c_subDistrict').setValue(null);
    this.provinceID = event;
    this.getDistrict(event);
    this.SubDistrictObj = [];
    this.districtID = null;
    this.subDistrictID = null;

    console.log('H : ' + this.registerForm.controls.c_subDistrict.value);

    this.registerForm.patchValue({
      c_postcode: '',
    });
  }
  isSelectedSubDistrict(event: any): void {
    this.getSubDistrict(event);
  }

  getDistrict_2(id): Promise<any> {
    this.isLoadingSdistrict = true;
    this.DistrictObj2 = [];
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterDistrict`, { ProvinceId: id })
        .subscribe((res) => {
          this.DistrictObj2 = res.data.resultData;
          this.isLoadingSdistrict = false;
        }, rejects);
    });
  }
  isSelectDistrict_2(id): Promise<any> {
    this.isLoadingSdistrict = true;
    this.registerForm.get('s_subDistrict').setValue(null);
    this.registerForm.patchValue({
      s_postcode: '',
    });
    console.log('this is : ' + id);
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterSubDistrict`, {
          districtId: id,
          SortPath: 'subDistrictName',
          Direction: 0,
        })
        .subscribe((res) => {
          console.log(res);
          this.SubDistrictObj2 = res.data.resultData;
          console.log(this.SubDistrictObj2);
          this.isLoadingSdistrict = false;
          return true;
        }, rejects);
    });
  }
  isSelectDistrictFirstPick_2(id): Promise<any> {
    console.log('this is : ' + id);
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterSubDistrict`, {
          districtId: id,
          SortPath: 'subDistrictName',
          Direction: 0,
        })
        .subscribe((res) => {
          console.log(res);
          this.SubDistrictObj2 = res.data.resultData;
          console.log(this.SubDistrictObj2);
          this.isLoadingSdistrict = false;
          return true;
        }, rejects);
    });
  }
  isSelectProviceFirstPick_2(event: any): void {
    this.getDistrict_2(event);
    this.SubDistrictObj2 = [];
  }
  isSelectProvice_2(event: any): void {
    this.isLoadingSprovice = true;
    this.registerForm.get('s_district').setValue(null);
    this.registerForm.get('s_subDistrict').setValue(null);
    this.getDistrict_2(event);
    this.SubDistrictObj2 = [];
    this.registerForm.patchValue({
      s_postcode: '',
    });
    this.isLoadingSprovice = false;
  }
  isSelectSubDistrict_2(event: any): void {
    this.registerForm.get('s_subDistrict').setValue(null);
    this.getSubDistrict_2(event);
  }
  getPostCode_2(event: any): Promise<any> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetDataById(`MasterSubDistrict`, event)
        .subscribe((res) => {
          console.log(res.data.resultData[0].subDistrictPostCode);
          this.registerForm.patchValue({
            s_postcode: res.data.resultData[0].subDistrictPostCode,
          });
          return true;
        }, rejects);
    });
  }
  getSubDistrict_2(id): Promise<any> {
    this.isLoadingSsubDistrict = true;
    this.SubDistrictObj2 = [];
    console.log('กำลัง getSubDistrict_2' + id);
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterSubDistrict`, {
          DistrictId: id,
          SortPath: 'subDistrictName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.SubDistrictObj2 = res.data.resultData;
          this.isLoadingSsubDistrict = false;
        }, rejects);
    });
  }

  ngOnInit(): void {
    this.initForm(this.isEditing);
    const input: HTMLInputElement = document.getElementById(
      'PersonalID'
    ) as HTMLInputElement;
    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });
  }

  setShippingAddress(obj) {
    obj.forEach(function (item, i) {
      if (item.isDefault === true) {
        obj.splice(i, 1);
        obj.unshift(item);
      }
    });
    this.shippingAddressList = obj;
  }

  setBillingAddress(obj) {
    obj.forEach(function (item, i) {
      if (item.isDefault === true) {
        obj.splice(i, 1);
        obj.unshift(item);
      }
    });
    this.billingAddressList = obj;
  }

  async buildForm(varId: any, isEditing: boolean): Promise<void> {
    this.isEditing = isEditing;
    this._apiService
      .GetAllData(this.apiPath, {
        customerId: varId,
        UseForBilling: false,
        UseForShipping: true,
        UseForContact: false,
        isDelete: false,
      })
      .subscribe((res) => {
        this.shippingAddressList = [];
        this.setShippingAddress(res.data.resultData);
        console.log('shippingAddressList', this.shippingAddressList);
      });

    this._apiService
      .GetAllData(this.apiPath, {
        customerId: varId,
        UseForBilling: true,
        UseForShipping: false,
        UseForContact: false,
        isDelete: false,
      })
      .subscribe((res) => {
        this.billingAddressList = [];
        this.setBillingAddress(res.data.resultData);
        console.log('billingAddressList', this.billingAddressList);
      });
  }

  transform(value: any[]): string {
    if (!Array.isArray(value)) {
      return '';
    }
    const flattenedArray = this.flattenArray(value);
    return flattenedArray.join('');
  }

  private flattenArray(arr: any[]): any[] {
    const result: any[] = [];
    for (const item of arr) {
      if (Array.isArray(item)) {
        result.push(...this.flattenArray(item));
      } else {
        result.push(item);
      }
    }
    return result;
  }

  validator() {
    this.validatePID()
    
    if (!this.registerForm.invalid) {
      return true;
    } else {
      for (const controlName in this.registerForm.controls) {
        const control = this.registerForm.get(controlName);
        if (control.invalid) {
          console.log(`Invalid control: ${controlName}`);
          this.isSubmit = true;
        }
      }
      return false;
    }
  }

  onKeyDown(
    event: KeyboardEvent,
    index: number,
    containerId: string,
    dynamicArray: number[]
  ): void {
    const inputs = document.querySelectorAll(`#${containerId} > *[id]`);
    const currentInput = inputs[index] as HTMLInputElement;
    // if (event.metaKey && event.key === 'v') {
    //   console.log('Hi');

    //   this.getDataFromClipboard();
    // }
    if (event.key === 'Backspace') {
      if (currentInput.value === '') {
        if (index !== 0) {
          const previousInput = inputs[index - 1] as HTMLInputElement;
          previousInput.focus();
        }
      } else {
        dynamicArray[index] = NaN;
        currentInput.value = '';
      }
    } else if (event.key === 'ArrowLeft' && index !== 0) {
      const previousInput = inputs[index - 1] as HTMLInputElement;
      previousInput.focus();
    } else if (event.key === 'ArrowRight' && index !== inputs.length - 1) {
      const nextInput = inputs[index + 1] as HTMLInputElement;
      nextInput.focus();
    } else if (event.key === 'ArrowUp' && index !== inputs.length - 1) {
      const nextInput = inputs[index] as HTMLInputElement;
      nextInput.focus();
    } else if (event.key === 'ArrowDown' && index !== inputs.length - 1) {
      const nextInput = inputs[index] as HTMLInputElement;
      nextInput.focus();
    } else if (event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
      currentInput.value = '';
      setTimeout(() => {}, 1000);
    }
  }

  async onPaste(containerId: string): Promise<void> {
    // const containerId = 's_ContactPersonalID'; // Change this to your container ID
    const inputs = document.querySelectorAll(`#${containerId} > *[id]`);
    const dynamicArray: number[] = new Array(inputs.length).fill(NaN);

    await new Promise<void>((resolve) => {
      inputs.forEach((input: any) => {
        input.value = '';
      });

      resolve();
    });

    this.getDataFromClipboard(containerId, dynamicArray);
  }

  onInput(
    event: any,
    index: number,
    containerId: string,
    dynamicArray: number[]
  ): void {
    if (containerId == 'c_ContactPersonalID') {
      this.c_check_pid_empty = false;
    }

    if (containerId == 's_ContactPersonalID') {
      this.s_check_pid_empty = false;
    }

    // const containerId = 's_ContactPersonalID'; // Change this to your container ID
    const inputs = document.querySelectorAll(`#${containerId} > *[id]`);
    const currentInput = inputs[index] as HTMLInputElement;
    currentInput.value = currentInput.value.toUpperCase();
    const inputValue: string = currentInput.value;
    const parsedValue: number = parseInt(inputValue, 10);
    dynamicArray[index] = parsedValue;

    if (index === inputs.length - 1 && currentInput.value !== '') {
      return;
    } else if (currentInput.value !== '') {
      const nextInput = inputs[index + 1] as HTMLInputElement;
      nextInput.focus();
    }
  }

  getDataFromClipboard(containerId: string, dynamicArray: number[]): void {
    // const containerId = 's_ContactPersonalID'; // Change this to your container ID
    const inputs = document.querySelectorAll(`#${containerId} > *[id]`);
    console.log(inputs);

    navigator.clipboard
      .readText()
      .then((clipboardData: string) => {
        const dataArray = clipboardData.split('\n');
        const regex = /\d+/g;
        const matches = dataArray[0].match(regex);

        if (matches) {
          const only_int = matches.map((match) => parseInt(match));
          const str_num = only_int.join('').toString();

          dynamicArray.forEach((_, index) => {
            if (str_num[index]) {
              inputs.forEach((input: any, inputIndex) => {
                input.value = '';
                if (str_num[inputIndex]) {
                  input.value = str_num[inputIndex].toString();
                }
              });
              dynamicArray[index] = str_num.split('').map(Number)[index];
            }
          });
        }
      })
      .catch((error: Error) => {
        console.error('Failed to read clipboard data:', error);
      });
  }

  async submit(varId: any, isEditing: boolean): Promise<void> {
    console.log('Generate form');
    console.log(isEditing);

    const self = this;
    console.log(varId);
    const c_taxIdsString: string = this.c_index_pid.join('');
    const s_taxIdsString: string = this.s_index_pid.join('');

    this.formatFormBilling = this._formBuilder.group({
      customerId: varId,
      businessName: self.f.c_isLegalAddr.value == true ? self.f.c_fname.value : '',
      firstName: self.f.c_isLegalAddr.value == false ? self.f.c_fname.value : '',
      lastName: self.f.c_isLegalAddr.value == false ? self.f.c_lname.value : '',
      taxId: c_taxIdsString,
      isHeadBranch: self.f.c_branchType.value == '1' ? true : false,
      branchNumber: self.f.c_isLegalAddr.value
      ? parseInt(self.f.c_branchNumber.value ? self.f.c_branchNumber.value : 0)
      : 0,
      branchName: self.f.c_branchName.value,
      detail: '',
      addressNumber: self.f.c_address.value,
      village: '',
      alley: '',
      road: '',
      country: self.f.c_country.value,
      postCode: self.f.c_postcode.value,
      provinceId: self.f.c_province.value,
      // provinceName: self.provinceName,
      districtId: self.f.c_district.value,
      // districtName: self.f.districtName,
      subDistrictId: self.f.c_subDistrict.value,
      // subDistrictName: self.f.subDistrictName,
      useForShipping: true,
      useForBilling: false,
      useForContact: false,
      isDefault: true,
      isLegalAddr: self.f.c_isLegalAddr.value,
      addrLatitude: 0,
      addrLongitude: 0,
      phone: self.f.c_phone.value,
      fax: '',
    });

    this.formatFormShipping = this._formBuilder.group({
      customerId: varId,
      businessName: self.f.s_isLegalAddr.value == true ? self.f.s_fname.value : '',
      firstName: self.f.s_isLegalAddr.value == false ? self.f.s_fname.value : '',
      lastName: self.f.s_isLegalAddr.value == false ? self.f.s_lname.value : '',
      taxId: s_taxIdsString,
      isHeadBranch: self.f.s_branchType.value == '1' ? true : false,
      branchNumber: self.f.s_isLegalAddr.value
      ? parseInt(self.f.s_branchNumber.value ? self.f.s_branchNumber.value : 0)
      : 0,
      branchName: self.f.s_branchName.value,
      detail: '',
      addressNumber: self.f.s_address.value,
      village: '',
      alley: '',
      road: '',
      country: self.f.s_country.value,
      postCode: self.f.s_postcode.value,
      provinceId: self.f.s_province.value,
      // provinceName: self.provinceName,
      districtId: self.f.s_district.value,
      // districtName: self.f.districtName,
      subDistrictId: self.f.s_subDistrict.value,
      // subDistrictName: self.f.subDistrictName,
      useForShipping: false,
      useForBilling: true,
      useForContact: false,
      isDefault: true,
      isLegalAddr: self.f.s_isLegalAddr.value,
      addrLatitude: 0,
      addrLongitude: 0,
      phone: self.f.s_phone.value,
      fax: '',
    });

    if (this.registerForm.invalid || this.c_check_pid_empty || this.s_check_pid_empty) {
      return;
    }

    console.log('objlist ', this.shippingAddressList);
    console.log('formatFormShipping ', this.formatFormShipping);
    console.log('formatFormBilling ', this.formatFormBilling);

    self._apiService
      .AddData(self.apiPath, self.formatFormShipping.value)
      .subscribe(
        (res) => {
          console.log('Added new customer....');
          self._apiService
            .AddData(self.apiPath, self.formatFormBilling.value)
            .subscribe(
              (res) => {
                this._componentsService.SuccessSwal();
              this._router.navigate([`/customer`]);
                console.log('Added new customer....');
                return true;
              },
              (err) => {
                return false;
              }
            );
        },
        (err) => {
          return false;
        }
      );
  }

  validatePID() {
    const c_taxIdsString: string = this.c_index_pid.join('');
    const s_taxIdsString: string = this.s_index_pid.join('');

    const regex = /NaN/;
    if (regex.test(c_taxIdsString)) {
      console.log('c_pid_false')
      this.c_check_pid_empty = true;
    }

    // if (regex.test(s_taxIdsString)) {
    //   console.log('s_pid_false')
    //   this.s_check_pid_empty = true;
    // }
  }

  async copyAddress() {
    var self = this;
    this.isLoadingSdistrict = true;
    this.isLoadingSsubDistrict = true;

    if (this.SubDistrictObj != this.SubDistrictObj2) {
      this.getSubDistrict_2(this.districtID);
    } else {
      this.isLoadingSsubDistrict = false;
    }
    if (this.DistrictObj != this.DistrictObj2) {
      this.getDistrict_2(this.provinceID);
    } else {
      this.isLoadingSdistrict = false;
    }

    this.registerForm.controls.s_isLegalAddr.setValue(
      this.registerForm.controls.c_isLegalAddr.value
    );

    this.registerForm.controls.s_branchType.setValue(
      this.registerForm.controls.c_branchType.value
    );

    this.registerForm.controls.s_branchNumber.setValue(
      this.registerForm.controls.c_branchNumber.value
    );

    this.registerForm.controls.s_branchName.setValue(
      this.registerForm.controls.c_branchName.value
    );

    this.s_index_pid = this.c_index_pid;

    const inputs = document.querySelectorAll('#s_ContactPersonalID > *[id]');
    if (inputs.length === self.s_index_pid.length) {
      for (let i = 0; i < inputs.length; i++) {
        var currentInput = inputs[i] as HTMLInputElement;
        currentInput.value = self.s_index_pid[i].toString();
      }
    } else {
      console.error('Number of inputs does not match the length of index_id.');
    }

    this.s_nameInput.nativeElement.value = this.c_nameInput.nativeElement.value;
    this.registerForm.controls.s_fname.setValue(
      this.registerForm.controls.c_fname.value
    );

    this.registerForm.controls.s_lname.setValue(
      this.registerForm.controls.c_lname.value
    );

    this.registerForm.controls.s_phone.setValue(
      this.registerForm.controls.c_phone.value
    );

    this.s_addressInput.nativeElement.value =
      this.c_addressInput.nativeElement.value;
    this.registerForm.controls.s_address.setValue(
      this.registerForm.controls.c_address.value
    );

    this.s_countryInput.nativeElement.value =
      this.c_countryInput.nativeElement.value;
    this.registerForm.controls.s_country.setValue(
      this.registerForm.controls.c_country.value
    );

    console.log(
      this.provinceID,
      typeof this.provinceID,
      this.districtID,
      typeof this.districtID,
      this.subDistrictID,
      typeof this.subDistrictID,
      this.c_postcodeInput.nativeElement.value
    );

    this.registerForm.get('s_province').setValue(this.provinceID);
    this.registerForm.get('s_district').setValue(this.districtID);
    this.registerForm.get('s_subDistrict').setValue(this.subDistrictID);

    this.s_postcodeInput.nativeElement.value =
      this.c_postcodeInput.nativeElement.value;
    this.registerForm.controls.s_postcode.setValue(
      this.registerForm.controls.c_postcode.value
    );

    console.log(this.registerForm.controls.c_province.value);
    console.log(this.registerForm.controls.s_province.value);
    console.log(this.registerForm.controls.c_district.value);
    console.log(this.registerForm.controls.s_district.value);
    // this.isLoadingSdistrict = false;
    // this.isLoadingSsubDistrict = false;
  }

  openAddressModal(isEditing: boolean, obj?: any, targetType?: any) {
    const modalRef = this._modalService.open(CustomerAddrModalComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });

    if (isEditing) {
      modalRef.componentInstance.addrObj = obj;
      modalRef.componentInstance.isEditing = true;
    } else {
      modalRef.componentInstance.isEditing = false;
    }

    modalRef.componentInstance.customerId = this.customerId;
    modalRef.componentInstance.targetType = targetType;
    modalRef.componentInstance.defaultBilling = this.billingAddressList[0]

    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      var self = this;

      this.buildForm(this.customerId, true);
    });
  }

  openDeleteAddressModal(id) {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.title = 'ลบที่อยู่';
    modalRef.componentInstance.detail = 'คุณต้องการที่จะลบข้อมูลที่อยู่นี้หรือไม่';
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.deleteCustomerAddress(id);
    });
  }

  setAsDefault(id) {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.title = 'ตั้งค่าที่อยู่นี้เป็นค่าเริ่มต้น';
    modalRef.componentInstance.detail = 'คุณต้องการที่จะตั้งค่าที่อยู่นี้เป็นค่าเริ่มต้นหรือไม่';
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.blockUI.start()
      this._apiService.SetIsDefault('CustomerAddress',id).subscribe(addr_res => {
        this.buildForm(this.customerId, true);
        this.blockUI.stop()
        this._componentsService.SuccessSwal()
      })
    });
  }

  deleteCustomerAddress(id) {
    this._apiService.SoftDelete(this.apiPath, id).subscribe((res) => {
      this._componentsService.SuccessSwal();
      this.buildForm(this.customerId, true);
    });
  }

  addBillingAddress(val) {
    this.billingAddressList.push(val);
  }
  addShippingAddress(val) {
    this.billingAddressList.push(val);
  }

  GetAddressTemplate(customerAddressObj): string {
    return this._globalFuncService.GetAddressTemplate(customerAddressObj);
  }
}
