<div class="content-wrapper container-xxl p-0">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div class="content-body">
        <div class="card">
            <div class="card-body">


                <button class="btn btn-primary" [disabled]="isEditing" (click)="editMode()">{{ 'General.Edit' |
                    translate }}</button>

                <hr>
                <div class="row">
                    <div class="col-6">
                        <h4 class="mb-1 text-primary">{{ "MLM.Period.Setting" | translate }}</h4>
                        <div class="custom-control custom-radio mb-1">
                            <input type="radio" id="periodSettings1" [(ngModel)]="periodSettings"
                                [checked]="periodSettings == 0" name="periodSettings" [value]="0"
                                class="custom-control-input" [disabled]="!isEditing" />
                            <label class="custom-control-label" for="periodSettings1">{{ "MLM.Period.EndOfMonth" |
                                translate }}</label>
                        </div>
                        <div class="custom-control custom-radio mb-1">
                            <input type="radio" id="periodSettings2" [(ngModel)]="periodSettings"
                                [checked]="periodSettings == 1" name="periodSettings" [value]="1"
                                class="custom-control-input" [disabled]="!isEditing" />
                            <label class="custom-control-label" for="periodSettings2">{{ "MLM.Period.HalfOfMonth" |
                                translate }}</label>
                        </div>
                        <p class="text-danger" *ngIf="isEditing">***คำเตือน: การตั้งค่าจะมีผลต่อ Period ถัดไป***</p>
                        <button class="btn btn-primary" *ngIf="isEditing" (click)="onSubmit()">{{ 'Form.Submit' |
                            translate }}</button>
                    </div>
                    <div class="col-6">
                        <h4 class="mb-1 text-primary">{{ "MLM.Period.Current" | translate }}</h4>
                        <ng-container *ngIf="currentLang == 'th'">
                            <p><span class="text-bold">{{ "MLM.Period.Start" | translate }}:</span>
                                {{ lastPeriod ? (lastPeriod.startDate | thaidate : "DD MMM YYYY") : '-' }}
                            </p>
                            <p><span class="text-bold">{{ "MLM.Period.End" | translate }}:</span>
                                {{ lastPeriod ? (lastPeriod.endDate | thaidate : "DD MMM YYYY") : '-' }}
                            </p>
                        </ng-container>
                        <ng-container *ngIf="currentLang == 'en'">
                            <p><span class="text-bold">{{ "MLM.Period.Start" | translate }}:</span>
                                {{ lastPeriod ? (lastPeriod.startDate | date :'dd MMM YYYY') : '-' }}
                            </p>
                            <p><span class="text-bold">{{ "MLM.Period.End" | translate }}:</span>
                                {{ lastPeriod ? (lastPeriod.endDate | date : 'dd MMM YYYY') : '-' }}
                            </p>
                        </ng-container>
                        <p><span class="text-bold">{{ "MLM.Period.Type" | translate }}:</span> ตัดรอบวันสุดท้าย</p>
                    </div>
                </div>

                <hr class="hr-primary">

                <ul ngbNav #nav="ngbNav" class="nav nav-pills" [destroyOnHide]="false">
                    <li ngbNavItem>
                        <a ngbNavLink>{{ "MLM.SalePeriodHistory" | translate }}</a>
                        <ng-template ngbNavContent>

                            <table datatable [dtOptions]="periodHistory_dtOptions" class="table">
                                <thead>
                                    <tr>
                                        <th width="8%" class="text-center">#</th>
                                        <th width="10%" class="text-center">{{ "MLM.Period.Year" | translate }}</th>
                                        <th>{{ "MLM.Period.Month" | translate }}</th>
                                        <th>{{ "MLM.Period.Title" | translate }}</th>
                                        <th>{{ "MLM.Period.Type" | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="w-100" *ngIf="isLoadingPeriodHistory">
                                        <td class="text-center" *ngFor="let num of [].constructor(5)">
                                            <div class="skeleton-loading"></div>
                                        </td>
                                    </tr>

                                    <tr *ngIf="periodHistoryList.length < 1 && !isLoadingPeriodHistory">
                                        <td colspan="100%" class="text-center">
                                            <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
                                        </td>
                                    </tr>

                                    <tr *ngFor="let periodHistory of periodHistoryList; index as ind">
                                        <td class="text-center">{{ (periodHistoryPage - 1) * pageSize + ind + 1 }}</td>
                                        <td class="text-center">
                                            <p *ngIf="currentLang == 'th'" class="m-0">{{ periodHistory.startDate |
                                                thaidate : 'YYYY' }}</p>
                                            <p *ngIf="currentLang == 'en'" class="m-0">{{ periodHistory.startDate | date
                                                : 'YYYY' }}</p>
                                        </td>
                                        <td>
                                            <p *ngIf="currentLang == 'th'" class="m-0">{{ periodHistory.startDate |
                                                thaidate : 'MMMM' }}</p>
                                            <p *ngIf="currentLang == 'en'" class="m-0">{{ periodHistory.startDate | date
                                                : 'MMMM' }}</p>
                                        </td>
                                        <td>
                                            <p *ngIf="currentLang == 'th'" class="m-0">
                                                {{ periodHistory.startDate | thaidate : 'DD MMM YYYY' }}
                                                - {{ periodHistory.endDate | thaidate :
                                                'DD MMM YYYY' }}
                                            </p>
                                            <p *ngIf="currentLang == 'en'" class="m-0">
                                                {{ periodHistory.startDate | date : 'dd MMM YYYY' }} - {{
                                                periodHistory.endDate | date :
                                                'dd MMM YYYY' }}
                                            </p>

                                        </td>
                                        <td>{{ periodHistory.periodSetting ?
                                            (getSalePeriodModeLabel(periodHistory.periodSetting) | translate) :
                                            '-' }}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>{{ "MLM.SettingHistory" | translate }}</a>
                        <ng-template ngbNavContent>

                            <table datatable [dtOptions]="settingsHistory_dtOptions" class="table">
                                <thead>
                                    <tr>
                                        <th width="8%" class="text-center">#</th>
                                        <th>{{ "MLM.Period.From" | translate }}</th>
                                        <th>{{ "MLM.Period.To" | translate }}</th>
                                        <th>{{ "MLM.Period.Date" | translate }}</th>
                                        <th>{{ "MLM.Period.Editor" | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="w-100" *ngIf="isLoadingSettingsHistory">
                                        <td class="text-center" *ngFor="let num of [].constructor(5)">
                                            <div class="skeleton-loading"></div>
                                        </td>
                                    </tr>

                                    <tr *ngIf="settingsHistoryList.length < 1 && !isLoadingSettingsHistory">
                                        <td colspan="100%" class="text-center">
                                            <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
                                        </td>
                                    </tr>

                                    <tr *ngFor="let settingsHistory of settingsHistoryList; index as ind">
                                        <td class="text-center">{{ (settingsHistoryPage - 1) * pageSize + ind + 1 }}</td>
                                        <td>{{ getSalePeriodModeLabel(settingsHistory.changeFrom) | translate }}</td>
                                        <td>{{ getSalePeriodModeLabel(settingsHistory.changeTo) | translate }}</td>
                                        <td>
                                            <p *ngIf="currentLang == 'th'" class="m-0">{{ settingsHistory.changeDate |
                                                thaidate : 'DD MMM YYYY'
                                                }}</p>
                                            <p *ngIf="currentLang == 'en'" class="m-0">{{ settingsHistory.changeDate |
                                                date : 'dd MMM YYYY' }}
                                            </p>
                                        </td>
                                        <td>{{ settingsHistory.updateBy }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-template>
                    </li>

                </ul>
                <hr>
                <div [ngbNavOutlet]="nav" class="mt-1"></div>
            </div>
        </div>
    </div>
</div>