export enum DocumentType {
    None = 0,
    Quotation = 1,
    SaleOrder = 2,
    Invoice = 3,
    TaxInvoice = 4,
    Receipt = 5,
    CreditNote = 6,
    DebitNote = 7,
    PurchaseRequisition = 8,
    PurchaseOrder = 9,
    GoodsReceive = 10
}


