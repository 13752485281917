import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class QuatationService {
  componentName: string = this._translateService.instant('General.Quotation');
  apiPath: string = 'quotation';
  pathUrl: string = '/quotation';
  apiUrl: string = 'quotation';
  keyName: string = 'customerId';
  constructor(private _translateService: TranslateService) {}
}
