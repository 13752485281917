import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SubclassService } from '../subclass.service';

@Component({
  selector: 'app-subclass-create',
  templateUrl: './subclass-create.component.html',
  styleUrls: ['./subclass-create.component.scss'],
})
export class SubclassCreateComponent implements OnInit {
  contentHeader: object;
  pathUrl: string;
  constructor(
    private _translateService: TranslateService,
    private _subClassService: SubclassService
  ) {
    this.pathUrl = this._subClassService.pathUrl;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'General.SubClass',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.SubClass',
            isLink: true,
            link: '/item-manage/subclass',
          },
          {
            name: 'General.Create',
            isLink: false,
          },
        ],
      },
    };
  }
}
