import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { ComponentsService } from 'app/main/components/components.service';
import { TimelineItem } from 'app/main/components/horizontal-timeline/horizontal-timeline.component';
import Customer from 'app/main/model/Customer';
import Item from 'app/main/model/Item';

import {
  Quotation,
  QuotationItem,
  TempRowQuotation,
} from 'app/main/model/Quotation';

import { ApiService } from 'app/main/service/api.service';
import { DocumentApiService } from 'app/main/service/document-api.service';
import Stepper from 'bs-stepper';
import { DocumentService } from '../../document.service';

import { FlatpickrOptions } from 'ng2-flatpickr';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { ActivatedRoute, Router } from '@angular/router';
import { event, param } from 'jquery';

import { DragulaService } from 'ng2-dragula';

import { Subject } from 'rxjs';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'app/main/components/modal/modal.component';

import { CalculateFuncService } from 'app/main/service/calculate-func.service';
import { AddBillComponent } from 'app/main/components/document/template/add-bill/add-bill.component';
import { QuatationService } from '../../quotation/quotation.service';
import { SelectCreditDebitComponent } from 'app/main/components/document/select-credit-debit/select-credit-debit.component';
import { PaymentComponent } from 'app/main/components/payment/payment.component';
import { DebitNote } from 'app/main/model/DebitNote';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PaymentMethodList } from 'app/main/model/PaymentMethod';

@Component({
  selector: 'app-debit-note-formpage',
  templateUrl: './debit-note-formpage.component.html',
  styleUrls: ['./debit-note-formpage.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DebitNoteFormpageComponent implements OnInit, OnDestroy {
  @ViewChild('confirmModal') confirmModal: ModalComponent;
  @ViewChild('addBillComponent') addBillComponent: AddBillComponent;
  @ViewChild('selectCreditDebitComponent')
  selectCreditDebitComponent: SelectCreditDebitComponent;
  @ViewChild(PaymentComponent)
  paymentComponent: PaymentComponent;
  @ViewChildren('formTable') formTable: QueryList<ElementRef<HTMLLIElement>>;
  @Input() itemObj: DebitNote;

  @BlockUI() blockUI: NgBlockUI;

  private _unsubscribeAll: Subject<any> = new Subject();
  quotationData: any;
  formDocument: FormGroup;
  formPayment: FormGroup;
  paymentMethod: number;
  public sharedData: any;
  personalInfoForm: FormGroup;
  codeAddBill: string;
  creditDebitData: any;
  debitNoteStepper: Stepper;
  contentHeader: object;
  invoiceApiPath: string;
  debitNoteApiPath: string;
  debitNotePath: string;
  receiptApiPath: string;
  currentUser: User;
  apiPath: string;
  docID: string;
  callBackData: any;
  docCode: string;
  isSubmit: boolean;
  quotationForm: FormGroup;
  taxRate: number = 7.0;
  whtRate: number = 0;
  componentName: String;
  itemCounter: number = 0;
  rows: TempRowQuotation[] = [];
  draggingRow: any;
  currentDate: Date = new Date();
  nextWeekDate: Date = new Date();
  customerList: Customer[] = [];
  customerObj: Customer;
  customerAddressObj: CustomerAddress;
  itemList: Item[] = [];
  isLoading: boolean;
  payObj: any;
  isEditing: boolean = true;
  isVatExempted: boolean;
  isSetValueWhenInit: boolean = false;
  isExcludeTax: boolean = true;
  isSameWHTAllRow: boolean;
  isDiscountByPercent: boolean;
  itemInvalid: boolean[] = [];
  sumTotalInvalid: boolean = false;
  totalDiscountInvalid: boolean = false;
  descriptionInvalid: boolean = false;

  whtList: any[] = [
    // { value: 0, label: 'ยังไม่ระบุ' },
    { value: 0, label: 'ไม่มี' },
    { value: 0.75, label: '0.75%' },
    { value: 1, label: '1%' },
    { value: 1.5, label: '1.5%' },
    { value: 2, label: '2%' },
    { value: 3, label: '3%' },
    { value: 5, label: '5%' },
    { value: 10, label: '10%' },
    { value: 15, label: '15%' },
  ];

  invoiceDocList: any[];

  passObj: any;

  reasonList: any[] = [
    {
      value: 1,
      label:
        '1. เพิ่มราคาสินค้า เนื่องจากสินค้าเกินกว่าจำนวนที่ตกลงกัน คำนวณราคาต่ำกว่าความเป็นจริง',
    },
    {
      value: 2,
      label:
        '2. เพิ่มราคาบริการ เนื่องจากให้บริการเกินกว่าที่กำหนดคำนวณราคาต่ำกว่าความเป็นจริง',
    },
    { value: 3, label: '3. อื่น ๆ' },
  ];

  debitNoteCode: string;

  timelineData: TimelineItem[];

  createDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: this.currentDate,
  };

  expiredDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: new Date(
      this.nextWeekDate.setDate(this.currentDate.getDate() + 7)
    ),
  };

  paidDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: new Date(),
  };

  paymentMethodList: any[] = [{ amount: 1070, paidSolution: 1, note: '' }];

  //test payment
  pathUrl: string;
  editPathUrl: string;
  detailPathUrl: string;
  isConfirm: boolean = false;
  title: string;
  quatationObj: Quotation;

  isGridView: boolean = false;
  isShowChildList: boolean = false;
  isForGroupTemplate: boolean = false;
  isGoEditPage: boolean = false;
  nextButtonDisabled: boolean = true;
  activeStatus: string;
  selectedColumns: string[];
  objectExport: any;
  searchName: string;
  searchId: string;
  activeTab: string = 'all';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  currentStepIndex = 0;

  constructor(
    private _apiService: ApiService,
    private _documentApiService: DocumentApiService,
    private _documentService: DocumentService,
    private _translateService: TranslateService,
    private _globalFuncService: GlobalFuncService,
    private _dragulaService: DragulaService,
    private _modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _componentsService: ComponentsService,
    private _contentTemplateService: QuatationService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _calculateService: CalculateFuncService,
    private _componentService: ComponentsService
  ) {
    this.componentName = 'Item';
    this._unsubscribeAll = new Subject();
    this.componentName = this._contentTemplateService.componentName;
    this.timelineData = this._documentService.timelineData;
    this.debitNoteApiPath = this._documentService.debitNote.ApiPath;
    this.debitNotePath = this._documentService.debitNote.PathURL;
    this.invoiceApiPath = this._documentService.invoice.ApiPath;
    this.receiptApiPath = this._documentService.receipt.ApiPath;
    this.passObj = this._documentService.getDataNote();
    this.initData();
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }
  SetLoadedState(): void {
    this.isLoading = false;
  }

  previousPage(): void {
    this.addBillComponent.initDataForce();
    this.debitNoteStepper.previous();
  }

  previousFinalPage(): void {
    this.debitNoteStepper.previous();
  }

  nextPage(stepper?: number): void {
    if (!this.selectCreditDebitComponent.isValid()) {
      console.log('Please select your info.');
      return;
    }

    const creditDebitData = this.selectCreditDebitComponent.getData();
    const infoData = this.personalInfoForm.value;

    if (creditDebitData) {
      this._documentService.setDataNote('infoDocument', creditDebitData);
    }
    if (infoData) {
      this._documentService.setDataNote('infoDescription', infoData);
    }

    if (this.validatorAddbill() && stepper == 3) {
      return;
    } else {
      this.setFormDocument();
      this.addBillComponent.initDataForce();
      this.setPayObj();
      this.debitNoteStepper.next();
    }
  }

  validatorAddbill(): boolean {
    return this.addBillComponent.validator();
  }

  nextPageAddBill(): void {
    const creditDebitData = this.selectCreditDebitComponent.getData();
    const infoData = this.personalInfoForm.value;

    if (creditDebitData) {
      this._documentService.setDataNote('infoDocument', creditDebitData);
    }
    if (infoData) {
      this._documentService.setDataNote('infoDescription', infoData);
    }
    console.log(this._documentService.getDataNote());
    const noteObj = this._documentService.getDataNote();
    if (noteObj?.infoDocument?.docCode) {
      this.getRefDocData(noteObj.infoDocument.docCode);
    } else {
      console.error('docType is undefined or noteObj is null');
    }

    this.addBillComponent.initDataForce();
    this.addBillComponent.ngAfterViewInit();

    this.debitNoteStepper.next();

    console.log('PASSSSSSOBJ', this.formDocument);

    this.debitNoteCode = this.formDocument.value.debitNoteCode;
    console.log('DebitNote Code : ', this.debitNoteCode);
  }

  setPayObj(): void {
    this.payObj = this.addBillComponent.getGrandTotal();
  }

  handleCreditDebitData(data: any) {
    // console.log('testsrfsdfasatadfgfgadg',data);
    this.creditDebitData = data;
    this.addBillComponent.isSetValueWhenInit = false;
    // this.addBillComponent.ngOnDestroy()
    // this.addBillComponent.initDataForce()
  }

  submit(): void {
    this.sharedData = this._documentService.getDataNote();
    console.log(this.sharedData);
  }

  initData(): void {
    const noteObj = this._documentService.getDataNote();
    this._documentApiService
      .GetDocRunningNumber(this.debitNoteApiPath)
      .subscribe((res) => {
        this.debitNoteCode = res.data.resultData[0];
      });
    this.getReferDocDataList();
    if (noteObj?.infoDocument?.docCode) {
      this.passObj = this.getRefDocData(noteObj.infoDocument.docCode);
    }

    this.getitemList();

    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
      this.isEditing = this.docID ? true : false;
    });

    this.isEditing = false;
    if (this.isEditing) {
      this.getQuatationData(this.invoiceApiPath, this.docID);
    } else {
      this.getLastCode();
      this.customerObj = new Customer();
      this.quotationData = {};
      this.SetLoadedState();
    }

    console.log('thisquoobj', this.quotationForm);
    //test
    this.getAllData();
  }

  //test
  getAllData(obj?: { [key: string]: any }): Promise<any> {
    var self = this;
    let params;

    if (obj) {
      this.searchName = obj.searchName ? obj.searchName : '';

      if (!obj.params) {
        params = { SortPath: 'invoiceCode', Direction: 1, isDelete: false };
      } else {
        params = obj.params;
      }
    } else {
      this.searchName = '';
      params = { SortPath: 'invoiceCode', Direction: 1, isDelete: false };
    }
    return new Promise((resolve, rejects) => {
      this._apiService.GetAllData(this.apiPath, params).subscribe((res) => {
        self.itemList = res.data.resultData;
        console.log(self.itemList);
        self.SetLoadedState();
      });
    });
  }

  getDatabyState(state, tab: string): Promise<any> {
    var self = this;
    self.isLoading = true;
    self.activeTab = tab;

    if (tab == 'all') {
      this.getAllData();
      return;
    }
    return new Promise((resolve, rejects) => {
      this._apiService.GetAllData(this.apiPath, state).subscribe((res) => {
        self.itemList = res.data.resultData;
        console.log(this.itemList);
        self.isLoading = false;
        self.SetLoadedState();
      });
    });
  }

  openPaymentModal(objData?: any): void {
    const modalRef = this._modalService.open(DebitNoteFormpageComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
    });
    modalRef.componentInstance.objData = objData;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      console.log(res);

      let params = {
        paymentTypeId: res.paymentTypeId,
        paymentDate: res.paymentDate,
        isCreateReceipt: true,
      };
      this._documentApiService
        .Payment(this.apiPath, res.docId, params)
        .subscribe(
          (dataPayment) => {
            this._componentService.SuccessSwal();
          },
          (err) => {
            this._componentService.ErrorSwal();
          }
        );
    });
  }

  get f() {
    return this.quotationForm.controls;
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData[0];
        });
    });
  }

  getitemList(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('item', { isDelete: false })
        .subscribe((res) => {
          self.itemList = res.data.resultData;

          this.quotationData = {
            itemList: res.data.resultData,
          };
        });
    });
  }

  getLastCode(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocRunningNumber('Quotation')
        .subscribe((res) => {
          let docCode = document.getElementById('docCode') as HTMLFormElement;
          self.docCode = res.data.resultData[0];
          docCode.value = self.docCode;
        });
    });
  }

  getQuatationData(ApiPath, DocID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._documentApiService.GetDocData(ApiPath, DocID).subscribe(
        (res) => {
          const quotationObj = res.data.resultData[0];
          console.log('++++++++++%+%+%+%+%+%+========', res.data.resultData[0]);

          this.passObj = quotationObj;
          this.isExcludeTax = quotationObj.isExcludeTax;
          // resolve(quotationObj);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  ngOnInit(): void {
    this.debitNoteStepper = new Stepper(
      document.querySelector('#debit-stepper'),
      {
        linear: false,
        animation: true,
      }
    );

    this.contentHeader = {
      headerTitle: 'Document.DebitNote',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Document',
            isLink: true,
            link: '/',
          },
          {
            name: 'Document.DebitNote',
            isLink: true,
            link: '/document/debit-note/view',
          },
          {
            name: 'FormName.DebitNoteForm',
            isLink: false,
            link: '/document/debit-note/view',
          },
        ],
      },
    };

    this.personalInfoForm = this._formBuilder.group({
      reasonId: [0, Validators.required],
      description: ['', Validators.required],
    });

    this.formDocument = this._formBuilder.group({
      debitNoteCode: ['', Validators.required],
      invoiceId: ['', Validators.required],
      documentCreateDate: ['', Validators.required],
      documentExpireDate: ['', Validators.required],
      remarks: '',
      reason: '',
      reasonId: 0,
      discount: 0,
      isDiscountByPercent: true,
      isVatExempt: true,
      taxRate: 0,
      isProductSent: false,
      salesWHT: 0,
      shippingFee: 0,
      shippingAddressId: '',
      shippingAddressDetail: '',
      shippingPhone: '',
      shippingCustomerName: '',
      shippingCustomerTaxId: '',
      item: this._formBuilder.array([
        this._formBuilder.group({
          ordinal: 0,
          itemId: ['', Validators.required],
          quantity: 0,
          discount: 0,
          isDiscountByPercent: true,
          unit: '',
          whtPrice: 0,
          unitPrice: 0,
          whtRate: 0,
          description: this._formBuilder.array([
            this._formBuilder.group({
              ordinal: 0,
              description: '',
            }),
          ]),
        }),
      ]),
    });
  }

  ngOnDestroy(): void {
    this._documentService.clearDataNote();
    this.addBillComponent.ngOnDestroy();
    this._unsubscribeAll.complete();
    this._unsubscribeAll.next();
  }

  changeRefDoc(event: any) {
    console.log(event);
    // this.getRefDocData(event.target.value);
  }

  getReferDocDataList(): Promise<any> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(this.invoiceApiPath, {
          isDelete: false,
          isActive: true,
        })
        .subscribe((res) => {
          this.invoiceDocList = res.data.resultData;
        });
    });
  }

  handleFormValidityChanged(isValid: boolean): void {
    this.nextButtonDisabled = !isValid;
  }

  async getRefDocData(guid: string): Promise<any> {
    const noteObj = this._documentService.getDataNote();

    if (!noteObj || !noteObj.infoDocument || !noteObj.infoDocument.docType) {
      // Handle the case when 'docType' is missing or 'noteObj' is null/undefined
      console.error("Unable to retrieve 'docType' from 'noteObj.infoDocument'");
      return Promise.reject("Unable to determine 'docType'");
    }

    let path;
    if (noteObj.infoDocument.docType === 'receipt') {
      path = this.receiptApiPath;
      this.codeAddBill = 'receiptCode';
    } else if (noteObj.infoDocument.docType === 'invoice') {
      path = this.invoiceApiPath;
      this.codeAddBill = 'invoiceCode';
    } else {
      // Handle the case when 'docType' is invalid or not supported
      console.error("Invalid 'docType' in 'noteObj.infoDocument'");
      return Promise.reject("Invalid 'docType'");
    }

    try {
      const res = await this._apiService.GetDataById(path, guid).toPromise();
      this.passObj = res.data.resultData[0];
      return Promise.resolve(this.passObj);
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error('Error while fetching data:', error);
      return Promise.reject(error);
    }
  }

  formatMoney(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }

  addPaymentMethod() {
    this.paymentMethodList.push({ amount: 0, paidSolution: 1, note: '' });
  }

  setFormDocument() {
    this.setPayObj();
    console.log('passObj : ', this.passObj);
    console.log(this.passObj.item);
    let formValue = this.addBillComponent.getFormValue();
    console.log('1234123412341234', formValue);

    let noteObj = this._documentService.getDataNote();
    let itemArray = this.formDocument.get('item') as FormArray;
    itemArray.clear();
    console.log('FORMMMMMMVALUE', formValue);

    if (Array.isArray(formValue.item)) {
      console.log('YYYYYYYYYOYOYOYOYO', formValue);

      for (let item of formValue.item) {
        console.log('11111111111111111111111111111111', item);
        let itemFormGroup = this._formBuilder.group({
          ordinal: item.ordinal,
          itemId: [item.itemId, Validators.required],
          quantity: item.quantity,
          discount: item.discount,
          isDiscountByPercent: item.isDiscountByPercent,
          unit: item.unit,
          remarks: item.remarks,
          unitPrice: item.unitPrice,
          whtPrice: item.whtPrice,
          description: this._formBuilder.array([]), // Initialize an empty FormArray for 'description'
        });
        console.log('SHOW ADDBILL REBACK', itemFormGroup);

        let descriptionArray = itemFormGroup.get('description') as FormArray;
        for (let description of item.description) {
          let descriptionFormGroup = this._formBuilder.group({
            ordinal: description.ordinal,
            description: description.description,
          });
          descriptionArray.push(descriptionFormGroup);
        }
        itemArray.push(itemFormGroup);
      }
      console.log('ITEMARRAY PUSH', itemArray);
    }

    let invoiceId: string;
    let receiptId: string;
    if (noteObj.infoDocument.docType == 'invoice') {
      invoiceId = noteObj?.infoDocument?.docCode;
    }
    if (noteObj.infoDocument.docType == 'receipt') {
      receiptId = noteObj?.infoDocument?.docCode;
    }
    console.log(this.passObj.discount);

    this.formDocument.patchValue({
      debitNoteCode: this.debitNoteCode,
      invoiceId: invoiceId,
      receiptId: receiptId,
      documentCreateDate: formValue.documentCreateDate,
      documentExpireDate: formValue.documentExpireDate,
      reason: this.personalInfoForm.value.description,
      reasonId: this.personalInfoForm.value.reasonId,
      remarks: formValue.remarks,
      discount: formValue.discount,
      shippingFee: formValue.shippingFee,
      shippingAddressId: formValue.shippingAddressId,
      shippingAddressDetail: formValue.shippingAddressDetail,
      shippingPhone: formValue.shippingPhone,
      shippingCustomerName: formValue.shippingCustomerName,
      shippingCustomerTaxId: formValue.shippingCustomerTaxId,
      isDiscountByPercent: formValue.isDiscountByPercent,
      isVatExempt: formValue.isVatExempt,
      taxRate: formValue.taxRate,
      isProductSent: formValue.isProductSent,
      salesWHT: formValue.salesWHT ? formValue.salesWHT : 0.0,
      // item: this.passObj.item,
    });
    console.log(this.formDocument.value);
  }

  removePaymentMethod(index: number) {
    this.paymentMethodList.splice(index, 1);
  }

  onDataChange(event: any) {
    console.log('THIS DATA EVENTS', event);
  }

  getResultData(data: any): void {
    console.log('gsadfasdfasdsf', data);

    this.callBackData = data;
  }

  openConfirmModal(content: any): void {
    let isInvalidForm;

    isInvalidForm = this.paymentComponent.checkInvalidForm();

    let isConfirm = true;
    let title;
    let detail;
    let contentNameText = this.isEditing ? content.name : this.componentName;
    if (this.isEditing) {
      var stateForm = this._translateService.instant('General.Edit');
    } else {
      title = this._translateService.instant('DebitNote.ConfirmCreateTitle');
      detail = this._translateService.instant('DebitNote.ConfirmCreate');
    }

    if (isInvalidForm) {
      console.error('Please select or fill all input');
    } else {
      this.openModal(title, detail, isConfirm);
    }
  }

  openModal(
    title: string,
    detail: string,
    IsConfirm: boolean,
    isDraft?: boolean
  ): void {
    if (this.formDocument.value) {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        if (IsConfirm) {
          this.Submit(false, this.formDocument.value, false);
        } else {
          this._router.navigate([`/document/quotation/view`]);
        }
      });
    } else {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        this._router.navigate([`/document/quotation/view`]);
      });
    }
    console.log('=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=', this.quotationForm);
  }

  onItemInvalidChange(itemInvalid: boolean[]) {
    this.itemInvalid = itemInvalid;
    console.log('itemInvalid (debit) : ', itemInvalid);
  }

  onDescriptionInvalidChange(descriptionInvalid: boolean) {
    this.descriptionInvalid = descriptionInvalid;
  }

  onSumTotalInvalidChange(sumTotalInvalid: boolean) {
    this.sumTotalInvalid = sumTotalInvalid;
    console.log('sumTotalInvalid (debit) : ', sumTotalInvalid);
  }

  onTotalDiscountInvalidChange(totalDiscountInvalid: boolean) {
    this.totalDiscountInvalid = totalDiscountInvalid;
    console.log('totalDiscountInvalid (debit) : ', totalDiscountInvalid);
  }

  hasInvalidItem(): boolean {
    return this.itemInvalid.some((invalid) => invalid);
  }

  hasInvalidSumTotal(): boolean {
    return this.sumTotalInvalid;
  }

  hasInvalidTotalDiscount(): boolean {
    return this.totalDiscountInvalid;
  }

  Submit(
    isDraft?: boolean,
    quotationForm?: object,
    isEditing?: boolean,
    docID?: string
  ) {
    var self = this;
    if (isEditing) {
      this.blockUI.start();
      this._documentApiService
        .UpdateDocData(this.debitNoteApiPath, quotationForm, docID)
        .subscribe(
          (res) => {
            if (!isDraft) {
              this._documentApiService
                .ApproveDocData(this.debitNoteApiPath, docID)
                .subscribe((res) => {
                  if (res) {
                    this.blockUI.stop();
                    self._componentsService.SuccessSwal();
                    this._router.navigate([
                      `/document/debit-note/detail/${docID}`,
                    ]);
                  }
                });
            } else {
              this.blockUI.stop();
              console.log('edit update');
              self._componentsService.SuccessSwal();
              self._router.navigate([`/document/debit-note/view`]);
            }
          },
          (err) => {
            this.blockUI.stop();
            self._componentsService.ErrorSwal();
            self._router.navigate([`/document/debit-note/view`]);
          }
        );
    } else {
      this.blockUI.start();
      console.log(this.debitNoteApiPath);
      // if (quotationForm.salesWHT.) {
      //   return;
      // } else {
      //   console.log(quotationForm);
      // }
      let paymentTypeId = this.paymentComponent.getTypePaymentId();

      console.log(quotationForm);
      this._documentApiService
        .AddDocData(this.debitNoteApiPath, quotationForm)
        .subscribe(
          (res) => {
            let qoGuid = res.data.resultData[0].id;
            if (paymentTypeId == PaymentMethodList.Cash.id) {
              this._documentApiService
                .CashPayment(this.debitNoteApiPath, qoGuid, {
                  paymentDate: this.getPaymentDate(),
                  paymentRemarks: this.getPaymentRemarks(),
                })
                .subscribe((res) => {
                  if (res) {
                    this.blockUI.stop();
                    self._componentsService.SuccessSwal();
                    this._router.navigate([
                      `/document/debit-note/detail/${qoGuid}`,
                    ]);
                  }
                });
            } else if (paymentTypeId == PaymentMethodList.Transfer.id) {
              this._documentApiService
                .TransferPayment(this.debitNoteApiPath, qoGuid, {
                  paymentDate: this.getPaymentDate(),
                  bankAccountId: this.getBankAccountId(),
                  paymentRemarks: this.getPaymentRemarks(),
                })
                .subscribe((res) => {
                  if (res) {
                    this.blockUI.stop();
                    self._componentsService.SuccessSwal();
                    this._router.navigate([
                      `/document/debit-note/detail/${qoGuid}`,
                    ]);
                  }
                });
            } else {
              this._documentApiService
                .Payment(this.debitNoteApiPath, qoGuid, {
                  paymentTypeId: this.getPaymentMethod(),
                  paymentDate: this.getPaymentDate(),
                })
                .subscribe((res) => {
                  if (res) {
                    this.blockUI.stop();
                    self._componentsService.SuccessSwal();
                    this._router.navigate([
                      `/document/debit-note/detail/${qoGuid}`,
                    ]);
                  }
                });
            }
          },
          (err) => {
            this.blockUI.stop();
            self._componentsService.ErrorSwal();
            self._router.navigate([`/document/debit-note/view`]);
          }
        );
    }
  }

  getPaymentMethod(): string {
    let tempObjMethod = this.paymentComponent.getResult();
    console.log('tempObjMethod : ', tempObjMethod.controls);
    return tempObjMethod.controls.paymentTypeId.value;
  }

  getPaymentDate(): string {
    let tempObjDate = this.paymentComponent.getResult();
    console.log('tempObjDate : ', tempObjDate.controls);
    return tempObjDate.controls.paymentDate.value;
  }

  getBankAccountId(): string {
    let tempObjBankAccountId = this.paymentComponent.getResult();
    console.log('tempObjBankAccountId : ', tempObjBankAccountId.controls);
    return tempObjBankAccountId.controls.bankAccountId.value;
  }

  getPaymentRemarks(): string {
    let tempObjRemark = this.paymentComponent.getResult();
    console.log('tempObjRemark : ', tempObjRemark.controls);
    return tempObjRemark.controls.paymentRemarks.value;
  }

  handlePayment(event: Event) {
    console.log(event);
  }
}
