<div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">
        {{ title }}
    </h5>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">
            <i data-feather="x" size="22"></i>
        </span>
    </button>
</div>
<div class="modal-body">
    <h5 class="modal-title" id="exampleModalCenterTitle">
        {{ detail }}
    </h5>

    <label class="form-label"> {{ "BookingCalendar.Modal.Remarks" | translate }}</label>
    <form [formGroup]="formGroup" class="remark-approval">
        <textarea class="form-control" formControlName="remarks" id="" cols="30" rows="5"></textarea>
    </form>
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-secondary m-0" (click)="closeModal()" rippleEffect>
      {{ "Form.Cancel" | translate }}
    </button>
    <a type="button" class="btn btn-primary m-0" (click)="callBack()" rippleEffect>
      {{ "Form.Submit" | translate }}
    </a>
  </div>