import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  contentHeader: object;
  componentName: string;
  isEditing: boolean = true;

  user: User;

  constructor(
    private _route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService
  ) {
    this._route.data.subscribe((res) => {
      if (res.isProfilePath != undefined && res.isProfilePath != null) {
        this.isEditing = !res.isProfilePath;
      }
    });
  }

  ngOnInit(): void {

    this.contentHeader = {
      headerTitle: 'General.Settings',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }
}
