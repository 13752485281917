<div
  class="custom-dropzone"
  ngx-dropzone
  [accept]="'image/png, image/jpg, image/jpeg'"
  (change)="onFilesSelected($event)"
>
  <ngx-dropzone-label>
    <div>
      <h2>
        <i data-feather="file" size="150"></i><br />
        Drop files here or Click here to files images.
      </h2>
    </div>
  </ngx-dropzone-label>
  <ngx-dropzone-image-preview
    ngProjectAs="ngx-dropzone-preview"
    *ngFor="let file of selectedFiles"
    [file]="file.file"
    [removable]="true"
    (removed)="onRemoveAtteched(file, images)"
  >
  </ngx-dropzone-image-preview>
</div>
