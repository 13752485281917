import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreCommonModule } from '@core/common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { MasterAddrCrudComponent } from './master-addr-crud.component';
import { MasterAddrFormModalComponent } from './master-addr-form-modal/master-addr-form-modal.component';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: 'admin/master-address',
    component: MasterAddrCrudComponent,
    canActivate: [AuthGuard],
    data: { animation: 'master-address', roles: ['SuperAdmin'] },
  },
];

@NgModule({
  declarations: [MasterAddrCrudComponent, MasterAddrFormModalComponent],
  imports: [
    CommonModule,
    [RouterModule.forChild(routes)],
    CoreCommonModule,
    NgbModule,
    ContentHeaderModule,
    NgSelectModule,
    TranslateModule
  ],
  exports: [MasterAddrCrudComponent, MasterAddrFormModalComponent],
})
export class MasterAddrCrudModule {}
