import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Quotation, TempRowQuotation } from '../../../../model/Quotation';
import { DocumentService } from '../../document.service';
import { AuthenticationService } from '../../../../../auth/service';
import { TranslateService } from '@ngx-translate/core';
import { DocumentApiService } from '../../../../service/document-api.service';
import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { ApiService } from '../../../../service/api.service';
import { CalculateFuncService } from '../../../../service/calculate-func.service';
import { GlobalFuncService } from '../../../../service/global-func.service';
import { User } from '../../../../../auth/models';
import { TimelineItem } from '../../../../components/horizontal-timeline/horizontal-timeline.component';
import CustomerAddress from '../../../../model/CustomerAddress';
import { BehaviorSubject, Observable } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-purchase-request-detail',
  templateUrl: './purchase-request-detail.component.html',
  styleUrls: ['./purchase-request-detail.component.scss'],
})
export class PurchaseRequestDetailComponent implements OnInit, AfterViewInit {
  contentHeader: object;
  typeDocName: string;
  currentUser: User;
  timelineData: TimelineItem[];

  quotationObj: Quotation;
  customerAddressObj: CustomerAddress;

  orderId: string;
  orderStatus: any = [];
  currentStep: number;

  apiPath: string;
  editPathUrl: string;
  titleDoc: string = 'ใบขอสั่งซื้อ';

  docID: string;
  docStatus: number;
  isLoading: boolean;

  sumNetPrice: number;
  sumVatPrice: number;

  checkCreateInvoice: boolean = false;
  disableButton: boolean = false;

  detailPathUrl: string;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private _documentService: DocumentService,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _documentApiService: DocumentApiService,
    public _route: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private _apiService: ApiService,
    private _calculateService: CalculateFuncService,
    private _globalFuncService: GlobalFuncService
  ) {
    this.timelineData = this._documentService.timelineBuyData;
    this.apiPath = this._documentService.purchaseRequest.ApiPath;
    this.detailPathUrl = this._documentService.purchaseRequest.DetailPathURL;
    this.editPathUrl = this._documentService.purchaseRequest.EditPathURL;

    this.initData();
  }

  SetLoadingState() {
    this.isLoading = true;
  }
  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;

    this._authenticationService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    this.contentHeader = {
      headerTitle: 'Document.PurchaseRequest',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Document',
            isLink: true,
            link: '/',
          },
          {
            name: 'Document.PurchaseRequest',
            isLink: true,
            link: '/document/purchase-request/view',
          },
          {
            name: 'FormName.PurchaseRequestDetail',
            isLink: false,
            link: '/document/purchase-request/view',
          },
        ],
      },
    };
  }

  activeTab: string = 'detail';
  @ViewChild('navFilled') nav: NgbNav;
  public active_fragment: BehaviorSubject<string> = new BehaviorSubject('');
  
  ngAfterViewInit(): void {
    this._route.fragment.subscribe((frag) => {
      this.active_fragment.next(frag);
      
      console.log(this.active_fragment.value)
      if (!this.active_fragment.value) {
        this.nav.select('detail')
        this.activeTab = 'detail'
        console.log('select default page')
      } else {
        this.activeTab = this.active_fragment.value
      }
      
    });
  }

  initData(): void {
    this.SetLoadingState();
    this.blockUI.start();
    this.typeDocName = 'purchaseRequisitionCode';
    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
    });
    console.log('Doc id : ' + this.docID);
    console.log(this.apiPath);

    this.getQuotationData();
  }

  refreshData() {
    this.initData();
  }

  getQuotationData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocData(this.apiPath, this.docID)
        .subscribe((res) => {
          self.quotationObj = res.data.resultData[0];
          self.getCustomerAddress(self.quotationObj.customerId);
          self.calculateSummary();
          self.SetLoadedState();
          self.SetDisableButton();

          this.docStatus = self.quotationObj.transactionStatusId;
          console.log('Current Status: ', this.docStatus);

          this.orderId = self.quotationObj.orderId;
          this.getOrderStatus(this.orderId).subscribe((orderStatusRes) => {
            this.orderStatus = orderStatusRes.data.resultData;

            console.log('Order Status: ', this.orderStatus[0]);

            this.currentStep = this._globalFuncService.GetCurrentStep(
              this.orderStatus
            );

            this.blockUI.stop();
            console.log('Current Step: ', this.currentStep);
          });
        });
    });
  }

  getOrderStatus(orderId: string): Observable<any> {
    return this._apiService.GetDataById('BuyOrder/State', orderId);
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData.find((it) => {
            return it['useForShipping'] == true;
          });
        });
    });
  }

  calculateSummary() {
    let itemList = [];

    this.quotationObj.item.forEach((element, index) => {
      let tempRow: TempRowQuotation = new TempRowQuotation();
      tempRow.rowType = 'item';
      tempRow.itemId = element.itemId;
      tempRow.description = element.description[0].description;
      tempRow.discount = element.discount;
      tempRow.quantity = element.quantity;
      tempRow.unit = element.unit;
      tempRow.unitPrice = element.unitPrice;
      tempRow.whtRate = 0.0;

      itemList.push(tempRow);
    });

    this.sumNetPrice = this._calculateService.calculateNet(
      itemList,
      true,
      this.quotationObj.taxRate,
      this.quotationObj.discount
    );

    this.sumVatPrice = this._calculateService.calculateVat(
      this.sumNetPrice,
      this.quotationObj.taxRate
    );
  }

  convertNumbertoMoneyFormat(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }

  SetDisableButton() {
    this.checkCreateInvoice = this.quotationObj.isCreateInvoice;
    if (this.checkCreateInvoice === true) {
      this.disableButton = true;
    } else {
      this.disableButton = false;
    }
  }
}
