import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { TimelineService } from '../timeline.service';

@Component({
  selector: 'app-timeline-create',
  templateUrl: './timeline-create.component.html',
  styleUrls: ['./timeline-create.component.scss']
})
export class TimelineCreateComponent implements OnInit {

  config: ICMSConfig;

  constructor(private _timelineService: TimelineService) {
    this.config = this._timelineService.config;
  }

  ngOnInit(): void {
  }

}
