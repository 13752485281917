import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-content-child-document',
  templateUrl: './content-child-document.component.html',
  styleUrls: ['./content-child-document.component.scss'],
})
export class ContentChildDocumentComponent implements OnInit, AfterViewInit {
  @Input() orderStateData: any;
  @Input() currentDocCode: string;
  @Input() notShowTitle: boolean = false;
  @Output() callRefreshDataFunc: EventEmitter<any> = new EventEmitter();

  currentLang: string;

  constructor(
    private elementRef: ElementRef,
    private _route: ActivatedRoute,
    private _router: Router,
    private _translateService: TranslateService
  ) {
    this.currentLang = this._translateService.currentLang || 'en';
  }

  ngOnInit(): void {
    console.log(this.currentDocCode);

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });
  }

  ngAfterViewInit(): void {
    let summaryDOMList: any[] =
      this.elementRef.nativeElement.querySelectorAll('summary');

    summaryDOMList.forEach((element, index) => {
      element.addEventListener('click', this.onClick.bind(this, element));
    });

    // this.elementRef.nativeElement
    // .querySelectorAll('summary')
    // .addEventListener('click', this.onClick.bind(this));

    // console.log(this.elementRef.nativeElement.querySelector('summary'))
  }

  onClick(event, element) {
    console.log('event', event);
    console.log('element', element);
    element.open = true;
    // console.log(event)
    // event.stopPropagation();
  }

  callRefreshData(url: string) {
    console.log('refresh Data');
    this._router.navigateByUrl(url).then(() => {
      console.log('calling refresh');
      this.callRefreshDataFunc.emit();
    });
  }
}
