import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from '../../modal/modal.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import Category from 'app/main/model/Category';
import SubCategory from 'app/main/model/SubCategory';
import { Subject } from 'rxjs';
import { ApiService } from 'app/main/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainConfig } from 'app/main/config/MainConfig';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from '../../components.service';
import { AuthenticationService } from 'app/auth/service';
import { SubcategoryService } from 'app/main/pages/item-manage/subcategory/subcategory.service';

@Component({
  selector: 'app-subcategory-form',
  templateUrl: './subcategory-form.component.html',
  styleUrls: ['./subcategory-form.component.scss'],
})
export class SubcategoryFormComponent implements OnInit {
  private _unsubscribeAll: Subject<any>;
  componentName: string;

  apiPath: string;
  pathUrl: string;

  parentApiUrl: string;

  contentHeader: object;

  itemGuid: string;
  categoryList: Category[];

  registerForm: FormGroup;
  isSubmit: Boolean = false;
  @Input() itemObj: SubCategory;
  @Input() isLoading: boolean;
  @Input() callbackUrl: string;
  @Input() isEditing: boolean;

  summernoteConfig: object;
  modeText: string;
  activeModal: any;

  @ViewChild('confirmModal') confirmModal: ModalComponent;

  constructor(
    private _apiService: ApiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _modalService: NgbModal,
    private _mainConfig: MainConfig,
    private _subCategoryTemplateService: SubcategoryService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder
  ) {
    this.SetLoadingState();
    this.initConfig();
    this.getParentData();
    this.initForm(this.isEditing);
    console.log(this.itemObj);
    this.registerForm = this._formBuilder.group({
      id: [null],
      name: ['', [Validators.required, Validators.maxLength(50)]],
      categoryId: [null, [Validators.required]],
    });
  }
  get f() {
    return this.registerForm.controls;
  }
  initConfig(): void {
    this._unsubscribeAll = new Subject();
    this.componentName = this._subCategoryTemplateService.componentName;
    this.apiPath = this._subCategoryTemplateService.apiUrl;
    this.parentApiUrl = this._subCategoryTemplateService.parentApiUrl;
    this.pathUrl = this._subCategoryTemplateService.pathUrl;

    this.summernoteConfig = this._mainConfig.summernoteNewsConfig;
    this._apiService.SetHttpHeaders(this._authenticationService.tokenValue);

    this.modeText = this.isEditing
      ? this._translateService.instant('General.Edit')
      : this._translateService.instant('General.Create');
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;
    this._route.paramMap.subscribe((val) => {
      self.itemGuid = self._route.snapshot.paramMap.get('id');
      self.isEditing = self.itemGuid ? true : false;
      if (self.isEditing) {
        self.getCurrentData(self.itemGuid);
      } else {
        self.itemObj = new SubCategory();
        self.buildForm();
        self.SetLoadedState();
      }
    });

    this.contentHeader = {
      headerTitle: this.isEditing
        ? this.modeText + ' ' + this.componentName
        : 'Create Item',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this.componentName,
            isLink: true,
            link: this.pathUrl,
          },
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  initForm(isEditing: boolean): void {
    this.registerForm = this._formBuilder.group({
      name: ['', Validators.required],
    });
  }

  buildForm(): void {
    var self = this;
    this.registerForm.patchValue({
      id: self.itemObj.id,
      name: self.itemObj.name,
      categoryId: self.itemObj.categoryId,
    });
  }

  getFormControlsFields(): object {
    const formGroupFields: object = {};
    for (const field of Object.keys(this.itemObj)) {
      formGroupFields[field] = new FormControl('');
    }
    return formGroupFields;
  }

  getCurrentData(guid: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService.GetDataById('subCategory', guid).subscribe((res) => {
        self.itemObj = res.data.resultData[0];
        if (this.itemObj) {
          self.buildForm();
          self.SetLoadedState();
        }
      }, rejects);
    });
  }

  getParentData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(this.parentApiUrl, { isDelete: false })
        .subscribe((res) => {
          self.categoryList = res.data.resultData;
        }, rejects);
    });
  }

  openConfirmModal(content: any): void {
    this.isSubmit = true;
    let isConfirm = true;
    let title;
    let detail;
    let contentNameText = this.isEditing ? content.name : this.componentName;
    if (this.isEditing) {
      title = this._translateService.instant(
        'ManageSubCategory.ConfirmEditTitle'
      );
      detail = this._translateService.instant('ManageSubCategory.ConfirmEdit');
    } else {
      title = this._translateService.instant(
        'ManageSubCategory.ConfirmCreateTitle'
      );
      detail = this._translateService.instant(
        'ManageSubCategory.ConfirmCreate'
      );
    }

    this.openModal(title, detail, isConfirm);
  }

  openCancelModal(content?: any): void {
    let isConfirm = false;
    let title;
    let detail;
    let contentNameText = this.isEditing
      ? this.itemObj.name
      : this.componentName;
    if (this.isEditing) {
      title = this._translateService.instant(
        'ManageSubCategory.CancelEditTitle'
      );
      detail = this._translateService.instant('ManageSubCategory.CancelEdit');
    } else {
      title = this._translateService.instant(
        'ManageSubCategory.CancelCreateTitle'
      );
      detail = this._translateService.instant('ManageSubCategory.CancelCreate');
    }

    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    var self = this;
    if (!IsConfirm) {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        self._router.navigate([this.pathUrl]);
      });
    } else {
      if (!this.registerForm.invalid) {
        const modalRef = this._modalService.open(ModalComponent, {
          centered: true,
          backdrop: 'static',
        });
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.isConfirm = IsConfirm;
        modalRef.componentInstance.detail = detail;
        modalRef.componentInstance.callBackFunc.subscribe((res) => {
          if (IsConfirm) {
            self.Submit();
          }
          self._router.navigate([this.pathUrl]);
        });
      }
    }
  }

  Submit(): void {
    var self = this;
    if (this.registerForm.valid) {
      if (
        !this.itemObj.isActive ||
        this.itemObj.isActive === null ||
        this.itemObj.isActive === undefined
      ) {
        this.itemObj.isActive = false;
      }
      if (this.isEditing) {
        this._apiService
          .UpdateDataById(
            this.apiPath,
            this.itemObj.id.toString(),
            this.registerForm.value
          )
          .subscribe(
            (res) => {
              this._apiService
                .SetIsActiveData(
                  this.apiPath,
                  this.itemObj.id.toString(),
                  this.itemObj.isActive
                )
                .subscribe((res) => {});

              self._componentsService.SuccessSwal();
              self._router.navigate([this.pathUrl]);
            },
            (err) => {
              self._componentsService.ErrorSwal();
            }
          );
      } else {
        this._apiService
          .AddData(this.apiPath, this.registerForm.value)
          .subscribe(
            (res) => {
              this._apiService
                .SetIsActiveData(
                  this.apiPath,
                  res.data.resultData[0],
                  this.itemObj.isActive
                )
                .subscribe((res) => {});

              self._componentsService.SuccessSwal();
              self._router.navigate([this.pathUrl]);
            },
            (err) => {
              self._componentsService.ErrorSwal();
            }
          );
      }
    } else {
      // Handle form validation errors
      let title = this._translateService.instant('Alert.FormValidationFailed')
      this._componentsService.ErrorSwal(title);
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
