<div class="card">
  <div class="d-flex justify-content-center">
    <!-- <div>เพิ่มรายการโปรด</div> -->
    <div>
      <h4>อัพโหลดไฟล์เอกสาร</h4>
    </div>
  </div>
  <hr />
  <div class="attach-file d-flex">
    <div  class="custom-dropzone col-7">
      <div
        ngx-dropzone
        [accept]="
          'image/png, image/jpg, image/jpeg, application/msword, application/vnd.ms-excel, application/pdf'
        "
        (change)="onSelectAttachedFile($event)"
      >
        <ngx-dropzone-label>
          <div>
            <h2>
              <i data-feather="file" size="120"></i><br />
              Drop files here or Click here to choose files.
            </h2>
          </div>
        </ngx-dropzone-label>
      </div>
      <label>Choose only .jpg, .jpeg, .png, .doc, .docx, .xlsx, .pdf</label>
    </div>

    <div *ngIf="listAttachedFile.length > 0" class="list-file col-5 m-1">
      <h5>ไฟล์ที่ถูกอัพโหลด</h5>
      <ol>
        <div class="name-file-list">
          <li *ngFor="let file of listAttachedFile, let i = index" class="file-quotation">
            <span class="index-file">{{i+1}}.</span>
            <span class="name-file">{{ file.name }}</span>
            <a class="icon-remove-file" (click)="onRemoveAttachedFile(i)">
              <i data-feather='x'></i>
            </a>
          </li>
        </div>
      </ol>
    </div>
  </div>
</div>
<hr/>
<div class="d-flex justify-items-end">
  <div>
    <a
      class="btn btn-lg btn-primary"
      (click)="onSubmit()"
    >
      {{ "Form.Submit" | translate }}
    </a>
  </div>
</div>
