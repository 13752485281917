<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <div class="col-2">
          <div class="card calendar-sidebar border">
            <div class="card-body">
              <a (click)="createEvent()" class="btn btn-primary w-100 mb-2">
                {{ "BookingCalendar.Modal.CreateEvent" | translate }}
              </a>

              <hr />

              <ul class="list-unstyled brand-list">
                <li>
                  <div class="custom-control custom-checkbox mb-1">
                    <input type="checkbox" [checked]="isAllBranchChecked" class="custom-control-input" id="branch-all"
                      (change)="onSelectedAllBranch($event)" />
                    <label class="custom-control-label" for="branch-all">{{ 'General.All' | translate }}</label>
                  </div>
                </li>
                <hr />
                <li *ngFor="let branch of branchList; index as ind">
                  <div class="custom-control custom-checkbox mb-1">
                    <input type="checkbox" [checked]="branch.isChecked" class="custom-control-input" id="branch-{{ind}}" [value]="branch.id"
                      (change)="onSelectedBranch($event, branch)" />
                    <label class="custom-control-label" for="branch-{{ind}}">{{branch.name}}</label>
                  </div>
                  <!-- <span>746</span> -->
                </li>
              </ul>

            </div>
          </div>
        </div>
        <!-- calendar -->
        <div class="col-10 position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar #bookingCalendar [options]="calendarOptions"></full-calendar>
            </div>
          </div>
        </div>
        <!-- calendar -->
      </div>
    </div>
  </div>
</div>