import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  componentName: string = 'Notification';
  apiPath: string = 'Notification';
  pathUrl: string = '/notification';

  constructor() { }
}
