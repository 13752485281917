<div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
  <app-loading></app-loading>
</div>

<div *ngIf="!isLoading" class="row item_form">
  <div class="col-md-12">
    <form [formGroup]="registerForm" id="item-form">
      <h4 class="mb-2">{{ "General.ItemManage" | translate }}</h4>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label"
          >{{ "Form.Name" | translate }}
          <span class="text-danger">*</span></label
        >
        <div class="col-sm-9">
          <input
            class="form-control"
            type="text"
            formControlName="name"
            (blur)="checkFirstLetterInvalid($event, f.name)"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.name?.invalid) ||
                (f.name?.invalid && (f.name?.dirty || f.name?.touched))
            }"
            [placeholder]="'Form.EnterName' | translate"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block': f.name.invalid && (f.name.dirty || f.name.touched)
            }"
          >
            <div>
              <div *ngIf="f.name.errors?.required">
                {{ "Handle.Name" | translate }}
              </div>
              <div *ngIf="f.name.errors?.maxlength">
                {{ "Handle.Exceed" | translate }}
                100
                {{ "Handle.Char" | translate }}
              </div>
              <div
                *ngIf="
                  checkFirstLetterInvalid($any(f.name.value), $any(f.name))
                "
              >
                {{ "Handle.FirstLetterInvalid" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-3 col-form-label"
          >{{ "Form.Cover" | translate }}
          <span class="text-danger">*</span></label
        >
        <div class="col-sm-9">
          <app-image-upload #imageUploadComponent></app-image-upload>
        </div>
      </div>

      <div formGroupName="description">
        <div *ngFor="let lang of langList">
          <div class="form-group row" [class.d-none]="selectedLang != lang">
            <div class="col-sm-3 col-form-label">
              <label for="colFormLabelLg">
                {{ "Form.Description" | translate }}
                <span class="text-danger">*</span>
              </label>
              <br />
              <div *ngIf="langList.length > 1" class="badge badge-primary">
                {{ lang | uppercase }}
              </div>
            </div>
            <div class="col-sm-9">
              <textarea
                class="form-control"
                [formControlName]="lang"
                rows="5"
                [ngClass]="{
                  'is-invalid error':
                    (isSubmit && f.description?.invalid) ||
                    (f.description?.invalid &&
                      (f.description?.dirty || f.description?.touched))
                }"
                [placeholder]="'Form.EnterDescription' | translate"
              ></textarea>
              <div class="invalid-feedback">
                <div
                  *ngIf="
                    isSubmit ||
                    (f.description.invalid &&
                      (f.description.dirty || f.description.touched))
                  "
                >
                  {{ "Handle.DescriptionAllLanguage" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div formGroupName="detail">
        <div *ngFor="let lang of langList">
          <div class="form-group row" [class.d-none]="selectedLang != lang">
            <div class="col-sm-3 col-form-label">
              <label for="colFormLabelLg">
                {{ "Form.Detail" | translate }}
                <span class="text-danger">*</span>
              </label>
              <br />
              <div *ngIf="langList.length > 1" class="badge badge-primary">
                {{ lang | uppercase }}
              </div>
            </div>
            <div class="col-sm-9">
              <div
                [ngClass]="{
                  'invalid-border':
                    (isSubmit && f.detail?.invalid) ||
                    (f.detail?.invalid &&
                      (f.detail?.dirty || f.detail?.touched))
                }"
              >
                <textarea
                  class="form-control"
                  [ngxSummernote]="summernoteConfig"
                  [formControlName]="lang"
                ></textarea>
              </div>
              <div
                class="invalid-feedback"
                [ngClass]="{
                  'd-block':
                    (isSubmit && f.detail?.invalid) ||
                    (f.detail?.invalid &&
                      (f.detail?.dirty || f.detail?.touched))
                }"
              >
                <div
                  *ngIf="
                    isSubmit ||
                    (f.detail.invalid && (f.detail.dirty || f.detail.touched))
                  "
                >
                  {{ "Handle.DetailAllLanguage" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-3 col-form-label">{{
          "Form.Status" | translate
        }}</label>
        <div class="col-sm-9">
          <div
            class="custom-control custom-switch custom-control-inline col-form-label"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              id="isActive"
              formControlName="isActive"
            />
            <label class="custom-control-label" for="isActive"></label>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-3 col-form-label">{{
          "Form.IsShowing" | translate
        }}</label>
        <div class="col-sm-9">
          <div
            class="custom-control custom-switch custom-control-inline col-form-label"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              id="isShowing"
              formControlName="isShowing"
            />
            <label class="custom-control-label" for="isShowing"></label>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-3 col-form-label">{{
          "Form.IsNewProduct" | translate
        }}</label>
        <div class="col-sm-9">
          <div
            class="custom-control custom-switch custom-control-inline col-form-label"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              id="isNewProduct"
              formControlName="isNewProduct"
            />
            <label class="custom-control-label" for="isNewProduct"></label>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-3 col-form-label">{{
          "Form.IsBestSeller" | translate
        }}</label>
        <div class="col-sm-9">
          <div
            class="custom-control custom-switch custom-control-inline col-form-label"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              id="isBestSeller"
              formControlName="isBestSeller"
            />
            <label class="custom-control-label" for="isBestSeller"></label>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{
          "Form.Barcode" | translate
        }}</label>
        <div class="col-sm-9">
          <input
            class="form-control"
            type="text"
            formControlName="itemBarcode"
            [(ngModel)]="itemBarcode"
            [ngClass]="{
              'is-invalid error':
                f.name?.invalid && (f.name?.dirty || f.name?.touched)
            }"
          />
          <app-barcode-generator
            style="width: 100%"
            [value]="itemBarcode"
          ></app-barcode-generator>
        </div>
      </div> -->

      <!-- <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{
          "Form.Quantity" | translate
        }}</label>
        <div class="col-sm-9">
          <input
            class="form-control"
            type="text"
            thousandSeparator=","
            (keypress)="restrictInput($event)"
            (blur)="handleBlur('quantity')"
            formControlName="quantity"
            [value]="formattedValue"
            [ngClass]="{
              'is-invalid error':
                f.quantity?.invalid &&
                (f.quantity?.dirty || f.quantity?.touched)
            }"
            #quantityInput
          />
        </div>
      </div> -->

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{
          "Form.Price" | translate
        }}</label>
        <div class="col-sm-9">
          <div class="form-group row">
            <div class="col-md-6">
              <input
                class="form-control"
                #sellingPriceInput
                type="text"
                formControlName="sellingPrice"
                step="0.00"
                thousandSeparator=","
                (keypress)="restrictInput($event)"
                (blur)="handleBlur('sellingPrice')"
                [ngClass]="{
                  'is-invalid error':
                    (f.sellingPrice?.invalid &&
                      (f.sellingPrice?.dirty || f.sellingPrice?.touched)) ||
                    isPriceInvalid
                }"
              />
              <div>
                <label for="helpInputTop">{{
                  "Form.SellingPrice" | translate
                }}</label>
                <small class="text-muted">
                  <i>{{ "iTem.Number" | translate }}</i></small
                >
              </div>
            </div>
            <div class="col-md-6">
              <input
                class="form-control"
                type="text"
                thousandSeparator=","
                formControlName="purchasePrice"
                (keypress)="restrictInput($event)"
                (blur)="handleBlur('purchasePrice')"
                [ngClass]="{
                  'is-invalid error':
                    (f.purchasePrice?.invalid &&
                      (f.purchasePrice?.dirty || f.purchasePrice?.touched)) ||
                    isPriceInvalid
                }"
              />
              <div>
                <label for="helpInputTop">{{
                  "Form.purchasePrice" | translate
                }}</label>
                <small class="text-muted">
                  <i>{{ "iTem.Number" | translate }}</i></small
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">SV</label>
        <div class="col-sm-9">
          <div class="form-group row">
            <div class="col-md-6">
              <input
                class="form-control"
      
                type="text"
                formControlName="repoveSV"
                step="0.00"
                thousandSeparator=","
                (keypress)="restrictInput($event)"
                (blur)="handleBlur('repoveSV')"
                [ngClass]="{
                  'is-invalid error':
                    (f.repoveSV?.invalid &&
                      (f.repoveSV?.dirty || f.repoveSV?.touched)) ||
                    isPriceInvalid
                }"
              />
              <div>
                <label for="helpInputTop">Sale Value</label>
                <small class="text-muted">
                  <i>{{ "iTem.Number" | translate }}</i></small
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="form-group row">
        <label class="col-sm-3 col-form-label"
          >{{ "General.Brand" | translate }}
          <span class="text-danger">*</span></label
        >
        <div class="col-sm-9">
          <div>
            <div class="skeleton-select" *ngIf="isLoadingBrand">
              <div class="spinner-border spinner-border-sm mr-50" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>

            <ng-select
              *ngIf="!isLoadingBrand"
              class="custom-select"
              [(ngModel)]="brandId"
              formControlName="brandId"
              placeholder="{{ 'General.All' | translate }}"
              [clearable]="false"
              #selectGroup
              [ngClass]="{
                'is-invalid error':
                  (isSubmit && f.brandId?.value == null) ||
                  (isSubmit && f.brandId?.invalid) ||
                  (f.brandId?.invalid &&
                    (f.brandId?.dirty || f.brandId?.touched)) ||
                  (f.brandId?.value == null &&
                    (f.brandId?.dirty || f.brandId?.touched))
              }"
            >
              <!-- <option value="All">{{ "General.All" | translate }}</option> -->
              <ng-option
                [value]="brand.id"
                *ngFor="let brand of BrandObjList"
                [selected]="brand.id == brandId"
              >
                {{ brand.name }}
              </ng-option>
            </ng-select>
            <div>
              <label for="helpInputTop">{{
                "General.Brand" | translate
              }}</label>
              <small
                style="color: rgb(180, 180, 180)"
                [ngClass]="{
                  'text-danger':
                    (isSubmit && f.brandId?.value == null) ||
                    (isSubmit && f.brandId?.invalid) ||
                    (f.brandId?.invalid &&
                      (f.brandId?.dirty || f.brandId?.touched)) ||
                    (f.brandId?.value == null &&
                      (f.brandId?.dirty || f.brandId?.touched))
                }"
              >
                <i>{{ "iTem.Validate" | translate }}</i></small
              >
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label"
          >{{ "General.Group" | translate }}
          <span class="text-danger">*</span></label
        >
        <div class="col-sm-9">
          <div>
            <div class="skeleton-select" *ngIf="isLoadingGroup">
              <div class="spinner-border spinner-border-sm mr-50" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>

            <ng-select
              *ngIf="!isLoadingGroup"
              class="custom-select"
              [(ngModel)]="groupId"
              formControlName="groupId"
              placeholder="{{ 'General.All' | translate }}"
              [clearable]="false"
              (change)="getCategoryData($event)"
              #selectGroup
              [ngClass]="{
                'is-invalid error':
                  (isSubmit && f.groupId?.value == null) ||
                  (isSubmit && f.groupId?.invalid) ||
                  (f.groupId?.invalid &&
                    (f.groupId?.dirty || f.groupId?.touched)) ||
                  (f.groupId?.value == null &&
                    (f.groupId?.dirty || f.groupId?.touched))
              }"
            >
              <!-- <option value="All">{{ "General.All" | translate }}</option> -->
              <ng-option
                [value]="group.id"
                *ngFor="let group of GroupObjList"
                [selected]="group.id == groupId"
              >
                {{ group.name }}
              </ng-option>
            </ng-select>
            <div>
              <label for="helpInputTop">{{ "iTem.Group" | translate }}</label>
              <small
                style="color: rgb(180, 180, 180)"
                [ngClass]="{
                  'text-danger':
                    (isSubmit && f.groupId?.value == null) ||
                    (isSubmit && f.groupId?.invalid) ||
                    (f.groupId?.invalid &&
                      (f.groupId?.dirty || f.groupId?.touched)) ||
                    (f.groupId?.value == null &&
                      (f.groupId?.dirty || f.groupId?.touched))
                }"
              >
                <i>{{ "iTem.Validate" | translate }}</i></small
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="invalid-feedback"
        [ngClass]="{
          'd-block': groupId
        }"
      >
        <div class="form-group row">
          <label class="col-sm-3 col-form-label"
            >{{ "General.Category" | translate }}
            <span class="text-danger">*</span></label
          >
          <div class="col-sm-9">
            <div>
              <div class="skeleton-select" *ngIf="isLoadingCategory">
                <div
                  class="spinner-border spinner-border-sm mr-50"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <ng-select
                *ngIf="!isLoadingCategory"
                class="custom-select"
                [(ngModel)]="categoryId"
                [ng-disabled]=""
                formControlName="categoryId"
                [disabled]="!groupId"
                [clearable]="false"
                placeholder="{{ 'General.All' | translate }}"
                (change)="getSubCategoryData($event)"
                [ngClass]="{
                  'is-invalid error':
                    (isSubmit && f.categoryId?.value == null) ||
                    (isSubmit && f.categoryId?.invalid) ||
                    (f.categoryId?.invalid &&
                      (f.categoryId?.dirty || f.categoryId?.touched)) ||
                    (f.categoryId?.value == null &&
                      (f.categoryId?.dirty || f.categoryId?.touched))
                }"
                #selectCategory
              >
                <ng-option
                  [value]="category.id"
                  *ngFor="let category of categoryList"
                  [selected]="category.id == categoryId"
                >
                  {{ category.name }}
                </ng-option>
              </ng-select>
              <div>
                <label for="helpInputTop">{{ "iTem.Cate" | translate }}</label>
                <small
                  style="color: rgb(180, 180, 180)"
                  [ngClass]="{
                    'text-danger':
                      (isSubmit && f.categoryId?.value == null) ||
                      (isSubmit && f.categoryId?.invalid) ||
                      (f.categoryId?.invalid &&
                        (f.categoryId?.dirty || f.categoryId?.touched)) ||
                      (f.categoryId?.value == null &&
                        (f.categoryId?.dirty || f.categoryId?.touched))
                  }"
                >
                  <i>{{ "iTem.Validate" | translate }}</i></small
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="invalid-feedback"
        [ngClass]="{
          'd-block': categoryId
        }"
      >
        <div class="form-group row">
          <label class="col-sm-3 col-form-label"
            >{{ "General.SubCategory" | translate }}
            <span class="text-danger">*</span></label
          >
          <div class="col-sm-9">
            <div>
              <div class="skeleton-select" *ngIf="isLoadingSubCategory">
                <div
                  class="spinner-border spinner-border-sm mr-50"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <ng-select
                *ngIf="!isLoadingSubCategory"
                class="custom-select"
                [(ngModel)]="subCategoryId"
                formControlName="subCategoryId"
                [clearable]="false"
                placeholder="{{ 'General.All' | translate }}"
                (change)="getClassData($event)"
                [ngClass]="{
                  'is-invalid error':
                    (isSubmit && f.subCategoryId?.value == '') ||
                    (isSubmit && f.subCategoryId?.invalid) ||
                    (f.subCategoryId?.invalid &&
                      (f.subCategoryId?.dirty || f.subCategoryId?.touched)) ||
                    (f.subCategoryId?.value == 'All' &&
                      (f.subCategoryId?.dirty || f.subCategoryId?.touched))
                }"
                #selectSubCategory
              >
                <ng-option
                  [value]="subCategory.id"
                  *ngFor="let subCategory of subCategoryList"
                  [selected]="subCategory.id == subCategoryId"
                >
                  {{ subCategory.name }}
                </ng-option>
              </ng-select>
              <div>
                <label for="helpInputTop">{{
                  "iTem.subCate" | translate
                }}</label>
                <small
                  style="color: rgb(180, 180, 180)"
                  [ngClass]="{
                    'text-danger':
                      (isSubmit && f.subCategoryId?.value == null) ||
                      (isSubmit && f.subCategoryId?.invalid) ||
                      (f.subCategoryId?.invalid &&
                        (f.subCategoryId?.dirty || f.subCategoryId?.touched)) ||
                      (f.subCategoryId?.value == null &&
                        (f.subCategoryId?.dirty || f.subCategoryId?.touched))
                  }"
                >
                  <i>{{ "iTem.Validate" | translate }}</i></small
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="invalid-feedback"
        [ngClass]="{
          'd-block': subCategoryId
        }"
      >
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">{{
            "General.Class" | translate
          }}</label>
          <div class="col-sm-9">
            <div class="input-group">
              <div class="skeleton-select" *ngIf="isLoadingClass">
                <div
                  class="spinner-border spinner-border-sm mr-50"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <ng-select
                *ngIf="!isLoadingClass"
                class="custom-select"
                [(ngModel)]="classId"
                formControlName="classId"
                [clearable]="false"
                placeholder="{{ 'General.All' | translate }}"
                (change)="getSubClassData($event)"
                #selectClass
              >
                <ng-option
                  [value]="class.id"
                  *ngFor="let class of classList"
                  [selected]="class.id == classId"
                >
                  {{ class.name }}
                </ng-option>
              </ng-select>
            </div>
          </div>
        </div>
      </div>

      <div
        class="invalid-feedback"
        [ngClass]="{
          'd-block': classId
        }"
      >
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">{{
            "General.SubClass" | translate
          }}</label>
          <div class="col-sm-9">
            <div class="input-group">
              <div class="skeleton-select" *ngIf="isLoadingSubClass">
                <div
                  class="spinner-border spinner-border-sm mr-50"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <ng-select
                *ngIf="!isLoadingSubClass"
                class="custom-select"
                [(ngModel)]="subClassId"
                formControlName="subClassId"
                [clearable]="false"
                placeholder="{{ 'General.All' | translate }}"
                #selectSubClass
                (change)="showValue($event)"
              >
                <option
                  [value]="subClass.id"
                  *ngFor="let subClass of subClassList"
                  [selected]="subClass.id == subClassId"
                >
                  {{ subClass.name }}
                </option>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-3 col-form-label"
          >{{ "Form.Images" | translate }}
          <span class="text-danger">*</span></label
        >
        <div class="col-sm-9">
          <!-- typeFiles  0 รับทุกไฟล์ 1 รับเฉพาะรูป 2 รับไฟล์ด็อค 3 ไฟล์บีบอัด 4 ไปกำหนดเอง 55555 -->
          <app-custom-dropzone
            #CustomDropzone
            [editing]="isEditing"
            [typeFiles]="FileTypeFilter.Images"
            [countMedia]="7"
            [media]="passData"
            (filesUploaded)="onFilesUploaded($event)"
          ></app-custom-dropzone>
        </div>
      </div>

      <!-- <hr /> -->
      <!-- <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-3 col-form-label"
          >{{ "Form.Attract" | translate }}
        </label>
        <div class="col-sm-9">
          <app-multi-file-upload></app-multi-file-upload>
        </div>
      </div> -->
      <br />
      <div class="auto-form-footer">
        <a class="btn btn-secondary" (click)="openCancelModal(itemObj)">
          {{ "Form.Cancel" | translate }}
        </a>

        <a class="btn btn-primary" (click)="openConfirmModal(itemObj)">
          {{ "Form.Submit" | translate }}
        </a>
      </div>
    </form>
  </div>
</div>

<!--
<form [formGroup]="registerForm">
  <input class="form-control" type="text" formControlName="title" />
  <input class="form-control" type="text" formControlName="desc" />

  <app-image-upload></app-image-upload>
  //in app-image-upload component have upload function need id of responce save this component
  // how to pass res.data.id to upload function in app-image-upload component
  <button (click)="save()"></button>
</form> -->
