import { Component, OnInit } from '@angular/core';
import { CompanyCardService } from '../company-card.service';
import { ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-company-card-create',
  templateUrl: './company-card-create.component.html',
  styleUrls: ['./company-card-create.component.scss']
})
export class CompanyCardCreateComponent implements OnInit {

  config: ICMSConfig;

  constructor(private _companyCard: CompanyCardService) {
    this.config = this._companyCard.config;
  }


  ngOnInit(): void {
  }

}
