<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div class="row">
      <div class="col-6 col-sm-4 col-md-3 col-lg-2" *ngFor="let link of Links">
        <div class="card">
          <a class="list-group-item border-0" [routerLink]="link.routerLink">{{
            link.label
          }}</a>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h6>CMS</h6>

        <!-- <h6>Stock</h6>
        <ul>
          <li><a class="list-group-item border-0" routerLink="/truncate/detail/banner">Banner</a></li>
        </ul>
        <h6>Purshesing</h6>
        <ul>
          <li><a class="list-group-item border-0" routerLink="/truncate/detail/banner">Banner</a></li>
        </ul> -->
        <h6>Etc.</h6>
        <ul>
          <li>
            <a
              class="list-group-item border-0"
              routerLink="/admin/trash/detail/user"
              >User</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
