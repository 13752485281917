import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import THBText from 'thai-baht-text';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { TimelineItem } from 'app/main/components/horizontal-timeline/horizontal-timeline.component';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { Quotation, TempRowQuotation } from 'app/main/model/Quotation';
import { ApiService } from 'app/main/service/api.service';
import { CalculateFuncService } from 'app/main/service/calculate-func.service';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { DocumentService } from '../../../document.service';
import { SaleOrder, SaleOrderItem } from 'app/main/model/SaleOrder';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Customer from 'app/main/model/Customer';
import { GlobalFuncService } from 'app/main/service/global-func.service';

@Component({
  selector: 'app-sale-order-export',
  templateUrl: './sale-order-export.component.html',
  styleUrls: ['./sale-order-export.component.scss'],
})
export class SaleOrderExportComponent implements OnInit {
  @BlockUI('section-block') sectionBlockUI: NgBlockUI;
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('tableElement')
  tableElement: ElementRef<HTMLElement>;

  @ViewChild('trClild')
  trClild: ElementRef<HTMLElement>;

  @ViewChild('mainElement')
  mainElement: ElementRef<HTMLElement>;

  @ViewChild('overElement')
  overElement: ElementRef<HTMLElement>;

  @ViewChildren('itemListElements')
  itemListElements: QueryList<ElementRef<HTMLElement>>;
  contentHeader: object;
  customerObj: Customer;
  passObj: object;

  currentUser: User;
  timelineData: TimelineItem[];
  Excess: Boolean = false;
  saleOrderObj: SaleOrder;
  customerAddressObj: CustomerAddress;
  itemList: any[];
  apiPath: string;
  editPathUrl: string;
  THBgrantotal: string;
  docID: string;
  isLoading: boolean;
  sumNetPrice: number;
  sumVatPrice: number;

  constructor(
    private cdr: ChangeDetectorRef,
    private _modalService: NgbModal,
    private _documentService: DocumentService,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _documentApiService: DocumentApiService,
    private _route: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private _apiService: ApiService,
    private _router: Router,
    private _calculateService: CalculateFuncService,
    private _globalFuncService: GlobalFuncService
  ) {
    this.apiPath = this._documentService.saleOrder.ApiPath;
  }

  ngOnInit(): void {
    var self = this;
    self.initData();
    this.checkTableHeight();
    this._authenticationService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    this.contentHeader = {
      headerTitle: 'Document.SaleOrder',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  SetLoadingState() {
    this.isLoading = true;
  }
  SetLoadedState() {
    this.isLoading = false;
  }

  initData(): void {
    this.SetLoadingState();
    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
    });
    console.log('Doc id : ' + this.docID);
    console.log(this.apiPath);

    this.getSaleOrderData();
  }
  getSaleOrderData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocData(this.apiPath, this.docID)
        .subscribe((res) => {
          self.saleOrderObj = res.data.resultData[0];
          console.log('Customer ID', self.saleOrderObj);
          this.THBgrantotal = THBText(this.saleOrderObj.grandTotal);
          self.getCustomer(self.saleOrderObj.customerId);
          self.getCustomerAddress(self.saleOrderObj.customerId);
          self.calculateSummary();
          self.SetLoadedState();
        });
    });
  }

  getCustomer(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService.GetDataById('customer', customerId).subscribe((res) => {
        self.customerObj = res.data.resultData[0];
        // self.getCustomerAddress(customerId);
      });
    });
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData.filter((it) => {
            return it['useForShipping'] == true;
          });

          this.passObj = {
            quotationObj: this.saleOrderObj,
            titleTh: 'ใบสั่งขาย',
            titleEn: 'Sale Order',
            customerObj: this.customerObj,
            customerAddressObj: this.customerAddressObj[0],
          };
          this.blockUI.stop();
          console.log(this.passObj);
        });
    });
  }

  ngAfterViewInit() {
    this.itemListElements.changes.subscribe((res) => {
      this.calculateSummary();
    });
  }

  calculateSummary() {
    this.itemList = [];
    const { reback } = this.checkTableHeight();

    const result: any[][] = [];
    let startIndex = 0;

    for (const index of reback) {
      if (index <= this.saleOrderObj.item.length) {
        const chunk = this.saleOrderObj.item.slice(startIndex, index);
        result.push(chunk);
        startIndex = index;
      }
    }
    if (startIndex < this.saleOrderObj.item.length) {
      const remainingChunk = this.saleOrderObj.item.slice(startIndex);
      result.push(remainingChunk);
    }

    result.forEach((subItemList) => {
      const tempRowList: TempRowQuotation[] = [];

      subItemList.forEach((element: SaleOrderItem) => {
        const tempRow = new TempRowQuotation();
        tempRow.rowType = 'item';
        tempRow.itemId = element.itemId;
        tempRow.itemCode = element.itemCode;
        tempRow.description = element.description;
        tempRow.discount = element.discount;
        tempRow.quantity = element.quantity;
        tempRow.unit = element.unit;
        tempRow.unitPrice = element.unitPrice;
        tempRow.whtRate = 0.0;
        tempRowList.push(tempRow);
      });

      this.itemList.push(tempRowList);
      console.log('rebackkkk', reback[reback.length - 1]);
      console.log('========0=======', this.saleOrderObj.item.length);
    });
    if (reback[reback.length - 1] == this.saleOrderObj.item.length) {
      this.itemList.push([]);
      console.log(this.itemList);
    }

    this.itemList.forEach((subItemList) => {
      subItemList.forEach((element) => {
        this.sumNetPrice += this._calculateService.calculateNet(
          [element],
          true,
          this.saleOrderObj.taxRate,
          this.saleOrderObj.discount
        );

        this.sumVatPrice += this._calculateService.calculateVat(
          this.sumNetPrice,
          this.saleOrderObj.taxRate
        );
      });
    });
  }

  checkTableHeight() {
    if (this.saleOrderObj) {
      const table = this.tableElement.nativeElement as HTMLElement;
      const mainHeight = this.mainElement.nativeElement.offsetHeight;
      const overHeight = 800;
      const trElements = Array.from(table.getElementsByTagName('tr'));
      let reback = [];
      let cumulativeHeight = 0;
      let excessTrCount = 0;
      let splitIndex = -1;
      let forTwoPage = 0;
      let lastIndexofExcess = [];
      for (let i = 0; i < trElements.length; i++) {
        const tr = trElements[i];
        if (tr.classList[0] == 'trc') {
          cumulativeHeight += tr.getBoundingClientRect().height;
        }
        if (cumulativeHeight > mainHeight - 65) {
          excessTrCount++;
          console.log('เข้าเคสมากกว่า mainHeight');
          console.log(cumulativeHeight);
          this.Excess = true;
          lastIndexofExcess.push(i);
          console.log(
            'lindex',
            lastIndexofExcess[lastIndexofExcess.length - 1]
          );
          forTwoPage++;
        } else {
        }
        if (cumulativeHeight >= overHeight) {
          forTwoPage = 0;
          console.log('เข้าเคส OverHeight');
          console.log('------------แบ่งหน้า-----------');
          // lastIndexofExcess = [];
          reback.push(i);
          excessTrCount++;

          cumulativeHeight = tr.getBoundingClientRect().height;
          if (splitIndex === -1) {
            splitIndex = i;
          }
        } else {
          console.log('lindex over', lastIndexofExcess);
          console.log('UUUUUU', i);
          console.log('YYYYYY', forTwoPage);
        }
        console.log('222222222', forTwoPage);

        console.log('จำนวนรอบ', excessTrCount);
      }

      if (excessTrCount >= 1) {
        if (forTwoPage == 1) {
          console.log(lastIndexofExcess);

          console.log('YESSSSS');

          reback.push(lastIndexofExcess[0] + 1);
        } else {
          console.log(lastIndexofExcess);
          if (!lastIndexofExcess[lastIndexofExcess.length - 1]) {
          } else {
          }
          console.log('NOOOOOOO');
        }
      }

      this.cdr.detectChanges();
      console.log('reback', reback);
      console.log('เกินหน้าไหม', this.Excess);

      return { reback };
    }
  }

  openCancelModal(): void {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

  // openModal(title: string, detail: string, IsConfirm: boolean): void {
  //     const modalRef = this._modalService.open(ModalComponent, {
  //       centered: true,
  //     });
  //     modalRef.componentInstance.title = title;
  //     modalRef.componentInstance.isConfirm = IsConfirm;
  //     modalRef.componentInstance.detail = detail;
  //     modalRef.componentInstance.callBackFunc.subscribe((res) => {
  //       this._router.navigate([`${this.pathUrl}`]);
  //   }
  // }

  splitArray(arr, chunkSize) {
    const splitItems = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      splitItems.push(chunk);
    }

    return splitItems;
  }

  convertNumbertoMoneyFormat(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }
}
