import { Component, OnInit } from '@angular/core';
import { FrontendUserService } from '../frontend-user.service';

@Component({
  selector: 'app-frontend-user-formpage',
  templateUrl: './frontend-user-formpage.component.html',
  styleUrls: ['./frontend-user-formpage.component.scss']
})
export class FrontendUserFormpageComponent implements OnInit {

  contentHeader: object;
  callbackUrl: string;
  apiPath: string;
  pathUrl: string;

  constructor(
    private frontendUserService: FrontendUserService
  ) {
    this.callbackUrl = this.frontendUserService.pathUrl;
    this.apiPath =this.frontendUserService.apiPath;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'BreadCrumbs.Manage.User',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

}
