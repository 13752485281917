<div class="card">
  <div class="card-body">
    <h3 class="mb-2">Warehouse</h3>

    <table class="table" [dtOptions]="dtOptions" datatable>
      <thead>
        <tr>
          <th>No</th>
          <th>WH</th>
          <th>Floor</th>
          <th>Room</th>
          <th>Space</th>
          <th>Lock</th>
          <th>Stock Value</th>
          <th>Balance</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        <tr class="w-100" *ngIf="isLoading">
          <td class="text-center" *ngFor="let num of [].constructor(9)">
            <div class="skeleton-loading"></div>
          </td>
        </tr>

        <tr *ngIf="wareHouseList.length < 1 && !isLoading">
          <td colspan="100%" class="text-center">
            <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
          </td>
        </tr>

        <tr *ngFor="let wareHouse of wareHouseList; let ind = index">
          <td class="text-center">
            {{ (page - 1) * pageSize + ind + 1 | number }}
          </td>
          <td>WH</td>
          <td>Floor</td>
          <td>Room</td>
          <td>Space</td>
          <td>Lock</td>
          <td>Stock Value</td>
          <td>Balance</td>
          <td>Type</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
