import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ItemComponent } from './item.component';
import { AuthGuard } from '../../../../auth/helpers';
import { ContentHeaderModule } from '../../../../layout/components/content-header/content-header.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSummernoteModule } from 'ngx-summernote';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ItemFormpageComponent } from './item-formpage/item-formpage.component';
import { ComponentsModule } from '../../../components/components.module';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';
import { environment } from 'environments/environment';


const routes: Routes =!environment.menuList.itemManage ? [] : [
  {
    path: 'item-manage/item',
    component: ItemComponent,
    canActivate: [AuthGuard],
    data: { animation: 'item-manage' },
  },
  {
    path: 'item-manage/item/create',
    component: ItemFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'item-create' },
  },
  {
    path: 'item-manage/item/edit/:itemID',
    component: ItemFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'item-edit' },
  },
]

@NgModule({
  declarations: [ItemComponent, ItemFormpageComponent],
  imports: [
    RouterModule.forChild(routes),
    ComponentsModule,
    ContentHeaderModule,
    CoreCommonModule,
    CommonModule,
    CorePipesModule,
    TranslateModule,
    NgxDropzoneModule,
    NgxSummernoteModule,
    FormsModule,
    ReactiveFormsModule,
    CoreTouchspinModule,
    NgbPaginationModule,
    NgbModule
  ]
})
export class ItemModule { }
