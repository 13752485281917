// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  // apiUrl: 'https://repove-api.appintouch.net/api',
  apiUrl: 'https://az2-api.appintouch.net/api',
  appName: 'App Intouch Standard CMS',
  appTitle: 'App Intouch Standard CMS',
  appLogoImage: 'assets/images/logo/logo.png',
  appLogoNav: 'assets/images/logo/logo.png',
  defaultIMG: 'assets/images/general/no-image.png',

  // language list for content
  langDefault: 'en',
  langContent: ['en', 'th'],

  //transaction
  transaction: {
    logoOnPaperPath: '../assets/images/logo/logo.png',
    logoOnFooterLeftPaperPath:
      '../assets/images/logo/Logo-Coceuco-TH-compressed.png',
    logoOnFooterRightPaperPath: '../assets/images/logo/repove-blue.png',
    showLogoFooter: false,
    isUseWht: false,
    isSplitIVfromSO: true,
    isSplitGRfromPO: true,
    isJoinIV_TX_RE: false,
    isQuantityDecimal: true,

    shippingFee: 150,
  },

  stock: {
    isQuantityDecimal: true,
  },

  //customer
  customer: {
    requiredDetail: false,
    requiredCentralContact: false,
  },

  limitFileSize: {
    maxImageSizeInBytes: 300 * 1024, //300KB
    maxVideoSizeInBytes: 2 * 1024 * 1024, //2MB
    maxFileSizeInBytes: 2 * 1024 * 1024, //2MB
  },

  menuList: {
    itemManage: true,
    cms: true,
    customer: true,
    transactionDoc: true,
    stock: true,
    mlm: true,
    codeManage: true,
    returnProduct: true,
    notification: true,
    bookingCalendar: true,
  },

  systemSettings: {
    useCRUDMasterAddr: true,
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
