class Home {
  id: any;
  bannerIMG?: any;
  bannerVDO?: any;
  isBannerVDO: boolean;
  whoWeAreQuote: string;
  whoWeAreDescription: string;
  whoWeArePic1: any;
  whoWeArePic2: any;
  whoWeArePic3: any;

  ourTechQuote: string;
  ourTechVDO: any;

  memberPromotionPic1: any;
  memberPromotionPic2: any;

  isActive: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
}

export default Home;
