import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../../../auth/service';
import { ComponentsService } from '../../../../components/components.service';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { ApiService } from '../../../../service/api.service';
import { CalculateFuncService } from '../../../../service/calculate-func.service';
import { DocumentApiService } from '../../../../service/document-api.service';
import { DocumentService } from '../../document.service';
import { User } from '../../../../../auth/models';
import { TimelineItem } from '../../../../components/horizontal-timeline/horizontal-timeline.component';
import { SaleOrder } from '../../../../model/SaleOrder';
import CustomerAddress from '../../../../model/CustomerAddress';
import { TempRowQuotation } from '../../../../model/Quotation';
import { GlobalFuncService } from '../../../../service/global-func.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-receipt-detail',
  templateUrl: './receipt-detail.component.html',
  styleUrls: ['./receipt-detail.component.scss'],
})
export class ReceiptDetailComponent implements OnInit, AfterViewInit {
  @Input() titleDoc: string;
  @ViewChild('confirmModal') confirmModal: ModalComponent;
  contentHeader: object;
  typeDocName: string;

  currentUser: User;
  timelineData: TimelineItem[];

  saleOrderObj: any;
  customerAddressObj: CustomerAddress;

  orderId: string;
  orderStatus: any;
  currentStep: number;

  apiPath: string;
  editPathUrl: string;

  invoiceID: string;
  docID: string;
  docStatus: number;
  isLoading: boolean;
  isIncludeQo: boolean = false;
  sumNetPrice: number;
  sumVatPrice: number;
  refDetailUrl: string;

  receipt: string = 'receipt';

  detailPathUrl: string

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private _documentService: DocumentService,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _documentApiService: DocumentApiService,
    public _route: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private _apiService: ApiService,
    private _calculateService: CalculateFuncService,
    private _modalService: NgbModal,
    private _componentService: ComponentsService,
    private _router: Router,
    private _globalFuncService: GlobalFuncService
  ) {
    this.timelineData = this._documentService.timelineData;
    this.apiPath = this._documentService.receipt.ApiPath;
    this.editPathUrl = this._documentService.receipt.EditPathURL;
    this.detailPathUrl = this._documentService.receipt.DetailPathURL
    this.refDetailUrl = this._documentService.invoice.DetailPathURL;

    this.initData();
  }

  toggleIncludeQo(): void {
    this.isIncludeQo = !this.isIncludeQo;
  }

  SetLoadingState() {
    this.isLoading = true;
  }
  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;

    this._authenticationService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    this.contentHeader = {
      headerTitle: 'Document.Receipt',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Document',
            isLink: true,
            link: '/',
          },
          {
            name: 'Document.Receipt',
            isLink: true,
            link: '/document/receive/view',
          },
          {
            name: 'FormName.ReceiptDetail',
            isLink: false,
            link: '/document/receive/view',
          },
        ],
      },
    };
  }

  activeTab: string = 'detail';
  @ViewChild('navFilled') nav: NgbNav;
  public active_fragment: BehaviorSubject<string> = new BehaviorSubject('');
  
  ngAfterViewInit(): void {
    this._route.fragment.subscribe((frag) => {
      this.active_fragment.next(frag);
      
      console.log(this.active_fragment.value)
      if (!this.active_fragment.value) {
        this.nav.select('detail')
        this.activeTab = 'detail'
        console.log('select default page')
      } else {
        this.activeTab = this.active_fragment.value
      }
      
    });
  }

  initData(): void {
    this.SetLoadingState();
    this.blockUI.start();
    this.typeDocName = 'receiptCode';
    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
    });
    console.log('Doc id : ' + this.docID);
    console.log(this.apiPath);

    this.getSaleOrderData();
  }

  refreshData() {
    this.initData();
  }

  getSaleOrderData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocData(this.apiPath, this.docID)
        .subscribe((res) => {
          self.saleOrderObj = res.data.resultData[0];

          this.invoiceID = self.saleOrderObj.invoiceId;
          console.log('saleOrderObj invoiceID: ', this.invoiceID);

          self.getCustomerAddress(self.saleOrderObj.customerId);
          self.calculateSummary();
          self.SetLoadedState();

          this.docStatus = self.saleOrderObj.transactionStatusId;
          console.log('Current Status: ', this.docStatus);

          this.orderId = self.saleOrderObj.orderId;

          this.getOrderStatus(this.orderId).subscribe((orderStatusRes) => {
            this.orderStatus = orderStatusRes.data.resultData;

            console.log('Order Status: ', this.orderStatus[0]);

            for (let orderStatus of this.orderStatus) {
              for (let invoice of orderStatus.invoice) {
                if (invoice.id === this.invoiceID) {
                  this.currentStep = invoice.transactionStatusId;
                  break;
                }
              }
              if (this.currentStep !== undefined) {
                break;
              }
            }

            if (this.currentStep === undefined) {
              console.error('No matching invoice found');
            }

            this.blockUI.stop();

            // this.currentStep = this._globalFuncService.GetCurrentStep(this.orderStatus);
            console.log('Current Step: ', this.currentStep);
          });
        });
    });
  }

  getOrderStatus(orderId: string): Observable<any> {
    return this._apiService.GetDataById('Order/State', orderId);
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData.find((it) => {
            return it['useForShipping'] == true;
          });
        });
    });
  }

  calculateSummary() {
    let itemList = [];

    this.saleOrderObj.item.forEach((element, index) => {
      let tempRow: TempRowQuotation = new TempRowQuotation();
      tempRow.rowType = 'item';
      tempRow.itemId = element.itemId;
      tempRow.description = element.description[0].description;
      tempRow.discount = element.discount;
      tempRow.quantity = element.quantity;
      tempRow.unit = element.unit;
      tempRow.unitPrice = element.unitPrice;
      tempRow.whtRate = 0.0;

      itemList.push(tempRow);
    });

    this.sumNetPrice = this._calculateService.calculateNet(
      itemList,
      true,
      this.saleOrderObj.taxRate,
      this.saleOrderObj.discount
    );

    this.sumVatPrice = this._calculateService.calculateVat(
      this.sumNetPrice,
      this.saleOrderObj.taxRate
    );
  }

  convertNumbertoMoneyFormat(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }

  approveSaleOrder(): Promise<any> {
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .ApproveDocData(this.apiPath, this.docID, {
          isTaxInvoice: this.isIncludeQo,
        })
        .subscribe(
          (res) => {
            let title = this._translateService.instant('Alert.Approved');
            this._componentService.SuccessSwal(title);
          },
          (error) => {
            let title = this._translateService.instant('Alert.ApproveFail');
            this._componentService.ErrorSwal(title, error.message);
          }
        );
    });
  }

  openApproveModal(): void {
    let title = 'Approve to Invoice';
    let detail = 'คุณแน่ใจที่จะยอมรับใบเสนอราคานี้หรือไม่?';
    this.openModal(title, detail);
  }
  openModal(title: string, detail: string): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.approveSaleOrder();
    });
  }
}
