export enum LogEvent {
    Create = 'Create',
    Paid = 'Paid',
    View = 'Viewed',
    Update = 'Update',
    Accept = 'Accepted',
    Void = 'Void',
    Cancel = 'Cancel',
    IssueTaxInvoice = 'IssueTaxInvoice',
    GotReceipt = 'Got Receipt',
    VoidGotReceipt = 'Void Got Receipt',
    RegisterTaxInvoice = 'Register TaxInvoice',
    VoidTaxInvoice = 'Void TaxInvoice',
  }
