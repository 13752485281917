<div>
  <div class="d-flex">
    <div class="w-100">
      <div>
        <span> {{ "Document.Tax" | translate }} </span>
      </div>
      <div style="flex-shrink: 0">
        <span *ngIf="TaxIv" class="text-success">
          1{{ "ActionHistory.Attach" | translate }}
        </span>
        <span *ngIf="!TaxIv || removedTax" class="text-success"> - </span>
      </div>
    </div>
    <button
      (click)="openEdit = true"
      *ngIf="!openEdit"
      type="button"
      class="btn btn-sm btn-icon"
      rippleEffect
    >
      <span [data-feather]="'edit-2'"></span>
    </button>
    <button
      (click)="openEdit = false; conFirDelete = false"
      *ngIf="openEdit"
      type="button"
      class="btn btn-sm btn-icon"
      rippleEffect
    >
      <span [data-feather]="'x'"></span>
    </button>
  </div>

  <div *ngIf="openEdit">
    <hr />

    <ng-container *ngIf="TaxIv">
      <p>{{ "ActionHistory.No" | translate }} {{ TaxIv }}</p>

      <button
        (click)="conFirDelete = true"
        type="button"
        *ngIf="!conFirDelete"
        class="btn btn-sm btn-outline-primary w-100"
        rippleEffect
      >
        <span [data-feather]="'trash-2'" [class]="'mr-25'"></span
        >{{ "ActionHistory.Void" | translate }}
      </button>

      <div class="d-flex" *ngIf="conFirDelete">
        <button
          type="button"
          class="btn btn-sm btn-outline-success w-100 mr-50"
          rippleEffect
          (click)="VoidTaxInvoice()"
        >
          <span [data-feather]="'check'" [class]="'mr-25'"></span>
          {{ "ActionHistory.Accept" | translate }}
        </button>
        <button
          (click)="conFirDelete = false"
          type="button"
          class="btn btn-sm btn-outline-primary w-100"
          rippleEffect
        >
          <span [data-feather]="'x'" [class]="'mr-25'"></span>
          {{ "ActionHistory.Cancel" | translate }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="!TaxIv">
      <div *ngIf="addTaxInvoice || !TaxIv">
        <form [formGroup]="addTaxForm">
          <ng2-flatpickr
            class="date-form-control"
            [setDate]="createDateOptions.defaultDate"
            [config]="createDateOptions"
            formControlName="taxInvoiceDate"
            #createDate
          ></ng2-flatpickr>

          <input
            type="text"
            class="form-control mt-1"
            formControlName="taxInvoiceNo"
            placeholder="{{ 'ActionHistory.TaxInvoice' | translate }}"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.taxInvoiceNo?.invalid) ||
                f.taxInvoiceNo.errors?.maxlength
            }"
          />

          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                f.taxInvoiceNo.invalid &&
                (f.taxInvoiceNo.dirty || f.taxInvoiceNo.touched)
            }"
          >
            <div>
              <div *ngIf="f.taxInvoiceNo.errors?.required">
                {{ "Handle.Name" | translate }}
              </div>
              <div *ngIf="f.taxInvoiceNo.errors?.maxlength">
                {{ "Handle.Exceed" | translate }}
                50
                {{ "Handle.Char" | translate }}
              </div>
            </div>
          </div>

          <button
            type="button"
            class="btn btn-sm btn-outline-success mt-1 w-100"
            rippleEffect
            (click)="submit()"
            [disabled]="f.taxInvoiceNo.errors?.maxlength"
          >
            <span [data-feather]="'check'" [class]="'mr-25'"></span>Save
          </button>
        </form>
      </div>
    </ng-container>
  </div>
</div>
