class Content {
  id?: any
  name: string
  title: string
  subTitle: string
  detail: any
  dateFrom: any
  dateTo: any
  contentDate: Date
  contentCategoryId?: number
  linkBtnCaption: string
  linkBtnUrl: string
  mediaFiles: string[]
  viewCount: number
  roleLevel: any
  isActive: boolean
  createBy: string
  createDate: Date
  updateBy: string
  updateDate: Date
}

export default Content;
