import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoFormComponent } from './auto-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../../auth/helpers';
import { ComponentsModule } from '../../../components/components.module';
import { ContentHeaderModule } from '../../../../layout/components/content-header/content-header.module';
import { AutoFormDetailComponent } from './auto-form-detail/auto-form-detail.component';
import { CoreCommonModule } from '@core/common.module';
import { ContentDocumentComponent } from 'app/main/components/content-document/content-document.component';
import { ContentDetailQuotationComponent } from './content-detail-quotation/content-detail-quotation.component';
import { ModalPaymentComponent } from 'app/main/components/modal-payment/modal-payment.component';
import { ActionHistoryComponent } from 'app/main/components/document/action-history/action-history.component';
import { PaymentDetailComponent } from 'app/main/components/payment-detail/payment-detail.component';
import { environment } from 'environments/environment';


const routes: Routes = !environment.menuList.itemManage ? [] : [
  {
    path: 'auto-form',
    component: AutoFormComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auto-form-create' },
  },
  {
    path: 'auto-form/create/:apiPath',
    component: AutoFormDetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auto-form-create' },
  },
  {
    path: 'auto-form/edit/:apiPath/:id',
    component: AutoFormDetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auto-form-edit' },
  },
  {
    path: 'document/detail/:type/:id',
    component: ContentDetailQuotationComponent,
    canActivate: [AuthGuard],
    data: { animation: 'view-content-document' }
  },
  {
    path: 'history/detail',
    component: ActionHistoryComponent,
    canActivate: [AuthGuard],
    data: { animation: 'view-content-document' }
  },
  {
    path: 'document/test',
    component: PaymentDetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'view-content-document' }
  }
  
];

@NgModule({
  declarations: [AutoFormComponent, AutoFormDetailComponent, ContentDetailQuotationComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreCommonModule,
    TranslateModule,
    ComponentsModule,
    ContentHeaderModule,
  ],
  exports: [AutoFormComponent, AutoFormDetailComponent],
})
export class AutoFormModule {}
