<form [formGroup]="payForm">
  <div class="modal-header" style="background-color: white">
    <div class="d-flex justify-content-between w-100 mt-2">
      <div>
        <h2 class="my-auto" *ngIf="isGoodsReceive">
          {{ "Payment.ReceivePaymentRecord" | translate }} #{{
            objData ? objData[docCodeColumn] : "-"
          }}
        </h2>
        <h2 class="my-auto" *ngIf="!isGoodsReceive">
          {{ "Payment.PaymentRecord" | translate }} #{{
            objData ? objData[docCodeColumn] : "-"
          }}
        </h2>
        <div class="d-flex justify-content-between">
          {{ "Payment.TheTotalAmountOfReceivableDebt" | translate }}
          {{
            convertNumbertoMoneyFormat(
              netData ? netData.preWHTAmountTotal : "-"
            )
          }}
          {{ "Payment.Baht" | translate }}
        </div>
      </div>
      <div class="d-flex">
        <ng2-flatpickr
          *ngIf="isMaxTodayDate"
          [config]="maxTodayDateOption"
          [setDate]="maxTodayDateOption.defaultDate"
          formControlName="paymentDate"
          (change)="onDateChange($event)"
        ></ng2-flatpickr>
        <ng2-flatpickr
          *ngIf="!isMaxTodayDate"
          [config]="DefaultDateOption"
          [setDate]="DefaultDateOption.defaultDate"
          formControlName="paymentDate"
          (change)="onDateChange($event)"
        ></ng2-flatpickr>
      </div>
    </div>

    <button
      type="button"
      class="close"
      style="top: 25px; width: 80px"
      (click)="closeModal()"
      aria-label="Close"
    >
      <span aria-hidden="true">
        <i data-feather="x" size="22"></i>
      </span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <!-- <div class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        id="isCheckPayment"
        [checked]="isCheckPayment"
        (change)="togglePayment()"
      />
      <label class="custom-control-label" for="isCheckPayment"
        >ช่องทางการรับชำระเงิน</label
      >
    </div> -->
    <div class="row" *ngIf="isCheckPayment">
      <div class="col-5 mt-1">
        <label *ngIf="isGoodsReceive">
          {{ "Payment.PaymentRecieveMethod" | translate }}
        </label>
        <label *ngIf="!isGoodsReceive">
          {{ "Payment.PaymentMethod" | translate }}
        </label>
        <ng-select
          [items]="paymentList"
          bindLabel="name"
          bindValue="id"
          formControlName="paymentTypeId"
          [clearable]="false"
          [searchable]="false"
          [markFirst]="false"
          [(ngModel)]="selectedPayment"
          [loading]="loadingPaymentList"
          (change)="onPaymentMethodChange()"
          [ngClass]="{
            'is-invalid error':
              payForm.get('paymentTypeId').invalid &&
              (payForm.get('paymentTypeId').dirty ||
                payForm.get('paymentTypeId').touched)
          }"
        >
          <ng-option *ngFor="let payment of paymentList" [value]="payment.id">
            {{ payment.name }}
          </ng-option>
        </ng-select>
        <div
          *ngIf="
            payForm.get('paymentTypeId').invalid &&
            (payForm.get('paymentTypeId').dirty ||
              payForm.get('paymentTypeId').touched)
          "
          class="text-danger"
        >
          {{ "Payment.Selectmethod" | translate }}
        </div>
      </div>

      <div class="col-3 mt-1" [ngClass]="{ ' d-none': !isGoodsReceive }">
        <label>{{ "Payment.PaymentReceiveAmount" | translate }}</label>
        <div class="d-flex justify-content-end">
          <input
            type="text"
            class="input-number form-control text-end"
            (focus)="removeComma($event)"
            [value]="
              convertNumbertoMoneyFormat(
                netData ? netData.preWHTAmountTotal : 0.0
              )
            "
            (change)="calculateBalance()"
            (blur)="onFormatAmountBlur()"
            id="payAmount"
            formControlName="payAmount"
            [readonly]="isJoinIV_TX_RE || notSplitPay"
          />
        </div>
      </div>

      <div class="col-4 mt-1">
        <label>{{ "Payment.Remark" | translate }}</label>
        <div class="d-flex justify-content-end">
          <input
            type="text"
            class="form-control"
            id="remarks"
            formControlName="remarks"
            maxlength="100"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="modal-body">
    <div
      class="col-12 d-flex justify-content-between align-items-start w-100 mb-1 moty"
    >
      <div class="w-100">
        <!-- <div class="d-flex justify-content-end subtotal-row">
          <p class="my-auto" *ngIf="isGoodsReceive">
            {{ "Payment.MoneyPaymentRecieve" | translate }}
          </p>
          <p class="my-auto" *ngIf="!isGoodsReceive">
            {{ "Payment.MoneyPayment" | translate }}
          </p>
          <p class="ml-1 my-auto">
            {{
              convertNumbertoMoneyFormat(this.payAmount ? this.payAmount : 0.0)
            }}
          </p>
          <p class="ml-2 my-auto">{{ "Payment.Baht" | translate }}</p>
        </div> -->
        <div class="row mr-4">
          <div class="col-8" *ngIf="!isTransfer">&nbsp;</div>
          <div
            class="col-8 px-0"
            *ngIf="isTransfer"
            style="margin-bottom: 0.5rem"
          >
            <label>{{ "Payment.Modal.BankAccount.Label" | translate }}</label>
            <ng-container>
              <ng-select
                bindLabel="name"
                bindValue="id"
                [items]="bankAccountList"
                formControlName="bankAccountId"
                [clearable]="false"
                [searchable]="false"
                [markFirst]="false"
                placeholder="{{
                  'Payment.Modal.BankAccount.Placeholder' | translate
                }}"
                [loading]="isLoadingBankAccount"
                [(ngModel)]="selectedBankAccount"
                (change)="onPaymentCanCreateChange($event)"
                [ngClass]="{
                  'is-invalid error':
                    (isSubmit && isTransfer && f.bankAccountId?.invalid) ||
                    (f.bankAccountId?.invalid &&
                      (f.bankAccountId?.dirty || f.bankAccountId?.touched))
                }"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="custom-option-body">
                    <div
                      class="d-flex justify-content-between align-items-start"
                    >
                      <p class="custom-option-title custom-option-text">
                        {{ item.accountName }}, บัญชีเลขที่ :
                        {{ item.accountNumber }}
                      </p>
                      <div class="badge badge-secondary" *ngIf="item.isUseForDocument">
                        {{ 'General.Default' | translate }}
                      </div>
                    </div>
                    <p class="card-text custom-option-text">
                      {{ item.bankName }}, {{ item.bankAccountTypeName }}
                    </p>
                  </div>
                </ng-template>
                <ng-template ng-footer-tmp>
                  <button
                    class="btn btn-primary"
                    (click)="onPaymentCanCreateChange('addBankAccount')"
                    style="float: right; margin-bottom: 5px"
                  >
                    <i data-feather="plus-square"></i>&nbsp;{{
                      "Payment.Modal.BankAccount.AddBankAccount" | translate
                    }}
                  </button>
                </ng-template>

                <ng-template ng-label-tmp let-item="item">
                  {{ item.name }}
                </ng-template>
              </ng-select>
            </ng-container>
            <div class="invalid-feedback">
              <div
                *ngIf="
                  (isSubmit && isTransfer && f.bankAccountId?.invalid) ||
                  (f.bankAccountId?.invalid &&
                    (f.bankAccountId?.dirty || f.bankAccountId?.touched))
                "
              >
                {{ "Payment.Modal.BankAccount.Bank.Error" | translate }}
              </div>
            </div>
          </div>
          <div class="col-4">
            <div>
              <div class="grandTotal-box align-items-center mb-0">
                <p
                  class="mb-0 mr-1 text-white text-left"
                  *ngIf="isGoodsReceive"
                >
                  {{ "Payment.TotalPaymentRecieve" | translate }}
                </p>
                <p
                  class="mb-0 mr-1 text-white text-left"
                  *ngIf="!isGoodsReceive"
                >
                  {{ "Payment.TotalPayment" | translate }}
                </p>
                <p class="grandTotal-price mb-0 align-items-center">
                  {{ convertNumbertoMoneyFormat(getSumTotal()) }}
                </p>
                <p class="mb-0 ml-1">{{ "Payment.Baht" | translate }}</p>
              </div>

              <div>
                <div
                  class="d-flex justify-content-end subtotal-row"
                  style="margin-right: -72px"
                >
                  <p class="my-auto">
                    {{ "Payment.RemainingAmount" | translate }}
                  </p>
                  <p class="ml-1 my-auto">
                    {{ convertNumbertoMoneyFormat(balance ? balance : 0.0) }}
                  </p>
                  <p class="ml-2 my-auto">{{ "Payment.Baht" | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row mr-1" *ngIf="isCreditDebitCard">
          <div class="col-12" style="margin-bottom: 0.5rem">
            <label>เลขที่บัตร</label>
            <div class="d-flex justify-content-start">
              <input
                type="number"
                class="form-control"
                id="cardNumber"
                formControlName="cardNumber"
                maxlength="20"
              />
            </div>
          </div>
          <div class="col-12" style="margin-bottom: 0.5rem">
            <label>ชื่อบนบัตร</label>
            <div class="d-flex justify-content-start">
              <input
                type="text"
                class="form-control"
                id="NameOnCard"
                formControlName="NameOnCard"
                maxlength="100"
              />
            </div>
          </div>

          <div class="col-6" style="margin-bottom: 0.5rem">
            <label>วันหมดอายุ</label>
            <div class="d-flex justify-content-start">
              <input
                type="number"
                class="form-control"
                id="bankNumber"
                formControlName="bankNumber"
                maxlength="4"
              />
            </div>
          </div>
          <div class="col-6" style="margin-bottom: 0.5rem">
            <label>CVV</label>
            <div class="d-flex justify-content-start">
              <input
                type="number"
                class="form-control"
                id="CVV"
                formControlName="CVV"
                maxlength="3"
              />
            </div>
          </div>
        </div>
        <div class="row mr-1" [style.display]="isCheque ? 'flex' : 'none'">
          <div class="col-12" style="margin-bottom: 0.5rem">
            <label>ธนาคาร</label>
            <div class="d-flex justify-content-start">
              <input
                type="text"
                class="form-control"
                id="bankName"
                formControlName="bankName"
                maxlength="100"
              />
            </div>
          </div>
          <div class="col-12" style="margin-bottom: 0.5rem">
            <label>วันที่หน้าเช็ค</label>
            <div class="d-flex justify-content-start">
              <ng2-flatpickr
                class="date-form-control"
                style="width: 452px"
                [config]="DefaultDateOption"
                [setDate]="DefaultDateOption.defaultDate"
                formControlName="chequeDate"
                placeholder="dd/mm/yyyy"
              ></ng2-flatpickr>
            </div>
          </div>
          <div class="col-12" style="margin-bottom: 0.5rem">
            <label>เลขที่เช็ค</label>
            <div class="d-flex justify-content-start">
              <input
                type="number"
                class="form-control"
                id="chequeNumber"
                formControlName="chequeNumber"
                maxlength="20"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="divider divider-left divider-primary">
              <div class="divider-text text-primary">ข้อมูลการฝากเช็ค</div>
            </div>
          </div>
          <div class="col-12" style="margin-bottom: 0.5rem">
            <label>วันที่ฝากเช็ค</label>
            <div class="d-flex justify-content-start">
              <ng2-flatpickr
                class="date-form-control"
                style="width: 452px"
                [config]="chequeDateOption"
                formControlName="chequeDepositDate"
                placeholder="dd/mm/yyyy"
              ></ng2-flatpickr>
            </div>
          </div>
          <div class="col-12" style="margin-bottom: 0.5rem">
            <label>บัญชีที่ฝากเช็ค</label>
            <div class="d-flex justify-content-start">
              <input
                type="text"
                class="form-control"
                id="chequeBankName"
                formControlName="chequeBankName"
                maxlength="100"
              />
            </div>
          </div>
          <div class="col-12" style="margin-bottom: 0.5rem">
            <label>วันที่เงินเข้าบัญชี</label>
            <div class="d-flex justify-content-start">
              <ng2-flatpickr
                class="date-form-control"
                style="width: 452px"
                [config]="chequeDateOption"
                formControlName="chequeEntryDate"
                placeholder="dd/mm/yyyy"
              ></ng2-flatpickr>
            </div>
          </div>
        </div>
        <div class="row mr-1" *ngIf="isOther">
          <div class="col-12" style="margin-bottom: 0.5rem">
            <label>อื่นๆ (รายละเอียด)</label>
            <div class="d-flex justify-content-start">
              <textarea
                class="form-control"
                style="width: 300px"
                rows="5"
                id="otherRemark"
                formControlName="otherRemark"
              ></textarea>
            </div>
          </div>
        </div> -->

        <div
          class="justify-content-end subtotal-row ml-1"
          [style.display]="isUseWHT ? 'flex' : 'none'"
        >
          <p class="my-auto">{{ "Payment.TaxWithheld" | translate }}:</p>
          <input
            type="text"
            class="input-number form-control text-end ml-1 my-auto"
            (focus)="removeComma($event)"
            [value]="
              convertNumbertoMoneyFormat(netData ? netData.salesWHT : 0.0)
            "
            (change)="calculateBalance()"
            (blur)="onFormatAmountBlur()"
            id="salesWHT"
            formControlName="salesWHT"
          />
          <p class="ml-2 my-auto">{{ "Payment.Baht" | translate }}</p>
        </div>
      </div>

      <!-- <div>
        <div class="grandTotal-box align-items-center mb-0">
          <p class="mb-0 mr-1 text-white text-left" *ngIf="isGoodsReceive">
            {{ "Payment.TotalPaymentRecieve" | translate }}
          </p>
          <p class="mb-0 mr-1 text-white text-left" *ngIf="!isGoodsReceive">
            {{ "Payment.TotalPayment" | translate }}
          </p>
          <p class="grandTotal-price mb-0 align-items-center">
            {{ convertNumbertoMoneyFormat(getSumTotal()) }}
          </p>
          <p class="mb-0 ml-1">{{ "Payment.Baht" | translate }}</p>
        </div>

        <div>
          <div class="d-flex justify-content-end subtotal-row">
            <p class="my-auto">{{ "Payment.RemainingAmount" | translate }}</p>
            <p class="ml-1 my-auto">
              {{ convertNumbertoMoneyFormat(balance ? balance : 0.0) }}
            </p>
            <p class="ml-2 my-auto">{{ "Payment.Baht" | translate }}</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>

  <div class="modal-footer justify-content-between" style="background: #f0f0f0">
    <button
      type="button"
      class="btn btn-md btn-secondary m-0"
      (click)="closeModal()"
      rippleEffect
    >
      {{ "Form.Cancel" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-md btn-primary m-0"
      (click)="callBack()"
      rippleEffect
      [disabled]="!isValidValue || loadingPaymentList"
    >
      {{
        isConfirm ? ("Form.Submit" | translate) : ("Form.Confirm" | translate)
      }}
    </button>
  </div>
</form>
