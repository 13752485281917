<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
      
      <div class="card">
        <div class="card-body">
          <app-promotion-form></app-promotion-form>
        </div>
      </div>
    </div>
  </div>