import {
  getTimeZone,
  getTimezoneOffset,
} from '@amcharts/amcharts5/.internal/core/util/Utils';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { Token } from 'app/auth/models/token';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiBaseUrl: string = environment.apiUrl;
  private apiPath: string;
  private httpHeaders: HttpHeaders;

  constructor(
    private http: HttpClient,
  ) {
  }

  get API_URL() {
    return this.apiBaseUrl;
  }

  get API_HEADERS() {
    return this.httpHeaders;
  }

  // {
  //   headers: this.httpHeaders,
  // }

  SetHttpHeaders(token: Token): void {
    let currentDate: Date = new Date();

    this.httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token.accessToken}`)
      .set('X-Timezone', `${Intl.DateTimeFormat().resolvedOptions().timeZone}`);
  }

  GetAllData(
    apiPath: string,
    params?: { [key: string]: any }
  ): Observable<any> {
    if (params) {
      let httpParams = new HttpParams();

      // Loop through the params object and add each key-value pair to the HttpParams
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.set(key, params[key]);
      });

      return this.http.get(`${this.apiBaseUrl}/${apiPath}`, {
        headers: this.httpHeaders,
        params: params,
      });
    }

    return this.http.get(`${this.apiBaseUrl}/${apiPath}`, {
      headers: this.httpHeaders,
    });
  }

  GetDataById(
    apiPath: string,
    id: string,
    params?: { [key: string]: any }
  ): Observable<any> {
    if (params) {
      let httpParams = new HttpParams();

      // Loop through the params object and add each key-value pair to the HttpParams
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.set(key, params[key]);
      });

      return this.http.get(`${this.apiBaseUrl}/${apiPath}/${id}`, {
        headers: this.httpHeaders,
        params: params,
      });
    }

    return this.http.get(`${this.apiBaseUrl}/${apiPath}/${id}`, {
      headers: this.httpHeaders,
    });
  }

  AddData(apiPath: string, body: any): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${apiPath}`, body, {
      headers: this.httpHeaders,
    });
  }

  UpdateWithOutId(apiPath: string, body: any): Observable<any> {
    return this.http.put(`${this.apiBaseUrl}/${apiPath}/`, body, {
      headers: this.httpHeaders,
    });
  }
  UpdateSV(apiPath: string, body: any): Observable<any> {
    return this.http.put(`${this.apiBaseUrl}/${apiPath}`, body, {
      headers: this.httpHeaders,
    });
  }

  UpdateDataById(apiPath: string, id: string, body: any): Observable<any> {
    return this.http.put(`${this.apiBaseUrl}/${apiPath}/${id}`, body, {
      headers: this.httpHeaders,
    });
  }

  DeleleDataById(apiPath: string, id: string): Observable<any> {
    return this.http.delete(`${this.apiBaseUrl}/${apiPath}/${id}`, {
      headers: this.httpHeaders,
    });
  }

  SetIsActiveData(
    apiPath: string,
    id: string,
    active: boolean
  ): Observable<any> {
    if (isNullOrUndefined(active)) {
      active = false;
    }

    return this.http.patch(
      `${this.apiBaseUrl}/${apiPath}/${id}/active/${active}`,
      { headers: this.httpHeaders }
    );
  }
  SetBookingEnumState(apiPath: string, id: string, body: any): Observable<any> {
    if (isNullOrUndefined(id)) {
      id = null;
    }

    return this.http.patch(
      `${this.apiBaseUrl}/${apiPath}/${id}/BookingStatus`,
      body,
      { headers: this.httpHeaders }
    );
  }

  SetIsDefault(apiPath: string, id: string): Observable<any> {
    return this.http.patch(`${this.apiBaseUrl}/${apiPath}/${id}/default/true`, {
      headers: this.httpHeaders,
    });
  }
  SetIsActiveBonus(apiPath: string, body: any): Observable<any> {
    return this.http.patch(`${this.apiBaseUrl}/${apiPath}`, body, {
      headers: this.httpHeaders,
    });
  }

  SetIsShowing(apiPath: string, id: string, active: boolean): Observable<any> {
    if (isNullOrUndefined(active)) {
      active = false;
    }

    return this.http.patch(
      `${this.apiBaseUrl}/${apiPath}/${id}/showing/${active}`,
      { headers: this.httpHeaders }
    );
  }
  SoftDeleteBonus(apiPath: string, body: any): Observable<any> {
    return this.http.patch(`${this.apiBaseUrl}/${apiPath}`, body, {
      headers: this.httpHeaders,
    });
  }

  SoftDelete(
    apiPath: string,
    id: string,
    isDelete: boolean = true
  ): Observable<any> {
    return this.http.patch(
      `${this.apiBaseUrl}/${apiPath}/${id}/delete/${isDelete}`,
      { headers: this.httpHeaders }
    );
  }

  //Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage : ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  private createConfig(config) {
    const options = config || {};

    //filter null value
    options.headers = this.httpHeaders;
    if (options.params) {
      options.params = Object.entries(options.params).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );
    }

    return options;
  }

  get(url: string, config?): Observable<any> {
    return this.http.get<any>(`${this.apiBaseUrl}/${url}`, this.createConfig(config));
  }

  post(url: string, body?: any, config?): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}/${url}`, body, this.createConfig(config));
  }

  put(url: string, body?: any, config?): Observable<any> {
    return this.http.put<any>(`${this.apiBaseUrl}/${url}`, body, this.createConfig(config));
  }

  patch(url: string, body?: any, config?): Observable<any> {
    return this.http.patch<any>(`${this.apiBaseUrl}/${url}`, body, this.createConfig(config));
  }

  delete(url: string, config?): Observable<any> {
    return this.http.delete<any>(`${this.apiBaseUrl}/${url}`, this.createConfig(config));
  }
}
