class Item {
  mediaFiles?: any[]
  isActive: boolean
  isDelete: boolean
  createBy: string
  createDate: string
  updateBy: string
  updateDate: string
  deleteBy: string
  deleteDate: string
  id: string
  itemCode: string
  modelNo: string
  groupId: number
  groupName: string
  categoryId: number
  categoryName: string
  subCategoryId: number
  subCategoryName: string
  classId: number
  className: string
  subClassId: number
  subClassName: string
  name: string
  handleName_uq: string
  itemBarcode: string
  brandId: number
  brandName: string
  purchasePrice: number
  sellingPrice: number
  repoveSV: number
  discountPrice: number
  taxRate: number
  description: string
  subDescription: string
  detail: string
  vendorId: number
  vendorName: string
  packageId: number
  packageName: string
  unitId: number
  unitName: string
  quantity: number
  fgAvailableQuantity: number
  focAvailableQuantity: number
  dimension: string
  weight: number
  volume: number
  colorId: number
  colorName: string
  materialId: number
  materialName: string
  textureId: number
  textureName: string
  lotNo: string
  lotDate: string
  isItemSet: boolean
  isBestSeller: boolean
  isNewProduct: boolean
  isShowing: boolean
  itemSet?: any[]
}

export default Item;
