export class Quotation {
  id: string;
  customerId: string;
  customerName: string;
  transactionStatusId: number;
  issuerId: string;
  issuerName: string;
  quotationCode: string;
  monthId: number;
  monthName: string;
  orderId: string;
  documentCreateDate: string;
  documentExpireDate: string;
  references: string;
  remarks: string;
  invoiceId?: string;
  isExcludeTax: boolean;
  isTaxInvoice: boolean;
  isReceipt?: boolean;
  shippingAddressId: string;
  shippingAddressDetail: string;
  shippingPhone: string;
  taxInvoiceAddressId: string;
  taxInvoiceAddressDetail: string;
  taxInvoicePhone: string;
  vatExemptedAmountTotal: number;
  preDiscountAmountTotal: number;
  preTaxAmountTotal: number;
  discount: number;
  percentDiscount?: number;
  isDiscountByPercent?: boolean;
  isCreateInvoice: boolean;
  isVatExempt: boolean;
  taxRate: number;
  salesTax: number;
  salesWHT: number;
  preWHTAmountTotal: number;
  grandTotal: number;
  preparedById: string;
  preparedByName: string;
  isVoid: boolean;
  isActive: boolean;
  isDelete: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  deleteBy: string;
  deleteDate: string;
  item: QuotationItem[];
}

export interface QuotationItem {
  id?: string;
  ordinal: number;
  quotationId?: string;
  itemId: string;
  itemName?: string;
  itemCode?: string;
  stockType?: number;
  quantity: number;
  unit: string;
  unitPrice: number;
  preTaxDiscount: number;
  discount: number;
  percentDiscount?: number;
  isDiscountByPercent?: boolean;
  salesTax?: number;
  whtRate: number;
  salesWHT?: number;
  grandTotal?: number;
  preTaxAmount?: number;
  isVatExempt?: boolean;
  isActive?: boolean;
  isDelete?: boolean;
  remarks: String;
  uniqueId: string;
  createBy?: string;
  createDate?: string;
  updateBy?: string;
  updateDate?: string;
  deleteBy?: string;
  deleteDate?: string;
  description: QuotationDescription[];
}

export interface QuotationDescription {
  id?: string;
  ordinal: number;
  quotationItemId?: string;
  description: string;
  isActive?: boolean;
  isDelete?: boolean;
  createBy?: string;
  createDate?: string;
  updateBy?: string;
  updateDate?: string;
  deleteBy?: string;
  deleteDate?: string;
}

export class TempRowQuotation {
  rowType:
    | 'item'
    | 'description'
    | 'accepted-item'
    | 'accepted-desc'
    | 'credit-item'
    | 'credit-desc'
    | 'debit-item'
    | 'debit-desc';
  itemId: string;
  itemName?: string;
  itemCode?: string;
  stockType?: number;
  quantity: number;
  inStockFGQuantity?: number;
  inStockFOCQuantity?: number;
  unit?: string;
  unitPrice: number;
  discount: number;
  preTaxDiscount: number;
  isDiscountByPercent: boolean;
  description: any;
  remarks: string;
  uniqueId: string;
  whtRate: number;
  salesWHT: number;
  grandTotal: number;
  preTaxAmount: number;
  isNewRow?: boolean = false;
}
