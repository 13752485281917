import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { TranslateService } from '@ngx-translate/core';
import { BannerService } from './banner.service';
import { ComponentsService } from '../../../components/components.service';
import Banner from '../../../model/Banner';
import { Subject } from 'rxjs';
import { ContentListComponent } from 'app/main/components/content-list/content-list.component';
import { ICMSConfig } from 'app/main/config/CMSInterface';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  config!: ICMSConfig;
  keyName: string;

  constructor(private _bannerService: BannerService) {
    this.config = this._bannerService.config;
    this.keyName = this._bannerService.keyName;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
