<div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">
        {{ title }}
    </h5>
    <button type="button" class="close" (click)="Cancel()" aria-label="Close">
        <span aria-hidden="true">
            <i data-feather="x" size="22"></i>
        </span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
    <section>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary" (click)="editMode()" [disabled]="isEditingValue">
                {{ 'EKYC.EditData' | translate }}
            </button>
        </div>

        <hr>

        <div class="d-flex">
            <div class="col" style="border-right: 1px solid #ebe9f1">
                <ng-container>
                    <div class="d-flex justify-content-between">
                        <div class="my-auto text-primary">{{ 'EKYC.PIDImage' | translate }}</div><span
                            *ngIf="isPidImageInvalid" class="text-danger">*</span>

                        <div class="d-flex align-items-center">
                            <p class="mr-1 mb-0">{{'General.Status' | translate}}:&nbsp;</p>
                            <select #statusC class="custom-select" [disabled]="pidImage == null"
                                (change)="confirmFileStatusChange('pidImage',$event.target.value)"
                                [(ngModel)]="pidImageStateEnum" [ngClass]="statusC.value == stateEnum.WaitForVerify ? 'text-warning' : statusC.value == stateEnum.Verified ? 'text-success' : 'text-danger'">
                                <option [value]="stateEnum.None" disabled class="text-dark">
                                    {{getStatusLabel(stateEnum.None)}}
                                </option>
                                <option [value]="stateEnum.WaitForVerify"
                                    [disabled]="pidImageStateEnum == stateEnum.WaitForVerify" class="text-dark">
                                    {{getStatusLabel(stateEnum.WaitForVerify)}}</option>
                                <option [value]="stateEnum.Verified"
                                    [disabled]="pidImageStateEnum == stateEnum.Verified" class="text-dark">
                                    {{getStatusLabel(stateEnum.Verified)}}</option>
                                <option [value]="stateEnum.Rejected"
                                    [disabled]="pidImageStateEnum == stateEnum.Rejected" class="text-dark">
                                    {{getStatusLabel(stateEnum.Rejected)}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="ekyc-image-preview mt-1">
                        <div class="ekyc-image-preview-container w-100"
                            [ngStyle]="{ 'border' : isSubmit && isPidImageInvalid ? '1px solid red' : 'none' }">
                            <img *ngIf="pidImage == null" [src]="'../../../../assets/images/general/kyc-1.png'"
                                alt="thumbIdCard" />
                            <img *ngIf="pidImage != null" [src]="pidImage" alt="thumbIdCard" class="idcard" />
                        </div>

                        <input type="file" (change)="
                      onFileSelected($event, 'pidImage',changeImageModal);
                    " accept="image/jpeg, image/png, image/jpeg" type="file" #pidImageUpload hidden />

                        <div class="col-12">
                            <div class="d-flex justify-content-center">
                                <button (click)="pidImageUpload.click()" *ngIf="isEditingValue"
                                    class="btn btn-warning mt-1">
                                    <i data-feather="edit"></i> {{ "General.Change" | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="col">
                <ng-container>
                    <div class="d-flex justify-content-between">
                        <div class="my-auto text-primary">{{ 'EKYC.PIDWithPersonImage' | translate }}<span
                                *ngIf="isPidWithPersonImageInvalid" class="text-danger">*</span>
                        </div>

                        <div class="d-flex align-items-center">
                            <p class="mr-1 mb-0">{{'General.Status' | translate}}:&nbsp;</p>
                            <select #statusP class="custom-select" [disabled]="pidWithPersonImage == null"
                                (change)="confirmFileStatusChange('pidWithPersonImage',$event.target.value)"
                                [(ngModel)]="pidWithPersonImageStateEnum" [ngClass]="statusP.value == stateEnum.WaitForVerify ? 'text-warning' : statusP.value == stateEnum.Verified ? 'text-success' : 'text-danger'">
                                <option [value]="stateEnum.None" disabled class="text-dark">
                                    {{getStatusLabel(stateEnum.None)}}
                                </option>
                                <option [value]="stateEnum.WaitForVerify"
                                    [disabled]="pidWithPersonImageStateEnum == stateEnum.WaitForVerify" class="text-dark">
                                    {{getStatusLabel(stateEnum.WaitForVerify)}}
                                </option>
                                <option [value]="stateEnum.Verified"
                                    [disabled]="pidWithPersonImageStateEnum == stateEnum.Verified" class="text-dark">
                                    {{getStatusLabel(stateEnum.Verified)}}
                                </option>
                                <option [value]="stateEnum.Rejected"
                                    [disabled]="pidWithPersonImageStateEnum == stateEnum.Rejected" class="text-dark">
                                    {{getStatusLabel(stateEnum.Rejected)}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="ekyc-image-preview mt-1">
                        <div class="ekyc-image-preview-container w-100"
                            [ngStyle]="{ 'border' : isSubmit && isPidWithPersonImageInvalid ? '1px solid red' : 'none' }">
                            <img *ngIf="pidWithPersonImage == null"
                                [src]="'../../../../assets/images/general/kyc-2.png'" alt="thumbIdCard" />
                            <img *ngIf="pidWithPersonImage != null" [src]="pidWithPersonImage" alt="thumbIdCard"
                                class="idcard" />
                        </div>

                        <input type="file" (change)="
                      onFileSelected($event, 'pidWithPersonImage',changeImageModal);
                    " accept="image/jpeg, image/png, image/jpeg" type="file" #pidWithPersonImageUpload hidden />

                        <div class="col-12">
                            <div class="d-flex justify-content-center">
                                <button (click)="pidWithPersonImageUpload.click()" *ngIf="isEditingValue"
                                    class="btn btn-warning mt-1">
                                    <i data-feather="edit"></i> {{ "General.Change" | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <hr />

        <div class="d-flex justify-content-between">

        </div>
        <div class="d-flex">
            <div class="col">

                <ng-container>
                    <div class="d-flex justify-content-between">
                        <div class="my-auto text-primary">{{ 'EKYC.Bookbank' | translate }}
                        </div>

                        <div class="d-flex align-items-center">
                            <p class="mr-1 mb-0">{{'General.Status' | translate}}:&nbsp;</p>
                            <select #statusB class="custom-select" [disabled]="bookBankImage == null"
                                (change)="confirmFileStatusChange('bookBankImage',$event.target.value)"
                                [(ngModel)]="bookBankImageStateEnum" [ngClass]="statusB.value == stateEnum.WaitForVerify ? 'text-warning' : statusB.value == stateEnum.Verified ? 'text-success' : 'text-danger'">
                                <option [value]="stateEnum.None" disabled class="text-dark">
                                    {{getStatusLabel(stateEnum.None)}}
                                </option>
                                <option [value]="stateEnum.WaitForVerify"
                                    [disabled]="bookBankImageStateEnum == stateEnum.WaitForVerify" class="text-dark">
                                    {{getStatusLabel(stateEnum.WaitForVerify)}}
                                </option>
                                <option [value]="stateEnum.Verified"
                                    [disabled]="bookBankImageStateEnum == stateEnum.Verified" class="text-dark">
                                    {{getStatusLabel(stateEnum.Verified)}}
                                </option>
                                <option [value]="stateEnum.Rejected"
                                    [disabled]="bookBankImageStateEnum == stateEnum.Rejected" class="text-dark">
                                    {{getStatusLabel(stateEnum.Rejected)}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="ekyc-image-preview mt-1">
                        <div class="ekyc-image-preview-container w-100">
                            <img *ngIf="bookBankImage == null" [src]="'../../../../assets/images/general/kyc-3.png'"
                                alt="thumbIdCard" />
                            <img *ngIf="bookBankImage != null" [src]="bookBankImage" alt="thumbIdCard" class="idcard" />
                        </div>

                        <input type="file" (change)="
                      onFileSelected($event, 'bookBankImage',changeImageModal);
                    " accept="image/jpeg, image/png, image/jpeg" type="file" #bookBankImageUpload hidden />

                        <div class="col-12">
                            <div class="d-flex justify-content-center">
                                <button (click)="bookBankImageUpload.click()" *ngIf="isEditingValue"
                                    class="btn btn-warning mt-1">
                                    <i data-feather="edit"></i> {{ "General.Change" | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- <div class="ekyc-image-preview mt-1">
                    <div class="ekyc-image-preview-container w-100">
                        <img *ngIf="bookBankImage == null" src="../../../../assets/images/general/kyc-3.png"
                            alt="thumbIdCard" />
                        <img *ngIf="bookBankImage != null" [src]="bookBankImage" alt="thumbIdCard" class="idcard" />
                    </div>
                    <input type="file" (change)="
                  onFileSelected($event, 'bookBankImage', changeImageModal);
                " accept="image/jpeg, image/png, image/jpeg" type="file" #bookBankImageUpload hidden />
                </div>
                <div class="col-12">
                    <div class="d-flex justify-content-center">
                        <button (click)="bookBankImageUpload.click()" class="btn btn-warning mt-1"
                            *ngIf="isEditingValue">
                            <i data-feather="edit"></i> {{ "General.Change" | translate }}
                        </button>
                    </div>
                </div> -->
            </div>

            <div class="col px-1">
                <div class="d-flex justify-content-between">
                    <div class="my-auto text-primary">{{
                        "Payment.Modal.BankAccount.Bank.Label" | translate
                        }}</div>

                    <div class="d-flex align-items-center">
                        <p class="mr-1 mb-0">{{'General.Status' | translate}}:&nbsp;</p>
                        <select #statusBB class="custom-select" (change)="onBankStatusChange($event.target.value)"
                            [(ngModel)]="EKYCDetail.verifyBookbankDetailStatusEnum" [ngClass]="statusBB.value == stateEnum.WaitForVerify ? 'text-warning' : statusBB.value == stateEnum.Verified ? 'text-success' : 'text-danger'">
                            <option [value]="stateEnum.None" disabled class="text-dark">
                                {{getStatusLabel(stateEnum.None)}}
                            </option>
                            <option [value]="stateEnum.WaitForVerify"
                                [disabled]="EKYCDetail.verifyBookbankDetailStatusEnum == stateEnum.WaitForVerify" class="text-dark">
                                {{getStatusLabel(stateEnum.WaitForVerify)}}
                            </option>
                            <option [value]="stateEnum.Verified"
                                [disabled]="EKYCDetail.verifyBookbankDetailStatusEnum == stateEnum.Verified" class="text-dark">
                                {{getStatusLabel(stateEnum.Verified)}}
                            </option>
                            <option [value]="stateEnum.Rejected"
                                [disabled]="EKYCDetail.verifyBookbankDetailStatusEnum == stateEnum.Rejected" class="text-dark">
                                {{getStatusLabel(stateEnum.Rejected)}}
                            </option>
                        </select>
                    </div>
                </div>
                <form [formGroup]="bankAccountForm">
                    <div class="col-12" style="margin-bottom: 0.5rem">
                        <label class="form-label" for="bankId">{{
                            "Payment.Modal.BankAccount.Bank.Label" | translate
                            }}</label>

                        <!-- <span class="text-danger">&nbsp;*</span> -->
                        <div class="w-100">
                            <ng-select bindLabel="name" bindValue="id" id="bankId" formControlName="bankId"
                                [clearable]="false" [searchable]="true" placeholder="{{
                      'Payment.Modal.BankAccount.Bank.Placeholder' | translate
                    }}" (change)="onSelectBankId()" [ngClass]="{
                      'is-invalid error':
                        (isSubmit && f.bankId?.invalid) ||
                        (f.bankId?.invalid && (f.bankId?.dirty || f.bankId?.touched))
                    }">
                                <ng-option *ngFor="let item of bankNameList" [value]="item.id">
                                    <img [src]="getBankImg(item.id)" [width]="30" [height]="30" class="mr-1">
                                    {{ item.name }}
                                </ng-option>
                            </ng-select>
                            <div class="invalid-feedback">
                                <div *ngIf="
                        isSubmit ||
                        (f.bankId.invalid && (f.bankId.dirty || f.bankId.touched))
                      ">
                                    {{ "Payment.Modal.BankAccount.Bank.Error" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="margin-bottom: 0.5rem">
                        <label class="form-label" for="accountNumber">{{
                            "Payment.Modal.BankAccount.AccountNumber.Label" | translate
                            }}</label>

                        <!-- <span class="text-danger">&nbsp;*</span> -->
                        <input type="text" class="form-control" (change)="onSelectAccountNumber()"
                            formControlName="accountNumber" placeholder="{{
                    'Payment.Modal.BankAccount.AccountNumber.Placeholder' | translate
                  }}" id="accountNumber"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                            maxlength="50" [ngClass]="{
                    'is-invalid error':
                      (isSubmit && f.accountNumber?.invalid) ||
                      (f.accountNumber?.invalid &&
                        (f.accountNumber?.dirty || f.accountNumber?.touched))
                  }" />
                        <div class="invalid-feedback">
                            <div *ngIf="
                      isSubmit ||
                      (f.accountNumber.invalid &&
                        (f.accountNumber.dirty || f.accountNumber.touched))
                    ">
                                {{ "Payment.Modal.BankAccount.AccountNumber.Error" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="margin-bottom: 0.5rem">
                        <label class="form-label" for="accountName">{{
                            "Payment.Modal.BankAccount.AccountName.Label" | translate
                            }}</label>
                        <!-- <span class="text-danger">&nbsp;*</span> -->
                        <input type="text" class="form-control" (change)="onSelectAccountName()"
                            formControlName="accountName" placeholder="{{
                    'Payment.Modal.BankAccount.AccountName.Placeholder' | translate
                  }}" id="accountName" maxlength="200" [ngClass]="{
                    'is-invalid error':
                      (isSubmit && f.accountName?.invalid) ||
                      (f.accountName?.invalid &&
                        (f.accountName?.dirty || f.accountName?.touched))
                  }" />
                        <div class="invalid-feedback">
                            <div *ngIf="
                      isSubmit ||
                      (f.accountName.invalid &&
                        (f.accountName.dirty || f.accountName.touched))
                    ">
                                {{ "Payment.Modal.BankAccount.AccountName.Error" | translate }}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12" *ngIf="isEditingValue">
                        <div class="d-flex justify-content-between mt-2">
                            <button (click)="Cancel()" class="btn btn-secondary">{{ "Form.Cancel" | translate
                                }}</button>
                            <button (click)="Submit()" class="btn btn-primary">{{ "Form.Submit" | translate }}</button>
                        </div>
                    </div> -->
                </form>
            </div>
        </div>
    </section>


</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-secondary m-0" (click)="Cancel()" rippleEffect>
        {{ "Form.Cancel" | translate }}
    </button>
    <a type="button" class="btn btn-primary m-0" (click)="Submit()" rippleEffect>
        {{ "Form.Submit" | translate }}
    </a>
</div>

<!-- <ng-template #imagePreview let-title="title" let-tempimage="tempimage" let-originalImage="originalImage"
    let-isInvalid="isInvalid" let-defaultImage="defaultImage" let-fileEnum="fileEnum" let-fileStateEnum="fileStateEnum"
    let-isRequired="isRequired">
    <div class="d-flex justify-content-between">
        <div class="my-auto text-primary">{{ title | translate }}<span *ngIf="isRequired" class="text-danger">*</span>
        </div>

        <div class="d-flex align-items-center">
            <p class="mr-1 mb-0">{{'General.Status' | translate}}:&nbsp;</p>
            <select class="custom-select" [disabled]="originalImage == null"
                (change)="confirmFileStatusChange(fileEnum,$event.target.value)" [(ngModel)]="selectedValue">
                <option [value]="0" [disabled]="fileStateEnum == 0">{{getStatusLabel(0)}}</option>
                <option [value]="1" [disabled]="fileStateEnum == 1">{{getStatusLabel(1)}}</option>
                <option [value]="2" [disabled]="fileStateEnum == 2">{{getStatusLabel(2)}}</option>
            </select>
        </div>
    </div>
    <div class="ekyc-image-preview mt-1">
        <div class="ekyc-image-preview-container w-100"
            [ngStyle]="{ 'border' : isSubmit && isInvalid ? '1px solid red' : 'none' }">
            <img *ngIf="originalImage == null" [src]="defaultImage" alt="thumbIdCard" />
            <img *ngIf="originalImage != null" [src]="originalImage" alt="thumbIdCard" class="idcard" />
        </div>

        <input type="file" (change)="
              onFileSelected($event, fileEnum,changeImageModal);
            " accept="image/jpeg, image/png, image/jpeg" type="file" #fileImageUpload hidden />

        <div class="col-12">
            <div class="d-flex justify-content-center">
                <button (click)="fileImageUpload.click()" *ngIf="isEditingValue" class="btn btn-warning mt-1">
                    <i data-feather="edit"></i> {{ "General.Change" | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template> -->

<ng-template #changeImageModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">
            Change e-KYC Picture
        </h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center" tabindex="0" ngbAutofocus>
        <div class="mt-3 mb-2" style="object-fit: cover">
            <img [src]="previewIMG" style="
                object-fit: cover;
                max-width: 500px;
                max-height: 500px;
                border-radius: 10px;
              " />
        </div>
        <p class="mt-1">{{ previewIMGName }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-dark" (click)="modal.close()" rippleEffect>
            {{ "Form.Cancel" | translate }}
        </button>
        <button type="button" ngSubmit (click)="SavePicture(); modal.close()" class="btn btn-primary" rippleEffect>
            {{ "Form.Submit" | translate }}
        </button>
    </div>
</ng-template>
