import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Customer from 'app/main/model/Customer';
import { Rank, RankEnum, RankList } from 'app/main/model/Rank';
import { ApiService } from 'app/main/service/api.service';
import { ModalComponent } from '../modal/modal.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ComponentsService } from '../components.service';

@Component({
  selector: 'app-modal-manage-rank',
  templateUrl: './modal-manage-rank.component.html',
  styleUrls: ['./modal-manage-rank.component.scss'],
})
export class ModalManageRankComponent implements OnInit {
  @Input() customer: Customer;
  @Input() defaultRank: number = 1;

  rankEnum = RankEnum;
  rankList: Rank[] = RankList;
  formGroup: FormGroup;
  isSubmit: boolean = false;

  @BlockUI() blockUI: NgBlockUI;
  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private componentsService: ComponentsService
  ) {
    this.formGroup = this.formBuilder.group({
      selectedRank: [0],
      modeType: [0],
    });
  }

  ngOnInit(): void {}

  closeModal() {
    this.modalService.dismissAll();
  }

  get f() {
    return this.formGroup.controls;
  }

  callBack() {
    this.callBackFunc.emit();
  }

  Submit() {
    this.isSubmit = true

    if (this.f.selectedRank.value == 0) {
      return
    }

    const modalRef = this.modalService.open(ModalComponent, { centered: true });
    modalRef.componentInstance.title = 'ยืนยันการปรับระดับ';
    modalRef.componentInstance.detail = `คุณแน่ใจที่จะปรับระดับลูกค้ารายนี้ให้เป็น ${this.rankList[this.f.selectedRank.value - 1].name} หรือไม่?`;

    modalRef.componentInstance.callBackFunc.subscribe(() => {
      if (this.f.modeType.value == 0) {
        this.blockUI.start();
        this.apiService
          .UpdateDataById('mlm/RankUpdateEndOfPeriod', this.customer.id, {
            changeRankTo: this.f.selectedRank.value,
          })
          .subscribe(
            (res) => {
              this.callBackFunc.emit()
              this.componentsService.SuccessSwal();
              this.blockUI.stop();
            },
            (err) => {
              this.componentsService.ErrorSwal();
              this.blockUI.stop();
            }
          );
      } else if (this.f.modeType.value == 1) {
        this.blockUI.start();
        this.apiService
          .UpdateDataById('mlm/RankInstantUpdate', this.customer.id, {
            rankId: this.f.selectedRank.value,
          })
          .subscribe(
            (res) => {
              this.callBackFunc.emit()
              this.componentsService.SuccessSwal();
              this.blockUI.stop();
            },
            (err) => {
              this.componentsService.ErrorSwal();
              this.blockUI.stop();
            }
          );
      }
    });
  }
}
