import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification.component';
import { RouterModule, Routes } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard } from 'app/auth/helpers';
import { DataTablesModule } from 'angular-datatables';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'app/main/components/components.module';
import { NotificationFormpageComponent } from './notification-formpage/notification-formpage.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

const routes: Routes = [
  {
    path: 'notification',
    component: NotificationComponent,
    canActivate: [AuthGuard],
    data: { animation: 'notification' },
  },
  {
    path: 'notification/create',
    component: NotificationFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'notification-create' },
  },
  {
    path: 'notification/edit/:id',
    component: NotificationFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'notification-create' },
  },
];

@NgModule({
  declarations: [NotificationComponent, NotificationFormpageComponent],
  exports: [NotificationComponent, NotificationFormpageComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    ReactiveFormsModule,
    NgbModule,
    DataTablesModule,
    ContentHeaderModule,
    TranslateModule,
    ComponentsModule,
    FormsModule,
    NgxSummernoteModule,
    Ng2FlatpickrModule
  ],
})
export class NotificationModule {}
