import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CertificationsFileComponent } from './certifications-file.component';
import { CertFileCreateComponent } from './cert-file-create/cert-file-create.component';
import { CoreCommonModule } from '@core/common.module';
import { CmsListModule } from '../../cms-list/cms-list.module';
import { CmsFormpageModule } from '../../cms-formpage/cms-formpage.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { ComponentsModule } from 'app/main/components/components.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { TranslateModule } from '@ngx-translate/core';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const path: string = 'cms/repove-tools/certification/file';

const routes: Routes = [
  {
    path: `${path}`,
    component: CertificationsFileComponent,
    canActivate: [AuthGuard],
    data: { animation: 'certifications-file' },
  },
  {
    path: `${path}/create`,
    component: CertFileCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'certifications-file-create' },
  },
  {
    path: `${path}/edit/:id`,
    component: CertFileCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'certifications-file-edit' },
  },
];

@NgModule({
  declarations: [CertificationsFileComponent, CertFileCreateComponent],
  imports: [
    CommonModule,
    CoreCommonModule,
    CmsListModule,
    CmsFormpageModule,
    RouterModule.forChild(routes),
    ComponentsModule,
    ContentHeaderModule,
    TranslateModule,
    DataTablesModule,
    NgbModule
  ],
})
export class CertificationsFileModule {}
