export const PaymentMethodList = {
  Cash: {
    id: '6ebf04f8-2f19-452f-9e08-c1f48a20a1ce',
    name: 'Cash',
  },
  // CreditDebit: {
  //   id: '51644c97-904c-48e4-91e6-e3419ad92b51',
  //   name: 'CreditDebit',
  // },
  Transfer: {
    id: '8a24224d-2aeb-4ec0-a3b0-bf3aa00f56bb',
    name: 'Transfer',
  },
  // Cheque: {
  //   id: '1c9b4544-ca69-46b1-8ed9-db578c619b4e',
  //   name: 'Cheque',
  // },
  // Other: {
  //   id: '0000',
  //   name: 'Other'
  // }
} as const;

export enum PaymentTypeExpenseEnum {
  Cash = 1,
  Transfer = 2,
  Cheque = 3,
}
export enum PaymentTypeRevenueEnum {
  Cash = 1,
  Transfer = 2,
  Cheque = 3,
  CreditDebit = 4,
}
