import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { User } from 'app/auth/models';
import { UsersApiService } from 'app/main/service/users-api.service';
import { UserService } from '../users.service';

@Component({
  selector: 'app-users-form-page',
  templateUrl: './users-form-page.component.html',
  styleUrls: ['./users-form-page.component.scss'],
})
export class UsersFormPageComponent implements OnInit {
  contentHeader: object;
  isLoading: boolean;
  callbackUrl: string;
  apiPath: string;

  userGuid: string;
  userObj: User;

  formGroup: FormGroup;

  constructor(
    private _userApiService: UsersApiService,
    private _route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _userService: UserService
  ) {
    this.SetLoadingState();
    this.callbackUrl = this._userService.pathUrl;
    this.apiPath = this._userService.apiPath;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'General.UserManage',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/'
          }
        ]
      }
    }
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }
  SetLoadedState(): void {
    this.isLoading = false;
  }
}
