import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class PersonalBonusService {
  
componentName: string = 'Personal Bonus'
apiUrl: string ='/mlm/MasterPersonalBonus';
pahtUrl:'/mlm-config/bonus-table-config'
  constructor() { }
}
