<div *ngIf="roleSetName == 'SuperAdmin'" class="badge badge-primary">
  SuperAdmin
</div>
<div *ngIf="roleSetName == 'Admin'" class="badge badge-secondary">Admin</div>
<!-- <div *ngIf="roleSetName == 'User'" class="badge badge-success">User</div> -->
<div *ngIf="roleSetName == 'Employee'" class="badge badge-danger">Employee</div>
<div *ngIf="roleSetName == 'Customer'" class="badge badge-warning">
  Customer
</div>
<div *ngIf="roleSetName == 'Marketing'" class="badge badge-info">Marketing</div>
<div *ngIf="!roleSetName || roleSetName == 'User'" class="badge badge-dark">
  User
</div>
