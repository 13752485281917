class Notification {
  id?: number
  typeId: number
  name: string
  title: string
  description: string
  notificationTypeId: number
  notificationTemplateId: number
  employeeActorId: any
  actorName: string
  rankNotifierJoinId: any
  startNotificationDate: Date
  notifierId: any
  customerList: any
  rankNotifierIdList: any
  isActive: boolean
  isDelete: boolean
  createBy: string
  createDate: Date
  updateBy: string
  updateDate: Date
  deleteBy: string
  deleteDate: string
}

export enum NotificationStatus {
  All = 0,
  Group = 1,
  AllRank = 2,
  GroupRank = 3,
}

export enum typeId {
  RepoveTH = 0,
  Promotion = 1,
  Order = 2,
  Finance = 3,
}

export default Notification;
