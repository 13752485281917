import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LogEvent } from 'app/main/model/LogEvent';
import { ApiService } from 'app/main/service/api.service';
import { FilesApiService } from 'app/main/service/files-api.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-action-history',
  templateUrl: './action-history.component.html',
  styleUrls: ['./action-history.component.scss'],
})
export class ActionHistoryComponent implements OnInit {
  @Input() title: string;
  @Input() apiPath: string;
  @Input() documentId: string;
  @Input() typeDocName: string;
  @Input() detailObj: any;

  isLoading: boolean;
  documentLog: any[] = [];
  coverMediaFile: string[];
  imageUrl: string[];
  logEvent = LogEvent;

  defaultImage: any;
  imageSources: any;

  constructor(
    private _apiService: ApiService,
    private _fileApiService: FilesApiService
  ) {
    this.defaultImage = environment.defaultIMG
    console.log(this.defaultImage);
    
    this.SetLoadingState();
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.getDocumentId();
    this.getDocumentLog();
  }

  getLogEvent(event: LogEvent): {
    text: string;
    badgeColorClass: string;
    colorCode: string;
    colorOpacityRGB: string;
    class: string;
  } {
    switch (event) {
      case LogEvent.Create:
        return {
          text: 'Created',
          badgeColorClass: 'badge-primary',
          colorCode: '#CD212A',
          colorOpacityRGB: 'RGB(205, 33, 42, 0.05)',
          class: 'timeline-point-primary',
        };
      case LogEvent.Paid:
        return {
          text: 'Paid',
          badgeColorClass: 'badge-success',
          colorCode: '#27C76F',
          colorOpacityRGB: 'RGB(39, 199, 111, 0.05)',
          class: 'timeline-point-success',
        };
      case LogEvent.View:
        return {
          text: 'Viewed',
          badgeColorClass: 'badge-info',
          colorCode: '#02CFE8',
          colorOpacityRGB: 'RGB(2, 207, 232, 0.05)',
          class: 'timeline-point-info',
        };
      case LogEvent.Update:
        return {
          text: 'Update',
          badgeColorClass: 'badge-warning',
          colorCode: '#FF9F43',
          colorOpacityRGB: 'RGB(255, 159, 67, 0.05)',
          class: 'timeline-point-warning',
        };
      case LogEvent.Accept:
        return {
          text: 'Accept',
          badgeColorClass: 'badge-success',
          colorCode: '#27C76F',
          colorOpacityRGB: 'RGB(39, 199, 111, 0.05)',
          class: 'timeline-point-success',
        };
      case LogEvent.Void:
        return {
          text: 'Void',
          badgeColorClass: 'badge-danger',
          colorCode: '#EA5455',
          colorOpacityRGB: 'RGB(234, 84, 85, 0.05)',
          class: 'timeline-point-danger',
        };
      case LogEvent.Cancel:
        return {
          text: 'Cancel',
          badgeColorClass: 'badge-danger',
          colorCode: '#EA5455',
          colorOpacityRGB: 'RGB(234, 84, 85, 0.05)',
          class: 'timeline-point-danger',
        };
      case LogEvent.IssueTaxInvoice:
        return {
          text: 'IssueTaxInvoice',
          badgeColorClass: 'badge-primary',
          colorCode: '#CD212A',
          colorOpacityRGB: 'RGB(205, 33, 42, 0.05)',
          class: 'timeline-point-primary',
        };
      case LogEvent.GotReceipt:
        return {
          text: 'GotReceipt',
          badgeColorClass: 'badge-info',
          colorCode: '#02CFE8',
          colorOpacityRGB: 'RGB(2, 207, 232, 0.05)',
          class: 'timeline-point-info',
        };
      case LogEvent.VoidGotReceipt:
        return {
          text: 'VoidGotReceipt',
          badgeColorClass: 'badge-danger',
          colorCode: '#EA5455',
          colorOpacityRGB: 'RGB(234, 84, 85, 0.05)',
          class: 'timeline-point-danger',
        };
      case LogEvent.RegisterTaxInvoice:
        return {
          text: 'RegisterTaxInvoice',
          badgeColorClass: 'badge-warning',
          colorCode: '#FF9F43',
          colorOpacityRGB: 'RGB(255, 159, 67, 0.05)',
          class: 'timeline-point-warning',
        };
      case LogEvent.VoidTaxInvoice:
        return {
          text: 'VoidTaxInvoice',
          badgeColorClass: 'badge-danger',
          colorCode: '#EA5455',
          colorOpacityRGB: 'RGB(234, 84, 85, 0.05)',
          class: 'timeline-point-danger',
        };
      default:
        return {
          text: '',
          badgeColorClass: 'badge-secondary',
          colorCode: '#82868B',
          colorOpacityRGB: 'RGB(130, 134, 139, 0.05)',
          class: 'timeline-point-secondary',
        };
    }
  }

  getDocumentId() {
    // console.log(this.documentId);
  }

  filterDuplicateDate(data: any) {
    const dateData = [];
    const createDateSet = new Set();

    for (const entry of data) {
      const createDate = new Date(entry.createDate).getTime();
      if (!createDateSet.has(createDate)) {
        createDateSet.add(createDate);
        dateData.push(entry);
      }
    }

    return dateData;
  }

  getDocumentLog() {
    const apiPath = this.apiPath + '/ActivityLog';
    this._apiService.GetDataById(apiPath, this.documentId).subscribe((res) => {
      this.documentLog = res.data.resultData.flat();
      this.documentLog.sort((a, b) => {
        const dateA = new Date(a.createDate).getTime();
        const dateB = new Date(b.createDate).getTime();
        return dateB - dateA;
      });

      this.documentLog = this.documentLog.map((item) => {
        const createDate = new Date(item.createDate);
        const offset = +7;
        createDate.setHours(createDate.getHours() + offset);
        return {
          ...item,
          createDate: createDate.toISOString(),
        };
      });

      this.documentLog = this.filterDuplicateDate(this.documentLog);
      this.documentLog = this.documentLog.filter(
        (item) => item.event !== this.logEvent.View
      );

      this.imageSources  = this.documentLog.map((item) => {
        return {
          profileImage: item.mediaFiles?.profileImage?.[0]?.id || null,
        };
      });

      this.SetLoadedState();
      console.log('Document Log : ', this.documentLog);
    });
  }

  shouldDisplayTitle(item: any): boolean {
    return (
      item.event !== 'Register TaxInvoice' &&
      item.event !== 'Got Receipt' &&
      item.event !== 'Void Got Receipt' &&
      item.event !== 'Void TaxInvoice' &&
      item.event !== 'IssueTaxInvoice'
    );
  }

  getProfileImage(filename: string) {
    let params = {
      Width: '40',
      Height: '40',
      KeepRatio: 'true',
    };

    if (filename) {
      return this._fileApiService.getImage(filename, params);
    } else {
      return this.defaultImage;
    }
  }
}
