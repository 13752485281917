<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section>
      <div class="card" *ngIf="detailObj">
        <div class="card-body">
          <div *ngIf="!isLoading">
            <div class="d-flex justify-content-between w-100 mt-2 mb-3">
              <div>
                <h2 class="my-auto">
                  {{ titleDoc ? titleDoc : "-" }}
                  {{ "ActionHistory.No" | translate }} #{{
                  detailObj[typeDocName]
                  }}
                </h2>
                <div class="d-flex justify-content-between">
                  <div class="d-block mt-2">
                    <app-document-status-badge [status]="detailObj.transactionStatusId" [isDelete]="detailObj.isDelete">
                    </app-document-status-badge>
                  </div>
                  <div class="d-block mt-2">
                    <span>{{ "ContentDocument.Ref" | translate }}</span>
                    <a [routerLink]="[refDetailUrl + '/' + detailObj[refDocID]]" target="_blank">
                      <span> {{ detailObj.references }} </span>
                    </a>
                  </div>
                </div>
              </div>

              <div *ngIf="
                  apiPath == 'Invoice' ||
                  apiPath == 'CreditNote' ||
                  apiPath == 'DebitNote' ||
                  apiPath == 'GoodsReceive'
                ">
                <div style="margin-top: 47px">
                  <p>
                    {{ "ContentDocument.InventoryStatus" | translate }}
                    :
                    <app-inventory-status-badge [status]="detailObj.inventoryStatusId"
                      [isDelete]="detailObj.isDelete"></app-inventory-status-badge>
                  </p>
                </div>
                <div class="d-flex justify-content-between"></div>
              </div>

              <div>
                <div class="d-flex">
                  <div ngbDropdown *ngIf="apiPath == 'Invoice'">
                    <button type="button" [disabled]="
                        (detailObj.transactionStatusId != 5 &&
                          detailObj.customerPaymentTypeEnum == 2) ||
                        (detailObj.transactionStatusId != 5 &&
                          !detailObj.trackingNumber &&
                          detailObj.customerPaymentTypeEnum == 2) ||
                        detailObj.trackingNumber ||
                        detailObj.isVoid
                      " class="d-flex justify-content-end text-white-50 btn btn-dark text-nowrap"
                      (click)="openConfirmCODModal()">
                      <div>
                        {{ "ContentDocument.TrackingNumber" | translate }}
                      </div>
                    </button>
                  </div>

                  <div ngbDropdown *ngIf="
                      apiPath == 'Invoice' ||
                      apiPath == 'GoodsReceive' ||
                      apiPath == 'DebitNote' ||
                      apiPath == 'CreditNote'
                    ">
                    <a ngbDropdownToggle href="javascript:void(0);"
                      class="d-flex justify-content-end text-white-50 btn btn-dark ml-1" id="tbproductwht-dropdown"
                      data-toggle="dropdown" aria-haspopup="true"
                      [disabled]="detailObj.inventoryStatusId == inventoryStatusEnum.Complete" aria-expanded="false">
                      <i data-feather="truck" class="mr-1"></i>
                      <div>{{ "Stock.TransferGoods" | translate }}</div>
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu-left" aria-labelledby="dropdownBrowserState">
                      <a *ngIf="
                          apiPath == 'Invoice' ||
                          apiPath == 'GoodsReceive' ||
                          apiPath == 'DebitNote' ||
                          apiPath == 'CreditNote'
                        " ngbDropdownItem class="d-flex align-items-center" (click)="SetInventoryStatus()"
                        placement="right" ngbTooltip="ตัดสต็อกสินค้าก็ต่อเมื่อจัดส่งสินค้าสำเร็จ" [disabled]="
                          (apiPath != 'Invoice' ||
                            apiPath != 'GoodsReceive' ||
                            apiPath != 'DebitNote' ||
                            apiPath == 'CreditNote') &&
                          detailObj.inventoryStatusId != inventoryStatusEnum.Pending
                        ">{{ "ContentDocument.SetInventory" | translate }}</a>

                      <a ngbDropdownItem class="d-flex align-items-center" (click)="SetInventoryCompleteAll()"
                        placement="right" ngbTooltip="ตัดสต็อกสินค้าก็ต่อเมื่อจัดส่งสินค้าสำเร็จ" [disabled]="
                          (apiPath != 'Invoice' ||
                            apiPath != 'GoodsReceive' ||
                            apiPath != 'DebitNote' ||
                            apiPath == 'CreditNote') &&
                          detailObj.inventoryStatusId != inventoryStatusEnum.WaitingAdjust
                        ">{{
                        "ContentDocument.TransferCenterInAll" | translate
                        }}</a>
                    </div>
                  </div>

                  <div ngbDropdown>
                    <a ngbDropdownToggle href="javascript:void(0);"
                      class="d-flex justify-content-end text-white-50 btn btn-dark mx-1" id="tbproductwht-dropdown"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i data-feather="printer" class="mr-1"></i>
                      <div>{{ "ContentDocument.Print" | translate }}</div>
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu-left" aria-labelledby="dropdownBrowserState">
                      <a ngbDropdownItem class="d-flex align-items-center" [routerLink]="['export']">{{
                        "ContentDocument.Print" | translate }}</a>
                    </div>
                  </div>

                  <div *ngIf="docStatus !== 8 && docStatus !== 9">
                    <div ngbDropdown>
                      <a ngbDropdownToggle href="javascript:void(0);"
                        class="d-flex justify-content-end text-white-50 btn btn-warning" id="tbproductwht-dropdown"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i data-feather="menu" class="mr-1"></i>
                        <div>{{ "ContentDocument.Menu" | translate }}</div>
                      </a>
                      <div ngbDropdownMenu class="dropdown-menu-left" aria-labelledby="dropdownBrowserState">
                        <a *ngIf="apiPath != 'SaleOrder'" ngbDropdownItem href=""
                          [routerLink]="[editPathUrl + '/' + detailObj.id]" [disabled]="isDisabledEditBtn"
                          class="d-flex align-items-center">
                          {{ "ContentDocument.Edit" | translate }}
                        </a>
                        <a *ngIf="apiPath == 'SaleOrder'" ngbDropdownItem [disabled]="isDisabledEditBtn" [routerLink]="[
                            editPathUrl + '/' + detailObj.quotationId
                          ]" class="d-flex align-items-center">
                          {{ "ContentDocument.Edit" | translate }}
                        </a>
                        <a *ngIf="
                            isCreateTaxInvoice &&
                            apiPath == 'Invoice' &&
                            !isJoinIV_TX_RE
                          " (click)="openGenTXModal()" ngbDropdownItem class="d-flex align-items-center">
                          {{ "ContentDocument.CreateTaxInvoice" | translate }}
                        </a>
                        <a *ngIf="apiPath == 'GoodsReceive' && docStatus == 10" (click)="openVoidReceiptModal()"
                          ngbDropdownItem class="d-flex align-items-center">
                          {{ "ContentDocument.VoidGotReceipt" | translate }}
                        </a>
                        <a [disabled]="
                            apiPath != 'Quotation' &&
                            apiPath != 'SaleOrder' &&
                            apiPath != 'PurchaseRequisition' &&
                            apiPath != 'PurchaseOrder'
                          " ngbDropdownItem (click)="createDocFromThisData()" class="d-flex align-items-center">
                          {{ "General.Copy" | translate }}
                        </a>
                        <a [disabled]="apiPath != 'Invoice' || detailObj.transactionStatusId == documentStatusEnum.AwaitPayment || detailObj.inventoryStatusId != inventoryStatusEnum.Complete"
                          ngbDropdownItem (click)="openReturnProductModal()" class="d-flex align-items-center">
                          {{ "ReturnProduct.ReturnProduct" | translate }}
                        </a>

                        <!-- <a ngbDropdownItem class="d-flex align-items-center" (click)="claimTest()">Claim</a> -->
                        <a *ngIf="
                            apiPath !== 'Quotation' &&
                            apiPath !== 'SaleOrder' &&
                            apiPath !== 'PurchaseRequisition' &&
                            apiPath !== 'PurchaseOrder'
                          " ngbDropdownItem (click)="openCancelDocModal()" class="d-flex align-items-center">
                          {{ (apiPath == 'Quotation' ||
                          apiPath == 'SaleOrder' ||
                          apiPath == 'PurchaseRequisition' ||
                          apiPath == 'PurchaseOrder') ? ("ContentDocument.CancelDocument" | translate)
                          :("ContentDocument.VoidDocument" | translate ) }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex mt-1">
                  <p class="mb-0">
                    {{ "ContentDocument.TrackingNumber" | translate }} :
                  </p>
                  <p class="mb-0 ml-1">
                    {{
                    detailObj.trackingNumber
                    ? "#" + detailObj.trackingNumber
                    : "-"
                    }}
                  </p>
                </div>
                <div class="d-flex mt-1">
                  <p class="mb-0">
                    {{ "ContentDocument.TransportationChannel" | translate }} :
                  </p>
                  <p class="mb-0 ml-1">
                    {{ detailObj ? detailObj.deliveryProviderName : "-" }}
                  </p>
                </div>
              </div>
            </div>

            <div class="detail-row row customer-row">
              <div class="col-2">
                <div class="d-flex">
                  <i data-feather="home" class="mr-1"></i>
                  <span>{{ "ContentDocument.CustomerInfo" | translate }}</span>
                </div>
              </div>
              <div class="col-3">
                <div class="d-flex">
                  <span>{{ "ContentDocument.Customer" | translate }}</span>
                </div>
                <div class="d-flex">
                  <a [routerLink]="['/customer/detail/' + detailObj.customerId]" target="_blank" class="text-primary">{{
                    detailObj.customerName }}
                    <br />
                    <span *ngIf="detailObj.isHeadBranch && detailObj.islegalEntity">
                      ({{ "ContentDocument.HeadOffice" | translate }})
                    </span>
                    <span *ngIf="!detailObj.isHeadBranch && detailObj.islegalEntity">
                      {{ "ContentDocument.Branch" | translate }} :
                      {{
                      detailObj
                      ? detailObj.branchNumber.toString().padStart(5, "0")
                      : "-"
                      }}
                    </span></a>
                </div>
              </div>

              <div *ngIf="
                  apiPath === 'Receipt' &&
                  apiPath === 'GoodsReceive' &&
                  apiPath === 'TaxReceive'
                " class="col-2">
                <p></p>
              </div>
              <div class="col-3"></div>
              <div class="col-2">
                <div class="d-flex">
                  <span>{{ "ContentDocument.IssueDate" | translate }}</span>
                </div>
                <div class="d-flex">
                  <p *ngIf="currentLang != 'th'">
                    {{ detailObj.documentCreateDate | date : "dd MMM YYYY HH:mm" }}
                  </p>
                  <p *ngIf="currentLang != 'en'">
                    {{
                    detailObj.documentCreateDate | thaidate : "DD MMM YYYY HH:mm"
                    }} น.
                  </p>
                </div>
              </div>

              <div *ngIf="
                  apiPath !== 'Receipt' &&
                  apiPath !== 'GoodsReceive' &&
                  apiPath !== 'TaxInvoice' &&
                  apiPath !== 'CreditNote' &&
                  apiPath !== 'DebitNote'
                " class="col-2">
                <div class="d-flex">
                  <span>{{ "ContentDocument.DueDate" | translate }}</span>
                </div>
                <div class="d-flex">
                  <p *ngIf="currentLang != 'th'">
                    {{ detailObj.documentExpireDate | date : "dd MMM YYYY HH:mm" }}
                  </p>
                  <p *ngIf="currentLang != 'en'">
                    {{
                    detailObj.documentExpireDate | thaidate : "DD MMM YYYY HH:mm"
                    }} น.
                  </p>
                </div>
              </div>
              <div class="col-2 mt-2">
                <div class="d-flex">
                  <i data-feather="home" class="mr-1"></i>
                  <span>{{ "ContentDocument.ShippingInfo" | translate }}</span>
                </div>
              </div>
              <div class="col-3 mt-2">
                <div class="d-flex">
                  <span>{{ "ContentDocument.Reciever" | translate }}</span>
                </div>
                <div class="d-flex">
                  <a [routerLink]="['/customer/detail/' + detailObj.customerId]" target="_blank" class="text-primary">{{
                    detailObj.shippingCustomerName
                    ? detailObj.shippingCustomerName
                    : "-"
                    }}
                    <br />
                    <span *ngIf="detailObj.isHeadBranch && detailObj.islegalEntity">
                      ({{ "ContentDocument.HeadOffice" | translate }})
                    </span>
                    <span *ngIf="!detailObj.isHeadBranch && detailObj.islegalEntity">
                      {{ "ContentDocument.Branch" | translate }} :
                      {{
                      detailObj
                      ? detailObj.branchNumber.toString().padStart(5, "0")
                      : "-"
                      }}
                    </span></a>
                </div>
              </div>
              <div class="col-6 mt-2">
                <div class="d-flex">
                  <span>{{
                    "ContentDocument.ShippingAddress" | translate
                    }}</span>
                </div>
                <div class="d-flex">
                  <div>
                    {{
                    detailObj.shippingAddressDetail
                    ? detailObj.shippingAddressDetail
                    : "-"
                    }}
                  </div>
                </div>
              </div>

              <!-- //////////tax section////////// -->

              <div class="col-2 mt-2">
                <div class="d-flex">
                  <i data-feather="home" class="mr-1"></i>
                  <span>{{
                    "ContentDocument.TaxInvoiceInfo" | translate
                    }}</span>
                </div>
              </div>
              <div class="col-3 mt-2">
                <div class="d-flex">
                  <span>{{
                    "ContentDocument.TaxInvoiceCustomerName" | translate
                    }}</span>
                </div>
                <div class="d-flex">
                  <a [routerLink]="['/customer/detail/' + detailObj.customerId]" target="_blank" class="text-primary">{{
                    detailObj.taxInvoiceCustomerName
                    ? detailObj.taxInvoiceCustomerName
                    : "-"
                    }}
                    <br />
                    <span *ngIf="detailObj.isHeadBranch && detailObj.islegalEntity">
                      ({{ "ContentDocument.HeadOffice" | translate }})
                    </span>
                    <span *ngIf="!detailObj.isHeadBranch && detailObj.islegalEntity">
                      {{ "ContentDocument.Branch" | translate }} :
                      {{
                      detailObj
                      ? detailObj.branchNumber.toString().padStart(5, "0")
                      : "-"
                      }}
                    </span></a>
                </div>
              </div>
              <div class="col-6 mt-2">
                <div class="d-flex">
                  <span>{{
                    "ContentDocument.TaxInvoiceAddress" | translate
                    }}</span>
                </div>
                <div class="d-flex">
                  <div>
                    {{
                    detailObj.taxInvoiceAddressDetail
                    ? detailObj.taxInvoiceAddressDetail
                    : "-"
                    }}
                  </div>
                </div>
              </div>

              <div class="col-3 mt-2" [ngClass]="{
                  'col-4': !(
                    apiPath == 'GoodsReceive' &&
                    detailObj.transactionStatusId != 8
                  )
                }">
                <div class="mt-2">
                  <div>
                    <i data-feather="credit-card" class="mr-1"></i>

                    <span>{{
                      "ContentDocument.PricingAndTaxSetting" | translate
                      }}</span>
                  </div>
                </div>
              </div>

              <div class="col-3" [ngClass]="{
                  'col-4': !(
                    apiPath == 'GoodsReceive' &&
                    detailObj.transactionStatusId != 8
                  )
                }">
                <div class="p-1 mt-2">
                  <div>
                    <span>{{ "ContentDocument.PricingType" | translate }}</span>
                  </div>
                  <div>
                    <span>
                      {{
                      (detailObj.isExcludeTax
                      ? "ContentDocument.Exclude"
                      : "ContentDocument.Include"
                      ) | translate
                      }}</span>
                  </div>
                </div>
              </div>

              <!-- <div
                class="col-3"
                [ngClass]="{
                  'd-none': !(
                    apiPath == 'GoodsReceive' &&
                    detailObj.transactionStatusId != 8
                  )
                }"
              >
                <div
                  *ngIf="
                    apiPath == 'GoodsReceive' &&
                    detailObj.transactionStatusId != 8
                  "
                  class="border rounded p-1 mt-2 bg-white"
                >
                  <app-add-tax-invoice
                    [TaxIv]="detailObj.taxInvoiceNo"
                    [TaxIvDate]="detailObj.taxInvoiceDate"
                    [DocID]="detailObj.id"
                    (valueChanged)="handleChildValueChange($event)"
                  >
                  </app-add-tax-invoice> -->
              <!-- <div>
                    <div>
                      <span> ใบกำกับภาษี </span>
                    </div>
                    <div>
                      <span class="text-success"> 1 ใบ </span>
                      <span class="text-danger">
                        {{ "General.Edit" | translate }}
                      </span>
                    </div>
                  </div> -->
              <!-- </div>
              </div> -->

              <div class="col-3" [ngClass]="{
                  'col-4': !(
                    apiPath == 'GoodsReceive' &&
                    detailObj.transactionStatusId != 8
                  )
                }">
                <div class="p-1 mt-2">
                  <div>
                    <span>{{ "ContentDocument.Currency" | translate }}</span>
                  </div>
                  <div>
                    <span>{{ "ContentDocument.Baht" | translate }}</span>
                  </div>
                </div>
              </div>

              <div style="display: contents" *ngIf="apiPath == 'CreditNote' || apiPath == 'DebitNote'">
                <div class="col-4">
                  <div class="mt-2">
                    <div>
                      <i data-feather="info" class="mr-1"></i>
                      <span *ngIf="apiPath == 'CreditNote'">{{
                        "ContentDocument.CreditNoteInfo" | translate
                        }}</span>
                      <span *ngIf="apiPath == 'DebitNote'">{{
                        "ContentDocument.DebitNoteInfo" | translate
                        }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="mt-2">
                    <div>
                      <span *ngIf="apiPath == 'CreditNote'">{{
                        "FormQuotation.RecieveMoreProduct.Reason" | translate
                        }}</span>
                      <span *ngIf="apiPath == 'DebitNote'">{{
                        "FormQuotation.SendMoreProduct.Reason" | translate
                        }}</span>
                    </div>
                    <div>
                      <span>{{
                        GetReasonDocument(detailObj.reasonId, apiPath)
                        }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="mt-2">
                    <div>
                      <span>{{
                        "FormQuotation.DescriptionOfReason" | translate
                        }}</span>
                    </div>
                    <div>
                      <span>{{ detailObj.reason }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-4"></div>
                <div class="col-4">
                  <div class="mt-2">
                    <div>
                      <span *ngIf="apiPath == 'CreditNote'">{{
                        "FormQuotation.RecieveMoreProduct.Label" | translate
                        }}</span>
                      <span *ngIf="apiPath == 'DebitNote'">{{
                        "FormQuotation.SendMoreProduct.Label" | translate
                        }}</span>
                    </div>
                    <div *ngIf="apiPath == 'CreditNote'">
                      <span *ngIf="detailObj.isProductReceived">
                        {{
                        "FormQuotation.RecieveMoreProduct.Option2" | translate
                        }}
                      </span>
                      <span *ngIf="!detailObj.isProductReceived">
                        {{
                        "FormQuotation.RecieveMoreProduct.Option1" | translate
                        }}
                      </span>
                    </div>
                    <div *ngIf="apiPath == 'DebitNote'">
                      <span *ngIf="detailObj.isProductSent">
                        {{
                        "FormQuotation.SendMoreProduct.Option2" | translate
                        }}
                      </span>
                      <span *ngIf="!detailObj.isProductSent">
                        {{
                        "FormQuotation.SendMoreProduct.Option1" | translate
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <table class="table form-table custo-table">
              <thead>
                <tr class="text-center">
                  <th class="text-center" width="5%">
                    {{ "ContentDocument.ProductService" | translate }}
                  </th>
                  <th class="text-start" width="30%">
                    {{ "ContentDocument.Description" | translate }}
                  </th>
                  <th class="text-start" width="5%">
                    {{ "DocTable.StockType" | translate }}
                  </th>
                  <th class="text-center" width="5%">
                    {{ "ContentDocument.Quantity" | translate }}
                  </th>
                  <th class="text-center" width="5%">
                    {{ "ContentDocument.Unit" | translate }}
                  </th>
                  <th class="text-center" width="10%">
                    {{ "ContentDocument.PriceQ" | translate }}<br />
                    ({{
                    detailObj.isExcludeTax
                    ? ("FormQuotation.Price.Exclude" | translate)
                    : ("FormQuotation.Price.Include" | translate)
                    }})
                  </th>
                  <th class="text-center" *ngIf="pageName != 'credit-note-detail'" width="10%">
                    {{ "ContentDocument.DiscQ" | translate }}
                  </th>
                  <th class="text-center" width="10%">
                    {{ "ContentDocument.PreVatAmount" | translate }} <br />
                    ({{ "FormQuotation.Price.Exclude" | translate }})
                  </th>
                  <!-- <th class="text-center">หัก ณ ที่จ่าย</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of detailObj.item">
                  <td class="text-center">
                    <a class="text-primary">
                      {{ item.itemCode }}
                    </a>
                  </td>
                  <td class="text-start">
                    <p *ngFor="let desc of item.description" class="m-0" style="
                        white-space: pre-line;
                        word-wrap: break-word;
                        display: block;
                        max-width: 350px;
                      ">
                      {{ desc.description }}
                    </p>
                  </td>
                  <td class="text-center">
                    {{ kStockType[item.stockType - 1].name }}
                  </td>
                  <td class="text-center">
                    {{
                    convertNumbertoMoneyFormat(
                    item.quantity,
                    !isQuantityDecimal
                    )
                    }}
                  </td>
                  <!-- <td class="text-center">{{ item.unit }}</td> -->
                  <td class="text-center">ชิ้น</td>
                  <td class="text-end">
                    {{ convertNumbertoMoneyFormat(item.unitPrice) }}
                  </td>
                  <td class="text-end" *ngIf="pageName != 'credit-note-detail'">
                    {{
                    item.isDiscountByPercent
                    ? convertNumbertoMoneyFormat(item.percentDiscount)
                    : convertNumbertoMoneyFormat(item.discount)
                    }}
                    <span *ngIf="item.isDiscountByPercent">%</span>
                  </td>
                  <td class="text-end">
                    {{ convertNumbertoMoneyFormat(item.preTaxAmount) }}
                  </td>
                  <!-- <td class="text-end">{{ item.salesWHT }}</td> -->
                </tr>
              </tbody>
            </table>

            <hr />

            <div class="detail-row row">
              <div class="col-2">
                <div class="d-flex">
                  <span>{{
                    "ContentDocument.DocumentSummary" | translate
                    }}</span>
                </div>
              </div>

              <div class="col-5">
                <div class="d-flex justify-content-end subtotal-row" *ngIf="
                    pageName === 'debit-note-detail' ||
                    pageName === 'credit-note-detail'
                  ">
                  <p class="my-auto">
                    {{
                    "ContentDocument.TotalAmountOfReferredTaxInvoices"
                    | translate
                    }}
                  </p>
                  <p class="ml-1 my-auto">
                    {{
                    convertNumbertoMoneyFormat(
                    invoiceDetailObj.preTaxAmountTotal
                    )
                    }}
                  </p>
                  <p class="ml-2 my-auto">
                    {{ "ContentDocument.Baht" | translate }}
                  </p>
                </div>
                <div class="d-flex justify-content-end subtotal-row" *ngIf="
                    pageName === 'debit-note-detail' ||
                    pageName === 'credit-note-detail'
                  ">
                  <p class="my-auto">
                    {{ "ContentDocument.NewAmount" | translate }}
                  </p>
                  <p class="ml-1 my-auto" *ngIf="pageName === 'debit-note-detail'">
                    {{
                    convertNumbertoMoneyFormat(
                    detailObj.preTaxAmountTotal +
                    invoiceDetailObj.preTaxAmountTotal
                    )
                    }}
                  </p>
                  <p class="ml-1 my-auto" *ngIf="pageName === 'credit-note-detail'">
                    {{
                    convertNumbertoMoneyFormat(
                    invoiceDetailObj.preTaxAmountTotal -
                    detailObj.preTaxAmountTotal
                    )
                    }}
                  </p>
                  <p class="ml-2 my-auto">
                    {{ "ContentDocument.Baht" | translate }}
                  </p>
                </div>
                <div class="d-flex justify-content-end subtotal-row" *ngIf="
                    pageName === 'debit-note-detail' ||
                    pageName === 'credit-note-detail'
                  ">
                  <p class="my-auto">
                    {{ "ContentDocument.Difference" | translate }}
                  </p>
                  <p class="ml-1 my-auto">
                    {{
                    convertNumbertoMoneyFormat(detailObj.preTaxAmountTotal)
                    }}
                  </p>
                  <p class="ml-2 my-auto">
                    {{ "ContentDocument.Baht" | translate }}
                  </p>
                </div>

                <div class="d-flex justify-content-end subtotal-row" *ngIf="pageName != 'credit-note-detail'">
                  <p class="my-auto">
                    {{ "ContentDocument.Discount" | translate }}
                  </p>
                  <p class="ml-1 my-auto">
                    {{
                    detailObj.isDiscountByPercent
                    ? convertNumbertoMoneyFormat(detailObj.percentDiscount)
                    : convertNumbertoMoneyFormat(detailObj.discount)
                    }}
                  </p>
                  <p class="ml-2 my-auto">
                    {{
                    detailObj.isDiscountByPercent
                    ? "%"
                    : ("ContentDocument.Baht" | translate)
                    }}
                  </p>
                </div>

                <div *ngIf="apiPath !== 'Receipt'" class="d-flex justify-content-end subtotal-row">
                  <p class="my-auto">
                    {{ "DocFooter.DeliveryPrice" | translate }}
                  </p>
                  <p class="ml-1 my-auto">
                    {{ detailObj.preTaxShippingFee | number : "1.2-2" }}
                  </p>
                  <p class="ml-2 my-auto">
                    {{ "ContentDocument.Baht" | translate }}
                  </p>
                </div>

                <div class="d-flex justify-content-end subtotal-row">
                  <p class="my-auto">
                    {{ "ContentDocument.VatItemValue" | translate }}
                    {{ detailObj.taxRate }}%
                  </p>
                  <p class="ml-1 my-auto" *ngIf="detailObj.isDiscountByPercent">
                    <!-- {{
                      convertNumbertoMoneyFormat(
                        detailObj.preDiscountAmountTotal -
                          detailObj.preDiscountAmountTotal *
                            (detailObj.discount / 100)
                      )
                    }} -->
                    {{ convertNumbertoMoneyFormat(detailObj.preTaxDiscount) }}
                  </p>
                  <p class="ml-1 my-auto" *ngIf="!detailObj.isDiscountByPercent">
                    {{
                    convertNumbertoMoneyFormat(detailObj.preTaxAmountTotal)
                    }}
                  </p>
                  <p class="ml-2 my-auto">
                    {{ "ContentDocument.Baht" | translate }}
                  </p>
                </div>

                <div class="d-flex justify-content-end subtotal-row">
                  <p class="my-auto">
                    {{ "ContentDocument.VatAmount" | translate }}
                  </p>
                  <p class="ml-1 my-auto">
                    {{ convertNumbertoMoneyFormat(detailObj.salesTax) }}
                  </p>
                  <p class="ml-2 my-auto">
                    {{ "ContentDocument.Baht" | translate }}
                  </p>
                </div>
              </div>

              <div class="col-1"></div>

              <div class="col-4">
                <div class="d-block grandTotal-box align-items-center">
                  <p class="mb-0" style="text-align: left !important">
                    {{ "ContentDocument.NetTotal" | translate }}
                  </p>
                  <span class="grandTotal-price mb-0">
                    {{
                    detailObj.salesWHT != 0 && detailObj.salesWHT > 0
                    ? convertNumbertoMoneyFormat(
                    detailObj.preWHTAmountTotal
                    )
                    : convertNumbertoMoneyFormat(detailObj.grandTotal)
                    }}
                  </span>
                  <p class="mb-0">{{ "ContentDocument.Baht" | translate }}</p>
                </div>

                <div *ngIf="detailObj.salesWHT != 0 && detailObj.salesWHT > 0">
                  <div class="d-flex justify-content-end subtotal-row">
                    <p class="my-auto">
                      {{ "ContentDocument.WithholdingTaxAmount" | translate }}
                    </p>
                    <p class="ml-1 my-auto">
                      {{ convertNumbertoMoneyFormat(detailObj.salesWHT) }}
                    </p>
                    <p class="ml-2 my-auto">
                      {{ "ContentDocument.Baht" | translate }}
                    </p>
                  </div>

                  <div class="d-flex justify-content-end subtotal-row">
                    <p class="my-auto">
                      {{ "ContentDocument.NetAmountToPay" | translate }}
                    </p>
                    <p class="ml-1 my-auto">
                      {{ convertNumbertoMoneyFormat(detailObj.grandTotal) }}
                    </p>
                    <p class="ml-2 my-auto">
                      {{ "ContentDocument.Baht" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div class="detail-row row">
              <div class="col-6">
                <div class="d-flex">
                  <i data-feather="edit-3" class="mr-1"></i>
                  <span>{{
                    "ContentDocument.RemarkForCustomer" | translate
                    }}</span>
                </div>
                <div class="d-flex">
                  <p class="ml-3" style="word-break: break-word">
                    {{ detailObj.remarks ? detailObj.remarks : "-" }}
                  </p>
                </div>
              </div>
            </div>

            <!-- <div class="">
              <div class="d-flex">
                <i data-feather="file-text" class="mr-1"></i>
                <span>แนบไฟล์ในเอกสารนี้</span>
              </div>

              <app-attached-file-uploader></app-attached-file-uploader>
            </div> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</div>