import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  componentName: string = 'Item';
  apiPath: string = 'item';
  pathUrl: string = '/item-manage/item';

  constructor() {}
}
