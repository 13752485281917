import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { ComponentsService } from 'app/main/components/components.service';
import { TimelineItem } from 'app/main/components/horizontal-timeline/horizontal-timeline.component';
import Customer from 'app/main/model/Customer';
import Item from 'app/main/model/Item';
import { Invoice, InvoiceItem, TempRowInvoice } from 'app/main/model/Invoice';
import { ApiService } from 'app/main/service/api.service';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { DragulaService } from 'ng2-dragula';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { DocumentService } from '../../document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { CalculateFuncService } from 'app/main/service/calculate-func.service';
import { AddBillComponent } from 'app/main/components/document/template/add-bill/add-bill.component';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-goods-receipt-formpage',
  templateUrl: './goods-receipt-formpage.component.html',
  styleUrls: ['./goods-receipt-formpage.component.scss'],
})
export class GoodsReceiptFormpageComponent implements OnInit {
  @ViewChild('confirmModal') confirmModal: ModalComponent;
  @ViewChild('addBillComponent', { static: false })
  addBillComponent: AddBillComponent;
  @ViewChildren('formTable') formTable: QueryList<ElementRef<HTMLLIElement>>;
  private _unsubscribeAll: Subject<any> = new Subject();
  invoiceData: any;

  contentHeader: object;
  currentUser: User;
  apiPath: string;
  apiPathExport: string;
  docID: string;
  refID: string;

  InvNumber: string;
  SaleId: string;

  docCode: string;
  isSubmit: boolean;
  invoiceForm: FormGroup;
  invoiceObj: Invoice;

  orderId: string;
  orderStatus: any = [];
  currentStep: number;

  taxRate: number = 7.0;
  whtRate: number = 0;
  componentName: String;
  itemCounter: number = 0;
  rows: TempRowInvoice[] = [];
  draggingRow: any;
  currentDate: Date = new Date();
  nextWeekDate: Date = new Date();
  customerList: Customer[] = [];
  customerObj: Customer;
  customerAddressObj: CustomerAddress;
  itemList: Item[] = [];
  isLoading: boolean;
  isEditing: boolean;
  isVatExempted: boolean;
  isSetValueWhenInit: boolean = false;
  isExcludeTax: boolean = true;
  isSameWHTAllRow: boolean;
  isDiscountByPercent: boolean;
  isEditForCreating: boolean;

  whtList: any[] = [
    // { value: 0, label: 'ยังไม่ระบุ' },
    { value: 0, label: 'ไม่มี' },
    { value: 0.75, label: '0.75%' },
    { value: 1, label: '1%' },
    { value: 1.5, label: '1.5%' },
    { value: 2, label: '2%' },
    { value: 3, label: '3%' },
    { value: 5, label: '5%' },
    { value: 10, label: '10%' },
    { value: 15, label: '15%' },
    // { value: 0, label: 'กำหนดเอง' },
  ];

  timelineData: TimelineItem[];

  createDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: this.currentDate,
  };

  expiredDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y',
    enableTime: false,
    defaultDate: new Date(
      this.nextWeekDate.setDate(this.currentDate.getDate() + 7)
    ),
  };

  isJoinIV_TX_RE: boolean = false;

  detailGRPath: string;

  constructor(
    private _translateService: TranslateService,
    private _dragulaService: DragulaService,
    private _apiService: ApiService,
    private _documentService: DocumentService,
    private _documentApiService: DocumentApiService,
    private _modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _componentsService: ComponentsService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _calculateService: CalculateFuncService,
    private _globalService: GlobalFuncService
  ) {
    this.componentName = 'Item';
    this._unsubscribeAll = new Subject();
    this.componentName = this._documentService.GoodsReceive.ComponentsName;
    this.timelineData = this._documentService.timelineBuyData;
    this.isJoinIV_TX_RE = environment.transaction.isJoinIV_TX_RE;
    this.apiPathExport = this._documentService.GoodsReceive.ComponentsName;
    this.detailGRPath = this._documentService.GoodsReceive.DetailPathURL;
    this.initData();
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }
  SetLoadedState(): void {
    this.isLoading = false;
  }

  ngOnInit(): void {
    this._documentApiService.GetDocRunningNumber(this.apiPath).subscribe(
      (res) => {
        console.log(res);
        this.InvNumber = res.data.resultData[0];
      },
      (error) => {}
    );
    this.isDiscountByPercent = false;
    var self = this;
    this._authenticationService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    self.contentHeader = {
      headerTitle: this.isJoinIV_TX_RE
        ? 'Document.IV_TX_RE'
        : 'Document.GoodsReceive',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Document',
            isLink: false,
            link: '/',
          },
          {
            name: this.isJoinIV_TX_RE
              ? 'Document.IV_TX_RE'
              : 'Document.GoodsReceive',
            isLink: true,
            link: '/document/goods-receive/view',
          },
          {
            name: 'FormName.GoodsReceiveForm',
            isLink: false,
          },
        ],
      },
    };
  }
  childFunction() {
    console.log('Child function called from the parent component');
  }

  ngOnDestroy() {
    console.log('hello from destroy');
    this._dragulaService.destroy('handle-list');
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  initData() {
    this.SetLoadingState();
    this.apiPath = this._documentService.purchaseOrder.ApiPath;
    this.apiPathExport = this._documentService.GoodsReceive.ApiPath;

    this.getitemList();

    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
      this.refID = this._route.snapshot.paramMap.get('refID');
      this.isEditing = this.docID ? true : false;
      this.isEditForCreating = this.refID ? true : false;
    });
    console.log(this.docID);
    console.log(this.refID);

    this.invoiceObj = new Invoice();
    this.invoiceObj.transactionStatusId = 0;
    this.invoiceObj.salesWHT = 0.0;

    if (this.isEditing) {
      this.getInvoiceData(this.apiPath, this.docID);
    } else if (this.isEditForCreating) {
      console.log('is creating IV');
      this.getRemainingInvoiceData(this.apiPathExport, this.refID);
      this.getLastCode();
    } else {
      this.getLastCode();
      this.customerObj = new Customer();
      this.invoiceData = {};
      this.SetLoadedState();
    }
  }

  get f() {
    return this.invoiceForm.controls;
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData[0];
        });
    });
  }

  getitemList(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('item', { isDelete: false })
        .subscribe((res) => {
          self.itemList = res.data.resultData;

          this.invoiceData = {
            itemList: res.data.resultData,
          };
        });
    });
  }

  getLastCode(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocRunningNumber('GoodsReceive')
        .subscribe((res) => {
          let docCode = document.getElementById('docCode') as HTMLFormElement;
          self.docCode = res.data.resultData[0];
          docCode.value = self.docCode;

          if (this.isEditForCreating) {
            this.invoiceObj.invoiceCode = self.docCode;
          }
        });
    });
  }

  getInvoiceData(ApiPath, DocID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._documentApiService.GetDocData(ApiPath, DocID).subscribe(
        (res) => {
          const invoiceObj = res.data.resultData[0];
          this.invoiceObj = invoiceObj;
          this.isExcludeTax = invoiceObj.isExcludeTax;
          // resolve(invoiceObj);

          this.orderId = invoiceObj.orderId;
          this.getOrderStatus(this.orderId).subscribe((orderStatusRes) => {
            this.orderStatus = orderStatusRes.data.resultData;
            console.log('Order Status: ', this.orderStatus[0]);
            this.currentStep = this._globalService.GetCurrentStep(
              this.orderStatus
            );
            console.log('Current Step: ', this.currentStep);
          });
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getOrderStatus(orderId: string): Observable<any> {
    return this._apiService.GetDataById('BuyOrder/State', orderId);
  }

  getRemainingInvoiceData(ApiPath, DocID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._documentApiService
        .GetRemainingDocData(this.apiPathExport, DocID)
        .subscribe(
          (res) => {
            console.log('LO', res);
            const invoiceObj = res.data.resultData[0];
            this.invoiceObj = invoiceObj;
            this.SaleId = invoiceObj.id;
            this.isExcludeTax = invoiceObj.isExcludeTax;
            // resolve(invoiceObj);

            this.orderId = invoiceObj.orderId;
            this.getOrderStatus(this.orderId).subscribe((orderStatusRes) => {
              this.orderStatus = orderStatusRes.data.resultData;
              console.log('Order Status: ', this.orderStatus[0]);
              this.currentStep = this._globalService.GetCurrentStep(
                this.orderStatus
              );
              console.log('Current Step: ', this.currentStep);
            });
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  onDataChange(event: any) {
    // Handle the updated data from the add-bill component
    console.log('Event Channged', event);
    // Other logic
  }
  handleAddBillSubmit(data: any): void {
    // Handle the submitted data from the AddBillComponent
    console.log('Submitted data from AddBillComponent:', data.quotationForm);
    // Perform any necessary actions with the submitted data
    this.Submit(data.isDraft, data.quotationForm, data.isEditing, data.docID);
  }

  Submit(
    isDraft?: boolean,
    invoiceForm?: object,
    isEditing?: boolean,
    docID?: string
  ) {
    var self = this;
    if (isEditing) {
      this._documentApiService
        .UpdateDocData(this.apiPathExport, invoiceForm, docID)
        .subscribe(
          (res) => {
            if (!isDraft) {
              this._documentApiService
                .ApproveDocData(this.apiPathExport, docID)
                .subscribe((res) => {
                  if (res) {
                    const titleLinkDocButton =
                      this._translateService.instant('Alert.GoToGR');

                    self._componentsService.SuccessSwal(
                      undefined,
                      undefined,
                      true,
                      titleLinkDocButton,
                      this.detailGRPath
                    );
                    this._router.navigate([
                      `/document/goods-receive/detail/${docID}`,
                    ]);
                  }
                });
            } else {
              const titleLinkDocButton =
                this._translateService.instant('Alert.GoToGR');

              self._componentsService.SuccessSwal(
                undefined,
                undefined,
                true,
                titleLinkDocButton,
                this.detailGRPath
              );
              self._router.navigate([`/document/goods-receive/view`]);
            }
          },
          (err) => {
            self._componentsService.ErrorSwal();
            self._router.navigate([`/document/goods-receive/view`]);
          }
        );
    } else {
      let isTX;
      let isRE;

      if (this.isJoinIV_TX_RE) {
        isTX = true;
        isRE = true;
      } else {
        isTX = false;
        isRE = false;
      }

      const modifiedInvoiceForm = {
        ...invoiceForm,
        isTaxInvoice: isTX,
        isReceipt: isRE,
        purchaseOrderId: this.SaleId,
      };
      console.log('THIS ISs SPATANNNN!!!', modifiedInvoiceForm);

      this._documentApiService
        .AddDocData(this.apiPathExport, modifiedInvoiceForm)
        .subscribe(
          (res) => {
            let qoGuid = res.data.resultData[0];
            console.log(res);

            let grCode = res.data.resultData[0].code;
            let grId = res.data.resultData[0].id;

            if (!isDraft) {
              console.log('is approve add');
              this._documentApiService
                .ApproveDocData(this.apiPathExport, res.data.resultData[0])
                .subscribe((res) => {
                  console.log(res);

                  if (res) {
                    const titleLinkDocButton =
                      this._translateService.instant('Alert.GoToGR');
                    const textCode = this._translateService.instant(
                      'Alert.CodeGR',
                      {
                        docCode: grCode,
                      }
                    );

                    self._componentsService.SuccessSwal(
                      undefined,
                      undefined,
                      true,
                      titleLinkDocButton,
                      this.detailGRPath
                    );
                    this._router.navigate([
                      `/document/goods-receive/detail/${qoGuid}`,
                    ]);
                  }
                });
            } else {
              const titleLinkDocButton =
                this._translateService.instant('Alert.GoToGR');
              const textCode = this._translateService.instant('Alert.CodeGR', {
                docCode: grCode,
              });

              self._componentsService.SuccessSwal(
                undefined,
                undefined,
                true,
                titleLinkDocButton,
                this.detailGRPath,
                textCode,
                grId
              );
              self._router.navigate([`/document/goods-receive/view`]);
            }
          },
          (err) => {
            self._componentsService.ErrorSwal();
            self._router.navigate([`/document/goods-receive/view`]);
          }
        );
    }
  }
}
